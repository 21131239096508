import styled, { css } from 'styled-components';

import { TextWrap } from 'styles/mixins';

const Transition = css`
  transition: opacity 80ms ease-out;
`;

export const LeftIconWrapper = styled.div`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.grey400};
  margin-right: 0.625rem;
`;

export const RightIconWrapper = styled.div`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.grey400};
  margin-left: 0.625rem;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  width: 100%;

  ${TextWrap};

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey600};
    ${Transition}
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey600};
  }
`;

export const Container = styled.div<{
  $isDisabled?: boolean;
  $hasError?: boolean;
}>`
  align-items: center;
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey400};
  display: flex;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  :hover > input:placeholder-shown {
    opacity: 0.7;
    ${Transition}
  }

  ${({ theme, $isDisabled }) =>
    $isDisabled &&
    css`
      background-color: ${theme.colors.grey300};
    `};

  ${({ $hasError, theme }) =>
    $hasError &&
    css`
      color: ${theme.colors.flamingo};
      border: solid 0.125rem ${theme.colors.flamingo};
      padding-inline: 0.6875rem;
    `}

  &:focus-within {
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    padding-inline: 0.6875rem;
  }
`;
