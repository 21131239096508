import { format } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';

import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
import Button from 'components/Button';
import useUser from 'context/hooks/User.hooks';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';

import {
  ActionButtons,
  Bold,
  ContactUs,
  Divider,
  Modal,
  ModalTitle,
  Shadow,
  Subtitle,
  Terms,
  Text,
} from './styles';
import { Props } from './types';

const TermsModal: FC<Props> = ({ isInvestor = false }) => {
  const isMobile = !useMediaQuery(from.tablet);
  const { close, isOpen } = useModal('TERMS');
  const {
    userInfo: { acceptedTerms },
    setAcceptTerms,
  } = useUser();

  const date = new Date();
  const month = format(date, 'LLLL');

  const handleCloseModal = useCallback(
    (_: any, reason: any) => {
      if (isInvestor || reason !== 'backdropClick') {
        close();
      }
    },
    [close, isInvestor],
  );

  const handleAccept = useCallback(async () => {
    await setAcceptTerms();
    close();
  }, [setAcceptTerms, close]);

  // If they accepted terms don't show this modal. They can always go to the terms page.
  const open = useMemo(
    () => isOpen && (isInvestor || !acceptedTerms),
    [acceptedTerms, isInvestor, isOpen],
  );

  return (
    <Modal
      showCloseButton={isInvestor}
      fullScreen={isMobile}
      onClose={handleCloseModal}
      open={open}
    >
      <Shadow />
      <ModalTitle>Terms of Service &amp; Privacy Policy</ModalTitle>
      <Terms>
        <Subtitle>Terms Of Use</Subtitle>
        <Divider />
        <Text>
          You need to accept this agreement to use the Seeds portal software.
          <br />
          <br />
          The Seeds Terms of Use was updated as of {month} {date.getFullYear()}.
          <br />
          <br />
          &quot;Advisor&quot; means a financial advisor, home office, or
          financial institution, broker-dealer, registered investment advisor,
          third party administrator, trust company or family office customer who
          is authorized to use the Service solely for the purpose of providing
          personal, family or household investment services to Consumer.
          <br />
          <br />
          &quot;Consumer&quot; means a consumer customer of Advisor who is
          authorized by Advisor to use the Service solely for personal, family
          or household purposes.
          <br />
          <br />
          &quot;User&quot; means you as the Advisor personnel or Consumer
          authorized by Advisor to use the Service, each who has authorized user
          identification codes and passwords to access the Service.
          <br />
          <br />
          These Seeds Investor Terms of Use (this &quot;Agreement&quot; or
          &quot;Terms of Use&quot;) are made by and between Seeds LLC, a
          Delaware limited liability company (&quot;Seeds&quot;), and User. This
          Agreement sets forth the general terms and conditions of User&#39;s
          access to and use of Seeds&#39; portal software (the
          &quot;Service&quot;).
          <br />
          <br />
          THIS AGREEMENT CONTAINS PROVISIONS THAT REQUIRE ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES BETWEEN USER AND SEEDS, RATHER
          THAN JURY TRIALS OR CLASS ACTIONS. PLEASE REVIEW THE ARBITRATION,
          CLASS WAIVER, JURY TRIAL WAIVER, GOVERNING LAW SECTION FOR FURTHER
          INFORMATION ABOUT THIS REQUIREMENT. PLEASE READ THIS AGREEMENT
          CAREFULLY TO ENSURE THAT USER UNDERSTANDS EACH PROVISION.
          <br />
          <br />
          By clicking &quot;I Accept&quot; on the Service and/or Consumer Site
          (as defined below), User agrees to all of the terms and conditions of
          this Agreement.
        </Text>
        <Subtitle>Consumer Aknowledgement</Subtitle>
        <Divider />
        <Text>
          Consumer acknowledges and agrees (1) that this Agreement is between
          Seeds and Consumer and (2) that Advisor is not a party to this
          Agreement. Notwithstanding the foregoing, Consumer enters into this
          Agreement with Seeds and uses the Service subject to the written terms
          and conditions between Advisor and Consumer of that certain investment
          advisory services agreement between Advisor and Consumer (the
          &quot;Advisor Agreement&quot;). By accepting this Agreement and using
          the Service, Consumer represents and warrants to Seeds (1) that he or
          she has received and agrees with the Advisor Agreement and (2) that
          the Advisor Agreement is a valid binding agreement between Consumer
          and Advisor. Consumer acknowledges and agrees (1) that Consumer&#39;s
          sole source of remedies against Seeds regarding the Service are set
          forth herein and (2) that Consumer&#39;s sole source of remedies
          against Advisor shall be found in the Advisor Agreement and any other
          applicable agreement(s) between Advisor and Consumer.
        </Text>
        <Subtitle>User Aknowledgement</Subtitle>
        <Divider />
        <Text>
          User acknowledges that, as the Service changes, User may be required
          to agree to additional terms and conditions as a condition to
          continued use of the Service. User further acknowledges that Seeds may
          change the terms and conditions applicable to use of the Service at
          any time, and if required by law, Seeds will obtain User&#39;s
          agreement to such changed terms and conditions. The Service is at all
          times subject to this Agreement then in effect; should User refuse to
          accept the current Agreement, Seeds will immediately terminate
          User&#39;s access to and use of the Service.
        </Text>
        <Subtitle>Privilege to Access and Use the Service</Subtitle>
        <Divider />
        <Text>
          During the term of and subject to the terms and conditions of this
          Agreement, Consumer may access and use those aspects of the Service
          intended for access and use by Consumers (the &quot;Consumer
          Site&quot;). Consumer shall not (1) access or use any aspect of the
          Service other than the Consumer Site; (2) access or use the Service
          for any purposes other than personal use; or (3) access or use the
          Service in any unlawful manner or in violation of this Agreement.
          Advisor may provide Consumer access and use of Consumer Site solely
          for personal, family or household purposes.
          <br />
          <br />
          User understands, acknowledges and agrees that:
          <br />
          <br />
          Consumer&#39;s access to and use of the Consumer Site is at all times
          subject to authorization from the Advisor. The Advisor may at any
          time, in the Advisor&#39;s sole and absolute discretion, without
          notice, for any reason or no reason, terminate Consumer&#39;s
          authorization to use the Consumer Site, and Seeds shall comply with
          such direction from Advisor. Further, Seeds may at any time, in its
          sole and absolute discretion, without notice, for any reason or no
          reason, terminate Consumer&#39;s access to the Consumer Site.
          <br />
          <br />
          The Consumer Site is not a replacement for account statements provided
          by custodians of Consumer&#39;s financial accounts. It is important to
          compare the information on the Consumer Site with the statements
          Consumer receives from the custodian(s) for the Consumer&#39;s
          account(s).
          <br />
          <br />
          Seeds retains the right to establish practices and/or limits in
          connection with the Service, including but not limited to establishing
          maximum disk space allotted to Consumer&#39;s information and files
          stored on Seeds&#39; servers.
          <br />
          <br />
          The Consumer Site is provided solely as a convenience to Consumer.
          <br />
          <br />
          The Consumer Site is provided solely for personal, family or household
          purposes.
          <br />
          <br />
          The Consumer Site does not provide any advice regarding insurance or
          securities or any other financial planning or other investment
          advisory services.
          <br />
          <br />
          As between Consumer and Seeds, Consumer is solely responsible for all
          financial decisions and financial planning made by Consumer, whether
          made in connection with use of the Service or otherwise.
          <br />
          <br />
          Seeds is not a broker-dealer. Consumer access to brokerage accounts
          may be made available through the broker-dealer&#39;s website. Any
          transactions Consumer initiates in such brokerage accounts are
          processed through the broker-dealer where the Consumer&#39;s account
          is custodied, not Seeds. Seeds is not responsible for and shall not be
          held liable for any advice given or recommendations made by the
          Advisor with respect to any financial, insurance, planning or other
          matter, regardless of whether Consumer relied on such advice or the
          Service.
          <br />
          <br />
          Seeds does not guarantee or warrant that any part of the Service is
          free of viruses or other harmful code. User must take appropriate
          precautions, such as use of an anti-virus software package, to protect
          his or her computer hardware and software.
          <br />
          <br />
          User acknowledges and agrees that Seeds does not police communications
          or data transmitted through the Service and that Seeds shall not be
          responsible for the content of any such communications or
          transmissions.
          <br />
          <br />
          User has obtained all necessary authorizations and consents required
          by law, if any, to upload any data to the Seeds Service.
          <br />
          <br />
          User shall comply with applicable law at all times when using the
          Seeds Service. Any unlawful, harassing or other inappropriate behavior
          may result in the suspension or termination of User&#39;s subscription
          at Seeds&#39; sole discretion.
          <br />
          <br />
          User is responsible for obtaining his or her own hardware, software
          and services (such as computers, web browsers and Internet access
          service) necessary to access and use the Service and for payment of
          all fees involved in obtaining such hardware, software and services.
          <br />
          <br />
          User shall be solely responsible for safeguarding his or her login
          credentials such as user name and password. Consumer must immediately
          notify the Advisor if his or her login credentials are compromised.
          Seeds has no liability for any loss, claim, or other damages that
          result from unreported, unauthorized use of Consumer&#39;s login
          credentials.
          <br />
          <br />
          Seeds may require multi-factor authentication to access the Service.
          As a result, User understands that, at Seeds&#39; discretion, User may
          be required to use at least two (2) forms of authentication when
          accessing the Service and performing certain operations through the
          Service. Forms of multi-factor authentication in addition to
          User&#39;s login credentials may include verification tokens delivered
          through SMS or a specified and supported two-factor authentication
          (“Multi-Factor Authentication”) application. If User chooses to
          install and use a Multi-Factor Authentication application on a device
          on which the operating system has been tampered with in any way, User
          understands that User does so at User&#39;s own risk. Seeds reserves
          the right in Seeds&#39; sole discretion to prohibit access to the
          Service from or by any device on which the operating system has been
          or is suspected of having been modified or tampered with. User agrees
          that Seeds may provide User&#39;s Multi-Factor Authentication data to
          a third-party service provider in order to help authenticate User
          <br />
          <br />
          If as a result of monitoring or review by Seeds or its affiliates, the
          User is or is suspected to be in breach of the Terms of Use, Seeds
          shall have the right to immediately terminate any of its services and
          User&#39;s relationship without liability to User, User&#39;s clients
          or User&#39;s third-party services providers, agents or vendors.
          Seeds, in its sole discretion, reserves the right to suspend or
          terminate the User or the User&#39;s third party service provider or
          vendors access to, or the User or the User&#39;s third party service
          provider or vendors right to use, the Third Party Data and that upon
          termination or suspension of such access or use or upon the
          termination of the User&#39;s relationship, the User shall cease and
          shall cause all of User&#39;s third party service providers, agents
          and vendors to cease all use of and delete or purge any and all of the
          Third Party Data. Seeds will not be liable to User for any
          modification or discontinuance of any third party service provider or
          vendor products or services, whether as a result of Seeds&#39;
          suspension or termination of the Third Party Data, User&#39;s
          relationship with Seeds or for any other reason.
          <br />
          <br />
          By using the service, you acknowledge that you have read and
          understand the terms of the Seeds Advisor LLC privacy notice
          (&quot;Privacy Notice&quot;) available through the Service or
          www.seedsadvisor.com (&quot;Site&quot;). If you do not agree with (i)
          the Privacy Notice, (ii) any future revisions to the Privacy Notice
          and/or (iii) any practices related to the Privacy Notice, do not use,
          visit, register or otherwise access the Service or content contained
          in the Service.
        </Text>
        <Subtitle>Third Party Data</Subtitle>
        <Divider />
        <Text>
          Security, market and reference data (collectively, &quot;Third Party
          Data&quot;) is provided by third party licensor(s) of Seeds to enhance
          raw customer data. Third Party Data shall not be distributed or
          redistributed in any manner by the User or the User&#39;s agents,
          third party service providers or vendors. To the extent Seeds provides
          Third Party Data to the User or the User&#39;s third party service
          providers, agents or vendors, the receipt and use of Third Party Data
          by the User and the User&#39;s third party service providers is
          subject to certain terms and conditions required by third party
          licensors of Seeds (the &quot;Third Party Terms&quot;). The User shall
          be liable and responsible to Seeds for any actions or omissions,
          including any unauthorized or inadvertent receipt, use or misuse of
          third party data by the User or the User&#39;s third party service
          providers in violation of the Third Party Terms, the terms herein or
          any other obligations that are made to Seeds by the User.
          <br />
          <br />
          Third Party Data shall only be used to provide services to each
          individual client with respect to such client&#39;s holdings (and
          shall not use a client Third Party Data to provide services to another
          client or entity) and for no other purpose. The User shall not
          commingle the holdings of a particular client with the holdings of
          another client for the purpose of circumventing the obligations as
          otherwise set forth in the Third Party Terms, the terms herein or any
          other obligations that are made to Seeds by the User and shall ensure
          that User&#39;s third party service providers, agents or vendors do
          not commingle the Third Party Data relating to the holdings of a
          particular third party service provider, agent or vendor customer with
          the holdings of another third party service provider, agent or vendor
          customer (except to the extent such commingling is the result of
          householding of various accounts or such commingling is provided in a
          summary or report showing the holdings of such househeld accounts).
          <br />
          <br />
          Except as expressly provided herein, the User shall not distribute,
          redistribute, export to, download for, provide access to, or otherwise
          transfer Third Party Data to any party including but not limited to
          third party service providers, vendors, or agents.
          <br />
          <br />
          Use or unpermitted disclosure of any Third Party Data by User may
          result in immediate and irreparable harm to Seeds and/or the third
          party licensors of Seeds and any remedies at law in such event may not
          be adequate. Accordingly, in addition to all other remedies available
          at law or in equity, Seeds shall have the right to seek equitable and
          injunctive relief, without the necessity of proving actual damages and
          without posting a bond, to prevent such unauthorized, negligent or
          inadvertent use or disclosure, and to recover the amount of all such
          damages (including attorneys&#39; fees and expenses) incurred in
          connection with such use or disclosure. Additionally, Seeds is not
          waiving or purporting to waive any rights that the third party
          licensors of Seeds may have to pursue any and all claims against the
          User, the Consumer or the User&#39;s third party service providers,
          agents or vendors in connection with the unauthorized use or
          disclosure of Third Party Data.
          <br />
          <br />
          The User and the User&#39;s third party service providers, vendors and
          agents shall not distribute, redistribute or otherwise transfer Third
          Party Data (i) into (or to a national or resident of or to the
          government of) Cuba, Iran, North Korea, Sudan, Syria, Burma (Myanmar),
          Libya or any other country with respect to which the United States
          maintains trade sanctions prohibiting the shipment or provision of
          services, goods, technology or software; or (ii) to anyone on or
          acting on behalf of or owned or controlled by an entity on the
          Specially Designated Nations and Blocked Persons List maintained by
          the U.S. Treasury Department&#39;s Office of Foreign Assets Control
          (the &quot;SDN List&quot;) or the U.S. Commerce Department&#39;s
          Denied Persons List or Entities List (collectively with the SDN List,
          the &quot;U.S. Prohibited Party List(s)&quot;). Each time the User
          accesses, uses, transmits, exports, downloads, distributes,
          redistributes or otherwise transfers Third Party Data, the User shall
          be deemed to represent, warrant and covenant to Seeds that the User
          and the User&#39;s third party service providers, clients, agents and
          vendors are not (x) located in or a national or resident of or the
          government of any country that is subject to U.S. trade sanctions or
          (y) on any U.S. Prohibited Party List or acting on behalf of or owned
          or controlled by any person or entity on any such list.
          <br />
          <br />
          The User will defend, indemnify and hold harmless Seeds, and its
          affiliates, licensors, and third party licensors, service providers,
          and their partners, suppliers, officers, directors, employees and
          successors and assigns thereof (&quot;Indemnified Parties&quot;) from
          and against all claims, demands, proceedings, suits and actions and
          all liabilities, losses, expenses and costs (including any reasonable
          legal fees and expenses relating to Seeds&#39; or any third party
          service providers defense) (&quot;Damages&quot;) arising from third
          party claims which allege: (i) failure by the User or the User&#39;s
          third party service providers, clients, agents or vendors to comply
          with any obligations to Seeds with regards to the Third Party Data;
          (ii) the unauthorized access or use of the Third Party Data by the
          User or the User&#39;s third party service providers, clients, agents
          or vendors; or (iii) any negligent or willful acts, errors, or
          omissions by the User or the User&#39;s third party service providers,
          clients, agents or vendors in the performance of the User&#39;s
          obligations, provided, however, that the User shall not be obligated
          to indemnify any Indemnified Party in the event that, and solely to
          the extent that, the Damages result from the gross negligence or
          willful misconduct of Seeds. In the event Seeds requests
          indemnification pursuant to this Section, it shall give notice to the
          User promptly after the receipt of any claim that may be indemnifiable
          hereunder. The User shall have sole control of the defense with
          respect to any such claim (including settlement of such claim), except
          that Seeds may participate in such defense at its own expense, and
          that no settlement that imposes liability on or detrimentally affects
          the rights of Seeds or its licensors, and third-party service
          providers, and their partners, suppliers, officers, directors,
          employees and successors and assigns shall be entered into by the User
          without Seeds&#39; prior written consent. If the User fails to either
          defend or settle any such claim, Seeds may defend the claim, and keep
          the User informed of the progress, or settle the claim for a
          commercially reasonable result.
        </Text>
        <Subtitle>File Sharing</Subtitle>
        <Divider />
        <Text>
          The Service currently provides a service known as File Sharing which
          allows User to store, share, and retrieve electronic files on the
          Consumer Site. To add a file, (a) Consumer may either upload the file
          to a Consumer folder to which the Consumer may have access or (b) a
          User may provide a copy of the file to the Advisor if that document is
          to be added to a folder contained on the Consumer Site. The Advisor is
          responsible for sharing a file and organizing Consumer&#39;s files.
          User understands and agrees that Consumer will have &quot;view
          only&quot; access to materials that the Advisor has added and that any
          addition, deletion, modification, or organization of Consumer&#39;s
          files must be processed through the Advisor.
          <br />
          <br />
          User understands and agrees that:
          <br />
          <br />
          For each file stored or shared, User represents and warrants to Seeds
          that User lawfully possesses such file and has the authority to upload
          or have the file uploaded, stored and made retrievable to and from the
          Vault.
          <br />
          <br />
          User is solely responsible for the use and content of any file that
          User uploads into or accesses from the Consumer Site, including files
          provided by third parties (including, without limitation, User&#39;s
          broker-dealer, User&#39;s broker-dealer&#39;s service providers and
          User&#39;s custodian) and such third parties do not assume any
          liability with respect to the content of such files when viewed from
          the Consumer Site or for any other content provided on this site. User
          agrees not to use the Consumer Site to store any file or information
          that is unlawful, offensive, indecent, or otherwise actionable by any
          third party.
          <br />
          <br />
          Seeds is not responsible for any use or dissemination of User&#39;s
          files by any third parties to whom User grants authorization to view
          his or her files.
          <br />
          <br />
          File Sharing is provided to Consumer as a convenience and may be
          discontinued by Seeds at any time. User should not rely on the File
          Sharing as User&#39;s storage system for files; User should always
          retain a copy of User files outside of the Consumer Site. User is
          responsible for retaining originals or copies of the files he or she
          chooses to store in the Consumer Site as Seeds does not guarantee the
          contents will be available to the User or that the Consumer Site will
          always be offered as a service.
          <br />
          <br />
          User should not rely on the File Sharing as User&#39;s storage system
          for files; User should always retain a copy of User files outside of
          the Consumer Site. User is responsible for retaining originals or
          copies of the files he or she chooses to store in the Consumer Site as
          Seeds does not guarantee the contents will be available to the User or
          that the Consumer Site will always be offered as a service.
        </Text>
        <Subtitle>
          Arbitration, Class Waiver, Jury Trial Waiver, Governing Law
        </Subtitle>
        <Divider />
        <Text>
          READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES USER AND Seeds TO
          ARBITRATE THEIR DISPUTES.
          <br />
          <br />
          Both Seeds and User agree to resolve by binding arbitration any claim,
          dispute, or controversy (whether based in contract, tort, statute, or
          any other legal theory) arising out of or in connection with or
          relating to (1) the products or services made available by Seeds,
          including Advisor, User&#39;s use of these products and services, and
          the information that User provides Seeds in connection with User&#39;s
          use of these products and services, or (2) this Agreement, including
          without limitation, the validity, interpretation, enforcement, or
          application of this Agreement and/or this Section (collectively,
          &quot;Claims&quot;). Both User and Seeds further agree that the
          arbitrator shall also have the exclusive authority to determine all
          additional threshold arbitrability issues, including without
          limitation issues relating to whether the Agreement is unconscionable
          or illusory and any defense to arbitration, including waiver, delay,
          laches, or estoppel. A party who intends to seek arbitration must
          first send a written notice of the Claim to the other party by
          certified mail or Federal Express, or in the event that we do not have
          a physical address on file for User, by electronic mail
          (&quot;Notice&quot;). Seeds and User agree to use good faith efforts
          to resolve the Claim, but if the parties do not resolve the Claim
          within 30 days of the Notice, then either party may commence
          arbitration. Our address for notice is:
          <br />
          <br />
          Attn: General Counsel
          <br />
          <br />
          Seeds Investor LLC
          <br />
          <br />
          311 W. 43 RD Street
          <br />
          <br />
          New York, NY, 10036
          <br />
          <br />
          Arbitration Procedure: Any arbitration between User and Seeds will be
          governed by the Commercial Dispute Resolution Procedures and the
          Supplementary Procedures for Consumer Related Disputes (collectively,
          &quot;AAA Rules&quot;) of the American Arbitration Association
          (&quot;AAA&quot;), as modified by this Agreement, and will be
          administered by the AAA. The AAA Rules and filing forms are available
          online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
          contacting Seeds. Unless User and Seeds otherwise agree, the
          arbitration will be conducted in the county where User resides. If
          User is using the Service for commercial purposes, payment of all
          arbitration fees will be determined in accordance with the AAA Rules.
          If User is using the Service for non-commercial purposes and
          User&#39;s claim is for less than $10,000, Seeds will reimburse
          User&#39;s filing fee, unless the arbitrator finds that either the
          substance of User&#39;s claim or the relief sought is frivolous or
          brought for an improper purpose, in which case the payment of all fees
          will be determined in accordance with the AAA Rules. If User is using
          the Service for non-commercial purposes and User&#39;s claim is for
          greater than $10,000, the payment of all fees will be determined in
          accordance with the AAA Rules. Any judgment on the award rendered by
          the arbitrator may be entered in any court of competent jurisdiction.
          <br />
          <br />
          Exceptions: Notwithstanding anything else in this Agreement to the
          contrary, Seeds and User both agree that nothing herein will be deemed
          to waive, preclude, or otherwise limit either of our right to (i)
          bring an individual action in small claims court, (ii) pursue
          enforcement actions through applicable federal, state, or local
          agencies where such actions are available, (iii) seek injunctive
          relief in a court of law, provided that is the sole form of relief
          sought, or (iv) to file suit in a court of law to address intellectual
          property infringement claims. User and Seeds agree that the state
          courts with jurisdiction over New York, New York shall have exclusive
          jurisdiction over any claim, dispute or controversy brought pursuant
          to this section, and both parties irrevocably consent to the
          jurisdiction of this court.
          <br />
          <br />
          No Class Actions: USER AND Seeds AGREE THAT EACH MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY AND NOT IN ANY
          REPRESENTATIVE CAPACITY, INCLUDING A CLASS ACTION, COLLECTIVE ACTION,
          PRIVATE ATTORNEY GENERAL ACTION OR ANY OTHER REPRESENTATIVE PROCEEDING
          WHATSOEVER. Further, unless both User and Seeds agree otherwise, the
          arbitrator may not consolidate more than one person&#39;s claims, and
          may not otherwise preside over any form of a representative or class
          proceeding. The arbitrator may award declaratory or injunctive relief
          only in favor of the claimant and only to the extent necessary to
          provide relief warranted by the claimant&#39;s individual claim. Any
          determination about the validity, interpretation, enforcement, and/or
          application of this No Class Action section is delegated to the
          arbitrator.
          <br />
          <br />
          Jury Trial Waiver: USER AGREES THAT, BY ENTERING INTO THIS AGREEMENT,
          USER AND Seeds ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY FOR ANY
          Claims TO THE FULLEST EXTENT PERMITTED BY LAW.
          <br />
          <br />
          Governing Law: Notwithstanding any choice of law or other provision in
          the Terms, the parties agree and acknowledge that this Agreement
          evidences a transaction involving interstate commerce and that the
          Federal Arbitration Act, 9 U.S.C. § 1 et seq. (&quot;FAA&quot;), will
          govern its interpretation and enforcement and proceedings pursuant
          thereto. If the FAA and AAA Rules are found to not apply to any issue
          that arises under this Agreement or the enforcement thereof, then that
          issue shall be resolved under the laws of the State of New York,
          without respect to its conflict of laws principles, the parties
          acknowledge that this Agreement evidences a transaction involving
          interstate commerce.
          <br />
          <br />
        </Text>
        <Subtitle>Risk disclosure</Subtitle>
        <Divider />
        <Text>
          The Consumer Site is intended to provide general information about
          Seeds accounts. It is not intended to offer or deliver investment
          advice in any way.
          <br />
          <br />
          Consumers acknowledge responsibility to contact the respective Advisor
          if there are any changes in personal financial situation or investment
          objectives for the purpose of reviewing, evaluating, or revising
          previous recommendations and/or services, or if the Consumer would
          like to impose, add, or modify any reasonable restrictions.
        </Text>
        <Subtitle>Privacy disclosure</Subtitle>
        <Divider />
        <Text>
          Seeds does not collect personal non-public information through the
          Consumer Site; however, Advisors may collect information from
          Consumers on application forms, agreements, profile or investment
          policy statements, and other documents received or processed in
          relation to services. Seeds does not respond to &quot;do not
          track&quot; requests because Seeds does not track Users over time or
          across third party websites to provide targeted advertising.
        </Text>
        <Subtitle>Other Terms and Conditions</Subtitle>
        <Divider />
        <Text>
          Consumer may terminate use of the Service at any time by contacting
          the Advisor. Once Consumer has terminated use of the Service, Consumer
          will have no further access to the Service and all files retained in
          the file storage service will be deleted.
          <br />
          <br />
          Seeds stores information regarding actions taken by Consumer when
          Consumer accesses the Consumer Site. This information may be provided
          to the Advisor and to other Seeds employees and contractors who
          provide services or products to the Consumer. Consumer agrees that the
          Service is non-transferable and any rights to the Service and access
          to the Consumer Site and the information or contents within the
          Consumer Site, terminate upon Consumer&#39;s death. Upon receipt of a
          copy of Consumer&#39;s death certificate, Consumer&#39;s access to the
          Consumer Site may be terminated and all contents therein permanently
          deleted.
          <br />
          <br />
          Seeds may modify or discontinue offering the Service at any time, with
          or without notice, for any reason or no reason.
          <br />
          <br />
          The Service may provide links to websites and include embedded videos
          that are not operated or hosted by Seeds. Seeds provides these links
          and/or videos for User&#39;s convenience and may be removed at any
          time. Seeds does not investigate the quality, accuracy or completeness
          of any content on third party websites. User acknowledges and agrees
          that Seeds is not responsible or liable for any content, advertising,
          products or other materials on or available from third party websites
          or embedded videos. User is responsible for familiarizing his or
          herself with the content of such third party websites and/or embedded
          videos, including the applicable privacy policies related thereto. For
          more information on Seeds&#39;s privacy policies, please see the Seeds
          privacy statement.
          <br />
          <br />
          NONE OF SEEDS, AND ITS AFFILIATES, PARTNERS, SUPPLIERS, OFFICERS,
          DIRECTORS, EMPLOYEES AND SUCCESSORS AND ASSIGNS, ITS THIRD-PARTY DATA
          PROVIDERS, LICENSORS, AND THEIR PARTNERS, SUPPLIERS AND THEIR
          RESPECTIVE AFFILIATES (COLLECTIVELY, &quot;THIRD PARTY
          SUPPLIERS&quot;) ARE MAKING ANY REPRESENTATION OR WARRANTY, EXPRESSED
          OR IMPLIED, AS TO THE ACCURACY OR COMPLETENESS OF ANY THIRD PARTY DATA
          OR WITH RESPECT TO THE USER OR THE USER&#39;S THIRD PARTY SERVICE
          PROVIDERS, AGENTS OR VENDORS RECEIPT OR USE OF THE THIRD PARTY DATA.
          THE USER OR THE USER&#39;S THIRD PARTY SERVICE PROVIDERS, AGENTS OR
          VENDORS USE OF (OR USE IN ANY PARTICULAR LOCATION AS SET FORTH ABOVE)
          THIRD PARTY DATA IS AT THE USER&#39;S SOLE RISK. THIRD PARTY DATA IS
          PROVIDED BY SEEDS, ITS AFFILIATES AND THIRD PARTY SUPPLIERS ON AN
          &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. SEEDS, ITS
          AFFILIATES AND THIRD PARTY SUPPLIERS EXPRESSLY DISCLAIM ALL WARRANTIES
          OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO THE THIRD PARTY DATA
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
          SEEDS, ITS AFFILIATES AND THIRD-PARTY SUPPLIERS MAKE NO WARRANTY OR
          COMMITMENT TO THE USER THAT (I) THE THIRD PARTY DATA WILL MEET THE
          USER OR THE USER&#39;S THIRD PARTY SERVICE PROVIDERS, AGENTS OR
          VENDORS REQUIREMENTS, (II) THE TRANSMISSION OR RECEIPT OF THIRD PARTY
          DATA WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, (III) THE
          THIRD PARTY DATA THAT IS OBTAINED BY THE USER OR THE USER&#39;S THIRD
          PARTY SERVICE PROVIDERS, AGENTS OR VENDORS WILL BE ACCURATE, COMPLETE
          OR RELIABLE, OR (IV) ANY ERRORS IN THE THIRD PARTY DATA WILL BE
          CORRECTED. SEEDS, ITS AFFILIATES AND THIRD-PARTY SUPPLIERS SHALL NOT
          HAVE ANY LIABILITY (INCLUDING, LIABILITY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR SPECIAL DAMAGES,
          LOSS RESULTING FROM BUSINESS DISRUPTION, LOST PROFITS OR TRADING
          ERRORS) TO THE USER OR THE USER&#39;S THIRD PARTY SERVICE PROVIDERS,
          CLIENTS, AGENTS OR VENDORS AS A RESULT OF THE USER OR THE USER&#39;S
          THIRD PARTY SERVICE PROVIDERS, AGENTS, CLIENTS OR VENDORS USE, OR
          INABILITY TO USE, OR ANY ERRORS IN, ANY THIRD PARTY DATA OR ANY OTHER
          MATTER PERTAINING HEREIN.
          <br />
          <br />
          THE SERVICE IS FURNISHED &quot;AS IS&quot; AND WITHOUT ANY EXPRESS OR
          IMPLIED REPRESENTATIONS OR WARRANTIES INCLUDING, WITHOUT LIMITATION,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          AVAILABILITY OF ALL OR ANY PART OF THE SERVICE, AND FREEDOM FROM
          ERRORS, VIRUSES, BUGS OR OTHER HARMFUL COMPONENTS.
          <br />
          <br />
          SEEDS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES EVEN IF ADVISED OF THE
          POSSIBILITY OF THE SAME.
          <br />
          <br />
          IF THE FOREGOING DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
          IS HELD TO BE UNENFORCEABLE, CONSUMER AGREES THAT SEEDS&#39; LIABILITY
          TO CONSUMER, WHETHER IN TORT, CONTRACT OR OTHERWISE, FOR ALL CAUSES
          SHALL NOT EXCEED IN THE AGGREGATE $500.
          <br />
          <br />
          If any clause or provision set forth in this Agreement is determined
          to be illegal, invalid or unenforceable under present or future law,
          then the clause or provision so determined to be illegal, invalid or
          unenforceable shall be severable without affecting the enforceability
          of all remaining clauses or provisions.
          <br />
          <br />
          The headings used in these Terms of Use are for convenience only and
          such headings are not to be used in determining the meaning or
          interpretation of these Terms of Use. This Agreement will continue to
          apply until terminated by either the User or Seeds in accordance with
          this Agreement and/or the prevailing agreement in place between the
          Advisor and Seeds. This Agreement may not be modified or amended
          without the prior written consent of Seeds. Seeds may modify this
          Agreement from time to time. Any and all changes to this Agreement may
          be provided to User by electronic means. In addition, the Agreement
          will always indicate the date it was last revised. User is deemed to
          accept and agree to be bound by any changes to the Agreement when
          using the Service after those changes are posted.
          <br />
          <br />
          <Bold>
            Please note: Seeds does not accept trading or money movement
            instructions via email.
          </Bold>
          <br />
          <br />
          As a registered investment advisor, Seeds emails may be subject to
          inspection by the Chief Compliance Officer (“CCO”) of Seeds or the
          securities regulators.
          <br />
          <br />
          If you have received an email from Seeds in error, we ask that you
          contact the sender and destroy the email and its contents.
          <br />
          <br />
          If you have any questions regarding our email policies, please{' '}
          <ContactUs href="mailto:support@seedsinvestor.com">
            Contact Us
          </ContactUs>
          .
        </Text>
      </Terms>
      <ActionButtons>
        <Button
          icon={<CheckIcon />}
          onClick={isInvestor ? close : handleAccept}
          variant="primary"
          data-testid="accept-terms"
        >
          {isInvestor ? 'Continue' : 'Accept terms and continue'}
        </Button>
      </ActionButtons>
    </Modal>
  );
};

export default TermsModal;
