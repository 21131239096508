import IMask from 'imask';
import * as Yup from 'yup';

export const pattern = '1 (000) 000-0000';
export const VALID_PHONE_REGEX = /^\d\s\(\d{3}\)\s\d{3}-\d{4}$/;

export const phoneValidation = Yup.string()
  .matches(VALID_PHONE_REGEX, 'Invalid phone format')
  .notOneOf([pattern], 'Invalid phone');

export const blocksSchema = {
  0: {
    mask: IMask.MaskedRange,
    placeholderChar: '0',
    from: 0,
    to: 9,
    maxLength: 1,
  },
};
