import { SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { FC } from 'react';

import { StyledSelect } from './styled';

type OptionType = {
  value: string;
  label: string;
};
interface DropdownProps {
  options: OptionType[];
  onChange: (value: any) => void;
  placeholder?: string;
  value: OptionType;
  assetType?: string;
}

const Dropdown: FC<DropdownProps> = ({
  options,
  onChange,
  placeholder = 'Select Options',
  value = { label: '', value: '' },
  assetType = '',
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as string;
    let params: any = newValue;

    if (assetType) {
      params = {
        value: newValue,
        assetType,
      };
    }

    onChange(params);
  };

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        <StyledSelect
          value={value.value}
          onChange={handleChange}
          displayEmpty
          variant="standard"
          inputProps={{ 'aria-label': 'Without label' }}
          style={{ minWidth: '70px' }}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default Dropdown;
