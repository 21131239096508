import { useCallback, useRef } from 'react';

const useLogic = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return {
    handle: {
      focus: handleFocus,
    },
    inputRef,
  };
};

export default useLogic;
