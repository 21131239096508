/* eslint-disable react/no-danger */
import { FC, useMemo } from 'react';

import DraggableOptions from 'components/DraggableOptions';
import { Description, Hint } from 'components/SurveyCard/styles';
import { from, useMediaQuery } from 'styles/media';

import useConnect from './connect';
import { Props } from './types';

const OrderItems: FC<Props> = ({
  description,
  extraDescription,
  mobileDescription,
  initialValue,
  onAnswer,
  orderItems,
  readOnly,
}) => {
  const { handleAnswer, options } = useConnect({
    initialValue,
    onAnswer,
    orderItems,
  });

  const isMobile = !useMediaQuery(from.tablet);

  const descriptionToUse = useMemo(
    () => (isMobile && mobileDescription ? mobileDescription : description),
    [description, isMobile, mobileDescription],
  );

  return (
    <>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: descriptionToUse || '' }} />
      </Description>

      {extraDescription && (
        <>
          {' '}
          <Hint dangerouslySetInnerHTML={{ __html: extraDescription }} />
        </>
      )}
      <div style={{ overflow: 'hidden' }}>
        <DraggableOptions
          options={options}
          onChange={handleAnswer}
          disabled={readOnly}
        />
      </div>
    </>
  );
};

export default OrderItems;
