import { FC, memo } from 'react';

import { Container, Text } from './styles';
import { Props } from './types';

const Legal: FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Text>
        Seeds Investor LLC (“Seeds”) is a Registered Investment Advisor (“RIA”),
        located in the State of New York. Seeds provides investment advisory and
        related services for clients nationally. Seeds will maintain all
        applicable registration and licenses as required by the various states
        in which Seeds conducts business, as applicable. Seeds renders
        individualized responses to persons in a particular state only after
        complying with all regulatory requirements, or pursuant to an applicable
        state exemption or exclusion.
      </Text>
    </Container>
  );
};

export default memo(Legal);
