import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles';

const ValueSwitch = withStyles(() => ({
  switchBase: {
    // Default color for the knob (unchecked)
    color: '#878787',
    '&$checked': {
      // Color for the knob when it's checked
      color: '#36BF8A',
    },
    '&$checked:hover': {
      backgroundColor: 'rgba(54, 191, 138, 0.1)',
    },
    '&$checked + $track': {
      // Color for the track when the switch is checked
      backgroundColor: '#36BF8A',
    },
    // Not Checked and Disabled
    '&:not(checked).Mui-disabled  + $track': {
      backgroundColor: '#878787',
    },
    '&:not(checked).Mui-disabled .MuiSwitch-thumb': {
      backgroundColor: '#D3D3D3',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 3px 0px rgba(0,0,0,0.02)',
    },
    // Checked and Disabled
    '&.Mui-checked.Mui-disabled + $track': {
      backgroundColor: '#36BF8A',
    },
    '&.Mui-checked.Mui-disabled .MuiSwitch-thumb': {
      backgroundColor: '#DCF2E8', // light greenish gray
      boxShadow:
        '0px 2px 1px -1px rgba(198, 232, 217, 0.6), 0px 1px 1px 0px rgba(198, 232, 217, 0.8), 0px 1px 3px 0px rgba(198, 232, 217, 0.8)',
    },
  },
  checked: {},
  thumb: {},
  track: {
    // Default color for the track (unchecked)
    backgroundColor: '#A9A9A9',
  },
}))(Switch);

export default ValueSwitch;
