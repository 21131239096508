import { FC } from 'react';

import { Container } from './styles';
import { Props } from './types';

const Placeholder: FC<Props> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default Placeholder;
