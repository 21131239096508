const getValueLevels = (input?: any) => {
  if (!input) return undefined;

  const levels = [
    { title: 'earth', percentage: input.earthScore },
    { title: 'people', percentage: input.peopleScore },
    { title: 'integrity', percentage: input.integrityScore },
  ].filter((level) => !!level.percentage);

  return levels as {
    title: string;
    percentage: number;
  }[];
};

function levelsHelper({
  currentPortfolio,
  seedsPortfolio,
}: {
  currentPortfolio: any;
  seedsPortfolio: any;
}) {
  const levels = {
    current: getValueLevels(currentPortfolio),
    recommended: getValueLevels(seedsPortfolio),
  };

  return levels;
}

export default levelsHelper;
