import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

const TradeTicketRequestsButton = ({
  dataId,
  handleTradeTicketRequest,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTradetTicketRequestModal = (actionType: string) => {
    handleTradeTicketRequest(actionType, dataId);
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Button
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            color: theme.palette.grey[900],
          },
        })}
      >
        <MenuItem
          onClick={() => handleTradetTicketRequestModal('Reallocation')}
        >
          Reallocation Request
        </MenuItem>
        <MenuItem onClick={() => handleTradetTicketRequestModal('Cash Raise')}>
          Cash Raise Request
        </MenuItem>
        <MenuItem onClick={() => handleTradetTicketRequestModal('Other')}>
          Other
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TradeTicketRequestsButton;
