import { memo, useCallback, useState } from 'react';
import Component, { PrintContextConsumer as Consumer } from 'react-to-print';

import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import useTradeTicketActions from 'context/fastContext/tradeticket/actions';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';

import { Props } from './types';
// This would be a nice to have, sometimes the generation of the
// pdf takes longer depending on some external variable, like
// cpu speed or else. So the button should show a loading state
// if the generation takes longer than a specified threshold
// it should show the loading state.
/* const useStateSuspense = <T extends any>(initialValue: T | (() => T)) => {
  const [value, setValue] = useState(initialValue);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const previousValue = useRef(initialValue);
  const changeValue = (newValue: T) => {
    function frame() {
      setValue(newValue);
      previousValue.current = newValue;
    }
    if (timeoutRef.current && previousValue.current === newValue) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    } else {
      const timeoutId = setTimeout(() => requestAnimationFrame(frame), 50);
      timeoutRef.current = timeoutId;
    }
  };
  return [value, changeValue] as const;
}; */

const Print = ({ children, ...props }: Props) => {
  const { fetchAccounts } = useAccountActions();
  const { fetchTicketHistory } = useTradeTicketActions();
  const [isViewingHistory] = useAccountStore((s: any) => s.isViewingHistory);
  const [isViewingTicketHistory] = useTradeTicketStore(
    (s: any) => s.isViewingTicketHistory,
  );
  const [loading, setLoading] = useState(false);
  const triggerPrint = useCallback(
    (print: () => void) => () => {
      setLoading(true);
      print();
    },
    [setLoading],
  );

  const handleAfterPrint = useCallback(() => {
    if (isViewingHistory) {
      // TODO: Ensure this is grabbing the current accounts with cache
      fetchAccounts({ useCache: true });
    }
    if (isViewingTicketHistory) {
      fetchTicketHistory({ useCache: true });
    }
  }, [
    fetchAccounts,
    fetchTicketHistory,
    isViewingHistory,
    isViewingTicketHistory,
  ]);

  return (
    <Component
      onAfterPrint={handleAfterPrint}
      suppressErrors
      onBeforePrint={() => setLoading(false)}
      {...props}
    >
      <Consumer>
        {({ handlePrint }) =>
          children({ triggerPrint: triggerPrint(handlePrint), loading })
        }
      </Consumer>
    </Component>
  );
};

export default memo(Print);
