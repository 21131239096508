import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import TradeTicketRequestsButton from 'components/Account/TradeTicketRequestsButton';
import RiskNumber from 'components/RiskNumber';
import {
  AccountType,
  AccountTypeType,
  AccountCustodianType,
} from 'context/fastContext/account/types';
import { MinProposal } from 'context/fastContext/proposal/types';

export const riskColors: { [key: number]: string } = {
  1: '#ADB2B0',
  2: '#CCFAE5',
  3: '#91E3C2',
  4: '#24B07A',
  5: '#1c7552',
  6: '#D5C9FE',
  7: '#A199DE',
  8: '#6449C0',
  9: '#FCC79E',
  10: '#D48A57',
};

function AccountRows({
  handleDeleteAccount,
  handleEditAccount,
  handleImplementAccount,
  handleTradeTicketRequest,
  accounts,
  accountTypes,
  allProposals,
  accountCustodians,
}: {
  handleDeleteAccount: any;
  handleEditAccount: any;
  handleImplementAccount: any;
  handleTradeTicketRequest: any;
  accounts: AccountType[];
  accountTypes: AccountTypeType[];
  allProposals: MinProposal[];
  accountCustodians: AccountCustodianType[];
}) {
  return (
    <Box sx={{ mt: '12px' }}>
      {accounts?.map(
        ({
          id,
          name,
          accountType,
          amountInvestedDollar,
          accountCustodian,
          proposalId,
        }) => {
          const associatedProposal = allProposals.find(
            (p) => p.id === proposalId,
          );

          const proposalStatus = associatedProposal?.status;
          const isActive = ['active', 'draft'].includes(proposalStatus!);
          const isPending = proposalStatus === 'pending';
          const isImplemented = proposalStatus === 'deployed';
          const accountTypeName = accountTypes.find(
            (at) => at.id === accountType,
          )?.name;
          const shortName = accountTypeName
            ?.replace(/\s*\(.*?\)\s*/g, '')
            .trim();

          const isCustodian = accountCustodians.find(
            (at) => at.id === accountCustodian,
          )?.skipApproval;

          return (
            <Box
              key={id}
              sx={(theme) => ({
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                marginBottom: '12px',
                padding: '16px 20px',
                borderRadius: '6px',
                gap: '8px',
                color: theme.palette.grey[900],
                border: `1px solid ${
                  isImplemented
                    ? theme.palette.primary.main
                    : theme.palette.grey[100]
                }`,
              })}
              data-testid="account-row"
            >
              {!isActive && (
                <Box
                  sx={(theme) => ({
                    position: 'absolute',
                    top: '-8px',
                    fontSize: '0.75rem',
                    borderRadius: '6px',
                    lineHeight: '0.875rem',
                    minWidth: '120px',
                    minHeight: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.common.white,
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    backgroundColor: isImplemented
                      ? theme.palette.primary.main
                      : theme.palette.grey[200],
                  })}
                >
                  {isImplemented ? 'Implemented' : proposalStatus}
                </Box>
              )}
              <Box
                sx={{
                  flex: 5,
                  display: 'flex',
                  '& > *': { alignItems: 'center', display: 'flex' },
                  justifyContent: 'center',
                }}
              >
                <Typography flex={9}>{name}</Typography>
                <Typography flex={7}>{shortName}</Typography>
                <Typography flex={5}>
                  ${Number(amountInvestedDollar).toLocaleString()}
                </Typography>
                <Typography flex={3}>
                  {associatedProposal?.allocations?.EQ}%
                </Typography>
                <Typography flex={3}>
                  {associatedProposal?.allocations?.BD}%
                </Typography>
                <Box
                  sx={{ display: 'flex', flex: 4, justifyContent: 'center' }}
                >
                  <RiskNumber number={associatedProposal?.riskScore!} />
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '6px',
                }}
              >
                {(isActive || isPending || isCustodian) && (
                  <>
                    <Tooltip title="Implement">
                      <Box>
                        <IconButton
                          sx={(theme) => ({
                            backgroundColor:
                              isPending || isImplemented
                                ? `${theme.palette.grey[100]} !important`
                                : theme.palette.primary[50],
                            color: theme.palette.primary.main,
                            borderRadius: '6px',
                            '&:hover': {
                              backgroundColor: theme.palette.primary[100],
                            },
                          })}
                          onClick={handleImplementAccount}
                          data-id={id}
                          disabled={isPending || isImplemented}
                        >
                          <RocketLaunchIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <Box>
                        <IconButton
                          sx={(theme) => ({
                            backgroundColor:
                              isPending || isImplemented
                                ? `${theme.palette.grey[100]} !important`
                                : theme.palette.primary[50],
                            color: theme.palette.primary.main,
                            borderRadius: '6px',
                            '&:hover': {
                              backgroundColor: theme.palette.primary[100],
                            },
                          })}
                          onClick={handleEditAccount}
                          data-id={id}
                          disabled={isPending || isImplemented}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Box>
                        <IconButton
                          sx={(theme) => ({
                            backgroundColor:
                              isPending || isImplemented
                                ? `${theme.palette.grey[100]} !important`
                                : 'rgba(211, 47, 47, 0.1)',
                            color: 'error.main',
                            borderRadius: '6px',
                            margin: 0,
                            '&:hover': {
                              backgroundColor: 'rgba(211, 47, 47, 0.2)',
                            },
                          })}
                          data-name={name}
                          data-id={id}
                          onClick={handleDeleteAccount}
                          disabled={isPending || isImplemented}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </>
                )}
                {isImplemented && !isCustodian && (
                  <TradeTicketRequestsButton
                    dataId={id}
                    handleTradeTicketRequest={handleTradeTicketRequest}
                  />
                )}
              </Box>
            </Box>
          );
        },
      )}
    </Box>
  );
}

export default AccountRows;
