import { FC } from 'react';

import { ReactComponent as ReloadIcon } from 'assets/svg/reload.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import FileIcon from 'components/FileIcon';

import {
  Container,
  Failed,
  Loader,
  Name,
  Remove,
  RemoveFailed,
  RetryUpload,
  Right,
} from './styles';
import { Props } from './types';

const FileRow: FC<Props> = ({ fileItem, onRemove, onRetryUpload }) => {
  const { error, file, id, loading } = fileItem;
  if (loading)
    return (
      <Container>
        <Loader />
        <Name>{fileItem.file.name}</Name>
      </Container>
    );
  return (
    <Container failedUpload={!!error}>
      {error ? (
        <>
          {!!file.type && <FileIcon fileType={file.type} />}
          <Name>{fileItem.file.name}</Name>
          <Right>
            <Failed>Upload failed</Failed>
            <RetryUpload
              variant="transparent"
              onClick={onRetryUpload ? () => onRetryUpload(id) : undefined}
              icon={<ReloadIcon />}
              title="Retry upload"
            />
            <RemoveFailed
              variant="transparent"
              onClick={() => onRemove(id)}
              icon={<TrashIcon />}
              title="Remove file"
            />
          </Right>
        </>
      ) : (
        <>
          {!!file.type && <FileIcon fileType={file.type} />}
          <Name>{file.name}</Name>
          <Remove
            variant="transparent"
            onClick={() => onRemove(id)}
            icon={<TrashIcon />}
            title="Remove file"
          />
        </>
      )}
    </Container>
  );
};

export default FileRow;
