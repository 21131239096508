/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Modal from 'components/NewModal';

function ConfirmDeleteModal({
  isOpen,
  setShowConfirmDelete,
  confirmDelete,
  confirmErrors,
  setConfirmErrors,
}: any) {
  const handleClose = () => setShowConfirmDelete(false);
  const handleConfirm = async () => {
    try {
      await confirmDelete();
    } catch (error) {
      setConfirmErrors('Failed to delete the portfolio.');
    }
  };

  return (
    <Modal
      transitionSpeed="0.3s"
      sx={{ maxWidth: '350px' }}
      isOpen={isOpen}
      close={handleClose}
    >
      <Box mb="16px">
        <Typography>
          Are you sure you want to delete your existing saved Current Portfolio?
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleConfirm}
          color="error"
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            minWidth: '120px',
          }}
          variant="contained"
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            minWidth: '120px',
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
      {confirmErrors && (
        <Box sx={{ marginTop: '12px', fontSize: '14px', color: 'error.main' }}>
          {confirmErrors}
        </Box>
      )}
    </Modal>
  );
}

export default ConfirmDeleteModal;
