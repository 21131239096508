import { Field } from 'formik';
import { FC } from 'react';

import Checkbox from 'components/Checkbox';

import { Container, Label, Wrapper } from './styles';
import { Props } from './types';

const CheckboxControl: FC<Props> = ({
  className,
  id,
  label,
  name,
  checked,
  disabled,
  ...props
}) => (
  <Container htmlFor={disabled ? '' : id} className={className}>
    <Wrapper>
      <Field
        as={Checkbox}
        checked={checked}
        id={id}
        name={name}
        disabled={disabled}
        {...props}
      />
    </Wrapper>
    <Label checked={checked}>{label}</Label>
  </Container>
);

export default CheckboxControl;
