/* eslint-disable react/no-danger */
import { Formik, Form, Field } from 'formik';
import generateRange from 'lodash/range';
import { FC, SyntheticEvent, useMemo } from 'react';

import RatingInput from 'components/RatingInput';
import { Description, Hint } from 'components/SurveyCard/styles';

import { Props } from './types';

const Range: FC<Props> = ({
  extraDescription,
  description = '',
  ranges,
  onAnswer,
  initialValue,
  colorized,
  readOnly,
}) => {
  const { max, min } = ranges[0];

  const options = useMemo(
    () =>
      generateRange(min, max + 1).map((option) => ({
        label: option.toString(),
        id: option.toString(),
        rateValue: option,
      })),
    [max, min],
  );

  return (
    <>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Description>

      {extraDescription && (
        <>
          {' '}
          <Hint dangerouslySetInnerHTML={{ __html: extraDescription }} />
        </>
      )}
      <Formik
        initialValues={{ selected: initialValue as number }}
        onSubmit={({ selected }) => onAnswer(selected)}
      >
        {({ handleChange, submitForm }) => (
          <Form>
            <Field
              as={RatingInput}
              colorized={colorized}
              initialValue={initialValue}
              name="selected"
              options={options}
              readOnly={readOnly}
              onChange={(e: SyntheticEvent) => {
                handleChange(e);
                submitForm();
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Range;
