import { memo } from 'react';

import Select from 'components/Select';

import useConnect from './connect';
import { Props } from './types';

const AdvisorPicker = ({ disabled, ...props }: Props) => {
  const { advisors, loading, sortFunction } = useConnect();

  return (
    <Select disabled={disabled || loading} {...props}>
      <option value="">Choose an advisor...</option>
      {advisors.sort(sortFunction).map(({ id, fullName }) => (
        <option key={id} value={id}>
          {fullName}
        </option>
      ))}
    </Select>
  );
};

export default memo(AdvisorPicker);
