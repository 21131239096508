import { format, isValid } from 'date-fns';
import { FC, memo } from 'react';

import {
  Container,
  BirthDate,
  Email,
  PhoneWrapper,
  Phone,
  Primary,
} from './styles';
import { Props } from './types';

const ContactInfo: FC<Props> = ({ birthDate, email, phone, ...props }) => {
  return (
    <Container {...props}>
      <BirthDate>
        {isValid(birthDate) ? format(birthDate, 'MM/d/yyyy') : ''}
      </BirthDate>
      <Email>{email}</Email>
      {phone ? (
        <PhoneWrapper>
          <Phone>{phone}</Phone>
          <Primary>(Primary)</Primary>
        </PhoneWrapper>
      ) : null}
    </Container>
  );
};

export default memo(ContactInfo);
