import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';

import usePortfolioActions from 'context/fastContext/portfolio/actions';

import { FormValues } from '../types';
import { Props } from './types';

const useConnect = ({
  portfolioHasBeenClearedRef,
}: Pick<Props, 'portfolioHasBeenClearedRef'>) => {
  const { errors, values, setErrors, resetForm, dirty, isSubmitting } =
    useFormikContext<FormValues>();
  const { resetPortfolioStore } = usePortfolioActions();

  useEffect(() => {
    if (portfolioHasBeenClearedRef.current && values?.assets?.length) {
      portfolioHasBeenClearedRef.current = false;
    }
  }, [values, portfolioHasBeenClearedRef]);

  const handleClear = useCallback(() => {
    window.scrollTo(0, 0);
    // TODO: remove this portfolioHasBeenCreatedRef
    portfolioHasBeenClearedRef.current = false;
    resetForm({ values: { assets: [], valueType: 'MARKET_VALUE' } });
    resetPortfolioStore();
    setTimeout(() => setErrors({}), 0);
  }, [setErrors, resetForm, portfolioHasBeenClearedRef, resetPortfolioStore]);

  return { errors, handleClear, dirty, isSubmitting };
};

export default useConnect;
