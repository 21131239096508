import { ScrollToTopOnMount } from 'components/ScrollToTopOnMount';
import StaticPageCard, {
  Bold,
  ContactUs,
  Text,
  Title,
} from 'components/StaticPageCard';

const Disclosures = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <StaticPageCard title="Disclosures">
        <Title>Important disclaimers</Title>
        <Text>
          Seeds Investor LLC ( Seeds LLC (&quot;Seeds&quot;) is a Registered
          Investment Advisor (&quot;RIA&quot;), located in the State of New
          York. Seeds provides investment advisory and related services for
          clients nationally. Seeds will maintain all applicable registration
          and licenses as required by the various states in which Seeds conducts
          business, as applicable. Seeds renders individualized responses to
          persons in a particular state only after complying with all regulatory
          requirements, or pursuant to an applicable state exemption or
          exclusion.
        </Text>
        <Title>Client information</Title>
        <Text>
          The information contained in this dashboard is for private and
          confidential use only, and not intended for broad usage or
          dissemination.
          <br />
          <br />
          Information on Current Holdings that are not managed by the advisor,
          if any, have been provided by you (either directly or through
          Portfolio Upload) and has not been verified by your advisor. Any
          information in this report should not be used as a substitute for the
          custodian&apos;s statements and trade confirmations and it may not be
          relied upon for valuation or performance.
        </Text>
        <Title>Portfolio Insights</Title>
        <Text>
          This dashboard was created to illustrate style information (i.e.,
          holdings and returns) that is representative of Seeds account[s] and
          is not intended to be relied upon as a forecast, research or
          investment advice. Please contact your advisor or existing custodian
          for your actual holdings, returns, as well as the reporting of all
          balances and securities transactions. Past performance is no guarantee
          of future returns.
          <br />
          <br />
          The dashboard may include “Your Investor Profile” that is intended for
          illustrative purposes only and is not intended to be relied on as
          investment advice and may not be an accurate representation of an
          individual client.
        </Text>
        <Title>Values & Risk Scoring Methodology</Title>
        <Text>
          Seeds assesses companies and portfolios based on risk exposures
          related to earth, people, and corporate integrity (&quot;EPI&quot;)
          issues. The way in which an individual investor prioritizes personal
          values, as measured by EPI values, help to determine which holdings
          are included in a portfolio.
          <br />
          <br />
          Regardless of an individual investor&apos;s values priorities, Seeds
          strives to construct portfolios of companies that have demonstrated
          superior EPI leadership with distinct and measurable risk management
          most relevant to a company&apos;s business model (i.e. data privacy
          for a technology company or bank).
          <br />
          <br />
          Seeds&apos; scores of holdings and portfolios seek to represent the
          high-level effect a company has on the world, represented as either
          value scores of &quot;Ideal,&quot; &quot;Beneficial,&quot;
          &quot;Neutral,&quot; &quot;Harmful,&quot; and &quot;Dangerous,&quot;
          which correlate to a 1 through 5 seed score, or inversely, as a Risk
          Score. The scores result from Seeds&apos; proprietary assessment of
          EPI risk exposures based on third-party data providers. See below for
          more information.
          <br />
          <br />
          Past performance is no guarantee of future returns. Seeds uses a
          proprietary investment process based on EPI rankings and cannot
          guarantee that portfolios will fully reflect the personal values of an
          investor and there is no guarantee of any specific outcome of a
          portfolio.
          <br />
          <br />
          Part of this publication may contain proprietary information from
          third party data providers, including but not limited to FactSet,
          Sustainalytics, and Morningstar (or collectively “Data Providers”)
          that may not be reproduced, used, disseminated, modified nor published
          in any manner without the express written consent of the Data
          Providers. Nothing contained in this publication shall be construed as
          to make a representation or warranty, express or implied, regarding
          the advisability to invest in or include companies in investable
          universes and/or portfolios. The information is provided &quot;as
          is&quot; and, therefore the Data Providers assume no responsibility
          for errors or omissions. The Data Providers cannot be held liable for
          damage arising from the use of this publication or information
          contained herein in any manner whatsoever.
          <br />
          <br />
          The Insights Snapshot section highlights representative holdings
          intended for illustrative purposes only and is not intended to be
          relied on as investment advice and may not be an accurate
          representation of an individual portfolio.{' '}
          <Bold>
            Such material is neither intended, nor approved for, additional
            distribution, and its inclusion herein is not to be regarded as
            sales and marketing literature for any investment product.
          </Bold>
        </Text>
        <Title>Risk Disclosure</Title>
        <Text>
          <Bold>
            Different types of investments involve varying degrees of risk.
            Therefore, it should not be assumed that future performance of any
            specific investment or investment strategy will be profitable.
          </Bold>
          <br />
          <br />
          Asset allocation may be used in an effort to manage risk and enhance
          returns. It does not, however, guarantee a profit or protect against
          loss. Performance of the asset allocation strategies depends on the
          underlying investments.
          <br />
          <br />
          This dashboard is intended to provide general information about your
          Seeds account[s]. It is not intended to offer or deliver investment
          advice in any way.
          <br />
          <br />
          Please remember that it remains your responsibility to contact your
          advisor if there are any changes in your personal/financial situation
          or investment objectives for the purpose of
          reviewing/evaluating/revising previous recommendations and/or
          services, if you would like to impose, add, or to modify any
          reasonable restrictions.
        </Text>
        <Title>Privacy Disclosures</Title>
        <Text>
          Seeds is committed to safeguarding the use of personal information of
          all users of the dashboard that we obtain as your Investment Advisor,
          as described in our Privacy Policy. Seeds does not collect personal
          non-public information through this dashboard; however, your advisor
          may collect information from you on application forms, agreements,
          profile or investment policy statements, and other documents received
          or processed in relation to services. We do not respond to &quot;do
          not track&quot; requests because we do not track you over time or
          across third party websites to provide targeted advertising. We may
          track you across our dashboard to help us improve our content.
        </Text>
        <Title>Email Disclosures</Title>
        <Text>
          Seeds communicates with dashboard users, clients and prospective
          clients through electronic mail (&quot;email&quot;) and other
          electronic means. Your privacy and security are very important to us.
          Seeds makes every effort to ensure that email communications do not
          contain sensitive information. We remind dashboard users and others
          not to send Seeds private information over email. If you have
          sensitive data to deliver, we can provide secure means for such
          delivery.
          <br />
          <br />
          <Bold>
            Please note: Seeds does not accept money movement instructions via
            email.
          </Bold>
          <br />
          <br />
          As a registered investment advisor, Seeds emails may be subject to
          inspection by the Chief Compliance Officer (“CCO”) of Seeds or the
          securities regulators.
          <br />
          <br />
          If you have received an email from Seeds in error, we ask that you
          contact the sender and destroy the email and its contents.
          <br />
          <br />
          If you have any questions regarding our email policies, please{' '}
          <ContactUs href="mailto:support@seedsinvestor.com">
            Contact Us
          </ContactUs>
          .
        </Text>
      </StaticPageCard>
    </>
  );
};

export default Disclosures;
