import { ReactComponent as Earth } from 'assets/svg/earth.svg';
import { ReactComponent as Integrity } from 'assets/svg/legal-scale-unequal.svg';
import { ReactComponent as People } from 'assets/svg/multiple-circle.svg';

import { Container } from './styles';
import { Props } from './types';

export function CategoryTitle({ title, className }: Props) {
  const Icon = (() => {
    switch (title) {
      case 'Earth':
        return Earth;
      case 'People':
        return People;
      case 'Integrity':
        return Integrity;
      default:
        return null;
    }
  })();
  return (
    <Container className={className}>
      <Icon />
      {title}
    </Container>
  );
}
