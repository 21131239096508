import Alert from '@mui/material/Alert';
import { forwardRef, useMemo } from 'react';

import AccountProposalDisplay from 'components/Account/AccountProposalDisplay';
import { Chart as FinancialGoalsChart } from 'components/FinancialGoals/styles';
import FinancialGoalsResult from 'components/FinancialGoalsResult';
import StockStories from 'components/ImpactSnapshot/StockStories';
import Level from 'components/Level';
import RatingInput, { RatingOptions } from 'components/RatingInput';
import Values from 'components/Values';
import ValueScore from 'components/ValueScore';
import { ItemDescriptionWrapper } from 'containers/Main/Clients/ClientsDetail/ClientsDetailRoot/styles';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import { AccountState } from 'context/fastContext/account/types';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { ProposalState } from 'context/fastContext/proposal/types';
import levelsHelper from 'utils/proposalHelpers/levels';
import {
  colorSchema,
  getTooltipMessage,
} from 'v2/components/Chart/PieChart/Tooltip';
import {
  RiskCirlce,
  RiskContainer,
  RiskHeader,
  RiskWrapper,
} from 'v2/components/Chart/PieChart/Tooltip/styles';
import Sectors from 'v2/components/Sectors';

import useConnect from './connect';
import HoldingRow from './HoldingRow';
import HoldingsHeader from './HoldingsHeader';
import {
  epiToLongSentence,
  epiToShortSentence,
  humanizeTimeHorizon,
} from './logic';
import Page from './Page';
import {
  Block,
  BlockParagraph,
  Bold,
  ChartsContainer,
  ChartsSectionTitle,
  Container,
  Description,
  Disclosures,
  GoalsResultsWrapper,
  Hidden,
  ItemDescription,
  MediaContainer,
  ModuleCompact,
  ModuleItemContainer,
  ModuleItemsWrapper,
  PersonalityProfile,
  Result,
  ResultTitle,
  SectionModuleTitle,
  SectionTitle,
  Title,
  ValuesContainer,
} from './styles';

// THIS IS DOWNLOAD PROPOSAL
const Proposal = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    chartData,
    dontRecommend,
    files,
    folders,
    goalsResults,
    holdingsData,
    investorInfo,
    me,
    totalPages,
  } = useConnect();

  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));

  const [{ isLoadingProposalData, score, proposalState }] = useProposalStore(
    (s: ProposalState) => ({
      isLoadingProposalData: s.isLoadingProposalData,
      score: s.masterProposal.score,
      proposalState: s.proposalState,
    }),
  );

  const [{ isValuesDriven, riskLevel, normalizedEpiPriority, status }] =
    useInvestorStore((s: any) => ({
      isValuesDriven: s.profile?.isValuesDriven,
      riskLevel: s.profile?.riskLevel,
      normalizedEpiPriority: s.profile?.normalizedEpiPriority,
      status: s.profile?.status,
    }));

  const [{ accounts, isViewingHistory }] = useAccountStore(
    (s: AccountState) => ({
      accounts: s.accounts,
      isViewingHistory: s.isViewingHistory,
    }),
  );

  const profileIsComplete = useMemo(() => status === 'COMPLETE', [status]);

  const maxValue = useMemo(() => {
    const maxFirst = chartData.current.sectors
      ? Math.max(...chartData.current.sectors.map((d) => d.value))
      : 0;
    const maxSecond = chartData.seeds.sectors
      ? Math.max(...chartData.seeds.sectors.map((d) => d.value))
      : 0;

    return Math.max(maxFirst, maxSecond);
  }, [chartData]);

  // stock stories
  const stockStoriesFolderId = folders.find(
    (folder) => folder.name === 'Stock Stories' && folder.parent === null,
  )?.id;

  const stockStoriesFiles = files.filter(
    (file) => file.folder === stockStoriesFolderId,
  );

  const levels = levelsHelper({
    currentPortfolio: currentPortfolio?.score,
    seedsPortfolio: score,
  });

  const seedsProposalScore = Math.round(score?.valueScore || 0);

  const hasUploadedPortfolio =
    currentPortfolio && currentPortfolio.holdings?.length > 0;

  const isProposed = !investorInfo?.seedsInvested;

  const isAssessmentAvailable = ['NOT_SENT', 'NOT_SET', 'SENT'].includes(
    investorInfo?.assessmentStatus || 'NOT_SET',
  );

  const accountsImplemented = proposalState === 'IMPLEMENTED';

  const companyLogo = useMemo(
    () =>
      me && 'advisor' in me && me?.advisor?.company
        ? me.advisor.companyLogo
        : investorInfo?.advisor?.company?.logo,
    [me, investorInfo?.advisor?.company?.logo],
  );

  const isAdvisorOrInvestor = useMemo(
    () => me && (me.role === 'ADVISOR' || me.role === 'INVESTOR'),
    [me],
  );

  const displayStockStories = useMemo(
    () => isValuesDriven && !isViewingHistory,
    [isValuesDriven, isViewingHistory],
  );

  if (isLoadingProposalData && !investorInfo) return null;

  const actualTotalPages: number = displayStockStories
    ? totalPages
    : totalPages - 1;

  const hasCompanyLogo = isAdvisorOrInvestor ? companyLogo : null;

  const hasMissingTickers =
    currentPortfolio?.missingTickers &&
    currentPortfolio?.missingTickers.length > 0;

  const formatValue = (value: number) => {
    const formattedValue = parseFloat(value.toFixed(2));
    return formattedValue > 0 ? formattedValue : '0.00';
  };

  return (
    <Hidden>
      <Container ref={ref}>
        <Page
          page={1}
          totalPages={actualTotalPages}
          companyLogo={hasCompanyLogo}
        >
          <Title>{investorInfo?.household?.name} Portfolio Proposal</Title>
          <SectionTitle>Here is what matters to you</SectionTitle>
          <PersonalityProfile isProposal />
          {riskLevel ? (
            <Result>
              <ResultTitle>RISK TARGET</ResultTitle>
              <RatingInput
                name="RiskTolerance"
                // @ts-ignore
                initialValue={riskLevel || 1}
                // @ts-ignore
                value={riskLevel || 1}
                options={RatingOptions}
                readOnly
                colorized
              />
              <RiskWrapper>
                <RiskHeader>Risk Score</RiskHeader>
                <RiskContainer>
                  {colorSchema.map((circleColor: any, idx: any) => {
                    const isSelected = riskLevel === idx + 1;
                    const size = isSelected ? 2 : 1.5;
                    return (
                      <RiskCirlce
                        key={circleColor}
                        className="circle"
                        style={{
                          backgroundColor: circleColor,
                          width: `${size}rem`,
                          height: `${size}rem`,
                          lineHeight: `${size}rem`,
                          fontSize: isSelected ? '14px' : '10px',
                          color: 'white',
                        }}
                      >
                        {isSelected ? riskLevel : ''}
                      </RiskCirlce>
                    );
                  })}
                </RiskContainer>
                <span>{getTooltipMessage(riskLevel || 1)}</span>
              </RiskWrapper>
            </Result>
          ) : null}
          {profileIsComplete ? (
            <Result>
              <ResultTitle>VALUES</ResultTitle>
              {isValuesDriven ? (
                <Values showBreakdownByDefault />
              ) : (
                <BlockParagraph>No Preference</BlockParagraph>
              )}
            </Result>
          ) : null}
        </Page>
        <Page
          page={2}
          totalPages={actualTotalPages}
          companyLogo={hasCompanyLogo}
        >
          {goalsResults.length &&
          investorInfo?.surveyResult?.riskTolerance &&
          investorInfo?.surveyResult?.timeHorizon ? (
            <Result>
              <ResultTitle>FINANCIAL GOALS</ResultTitle>
              <GoalsResultsWrapper>
                {goalsResults.map(({ goal, value }) =>
                  value ? (
                    <FinancialGoalsResult
                      key={goal}
                      goal={goal}
                      value={value}
                    />
                  ) : null,
                )}
              </GoalsResultsWrapper>
            </Result>
          ) : null}
          {investorInfo?.surveyResult?.timeHorizon &&
          investorInfo?.surveyResult.riskTolerance ? (
            <Block>
              <BlockParagraph>
                You have{' '}
                <Bold>
                  {humanizeTimeHorizon(
                    investorInfo?.surveyResult.timeHorizon
                      .split('-')
                      .map(Number),
                  )}
                </Bold>{' '}
                before you need to use your portfolio assets, and you’re willing
                to take{' '}
                <Bold>
                  {investorInfo?.surveyResult.riskTolerance.toLowerCase()}
                </Bold>{' '}
                risk.
              </BlockParagraph>
              <BlockParagraph>
                You also care most about the{' '}
                <Bold>{epiToLongSentence(normalizedEpiPriority?.[0]!)}</Bold>{' '}
                followed by{' '}
                <Bold>{epiToLongSentence(normalizedEpiPriority?.[1]!)}</Bold>{' '}
                and the{' '}
                <Bold>{epiToLongSentence(normalizedEpiPriority?.[2]!)}</Bold>.
              </BlockParagraph>
              {dontRecommend ? (
                <BlockParagraph>
                  For now, we don&apos;t recommend investing in the market since
                  you don&apos;t have much time before you&apos;ll need to use
                  your money and you&apos;re not comfortable taking on risk.
                </BlockParagraph>
              ) : null}
            </Block>
          ) : null}
          <ModuleCompact>
            <ChartsSectionTitle>
              Here is your proposed portfolio
            </ChartsSectionTitle>
            <ChartsContainer>
              {hasUploadedPortfolio && (
                <ModuleItemContainer>
                  <SectionModuleTitle>Current portfolio</SectionModuleTitle>
                  <Description>
                    <span style={{ fontWeight: 700 }}>
                      Asset Class Breakdown:
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {formatValue(currentPortfolio?.score?.equities || 0)}%
                    </span>{' '}
                    Equity{', '}
                    <span style={{ fontWeight: 700 }}>
                      {formatValue(currentPortfolio?.score?.fixed || 0)}%
                    </span>{' '}
                    Fixed{' and '}
                    <span style={{ fontWeight: 700 }}>
                      {formatValue(0)}%
                    </span>{' '}
                    Alternatives.
                  </Description>
                  {chartData.current.sectors && chartData.current.pie ? (
                    <FinancialGoalsChart
                      data={chartData.current.sectors}
                      pieData={chartData.current.pie}
                      extended={false}
                      dataShown="current"
                    />
                  ) : null}
                  {hasMissingTickers && (
                    <Alert
                      severity="warning"
                      sx={(theme) => ({
                        borderRadius: '6px',
                        color: theme.palette.warning.main,
                        bgcolor: theme.palette.warning.main[100],
                        padding: '0 1rem',
                      })}
                    >
                      Current Portfolio Missing Assets:{' '}
                      {`${currentPortfolio?.missingTickers.join(', ')}`}
                    </Alert>
                  )}
                </ModuleItemContainer>
              )}
              <ModuleItemContainer>
                <SectionModuleTitle>Proposed Portfolio</SectionModuleTitle>
                <Description>
                  <span style={{ fontWeight: 700 }}>
                    Asset Class Breakdown:
                  </span>
                  <br />
                  <span style={{ fontWeight: 700 }}>
                    {formatValue(chartData.seeds?.allocations?.equity || 0)}%
                  </span>{' '}
                  Equity{', '}
                  <span style={{ fontWeight: 700 }}>
                    {formatValue(chartData.seeds?.allocations?.fixed || 0)}%
                  </span>{' '}
                  Fixed{' and '}
                  <span style={{ fontWeight: 700 }}>
                    {formatValue(
                      chartData.seeds?.allocations?.alternatives || 0,
                    )}
                    %
                  </span>{' '}
                  Alternatives.
                </Description>
                {chartData.seeds.breakdown && chartData.seeds.pie ? (
                  <FinancialGoalsChart
                    data={chartData.seeds.breakdown}
                    pieData={chartData.seeds.pie}
                    extended={false}
                    dataShown="seeds"
                  />
                ) : null}
              </ModuleItemContainer>

              {(!currentPortfolio || // Very weird styling issue if there are not 2 FinancialGoalsCharts...
                currentPortfolio?.holdings?.length === 0) && (
                <div style={{ visibility: 'hidden' }}>
                  <FinancialGoalsChart
                    data={[]}
                    pieData={chartData.current.pie}
                    extended={false}
                    dataShown="current"
                  />
                </div>
              )}
            </ChartsContainer>
            <ItemDescriptionWrapper>
              <ItemDescription>
                Industry classification is provided via FactSet data.
                Occasionally, positions in the portfolio are not recognized and
                are excluded from the visual representation. Sector weights
                represent only the equity portion of the portfolio.
              </ItemDescription>
            </ItemDescriptionWrapper>
          </ModuleCompact>
          {accounts.length > 1 && (
            <ModuleCompact style={{ marginTop: '12px' }}>
              <SectionTitle>Accounts</SectionTitle>
              <AccountProposalDisplay />
            </ModuleCompact>
          )}
        </Page>
        <Page
          page={3}
          totalPages={actualTotalPages}
          companyLogo={hasCompanyLogo}
        >
          {isValuesDriven && (
            <>
              <ModuleCompact>
                <SectionTitle>Values</SectionTitle>
                {hasUploadedPortfolio && (
                  <ModuleItemsWrapper>
                    <ModuleItemContainer>
                      <SectionModuleTitle>Current Portfolio</SectionModuleTitle>
                      <Description>
                        Overall your portfolio has a{' '}
                        <ValueScore
                          value={currentPortfolio?.score?.valueScore}
                        />{' '}
                        on the world.
                      </Description>
                      <ValuesContainer>
                        {levels.current?.map((level) => (
                          <Level
                            key={level.title}
                            title={level.title}
                            completePercentage={level.percentage}
                          />
                        ))}
                      </ValuesContainer>
                    </ModuleItemContainer>
                  </ModuleItemsWrapper>
                )}
                <ModuleItemsWrapper>
                  <ModuleItemContainer>
                    <SectionModuleTitle>Proposed Portfolio</SectionModuleTitle>
                    <Description>
                      Overall your recommended portfolio has a{' '}
                      <ValueScore value={seedsProposalScore} /> on the world.
                    </Description>
                    <ValuesContainer>
                      {levels.recommended?.map((level) => (
                        <Level
                          key={level.title}
                          title={level.title}
                          completePercentage={level.percentage}
                        />
                      ))}
                    </ValuesContainer>
                  </ModuleItemContainer>
                </ModuleItemsWrapper>
              </ModuleCompact>
              <Block>
                <BlockParagraph>
                  Your portfolio takes the appropiate risk and is ideal for
                  earth, people and the integrity of corporations.
                </BlockParagraph>
                {normalizedEpiPriority?.length ? (
                  <BlockParagraph>
                    Your portfolio aligns with your values by prioritizing the{' '}
                    <Bold>
                      {epiToShortSentence(normalizedEpiPriority?.[0]!)}
                    </Bold>{' '}
                    issues, followed by{' '}
                    <Bold>
                      {epiToShortSentence(normalizedEpiPriority?.[1]!)}
                    </Bold>{' '}
                    issues and{' '}
                    <Bold>
                      {epiToShortSentence(normalizedEpiPriority?.[2]!)}
                    </Bold>{' '}
                    issues.
                  </BlockParagraph>
                ) : null}
              </Block>
              <ItemDescription>
                Portfolios assessments based on a proprietary methodology using
                third-party &quot;EPI&quot; factor data. See disclosures for
                more detail.
                <br />
                <br />
                The Earth, People, and Integrity (&apos;EPI&apos;) ratings shown
                above may default to the headline values score, as individual
                EPI factor data is not available for certain funds and
                individual holdings.
              </ItemDescription>
            </>
          )}
          <div style={{ marginTop: '2.5rem' }}>
            <SectionTitle>Exposures</SectionTitle>
            <ModuleItemsWrapper>
              <MediaContainer>
                {hasUploadedPortfolio && !accountsImplemented && (
                  <ModuleItemContainer>
                    <SectionModuleTitle>Current Sectors</SectionModuleTitle>
                    <Description />
                    <Sectors
                      chartData={chartData.current?.sectors}
                      maxValue={maxValue}
                      printable
                    />
                  </ModuleItemContainer>
                )}
                {!isAssessmentAvailable && (
                  <ModuleItemContainer>
                    <SectionModuleTitle>
                      {accountsImplemented
                        ? 'Portfolio Sectors'
                        : 'Proposed Sectors'}
                    </SectionModuleTitle>
                    <Description />
                    <Sectors
                      chartData={chartData.seeds?.sectors}
                      maxValue={maxValue}
                      printable
                    />
                  </ModuleItemContainer>
                )}
              </MediaContainer>
            </ModuleItemsWrapper>
          </div>
        </Page>
        {displayStockStories && (
          <Page
            page={4}
            totalPages={actualTotalPages}
            companyLogo={hasCompanyLogo}
          >
            <SectionTitle>
              Insights Snapshot
              {isProposed && !accountsImplemented ? ` (Proposed)` : null}
            </SectionTitle>
            {stockStoriesFiles.length > 0 ? (
              <StockStories files={stockStoriesFiles} />
            ) : (
              <Description>No data yet.</Description>
            )}
          </Page>
        )}

        {holdingsData.map((chunk, index) => (
          <Page
            page={index + (displayStockStories ? 5 : 4)}
            totalPages={actualTotalPages}
            companyLogo={hasCompanyLogo}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <HoldingsHeader isValuesDriven={isValuesDriven} />
            {chunk.map(
              ({ id, actualWeight, asset: { name, scoreValue, sector } }) =>
                actualWeight ? (
                  <HoldingRow
                    key={id}
                    name={name}
                    percentage={actualWeight}
                    score={isValuesDriven ? scoreValue : null}
                    sector={sector}
                  />
                ) : null,
            )}
          </Page>
        ))}
        <Page
          page={actualTotalPages}
          totalPages={actualTotalPages}
          companyLogo={hasCompanyLogo}
        >
          <Disclosures>
            <p>
              Seeds Investor LLC ( Seeds Investor LLC (&apos;Seeds&apos;) is a
              Registered Investment Advisor (&apos;RIA&apos;), located in the
              State of New York. Advisory services are only offered to clients
              or prospective clients where Seeds and its representatives are
              properly licensed or exempt from licensure.
            </p>
            <p>
              Although this material is based upon information the advisor
              considers reliable and endeavors to keep current, the advisor does
              not assure that this material is accurate, current, or complete,
              and it should not be relied upon as such. No investment strategy
              or risk management technique can guarantee returns or eliminate
              risk in any market environment. All investments include a risk of
              loss that clients should be prepared to bear. The principal risks
              of the advisor’s investment services are disclosed in the publicly
              available Form ADV Part 2A.
            </p>
            <p>
              Value Ranking Methodology: Seeds assesses companies and portfolios
              based on risk exposures related to earth, people, and corporate
              integrity (&apos;EPI&apos;) issues. The way in which an individual
              investor prioritizes personal values, as measured by EPI values,
              help to determine which holdings are included in the portfolio.
              Regardless of an individual investor’s values priorities, Seeds
              strives to construct portfolios of companies that have
              demonstrated superior EPI leadership with distinct and measurable
              risk management most relevant to a company’s business model (i.e.
              data privacy for a technology company or bank). Seeds’ scores of
              holdings and portfolios seek to represent the high-level effect a
              company has on the world, represented as value scores of
              &apos;Ideal&apos;, &apos;Beneficial&apos;, &apos;Neutral&apos;,
              &apos;Harmful&apos;, and &apos;Dangerous&apos;, which correlate to
              a 1 through 5 seed score. The scores result from Seeds’
              proprietary assessment of EPI risk exposures based on third-party
              data, including data from Sustainalytics (see below).
            </p>
            <p>
              Part of this publication may contain proprietary information from
              third party data providers, including but not limited to
              Sustainalytics and AllianceBernstein L.P. (&quot;AB&quot;) (or
              collectively &quot;Data Providers&quot;) that may not be
              reproduced, used, disseminated, modified nor published in any
              manner without the express written consent of the Data Providers.
              Nothing contained in this publication shall be construed as to
              make a representation or warranty, express or implied, regarding
              the advisability to invest in or include companies in investable
              universes and/or portfolios. The information is provided &quot;as
              is&quot; and, therefore the Data Providers assume no
              responsibility for errors or omissions. The Data Providers can not
              be held liable for damage arising from the use of this publication
              or information contained herein in any manner whatsoever.
            </p>
            <p>
              Municipal Bonds represent an allocation to AllianceBernstein
              Municipal Impact Fixed Income managed by AllianceBernsteinL.P.
              (“AB”). The Insights Snapshot: Municipals” section highlights
              representative holdings intended for illustrative purposes only
              and is not intended to be relied on as investment advice and may
              not be an accurate representation of an individual portfolio. Such
              material is neither intended, nor approved for, additional
              distribution, and its inclusion herein is not to be regarded as
              sales and marketing literature for any AB (or its applicable
              affiliate providing this publication) investment product. AB
              specifically disclaims any responsibility for any use of the
              information contained herein by any third party. In preparing this
              proposal, we have obtained information from sources we believe to
              be reliable, but do not offer any guarantees as to its accuracy or
              completeness. Neither this proposal nor any of its contents may be
              used for any purpose without the consent of AB.
            </p>
            <p>
              This document is for your private and confidential use only, and
              not intended for broad usage or dissemination. Past performance is
              no guarantee of future returns. Seeds uses a proprietary
              investment process based on EPI rankings and can not guarantee
              that portfolios will fully reflect the personal values of an
              investor and there is no guarantee of any specific outcome of a
              portfolio. Nothing contained in this publication shall be
              construed as to make a representation or warranty, express or
              implied, regarding the advisability to invest in or include
              companies in investable universes and/or portfolios. If you have
              any questions regarding this report, please contact Seeds at{' '}
              <Bold>support@seedsinvestor.com</Bold>
            </p>
          </Disclosures>
        </Page>
      </Container>
    </Hidden>
  );
});

export default Proposal;
