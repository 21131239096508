import { FC, memo } from 'react';

import useLogic from './logic';
import {
  Container,
  Input as StyledInput,
  LeftIconWrapper,
  RightIconWrapper,
} from './styles';
import { Props } from './types';

const InputBox: FC<Props> = ({
  className,
  error,
  leftIcon = null,
  rightIcon = null,
  placeholder,
  disabled,
  ...props
}) => {
  const { handle, inputRef } = useLogic();

  return (
    <Container
      className={className}
      onClick={handle.focus}
      $isDisabled={disabled}
      $hasError={error}
    >
      {!!leftIcon && <LeftIconWrapper>{leftIcon}</LeftIconWrapper>}
      <StyledInput
        placeholder={placeholder}
        ref={inputRef}
        disabled={disabled}
        {...props}
      />
      {!!rightIcon && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
    </Container>
  );
};

export default memo(InputBox);
