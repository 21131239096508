import styled from 'styled-components';

import { from } from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 1.5rem;

  @media print {
    gap: 1.25rem;
    margin-top: 0.5rem;
  }
`;

export const Module = styled.div`
  margin: 2.5rem 0;

  @media print {
    margin: 1.5rem 0;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 24);

  @media print {
    font-weight: ${({ theme }) => theme.weights.bold};
    font-size: 1.3125rem;
    line-height: calc(30 / 21);
  }
`;

export const ModuleTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.44;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ModuleDescription = styled.p`
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(26 / 16);
  margin: 0.5rem 0 1rem 0;
  color: ${({ theme }) => theme.colors.grey600};

  @media print {
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.grey200};
  margin: 2rem 0;
  border: 0;
  height: 1px;

  @media print {
    margin: 1rem 0;
  }
`;

export const ModuleItemContainer = styled.div`
  ${from.tablet} {
    & + & {
      margin-left: 1.875rem;
    }
  }
`;

export const ModuleInfo = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey600};
  line-height: 1.42;
  margin-top: 2rem;

  @media print {
    font-size: 0.7rem;
    line-height: 1.22;
    margin-top: 0.6rem;
  }

  a {
    color: inherit;
  }
`;
