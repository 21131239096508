import { gql } from '@apollo/client';

export const CREATE_HOUSEHOLD = gql`
  mutation CreateHousehold($input: CreateHouseholdInput!) {
    createHousehold(input: $input) {
      ok
      errors {
        primary {
          birthDate
          email
          firstName
          lastName
          phone
        }
        others {
          birthDate
          email
          firstName
          lastName
          phone
        }
        nonFieldErrors
      }
      investor {
        id
      }
    }
  }
`;

export const REMOVE_HOUSEHOLD = gql`
  mutation RemoveHousehold($investorId: UUID!) {
    removeHousehold(investorId: $investorId) {
      ok
    }
  }
`;

export const UPDATE_HOUSEHOLD = gql`
  mutation UpdateHousehold($input: HouseholdInput!, $investorId: UUID!) {
    editHousehold(input: $input, investorId: $investorId) {
      ok
      errors {
        primary {
          email
          firstName
          lastName
          birthDate
          phone
        }
        others {
          firstName
          lastName
          birthDate
          phone
        }
        nonFieldErrors
      }
      investor {
        id
      }
    }
  }
`;
