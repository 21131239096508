import styled from 'styled-components';

import BaseCircle from 'components/Circle';
import { from } from 'styles/media';
import { TextWrap } from 'styles/mixins';

import { CircleProps } from './types';

export const Circle = styled(BaseCircle)<CircleProps>`
  margin-right: 0.5rem;
  background-color: ${({ background }) => background};
  flex-shrink: 0;
  @media print {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 11rem;

  ${from.tablet} {
    flex-direction: row;
  }
`;

export const ItemText = styled.span`
  @media not print {
    ${TextWrap};
  }
`;
