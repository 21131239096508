import styled from 'styled-components';

import BaseIconButton from 'components/IconButton';
import { VisuallyHidden, UnsetVisuallyHidden, TextWrap } from 'styles/mixins';

export const Remove = styled(BaseIconButton)`
  margin-left: auto;
  ${VisuallyHidden}

  &:focus {
    ${UnsetVisuallyHidden}
  }
  :hover {
    color: ${({ theme }) => theme.colors.flamingo};
  }
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  height: 1.375rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-left: 0.75rem;
  ${TextWrap};
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 0.75rem;
`;

export const Container = styled.div`
  align-items: center;
  border-bottom: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  display: flex;
  height: 3.75rem;
  padding: 1.0625rem 1rem 1.3125rem 1rem;

  &:hover {
    background: ${({ theme }) => theme.colors.grey0};
    > :last-child {
      ${UnsetVisuallyHidden}
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;
