import styled from 'styled-components';

import { from } from 'styles/media';

export const CompanyLogo = styled.img`
  max-height: 40px;
  filter: grayscale(100%);
  margin-bottom: 1.5rem;
  margin-top: -4.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey0};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.875rem 2.5rem;

  ${from.mobile} {
    padding: 2.875rem 3.75rem;
  }
`;
