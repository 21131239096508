import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrowLeft.svg';
import IconButton from 'components/IconButton';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 35.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13.0625rem;
`;

export const Indicator = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  margin-left: auto;
  height: 1.1875rem;
  display: flex;
  align-items: flex-end;
`;

export const Back = styled(IconButton).attrs(() => ({
  to: '/home',
  variant: 'secondary',
  icon: <ArrowIcon />,
}))`
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-left: 1rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 7.25rem;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.875rem;
  grid-row-gap: 0.75rem;
  margin-bottom: 1.5rem;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Success = styled.div`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary};
  padding: 12px;
  border-radius: 3px;
  text-align: center;
`;
