import { FC } from 'react';

import Header from './Header';
import {
  Bold,
  Card,
  ContactUs,
  HeaderTitle,
  Text,
  Title,
  Wrapper,
} from './styles';

interface StaticPageCardProps {
  title: any;
}

const StaticPageCard: FC<StaticPageCardProps> = ({ children, title }) => {
  return (
    <Card>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      <Wrapper>{children}</Wrapper>
    </Card>
  );
};

export default StaticPageCard;

export { Bold, ContactUs, Text, Title };
