import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import { Props } from './types';

const IconButton: FC<Props> = ({ icon, variant, to, ...props }) => {
  return (
    <Container
      variant={variant}
      {...(to ? ({ as: Link, to } as any) : {})}
      {...props}
    >
      {icon}
    </Container>
  );
};

export default memo(IconButton);
