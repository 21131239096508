import { useCallback, useMemo } from 'react';

import AuthFetch from 'utils/authFetch';

import { normalizeEpiPriority } from '../helpers';
import { useStore } from './context';
import type { FetchProfileParameters, ProfileType } from './types';

export default function useInvestorActions() {
  const [, setStore] = useStore((s) => s);

  const fetchProfile = useCallback(
    async ({
      investorId,
      useCache = true,
    }: FetchProfileParameters): Promise<ProfileType> => {
      setStore({ isLoadingProfile: true });
      try {
        const data = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/profile/${investorId}`,
          method: 'GET',
          useCache,
        });

        let { payload } = data;

        if (payload.valueRankings || payload.epiPriority) {
          payload = {
            ...payload,
            valueRankings: payload.valueRankings
              ? JSON.parse(payload.valueRankings)
              : null,
            normalizedEpiPriority: normalizeEpiPriority(payload.epiPriority),
          };
        }

        setStore({
          profile: payload,
          isLoadingProfile: false,
          id: investorId,
        });

        return payload;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error retrieving investor profile', e);
        setStore({ isLoadingProfile: false });
      }

      return {};
    },
    [setStore],
  );

  const emailResults = useCallback(async (investorId: string) => {
    try {
      await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/${investorId}/survey_results`,
        method: 'POST',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error emailing investor profile', e);
    }
  }, []);

  const resetProfile = useCallback(() => {
    setStore({ profile: {}, isLoadingProfile: false, investorId: null });
  }, [setStore]);

  return useMemo(
    () => ({
      fetchProfile,
      resetProfile,
      emailResults,
    }),
    [fetchProfile, resetProfile, emailResults],
  );
}
