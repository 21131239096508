import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { memo, useState, useCallback, useEffect } from 'react';

import Modal from 'components/NewModal';
import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import { HistoryType } from 'context/fastContext/account/types';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { AssetGroupType } from 'context/fastContext/proposal/types';
import normalizeTime from 'utils/normalizeTime';

const valueTypeBreakdown = {
  VALUE: 'Values Focused',
  MIXED: 'Values and Broad Focused',
  'NON VALUE': 'Broad Focused',
};

const valueTypeColors = {
  VALUE: '#36BF8A',
  MIXED: '#00613a',
  'NON VALUE': '#000',
};

type ValueTypes = 'VALUE' | 'MIXED' | 'NON VALUE';

const ProposalVaultModal = ({
  triggerPrint,
  printIsLoading,
  setComponentToPrint,
  isOpen,
  handleShowProposalVault,
}: {
  triggerPrint: Function;
  printIsLoading: boolean | undefined;
  setComponentToPrint: any;
  isOpen: boolean;
  handleShowProposalVault: Function;
}) => {
  // const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [errorPrintingHistoric, setErrorPrintingHistoric] =
    useState<boolean>(false);
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null);

  const { fetchAccounts } = useAccountActions();
  const [{ history, isLoadingHistoricAccounts }] = useAccountStore(
    (s: any) => ({
      history: s.history as HistoryType[],
      isLoadingHistoricAccounts: s.isLoadingHistoricAccounts,
    }),
  );

  const [assetClassGroups] = useProposalStore(
    (s: any) => s.assetClassGroups as AssetGroupType[],
  );

  const handleToggleAccordion = useCallback(
    (event) => {
      const { historicId } = event.currentTarget.dataset;
      if (errorPrintingHistoric) {
        setErrorPrintingHistoric(false);
      }
      if (openedAccordion === historicId) {
        setOpenedAccordion(null);
      } else {
        setOpenedAccordion(historicId);
      }
    },
    [errorPrintingHistoric, openedAccordion],
  );

  const handleTriggerPrint = useCallback(
    async (event) => {
      if (errorPrintingHistoric) {
        setErrorPrintingHistoric(false);
      }
      const { historicId } = event.currentTarget.dataset;
      const isSuccess = await fetchAccounts({
        useCache: true,
        isUpdating: true,
        historicId,
      });

      if (isSuccess) {
        setComponentToPrint('proposal');
        triggerPrint();
      } else {
        setErrorPrintingHistoric(true);
      }
    },
    [setComponentToPrint, errorPrintingHistoric, fetchAccounts, triggerPrint],
  );

  // const handleDeactivateProposal = useCallback(
  //   async (event) => {
  //     const { historyId } = event.currentTarget.dataset;
  //     await deactivateHistoricAccount({ accountId: historyId });
  //     setIsConfirmDelete(false);
  //   },
  //   [deactivateHistoricAccount],
  // );

  // const handleConfirmDelete = useCallback(() => {
  //   setIsConfirmDelete(!isConfirmDelete);
  // }, [isConfirmDelete]);

  useEffect(() => {
    if (!isOpen && errorPrintingHistoric) {
      setErrorPrintingHistoric(false);
    }
  }, [openedAccordion, isOpen, errorPrintingHistoric]);

  return (
    <Modal
      sx={{ maxWidth: '675px' }}
      close={handleShowProposalVault}
      isOpen={isOpen}
    >
      {isLoadingHistoricAccounts ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography
              sx={{
                fontSize: '18px',
                mb: 1,
                letterSpacing: '1px',
                fontWeight: 'bold',
                color: 'primary.main',
              }}
            >
              Loading...
            </Typography>
            <LinearProgress color="primary" />
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              fontSize: '1.3125rem',
              fontWeight: 'bold',
              lineHeight: 'calc(30 / 21)',
              height: '1.875rem',
            }}
          >
            Proposal Vault
          </Typography>
          <Box>
            {history.map(
              ({ id, timestamp, allocations, assetClassBreakdown }, i) => {
                const isSelected = id.toString() === openedAccordion;

                return (
                  <Accordion
                    key={id}
                    data-historic-id={id}
                    data-proposal-index={i}
                    sx={(theme) => ({
                      border: isSelected
                        ? `1px solid ${theme.palette.primary.main}`
                        : '1px solid #fff',
                      margin: '6px 0 !important',
                      '&.MuiAccordion-root:before': {
                        display: 'none',
                      },
                    })}
                    expanded={isSelected}
                  >
                    <AccordionSummary
                      data-historic-id={id}
                      data-proposal-index={i}
                      onClick={handleToggleAccordion}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography
                        color={isSelected ? 'primary.main' : 'initial'}
                        lineHeight="32px"
                        width="155px"
                      >
                        {normalizeTime(timestamp)}
                      </Typography>
                      <Stack
                        sx={{ ml: 2, justifyContent: 'center' }}
                        direction="row"
                        spacing={1}
                      >
                        {assetClassGroups.map((acg) => {
                          return (
                            <Chip
                              key={`chip-${acg.id}`}
                              sx={(theme) => ({
                                border: isSelected
                                  ? `1px solid ${theme.palette.primary.main}`
                                  : '1px solid #bdbdbd',
                              })}
                              label={`${acg.name} ${(
                                allocations[acg.symbol] || 0
                              ).toFixed(2)}%`}
                            />
                          );
                        })}
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '30px',
                        }}
                      >
                        {assetClassGroups.map((acg) => {
                          const sortedChildAssetClasses = acg.children.sort(
                            (a, b) => a.assetOrder - b.assetOrder,
                          );
                          return (
                            <Box sx={{ flex: 1 }} key={acg.id}>
                              <Typography fontWeight="bold">
                                {acg.name}
                              </Typography>
                              {sortedChildAssetClasses.map((ac) => {
                                const childBreakdown = assetClassBreakdown.find(
                                  (acb: any) => acb.id === ac.id,
                                );

                                return (
                                  <Box
                                    sx={{
                                      display: 'grid',
                                      gridTemplateColumns: '1fr auto auto',
                                      alignItems: 'center',
                                      marginBottom: '6px',
                                    }}
                                    key={ac.id}
                                  >
                                    <Tooltip
                                      title={
                                        valueTypeBreakdown[
                                          childBreakdown?.valueType as ValueTypes
                                        ]
                                      }
                                    >
                                      <Box
                                        sx={{
                                          color:
                                            valueTypeColors[
                                              childBreakdown?.valueType as ValueTypes
                                            ],
                                          fontWeight: [
                                            'VALUE',
                                            'MIXED',
                                          ].includes(childBreakdown?.valueType)
                                            ? 'bold'
                                            : 'initial',
                                        }}
                                      >
                                        {ac.name}
                                      </Box>
                                    </Tooltip>
                                    <Box>
                                      {(allocations[ac.symbol] || 0).toFixed(2)}
                                      %
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '6px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                        mt={3}
                      >
                        {/* {!isConfirmDelete && ( */}
                        <>
                          <Tooltip title="Print to PDF original historic proposal.">
                            <Button
                              disabled={printIsLoading}
                              data-historic-id={id}
                              onClick={handleTriggerPrint}
                              sx={{
                                flex: 2,
                                fontWeight: 'bold',
                                textTransform: 'initial',
                              }}
                              variant="contained"
                            >
                              {printIsLoading ? 'Loading...' : <PrintIcon />}
                            </Button>
                          </Tooltip>
                        </>
                        {/* )} */}
                        {/* <Tooltip title="Delete proposal from history.">
                          <Box sx={{ flex: isConfirmDelete ? 3 : 'initial' }}>
                            <Button
                              data-historic-id={id}
                              onClick={
                                isConfirmDelete
                                  ? handleDeactivateProposal
                                  : handleConfirmDelete
                              }
                              sx={{
                                flex: 1,
                                fontWeight: 'bold',
                                textTransform: 'initial',
                                width: '100%',
                              }}
                              color="error"
                              variant="contained"
                            >
                              {isConfirmDelete ? (
                                <>
                                  <DeleteIcon sx={{ mr: '4px' }} /> Confirm
                                </>
                              ) : (
                                <DeleteIcon />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                        {isConfirmDelete && (
                          <Button
                            onClick={handleConfirmDelete}
                            sx={{
                              flex: 1,
                              fontWeight: 'bold',
                              textTransform: 'initial',
                            }}
                            variant="contained"
                          >
                            Cancel
                          </Button>
                        )} */}
                      </Box>
                      {isSelected && errorPrintingHistoric && (
                        <Alert sx={{ marginTop: '8px' }} severity="error">
                          Error printing historic proposal, please try again or
                          contact support
                        </Alert>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              },
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default memo(ProposalVaultModal);
