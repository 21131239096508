import { FC, memo } from 'react';

import { useStore as useProposalStore } from 'context/fastContext/proposal/context';

import Badge from './Badge';
import { Container, Text } from './styles';
import { Props } from './types';

const SurveyStatus: FC<Props> = ({
  investorStatus,
  surveyStatus,
  ...props
}) => {
  let statusToUse;
  let labelToUse;

  const [proposalState] = useProposalStore((s: any) => s.proposalState);

  if (['PENDING', 'IMPLEMENTED'].includes(proposalState)) {
    statusToUse = proposalState === 'IMPLEMENTED' ? 'OPEN' : 'PENDING';
    labelToUse = 'Account Status';
  } else {
    statusToUse = surveyStatus?.toUpperCase();
    labelToUse = 'Assessment Status';
  }

  // STATUS TO USE VALUES - NOT SENT, SENT, WAITING, COMPLETED, OPEN
  return (
    <Container
      status={statusToUse}
      {...props}
      data-testid="investor-profile-container-assessment-status"
    >
      <Text>{labelToUse}</Text>
      <Badge status={statusToUse} />
    </Container>
  );
};

export default memo(SurveyStatus);
