import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';

import type { FilterOptionType } from './types';

export default function FilterDropdown({
  displayDropdown,
  handleSetFilterValues,
  filterValues,
  filterOptions,
  clearFilters,
}: {
  displayDropdown: boolean;
  handleSetFilterValues: any;
  filterValues: string[];
  filterOptions: FilterOptionType;
  clearFilters: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const categories = useMemo(() => Object.keys(filterOptions), [filterOptions]);
  return (
    <Box
      sx={{
        display: displayDropdown ? 'block' : 'none',
        width: '200px',
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: '#FFFFFF',
        p: '1rem 1.375rem 0.5rem',
        border: '0.0625rem solid #F1F3F5',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontSize="18px" fontWeight="bold">
          Filters
        </Typography>
        {clearFilters && filterValues.length > 0 && (
          <Button onClick={clearFilters} size="small" variant="contained">
            Clear
          </Button>
        )}
      </Box>

      {categories.map((cat, index) => {
        const catValues = filterOptions[cat];
        const flattenedValues = catValues.map((v) => v.name);
        const commonElements = flattenedValues.filter((item) =>
          filterValues.includes(item),
        );

        return (
          <Accordion
            key={cat}
            disableGutters
            sx={{
              '&.Mui-expanded': {
                margin: 0,
                marginBottom: 2,
              },
              '& .MuiFormControlLabel-labelPlacementEnd': {
                margin: 0,
              },
              '& .MuiAccordionDetails-root': {
                padding: '0 !important',
              },
              '&::before': {
                display: index === 0 ? 'none' : 'block',
              },
              boxShadow: 'none',
              fontSize: '12px',
            }}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                width="95%"
                padding="0 !important"
              >
                <Typography fontSize="14px" fontWeight="bold">
                  {cat}
                </Typography>
                {commonElements.length ? (
                  <Typography
                    style={{
                      textAlign: 'center',
                      color: '#FFFFFF',
                      borderRadius: '400px',
                      marginLeft: '8px',
                      height: '20px',
                      width: '20px',
                      lineHeight: '20px',
                      backgroundColor: '#36BF8A',
                    }}
                  >
                    {commonElements.length}
                  </Typography>
                ) : null}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ maxHeight: '250px', overflow: 'auto' }}>
              {filterOptions[cat].map(({ name, label, isDisabled }) => (
                <FormControlLabel
                  onChange={handleSetFilterValues}
                  control={<Checkbox />}
                  label={label}
                  value={name}
                  disabled={isDisabled}
                  key={name}
                  checked={filterValues.includes(name)}
                  sx={{
                    flex: 1,
                    marginRight: '6px !important',
                    fontSize: '10px',
                    '.MuiCheckbox-root': {
                      padding: '6px 3px 6px 0px',
                    },
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                    '&:hover .MuiButtonBase-root': {
                      color: '#36BF8A',
                    },
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
