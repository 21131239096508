import styled, { css } from 'styled-components';

import { TextWrap } from 'styles/mixins';
import theme from 'styles/v2/theme';

import { ContainerProps } from './types';

const Transition = css`
  transition: opacity 80ms ease-out;
`;

export const RightIconWrapper = styled.div`
  position: absolute;
  right: 0.25rem;
  width: 40px;
  height: 40px;

  .MuiButtonBase-root {
    color: ${theme.palette.grey[300]};
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  color: ${theme.palette.grey[900]};
  font-family: 'GreyCliff CF';
  font-size: 1rem;
  line-height: calc(22 / 16);
  width: 100%;
  ${TextWrap}

  ::placeholder {
    color: ${theme.palette.grey[500]};
    ${Transition}
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${theme.palette.grey[400]};
    cursor: not-allowed;
  }
`;

export const Label = styled.div`
  color: inherit;
  height: 1.125rem;
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  ${Transition}
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputErrorWrapper = styled.div`
  color: ${theme.palette.warning.main};
  font-size: 0.75rem;
  line-height: calc(16 / 12);
  min-height: 1rem;
`;

export const Container = styled.div<ContainerProps>`
  background-color: ${theme.palette.grey[50]};
  border-bottom: solid 0.125rem ${theme.palette.grey[100]};
  border-radius: 0.5rem 0.5rem 0 0;
  color: ${theme.palette.grey[500]};
  display: flex;
  flex-direction: column;
  height: 3.125rem;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 100%;
  max-width: 16.8125rem;

  &:focus-within {
    color: ${theme.palette.primary[400]};
    border-bottom: solid 0.125rem ${theme.palette.primary[400]};
  }

  ${({ isError }) => {
    return isError
      ? css`
          color: ${theme.palette.warning.main} !important;
          border-bottom: solid 0.125rem ${theme.palette.warning.main} !important;
        `
      : null;
  }}

  &:hover {
    ${Input}::placeholder {
      opacity: 0.7;
      ${Transition};
    }
  }
`;
