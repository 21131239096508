import { css } from 'styled-components';

export const TextWrap = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const VisuallyHidden = css`
  position: absolute !important;
  height: 0.0625rem;
  width: 0.0625rem;
  overflow: hidden;
  clip: rect(0.0625rem 0.0625rem 0.0625rem 0.0625rem); /* IE6, IE7 */
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
  white-space: nowrap;
`;

export const UnsetVisuallyHidden = css`
  position: unset !important;
  height: unset;
  width: unset;
  overflow: unset;
  clip: unset;
  white-space: unset;
`;

export const prettyScrollbar = ({
  vertical = true,
  size = '0.375rem',
}: {
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.grey600} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey600};
    border-radius: 0.25rem;
  }
`;

export const CustomTooltip = css`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.775rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
  box-shadow: 0 0.125rem 1rem -0.5rem ${({ theme }) => theme.colors.grey500};
  letter-spacing: 0.0063rem;
  font-family: 'Greycliff CF';
`;

export const lineClamp = (lines: number) => css`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
  display: -webkit-box;
`;
