import throttle from 'lodash/throttle';
import { FC, useRef, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { Container, Wrapper, ShadowStart, ShadowEnd } from './styles';
import { Props } from './types';

const OverflowContainer: FC<Props> = ({
  disabled = false,
  children,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [{ start, end }, setIntersections] = useState({
    start: false,
    end: false,
  });

  useEffect(() => {
    if (disabled) return;
    const container = containerRef.current;
    if (!container) return;

    function onScroll() {
      if (!container) return;
      const { scrollLeft, scrollWidth, offsetWidth } = container;
      const localStart = scrollLeft === 0;
      const localEnd = scrollLeft + offsetWidth === scrollWidth;
      setIntersections({ start: !localStart, end: !localEnd });
    }

    const throttled = throttle(onScroll, 100);

    onScroll();
    const observer = new ResizeObserver(onScroll);
    observer.observe(container);
    container.addEventListener('scroll', throttled);

    // eslint-disable-next-line
    return () => {
      container.removeEventListener('scroll', throttled);
      observer.unobserve(container);
    };
  }, [disabled]);

  return (
    <Container {...props}>
      <ShadowStart $show={start} />
      <Wrapper ref={containerRef} $disabled={disabled}>
        {children}
      </Wrapper>
      <ShadowEnd $show={end} />
    </Container>
  );
};

export default OverflowContainer;
