import { parse, format } from 'date-fns';
import { FormikValues, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { minDate, maxDate } from 'components/DateInput';
import { phoneValidation } from 'components/PhoneInput/constants';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';

const noSpaceRegExp = /^[^\s]+(\s+[^\s]+)*$/;
const noSpaceMessage = 'Leading or trailing whitespaces are not allowed';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .required(),
  lastName: Yup.string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .required(),
  birthDate: Yup.date()
    .min(minDate)
    .max(maxDate)
    .required('Date is invalid or incomplete'),
  phone: phoneValidation.required(),
});

const useConnect = () => {
  const { me } = useMe();
  const { push } = useHistory();
  const { update } = useMeActions();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const initialValues = useMemo(
    () => ({
      firstName: me?.firstName,
      lastName: me?.lastName,
      birthDate: me?.birthDate
        ? format(parse(me.birthDate, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
        : undefined,
      email: me?.email,
      phone: me?.phone,
    }),
    [me],
  );

  const handleSubmit = useCallback(
    async (
      values: FormikValues,
      { setSubmitting, setErrors }: FormikHelpers<any>,
    ) => {
      const email = me?.email;
      if (email) {
        const input = {
          email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          birthDate: format(
            parse(values.birthDate, 'MM/dd/yyyy', new Date()),
            'yyyy-MM-dd',
          ),
        };

        const { errors, success } = await update(input);

        if (!errors && success) {
          setShowSuccess(true);
          setTimeout(() => {
            push('/');
            setShowSuccess(false);
          }, 1000);
        } else {
          setErrors(errors || {});
        }

        setSubmitting(false);
      }
    },
    [me, push, update],
  );

  return {
    me,
    initialValues,
    validationSchema,
    handleSubmit,
    showSuccess,
  };
};

export default useConnect;
