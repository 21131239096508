import { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdvisorOnboarding from './AdvisorOnboarding';
import useConnect from './connect';
import InvestorOnboardingHousehold from './InvestorOnboarding/Household';
import InvestorOnboardingWelcome from './InvestorOnboarding/Welcome';
import InvestorSurvey from './InvestorSurvey';
import Login from './Login';
import NewPassword from './NewPassword';
import ResetPassword from './ResetPassword';

const Guest = () => {
  const { pathname } = useConnect();
  const redirectPath = useMemo(
    () => `/login${pathname !== '/' ? `?continue=${pathname}` : ''}`,
    [pathname],
  );

  return (
    <Switch>
      <Route component={Login} exact path="/login" />
      <Route
        component={NewPassword}
        exact
        path="/new-password/:uidb64/:token"
      />
      <Route
        component={AdvisorOnboarding}
        exact
        path="/advisor/onboarding/:uidb64/:token"
      />
      <Route
        component={InvestorSurvey}
        exact
        path={['/investor/survey/:uidb64/:token', '/onboarding/:uidb64/:token']}
      />
      <Route
        component={InvestorOnboardingWelcome}
        exact
        path={[
          '/investor/onboarding/:advisorShortHash',
          '/o/:advisorShortHash',
          '/o/:company/:advisorShortHash',
        ]}
      />
      <Route
        component={InvestorOnboardingHousehold}
        exact
        path={[
          '/investor/onboarding/household/:advisorShortHash',
          '/o/h/:advisorShortHash',
        ]}
      />
      <Route component={ResetPassword} exact path="/reset-password" />
      <Redirect to={redirectPath} />
    </Switch>
  );
};

export default Guest;
