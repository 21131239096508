export enum ZIndex {
  MAIN = 0,
  PROFILE_ALT_OVERLAY = 1,
  OVERFLOW_CONTAINER = 1,
  INVESTOR_STICKY_TABS = 2,
  PROFILE_ALT_INITIALS = 2,
  DROPDOWN_MENU = 3,
  RICH_INPUT_PLACEHOLDER = 4,
  CARD_STATUS = 5,
  ORDER_BY_DROPDOWN = 6,
  SEARCH_FILTERS = 7,
  SELECT_MENU = 8,
  MOBILE_MENU = 9,
  MODAL_CLOSE_BUTTON = 10,
  MODAL = 1300,
  FULL_SCREEN_IMAGE_VIEWER = 1301,
  BREAKPOINT_DEBUGGER = 9999,
}
