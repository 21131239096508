import { Dialog } from '@mui/material';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseModalIcon } from 'assets/svg/closeModal.svg';
import IconButton from 'components/IconButton';
import { ZIndex } from 'styles/metrics';
import { prettyScrollbar } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Root = styled(Dialog)`
  position: relative;

  & .MuiBackdrop-root {
    background-color: ${({ theme }) => addAlpha(theme.colors.dark, 0.1)};
  }

  & .MuiDialog-paperScrollPaper {
    ${prettyScrollbar({
      size: '0.25rem',
      vertical: true,
    })};
    overflow-y: auto;
    z-index: ${ZIndex.MODAL};
  }

  & .MuiPaper-elevation24 {
    box-shadow: 0 1.25rem 2.5rem -0.125rem ${({ theme }) => addAlpha(theme.colors.dark, 0.1)};
  }

  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    color: ${({ theme }) => theme.colors.dark};

    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        min-width: 26.5625rem;
        max-width: 31.25rem;
        margin: 0 1.25rem;
        border-radius: 0.625rem;
      `};
  }

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  padding: 2rem;
`;

export const CloseButton = styled(IconButton).attrs(() => ({
  icon: <CloseModalIcon />,
  variant: 'transparent',
}))`
  position: absolute;
  padding: 0.6875rem;
  color: ${({ theme }) => theme.colors.primary};
  height: 2.25rem;
  width: 2.25rem;
  right: 0.5rem;
  top: 0.5rem;
  z-index: ${ZIndex.MODAL_CLOSE_BUTTON};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: bold;
  line-height: calc(30 / 21);
  height: 1.875rem;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
`;

export const Error = styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.flamingo};
  font-size: 0.75rem;
`;
