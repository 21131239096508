import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Indicator } from 'assets/svg/holdings-toggle/indicator.svg';
import { ReactComponent as RiskBar } from 'assets/svg/holdings-toggle/risk.svg';
import { ReactComponent as ValueBar } from 'assets/svg/holdings-toggle/value.svg';
import theme from 'styles/v2/theme';

const labels: { [any: string]: string } = {
  value: 'Values',
  risk: 'Risk',
};
const BoldText = styled('span')({
  fontWeight: 'bold',
});

function HoldingsSlider({
  score,
  type,
  displayHover = true,
}: {
  score: number;
  type: string;
  displayHover: boolean;
}) {
  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const Slider = type === 'value' ? ValueBar : RiskBar;
  const indicatorMargin = (score * 108) / 5;

  const handleToggleInfo = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setDisplayInfo((previousOpen) => !previousOpen);
    },
    [],
  );

  return (
    <Box
      onMouseEnter={displayHover ? handleToggleInfo : undefined}
      onMouseLeave={displayHover ? handleToggleInfo : undefined}
      sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
    >
      <Slider />
      <Box
        sx={{
          position: 'absolute',
          marginLeft: `calc(${indicatorMargin}px - 6px)`,
          top: '8px',
          '@media print': {
            top: '10px',
          },
        }}
      >
        <Indicator />
      </Box>
      <Popper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.secondary[300],
          color: theme.palette.common.white,
          borderRadius: '0.625rem',
          padding: '0.5rem 1.5rem 0.5rem 0.5rem',
          fontSize: '0.875rem',
          fontWeight: '700',
          marginLeft: '10px !important',
          boxShadow: '0 0.125rem 1rem -0.5rem rgba(0,0,0,0.1)',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '-9px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 0,
            height: 0,
            borderTop: '10px solid transparent',
            borderRight: '10px solid',
            borderRightColor: theme.palette.secondary[300], // color of the arrow
            borderBottom: '10px solid transparent',
          },
        }}
        placement="right"
        open={displayInfo}
        anchorEl={anchorEl}
      >
        <Box>
          <HelpIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Typography>
            {labels[type]} Score: <BoldText>{score}</BoldText> out of
            <BoldText> 5.00</BoldText>
          </Typography>
          <Typography>
            A values score of <BoldText>5.00</BoldText> is the highest possible.
          </Typography>
        </Box>
      </Popper>
    </Box>
  );
}

export default HoldingsSlider;
