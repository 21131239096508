import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { format, parse } from 'date-fns/esm';
import { Formik, Form, Field, FieldArray, FormikHelpers } from 'formik';
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as RemoveIcon } from 'assets/svg/minus.svg';
import { ReactComponent as AddIcon } from 'assets/svg/plusCircle.svg';
import { InputError, InputGroup } from 'components/Input';
import TermsModal from 'containers/Modals/TermsModal';
import useUser from 'context/hooks/User.hooks';
import useModal from 'graphql/hooks/useModal';

import { defaultOther, initialValues, validationSchema } from './constants';
import { primaryFields, otherFields } from './fields';
import {
  Button,
  Buttons,
  Container,
  Header,
  Inputs,
  SecondaryTitle,
  Title,
} from './styles';
import { FormValues, MutationErrors } from './types';

const AddHousehold = () => {
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const { close: closeModal, isOpen, open: openModal } = useModal('TERMS');
  const { advisorShortHash } = useParams<{ advisorShortHash: string }>();
  const { investorCreateHousehold } = useUser();

  const handleTermsToggle = useCallback(() => {
    setAgreeToTerms(!agreeToTerms);
  }, [agreeToTerms]);

  const handleToggleModal = useCallback(() => {
    if (isOpen) {
      closeModal();
    } else {
      openModal();
    }
  }, [closeModal, isOpen, openModal]);

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      const householdObject = {
        advisorShortHash: values.advisor,
        name: values.primary.lastName,
        primary: {
          ...values.primary,
          birthDate: values.primary.birthDate
            ? format(
                parse(values.primary.birthDate, 'MM/dd/yyyy', new Date()),
                'yyyy-MM-dd',
              )
            : undefined,
        },
        // filter empty values
        others: values.others
          .filter((el: any) => {
            const keys = Object.keys(el);
            return keys
              .filter((k) => k !== 'phone' && k !== 'birthDate')
              .every((fieldKey) => !!el[fieldKey]);
          })
          .map((other) => {
            return {
              ...other,
              birthDate: other.birthDate
                ? format(
                    parse(other.birthDate, 'MM/dd/yyyy', new Date()),
                    'yyyy-MM-dd',
                  )
                : undefined,
            };
          }),
        sendEmail: values.isEmailSent,
      };

      const results = await investorCreateHousehold(householdObject);
      if (results.errors as MutationErrors) {
        setErrors({
          primary: results.errors?.primary,
          others: results.errors?.others,
        });
      }
    },
    [investorCreateHousehold],
  );

  return (
    <Container>
      <Header>
        <Title>Household Information</Title>
      </Header>
      <Formik
        initialValues={initialValues(advisorShortHash)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, values, isValid }) => (
          <Form id="add-household-form">
            <Inputs>
              {primaryFields.map((field) => (
                <InputGroup key={field.name}>
                  <Field
                    {...field}
                    error={
                      !!(errors as any)[field.name] &&
                      !!(touched as any)[field.name]
                    }
                  />
                  <InputError name={field.name} />
                </InputGroup>
              ))}
            </Inputs>
            {values.others.length > 0 && (
              <SecondaryTitle>Additional Household Members</SecondaryTitle>
            )}
            <FieldArray name="others">
              {({ push, remove }) => (
                <>
                  {values.others!.map((other, index) => {
                    const key = `other-${index}`;
                    return (
                      <Inputs key={key}>
                        {otherFields.map((field) => (
                          <InputGroup key={`others-${field.name}`}>
                            <Field
                              {...field}
                              error={
                                errors.others &&
                                errors.others[index] &&
                                (errors?.others[index] as any)?.[field.name] &&
                                touched?.others &&
                                touched?.others[index] &&
                                (touched?.others[index] as any)?.[field.name]
                              }
                            />
                            <InputError name={`others.${index}${field.name}`} />
                          </InputGroup>
                        ))}
                        <Button
                          type="button"
                          icon={<RemoveIcon />}
                          onClick={() => remove(index)}
                        >
                          Remove Household Member
                        </Button>
                      </Inputs>
                    );
                  })}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <Checkbox
                      onChange={handleTermsToggle}
                      name="acceptTerms"
                      color="primary"
                      checked={agreeToTerms}
                    />
                    <Typography fontSize="12px">
                      I agree to the&nbsp;
                      <span
                        tabIndex={0}
                        onClick={handleToggleModal}
                        onKeyUp={handleToggleModal}
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        role="button"
                      >
                        Terms of Service.
                      </span>
                    </Typography>
                  </Box>

                  <Buttons>
                    <Button
                      type="button"
                      variant="secondary"
                      icon={<AddIcon />}
                      onClick={() => {
                        push(defaultOther);
                      }}
                    >
                      Add Household Member
                    </Button>
                    <Button
                      form="add-household-form"
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting || !isValid || !agreeToTerms}
                    >
                      Next
                    </Button>
                  </Buttons>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
      <TermsModal isInvestor />
    </Container>
  );
};

export default AddHousehold;
