import { FC } from 'react';

import { CompanyLogo, Container, Header, Logo, PageIndicator } from './styles';
import { Props } from './types';

const Page: FC<Props> = ({ children, page, companyLogo, totalPages }) => {
  const hasUrl = companyLogo?.includes('https://');
  return (
    <Container>
      <Header>
        {companyLogo ? (
          <CompanyLogo
            src={
              hasUrl
                ? companyLogo
                : `${process.env.REACT_APP_S3_MEDIA}${companyLogo}`
            }
            alt=""
          />
        ) : (
          <Logo />
        )}
      </Header>
      {children}
      <PageIndicator>
        {page} / {totalPages}
      </PageIndicator>
    </Container>
  );
};

export default Page;
