import kebabCase from 'lodash/kebabCase';
import { FC, useRef, useCallback } from 'react';

import BaseDropdown from 'components/BaseDropdown';

import { Button, Left, Link, Option, Right, Text } from './styles';
import { Props } from './types';

import '@reach/menu-button/styles.css';

function MenuOption({ option }: { option: Props['options'][number] }) {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const onSelect = useCallback(() => {
    linkRef.current?.click();
  }, []);

  if (option.onSelect) {
    return (
      <Option
        onSelect={option.onSelect}
        variant={option.variant}
        data-testid={`menu-${kebabCase(option.name)}`}
      >
        {option.iconPosition === 'left' && <Left>{option.icon}</Left>}
        <Text>{option.name}</Text>
        {option.iconPosition === 'right' && <Right>{option.icon}</Right>}
      </Option>
    );
  }

  return (
    <Link to={option.to} tabIndex={-1} ref={linkRef}>
      <Option
        onSelect={onSelect}
        variant={option.variant}
        data-testid={`menu-${kebabCase(option.name)}`}
      >
        {option.iconPosition === 'left' && <Left>{option.icon}</Left>}
        <Text>{option.name}</Text>
        {option.iconPosition === 'right' && <Right>{option.icon}</Right>}
      </Option>
    </Link>
  );
}

const Menu: FC<Props> = ({ children, className, options }) => (
  <BaseDropdown trigger={children} className={className}>
    {options.map((option) => (
      <MenuOption key={option.name} option={option} />
    ))}
  </BaseDropdown>
);

export default Menu;

export { Button };
