import { FC } from 'react';

import useLogic from './logic';
import { Container } from './styles';
import { Props } from './types';

const Date: FC<Props> = ({ date }) => {
  const { formattedDate } = useLogic(date);
  return <Container>{formattedDate}</Container>;
};

export default Date;
