/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';

import Dropdown from 'components/Dropdown';
import {
  AccountCustodianType,
  AccountTypeType,
} from 'context/fastContext/account/types';

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    height: '46px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px',
  },
  '& .MuiInputLabel-outlined': {
    lineHeight: '14px',
    top: 0,
    '&.MuiInputLabel-shrink': {
      top: '4px',
    },
  },
};

const dropdownSx = {
  width: '100%',
  minHeight: '50px',
  backgroundColor: '#fff',
};

function EditAllocationAccountInputs({
  handleAccountName,
  handleAccountValue,
  handleAccountType,
  handleAccountCustodian,
  accountValue,
  accountName,
  accountType,
  accountCustodian,
  disabled = false,
  disableAccountType = false,
  accountTypes,
  accountCustodians,
}: {
  handleAccountName: any;
  handleAccountType: any;
  handleAccountCustodian: any;
  handleAccountValue: any;
  accountValue: any;
  accountName: any;
  accountType: any;
  accountCustodian: any;
  disabled?: boolean;
  disableAccountType?: boolean;
  accountTypes: AccountTypeType[];
  accountCustodians: AccountCustodianType[];
}) {
  const custodianOptions = accountCustodians.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  const accountTypeOptions = accountTypes.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <TextField
          sx={textFieldStyles}
          label="Account Name"
          variant="outlined"
          value={accountName}
          onChange={handleAccountName}
          disabled={disabled}
          required
        />
        <Box sx={{ margin: '8px 0px', display: 'flex', gap: '6px' }}>
          <TextField
            sx={textFieldStyles}
            label="Value (USD)"
            variant="outlined"
            value={accountValue}
            onChange={handleAccountValue}
            disabled={disabled}
            required
          />
          <Dropdown
            onChange={handleAccountCustodian}
            options={custodianOptions}
            value={accountCustodian}
            label="Custodian *"
            disabled={disabled}
            sx={dropdownSx}
            height="22px"
            padding="12px 32px 12px 12px"
            required
          />
        </Box>
        <Dropdown
          onChange={handleAccountType}
          options={accountTypeOptions}
          value={accountType}
          label="Account Type *"
          disabled={disabled || disableAccountType}
          sx={dropdownSx}
          height="22px"
          padding="12px 32px 12px 12px"
          required
        />
      </Box>
    </Box>
  );
}

export default EditAllocationAccountInputs;
