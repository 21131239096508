import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMemo, useEffect } from 'react';

import Group from 'components/Account/Implement/Group';
import useAdvisorActions from 'context/fastContext/advisor/actions';
import { useStore as useAdvisorStore } from 'context/fastContext/advisor/context';
import { AdvisorState } from 'context/fastContext/advisor/types';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useMe from 'graphql/hooks/useMe';

function Custodian({
  accountToImplement,
  confirmImplement,
  hasErrorImplementingAlturist,
}: any) {
  const { me } = useMe();
  const [{ allProposals, assetClassGroups }] = useProposalStore((s: any) => ({
    allProposals: s.allProposals,
    assetClassGroups: s.assetClassGroups,
  }));
  const { fetchZendeskToken } = useAdvisorActions();
  const [{ zendeskToken }] = useAdvisorStore((s: AdvisorState) => ({
    zendeskToken: s.zendeskToken,
  }));
  const isAdvisor = useMemo(() => me?.role === 'ADVISOR', [me]);
  const associatedProposal = allProposals.find(
    (p: any) => p.id === accountToImplement?.proposalId,
  );

  const isImplemented = useMemo(
    () => associatedProposal?.status === 'deployed',
    [associatedProposal?.status],
  );

  useEffect(() => {
    if (zendeskToken) {
      const zendeskAltruistUrl = 'https://seedsinvestor.zendesk.com/hc/en-us';
      const zendeskUrl = `https://seedsinvestor.zendesk.com/access/jwt?jwt=${zendeskToken}&return_to=${zendeskAltruistUrl}`;
      window.open(zendeskUrl, '_blank');
    }
  }, [zendeskToken]);

  const handleZendeskHelp = () => {
    fetchZendeskToken();
  };

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '8px' }}
        variant="h5"
      >
        Thank You!
      </Typography>
      <Typography fontSize="14px" mb="16px" color="grey.500">
        To implement this portfolio, you will log into your Altruist Model
        Marketplace and select the appropriate Seeds sleeves and weights within
        the client account as shown below
      </Typography>
      <Box
        sx={{ border: '1px solid', borderColor: 'grey.100', fontSize: '14px' }}
      >
        {assetClassGroups.map((acg: any) => {
          const assetGroupKey = acg.symbol.toLowerCase();

          return (
            <Group
              key={assetGroupKey}
              assetClassGroup={acg}
              associatedProposal={associatedProposal}
              accountValue={accountToImplement?.amountInvestedDollar}
              isCustodian
            />
          );
        })}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          margin: '16px 0',
          display: 'flex',
          flexBasis: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isAdvisor && (
          <>
            <Button
              sx={{
                textTransform: 'capitalize',
                display: 'flex',
              }}
              href=""
              target="_blank"
              onClick={handleZendeskHelp}
              variant="outlined"
            >
              Click here
            </Button>
            &nbsp;&nbsp;for more information.
          </>
        )}
      </Box>
      <Typography fontSize="14px" mb="8px" color="grey.500">
        Contact support@seedsinvestor.com with any questions.
      </Typography>

      {!isImplemented ? (
        <>
          <Typography fontSize="14px" mb="16px" color="grey.500">
            By clicking Continue, the portfolio will be shown as “Implemented”
            in the Seeds platform.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{ textTransform: 'initial', fontWeight: 'bold' }}
              variant="contained"
              onClick={confirmImplement}
            >
              Mark Account as Implemented
            </Button>
          </Box>
        </>
      ) : (
        <Typography color="grey.500">
          This proposal has already been marked as implemented.
        </Typography>
      )}
      {hasErrorImplementingAlturist && (
        <Alert sx={{ marginTop: '8px' }} severity="error">
          Error marking account as implemented. Please try again and if the
          error persists contact support.
        </Alert>
      )}
    </Box>
  );
}

export default Custodian;
