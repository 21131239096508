import {
  Card,
  Container,
  Content,
  Illustration,
  Link,
  Logo,
  Text,
  Wrapper,
} from '../styles';

const Loading = () => (
  <Container>
    <Card>
      <Illustration />
      <Wrapper>
        <Content>
          <Logo size="medium-extra" />
          <Text>Wait a moment...</Text>
          <Link to="/login">Back to login</Link>
        </Content>
      </Wrapper>
    </Card>
  </Container>
);

export default Loading;
