import { Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { FC, memo, useState } from 'react';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrowRight.svg';
import Button from 'components/Button';
import { SidebarActionContainer } from 'components/SidebarActionContainer';
import TextWrap from 'components/TextWrap';

import ContactInfo from './ContactInfo';
import {
  CollapsableContainer,
  Container,
  Divider,
  InfoSpan,
  ProfileAlt,
  SurveyStatus,
  Title,
} from './styles';
import { Props } from './types';

const Sidebar: FC<Props> = ({
  investorStatus,
  surveyStatus,
  surveyModified,
  user,
  advisor,
  investorId,
  checkAnswers,
  onClick,
  loading,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Container
      {...props}
      style={{
        width: collapsed ? '0' : '360px',
        padding: collapsed ? '0' : '2.5rem 0.625rem 0 0',
        transition: 'width .3s',
        position: 'relative',
      }}
    >
      <CollapsableContainer>
        <Tooltip title={collapsed ? 'Open sidebar' : 'Hide sidebar'}>
          <Button
            onClick={() => setCollapsed(!collapsed)}
            icon={collapsed ? <ArrowRight /> : <ArrowLeft />}
            style={{
              borderRadius: '50%',
              padding: 0,
              width: '35px',
              height: '35px',
              justifyContent: 'center',
            }}
          />
        </Tooltip>
      </CollapsableContainer>
      {!collapsed ? (
        <>
          <ProfileAlt
            name={user.name}
            householdName={user.householdName}
            imageURL={user.imageURL}
          />
          <Divider />
          <ContactInfo
            birthDate={user.birthDate}
            email={user.email}
            phone={user.phone}
          />
          <SurveyStatus
            investorStatus={investorStatus}
            surveyStatus={surveyStatus}
          />
          {['COMPLETED', 'PENDING', 'OPEN'].includes(investorStatus) && (
            <SidebarActionContainer />
          )}
          <Divider />
          <Title>ADVISOR</Title>
          <TextWrap>
            <InfoSpan>{advisor}</InfoSpan>
          </TextWrap>
          <Divider />
          <>
            <Title>ASSESSED ON</Title>
            <TextWrap>
              <InfoSpan>
                {surveyStatus !== 'Completed'
                  ? 'Assessment not taken'
                  : format(parseISO(surveyModified), 'MM/d/yyyy')}
              </InfoSpan>
            </TextWrap>
            <Divider />
          </>
          <br />
        </>
      ) : null}
    </Container>
  );
};

export default memo(Sidebar);
