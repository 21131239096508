import { parse, isValid, format } from 'date-fns';
import { useFormikContext } from 'formik';
import {
  FocusEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import { LogicProps, State } from './types';

const dateFormat = 'mm/dd/yyyy';
export const formatDate = (date: Date) => format(date, dateFormat);
export const parseInput = (str: string) => parse(str, dateFormat, new Date());

const useLogic = ({
  name,
  onBlur,
  onFocus,
  placeholder: placeholderProp,
  defaultValue,
}: LogicProps) => {
  const initialState: State = {
    placeholder: placeholderProp || dateFormat,
    focused: false,
    showLabel: false,
    filled: false,
  };

  const [{ placeholder, showLabel, focused, filled }, setState] =
    useState(initialState);

  const { setFieldValue, setFieldError } = useFormikContext();

  const maskRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const lazy = useMemo(() => (focused ? false : !filled), [focused, filled]);

  useEffect(() => {
    if (inputRef?.current?.value) {
      setState((prevState) => ({
        ...prevState,
        showLabel: true,
        filled: inputRef.current.value.length > 0,
      }));
    }
  }, []);

  useEffect(() => {
    if (
      typeof defaultValue === 'string' &&
      defaultValue.length > 0 &&
      maskRef.current
    ) {
      inputRef.current.value = defaultValue;
      maskRef.current.maskRef.updateValue();
      setState((prevState) => ({
        ...prevState,
        showLabel: true,
        filled: inputRef.current.value.length > 0,
      }));
    }

    inputRef.current.placeholder = placeholder;
    inputRef.current.spellcheck = false;
  }, [placeholder, defaultValue]);

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);
      const value =
        inputRef?.current?.value === dateFormat
          ? null
          : inputRef?.current?.value;

      /*
        Wrongs value are not set, so we force the field to be set as "" on blur in order
        to make Formik show the error when the field is required, as I couldn't find a way of validating
        the value in the form using yup.
      */
      if (name) {
        if (!value) {
          setFieldValue(name, '');
        } else if (
          value !== dateFormat &&
          value.length > 0 &&
          !isValid(parse(value, 'MM/dd/yyyy', new Date()))
        ) {
          setFieldError(name, 'Date is invalid or incomplete');
        }
      }

      setState((prevState) => ({
        ...prevState,
        showLabel: !value ? false : prevState.showLabel,
        focused: false,
        filled: !!value,
        placeholder: !value
          ? placeholderProp || dateFormat
          : prevState.placeholder,
      }));
    },
    [
      inputRef,
      name,
      onBlur,
      placeholderProp,
      setFieldError,
      setFieldValue,
      setState,
    ],
  );

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(e);
      setState((prevState) => ({
        ...prevState,
        showLabel: true,
        focused: true,
        placeholder: dateFormat,
      }));
    },
    [onFocus, setState],
  );

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleOnChange = useCallback(
    (_: string, mask: any) => {
      const value = mask.value as string;
      const parsedDate = parse(value, 'MM/dd/yyyy', new Date());
      if (name) {
        if (isValid(parsedDate)) {
          // set field value as a string
          setFieldValue(name, value);
        } else if (value.length > 0 && value !== dateFormat) {
          setFieldError(name, `Date is invalid or incomplete`);
        }
      }
      setState((prevState) => ({
        ...prevState,
        filled: value.length > 0,
      }));
    },
    [name, setFieldValue, setFieldError],
  );

  return {
    handle: {
      focus: handleFocus,
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      onChange: handleOnChange,
    },
    inputRef,
    maskRef,
    showLabel,
    lazy,
  };
};

export default useLogic;
