import styled from 'styled-components';

export const LibraryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiBox-root {
    max-width: 200px;
  }
`;

export const LibraryWrapper = styled.div`
  margin: 1rem 0;
  h3 {
    margin-bottom: 0;
    line-height: normal;
  }
  hr {
    margin: 1.5rem 0;
  }
`;

export const FolderList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  row-gap: 2rem;
  min-height: 100px;
  margin-bottom: 40px;
`;

export const FileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(90px, 90px), 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
  min-height: 100px;
  margin-bottom: 40px;
`;

export const Link = styled.span`
  font-size: 1.125rem;
  line-height: 1.44;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.weights.bold};

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  &.root {
    &:hover,
    &:focus {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.dark};
      cursor: default;
    }
  }

  &.home {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
