import { Schema } from 'components/Table/types';
import { MinAdvisor } from 'models/Advisor';

import { BoldLink } from '.';

export const schema: Schema<MinAdvisor> = {
  columns: {
    fullName: {
      header: <>Advisor Name</>,
      row: (name) => (
        <BoldLink to={`/clients?advisor=${name}`}>{name}</BoldLink>
      ),
      width: '11.25rem',
    },
    company: {
      header: <>Company</>,
      row: (company) => <>{company}</>,
      width: '16.6875rem',
    },
    investors: {
      header: <># Clients</>,
      row: (value) => <>{value}</>,
      align: 'end',
      width: '4.9375rem',
    },
  },
};
