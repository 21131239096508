import { FC, memo } from 'react';

import { TXT, WRD, PDF, XLS, Zip } from './styles';
import { Props } from './types';

const DocIcon: FC<Props> = ({ fileType, ...props }) => {
  switch (fileType) {
    case 'text/plain':
      return <TXT {...props} />;
    case 'application/msword':
      return <WRD {...props} />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <WRD {...props} />;
    case 'application/pdf':
      return <PDF {...props} />;
    case 'application/zip':
      return <Zip {...props} />;
    case 'application/vnd.ms-excel':
      return <XLS {...props} />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <XLS {...props} />;

    default:
      return null;
  }
};

export default memo(DocIcon);
