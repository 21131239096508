import { clamp } from 'utils/clamp';

import { Props, EpiPriorityValue } from './types';

export function useLogic(
  ranks: Props['ranks'],
  epiPriority: Props['epiPriority'],
) {
  const earthCategory = {
    title: 'Earth',
    items: [
      {
        title: 'Product Sustainability',
        statement: `In your portfolio, the average company performs better than ${ranks.productSustainability}% of companies in developing and using clean, sustainable products and services.`,
        value: ranks.productSustainability ?? 0,
      },
      {
        title: 'Renewable Energy',
        statement: `In your portfolio, the average company performs better than ${ranks.renewableEnergy}% of companies in its share of renewable energy`,
        value: ranks.renewableEnergy ?? 0,
      },
      {
        title: 'Recycling',
        statement: `In your portfolio, the average company performs better than ${ranks.recycling}% of companies in its share of solid waste recycled.`,
        value: ranks.recycling ?? 0,
      },
    ],
  };

  const peopleCategory = {
    title: 'People',
    items: [
      {
        title: 'Human Rights',
        statement: `In your portfolio, the average company performs better than ${ranks.humanRights}% of companies in guaranteeing the basic human rights of workers and not doing business with companies or countries that violate those standards.`,
        value: ranks.humanRights ?? 0,
      },
      {
        title: 'Data Privacy',
        statement: `In your portfolio, the average company is better than ${ranks.dataPrivacy}% of companies in protecting the privacy of customers, including their data.`,
        value: ranks.dataPrivacy ?? 0,
      },
      {
        title: 'Inclusion',
        statement: `In your portfolio, the average company is in the top ${ranks.inclusion}% of companies that have a diverse and inclusive workplace for hiring, advancement, and without pay discrimination.`,
        value: ranks.inclusion ?? 0,
      },
    ],
  };

  const integrityCategory = {
    title: 'Integrity',
    items: [
      {
        title: 'Ethical Leadership',
        statement: `In your portfolio, the average company performs better than ${ranks.ethicalLeadership}% of companies in acting ethically and with integrity at the leadership level and taking responsibility for wrongdoings.`,
        value: ranks.ethicalLeadership ?? 0,
      },
      {
        title: 'Stakeholder Interests',
        statement: `In your portfolio, the average company performs better than ${ranks.stakeholderInterests}% of companies in having Executives and Boards of Directors that prioritize the interests of all stakeholders (e.g. workers, customers) when creating value in the company.`,
        value: ranks.stakeholderInterests ?? 0,
      },
      {
        title: 'Product Integrity',
        statement: `In your portfolio, the average company ranks better than ${ranks.consumerProtection}% of companies in making products or offering services that do minimal harm to society.`,
        value: ranks.consumerProtection ?? 0,
      },
    ],
  };

  const valueToCategory = (value: EpiPriorityValue[number]) => {
    switch (value) {
      case 'earth':
        return earthCategory;
      case 'people':
        return peopleCategory;
      case 'integrity':
      default:
        return integrityCategory;
    }
  };

  const categories = epiPriority.map(valueToCategory).map((category, index) => {
    const orderedItems = category.items
      .map((item) => ({ ...item, value: clamp(item.value) }))
      .filter((item) => item.value > 0)
      .sort((itemA, itemB) => Number(itemB.value) - Number(itemA.value))
      .slice(0, 3 - index);
    return { title: category.title, items: orderedItems };
  });

  return { categories };
}
