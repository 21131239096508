import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';

import { FileItem } from 'components/FileInput/types';
import useFileActions from 'graphql/hooks/useFileActions';
import { useEnqueueSnackbar } from 'hooks/useEnqueueSnackbar';

const useFileInput = () => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [fileList, setFileList] = useState<FileItem[]>([]);
  const { error, getUploadUrl } = useFileActions();

  const upload = useCallback(
    async (file: File, fileId?: string) => {
      const itemId = fileId || nanoid();

      setFileList((prevState) => {
        const index = prevState.findIndex(({ id }) => id === itemId);
        if (index >= 0) {
          const newState = [...prevState];
          newState[index] = { file, id: itemId, loading: true };
          return newState;
        }
        return prevState.concat({
          file,
          id: itemId,
          loading: true,
        });
      });

      const data = await getUploadUrl({
        contentType: file.type,
        filename: file.name,
      });

      if (!data?.uploadUrl) {
        const fileItem = {
          file,
          id: itemId,
          error: true,
        };

        setFileList((prevState) => {
          const index = prevState.findIndex(({ id }) => id === itemId);
          const newState = [...prevState];
          newState[index] = fileItem;
          return newState;
        });

        return;
      }

      const { contentType, retrieveUrl, uploadUrl } = data;

      if (retrieveUrl && uploadUrl && contentType) {
        try {
          const fetchResponse = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': contentType,
            },
            body: file,
          });

          const fileItem = fetchResponse.ok
            ? {
                file,
                id: itemId,
                error,
                retrieveUrl,
              }
            : { file, id: itemId, error: true };

          setFileList((prevState) => {
            const index = prevState.findIndex(({ id }) => id === itemId);
            const newState = [...prevState];
            newState[index] = fileItem;
            return newState;
          });
        } catch (e) {
          enqueueSnackbar("The file couldn't be uploaded.", {
            variant: 'error',
          });
        }
      }
    },
    [getUploadUrl, error, enqueueSnackbar],
  );

  const handleDrop = useCallback(
    (files: File[]) => {
      const filteredFiles = files.filter((newFile: File) => {
        return !fileList.find(({ file }) => {
          return newFile.name === file.name && newFile.size === file.size;
        });
      });

      filteredFiles.forEach((file: File) => {
        upload(file);
      });
    },
    [fileList, upload],
  );

  const handleRemove = useCallback(
    (id: string) => {
      setFileList((prevState) => prevState.filter((item) => item.id !== id));
    },
    [setFileList],
  );

  const handleRetry = useCallback(
    (id: string) => {
      const fileItem = fileList.find((failed) => failed.id === id);
      if (fileItem) {
        upload(fileItem.file, fileItem.id);
      }
    },
    [fileList, upload],
  );

  const handleEmpty = useCallback(() => {
    setFileList([]);
  }, []);

  return {
    handleDrop,
    handleEmpty,
    handleRemove,
    handleRetry,
    fileList,
  };
};

export default useFileInput;
