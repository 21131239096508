/* eslint-disable no-console */
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import AuthFetch from 'utils/authFetch';

import { useStore } from './context';
import { TradeTicketType, TradeTicketRequestType } from './types';

export default function useTradeTicketActions() {
  const { id: investorId } = useParams<{ id: string }>();
  const [
    {
      tradeTicket,
      ticketHistory,
      isLoadingAccounts,
      isUpdatingAccounts,
      isEditingAccount,
    },
    setStore,
  ] = useStore((s) => s);

  const createTradeTicket = useCallback(
    async ({
      accountId,
      createdTradeTicket,
      status,
    }: {
      accountId: string;
      createdTradeTicket: TradeTicketType;
      status: string;
    }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/tradeticket/`,
          method: 'POST',
          body: {
            createdTradeTicket,
            accountId,
            status,
          },
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error creating trade ticket', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  const createTradeTicketRequest = useCallback(
    async ({
      accountId,
      tradeTicketId,
      createdTradeTicketRequest,
    }: {
      accountId: string;
      tradeTicketId: string;
      createdTradeTicketRequest: TradeTicketRequestType;
    }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/tradeticketrequest/`,
          method: 'POST',
          body: {
            createdTradeTicketRequest,
            accountId,
            tradeTicketId,
          },
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error creating trade ticket request', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  const fetchTradeTicket = useCallback(
    async ({
      useCache = true,
      isUpdating = false,
      accountId,
      setLoading = true,
    }: {
      useCache?: boolean;
      isUpdating?: boolean;
      accountId: string;
      setLoading?: boolean;
    }): Promise<boolean> => {
      const loadKey = isUpdating ? 'isUpdatingTickets' : 'isLoadingTickets';
      if (setLoading) setStore({ [loadKey]: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/tradeticket/${accountId}`,
          method: 'GET',
          useCache,
        });
        if (response.status) {
          const transformedData = {
            ...response.payload.ticket,
            instructionsList: response.payload?.instructionsList,
          };
          setStore({ tradeTicket: transformedData });
          return true;
        }
        setStore({ tradeTicket: [] });
        return false;
      } catch (e) {
        console.log('Error retrieving trade ticket', e);
      }
      if (setLoading) setStore({ [loadKey]: false });
      return false;
    },
    [setStore],
  );

  const fetchTicketHistory = useCallback(
    async ({
      useCache = true,
      isUpdating = false,
      setLoading = true,
    }: {
      useCache?: boolean;
      isUpdating?: boolean;
      setLoading?: boolean;
    }) => {
      const loadKey = isUpdating
        ? 'isUpdatingTicketHistory'
        : 'isLoadingTickets';
      if (setLoading) setStore({ [loadKey]: true });
      try {
        const data = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/tradeticket/${investorId}/history`,
          method: 'GET',
          useCache,
        });

        if (data.payload) {
          setStore({ ticketHistory: data.payload });
        } else {
          setStore({
            ticketHistory: [],
            ...(setLoading && { [loadKey]: false }),
          });
        }
      } catch (e) {
        console.log('Error retrieving trade tickets', e);
      }
    },
    [investorId, setStore],
  );

  const handleUpdateLoadingState = useCallback(
    (isUpdating: boolean) => {
      setStore({ isUpdatingAccounts: isUpdating });
    },
    [setStore],
  );

  return {
    createTradeTicket,
    createTradeTicketRequest,
    handleUpdateLoadingState,
    fetchTradeTicket,
    fetchTicketHistory,
    tradeTicket,
    ticketHistory,
    isLoadingAccounts,
    isUpdatingAccounts,
    isEditingAccount,
  };
}
