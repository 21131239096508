import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useInvestor from 'graphql/hooks/useInvestor';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { push } = useHistory();
  const { id: investorId } = useParams<{ id: string }>();
  const {
    me,
    loading: meLoading,
    refetch: meRefetch,
  } = useMe({
    fetchPolicy: investorId ? 'cache-first' : 'network-only',
  });
  const {
    investor,
    loading: investorLoading,
    refetch: investorRefetch,
  } = useInvestor(investorId, {
    fetchPolicy: 'network-only',
  });

  const profile = me?.role === 'INVESTOR' ? me?.investor : investor;

  // just uses survey.steps
  const survey = useMemo(() => profile?.currentSurvey, [profile]);

  // Just uses surveyResult.answers
  const surveyResults = useMemo(() => profile?.surveyResult, [profile]);

  const goBack = () => {
    if (investorId) {
      push(`/clients/${investorId}`);
    }
  };

  return {
    survey,
    surveyResults,
    isLoading: investorLoading || meLoading,
    isRiskPreset:
      !!investor?.surveyResult?.riskLevel &&
      !!investor.surveyResult?.incomeLevel,
    meRefetch,
    investorRefetch,
    goBack,
  };
};

export default useConnect;
