import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 0.3125rem;
`;

export const Text = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey600};
  line-height: calc(18 / 12);
`;
