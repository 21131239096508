/* eslint-disable @typescript-eslint/no-unused-vars */
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useState, useMemo } from 'react';

import AddAccounts from 'components/Account/Modals/AddAccountModal/AddAccounts';
import Modal from 'components/NewModal';
import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import type { AccountState } from 'context/fastContext/account/types';
import { AddAccountParams } from 'context/fastContext/account/types';
import formatTextInputCurrency from 'utils/formatTextInputCurrency';

export type AccountToAddType = {
  name: string;
  value: string;
  type: string;
  custodian: string;
  key: any;
};

const emptyAccount: AccountToAddType = {
  name: '',
  value: '',
  type: '',
  custodian: '',
  key: '', // for react map purposes
};

function AddAccountsModal({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: Function;
}) {
  const { createAccounts, fetchAccounts } = useAccountActions();
  const [hasErrorAddingAccount, setHasErrorAddingAccount] =
    useState<boolean>(false);
  const [isSavingAccounts, setIsSavingAccounts] = useState<boolean>(false);
  const [accountsToAdd, setAccountsToAdd] = useState<AccountToAddType[]>([
    { ...emptyAccount, key: Date.now().toString() },
  ]);
  const [{ accountTypes, accountCustodians, isUpdatingAccounts }] =
    useAccountStore((s: AccountState) => ({
      accountTypes: s.accountTypes,
      accountCustodians: s.accountCustodians,
      isUpdatingAccounts: s.isUpdatingAccounts,
    }));

  const disableAddAccountInputs = useMemo(
    () => isUpdatingAccounts,
    [isUpdatingAccounts],
  );

  const handleUpdateAccount = useCallback(
    (e, dataset) => {
      const index = dataset ? dataset?.index : e?.target?.dataset?.index;
      const type = dataset ? dataset?.type : e?.target?.dataset?.type;

      const accountToEdit = { ...accountsToAdd[index] };
      const updatedAccounts = [...accountsToAdd];
      if (type === 'name') {
        accountToEdit.name = e.target.value;
      } else if (type === 'value') {
        const formattedValue = formatTextInputCurrency(e.target.value);
        if (formattedValue !== null) accountToEdit.value = formattedValue;
      } else if (type === 'type') {
        accountToEdit.type = e.target.value;
      } else {
        accountToEdit.custodian = e.target.value;
      }

      updatedAccounts[index] = { ...accountToEdit };
      setAccountsToAdd(updatedAccounts);
    },
    [accountsToAdd],
  );

  const handleRemoveAccount = useCallback(
    (e) => {
      const index = e?.currentTarget?.dataset?.index;
      const newAccounts = [...accountsToAdd].filter(
        (_, i) => i !== Number(index),
      );
      setAccountsToAdd([...newAccounts]);
    },
    [accountsToAdd],
  );

  const handleSaveNewAccounts = useCallback(async () => {
    if (hasErrorAddingAccount) {
      setHasErrorAddingAccount(false);
    }
    setIsSavingAccounts(true);

    const createdAccounts: AddAccountParams[] = accountsToAdd.map((ca) => ({
      name: ca.name,
      accountTypeId: ca.type,
      accountCustodianId: ca.custodian,
      amountInvestedDollar: Number(ca.value.replace(/,|\$/g, '')),
    }));

    const response = await createAccounts({ createdAccounts });

    if (response) {
      close();
      await fetchAccounts({ useCache: false, isUpdating: true });
      setAccountsToAdd([{ ...emptyAccount, key: Date.now().toString() }]);
    } else {
      setHasErrorAddingAccount(true);
    }

    setIsSavingAccounts(false);
  }, [
    accountsToAdd,
    close,
    createAccounts,
    fetchAccounts,
    hasErrorAddingAccount,
  ]);

  const allNewAccountsHaveRequiredFields = useMemo(
    () =>
      accountsToAdd.every((account: AccountToAddType) =>
        (Object.keys(account) as Array<keyof AccountToAddType>).every((key) => {
          return account[key].trim() !== '';
        }),
      ),
    [accountsToAdd],
  );

  const handleAddAccount = useCallback(() => {
    setAccountsToAdd([
      ...accountsToAdd,
      { ...emptyAccount, key: Date.now().toString() },
    ]);
  }, [accountsToAdd]);

  const handleCancel = useCallback(() => {
    close();
    setAccountsToAdd([{ ...emptyAccount, key: Date.now().toString() }]);
  }, [close]);

  return (
    <Modal
      transitionSpeed="0.3s"
      sx={{ width: '850px' }}
      isOpen={isOpen}
      close={close}
      dataTestId="add-accounts-modal"
    >
      <Typography fontWeight="bold" fontSize="18px">
        Add Accounts
      </Typography>
      <AddAccounts
        accountsToAdd={accountsToAdd}
        updateAccount={handleUpdateAccount}
        disableAddAccountInputs={disableAddAccountInputs}
        handleRemoveAccount={handleRemoveAccount}
        accountTypes={accountTypes}
        accountCustodians={accountCustodians}
      />
      <Box>
        <Box
          role="button"
          onClick={handleAddAccount}
          sx={(theme) => ({
            margin: '16px 0',
            padding: '20px',
            borderRadius: '6px',
            border: `1px dashed ${theme.palette.grey[100]}`,
            color: 'grey.300',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:hover': {
              border: `1px dashed ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
            },
          })}
        >
          <AddCircleOutlineRoundedIcon />
          &nbsp;Add Acccount
        </Box>
      </Box>
      <Box mb="6px" display="flex" justifyContent="space-between">
        <Tooltip
          title={
            !allNewAccountsHaveRequiredFields
              ? 'Please fill out all fields for new accounts being added in order save new accounts.'
              : ''
          }
        >
          <Box>
            <Button
              sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              variant="contained"
              onClick={handleSaveNewAccounts}
              disabled={!allNewAccountsHaveRequiredFields}
              data-testid="save-new-accounts"
            >
              Save New Account{accountsToAdd.length > 1 && 's'}
            </Button>
          </Box>
        </Tooltip>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
      {hasErrorAddingAccount && (
        <Alert sx={{ margin: '12px 0' }} severity="error">
          Error adding accounts, please try again or contact support.
        </Alert>
      )}
    </Modal>
  );
}

export default AddAccountsModal;
