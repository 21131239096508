import styled from 'styled-components';

import { ReactComponent as DefaultErrorIcon } from 'assets/svg/cancel.svg';
import { ReactComponent as DefaultSuccessIcon } from 'assets/svg/check-circle.svg';
import { ReactComponent as DefaultWarningIcon } from 'assets/svg/info-circle.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-inline-start: 0.75rem;
  column-gap: 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.5rem;
  box-shadow: 0rem 0.625rem 1.25rem rgba(31, 27, 36, 0.1);
  width: max-content;
  margin: 0 auto;
  > svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const Message = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.0256rem;
  color: ${({ theme }) => theme.colors.dark};
`;

export const ErrorIcon = styled(DefaultErrorIcon)`
  color: ${({ theme }) => theme.colors.flamingo};
`;
export const SuccessIcon = styled(DefaultSuccessIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
export const WarningIcon = styled(DefaultWarningIcon)`
  transform: rotate(0.5turn);
  color: ${({ theme }) => theme.colors.sandy};
`;
