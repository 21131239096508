import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { memo } from 'react';

import ConfirmDeleteModal from 'containers/Main/Portfolio/Modals/ConfirmDeleteModal';

import useConnect from './connect';
import { Container } from './styles';
import { Error } from './TotalPercentage/styles';
import { Props } from './types';

const Toolbar = ({
  portfolioType,
  portfolioHasBeenClearedRef,
  actions: { handleRefetch },
  hasCurrentPortfolio,
  handleConfirmDeletePortfolio,
  handleDeletePortfolio,
  showConfirmDelete,
  setShowConfirmDelete,
  confirmErrors,
  setConfirmErrors,
  isLoadingPortfolio,
  isImportingPortfolio,
  isUpdatingPortfolio,
}: Props) => {
  const { errors, handleClear, dirty, isSubmitting } = useConnect({
    portfolioHasBeenClearedRef,
  });

  const isDisabled =
    isSubmitting ||
    isLoadingPortfolio ||
    isImportingPortfolio ||
    isUpdatingPortfolio;

  return (
    <Container>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Tooltip title="Clear Form Values">
          <span>
            <Button
              sx={(theme) => ({
                fontWeight: '700 !important',
                textTransform: 'unset !important',
                backgroundColor: isDisabled
                  ? '#E9ECEF !important'
                  : 'rgba(237, 108, 2, 0.1) !important',
                color: isDisabled
                  ? '#ADB5BD !important'
                  : `${theme.palette.warning.main} !important`,
                cursor: isDisabled
                  ? 'not-allowed !important'
                  : 'pointer !important',
                borderRadius: '0.3125rem !important',
                lineHeight: 'calc(20 / 14) !important',
                margin: '0 0.5rem !important',
                padding: '0.4375rem 0.875rem !important',
                '&:hover': {
                  backgroundColor: 'rgba(237, 108, 2, 0.2) !important',
                },
              })}
              onClick={handleClear}
              disabled={isDisabled}
            >
              <span>Clear</span>
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Reset Form Values">
          <span>
            <Button
              sx={(theme) => ({
                fontWeight: '700 !important',
                textTransform: 'unset !important',
                backgroundColor: isDisabled
                  ? '#E9ECEF !important'
                  : `${theme.palette.primary[50]} !important`,
                color: isDisabled
                  ? '#ADB5BD !important'
                  : `${theme.palette.primary.main} !important`,
                cursor: isDisabled
                  ? 'not-allowed !important'
                  : 'pointer !important',
                borderRadius: '0.3125rem !important',
                lineHeight: 'calc(20 / 14) !important',
                margin: '0 0.5rem !important',
                padding: '0.4375rem 0.875rem !important',
                '&:hover': {
                  backgroundColor: `${theme.palette.primary[100]} !important`,
                },
              })}
              onClick={handleRefetch}
              disabled={isDisabled}
            >
              <span>Refresh</span>
            </Button>
          </span>
        </Tooltip>
      </Box>
      {hasCurrentPortfolio && !dirty && (
        <Tooltip title="Delete Current Portfolio">
          <span>
            <Button
              sx={(theme) => ({
                fontWeight: '700 !important',
                textTransform: 'unset !important',
                backgroundColor: isDisabled
                  ? '#E9ECEF !important'
                  : 'rgba(211, 47, 47, 0.1) !important',
                color: isDisabled
                  ? '#ADB5BD !important'
                  : `${theme.palette.error.main} !important`,
                cursor: isDisabled
                  ? 'not-allowed !important'
                  : 'pointer !important',
                borderRadius: '0.3125rem !important',
                lineHeight: 'calc(20 / 14) !important',
                margin: '0 0.5rem !important',
                padding: '0.4375rem 0.875rem !important',
                '&:hover': {
                  backgroundColor: 'rgba(211, 47, 47, 0.2) !important',
                },
              })}
              onClick={handleDeletePortfolio}
              disabled={isDisabled}
            >
              <span>Delete</span>
            </Button>
          </span>
        </Tooltip>
      )}
      {portfolioType === 'MARKET_VALUE' &&
      !Array.isArray(errors.assets) &&
      errors.assets ? (
        <Error>{errors.assets}</Error>
      ) : null}
      <ConfirmDeleteModal
        isOpen={showConfirmDelete}
        setShowConfirmDelete={setShowConfirmDelete}
        confirmDelete={handleConfirmDeletePortfolio}
        confirmErrors={confirmErrors}
        setConfirmErrors={setConfirmErrors}
      />
    </Container>
  );
};

export default memo(Toolbar);
