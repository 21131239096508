import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import { Props } from './types';

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'default',
      icon,
      to,
      iconPlacement = 'left',
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <Container
        ref={ref}
        variant={variant}
        {...(to ? ({ as: Link, to } as any) : {})}
        {...props}
      >
        {icon && iconPlacement === 'left' && icon}
        {children}
        {icon && iconPlacement === 'right' && icon}
      </Container>
    );
  },
);

export default Button;
