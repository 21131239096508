import { FC } from 'react';

import FileRow from '../FileRow';
import { Container } from './styles';
import { Props } from './types';

const FileContainer: FC<Props> = ({
  file,
  rootProps,
  isDragActive,
  isDragReject,
  onRemove,
}) => {
  return (
    <Container
      {...rootProps}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      onClick={() => {}}
    >
      <FileRow onRemove={onRemove} fileItem={file} />
    </Container>
  );
};

export default FileContainer;
