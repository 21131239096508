import Box from '@mui/material/Box';

import { riskColors } from './constants';

function RiskNumber({ number }: { number: number }) {
  return (
    <Box
      sx={{
        width: '32px',
        height: '32px',
        borderRadius: '100%',
        backgroundColor: riskColors[number],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '800',
      }}
    >
      {number}
    </Box>
  );
}

export default RiskNumber;
