import { useEffect } from 'react';

import PrettyLoading from 'components/PrettyLoading';
import Guest from 'containers/Guest';
import Main from 'containers/Main';
import useUser from 'context/hooks/User.hooks';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';

const App = () => {
  const { loading, me } = useMe();
  const { isOpen, open } = useModal('TERMS');
  const {
    userInfo: { acceptedTerms },
    userInfoFetched,
  } = useUser();

  useEffect(() => {
    if (
      userInfoFetched &&
      me &&
      me?.role !== 'INVESTOR' &&
      !isOpen &&
      !acceptedTerms
    ) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedTerms, me, userInfoFetched]);

  if (loading && !me) return <PrettyLoading />;

  // We essentially determine if we are logged in if the user request is successful or not
  return me ? <Main /> : <Guest />;
};

export default App;
