import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState, useCallback, useMemo } from 'react';

import FilterDropdown from './dropdown';
import type { FilterOptionType } from './types';

const Filter = ({
  handleSetFilterValues,
  clearFilters,
  filterValues,
  filterOptions,
}: {
  handleSetFilterValues: Function;
  clearFilters: React.MouseEventHandler<HTMLButtonElement>;
  filterValues: string[];
  filterOptions: FilterOptionType;
}) => {
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);

  const handleDisplayDropdown = useCallback(() => {
    setDisplayDropdown(!displayDropdown);
  }, [displayDropdown]);

  const handleClose = useCallback(() => {
    setDisplayDropdown(false);
  }, []);

  const hasFilterValues = useMemo(
    () => filterValues.length > 0,
    [filterValues.length],
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ minWidth: '117px', flex: '1', position: 'relative' }}>
        <Button
          onClick={handleDisplayDropdown}
          startIcon={
            <FilterListIcon
              sx={{ color: hasFilterValues ? '#ffffff' : '#36BF8A' }}
            />
          }
          sx={{
            '&:hover': {
              backgroundColor: hasFilterValues ? '#36BF8A' : '#F1F3F5',
              color: hasFilterValues ? '#ffffff' : '#868E96',
            },
            minWidth: '92px',
            height: '36px',
            padding: '7px 14px',
            textTransform: 'initial',
            backgroundColor: filterValues.length ? '#36BF8A' : '#F1F3F5',
            color: filterValues.length ? '#ffffff' : '#868E96',
            fontWeight: 'bold',
          }}
        >
          Filters
          {hasFilterValues && (
            <Box
              sx={{ backgroundColor: '#FFFFFF' }}
              color="#36BF8A"
              borderRadius={100}
              ml={1}
              height="20px"
              width="20px"
              lineHeight="20px"
            >
              {filterValues.length}
            </Box>
          )}
        </Button>
        <FilterDropdown
          filterValues={filterValues}
          handleSetFilterValues={handleSetFilterValues}
          displayDropdown={displayDropdown}
          filterOptions={filterOptions}
          clearFilters={clearFilters}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default Filter;
