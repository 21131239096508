import { useEffect } from 'react';

import Error from 'components/ErrorPage';
import PrettyLoading from 'components/PrettyLoading';
import useUser from 'context/hooks/User.hooks';

import Admin from './Admin';
import Advisor from './Advisor';
import useConnect from './connect';
import Investor from './Investor';

const Main = () => {
  const { loading, logout, me } = useConnect();
  const { fetchUserInfo, isLoading, userInfoFetched, errorFetching } =
    useUser();

  // TODO: When we switch to using DRF to determine login move this to src/containers/App/index.tsx
  useEffect(() => {
    const initialFetch = async () => {
      await fetchUserInfo();
    };

    if (!isLoading && !userInfoFetched && !errorFetching) initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, userInfoFetched, errorFetching]);

  if (loading || isLoading || (!userInfoFetched && !errorFetching))
    return <PrettyLoading />;

  switch (me?.role) {
    case 'ADVISOR':
      return <Advisor />;
    case 'ADMIN':
      return <Admin />;
    case 'INVESTOR':
      return <Investor />;
    case 'UNKNOWN':
    default:
      return (
        <Error
          title="An error has occurred"
          caption="Please contact Seeds."
          button={{ label: 'Log out', onClick: logout }}
        />
      );
  }
};

export default Main;
