import { Link } from 'react-router-dom';
import styled from 'styled-components';

export * from './formatters';
export * from './schemas';
export * from './types';

export const BoldLink = styled(Link)`
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
`;
