import { FC, memo, useCallback } from 'react';

import { blocksSchema, pattern, minDate, maxDate } from './constants';
import useLogic, { parseInput, formatDate } from './logic';
import { Container, Input, Label } from './styles';
import { Props } from './types';

export { minDate, maxDate } from './constants';

const DateInput: FC<Props> = ({
  className,
  error,
  max = maxDate,
  min = minDate,
  onBlur,
  onChange,
  onFocus,
  name,
  placeholder: placeholderProp,
  value,
  ...props
}) => {
  const { handle, maskRef, inputRef, showLabel, lazy } = useLogic({
    onBlur,
    onFocus,
    onChange,
    name,
    placeholder: placeholderProp,
    defaultValue: value,
  });

  const accessRef = useCallback(
    (el: HTMLInputElement) => {
      inputRef.current = el;
    },
    [inputRef],
  );

  return (
    <>
      <Container className={className} isError={error} onClick={handle.focus}>
        {showLabel && <Label>{placeholderProp}</Label>}
        <Input
          autofix
          overwrite
          mask={Date}
          pattern={pattern}
          min={min}
          max={max}
          lazy={lazy} // this controls how the placeholder is going to be shown
          format={formatDate}
          parse={parseInput}
          blocks={blocksSchema}
          ref={maskRef}
          inputRef={accessRef}
          name={name}
          onBlur={handle.onBlur}
          onAccept={handle.onChange}
          {...props}
        />
      </Container>
    </>
  );
};

export default memo(DateInput);
