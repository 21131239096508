import { MenuButton } from '@reach/menu-button';
import styled, { css } from 'styled-components';

import BaseCircle from 'components/Circle';

import { ButtonProps } from './types';

export const Container = styled.div`
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
`;

export const Button = styled(MenuButton)<ButtonProps>`
  border-radius: 0.625rem;
  border: none;
  height: 100%;
  justify-content: center;
  width: 100%;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  ${({ $alert, theme }) => {
    return $alert
      ? css`
          background-color: ${theme.colors.bridesmaid};
          color: ${theme.colors.flamingo};
        `
      : css`
          color: ${theme.colors.grey500};
          background-color: ${theme.colors.grey0};
        `;
  }}
`;

export const Circle = styled(BaseCircle)`
  position: absolute;
  right: 0;
  top: 0;
`;
