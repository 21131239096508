import { MenuButton } from '@reach/menu-button';
import styled from 'styled-components';

import DefaultMenu from 'components/Menu';
import { Option } from 'components/Menu/styles';

export const Menu = styled(DefaultMenu)`
  ${Option} {
    width: 6.625rem;
  }
`;

export const Button = styled(MenuButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary50};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 0.625rem;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
  display: inline;

  &[aria-expanded] {
    background-color: ${({ theme }) => theme.colors.primary100};
  }
`;
