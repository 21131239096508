import * as Yup from 'yup';

import { minDate, maxDate } from 'components/DateInput';
import { phoneValidation } from 'components/PhoneInput/constants';
import allOrNone from 'utils/yup/allOrNone';

Yup.addMethod(Yup.object, 'allOrNone', allOrNone);

const requiredFields = ['firstName', 'lastName', 'email'];

const noSpaceRegExp = /^[^\s]+(\s+[^\s]+)*$/;
const noSpaceMessage = 'Leading or trailing whitespaces are not allowed';

export const defaultOther = {
  firstName: '',
  lastName: '',
  birthDate: '',
  email: '',
  phone: '',
};

export const initialValues = (defaultAdvisor?: string) => ({
  advisor: defaultAdvisor || '',
  householdName: '',
  isEmailSent: false,
  primary: {
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phone: '',
  },
  others: [defaultOther],
});

export const validationSchema = Yup.object().shape({
  advisor: Yup.string().label('Advisor').required(),
  householdName: Yup.string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .label('Household name')
    .required(),
  primary: Yup.object().shape({
    firstName: Yup.string()
      .matches(noSpaceRegExp, { message: noSpaceMessage })
      .label('First name')
      .required(),
    lastName: Yup.string()
      .matches(noSpaceRegExp, { message: noSpaceMessage })
      .label('Last name')
      .required(),
    birthDate: Yup.date()
      .label('Birth date')
      .min(minDate)
      .max(maxDate)
      .nullable(),
    email: Yup.string()
      .matches(noSpaceRegExp, { message: noSpaceMessage })
      .label('Email')
      .email('Invalid email')
      .required(),
    phone: phoneValidation.label('Phone number').nullable(),
  }),
  others: Yup.array()
    .required()
    .of(
      Yup.object()
        .shape({
          firstName: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('First name'),
          lastName: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('Last name'),
          birthDate: Yup.date()
            .label('Birth date')
            .min(minDate)
            .max(maxDate)
            .notRequired()
            .nullable(),
          email: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('Email')
            .email('Invalid email'),
          phone: phoneValidation.label('Phone number').notRequired().nullable(),
        })
        .allOrNone(requiredFields),
    ),
});
