const useLogic = () => {
  const values = Array(7)
    .fill(undefined)
    .map((_, index) => index + 2);

  return {
    values,
  };
};

export default useLogic;
