import upperFirst from 'lodash/upperFirst';
import { FC, memo } from 'react';

import initialsOf from 'utils/initialsOf';

import {
  Container,
  Image,
  Information,
  Initials,
  Name,
  Wrapper,
} from './styles';
import { Props } from './types';

const Profile: FC<Props> = ({ groupName, imageURL, name, profileRole }) => (
  <Container>
    {imageURL ? (
      <Initials>
        <Image alt="" src={imageURL} />
      </Initials>
    ) : (
      <Initials>{initialsOf(name)}</Initials>
    )}
    <Wrapper>
      <Name data-testid="profile-span-name">{name}</Name>
      <Information>
        {upperFirst(
          profileRole === 'ADVISOR'
            ? groupName || profileRole.toLowerCase()
            : profileRole.toLowerCase(),
        )}
      </Information>
    </Wrapper>
  </Container>
);

export default memo(Profile);
