import {
  AdvisorQuery,
  AdvisorsQuery,
  AssignableAdvisorsQuery,
  InvestorQuery,
  InvestorsQuery,
  MeQuery,
} from 'graphql/generated';

import { normalizeGroup } from './Group';
import { normalizeAdvisorUser } from './User';

type Input = NonNullable<
  | AdvisorQuery['advisor']
  | NonNullable<NonNullable<AdvisorsQuery['advisors']>['objects']>[0]
  | NonNullable<MeQuery['me']>['advisor']
>;

type MinInput = NonNullable<
  | NonNullable<AssignableAdvisorsQuery['assignableAdvisors']>[0]
  | NonNullable<InvestorQuery['investor']>['advisor']
  | NonNullable<
      NonNullable<
        NonNullable<InvestorsQuery['investors']>['objects']
      >[0]['advisor']
    >
>;

export const normalizeAdvisor = (input: Input) => {
  const { id: userId, ...user } = normalizeAdvisorUser(input.user);
  return {
    company: input.company,
    companyLogo: input.companyLogo,
    shortHash: input?.shortHash || '',
    group: input.group ? normalizeGroup(input.group) : undefined,
    id: input.id,
    investors: input.investorList.length,
    isCustodian: input?.isCustodian as Boolean,
    userId,
    ...user,
  };
};

export const normalizeMinAdvisor = (input: MinInput) => {
  const { id: userId, ...user } = normalizeAdvisorUser(input.user);

  return {
    company: input.company,
    companyLogo: input.companyLogo,
    id: input.id,
    shortHash: input?.shortHash || '',
    investors: input.investorList.length,
    isCustodian: input?.isCustodian as Boolean,
    userId,
    ...user,
  };
};

export const normalizeUserAdvisor = (input: Input) => ({
  company: input.company,
  companyLogo: input.companyLogo,
  group: input.group ? normalizeGroup(input.group) : undefined,
  id: input.id,
  shortHash: input?.shortHash || '',
  totalInvestors: input.investorList.length,
  isCustodian: input?.isCustodian as Boolean,
});

export type Advisor = ReturnType<typeof normalizeAdvisor>;

export type MinAdvisor = ReturnType<typeof normalizeMinAdvisor>;

export type UserAdvisor = ReturnType<typeof normalizeUserAdvisor>;
