import { DraggableLocation } from 'react-beautiful-dnd';

import takeFrom from 'utils/takeFrom';

import { Column } from './types';

export const locationsAreEqual = (a: DraggableLocation, b: DraggableLocation) =>
  Object.entries(a).every(
    ([key, value]) => b[key as keyof DraggableLocation] === value,
  );

export const reorder = (
  columns: Column[],
  source: DraggableLocation,
  destination: DraggableLocation,
  itemsPerColumn: number,
) => {
  const columnsCopy = [...columns];
  const items = columnsCopy.flatMap((column) => column.items);
  const sourceColumnIndex = columnsCopy.findIndex(
    (column) => column.id === source.droppableId,
  );
  const destinationColumnIndex = columnsCopy.findIndex(
    (column) => column.id === destination.droppableId,
  );

  /**
   Each droppable has its array of items and indexes are relative
   to those columns arrays. However to simplify reordering we are
   applying operations over a single flattened array, and we need
   to calculate source and destination indexes based on the column
   index
  */

  const sourceIndex = source.index + sourceColumnIndex * itemsPerColumn;
  let destinationIndex =
    destination.index + destinationColumnIndex * itemsPerColumn;

  /**
   * We need to substract one because all other items move one
   * position backwards
   */

  if (sourceColumnIndex === 0 && destinationColumnIndex > 0) {
    destinationIndex -= 1;
  }

  const [removed] = items.splice(sourceIndex, 1);
  items.splice(destinationIndex, 0, removed);
  const result = columnsCopy.map((column, index) => ({
    ...column,
    items: takeFrom(items, index, itemsPerColumn),
  }));

  return result;
};
