import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Group from 'components/Account/Implement/Group';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useTradeTicketActions from 'context/fastContext/tradeticket/actions';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';

import { validationSchema } from '../constants';
import {
  AccountContainer,
  Form,
  ProductContainer,
  ProductTable,
  TaxContainer,
  TicketRow,
  Title,
  TradeContainer,
} from '../styles';
import AccountInformation from './AccountInformation';
import CashManagement from './CashManagement';
import TaxManagement from './TaxManagement';
import TradeInstructions from './TradeInstructions';
import TradeTicketHelp from './TradeTicketHelp';

function NonCustodian({
  accountToImplement,
  accountType,
  confirmNonCustodianImplement,
  hasErrorImplementingNonCustodian,
  custodianType,
  onClose,
  isOpen,
}: any) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const { createTradeTicket, fetchTradeTicket } = useTradeTicketActions();
  const [{ tradeTicket }] = useTradeTicketStore((s: TradeTicketState) => ({
    tradeTicket: s.tradeTicket,
  }));

  useEffect(() => {
    if (accountToImplement?.id) {
      fetchTradeTicket({ accountId: accountToImplement.id, useCache: false });
    }
  }, [accountToImplement, fetchTradeTicket]);

  useEffect(() => {
    if (!isOpen && showHelp) {
      setShowHelp(false);
    }
  }, [isOpen, showHelp]);

  const initialValues = {
    accountName: accountToImplement?.name || '',
    accountValue: accountToImplement?.amountInvestedDollar || 0,
    accountType: accountType?.name || '',
    accountCustodian: custodianType?.name || '',
    accountNumber: tradeTicket?.accountNumber || '',
    taxLossHarvesting:
      tradeTicket?.taxLossHarvesting ?? !accountType?.isQualified,
    taxTransition:
      tradeTicket?.taxTransition?.toLowerCase() ||
      (accountType?.isQualified ? 'no' : ''),
    capitalGainsBudget: tradeTicket?.capitalGainsBudget || '',
    taxTransitionYears: tradeTicket?.taxTransitionYears || '',
    taxTransitionInstructions: tradeTicket?.taxTransitionInstructions || '',
    initialSetAside: tradeTicket?.initialSetAside || '',
    monthlyCashRaise: tradeTicket?.monthlyCashRaise || '',
    instructionsList: tradeTicket?.instructionsList?.length
      ? tradeTicket.instructionsList.map((inst) => ({
          instructionType: inst.instructionType || '',
          instructions: inst.instructions || '',
        }))
      : [{ instructionType: '', instructions: '' }],
    additionalEmail: '',
  };

  const [{ allProposals, assetClassGroups }] = useProposalStore((s: any) => ({
    allProposals: s.allProposals,
    assetClassGroups: s.assetClassGroups,
  }));

  const associatedProposal = allProposals.find(
    (p: any) => p.id === accountToImplement?.proposalId,
  );

  const formattedAccountValue = useMemo(() => {
    const formattedNumber =
      Number(accountToImplement?.amountInvestedDollar).toLocaleString() || '0';
    return `$${formattedNumber === '0' ? '' : formattedNumber}`;
  }, [accountToImplement?.amountInvestedDollar]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!showConfirmation) {
      setShowConfirmation(true);
    } else {
      setSubmitting(true);
      await createTradeTicket({
        accountId: accountToImplement.id,
        createdTradeTicket: values,
        status: '',
      });
      confirmNonCustodianImplement(true);
      setSubmitting(false);
      setShowConfirmation(false);
      if (onClose) onClose();
    }
  };

  const handleShowHelp = useCallback(() => {
    setShowHelp(!showHelp);
  }, [showHelp]);

  const handleSaveDraft = async (formValues: any, { setSubmitting }: any) => {
    await createTradeTicket({
      accountId: accountToImplement.id,
      createdTradeTicket: formValues,
      status: 'DRAFT',
    });
    confirmNonCustodianImplement(true);
    setSubmitting(false);
    if (onClose) onClose();
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize // Async data loading
      >
        {({
          resetForm,
          values,
          setFieldValue,
          isSubmitting,
          setSubmitting,
          errors,
          touched,
          dirty,
        }) => (
          <Form>
            <TicketRow>
              <AccountContainer>
                <Title>Account Information</Title>
                <AccountInformation
                  accountValue={formattedAccountValue}
                  isSubmitting={isSubmitting}
                  errors={errors}
                  touched={touched}
                />
              </AccountContainer>
              <ProductContainer>
                <Title>Product Allocation</Title>
                <ProductTable>
                  {assetClassGroups.map((acg: any) => {
                    const assetGroupKey = acg.symbol.toLowerCase();
                    return (
                      <Group
                        key={assetGroupKey}
                        assetClassGroup={acg}
                        associatedProposal={associatedProposal}
                        accountValue={accountToImplement?.amountInvestedDollar}
                        isCustodian={false}
                      />
                    );
                  })}
                </ProductTable>
              </ProductContainer>
            </TicketRow>
            <TicketRow>
              <TaxContainer>
                <TaxManagement
                  values={values}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  errors={errors}
                  touched={touched}
                  isQualified={accountType?.isQualified}
                />
                <CashManagement
                  isSubmitting={isSubmitting}
                  errors={errors}
                  touched={touched}
                />
              </TaxContainer>
              <TradeContainer>
                <TradeInstructions
                  instructionsList={values.instructionsList}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              </TradeContainer>
            </TicketRow>
            {!showConfirmation && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <Button
                    disabled={isSubmitting || !dirty}
                    onClick={() => handleSaveDraft(values, { setSubmitting })}
                    variant="contained"
                    type="button"
                    color="warning"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      textTransform: 'initial',
                      minWidth: '120px',
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'initial',
                      minWidth: '120px',
                    }}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Implement
                  </Button>
                </Box>
                <Box sx={{ mx: 'auto' }}>
                  <Button
                    onClick={handleShowHelp}
                    sx={{ textTransform: 'initial' }}
                  >
                    Click Here For Help
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '210px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'initial',
                      minWidth: '120px',
                    }}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      resetForm();
                      onClose();
                      setSubmitting(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
            {showConfirmation && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px',
                  backgroundColor: 'primary.50',
                  gap: '30px',
                  borderRadius: '10px',
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    By confirming your submission, you are instructing Seeds to
                    implement this <br /> account according to your
                    instructions. Note: The custodial account must be <br />
                    opened, in good order, and funded before it can be
                    implemented.
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <Button
                    sx={{ fontWeight: 'bold', textTransform: 'initial' }}
                    variant="contained"
                    type="button"
                    onClick={() => handleSubmit(values, { setSubmitting })}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                  <Button
                    sx={{ fontWeight: 'bold', textTransform: 'initial' }}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      setShowConfirmation(false);
                      setSubmitting(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
            {hasErrorImplementingNonCustodian && (
              <Alert sx={{ marginTop: '8px' }} severity="error">
                Error marking account as implemented. Please try again and if
                the error persists contact support.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
      {showHelp && <TradeTicketHelp close={handleShowHelp} />}
    </Box>
  );
}

export default NonCustodian;
