import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  padding: 0.3125rem;
  width: 1.5rem;

  ${({ theme, variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.primary};
        `;
      case 'secondary':
        return css`
          color: ${theme.colors.primary};
          background-color: ${theme.colors.primary50};
        `;
      case 'transparent':
        return css`
          color: ${theme.colors.grey500};
          background-color: transparent;
        `;

      default:
        return css`
          color: ${theme.colors.grey500};
          background-color: ${theme.colors.grey0};
        `;
    }
  }}
`;
