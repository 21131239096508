import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CustomTooltip from 'v2/components/CustomTooltip';

type ActionButtonsType = {
  disableSave: boolean;
  handleSave: any;
  isSaving: boolean;
  selectedIsSameAsCurrentProposal: boolean;
};

function ActionButtons({
  disableSave,
  handleSave,
  isSaving,
  selectedIsSameAsCurrentProposal,
}: ActionButtonsType) {
  return (
    <Box sx={{ flex: 2, display: 'flex', gap: '12px' }}>
      <Button
        disabled={disableSave}
        onClick={handleSave}
        variant="contained"
        type="submit"
        color="primary"
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          textTransform: 'initial',
        }}
      >
        {isSaving ? 'Saving...' : 'Save'}
      </Button>

      {selectedIsSameAsCurrentProposal && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomTooltip
            title={`${
              selectedIsSameAsCurrentProposal
                ? 'Save is disabled as selections do not differ from the latest save.'
                : ''
            }`}
          />
        </Box>
      )}
    </Box>
  );
}

export default ActionButtons;
