import { memo } from 'react';

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import IconButton from 'components/IconButton';
import InputBox from 'components/InputBox';
import { ResultsWrapper } from 'containers/DataViewContainer/styles';

import AssetResult from './AssetResult';
import useConnect from './connect';
import Placeholder from './Placeholder';
import {
  Container,
  Form,
  CancelIcon,
  Results,
  Title,
  PaginationResults,
  TableFooter,
} from './styles';

const Search = () => {
  const {
    clearSearchInput,
    handleSearch,
    loading,
    notAddedAssets,
    search,
    totalResults,
  } = useConnect();

  return (
    <Container>
      <Title>Search assets</Title>
      <Form>
        <InputBox
          name="search"
          placeholder="Search stocks, bonds, funds..."
          leftIcon={<SearchIcon />}
          onChange={handleSearch}
          rightIcon={
            search && (
              <IconButton
                type="button"
                style={{ padding: 0 }}
                icon={<CancelIcon />}
                title="Clear input"
                variant="transparent"
                onClick={clearSearchInput}
              />
            )
          }
        />
      </Form>
      <Results>
        <Placeholder show={loading} realItemsLength={notAddedAssets.length}>
          {notAddedAssets.map(({ id, name, symbol }) => (
            <AssetResult key={id} id={id} name={name} symbol={symbol} />
          ))}
        </Placeholder>
      </Results>
      {notAddedAssets.length > 0 && (
        <TableFooter>
          <ResultsWrapper>
            {totalResults} <PaginationResults>RESULTS</PaginationResults>
          </ResultsWrapper>
        </TableFooter>
      )}
      {notAddedAssets.length === 0 && totalResults > 0 && (
        <TableFooter>
          <ResultsWrapper>
            {totalResults}{' '}
            <PaginationResults>RESULTS ALREADY IN PORTFOLIO</PaginationResults>
          </ResultsWrapper>
        </TableFooter>
      )}
      {notAddedAssets.length === 0 && totalResults === 0 && (
        <TableFooter>
          <ResultsWrapper>
            {totalResults} <PaginationResults>RESULTS</PaginationResults>
          </ResultsWrapper>
        </TableFooter>
      )}
    </Container>
  );
};

export default memo(Search);
