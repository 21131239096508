import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { AdvisorType } from 'graphql/generated';
import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';
import { logger } from 'services/logger';
import { from, useMediaQuery } from 'styles/media';

import { FormValues } from './constants';

const useConnect = () => {
  const {
    setAdvisorOnboardingPassword,
    validateAdvisorOnboarding,
    validateAdvisorOnboardingError,
    validateAdvisorOnboardingLoading,
  } = useAuthActions();
  const { push } = useHistory();
  const { refetch } = useMe();
  const isMobile = !useMediaQuery(from.tablet);
  const [advisor, setAdvisor] = useState<AdvisorType>();
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const { token, uidb64 } = useParams<{ token: string; uidb64: string }>();

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      try {
        const success = await setAdvisorOnboardingPassword({
          company: values.company,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          newPassword1: values.password,
          newPassword2: values.confirmPassword,
          acceptTerms: values.acceptTerms,
          token,
          uidb64,
        });

        if (success) {
          await refetch();
          setTimeout(() => {
            push('/clients');
          }, 0);
        }
      } catch (error) {
        setErrors({ acceptTerms: (error as Error).message });
      }
    },
    [push, refetch, setAdvisorOnboardingPassword, token, uidb64],
  );

  const validate = useCallback(async () => {
    try {
      const { isAdvisor } = await validateAdvisorOnboarding({
        token,
        uidb64,
      });

      if (isAdvisor) {
        setAdvisor(isAdvisor);
        setIsValidating(false);
      }
    } catch (err) {
      setIsValidating(false);
      logger.error(err);
    }
  }, [token, uidb64, validateAdvisorOnboarding]);

  const errorMessage = useMemo(() => {
    if (validateAdvisorOnboardingError?.message === 'Expired token') {
      return 'This link has expired. Please contact support@seedsinvestor.com to send you a new link. Thank you.';
    }
    if (validateAdvisorOnboardingError?.message === 'Invalid token') {
      return 'This session is invalid. Please retry using the link provided via email. If the error continues, please reach out to support@seedsinvestor.com.';
    }

    if (validateAdvisorOnboardingError !== undefined) {
      return 'An error has happened, please try again or contact support@seedsinvestor.com.';
    }

    return undefined;
  }, [validateAdvisorOnboardingError]);

  useEffect(() => {
    (async () => {
      await validate();
    })();
  }, [validate]);

  return {
    errorMessage,
    handleSubmit,
    isMobile,
    loading: validateAdvisorOnboardingLoading || isValidating,
    advisor,
  };
};

export default useConnect;
