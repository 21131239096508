import styled from 'styled-components';

import { Container as Circle } from 'components/Circle/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;

  & > ${Circle} {
    margin-right: 0.5rem;
  }
`;

export const Label = styled.div`
  color: inherit;
`;

export const Wrapper = styled.span`
  padding: 0px 0px 1px 0px;
  border: 0.175rem solid ${({ theme }) => theme.colors.grey800};
  color: ${({ theme }) => theme.colors.grey800};
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  margin-left: 1.25rem;
`;
