import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border-radius: 0.625rem;
  display: flex;
  height: 3.625rem;
  justify-content: space-between;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 16.25rem;

  ${({ theme, status }) => {
    switch (status) {
      case 'NOT SENT':
        return css`
          background-color: ${theme.colors.grey500};
          color: ${theme.colors.white};
        `;

      case 'SENT':
        return css`
          background-color: ${theme.colors.grey100};
          color: ${theme.colors.grey600};
        `;
      case 'WAITING':
        return css`
          background-color: ${theme.colors.tacao};
          color: ${theme.colors.white};
        `;
      default:
      case 'OPEN':
      case 'COMPLETED':
        return css`
          background-color: ${theme.colors.primary50};
          color: ${theme.colors.primary};
        `;
    }
  }};
`;

export const Text = styled.span`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
`;
