import {
  compareAsc as compareDateAsc,
  compareDesc as compareDateDesc,
} from 'date-fns';

export const isNullOrUndefined = (value: any) =>
  value === null || value === undefined;
const areNullOrUndefined = (...values: any[]) => values.some(isNullOrUndefined);

export function compareStrings(a: string, b: string) {
  const valueA = a.toLowerCase();
  const valueB = b.toLowerCase();
  if (valueA > valueB) return 1;
  if (valueB > valueA) return -1;
  return 0;
}

export default function defaultSort(
  valueA: any,
  valueB: any,
  direction: 'asc' | 'desc' = 'asc',
) {
  let result = 0;

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    result = compareStrings(valueA, valueB);
  }

  if (typeof valueA === 'number' && typeof valueB === 'number') {
    result = valueA - valueB;
  }

  if (valueA instanceof Date && valueB instanceof Date) {
    return direction === 'desc'
      ? compareDateDesc(valueA, valueB)
      : compareDateAsc(valueA, valueB);
  }

  if (areNullOrUndefined(valueA, valueB)) {
    if (!!valueA === !!valueB) {
      result = 0;
    } else if (valueA) {
      result = 1;
    } else {
      result = -1;
    }
  }

  return direction === 'desc' ? result * -1 : result;
}
