import { MenuButton } from '@reach/menu-button';
import { BsTrash } from 'react-icons/bs';
import { FaEllipsisH } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';

import BaseDropdown from 'components/BaseDropdown';

import { ActionComponent, SelectCallback } from '../types';
import { Client, SimpleClient } from './types';

export const Empty = () => <span>Empty</span>;
export const onSelect: SelectCallback = () => null;

const ActionButtonBase = css`
  all: unset;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const ActionButton = styled.button.attrs({ type: 'button' })`
  ${ActionButtonBase};
`;

const DropdownButton = styled(MenuButton)`
  ${ActionButtonBase};
`;

export const ShowInfo: ActionComponent<Client> = ({ rowData }) => {
  function handleClick() {
    JSON.stringify(rowData, null, 2);
  }
  return (
    <ActionButton onClick={handleClick} title="Show info">
      <MdInfoOutline />
    </ActionButton>
  );
};

const BaseRemove: ActionComponent<{ id: string }> = ({
  rowData: { id },
  setData,
}) => {
  function handleClick() {
    if (setData) {
      setData((current) => current.filter((it) => it.id !== id));
    }
  }

  return (
    <ActionButton onClick={handleClick} title="Remove">
      <BsTrash />
    </ActionButton>
  );
};
export const Remove = BaseRemove as ActionComponent<Client>;
export const SimpleRemove = BaseRemove as ActionComponent<SimpleClient>;

export const DropdownAction: ActionComponent<Client> = ({
  rowData,
  setData,
}) => {
  const trigger = (
    <DropdownButton>
      <FaEllipsisH />
    </DropdownButton>
  );

  return (
    <BaseDropdown trigger={trigger}>
      <Remove rowData={rowData} setData={setData} />
      <ShowInfo rowData={rowData} setData={setData} />
    </BaseDropdown>
  );
};
