/* eslint-disable no-console */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { useMemo, useCallback, useState, useEffect } from 'react';

import AccountHeader from 'components/Account/AccountHeader';
import AccountRows from 'components/Account/AccountRows';
import CustodianModal from 'components/Account/Implement/CustodianModal';
import NonCustodianModal from 'components/Account/Implement/NonCustodianModal';
import AddAccountsModal from 'components/Account/Modals/AddAccountModal';
import ConfirmActivateModal from 'components/Account/Modals/ConfirmActivate';
import ConfirmDeleteModal from 'components/Account/Modals/ConfirmDelete';
import RiskNumber from 'components/RiskNumber';
import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import type { AccountState } from 'context/fastContext/account/types';
import useProposalActions from 'context/fastContext/proposal/actions';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import type { ProposalState } from 'context/fastContext/proposal/types';
import useModal from 'graphql/hooks/useModal';

import TradeTicketRequestModal from './Modals/TradeTicketRequestModal';

type AccountToDeleteType = {
  id: string;
  name: string;
};

function Accounts() {
  const { isOpen, open: openEditAllocationsModal } =
    useModal('EDIT_ALLOCATIONS');
  const { setProposalToEdit, removeProposalToEdit } = useProposalActions();
  const [{ allProposals, masterProposal }] = useProposalStore(
    (s: ProposalState) => ({
      allProposals: s.allProposals,
      masterProposal: s.masterProposal,
    }),
  );
  const [
    {
      accounts,
      masterAccount,
      accountTypes,
      accountCustodians,
      isUpdatingAccounts,
      isEditingAccount,
      status,
    },
  ] = useAccountStore((s: AccountState) => ({
    accounts: s.accounts,
    masterAccount: s.masterAccount,
    accountTypes: s.accountTypes,
    accountCustodians: s.accountCustodians,
    isUpdatingAccounts: s.isUpdatingAccounts,
    isEditingAccount: s.isEditingAccount,
    status: s.status,
  }));

  const {
    handleEditAccountInteraction,
    handleRemoveEditAccount,
    fetchAccounts,
    deleteAccount,
    activateAccounts,
    fetchHistory,
    handleUpdateLoadingState,
    implementAlturistAccount,
  } = useAccountActions();

  useEffect(() => {
    if (isEditingAccount && !isOpen) {
      handleRemoveEditAccount();
      removeProposalToEdit();
    }
  }, [handleRemoveEditAccount, isEditingAccount, isOpen, removeProposalToEdit]);

  const [hasErrorImplementingAlturist, setHasErrorImplementingAlturist] =
    useState<boolean>(false);
  const [
    hasErrorImplementingNonCustodian,
    setHasErrorImplementingNonCustodian,
  ] = useState<boolean>(false);
  const [accountToDelete, setAccountToDelete] =
    useState<AccountToDeleteType | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmActivate, setShowConfirmActivate] =
    useState<boolean>(false);
  const [hasErrorActivating, setHasErrorActivating] = useState<boolean>(false);
  const [accountToImplement, setAccountToImplement] = useState<string | null>(
    null,
  );
  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);

  const [accountInRequest, setAccountInRequest] = useState<string | null>(null);
  const [actionType, setActionType] = useState<string | null>(null);
  const [confirmErrors, setConfirmErrors] = useState<string | null>(null);

  const disableActivate = useMemo(() => {
    return status !== 'draft';
  }, [status]);

  const hasSavedAccounts = useMemo(
    () => accounts.length > 0,
    [accounts.length],
  );

  const handleLoading = useCallback(
    (open) => {
      if (open) {
        document.body.style.overflow = 'hidden';
        handleUpdateLoadingState(true);
      } else {
        document.body.style.overflow = '';
        handleUpdateLoadingState(false);
      }
    },
    [handleUpdateLoadingState],
  );

  const handleAddAccount = useCallback(() => {
    setShowAddAccountModal(true);
  }, []);

  const handleCloseAddAccount = useCallback(() => {
    setShowAddAccountModal(false);
  }, []);

  const handleActivateChanges = useCallback(() => {
    setShowConfirmActivate(true);
  }, []);

  const handleTradeTicketRequest = useCallback((action, accountId) => {
    setAccountInRequest(accountId);
    setActionType(action);
  }, []);

  const handleConfirmActivate = useCallback(async () => {
    const isSuccess = await activateAccounts();

    if (isSuccess) {
      if (hasErrorActivating) {
        setHasErrorActivating(false);
      }
      setShowConfirmActivate(false);
      await fetchAccounts({ useCache: false, isUpdating: true });
      await fetchHistory({ useCache: false, isUpdating: true });
    } else {
      setHasErrorActivating(true);
    }
  }, [activateAccounts, fetchAccounts, fetchHistory, hasErrorActivating]);

  const handleDeleteAccount = useCallback((e) => {
    const { dataset } = e.currentTarget;
    const accId = dataset.id;
    const accName = dataset.name;

    setAccountToDelete({ name: accName, id: accId });
    setShowConfirmDelete(true);
  }, []);

  const handleConfirmDeleteAccount = useCallback(async () => {
    const isSuccess = await deleteAccount({ accountId: accountToDelete?.id! });
    if (isSuccess) {
      handleLoading(true);
      await fetchAccounts({ useCache: false, isUpdating: true });
      handleLoading(false);
      setShowConfirmDelete(false);
    } else {
      setConfirmErrors(
        'Error deleting account, please try again or contact support',
      );
    }
  }, [accountToDelete?.id, deleteAccount, fetchAccounts, handleLoading]);

  const handleEditAccount = useCallback(
    (e) => {
      const { id: accountId } = e.currentTarget.dataset;
      const accountToEdit = accounts.find((a: any) => a.id === accountId)!;
      const proposalToEdit = allProposals.find(
        (p: any) => p.id === accountToEdit?.proposalId,
      );
      handleEditAccountInteraction({ accountToEdit });
      setProposalToEdit({ proposalToEdit: proposalToEdit! });
      openEditAllocationsModal();
    },
    [
      accounts,
      allProposals,
      handleEditAccountInteraction,
      openEditAllocationsModal,
      setProposalToEdit,
    ],
  );

  const handleImplementAccount = useCallback((e) => {
    const { id: accountId } = e.currentTarget.dataset;
    setAccountToImplement(accountId);
  }, []);

  const handleConfirmImplement = useCallback(async () => {
    if (hasErrorImplementingAlturist) {
      setHasErrorImplementingAlturist(false);
    }
    const isSuccess = await implementAlturistAccount({
      accountId: accountToImplement!,
    });

    if (isSuccess) {
      setAccountToImplement(null);
      await fetchAccounts({ useCache: false, isUpdating: true });
      await fetchHistory({ useCache: false, isUpdating: true });
    } else {
      setHasErrorImplementingAlturist(true);
    }
  }, [
    accountToImplement,
    fetchAccounts,
    fetchHistory,
    hasErrorImplementingAlturist,
    implementAlturistAccount,
  ]);

  const handleNonCustodianImplement = useCallback(
    async (isSuccess) => {
      if (hasErrorImplementingNonCustodian) {
        setHasErrorImplementingNonCustodian(false);
      }
      if (isSuccess) {
        setAccountToImplement(null);
        await fetchAccounts({ useCache: false, isUpdating: true });
        await fetchHistory({ useCache: false, isUpdating: true });
      } else {
        setHasErrorImplementingNonCustodian(true);
      }
    },
    [fetchAccounts, fetchHistory, hasErrorImplementingNonCustodian],
  );

  return (
    <Box sx={{ position: 'relative' }}>
      {isUpdatingAccounts && (
        <Box data-testid="updating-accounts-loader">
          <Box
            sx={(theme) => ({
              position: 'fixed',
              top: 0,
              left: 0,
              opacity: 0.5,
              width: '100%',
              height: '100%',
              zIndex: 9998,
              backgroundColor: theme.palette.grey[100],
            })}
          />
          <Box
            sx={(theme) => ({
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              padding: '24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.primary.main,
            })}
          >
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      )}
      <AccountHeader
        hasSavedAccounts={hasSavedAccounts}
        handleAddAccount={handleAddAccount}
      />

      {hasSavedAccounts && (
        <AccountRows
          handleDeleteAccount={handleDeleteAccount}
          handleEditAccount={handleEditAccount}
          handleImplementAccount={handleImplementAccount}
          handleTradeTicketRequest={handleTradeTicketRequest}
          accounts={accounts}
          accountTypes={accountTypes}
          allProposals={allProposals}
          accountCustodians={accountCustodians}
        />
      )}

      {hasSavedAccounts && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            padding: '1.25rem',
            display: 'flex',
            gap: '8px',
            borderRadius: '6px',
          })}
        >
          <Box
            sx={{
              flex: 5,
              display: 'flex',
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box flex={9}>Total</Box>
            <Box flex={7} />
            <Box flex={5}>
              ${Number(masterAccount?.totalInvested).toLocaleString()}
            </Box>
            <Box flex={3}>{masterProposal.allocations?.EQ ?? 0}%</Box>
            <Box flex={3}>{masterProposal.allocations?.BD ?? 0}%</Box>
            <Box
              sx={{
                flex: 4,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <RiskNumber number={masterProposal.riskScore!} />
            </Box>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip
              title={
                disableActivate
                  ? 'Disabled since no changes have been made since last save.'
                  : ''
              }
            >
              <Box sx={{ width: '100%', display: 'flex' }}>
                <Button
                  disabled={disableActivate}
                  onClick={handleActivateChanges}
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'initial',
                    width: '100%',
                  }}
                  variant="contained"
                  data-testid="save-to-vault"
                >
                  Save To Vault
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )}

      {hasSavedAccounts && ( // TODO: Redo these modals...
        <>
          <ConfirmDeleteModal
            isOpen={showConfirmDelete}
            setShowConfirmDelete={setShowConfirmDelete}
            confirmDelete={handleConfirmDeleteAccount}
            accountToDelete={accountToDelete}
            confirmErrors={confirmErrors}
            setConfirmErrors={setConfirmErrors}
          />
          <ConfirmActivateModal
            isOpen={showConfirmActivate}
            setShowConfirmActivate={setShowConfirmActivate}
            confirmActivate={handleConfirmActivate}
            hasErrorActivating={hasErrorActivating}
            setHasErrorActivating={setHasErrorActivating}
          />
          <NonCustodianModal
            accountId={accountToImplement}
            setAccountToImplement={setAccountToImplement}
            confirmNonCustodianImplement={handleNonCustodianImplement}
            hasErrorImplementingNonCustodian={hasErrorImplementingNonCustodian}
            accounts={accounts}
            accountTypes={accountTypes}
            accountCustodians={accountCustodians}
          />
          <CustodianModal
            accountId={accountToImplement}
            setAccountToImplement={setAccountToImplement}
            confirmImplement={handleConfirmImplement}
            hasErrorImplementingAlturist={hasErrorImplementingAlturist}
            accounts={accounts}
            accountCustodians={accountCustodians}
          />
          <TradeTicketRequestModal
            accountId={accountInRequest}
            accounts={accounts}
            accountTypes={accountTypes}
            actionType={actionType}
            setAccountInRequest={setAccountInRequest}
            hasErrorImplementingNonCustodian={hasErrorImplementingNonCustodian}
          />
        </>
      )}

      <AddAccountsModal
        isOpen={showAddAccountModal}
        close={handleCloseAddAccount}
      />
    </Box>
  );
}

export default Accounts;
