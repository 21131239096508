import styled, { css } from 'styled-components';

import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as QuestionCircle } from 'assets/svg/question-circle.svg';
import { addAlpha } from 'utils/addAlpha';

import { BarProps } from './types';

export const Container = styled.div``;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 1.125rem;
`;

export const BarContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 170px;
  padding: 0.375rem;
`;

export const CheckIcon = styled(Check)`
  color: ${({ theme }) => theme.colors.primary10};
  display: none;
`;

export const BarItem = styled.div<BarProps>`
  display: flex;
  align-items: center;
  opacity: 25%;

  :not(:first-child) {
    width: 50%;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 100%;
      ${CheckIcon} {
        display: block;
      }
    `}
`;

export const ProgressCheckGroup = styled.div`
  position: relative;
`;

export const ProgressCheck = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 50%;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  margin-left: -0.0625rem;
  width: 1.25rem;
`;

export const ProgressLine = styled.div`
  margin-left: -0.0625rem;
  height: 0.375rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary300};
`;

export const ItemTitle = styled.h4`
  display: flex;
  position: absolute;
  margin-top: 0.6875rem;
  right: 50%;
  transform: translateX(50%);
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  color: ${({ theme }) => theme.colors.dark};
  letter-spacing: 0.0625rem;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const FirstItemTitle = styled(ItemTitle)`
  left: 0;
  transform: translateX(0%);
`;

export const LastItemTitle = styled(ItemTitle)`
  right: 0;
  transform: translateX(0%);
`;

export const Tooltip = styled.div`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  position: absolute;
  width: 12.5rem;
  margin-top: 1.625rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  left: 86%;
  box-shadow: 0 0 0.625rem ${({ theme }) => addAlpha(theme.colors.grey600, 0.2)};
  font-size: 0.625rem;
  white-space: normal;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TooltipIcon = styled(QuestionCircle)`
  align-self: center;
  margin-left: 0.25rem;
  cursor: pointer;
  :hover {
    ~ ${Tooltip} {
      opacity: 1;
    }
  }
`;
