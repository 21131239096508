import { gql } from '@apollo/client';

export const SAVE_CHECKBOX = gql`
  mutation SaveCheckbox($input: CheckboxAnswerInput!, $stepResultId: UUID!) {
    saveCheckboxAnswer(input: $input, stepResultId: $stepResultId) {
      ok
    }
  }
`;

export const SAVE_CHOICE = gql`
  mutation SaveChoice($input: ChoiceAnswerInput!, $stepResultId: UUID!) {
    saveChoiceAnswer(input: $input, stepResultId: $stepResultId) {
      ok
      end
    }
  }
`;

export const SAVE_ORDER_ITEMS = gql`
  mutation SaveOrderItems($input: OrderItemAnswerInput!, $stepResultId: UUID!) {
    saveOrderItemsAnswer(input: $input, stepResultId: $stepResultId) {
      ok
    }
  }
`;

export const SAVE_RANGE = gql`
  mutation SaveRange($input: RangeAnswerInput!, $stepResultId: UUID!) {
    saveRangeAnswer(input: $input, stepResultId: $stepResultId) {
      ok
    }
  }
`;

export const SUBMIT_SURVEY = gql`
  mutation SubmitSurvey($investorId: UUID!, $email: String) {
    sendSurvey(id: $investorId, email: $email) {
      ok
    }
  }
`;

export const SAVE_RISK_LEVEL = gql`
  mutation setRiskLevel($id: UUID!, $incomeLevel: String, $riskLevel: Int) {
    setRiskLevel(id: $id, riskLevel: $riskLevel, incomeLevel: $incomeLevel) {
      investor {
        id
      }
    }
  }
`;

export const SAVE_PROPOSAL_ALLOCATIONS = gql`
  mutation editProposalAllocations($allocationsInput: AllocationsInput!) {
    editProposalAllocations(allocationsInput: $allocationsInput) {
      investor {
        id
      }
    }
  }
`;
