import useAssignableAdvisors from 'graphql/hooks/useAssignableAdvisors';
import { MinAdvisor } from 'models/Advisor';

const useConnect = () => {
  const { advisors, loading } = useAssignableAdvisors();

  const sortFunction = (a: MinAdvisor, b: MinAdvisor) => {
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  };

  return {
    advisors,
    loading,
    sortFunction,
  };
};

export default useConnect;
