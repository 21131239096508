import styled from 'styled-components';

import DefaultPersonalityProfile from 'components/PersonalityProfile';
import SurveyResultsCard from 'components/SurveyResultsCard';
import DefaultTable from 'components/Table';
import { from } from 'styles/media';
import { lineClamp } from 'styles/mixins';

export const ValuesContainer = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
`;

export const Container = styled(SurveyResultsCard)`
  margin: 5rem auto;
  width: 100%;
`;

export const ModuleItemContainer = styled.div`
  ${from.tablet} {
    & + & {
      margin-left: 1.875rem;
    }
  }
`;

export const ModuleItemsWrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Table = styled(DefaultTable)`
  min-width: unset;

  tbody {
    border: 0;

    tr td:first-child {
      ${lineClamp(2)}
    }
  }
`;

export const ItemDescriptionWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const PersonalityProfile = styled(DefaultPersonalityProfile)`
  margin-bottom: 3rem;
`;

export const SurveyResultsTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 0.925rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-bottom: 1.5rem;
`;
