import PhoneIcon from '@mui/icons-material/Phone';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { determineProposalState } from 'context/fastContext/helpers';

import {
  // surveyStatusSortModel,
  riskColors,
} from './constants';

const columns: GridColDef[] = [
  {
    field: 'householdName',
    headerName: 'Household',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        placement="bottom-start"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'white',
              color: 'text.secondary',
              boxShadow: 1,
              fontSize: '0.875rem',
              '& .MuiTooltip-arrow': {
                color: 'white',
              },
            },
          },
        }}
        title={
          <Box sx={{ padding: '12px' }}>
            <Typography color="common.black">{params.row.name}</Typography>
            <Typography color="grey.500" variant="caption">
              {params.row.company}
            </Typography>
            {params.row.phone && (
              <Typography
                color="primary"
                sx={{ marginTop: '8px', display: 'flex' }}
              >
                <PhoneIcon
                  sx={{
                    backgroundColor: 'primary.50',
                    borderRadius: '8px',
                    p: '3px',
                    marginRight: '6px',
                  }}
                  color="primary"
                />
                {params.row.phone}
              </Typography>
            )}
          </Box>
        }
      >
        <Link
          style={{
            color: 'initial',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          to={`/clients/${params.row.id}/profile`}
          data-testid="client-profile-link"
        >
          {params.value}
        </Link>
      </Tooltip>
    ),
    minWidth: 100,
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    renderCell: (params: GridRenderCellParams) => params.value,
    flex: 2,
    minWidth: 100,
  },
  {
    field: 'email',
    headerName: 'Email',
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
    flex: 2,
    minWidth: 100,
  },
  {
    field: 'mindset',
    headerName: 'Mindset',
    valueGetter: (params: GridValueGetterParams) =>
      params.value ||
      params.row.investorProfile?.personalityBadge?.name ||
      'Not Defined',
    renderCell: (params: GridRenderCellParams) =>
      params.value === 'Not Defined' ? (
        <Box color="#868E96">{params.value}</Box>
      ) : (
        params.value
      ),
    flex: 2,
    minWidth: 100,
  },
  {
    field: 'riskLevel',
    headerName: 'Risk Target',
    align: 'center',
    sortComparator: (v1, v2) => {
      const value1 = typeof v1 === 'string' ? 0 : v1;
      const value2 = typeof v2 === 'string' ? 0 : v2;
      return value2 - value1;
    },
    valueGetter: (params: GridValueGetterParams) =>
      params.value || params.row.investorProfile?.riskLevel || 'Not Defined',
    renderCell: (params: GridRenderCellParams) => {
      if (typeof params.value === 'object') return params.value;
      return typeof params.value === 'number' ? (
        <Box
          sx={{
            width: '2rem',
            height: '2rem',
            borderRadius: '100%',
            backgroundColor: riskColors[params.value],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '800',
          }}
        >
          {params.value}
        </Box>
      ) : (
        <Box color="#868E96">{params.value}</Box>
      );
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'epiPriority',
    headerName: 'EPI Priority',
    valueGetter: (params: GridValueGetterParams) =>
      params.value || params.row.investorProfile?.epiPriority || 'Not Defined',
    renderCell: (params: GridRenderCellParams) =>
      params.value === 'Not Defined' ? (
        <Box color="#868E96">{params.value}</Box>
      ) : (
        params.value
      ),
    flex: 1,
    minWidth: 100,
  },
  // {
  //   field: 'surveyStatus',
  //   headerName: 'Survey',
  //   valueGetter: (params: GridValueGetterParams) =>
  //     params.value || 'Not Defined',
  //   flex: 1,
  //   renderCell: (params: GridRenderCellParams) => params.value,
  //   sortComparator: (v1, v2) => {
  //     const sortOrderValue1 = surveyStatusSortModel[v1] || 999;
  //     const sortOrderValue2 = surveyStatusSortModel[v2] || 999;

  //     return sortOrderValue1 - sortOrderValue2;
  //   },
  //   minWidth: 100,
  // },
  {
    field: 'progress',
    headerName: 'Progress',
    flex: 3,
    disableExport: true,
    valueGetter: (params: GridValueGetterParams) => {
      return params.value || params.row || 'Not Defined';
    },
    renderCell: (params) => {
      if (!params.row.proposals) {
        return <Box color="#868E96">{params.value}</Box>;
      }
      const proposals = params.value
        ? params.value.proposals.filter((p: any) => p.status !== 'deactivated')
        : params.value;
      const proposalState = proposals?.length
        ? determineProposalState(proposals)
        : 'DRAFT';
      let label: string;
      if (params.row.investorProfile.status !== 'COMPLETE') {
        label = 'Awaiting Assessment';
      } else {
        switch (proposalState) {
          case 'IMPLEMENTED':
            label = 'Accounts Implemented';
            break;
          case 'PENDING':
            label = 'Accounts In Progress';
            break;
          case 'ACTIVE':
            label = 'Proposal Complete';
            break;
          default:
          case 'DRAFT':
            label = 'Assessment Complete';
            break;
        }
      }

      return <Box>{label}</Box>;
    },
    sortComparator: (v1, v2) => {
      const map = ['DRAFT', 'ACTIVE', 'PENDING', 'IMPLEMENTED'];

      const proposalState1 = v1.proposals?.length
        ? determineProposalState(
            v1.proposals.filter((p: any) => p.status !== 'deactivated'),
          )
        : 'DRAFT';
      const proposalState2 = v2.proposals?.length
        ? determineProposalState(
            v2.proposals.filter((p: any) => p.status !== 'deactivated'),
          )
        : 'DRAFT';
      const s1 =
        v1.investorProfile.status !== 'COMPLETE'
          ? -1
          : map.indexOf(proposalState1);
      const s2 =
        v2.investorProfile.status !== 'COMPLETE'
          ? -1
          : map.indexOf(proposalState2);

      return s1 - s2;
    },
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    renderCell: (params: GridRenderCellParams) => params.value,
    flex: 1,
    minWidth: 100,
  },
];

export const companyColumn = {
  field: 'company',
  headerName: 'Company',
  renderCell: (params: GridRenderCellParams) => params.value,
  flex: 1,
  minWidth: 100,
};

export default columns;
