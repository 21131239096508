import { gql } from '@apollo/client';

import { ASSET_FRAGMENT } from './asset';

export const SECTOR_TYPE_FRAGMENT = gql`
  fragment SectorParts on SectorType {
    sector
    value
  }
`;

export const PORTFOLIO_ASSET_FRAGMENT = gql`
  fragment PortfolioAssetParts on PortfolioAssetType {
    id
    asset {
      ...AssetParts
    }
    monetaryValue
    sharesQuantity
    percentage
  }
  ${ASSET_FRAGMENT}
`;

export const MIN_PORTFOLIO_FRAGMENT = gql`
  fragment MinPortfolioParts on PortfolioType {
    id
    incomplete
  }
`;

export const PORTFOLIO_FRAGMENT = gql`
  fragment PortfolioParts on PortfolioType {
    ...MinPortfolioParts
    creationMethod
    assetList {
      ...PortfolioAssetParts
    }
    equity
    fixed
    other
    earthScore
    peopleScore
    integrityScore
    valueScore
    sectors {
      sector
      value
    }
  }
  ${MIN_PORTFOLIO_FRAGMENT}
  ${PORTFOLIO_ASSET_FRAGMENT}
`;

export const SEEDS_PROPOSAL_FRAGMENT = gql`
  fragment SeedsProposalParts on SeedsProposalType {
    id
    epiPriority
    largeCap
    midCap
    smallCap
    international
    coreFixed
    muni
    earthScore
    peopleScore
    integrityScore
    valueScore
    sectors {
      ...SectorParts
    }
    jcProductSustainability
    jcRenewableEnergy
    jcRecycling
    jcHumanRights
    jcDataPrivacy
    jcInclusion
    jcEthicalLeadership
    jcStakeholderInterests
    jcConsumerProtection
    jcAverageRank
    holdingExamples {
      holdingExampleType
      items {
        category
        description
        title
      }
    }
  }
  ${SECTOR_TYPE_FRAGMENT}
`;

export const MIN_CURRENT_PORTFOLIO_FRAGMENT = gql`
  fragment MinCurrentPortfolioParts on CurrentPortfolioType {
    id
    portfolio {
      ...MinPortfolioParts
    }
  }
  ${MIN_PORTFOLIO_FRAGMENT}
`;

export const CURRENT_PORTFOLIO_FRAGMENT = gql`
  fragment CurrentPortfolioParts on CurrentPortfolioType {
    id
    portfolio {
      ...PortfolioParts
    }
    seedsProposal {
      ...SeedsProposalParts
    }
  }
  ${PORTFOLIO_FRAGMENT}
  ${SEEDS_PROPOSAL_FRAGMENT}
`;
