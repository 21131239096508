import { useCallback, useMemo } from 'react';

import { Option } from 'components/DraggableOptions/types';

import { ConnectProps } from './types';

const useConnect = ({ initialValue, onAnswer, orderItems }: ConnectProps) => {
  const options = useMemo(
    () =>
      initialValue?.length
        ? orderItems.sort(
            (a, b) => initialValue.indexOf(a.id) - initialValue.indexOf(b.id),
          )
        : orderItems,
    [initialValue, orderItems],
  );

  const handleAnswer = useCallback(
    (opts: Option[]) => {
      onAnswer(opts.map((item) => item.id));
    },
    [onAnswer],
  );

  return {
    handleAnswer,
    options,
  };
};

export default useConnect;
