import createFastContext from 'context/fastContext/createFastContext';

import { TradeTicketState } from './types';

const initialState: TradeTicketState = {
  tradeTicket: {},
  tradeTicketRequest: {},
  tradeTickets: [],
  ticketHistory: [],
  isLoadingAccounts: false,
  isUpdatingAccounts: false,
  isEditingAccount: false,
  isViewingTicketHistory: false,
};

const { Provider, useStore } =
  createFastContext<TradeTicketState>(initialState);

export { Provider as TradeTicketProvider, useStore };
