/* eslint-disable no-console */
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import AuthFetch from 'utils/authFetch';
import roundToNDecimalsIfNeeded from 'utils/proposal/roundToNDecimalsIfNeeded';

import {
  sectorsMapper,
  calculateAllocations,
  determineProposalState,
  calculateRiskScore,
} from '../helpers';
import { useStore, defaultState } from './context';
import { ProposalType, AssetGroupType, MinProposal, Holding } from './types';

function useProposalActions() {
  const { id: investorId } = useParams<{ id: string }>();
  const [assetClassGroups, setStore] = useStore((s) => s.assetClassGroups);

  const fetchAvailableProducts = useCallback(async () => {
    try {
      const data = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/core/available-products`,
        queryParams: { id: investorId },
        method: 'GET',
      });

      const investorProducts = data.payload.products;
      const allPlatformProducts = data.payload.allProducts;

      // Verify that we are getting groups that match
      const availableProductKeys = Object.keys(investorProducts);
      const missingGroups =
        availableProductKeys.filter((apk) => investorProducts[apk].length === 0)
          .length > 0;
      // This is assuming that for each parent asset class we should have groups of products they can select from
      if (missingGroups) {
        setStore({ hasError: true });
      } else {
        setStore({
          availableProducts: investorProducts,
          allProducts: allPlatformProducts,
        });
      }
    } catch (e: any) {
      setStore({ hasError: true });
    }
  }, [investorId, setStore]);

  const fetchAssetClassGroups = useCallback(async (): Promise<any> => {
    try {
      const data = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/core/asset-classes`,
        method: 'GET',
        useCache: true,
      });

      const sorted: any = data.payload.sort(
        (a: any, b: any) => a.assetOrder - b.assetOrder,
      );
      setStore({ assetClassGroups: sorted });
      return sorted;
    } catch (e: any) {
      setStore({ hasError: true });
      return null;
    }
  }, [setStore]);

  const initialProposalFetch = useCallback(async (): Promise<any> => {
    setStore({ isLoadingProposalData: true });
    try {
      await fetchAvailableProducts();
      const assetClasses = await fetchAssetClassGroups();
      return assetClasses;
    } catch (e) {
      console.log('Error with initial proposal fetch', e);
      setStore({ hasError: true });
    } finally {
      setStore({ isLoadingProposalData: false });
    }

    return null;
  }, [fetchAssetClassGroups, fetchAvailableProducts, setStore]);

  const setProposalToEdit = useCallback(
    ({ proposalToEdit }: { proposalToEdit: MinProposal }) => {
      setStore({ proposalBeingEdited: { ...proposalToEdit } });
    },
    [setStore],
  );

  const removeProposalToEdit = useCallback(() => {
    setStore({ proposalBeingEdited: {} });
  }, [setStore]);

  const setMasterProposal = useCallback(
    ({
      proposal,
      assetClasses,
    }: {
      proposal: ProposalType;
      assetClasses?: AssetGroupType[];
    }) => {
      const assetsToUse = assetClassGroups.length
        ? assetClassGroups
        : assetClasses;

      const allocations = calculateAllocations(
        proposal.assetClasses,
        assetsToUse!,
      );

      const sectors = sectorsMapper(proposal.score);

      const combined: { [key: string]: Holding } = {};
      const holdings = proposal.holdings.map((h, i) => {
        const assetId = h.asset.id;
        if (combined[assetId]) {
          const sum = roundToNDecimalsIfNeeded(
            combined[assetId].actualWeight! + h.actualWeight!,
            3,
          );
          combined[assetId].actualWeight = sum;
        } else {
          combined[assetId] = {
            ...h,
            actualWeight: roundToNDecimalsIfNeeded(h.actualWeight!, 3),
          };
        }
        return {
          ...h,
          id: i,
          actualWeight: roundToNDecimalsIfNeeded(h.actualWeight!, 3),
        };
      });

      const riskScore = calculateRiskScore(allocations.EQ); // TODO: Temporary?

      // Too many holdings for print to PDF if holdings aren't combined
      const combinedHoldings = Object.values(combined);
      setStore({
        masterProposal: {
          ...proposal,
          allocations,
          sectors,
          // Master with accounts doesn't return products but some places in code
          // expects it so just putting this here but should revisit later
          ...(proposal.products ? {} : { products: [] }),
          status: proposal.status || 'active',
          holdings,
          combinedHoldings,
          riskScore, // Overriding risk passed in until BE rounds
        },
      });
    },
    [assetClassGroups, setStore],
  );

  const setProposals = useCallback(
    ({
      proposals,
      assetClasses,
    }: {
      proposals: ProposalType[];
      assetClasses?: AssetGroupType[];
    }) => {
      const assetsToUse = assetClassGroups.length
        ? assetClassGroups
        : assetClasses;

      const proposalWithBreakdown = proposals.map((p) => {
        const allocations = calculateAllocations(p.products, assetsToUse!);
        const riskScore = calculateRiskScore(allocations.EQ); // TODO: Temporary?
        return {
          ...p,
          allocations,
          riskScore,
        };
      });

      const stateOfProposals = determineProposalState(proposals);

      setStore({
        allProposals: proposalWithBreakdown,
        proposalState: stateOfProposals,
      });
    },
    [assetClassGroups, setStore],
  );

  const resetProposal = useCallback(() => {
    setStore({ ...defaultState });
  }, [setStore]);

  return {
    fetchAvailableProducts,
    fetchAssetClassGroups,
    initialProposalFetch,
    setProposalToEdit,
    removeProposalToEdit,
    setMasterProposal,
    setProposals,
    resetProposal,
  };
}

export default useProposalActions;
