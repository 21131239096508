import { FC } from 'react';

import theme from 'styles/v2/theme';

import { Circle, ItemText } from '../../styles';
import { Container } from './styles';
import { Props } from './types';

const Tooltip: FC<Props> = ({ active, payload }) => {
  if (!active) return null;
  const { title, value } = payload?.[0].payload;
  const color = theme.palette.primary.main;

  return (
    <Container>
      <Circle background={color} />
      <ItemText>
        {title}: {value.toFixed(2)}
      </ItemText>
    </Container>
  );
};

export default Tooltip;
