import styled from 'styled-components';

import { SeedsCoreEquity as DefaultSeedsCoreEquity } from 'components/ImpactSnapshot/SeedsCoreEquity';
import DefaultStockStories from 'components/ImpactSnapshot/StockStories';
import { ImpactSnapshotWrapper as DefaultImpactSnapshotWrapper } from 'components/ImpactSnapshotWrapper';
import DefaultPersonalityProfile from 'components/PersonalityProfile';
import { TextWrap } from 'styles/mixins';

export const Hidden = styled.div`
  display: none;
`;

export const Block = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 0.625rem;
  padding: 1rem 1.25rem 1.25rem 1.25rem;

  @media print {
    padding: 0.85rem 1rem 1rem 1rem;
  }
`;

export const BlockParagraph = styled.p`
  line-height: calc(20 / 14);
  letter-spacing: 0;
  font-size: 0.875rem;
  font-family: 'Greycliff CF';
  color: ${({ theme }) => theme.colors.dark};

  & + & {
    margin-top: 0.75rem;
  }

  @media print {
    font-size: 0.75;
    & + & {
      margin-top: 0.5rem;
    }
  }
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Container = styled.div`
  height: 100%;
  margin: 0;
  max-width: 60rem;
  padding: 0;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 14px;
  letter-spacing: 0;
  line-height: calc(18 / 12);
  margin: 0.5rem 0 0.875rem 0;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const Disclosures = styled(Description).attrs(() => ({ as: 'div' }))`
  font-size: 0.8375rem;
  p {
    margin: 0;

    &:not(last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const Text = styled.p`
  ${Description};
  color: ${({ theme }) => theme.colors.grey600};
  margin-bottom: 1.25rem;
`;

export const GoalsResultsWrapper = styled.div`
  > * {
    margin-bottom: 0.75rem;
  }

  display: flex;
  justify-content: space-between;
  > * {
    flex: 1;
    margin-bottom: 0;
    padding: 0.93rem 1rem 1rem 1rem;
  }

  > * + * {
    margin-left: 1.25rem;
  }
`;

export const ModuleItemContainer = styled.div``;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;

  ${ModuleItemContainer} {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const ModuleItemsWrapper = styled.div`
  > * {
    margin-top: 2rem;
  }
`;

export const ModuleCompact = styled.div`
  ${ModuleItemsWrapper} {
    > * {
      margin-top: 0.7rem;
    }
  }
`;

export const Module = styled.div`
  margin: 2rem 0;

  & + ${ModuleCompact} {
    margin: 1rem 0;
  }
`;

export const SectionModuleTitle = styled.h3`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  color: ${({ theme }) => theme.colors.dark};
`;

export const Result = styled.div`
  margin-bottom: 1rem;
  .Priorities > div {
    font-size: x-small;
  }
`;

export const ResultTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: 1.125rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;

  ${TextWrap}
`;

export const SectionTitle = styled.h2`
  margin-bottom: 1.5rem;
  line-height: calc(30 / 21);
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.3125rem;
  color: ${({ theme }) => theme.colors.dark};
`;

export const ChartsSectionTitle = styled(SectionTitle)`
  margin-bottom: 1.5rem;
`;

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;

  ${Description} {
    height: 2.25rem;
  }
`;

export const Title = styled.h1`
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ValuesContainer = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  display: flex;
  justify-content: space-between;
`;

export const PersonalityProfile = styled(DefaultPersonalityProfile)`
  margin-bottom: 1.5rem;
`;

export const SeedsCoreEquity = styled(DefaultSeedsCoreEquity)`
  p {
    font-size: 0.5rem;
  }
`;
export const StockStories = styled(DefaultStockStories)`
  p {
    font-size: 0.5rem;
  }
`;

export const ImpactSnapshotWrapper = styled(DefaultImpactSnapshotWrapper)`
  margin-top: 1rem;
  ${SeedsCoreEquity};
  ${StockStories};
`;

export const ItemDescription = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  margin-top: 1rem;
  line-height: 1;
`;
