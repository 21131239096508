import { Field } from 'formik';

import Input, { InputGroup, InputError } from 'components/Input';

import { Container } from '../styles';

function AccountInformation({
  accountValue,
  isSubmitting,
  errors,
  touched,
}: {
  accountValue: any;
  isSubmitting: any;
  errors: any;
  touched: any;
}) {
  return (
    <Container style={{ width: '284px' }}>
      <InputGroup>
        <Field
          as={Input}
          name="accountName"
          placeholder="Account Name"
          autoComplete="account-name"
          disabled
        />
        <InputError name="accountName" />
      </InputGroup>
      <InputGroup>
        <Field
          as={Input}
          name="accountValue"
          placeholder="Account Value"
          autoComplete="account-value"
          value={accountValue}
          disabled
        />
        <InputError name="accountValue" />
      </InputGroup>
      <InputGroup>
        <Field
          as={Input}
          name="accountType"
          placeholder="Type"
          autoComplete="account-type"
          disabled
        />
        <InputError name="accountType" />
      </InputGroup>
      <InputGroup>
        <Field
          as={Input}
          name="accountCustodian"
          placeholder="Custodian"
          autoComplete="account-custodian"
          disabled
        />
        <InputError name="accountCustodian" />
      </InputGroup>
      <InputGroup>
        <Field
          as={Input}
          name="accountNumber"
          placeholder="Account # (last 4 digits)"
          autoComplete="account-number"
          error={!!errors.accountNumber && !!touched.accountNumber}
          disabled={isSubmitting}
        />
        <InputError name="accountNumber" />
      </InputGroup>
    </Container>
  );
}

export default AccountInformation;
