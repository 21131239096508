import { useCallback } from 'react';

import { ConnectProps, FormValues } from './types';

const useConnect = ({ onAnswer }: ConnectProps) => {
  const handleSubmit = useCallback(
    (values: FormValues) => {
      onAnswer(values.items);
    },
    [onAnswer],
  );

  return {
    handleSubmit,
  };
};

export default useConnect;
