import styled from 'styled-components';

import { TextWrap } from 'styles/mixins';

export const Container = styled.div`
  padding-left: 0.625rem;
  font-size: 0.875rem;
  flex-direction: column;
  display: flex;
`;

export const BirthDate = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-bottom: 0.9375rem;

  ${TextWrap}
`;

export const Email = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.medium};
  letter-spacing: 0;
  line-height: calc(17 / 14);
  margin-bottom: 0.875rem;

  ${TextWrap}
`;

export const PhoneWrapper = styled.div`
  display: flex;
  letter-spacing: 0;
  margin-bottom: 1.4375rem;
  align-items: center;

  ${TextWrap}
`;

export const Phone = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: calc(17 / 14);
`;

export const Primary = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  line-height: calc(20 / 14);
  margin-left: 0.4375rem;
`;
