import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useInvestor from 'context/hooks/Investor.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import {
  OpenSeedsAccountMutation,
  OpenSeedsAccountMutationVariables,
} from 'graphql/generated';
import { OPEN_SEEDS_ACCOUNT } from 'graphql/mutations/openSeedsAccount';

const useOpenSeedsAccount = () => {
  const { id: investorId } = useParams<{
    id: string;
  }>();
  const { fetchInvestorProposalHistory, fetchInvestorCurrentProposal } =
    useInvestor();
  const { fetchInvestorInfo } = useInvestorInfo();
  const [openSeedsAccount, { loading, data, error }] = useMutation<
    OpenSeedsAccountMutation,
    OpenSeedsAccountMutationVariables
  >(OPEN_SEEDS_ACCOUNT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      fetchInvestorInfo(investorId, false);
      fetchInvestorCurrentProposal(investorId, false, false);
      fetchInvestorProposalHistory(investorId, false);
    },
  });

  const response = useMemo(() => data?.openSeedsAccount?.ok, [data]);

  return {
    openSeedsAccount,
    loading,
    response,
    error,
  };
};

export default useOpenSeedsAccount;
