import { memo, useMemo } from 'react';

import { ReactComponent as Community } from 'assets/svg/municipal-sectors/Community.svg';
import { ReactComponent as Education } from 'assets/svg/municipal-sectors/Education.svg';
import { ReactComponent as Energy } from 'assets/svg/municipal-sectors/Energy.svg';
import { ReactComponent as Healthcare } from 'assets/svg/municipal-sectors/Healthcare.svg';
import { ReactComponent as Transportation } from 'assets/svg/municipal-sectors/Transportation.svg';
import { ReactComponent as Various } from 'assets/svg/municipal-sectors/Various.svg';
import { ReactComponent as Water } from 'assets/svg/municipal-sectors/Water.svg';

import { Container, Content, Industry, Name, Description } from './styles';
import { Props } from './types';

const Story = ({ sector, file, title, description }: Props) => {
  const Icon = useMemo(() => {
    switch (sector) {
      case 'COMMUNITY':
        return Community;
      case 'EDUCATION':
        return Education;
      case 'ENERGY':
        return Energy;
      case 'HEALTHCARE':
        return Healthcare;
      case 'TRANSPORTATION':
        return Transportation;
      case 'WATER':
        return Water;
      case 'VARIOUS':
      default:
        return Various;
    }
  }, [sector]);
  return (
    <Container>
      <Icon />
      <Content>
        <Industry>{sector}</Industry>
        <a target="_blank" rel="noopener noreferrer" href={file || ''}>
          <Name>{title}</Name>
        </a>
        <Description>{description}</Description>
      </Content>
    </Container>
  );
};

export default memo(Story);
