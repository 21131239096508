import { MenuButton, MenuItem } from '@reach/menu-button';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Animation } from 'components/BaseDropdown/styles';
import { TextWrap } from 'styles/mixins';

import { OptionProps } from './types';

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.grey900};
  text-decoration: none;
  align-items: center;
  display: flex;
`;

export const Button = styled(MenuButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary50};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 0.625rem;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
`;

export const Option = styled(MenuItem)<OptionProps>`
  align-items: center;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  width: 11.75rem;

  ${Animation}

  &[data-selected] {
    background-color: ${({ theme }) => theme.colors.primary10};
    color: inherit;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'alert':
        return css`
          color: ${theme.colors.flamingo};

          &[data-selected] {
            background-color: ${theme.colors.bridesmaid};
            color: ${theme.colors.flamingo};
          }
        `;
      case 'default':
      default:
        return null;
    }
  }}
`;

export const Text = styled.span`
  line-height: calc(20 / 14);
  letter-spacing: 0;
  font-size: 0.875rem;

  ${TextWrap};
`;

export const Left = styled.div`
  margin-right: 0.5rem;
`;

export const Right = styled.div`
  margin-left: 0.5rem;
`;
