import { FC, memo } from 'react';

import {
  Container,
  HiddenRadio,
  PseudoRadio,
  Label,
  Option,
  Wrapper,
} from './styles';
import { Props } from './types';

const RadioGroupSurvey: FC<Props> = ({
  name,
  options,
  initialValue,
  value,
  ...props
}) => (
  <Container>
    {options.map(({ id, label }) => (
      <Option htmlFor={props.disabled ? '' : id} key={id}>
        <Wrapper>
          <HiddenRadio
            {...props}
            defaultChecked={id === initialValue}
            id={id}
            name={name}
            value={id}
          />
          <PseudoRadio />
          <Label
            selected={id === value}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Wrapper>
      </Option>
    ))}
  </Container>
);

export default memo(RadioGroupSurvey);
