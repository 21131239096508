import {
  CheckboxOptionType,
  ChoiceOptionType,
  InvestorQuery,
  InvestorsQuery,
  OrderItemOptionType,
  RangeOptionType,
  StepSurveyType,
  SurveyResultStepType,
  SurveyType,
} from 'graphql/generated';

/* eslint-disable no-nested-ternary */

type CheckboxInput = CheckboxOptionType;

type ChoiceInput = ChoiceOptionType;

type OrderItemInput = OrderItemOptionType;

type RangeInput = RangeOptionType;

type SurveyInput = SurveyType;

type SurveyResultEpiPriorityInput = string | null | undefined;

type MinSurveyResultInput = NonNullable<
  NonNullable<
    NonNullable<InvestorsQuery['investors']>['objects']
  >[0]['surveyResult']
>;

type SurveyResultInput = NonNullable<
  NonNullable<InvestorQuery['investor']>['surveyResult']
>;

type SurveyResultStatusInput = string | null | undefined;

type SurveyResultTimeHorizonInput = string | null | undefined;

type SurveyStepInput = StepSurveyType;

type SurveyStepResultInput = SurveyResultStepType;

export const normalizeCheckbox = (input: CheckboxInput) => ({
  id: input.id,
  label: input.label,
  order: input.order,
  nextStep: input.nextStep,
});

export const normalizeChoice = (input: ChoiceInput) => ({
  description: input.description || '',
  id: input.id,
  isRadioButton: input.radioButton,
  label: input.label,
  order: input.order,
  nextStep: input.nextStep,
});

export const normalizeOrderItems = (input: OrderItemInput) => ({
  description: input.description || '',
  id: input.id,
  label: input.label,
  icon: input.iconType,
  order: input.order,
  nextStep: input.nextStep,
});

export const normalizeRange = (input: RangeInput) => ({
  id: input.id,
  max: input.maxValue,
  min: input.minValue,
  percentage: input.percentage,
  order: input.order,
  label: input.label,
  nextStep: input.nextStep,
});

export const normalizeSurveyStep = (input: SurveyStepInput) => ({
  id: input.id,
  description: input.description,
  extraDescription: input.extraDescription || '',
  mobileDescription: input.mobileDescription || '',
  isSkippable: input.isSkippable || false,
  title: input.title,
  checkboxes: input.checkboxes.map(normalizeCheckbox),
  choices: input.choices.map(normalizeChoice),
  orderItems: input.orderitems.map(normalizeOrderItems),
  section: input.section,
  sectionLink: input.sectionLink,
  order: input.order,
  ranges: input.range.length
    ? input.range.map(normalizeRange)
    : [
        {
          id: '',
          max: 0,
          min: 0,
          percentage: false,
          order: 0,
          label: '',
          nextStep: undefined,
        },
      ],
  type: (input?.description
    ?.toLowerCase()
    .includes('set risk target and income level')
    ? 'RISK'
    : input.title.toLowerCase().includes('allocations')
    ? 'ALLOCATIONS'
    : input.checkboxes.length
    ? 'CHECKBOXES'
    : input.choices.length
    ? 'CHOICES'
    : input.orderitems.length
    ? 'ORDER_ITEMS'
    : input.range.length
    ? 'RANGE'
    : undefined) as StepType,
});

export const normalizeSurveyStepResult = (input: SurveyStepResultInput) => {
  const common = {
    id: input.id,
    step: input.step ? normalizeSurveyStep(input.step) : undefined,
  };

  switch (common.step?.type) {
    case 'RISK':
      // @ts-ignore
      // eslint-disable-next-line no-case-declarations
      return {
        ...common,
        // @ts-ignore
        selected: {
          // @ts-ignore
          riskLevel: input.rangeSelected
            ? JSON.parse(input.rangeSelected || '[{value: 1}]')[0]?.value
            : null,
          // @ts-ignore
          incomeLevel: input.choiceSelected,
        },
      };
    case 'ALLOCATIONS':
      return {
        ...common,
        // @ts-ignore
        selected: JSON.parse(input.rangeSelected || '[]') || [],
      };
    case 'CHECKBOXES':
      return {
        ...common,
        selected: input.checkboxesSelected || [],
      };
    case 'CHOICES':
      return {
        ...common,
        selected: input.choiceSelected || '',
      };
    case 'ORDER_ITEMS':
      return {
        ...common,
        selected: input.orderItemsSelected || [],
      };
    case 'RANGE':
      return {
        ...common,
        selected: input.rangeSelected
          ? JSON.parse(input.rangeSelected || '[{value: 1}]')[0]?.value
          : null,
      };
    default:
      return {
        ...common,
        selected: undefined,
      };
  }
};

export const normalizeSurvey = (input: SurveyInput) => ({
  active: input.active,
  id: input.id,
  name: input.name,
  modified: input.modified,
  steps: input.stepSurveyList.map(normalizeSurveyStep),
});

export const normalizeSurveyResultEpiPriority = (
  input: SurveyResultEpiPriorityInput,
) => {
  return input
    ? Array.from(input).map((char) => {
        switch (char) {
          case 'E':
            return 'earth';
          case 'P':
            return 'people';
          case 'I':
          default:
            return 'integrity';
        }
      })
    : [];
};

export const normalizeSurveyResultStatus = (input: SurveyResultStatusInput) => {
  switch (input) {
    case 'Completed':
      return 'COMPLETED';
    case 'Open':
      return 'OPEN';
    case 'Sent':
      return 'SENT';
    case 'Waiting':
      return 'WAITING';
    case 'Pending':
      return 'PENDING';
    case 'Not sent':
    default:
      return 'NOT_SENT';
  }
};

export const normalizeSurveyResultTimeHorizon = (
  input: SurveyResultTimeHorizonInput,
) => {
  switch (input) {
    case 'A_1_4':
      return '1-4';
    case 'A_4_10':
      return '4-10';
    case 'A_10_20':
      return '10-20';
    case 'A_20_50':
      return '20-50';
    default:
      return undefined;
  }
};

export const normalizeMinSurveyResult = (input: MinSurveyResultInput) => ({
  id: input.id,
  epiPriority: normalizeSurveyResultEpiPriority(input.epiPriority),
  rawEpiPriority: input.epiPriority,
  riskTolerance: input.riskTolerance,
  status: normalizeSurveyResultStatus(input.status),
  timeHorizon: normalizeSurveyResultTimeHorizon(
    input.timeHorizon,
  ) as ResultTimeHorizon,
});

export const normalizeSurveyResult = (input: SurveyResultInput) => ({
  // @ts-ignore
  answers: input.stepAnswerList.map(normalizeSurveyStepResult),
  // @ts-ignore
  incomeLevel: input.incomeLevel,
  // @ts-ignore
  riskLevel: input.riskLevel,
  id: input.id,
  epiPriority: normalizeSurveyResultEpiPriority(input.epiPriority),
  equity: input.equity || 0,
  fixed: input.fixed || 0,
  rawEpiPriority: input.epiPriority,
  riskTolerance: input.riskTolerance,
  status: normalizeSurveyResultStatus(input.status),
  timeHorizon: normalizeSurveyResultTimeHorizon(
    input.timeHorizon,
  ) as ResultTimeHorizon,
});

export type Checkbox = ReturnType<typeof normalizeCheckbox>;

export type Choice = ReturnType<typeof normalizeChoice>;

export type OrderItems = ReturnType<typeof normalizeOrderItems>;

export type Range = ReturnType<typeof normalizeRange>;

export type Survey = ReturnType<typeof normalizeSurvey>;

export type Result = ReturnType<typeof normalizeSurveyResult>;

export type ResultStatus = ReturnType<typeof normalizeSurveyResultStatus>;

export type ResultTimeHorizon = ReturnType<
  typeof normalizeSurveyResultTimeHorizon
>;

export type Step = ReturnType<typeof normalizeSurveyStep>;

export type StepResult = ReturnType<typeof normalizeSurveyStepResult>;

export type StepType =
  | 'CHECKBOXES'
  | 'CHOICES'
  | 'ORDER_ITEMS'
  | 'RANGE'
  | 'RISK'
  | 'ALLOCATIONS';
