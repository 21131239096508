import { Container, Column } from './styles';

const HoldingsHeader = ({ isValuesDriven }: { isValuesDriven: boolean }) => {
  return (
    <Container isValuesDriven={isValuesDriven}>
      <Column>NAME</Column>
      <Column>% OF PORTFOLIO</Column>
      {isValuesDriven && <Column>VALUE SCORE</Column>}
      <Column>INDUSTRY</Column>
    </Container>
  );
};

export default HoldingsHeader;
