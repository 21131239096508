import { useMemo, useCallback } from 'react';

import Custodian from 'components/Account/Implement/Custodian';
import Modal from 'components/NewModal';
import {
  AccountType,
  AccountCustodianType,
} from 'context/fastContext/account/types';

function ImplementAccount({
  accountId,
  setAccountToImplement,
  confirmImplement,
  hasErrorImplementingAlturist,
  accounts,
  accountCustodians,
}: {
  accountId: string | null;
  setAccountToImplement: any;
  confirmImplement: any;
  hasErrorImplementingAlturist: boolean;
  accounts: AccountType[];
  accountCustodians: AccountCustodianType[];
}) {
  const close = useCallback(() => {
    setAccountToImplement(null);
  }, [setAccountToImplement]);

  const accountToImplement = useMemo(
    () => accounts.find((acc) => acc.id === accountId),
    [accountId, accounts],
  );

  const custodianType = accountCustodians.find(
    (cus) => cus.id === accountToImplement?.accountCustodian,
  );

  return (
    <Modal
      sx={{
        maxWidth: '664px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 24px)',
      }}
      close={close}
      isOpen={!!accountToImplement && !!custodianType?.skipApproval}
      transitionSpeed="0.5s"
    >
      <Custodian
        confirmImplement={confirmImplement}
        accountToImplement={accountToImplement}
        hasErrorImplementingAlturist={hasErrorImplementingAlturist}
      />
    </Modal>
  );
}

export default ImplementAccount;
