import { nanoid } from 'nanoid/non-secure';
import { FC, useMemo, useCallback } from 'react';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrowRight.svg';

import { Container, IconButton, Number, Wrapper } from './styles';
import { Props } from './types';

const MAX_NUMBER_OF_PAGES = 7;
const HEAD_COLLAPSING_THRESHOLD = 4; // the number of the page from which to show the head collapsing

const Pagination: FC<Props> = ({
  hasNext,
  hasPrev,
  onChangePage,
  page,
  pages,
  ...props
}) => {
  const numbers = useMemo(
    () =>
      Array.from(Array(pages).keys()).map((_, index) => (
        <Number
          $current={page === index + 1}
          disabled={page === index + 1}
          key={nanoid()}
          onClick={() => {
            onChangePage(index + 1);
          }}
        >
          {index + 1}
        </Number>
      )),
    [pages, page, onChangePage],
  );

  const TAIL_COLLAPSING_THRESHOLD = useMemo(
    () => numbers.length - HEAD_COLLAPSING_THRESHOLD,
    [numbers],
  );

  const HEAD_COLLAPSING_ID = useMemo(() => nanoid(), []);
  const TAIL_COLLAPSING_ID = useMemo(() => nanoid(), []);

  const from = useMemo(() => {
    if (page <= HEAD_COLLAPSING_THRESHOLD) {
      return 0;
    }
    if (page > TAIL_COLLAPSING_THRESHOLD) {
      return TAIL_COLLAPSING_THRESHOLD - 1;
    }
    return page - 2; // page is currentIndex + 1
  }, [page, TAIL_COLLAPSING_THRESHOLD]);

  const to = useMemo(() => {
    if (page <= HEAD_COLLAPSING_THRESHOLD) {
      return HEAD_COLLAPSING_THRESHOLD + 1;
    }
    if (page > TAIL_COLLAPSING_THRESHOLD) {
      return numbers.length;
    }
    return page + 1; // slice is not inclusive in its second argument, therefore it needs to be the desired page + 1
  }, [page, numbers, TAIL_COLLAPSING_THRESHOLD]);

  const goHalfTheHead = useCallback(() => {
    const half = Math.floor(from / 2);
    onChangePage(half);
  }, [from, onChangePage]);

  const goHalfTheTail = useCallback(() => {
    const half = Math.ceil(to + (numbers.length - to) / 2);
    onChangePage(half);
  }, [onChangePage, to, numbers]);

  const headCollapsingSegment = useMemo(
    () => [
      numbers[0],
      <Number
        $current={false}
        key={HEAD_COLLAPSING_ID}
        onClick={goHalfTheHead}
        data-testid="head-collapse"
      >
        ...
      </Number>,
    ],
    [numbers, goHalfTheHead, HEAD_COLLAPSING_ID],
  );

  const tailCollapsingSegment = useMemo(
    () => [
      <Number
        $current={false}
        key={TAIL_COLLAPSING_ID}
        onClick={goHalfTheTail}
        data-testid="tail-collapse"
      >
        ...
      </Number>,
      numbers[numbers.length - 1],
    ],
    [numbers, goHalfTheTail, TAIL_COLLAPSING_ID],
  );

  const segments = useMemo(() => {
    if (numbers.length > MAX_NUMBER_OF_PAGES) {
      const head =
        page > HEAD_COLLAPSING_THRESHOLD ? headCollapsingSegment : [];
      const tail =
        page <= TAIL_COLLAPSING_THRESHOLD ? tailCollapsingSegment : [];
      return [...head, ...numbers.slice(from, to), ...tail];
    }

    return numbers;
  }, [
    from,
    to,
    numbers,
    page,
    TAIL_COLLAPSING_THRESHOLD,
    headCollapsingSegment,
    tailCollapsingSegment,
  ]);

  return (
    <Container {...props}>
      <Wrapper>{segments}</Wrapper>
      <Wrapper>
        <IconButton
          icon={<ArrowLeft />}
          onClick={() => {
            onChangePage(page - 1);
          }}
          disabled={!hasPrev}
          data-testid="previous"
        />
        <IconButton
          icon={<ArrowRight />}
          onClick={() => {
            onChangePage(page + 1);
          }}
          disabled={!hasNext}
          data-testid="next"
        />
      </Wrapper>
    </Container>
  );
};

export default Pagination;
