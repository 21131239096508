import styled, { css } from 'styled-components';

import { Loader as Spinner } from 'components/FileInput/FileRow/styles';
import BaseModal, { Title as BaseTitle } from 'components/Modal';

export const Modal = styled(BaseModal)`
  .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 32.5rem;
        width: 32.5rem;
      `};
  }
`;

export const ContactMessage = styled.p`
  margin-top: 6px;
  a {
    color: inherit;
  }
`;

export const ExampleTable = styled.table`
  border: 1px solid ${({ theme }) => theme.colors.grey600};
  border-collapse: collapse;
  margin-top: 4px 6px;
  font-size: 14px;
  td,
  th {
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    min-width: 80px;
    text-align: left;
  }
  th {
    font-weight: bold;
  }
`;

export const SwitchLabel = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
`;

export const Divider = styled.hr`
  border: none;
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  margin-top: 1rem;
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 1.875rem;
`;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 28.5rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Loader = styled(Spinner)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.4375rem;
`;
