import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.2rem 0;
  button {
    padding: 0.4375rem 0.35rem;
    margin: 0 4px;
  }
`;

export const FieldContainer = styled.div`
  width: 50px;
  margin: 0 0.3rem;
  input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
  }
`;

export const Description = styled.p`
  margin: 0;
  min-width: 85px;
`;
