import { Form as DefaultForm } from 'formik';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import BaseButton from 'components/Button';
import BaseCard from 'components/Card';
import BaseLogo from 'components/Logo';
import { from } from 'styles/media';
import theme from 'styles/v2/theme';

import DefaultIllustration from '../Login/SidePicture';

export const Button = styled(BaseButton).attrs(() => ({
  variant: 'primary',
  type: 'submit',
}))`
  width: 100%;
  justify-content: center;
  min-height: 2.5rem;
`;

export const Card = styled(BaseCard).attrs(() => ({
  variant: 'borderless',
}))`
  width: 100%;
  background: ${theme.palette.common.white};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border-radius: 0;
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${theme.palette.grey[50]};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 35.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;

  .resize {
    max-width: unset;
  }

  @media (min-width: 769px) {
    padding: 4rem;
  }

  ${from.tablet} {
    margin-bottom: 6rem;
  }
`;

export const Form = styled(DefaultForm)`
  width: 100%;
`;

export const Header = styled.h1`
  color: ${theme.palette.grey[900]};
  font-family: 'GreyCliff CF';
  font-size: 1.5rem;
  font-weight: ${theme.typography.fontWeightExtraBold};
  letter-spacing: 0;
  line-height: calc(36 / 32);
  margin-bottom: 1.5rem;

  p {
    color: ${theme.palette.grey[500]};
    margin-top: 10px;
    font-size: 1rem;
    font-weight: ${theme.typography.fontWeightRegular};
  }

  ${from.largeScreen} {
    font-size: 2rem;
  }
`;

export const Helpers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 14px;
  color: ${theme.palette.grey[900]};
  font-weight: ${theme.typography.fontWeightBold};
  margin-bottom: 1.5rem;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem;
`;

export const Illustration = styled(DefaultIllustration)`
  display: none;
  flex-shrink: 0;

  ${from.tablet} {
    display: flex;
  }
`;

export const Logo = styled(BaseLogo)`
  margin-bottom: 3rem;
`;

export const CompanyLogo = styled.img`
  max-height: 40px;
  margin-bottom: 3rem;
`;

export const Link = styled(DefaultLink)`
  align-self: flex-start;
  color: inherit;
  margin-top: 1rem;
  color: ${theme.palette.primary[400]};
  text-decoration: none;
  font-size: 0.875rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const StrengthMeter = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const StrengthIcon = styled.span`
  min-width: 40px;
  width: 100%;
  height: 0.75rem;
  border-radius: 6px;
  color: ${theme.palette.grey[500]};
`;

export const StrengthColors = [
  '#CCFAE5',
  '#91E3C2',
  '#36BF8A',
  '#1C7552',
  '#12402E',
  '#E5E8EC',
];

export const Text = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 0.5rem;
  font-size: 1rem;
  line-height: 1rem;
  color: ${theme.palette.grey[900]};

  a,
  span {
    color: ${theme.palette.primary[400]};
    font-weight: ${theme.typography.fontWeightBold};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  span {
    margin: 0 0.25rem;
    cursor: pointer;
    font-weight: ${theme.typography.fontWeightMedium};
  }
`;

export const ValidationGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const ValidationWrapper = styled.ul`
  list-style: circle;
  list-style-position: inside;
  flex: 1;
`;

export const ValidationItem = styled.li`
  color: ${theme.palette.grey[500]};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: 1.2rem;

  &.valid {
    color: ${theme.palette.primary[400]};
    list-style: disc;
    list-style-position: inside;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  flex: 5;
`;
