import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import PrettyLoading from 'components/PrettyLoading';
import AppLayout from 'containers/Main/AppLayout';
import NewFeature from 'containers/Modals/Feature';
import { from, useMediaQuery } from 'styles/media';

import AddHousehold from '../AddHousehold';
import Advisors from '../Advisors';
import Clients from '../Clients';
import Disclosures from '../Disclosures';
import Profile from '../Profile';
import Terms from '../Terms';
import { tabs } from './constants';
// import Shared from './Shared';

const Admin = () => {
  const isMobile = !useMediaQuery(from.tablet);
  const isProposal = useLocation().pathname.includes('/proposal');

  if (isMobile && !isProposal) {
    return (
      <PrettyLoading text="Seeds platform does not support the current screen size" />
    );
  }

  return (
    <AppLayout tabs={tabs}>
      <Switch>
        <Route component={Advisors} exact path="/advisors" />
        <Route component={AddHousehold} exact path="/clients/add" />
        <Route component={Clients} path="/clients" />
        <Route component={Disclosures} exact path="/disclosures" />
        <Route component={Profile} exact path="/profile" />
        {/* <Route component={Shared} exact path="/shared" /> */}
        <Route component={Terms} exact path="/terms-and-conditions" />
        <Redirect to="/clients" />
      </Switch>
      <NewFeature />
    </AppLayout>
  );
};

export default Admin;
