import { Client, SmallClient, SimpleClient } from './types';

const companies = [
  { id: '1', name: 'Milan Company' },
  { id: '2', name: 'Summit Advisors' },
  { id: '3', name: 'Shanghai Shoes' },
  { id: '4', name: 'Frankfurt LT' },
  { id: '5', name: 'Bakuask' },
];

export const clientData: Client[] = [
  {
    id: 'c-1',
    household: {
      id: 'h-1',
      name: 'Bertie Stephens',
      email: 'bstephens@milanco.com',
      phone: '1234567890',
      company: companies[0].name,
    },
    company: companies[0],
    advisor: {
      id: 'a-1',
      name: 'Adeline Blake',
    },
    values: ['earth', 'people', 'integrity'],
    horizon: {
      start: 1,
      end: 4,
    },
    risk: 'MODEST',
    status: 'COMPLETED',
  },
  {
    id: 'c-2',
    household: {
      id: 'h-2',
      name: 'Gabriel Moreira',
      email: 'gmoreira@summitad.com',
      phone: '1234567890',
      company: companies[1].name,
    },
    company: companies[1],
    advisor: {
      id: 'a-2',
      name: 'Freddy Kauschke',
    },
    values: null,
    horizon: null,
    risk: 'AGGRESSIVE',
    status: 'COMPLETED',
  },
  {
    id: 'c-3',
    household: {
      id: 'h-3',
      name: 'Saga Lindén',
      email: 'slinden@shanshoes.com',
      phone: '1234567890',
      company: companies[2].name,
    },
    company: companies[2],
    advisor: {
      id: 'a-3',
      name: 'Prescott MacCaffery',
    },
    values: ['earth', 'people', 'integrity'],
    horizon: {
      start: 10,
      end: 20,
    },
    risk: 'MODERATE',
    status: 'COMPLETED',
  },
  {
    id: 'c-4',
    household: {
      id: 'h-4',
      name: 'Elsi Hansdottir',
      email: 'ehasdottir@sflt.com',
      phone: '1234567890',
      company: companies[3].name,
    },
    company: companies[3],
    advisor: {
      id: 'a-4',
      name: 'Fariba Mirzaii',
    },
    values: ['people', 'integrity', 'people'],
    horizon: {
      start: 4,
      end: 10,
    },
    risk: 'MODERATE',
    status: 'SENT',
  },
  {
    id: 'c-5',
    household: {
      id: 'h-5',
      name: 'Alex Edwards',
      email: 'aedwards@bakuask.com',
      phone: '1234567890',
      company: companies[4].name,
    },
    company: companies[4],
    advisor: {
      id: 'a-5',
      name: 'Prescott MacCaffery',
    },
    values: null,
    horizon: {
      start: 20,
      end: 50,
    },
    risk: 'SIGNIFICANT',
    status: 'WAITING',
  },
];

export const smallClientData: SmallClient[] = [
  {
    id: 'c-1',
    advisorName: 'Bertie Stephens',
    company: 'Summit Advisors',
    numberOfClients: 10,
  },
  {
    id: 'c-2',
    advisorName: 'Alex Edwards',
    company: 'Milan Company',
    numberOfClients: 15,
  },
  {
    id: 'c-3',
    advisorName: 'Saga Lindén',
    company: 'Shanghai Shoes',
    numberOfClients: 5,
  },
];

export const simpleData: SimpleClient[] = [
  {
    id: 'c-1',
    name: 'Bertie Stephens',
    email: 'bstephens@summit.com',
  },
  {
    id: 'c-2',
    name: 'Alex Edwards',
    email: 'bstephens@summit.com',
  },
  {
    id: 'c-3',
    name: 'Saga Lindén',
    email: 'bstephens@summit.com',
  },
];
