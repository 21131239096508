import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
  padding: 0.25rem 0.5rem;
  min-width: 4.375rem;
  text-align: right;
`;

export const Result = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey600};
  display: block;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
`;

export const Value = styled.span`
  font-weight: ${({ theme }) => theme.weights.extraBold};
  font-family: monospace;
  font-size: 1.25rem;
`;

export const Error = styled.span`
  display: block;
  margin-top: 0.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.flamingo};
`;
