/* eslint-disable react/no-danger */

import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { from, useMediaQuery } from 'styles/media';

import { useTooltip } from './logic';
import { Container, DragHandler, Label, Numbering } from './styles';
import { Props } from './types';

const validIcons = ['earth', 'people', 'scales'];

const Option: FC<Props> = ({
  id,
  index,
  label,
  description,
  icon,
  disabled,
  itemsPerColumn,
  column,
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const { tooltipOpened, onMouseEnterHandler, onMouseLeaveHandler } =
    useTooltip(isMobile);

  const itemNumber = column * itemsPerColumn + index + 1;

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => {
        return description ? (
          <Tooltip
            title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            open={
              isMobile ? tooltipOpened : !snapshot.isDragging && tooltipOpened
            }
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  enabled: isMobile,
                  options: {
                    offset: [0, -120],
                  },
                },
              ],
            }}
          >
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onMouseDown={onMouseLeaveHandler}
              onTouchStart={onMouseEnterHandler}
              onTouchEnd={onMouseLeaveHandler}
              onTouchCancel={onMouseLeaveHandler}
              onFocus={onMouseEnterHandler}
              onBlur={onMouseLeaveHandler}
            >
              <Numbering>{itemNumber}</Numbering>
              <DragHandler />
              {icon && validIcons.includes(icon) && (
                <img
                  height="27px"
                  width="27px"
                  src={`${process.env.PUBLIC_URL}/${icon}.png`}
                  alt={`${icon} icon`}
                />
              )}
              <Label dangerouslySetInnerHTML={{ __html: label }} />
            </Container>
          </Tooltip>
        ) : (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
          >
            <DragHandler />
            {icon && validIcons.includes(icon) && (
              <img
                height="auto"
                width="27px"
                src={`${process.env.PUBLIC_URL}/${icon}.png`}
                alt={`${icon} icon`}
              />
            )}
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </Container>
        );
      }}
    </Draggable>
  );
};

export default Option;
