import { FC } from 'react';

import { Container } from './styles';
import { ContainerProps } from './types';

const StatusSelect: FC<ContainerProps> = ({ status, ...props }) => {
  if (!status) return null;
  return (
    <Container status={status} {...props}>
      {status.toUpperCase()}
    </Container>
  );
};

export default StatusSelect;
