import { useState, useCallback, useMemo } from 'react';

export const useSelection = <T extends { id: string }>({
  data,
}: {
  data: T[];
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const allRowIds = useMemo(() => data.map((row) => row.id), [data]);
  const areAllSelected = useMemo(
    () =>
      allRowIds.length > 0 && allRowIds.every((id) => selected.includes(id)),
    [selected, allRowIds],
  );

  const [entities, setEntities] = useState<T[]>([]);

  const selectRow = useCallback(
    ({ rowId }: { rowId: string }) => {
      const copySelected = [...selected];

      const rowIndex = selected.findIndex((id) => id === rowId);
      if (rowIndex !== -1) {
        copySelected.splice(rowIndex, 1);
      } else {
        copySelected.push(rowId);
      }

      const copyEntities = [...entities];
      const entity = data.filter(({ id }) => id === rowId)[0];
      const entityIndex = entities.findIndex(({ id }) => id === entity.id);
      if (entityIndex !== -1) {
        copyEntities.splice(entityIndex, 1);
      } else {
        copyEntities.push(entity);
      }

      // we use the set data structure to remove possible duplicates
      const updatedSelection = Array.from(new Set(copySelected));
      setSelected(updatedSelection);

      const updatedEntities = Array.from(new Set(copyEntities));
      setEntities(updatedEntities);
    },
    [data, entities, selected],
  );

  const selectRows = useCallback(
    ({ rowId }: { rowId: string }) => {
      const lastSelectedId = selected[selected.length - 1];
      const lastSelectedIndex = allRowIds.findIndex(
        (id) => id === lastSelectedId,
      );
      if (lastSelectedIndex === -1 || lastSelectedId === rowId) {
        selectRow({ rowId });
      } else {
        const currentSelectedIndex = allRowIds.findIndex((id) => id === rowId);
        const [start, end] = [lastSelectedIndex, currentSelectedIndex].sort();
        const rangeOfIds = allRowIds.slice(start, end);

        const rangeOfEntities = data.filter((item) =>
          rangeOfIds.includes(item.id),
        );

        setSelected((current) => {
          const newSelection = [...current, ...rangeOfIds, rowId];
          return Array.from(new Set(newSelection));
        });

        setEntities((current) => {
          const newEntitiesSelection = [...current, ...rangeOfEntities];
          return Array.from(new Set(newEntitiesSelection));
        });
      }
    },
    [allRowIds, data, selectRow, selected],
  );

  const selectAll = useCallback(() => {
    setSelected((current) => {
      if (current.length === 0) {
        return [...allRowIds];
      }

      const difference = allRowIds.filter((id) => !current.includes(id));

      if (difference.length === 0) {
        return [];
      }
      return [...current, ...difference];
    });

    setEntities((current) => {
      if (current.length === 0) {
        return [...data];
      }

      const currentIds = current.map(({ id }) => id);
      const difference = data.filter(({ id }) => !currentIds.includes(id));

      if (difference.length === 0) {
        return [];
      }
      return [...current, ...difference];
    });
  }, [allRowIds, data]);

  return [
    {
      areAllSelected,
      entities,
      selectAll,
      selectRow,
      selectRows,
      selected,
    },
    setSelected,
  ] as const;
};
