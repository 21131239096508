import { useCallback, useEffect, useState } from 'react';

import useDebounce from 'hooks/useDebounce';

import { Animation } from './types';

const useLogic = () => {
  const [animation, setAnimation] = useState<Animation>('SKIP');
  const [hide, setHide] = useDebounce<Animation>('SKIP', 500);

  const handleAnimation = useCallback(
    () =>
      setAnimation((prev) => {
        switch (prev) {
          case 'BAR':
            return 'TEXT';
          case 'SKIP':
            return 'TEXT';
          case 'TEXT':
            return 'BAR';
          default:
            return 'SKIP';
        }
      }),
    [setAnimation],
  );

  useEffect(() => {
    switch (animation) {
      case 'BAR':
        setHide('TEXT');
        break;
      case 'TEXT':
        setHide('BAR');
        break;
      case 'SKIP':
      default:
        break;
    }
  }, [animation, setHide]);

  return {
    animation,
    handleAnimation,
    hide,
  };
};

export default useLogic;
