import { FC, memo } from 'react';
import { useDropzone } from 'react-dropzone';

import Dropbox from './Dropbox';
import FileContainer from './FileContainer';
import { ListWrapper, UploadButton } from './styles';
import { Props } from './types';

const FileInput: FC<Props> = ({ onDrop, onRemove, file, acceptedFiles }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFiles,
    });

  return (
    <>
      <input type="file" {...getInputProps()} accept={acceptedFiles} />
      {file ? (
        <ListWrapper>
          <FileContainer
            file={file}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            onRemove={onRemove}
            rootProps={getRootProps()}
          />
          <UploadButton onClick={getRootProps().onClick}>
            Upload a different spreadsheet
          </UploadButton>
        </ListWrapper>
      ) : (
        <Dropbox
          rootProps={getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        />
      )}
    </>
  );
};

export default memo(FileInput);
