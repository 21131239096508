import { gql } from '@apollo/client';

import {
  ADVISOR_FRAGMENT,
  MIN_ADVISOR_FRAGMENT,
} from 'graphql/fragments/advisor';

export const ADVISOR = gql`
  query Advisor($id: UUID!) {
    advisor(id: $id) {
      ...AdvisorParts
    }
  }
  ${ADVISOR_FRAGMENT}
`;

export const ADVISORS = gql`
  query Advisors(
    $orderBy: [OrderByInputType]
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    advisors(
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      hasNext
      hasPrev
      page
      pages
      totalResults
      orderBy {
        field
        ordering
      }
      objects {
        ...AdvisorParts
      }
    }
  }
  ${ADVISOR_FRAGMENT}
`;

export const ASSIGNABLE_ADVISORS = gql`
  query AssignableAdvisors {
    assignableAdvisors {
      ...MinAdvisorParts
    }
  }
  ${MIN_ADVISOR_FRAGMENT}
`;
