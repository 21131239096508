import styled from 'styled-components';

export const StoriesWrapper = styled.div`
  margin: 1rem 0;
  h3 {
    margin-bottom: 0;
    line-height: normal;
  }
  hr {
    margin: 1.5rem 0;
  }
`;

export const StoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StoriesItem = styled.div`
  margin-bottom: 2.5rem;
`;
