import { Field } from 'formik';

import Input, { InputGroup, InputError } from 'components/Input';

import { Container, Title } from '../styles';

function CashManagement({
  isSubmitting,
  errors,
  touched,
}: {
  isSubmitting: any;
  errors: any;
  touched: any;
}) {
  return (
    <Container>
      <Title>Cash Management</Title>
      <InputGroup>
        <Field
          as={Input}
          name="initialSetAside"
          placeholder="Initial Set Aside (Optional)"
          autoComplete="initial-set-aside"
          disabled={isSubmitting}
        />
        <InputError name="initialSetAside" />
      </InputGroup>
      <InputGroup>
        <Field
          as={Input}
          name="monthlyCashRaise"
          placeholder="Monthly Cash Raise"
          autoComplete="monthly-cash-raise"
          error={!!errors.monthlyCashRaise && !!touched.monthlyCashRaise}
          disabled={isSubmitting}
        />
        <InputError name="monthlyCashRaise" />
      </InputGroup>
    </Container>
  );
}

export default CashManagement;
