import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as LoadingIcon } from 'assets/svg/loading.svg';
import Icon from 'components/Icon';
import BaseIconButton from 'components/IconButton';
import { VisuallyHidden, UnsetVisuallyHidden, TextWrap } from 'styles/mixins';

import { ContainerProps } from './types';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RetryUpload = styled(BaseIconButton)`
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const RemoveFailed = styled(BaseIconButton)`
  :hover {
    color: ${({ theme }) => theme.colors.flamingo};
  }
`;

export const Remove = styled(RemoveFailed)`
  margin-left: auto;
  ${VisuallyHidden}

  &:focus {
    ${UnsetVisuallyHidden}
  }
`;

export const Failed = styled.span`
  color: ${({ theme }) => theme.colors.flamingo};
  font-size: 0.75rem;
  height: 1.125rem;
  letter-spacing: 0;
  line-height: calc(18 / 12);
  text-align: right;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  height: 1.375rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-left: 0.75rem;
  ${TextWrap};
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 0.75rem;
`;

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border-bottom: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  display: flex;
  height: 3.75rem;

  ${({ failedUpload }) => {
    return failedUpload
      ? css`
          padding: 0.875rem 1rem 0.875rem 0.75rem;
        `
      : css`
          padding: 1.0625rem 1rem 1.3125rem 1rem;
        `;
  }}

  &:hover {
    background: ${({ theme }) => theme.colors.grey0};
    > :last-child {
      ${UnsetVisuallyHidden}
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Loader = styled(Icon).attrs(() => ({
  variant: 'transparent',
  icon: <LoadingIcon />,
}))`
  animation: ${rotate360} 1s linear infinite;
  padding: 0;
  transform: translateZ(0);
`;
