import { format, parseISO } from 'date-fns';
import { useEffect, useRef } from 'react';

import Button from 'components/Button';
import PrettyLoading from 'components/PrettyLoading';
import Print from 'components/Print';
import { Result } from 'components/SurveyResultsCard';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';

import StepComponent from '../Form/StepComponent';
import { Answer } from '../Form/types';
import PrintAssessment from '../Print/PrintAssessment';
import useConnect from './connect';
import { Container, SurveyResultsSubTitle, SurveyResultsTitle } from './styles';

const Answers = () => {
  const {
    surveyResults,
    survey,
    isLoading,
    meRefetch,
    investorRefetch,
    goBack,
    // isRiskPreset,
  } = useConnect();

  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    meRefetch();
    investorRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { investorInfo } = useInvestorInfo();

  if (isLoading) return <PrettyLoading text="Generating results" />;

  // NOTE: filter by unique description filed
  const surveySteps = survey?.steps;

  // Pre Filters Undefined (non answered) questions
  // @ts-ignore
  const selectedSteps = surveySteps.filter(
    (step) =>
      // @ts-ignore
      surveyResults?.answers.find((answer) => answer?.step.id === step.id) !==
      undefined,
  );

  return (
    <>
      <PrintAssessment ref={printRef} />
      <Print content={() => printRef.current}>
        {({ triggerPrint }) => (
          <Container triggerPrint={triggerPrint}>
            <SurveyResultsTitle>Assessment</SurveyResultsTitle>
            <SurveyResultsSubTitle data-testid="survey-results-span-assessed-on">
              {investorInfo?.surveyResult.modified &&
                `Assessed On ${format(
                  parseISO(investorInfo?.surveyResult.modified),
                  'MMMM dd, yyyy',
                )}`}
            </SurveyResultsSubTitle>
            {selectedSteps.map((step: { id: string }, stepIdx: number) => {
              const stepRes = surveyResults?.answers.find(
                // @ts-ignore
                (answer) => answer?.step.id === step.id,
              );
              const currentStep = stepIdx + 1;
              const selected = stepRes?.selected;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Result key={step.id + stepIdx}>
                  <StepComponent
                    initialValue={selected as Answer}
                    // @ts-ignore
                    stepList={selectedSteps}
                    // @ts-ignore
                    currentStep={currentStep}
                    onAnswer={() => {}}
                    readOnly
                  />
                </Result>
              );
            })}
            <Button variant="secondary" onClick={goBack}>
              Finish
            </Button>
          </Container>
        )}
      </Print>
    </>
  );
};

export default Answers;
