import createFastContext from 'context/fastContext/createFastContext';

import type { PortfolioState, ValueType } from './types';

export const initialScoresState = {
  cash: 0,
  consumerDiscretionary: 0,
  consumerStaples: 0,
  earthScore: 0,
  energy: 0,
  equities: 0,
  financials: 0,
  fixed: 0,
  healthcare: 0,
  industrials: 0,
  informationTechnology: 0,
  integrityScore: 0,
  materials: 0,
  other: 0,
  peopleScore: 0,
  realEstate: 0,
  scoreDate: null,
  telecommunicationServices: 0,
  utilities: 0,
  valueScore: 0,
};

export const initialCurrentPortfolioState = {
  id: '',
  holdings: [],
  score: initialScoresState,
  valueType: 'MARKET_VALUE' as ValueType,
  missingTickers: [],
  sectors: [],
};

export const initialImportedPortfolioState = {
  securities: [],
  missing: [],
};

export const initialState = {
  currentPortfolio: initialCurrentPortfolioState,
  importedPortfolio: initialImportedPortfolioState,
  isLoadingPortfolio: false,
  isImportingPortfolio: false,
  isUpdatingPortfolio: false,
  hasError: [],
};

const { Provider, useStore } = createFastContext<PortfolioState>(initialState);

export { Provider as PortfolioProvider, useStore };
