import winston from 'winston';

const isProduction = process.env.NODE_ENV === 'production';

export const logger = winston.createLogger({
  transports: [
    new winston.transports.Console({
      level: isProduction ? 'error' : 'debug',
      handleExceptions: true,
      handleRejections: true,
    }),
  ],
  exitOnError: false,
});
