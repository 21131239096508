import { gql } from '@apollo/client';

const CHECKBOX_OPTION_FRAGMENT = gql`
  fragment CheckboxOptionParts on CheckboxOptionType {
    id
    label
    order
    nextStep {
      id
    }
  }
`;

const CHOICE_OPTION_FRAGMENT = gql`
  fragment ChoiceOptionParts on ChoiceOptionType {
    id
    label
    description
    order
    radioButton
    nextStep {
      id
    }
  }
`;

const ORDER_ITEMS_OPTION_FRAGMENT = gql`
  fragment OrderItemsOptionParts on OrderItemOptionType {
    id
    label
    iconType
    description
    order
    nextStep {
      id
    }
  }
`;

const RANGE_OPTION_FRAGMENT = gql`
  fragment RangeOptionParts on RangeOptionType {
    id
    minValue
    maxValue
    nextStep {
      id
    }
  }
`;

export const SURVEY_STEP_FRAGMENT = gql`
  fragment SurveyStepParts on StepSurveyType {
    id
    title
    description
    extraDescription
    mobileDescription
    isSkippable
    section
    sectionLink {
      id
      name
      description
      content
    }
    order
    choices {
      ...ChoiceOptionParts
    }
    range {
      ...RangeOptionParts
    }
    checkboxes {
      ...CheckboxOptionParts
    }
    orderitems {
      ...OrderItemsOptionParts
    }
  }
  ${CHECKBOX_OPTION_FRAGMENT}
  ${CHOICE_OPTION_FRAGMENT}
  ${ORDER_ITEMS_OPTION_FRAGMENT}
  ${RANGE_OPTION_FRAGMENT}
`;

export const SURVEY_STEP_RESULT_FRAGMENT = gql`
  fragment SurveyStepResultParts on SurveyResultStepType {
    id
    step {
      ...SurveyStepParts
    }
    choiceSelected
    rangeSelected
    checkboxesSelected
    orderItemsSelected
  }
  ${SURVEY_STEP_FRAGMENT}
`;

export const MIN_SURVEY_RESULT_FRAGMENT = gql`
  fragment MinSurveyResultParts on SurveyResultType {
    id
    epiPriority
    timeHorizon
    riskTolerance
    status
  }
`;

export const SURVEY_RESULT_FRAGMENT = gql`
  fragment SurveyResultParts on SurveyResultType {
    ...MinSurveyResultParts
    incomeLevel
    riskLevel
    riskTolerance
    stepAnswerList {
      ...SurveyStepResultParts
    }
    equity
    fixed
  }
  ${MIN_SURVEY_RESULT_FRAGMENT}
  ${SURVEY_STEP_RESULT_FRAGMENT}
`;

export const SURVEY_FRAGMENT = gql`
  fragment SurveyParts on SurveyType {
    id
    name
    active
    modified
    stepSurveyList {
      ...SurveyStepParts
    }
  }
  ${SURVEY_STEP_FRAGMENT}
`;
