function normalizeTime(timeString: string) {
  const date = new Date(timeString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // Exclude seconds by not setting the 'second' option
  };
  return date.toLocaleString(undefined, options);
}

export default normalizeTime;
