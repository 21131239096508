import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

const defaultEvents = [
  'mousemove',
  'mousedown',
  'wheel',
  'touchstart',
  'keydown',
  'resize',
];

// 30 mins
const defaultTime = 1800000;

export function useIdle(
  ms: number = defaultTime,
  initialState: boolean = false,
  events: string[] = defaultEvents,
) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout;
    let localstate: boolean;

    const set = (newState: boolean) => {
      if (mounted) {
        localstate = newState;
        setState(newState);
      }
    };

    const resetTimer = throttle(() => {
      if (localstate) {
        set(false);
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => set(true), ms);
    }, 50);

    const onVisibilityChange = () => {
      if (!document.hidden) {
        resetTimer();
      }
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    document.addEventListener('visibilitychange', onVisibilityChange);

    timeout = setTimeout(() => set(true), ms);

    return () => {
      mounted = false;
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [ms, events]);

  return state;
}
