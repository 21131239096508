import { useFormikContext } from 'formik';
import { memo } from 'react';

import { AssetValue, FormValues } from '../../types';
import { Container, Result, Value, Error } from './styles';

const TotalPercentage = () => {
  const { values, errors, submitCount } = useFormikContext<FormValues>();
  const { valueType = 'MARKET_VALUE' } = values;

  const getterKey = AssetValue[valueType];
  const total = values.assets
    ? values.assets.reduce((prev, curr) => prev + (curr[getterKey] || 0), 0)
    : 0;
  return (
    <Container>
      <Result>total</Result>
      <Value>{total.toFixed(2)}%</Value>
      {!Array.isArray(errors.assets) && errors.assets && submitCount > 0 && (
        <Error>{errors.assets}</Error>
      )}
    </Container>
  );
};

export default memo(TotalPercentage);
