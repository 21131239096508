import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';
import { logger } from 'services/logger';
import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const {
    validateInvestorOnboardingToken,
    validateInvestorOnboardingTokenError,
    validateInvestorOnboardingTokenLoading,
  } = useAuthActions();
  const { push } = useHistory();
  const { refetch } = useMe();
  const isMobile = !useMediaQuery(from.tablet);
  const [userName, setUserName] = useState<string>();
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const { token, uidb64 } = useParams<{ token: string; uidb64: string }>();

  const validate = useCallback(async () => {
    try {
      // TODO: Is successful will be true if a jwt auth token and refresh token are returned
      const { investorName, activeSurveyStatus } =
        await validateInvestorOnboardingToken({
          token,
          uidb64,
        });

      if (investorName && activeSurveyStatus) {
        // on click then refetch
        const isComplelete = ['completed', 'open'].includes(
          activeSurveyStatus.toLowerCase(),
        );

        // If assessment is already complete then just bring them to the home page
        if (isComplelete) {
          await refetch();
          setTimeout(() => {
            push('/home');
          }, 0);
        } else {
          setUserName(investorName);
          setIsValidating(false);
        }
      }
    } catch (err) {
      setIsValidating(false);
      logger.error(err);
    }
  }, [push, refetch, token, uidb64, validateInvestorOnboardingToken]);

  const errorMessage = useMemo(() => {
    if (validateInvestorOnboardingTokenError?.message === 'Expired token') {
      return 'This link has expired. Please contact your advisor to send you a new link. Thank you.';
    }
    if (validateInvestorOnboardingTokenError?.message === 'Invalid token') {
      return 'This session is invalid. Please retry using the link provided via email. If the error continues, please reach out to your advisor.';
    }

    if (validateInvestorOnboardingTokenError !== undefined) {
      return 'An error has happened, please try again or contact your advisor.';
    }

    return undefined;
  }, [validateInvestorOnboardingTokenError]);

  useEffect(() => {
    (async () => {
      await validate();
    })();
  }, [validate]);

  return {
    errorMessage,
    isMobile,
    loading: validateInvestorOnboardingTokenLoading || isValidating,
    userName,
  };
};

export default useConnect;
