import styled, { css } from 'styled-components';

import BaseCircle from 'components/Circle';
import { from } from 'styles/media';
import { TextWrap, prettyScrollbar } from 'styles/mixins';
import themeV2 from 'styles/v2/theme';

import { CircleProps } from './types';

export const Circle = styled(BaseCircle)<CircleProps>`
  margin-right: 0.5rem;
  background-color: ${({ background }) => background};
  flex-shrink: 0;
  @media print {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

export const Legend = styled.div<{ $extended: boolean }>`
  display: block;
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;

  div:nth-child(2) > h4 {
    margin-top: 0.5rem;
  }

  ${from.tablet} {
    display: flex;
    flex-direction: column;
    height: 11.25rem;
    overflow-y: auto;
    margin-left: 1.875rem;
  }

  ${({ $extended }) =>
    $extended
      ? css`
          padding-right: 0.625rem;
        `
      : css`
          width: 11rem;
        `};
  ${prettyScrollbar({ size: '0.25rem' })}

  @media print {
    height: unset;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${from.tablet} {
    flex-direction: row;
  }

  @media print {
    align-items: start;
    ${Legend} {
      margin-left: 2rem;
    }
  }
`;

export const Item = styled.div<{ $lighter: boolean }>`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2.25rem;
  line-height: calc(18 / 12);

  div {
    display: flex;
    align-items: center;
  }

  @media print {
    height: 1.5rem;
    line-height: 1;
    font-size: 0.85rem;
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }

  ${from.tablet} {
    & + & {
      margin-top: 0rem;
      @media print {
        margin-top: 0;
      }
    }
  }
`;

export const ItemText = styled.span`
  @media not print {
    ${TextWrap};
  }
`;

export const Divider = styled.hr`
  background: ${({ theme }) => theme.colors.grey200};
  margin-block-start: 0.625rem;
  margin-block-end: 0;
  grid-column: 1 / -1;
  inline-size: 100%;
  border: 0;
  height: 1px;
`;

export const Header = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  vertical-align: baseline;
  color: ${themeV2.palette.grey[900]};
`;
