import styled from 'styled-components';

import { ReactComponent as ArrowRight } from 'assets/svg/arrowRight.svg';
import BaseButton from 'components/Button';
import BaseCard from 'components/Card';
import BaseLogo from 'components/Logo';
import { from } from 'styles/media';

export const AcceptTermsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(BaseButton).attrs(() => ({
  variant: 'primary',
  type: 'submit',
  icon: <ArrowRight />,
  iconPlacement: 'right',
}))`
  margin-top: 1.25rem;
`;

export const Card = styled(BaseCard).attrs(() => ({
  variant: 'borderless',
}))`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  overflow: hidden;
  padding: 0;
  max-width: 100%;
  margin: 2rem;

  form > * {
    margin-bottom: 0.75rem;
  }

  ${from.tablet} {
    width: 37.5rem;
    height: 28.125rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.875rem 2.5rem 1.625rem;
  width: 100%;

  ${from.mobile} {
    padding: 2.875rem 3.75rem 2rem;
  }
`;

export const Logo = styled(BaseLogo)`
  margin-bottom: 1.5rem;
`;

export const ShowTerms = styled(BaseButton).attrs(() => ({
  type: 'button',
  variant: 'transparent',
}))`
  all: inherit;
  cursor: pointer;
  display: inline;
  font-weight: bold;
  margin: 0;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 2rem;
`;

export const AcceptTermsText = styled(Text)`
  margin-bottom: 0;
  margin-left: 0.75rem;
`;
