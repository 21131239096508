import { FC, memo, HTMLAttributes } from 'react';

import cta1x_png from 'assets/images/loginCard/cta_full@1x.png';
import cta1x_webp from 'assets/images/loginCard/cta_full@1x.webp';
import cta2x_png from 'assets/images/loginCard/cta_full@2x.png';
import cta2x_webp from 'assets/images/loginCard/cta_full@2x.webp';

import { Container, Image, Promo } from './styles';

export type Props = HTMLAttributes<HTMLDivElement>;

const Cta: FC<Props> = ({ ...props }) => (
  <Container {...props}>
    <picture>
      <source
        srcSet={`
            ${cta2x_webp} 2x,
            ${cta1x_webp}
            `}
        type="image/webp"
      />
      <source
        srcSet={`
            ${cta2x_png} 2x,
            ${cta1x_png}
            `}
        type="image/png"
      />
      <Image src={cta1x_png} alt="" />
    </picture>
    <Promo>
      Today&apos;s investors want more than just ROI—they want a more digital,
      engaging and personalized experience.
    </Promo>
  </Container>
);

export default memo(Cta);
