import {
  MenuItems,
  Menu,
  MenuPopover as BaseMenuPopover,
} from '@reach/menu-button';
import styled, { css } from 'styled-components';

import { ZIndex } from 'styles/metrics';
import { addAlpha } from 'utils/addAlpha';

export const Animation = css`
  animation: slide-down 0.2s ease;
`;

export const ReachMenu = styled(Menu)`
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-0.625rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const MenuPopover = styled(BaseMenuPopover)`
  ${Animation}
  z-index: ${ZIndex.DROPDOWN_MENU};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  box-shadow: 0 1.25rem 2.5rem -0.125rem ${({ theme }) => addAlpha(theme.colors.dark, 0.1)};
  margin-top: 0.25rem;
  min-width: 7.8125rem;
  overflow: hidden;
  padding: 0.625rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: calc(18 / 12);
  padding: 0.75rem;
  text-transform: uppercase;
`;

export const Options = styled(MenuItems)`
  padding: 0;
  border: none;
`;
