import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportIcon from '@mui/icons-material/Support';
import IconButton from '@mui/material/IconButton';
import { FC, memo, useMemo, useEffect } from 'react';

import Legal from 'components/Legal';
import Logo from 'components/Logo';
import useAdvisorActions from 'context/fastContext/advisor/actions';
import { useStore as useAdvisorStore } from 'context/fastContext/advisor/context';
import { AdvisorState } from 'context/fastContext/advisor/types';
import useMe from 'graphql/hooks/useMe';

import {
  // NOTE: https://seedsinvestor.atlassian.net/browse/SD-529
  // socialMedia,
  links,
} from './constants';
import {
  CompanyLogo,
  Container,
  Content,
  // SocialMedia,
  Links,
  Link,
  Wrapper,
} from './styles';

type Props = {
  companyLogo?: string | null;
};
const Footer: FC<Props> = ({ companyLogo }) => {
  const { me } = useMe();
  const { fetchZendeskToken } = useAdvisorActions();
  const hasUrl = companyLogo?.includes('https://');
  const isAdvisor = useMemo(() => me?.role === 'ADVISOR', [me]);
  const [{ zendeskToken }] = useAdvisorStore((s: AdvisorState) => ({
    zendeskToken: s.zendeskToken,
  }));

  useEffect(() => {
    if (zendeskToken) {
      const zendeskKnowledgeUrl = 'https://seedsinvestor.zendesk.com/hc/en-us';
      const zendeskUrl = `https://seedsinvestor.zendesk.com/access/jwt?jwt=${zendeskToken}&return_to=${zendeskKnowledgeUrl}`;
      window.open(zendeskUrl, '_blank');
    }
  }, [zendeskToken]);

  const handleZendeskHelp = () => {
    fetchZendeskToken();
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          {companyLogo ? (
            <CompanyLogo
              src={
                hasUrl
                  ? companyLogo
                  : `${process.env.REACT_APP_S3_MEDIA}${companyLogo}`
              }
              alt=""
            />
          ) : (
            <Logo color="grey" size="small-extra" />
          )}
          <Links>
            {links.map(({ label, url }) => (
              <Link key={label} href={url} target="_blank">
                {label}
              </Link>
            ))}
          </Links>
          <IconButton
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: '0.4375rem',
              maxWidth: '160px',
              justifySelf: 'end',
              width: '160px',
              fontSize: '0.825rem',
              fontWeight: '700',
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[50],
              color: theme.palette.grey[500],
              '&:hover': {
                backgroundColor: theme.palette.grey[100],
              },
              '.MuiSvgIcon-root': {
                fontSize: '1rem',
              },
            })}
            href="mailto:support@seedsinvestor.com"
            target="_blank"
          >
            <ContactSupportIcon fontSize="small" />
            <span>Contact Support</span>
          </IconButton>
        </Content>
        <Legal />
        {isAdvisor && (
          <IconButton
            sx={(theme) => ({
              position: 'fixed',
              right: '30px',
              bottom: '30px',
              display: 'flex',
              alignItems: 'center',
              gap: '0.4375rem',
              maxWidth: '160px',
              justifySelf: 'end',
              width: '160px',
              fontSize: '0.825rem',
              fontWeight: '700',
              borderRadius: '8px',
              backgroundColor: theme.palette.secondary[50],
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary[100],
              },
              '.MuiSvgIcon-root': {
                fontSize: '1rem',
              },
            })}
            href=""
            onClick={handleZendeskHelp}
            target="_blank"
          >
            <SupportIcon fontSize="small" />
            <span>Need Help?</span>
          </IconButton>
        )}
      </Wrapper>
    </Container>
  );
};

export default memo(Footer);
