import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import DefaultButton from 'components/Button';
import DefaultCheckbox from 'components/Checkbox';
import DefaultInputBox from 'components/InputBox';

export const Container = styled.div`
  border-radius: 0.625rem;
  display: inline-block;
`;

export const Form = styled(DefaultForm)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
`;

export const Button = styled(DefaultButton)`
  min-width: 160px;
  margin-top: 1rem;
`;

export const InputBox = styled(DefaultInputBox)`
  margin-top: 0.5rem;
  width: 20rem;
`;

export const Checkbox = styled(DefaultCheckbox)`
  margin-right: 0.75rem;
`;

export const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.dark};
`;
