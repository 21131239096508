import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ImpactSnapshot from 'components/ImpactSnapshot';
import InsightsLibrary from 'components/ImpactSnapshot/InsightsLibrary';
import StockStories from 'components/ImpactSnapshot/StockStories';
import { SectionTitle } from 'components/ImpactSnapshot/styles';
import Level from 'components/Level';
import { PleaseCompleteAssessment } from 'components/PleaseCompleteAssessment';
import ValueScore from 'components/ValueScore';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useInsightsLibrary from 'context/hooks/InsightsLibrary.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import { useChartData } from 'hooks/useChartData';
import levelsHelper from 'utils/proposalHelpers/levels';
import Sectors from 'v2/components/Sectors';

import {
  DataNotAvailable,
  Description,
  ItemDescription,
  ModuleItemContainer,
  ModuleItemsWrapper,
  SectionModuleTitle,
  ValuesContainer,
} from './styles';

export function ImpactSnapshotWrapper() {
  const { id: investorId } = useParams<{ id: string }>();
  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));
  const [
    {
      masterProposal: { score },
      proposalState,
    },
  ] = useProposalStore((s: any) => ({
    masterProposal: s.masterProposal,
    proposalState: s.proposalState,
  }));

  const [{ isValuesDriven, status }] = useInvestorStore((s: any) => ({
    isValuesDriven: s.profile.isValuesDriven,
    status: s.profile.status,
  }));

  const chartData = useChartData();

  const { fetchInvestorFiles, fetchInvestorFolders, files, folders } =
    useInsightsLibrary();

  // pre filter files and folders
  const stockStoriesFolderId = folders.find(
    (folder) => folder.name === 'Stock Stories' && folder.parent === null,
  )?.id;

  const stockStoriesFiles = files.filter(
    (file) => file.folder === stockStoriesFolderId,
  );

  const otherFiles = files.filter(
    (file) => file.folder !== stockStoriesFolderId,
  );

  const otherFolders = folders.filter(
    (folder) => folder.name !== 'Stock Stories',
  );

  const { investorInfo } = useInvestorInfo();

  const maxValue = useMemo(() => {
    const maxFirst = chartData.current.sectors
      ? Math.max(...chartData.current.sectors.map((d) => d.value))
      : 0;
    const maxSecond = chartData.seeds.sectors
      ? Math.max(...chartData.seeds.sectors.map((d) => d.value))
      : 0;

    return Math.max(maxFirst, maxSecond);
  }, [chartData]);

  const hasUploadedPortfolio =
    currentPortfolio && currentPortfolio.holdings?.length > 0;
  const isProposed = !investorInfo?.seedsInvested;

  const levels = levelsHelper({
    currentPortfolio: currentPortfolio?.score,
    seedsPortfolio: score,
  });

  const seedsProposalScore = Math.round(score?.valueScore || 0);

  const isAssessmentAvailable = ['NOT_SENT', 'NOT_SET', 'SENT'].includes(
    investorInfo?.assessmentStatus || 'NOT_SET',
  );
  const accountsImplemented = proposalState === 'IMPLEMENTED';

  // TODO: This refetches everytime we switch to Insights tab, switch it to use
  // authFetch with caching or move up to top level where all initial load fetches are made
  useEffect(() => {
    const fetchFolderData = async () => {
      await fetchInvestorFolders();
    };

    const fetchFileData = async () => {
      await fetchInvestorFiles(investorId);
    };

    fetchFolderData();
    if (investorId) {
      fetchFileData();
    }
  }, [fetchInvestorFiles, fetchInvestorFolders, investorId]);

  if (!investorInfo || (status === 'INCOMPLETE' && !hasUploadedPortfolio))
    return (
      <DataNotAvailable proposed={false}>
        <Description>No portfolio selected yet.</Description>
      </DataNotAvailable>
    );

  return (
    <ImpactSnapshot proposed={isProposed}>
      {isValuesDriven && (
        <Box>
          <SectionTitle>Values</SectionTitle>
          <ModuleItemsWrapper>
            {hasUploadedPortfolio && !accountsImplemented && (
              <ModuleItemContainer>
                <SectionModuleTitle>Current Portfolio</SectionModuleTitle>
                <Description>
                  Overall your portfolio has{' '}
                  {currentPortfolio?.score.valueScore === 5 ? 'an' : 'a'}{' '}
                  <ValueScore value={currentPortfolio?.score?.valueScore} /> on
                  the <br />
                  world.
                </Description>
                <ValuesContainer>
                  {levels.current?.map((level) => (
                    <Level
                      key={level.title}
                      title={level.title}
                      completePercentage={level.percentage}
                    />
                  ))}
                </ValuesContainer>
              </ModuleItemContainer>
            )}

            {!isAssessmentAvailable && (
              <ModuleItemContainer>
                <SectionModuleTitle>
                  {accountsImplemented
                    ? 'Your Portfolio'
                    : 'Proposed Portfolio'}
                </SectionModuleTitle>
                <Description>
                  Overall your portfolio has{' '}
                  {seedsProposalScore === 5 ? 'an' : 'a'}{' '}
                  <ValueScore value={seedsProposalScore} /> on the <br />
                  world.
                </Description>
                <ValuesContainer>
                  {levels.recommended?.map((level) => (
                    <Level
                      key={level.title}
                      title={level.title}
                      completePercentage={level.percentage}
                    />
                  ))}
                </ValuesContainer>
              </ModuleItemContainer>
            )}
            {isAssessmentAvailable ? (
              <ModuleItemContainer>
                <PleaseCompleteAssessment investorId={investorInfo.id} />
              </ModuleItemContainer>
            ) : null}
          </ModuleItemsWrapper>
          <ItemDescription>
            Portfolios assessments based on a proprietary methodology using
            third-party &quot;EPI&quot; factor data. See disclosures for more
            detail.
            <br />
            <br />
            The Earth, People, and Integrity (&apos;EPI&apos;) ratings shown
            above may default to the headline values score, as individual EPI
            factor data is not available for certain funds and individual
            holdings.
          </ItemDescription>
        </Box>
      )}
      <Box>
        <SectionTitle>Exposures</SectionTitle>
        <ModuleItemsWrapper>
          {hasUploadedPortfolio && !accountsImplemented && (
            <ModuleItemContainer>
              <SectionModuleTitle>Current Sectors</SectionModuleTitle>
              <Description />
              <Sectors
                chartData={chartData.current?.sectors}
                maxValue={maxValue}
              />
            </ModuleItemContainer>
          )}
          {!isAssessmentAvailable && (
            <ModuleItemContainer>
              <SectionModuleTitle>
                {accountsImplemented ? 'Portfolio Sectors' : 'Proposed Sectors'}
              </SectionModuleTitle>
              <Description />
              <Sectors
                chartData={chartData.seeds?.sectors}
                maxValue={maxValue}
              />
            </ModuleItemContainer>
          )}
        </ModuleItemsWrapper>
      </Box>
      <Box>
        <SectionTitle>
          Insights Snapshot
          {isProposed && !accountsImplemented ? ` (Proposed)` : null}
        </SectionTitle>
        {otherFiles.length > 0 ? (
          <>
            {otherFiles.length > 0 && (
              <InsightsLibrary files={otherFiles} folders={otherFolders} />
            )}
            {stockStoriesFiles.length > 0 && (
              <StockStories files={stockStoriesFiles} />
            )}
          </>
        ) : (
          <Description>No data yet.</Description>
        )}
      </Box>
    </ImpactSnapshot>
  );
}
