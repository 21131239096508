import { gql } from '@apollo/client';

export const HOUSEHOLD_PERSON_FRAGMENT = gql`
  fragment HouseholdPersonParts on HouseholdPersonType {
    id
    firstName
    lastName
    email
    phone
    birthDate
  }
`;

export const MIN_HOUSEHOLD_FRAGMENT = gql`
  fragment MinHouseholdParts on HouseholdType {
    id
    name
  }
`;

export const HOUSEHOLD_FRAGMENT = gql`
  fragment HouseholdParts on HouseholdType {
    ...MinHouseholdParts
    householdPersonList {
      ...HouseholdPersonParts
    }
  }
  ${HOUSEHOLD_PERSON_FRAGMENT}
  ${MIN_HOUSEHOLD_FRAGMENT}
`;
