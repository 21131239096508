import * as yup from 'yup';

import { phoneValidation } from 'components/PhoneInput/constants';
import { AdvisorType } from 'graphql/generated';

export interface FormValues {
  confirmPassword: string;
  password: string;
  company: string;
  firstName: string;
  lastName: string;
  phone: string;
  acceptTerms: boolean;
  accept2FA: boolean;
}

export const getInitialValues = (
  advisor: AdvisorType | undefined,
): FormValues => ({
  confirmPassword: '',
  password: '',
  company: advisor?.company || '',
  firstName: advisor?.user?.firstName || '',
  lastName: advisor?.user?.lastName || '',
  phone: advisor?.user?.phone || '',
  acceptTerms: false,
  accept2FA: false,
});

const noSpaceRegExp = /^[^\s]+(\s+[^\s]+)*$/;
const noSpaceMessage = 'Leading or trailing whitespaces are not allowed';

export const validationSchema = yup.object({
  company: yup
    .string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .label('Company')
    .required(),
  firstName: yup
    .string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .label('First name')
    .required(),
  lastName: yup
    .string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .label('Last name')
    .required(),
  phone: phoneValidation.label('Phone number').required(),
  password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters')
    .matches(/[a-z]+/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]+/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]+/, 'Must contain at least one number')
    .matches(
      /[@$!%*#?&^_~]+/,
      'Must contain at least one special symbol (@,#,$,%,&,!)',
    ),

  confirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
