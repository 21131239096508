import { FC, useMemo, memo } from 'react';

import { from, useMediaQuery } from 'styles/media';

import {
  BarContainer,
  BarItem,
  CheckIcon,
  Container,
  FirstItemTitle,
  ItemTitle,
  LastItemTitle,
  ProgressCheckGroup,
  ProgressCheck,
  ProgressLine,
  Title,
  Tooltip,
  TooltipIcon,
} from './styles';
import { Props } from './types';

const SteppedProgressBar: FC<Props> = ({
  steps,
  advisorView,
  hideTitle,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);

  const lastCompleted = useMemo(() => {
    const completedSteps = steps.filter(({ completed }) => !!completed);
    return completedSteps[completedSteps.length - 1];
  }, [steps]);

  return (
    <Container {...props}>
      {!hideTitle && (
        <Title>{advisorView ? 'Progress' : 'Your progress'}</Title>
      )}
      <BarContainer>
        {steps.map(({ completed, name, info }, index, array) => {
          const isFirst = index === 0;
          const isLast = index === array.length - 1;
          let TitleComponent = ItemTitle;

          if (isFirst) {
            TitleComponent = FirstItemTitle;
          } else if (isLast) {
            TitleComponent = LastItemTitle;
          }

          return (
            <BarItem active={completed} key={name}>
              {!isFirst && <ProgressLine />}
              <ProgressCheckGroup>
                <ProgressCheck>
                  <CheckIcon title={name} />
                </ProgressCheck>
                <TitleComponent>
                  {name === lastCompleted?.name || !isMobile ? name : '...'}
                  {info && (
                    <>
                      <TooltipIcon />
                      <Tooltip>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </Tooltip>
                    </>
                  )}
                </TitleComponent>
              </ProgressCheckGroup>
            </BarItem>
          );
        })}
      </BarContainer>
    </Container>
  );
};

export default memo(SteppedProgressBar);
