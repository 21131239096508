import styled from 'styled-components';

import DefaultChart from 'v2/components/Chart';

export const SectionModuleTitle = styled.h3`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  color: ${({ theme }) => theme.colors.grey600};
`;

export const Chart = styled(DefaultChart)`
  margin-bottom: 2.75rem;

  @media print {
    margin-bottom: 1.25rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(26 / 16);
  margin: 0.5rem 0 1.875rem 0;
  color: ${({ theme }) => theme.colors.grey600};
`;
