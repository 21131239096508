import { nanoid } from 'nanoid/non-secure';

export const metrics = [
  {
    id: nanoid(),
    label: 'Market Value',
    value: 'MARKET_VALUE',
  },
  {
    id: nanoid(),
    label: 'Percentage',
    value: 'PERCENTAGE',
  },
];
