import createFastContext from 'context/fastContext/createFastContext';

import { GlobalState } from './types';

const initialState: GlobalState = {
  showEditAllocationsModal: false,
};

const { Provider, useStore } = createFastContext<GlobalState>(initialState);

// Dont forget to include Provider within src/context/fastContext/index.tsx
export { Provider as GlobalProvider, useStore };
