import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { AssignableAdvisorsQuery } from 'graphql/generated';
import { ASSIGNABLE_ADVISORS } from 'graphql/queries/advisor';
import { normalizeMinAdvisor } from 'models/Advisor';

const useAssignableAdvisors = () => {
  const { data, loading } =
    useQuery<AssignableAdvisorsQuery>(ASSIGNABLE_ADVISORS);

  const advisors = useMemo(
    () =>
      data?.assignableAdvisors
        ? data.assignableAdvisors.map(normalizeMinAdvisor)
        : [],
    [data],
  );

  return {
    advisors,
    loading,
  };
};

export default useAssignableAdvisors;
