import { Formik, Form, Field } from 'formik';
import { FC, SyntheticEvent } from 'react';

import { ReactComponent as CancelIcon } from 'assets/svg/cancel.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import IconButton from 'components/IconButton';

import { InputBox } from './styles';
import { Props } from './types';

const SearchBox: FC<Props> = ({
  className,
  initialSearch = '',
  onWrite,
  placeholder = 'Search...',
  ...props
}) => {
  return (
    <Formik
      initialValues={{ search: initialSearch }}
      onSubmit={({ search }) => {
        onWrite(search);
      }}
    >
      {({ resetForm, values, handleChange, submitForm }) => (
        <Form>
          <Field
            as={InputBox}
            name="search"
            type="search"
            placeholder={placeholder}
            autoComplete="off"
            leftIcon={<SearchIcon />}
            rightIcon={
              values.search && (
                <IconButton
                  type="button"
                  style={{ padding: 0, width: '15px' }}
                  icon={<CancelIcon />}
                  title="Clear input"
                  variant="transparent"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetForm({ values: { search: '' } });
                    submitForm();
                  }}
                  {...props}
                />
              )
            }
            onChange={(e: SyntheticEvent) => {
              handleChange(e);
              submitForm();
            }}
            className={className}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchBox;
