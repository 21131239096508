import { useState, createContext, useMemo } from 'react';

import { SortCallback } from 'components/Table/types';
import useNormalizeSorting from 'hooks/useNormalizeSorting';
import AuthFetch from 'utils/authFetch';
import useAsyncSort from 'utils/useAsyncSort';

export type InvestorsQueryVariables = {
  page?: number;
  pageSize?: number;
  search?: string;
};

export const AdvisorContext = createContext<{
  investors: {
    count: number;
    next?: string;
    previous?: string;
    results: any[];
  };
  loading: boolean;
  error: any;
  incompleteInvestorError: any;
  fetchInvestors: Function;
  onSort: SortCallback;
  sortBy: any;
  sortedBy: any;
  incompleteInvestors: any[];
  fetchIncompleteInvestors: Function;
}>({
  investors: {
    count: 0,
    next: undefined,
    previous: undefined,
    results: [],
  },
  loading: true,
  error: null,
  incompleteInvestorError: null,
  fetchInvestors: () => {},
  onSort: () => {},
  sortBy: [],
  sortedBy: [],
  incompleteInvestors: [],
  fetchIncompleteInvestors: () => {},
});

// Have this use fastContext when rewriting Household List
export const AdvisorProvider = ({ children }: any) => {
  const [investors, setInvestors] = useState<any>({});
  const [incompleteInvestors, setIncompleteInvestors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  // TODO: FO - Figure out better way to handle errors
  const [error, setError] = useState<any>(null);
  const [incompleteInvestorError, setIncompleteInvestorError] =
    useState<any>(null);
  const [sortBy, onSort] = useAsyncSort(true);
  const sortedBy = useNormalizeSorting(investors);

  const Advisor = useMemo(
    () => ({
      fetchInvestors: async ({
        page,
        pageSize,
        search,
      }: InvestorsQueryVariables) => {
        try {
          setLoading(true);
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}v2/user/investors/`,
            method: 'POST',
            queryParams: { page, page_size: pageSize },
            body: {
              orderBy: sortBy
                ? [sortBy]
                : [{ field: 'household', ordering: 'ASC' }],
              search,
            },
          });
          setLoading(false);
          setInvestors(data);
        } catch (e: any) {
          setLoading(false);
          setError(e.message);
        }
      },
      fetchIncompleteInvestors: async () => {
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}v2/user/incompleteInvestors/`,
            method: 'GET',
          });
          setIncompleteInvestors(data.results);
        } catch (e: any) {
          setIncompleteInvestorError(e.message);
        }
      },
      investors,
      loading,
      error,
      incompleteInvestorError,
      onSort,
      sortBy,
      sortedBy,
      incompleteInvestors,
    }),
    [
      error,
      incompleteInvestorError,
      incompleteInvestors,
      investors,
      loading,
      onSort,
      sortBy,
      sortedBy,
    ],
  );

  return (
    <AdvisorContext.Provider value={Advisor}>
      {children}
    </AdvisorContext.Provider>
  );
};
