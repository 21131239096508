import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './user';

const ADVISOR_GROUP_FRAGMENT = gql`
  fragment AdvisorGroupParts on AdvisorGroupType {
    id
    name
    lead {
      id
      user {
        ...UserParts
      }
      company
      isCustodian
    }
  }
  ${USER_FRAGMENT}
`;

export const MIN_ADVISOR_FRAGMENT = gql`
  fragment MinAdvisorParts on AdvisorType {
    id
    shortHash
    user {
      ...UserParts
    }
    investorList {
      id
    }
    company
    companyLogo
    isCustodian
  }
  ${USER_FRAGMENT}
`;

export const ADVISOR_FRAGMENT = gql`
  fragment AdvisorParts on AdvisorType {
    ...MinAdvisorParts
    group {
      ...AdvisorGroupParts
    }
  }
  ${ADVISOR_GROUP_FRAGMENT}
  ${MIN_ADVISOR_FRAGMENT}
`;
