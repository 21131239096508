import { useMutation } from '@apollo/client';

import {
  SetRiskLevelMutation,
  MutationSetRiskLevelArgs,
} from 'graphql/generated';
import { SAVE_RISK_LEVEL } from 'graphql/mutations/survey';

export const useConnect = () => {
  const [
    saveRiskMutation,
    { error: saveRiskMutationError, loading: saveRiskMutationLoading },
  ] = useMutation<SetRiskLevelMutation, MutationSetRiskLevelArgs>(
    SAVE_RISK_LEVEL,
  );

  const save = async (input: MutationSetRiskLevelArgs) => {
    const res = await saveRiskMutation({
      variables: {
        id: input.id,
        riskLevel: input.riskLevel,
        incomeLevel: input.incomeLevel,
      },
    });
    return res;
  };

  return {
    error: saveRiskMutationError?.message,
    loading: saveRiskMutationLoading,
    save,
  };
};
