// import { useMemo } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FaPencilAlt } from 'react-icons/fa';
import { Redirect, Switch, Route } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import PrettyLoading from 'components/PrettyLoading';
import ProgressBar from 'components/ProgressBar';
import { ScrollToTopOnMount } from 'components/ScrollToTopOnMount';
import RemoveHouseholdModal from 'containers/Modals/RemoveHouseholdModal';
import ShareModal from 'containers/Modals/ShareModal';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
// import useProposal from 'context/fastContext/proposal';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useInvestor from 'context/hooks/Investor.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import { useScrollAnchoring } from 'hooks/useScrollAnchoring';
import { ResultStatus } from 'models/Survey';

import { useConnect } from './connect';
import { Impact } from './Impact';
import { Portfolio } from './Portfolio';
import { Profile } from './Profile';
// import CallToAction from './Profile/components/CallToAction';
import {
  Container,
  EditButton,
  GoBack,
  Header,
  RemoveButton,
  Title,
  Tabs,
  Tab,
  TabsContainer,
  Main,
  Sidebar,
  ModuleLegacy,
} from './styles';

const ClientsDetailRoot = () => {
  const [isLoadingAccounts] = useAccountStore((s: any) => s.isLoadingAccounts);
  const [isLoadingPortfolio] = usePortfolioStore(
    (s: any) => s.isLoadingPortfolio,
  );
  const [isLoadingProposalData] = useProposalStore(
    (s: any) => s.isLoadingProposalData,
  );

  const {
    handleConfirmHouseholdRemoval,
    investorId,
    path,
    url,
    history,
    userData,
  } = useConnect();

  const { loadingProfile } = useInvestor();

  const { loadingInvestorInfo, investorInfo } = useInvestorInfo();

  useScrollAnchoring();

  // console.log('ClientsDetailRoot');

  if (
    isLoadingProposalData ||
    isLoadingAccounts ||
    isLoadingPortfolio ||
    loadingProfile ||
    isLoadingProposalData ||
    isLoadingPortfolio ||
    (loadingInvestorInfo && !investorInfo)
  )
    return <PrettyLoading />;

  const handleUploadPortfolio = () => {
    history.push(`/clients/${investorInfo?.id}/holdings`);
  };

  if (!loadingInvestorInfo && !investorInfo) return <Redirect to="/clients" />;
  return (
    <>
      <ScrollToTopOnMount />
      <Container>
        <Header>
          <GoBack
            onClick={(e) => {
              e.preventDefault();
              if (
                /profile|portfolio|impact/.exec(history.location.pathname) &&
                window.previousRoute
              ) {
                history.push(window.previousRoute);
              } else {
                history.push('/clients');
              }
            }}
            variant="secondary"
            icon={<ArrowLeft />}
            title="Back to investor list"
          />
          <Title>{investorInfo?.household?.name}</Title>
          <Tooltip title="Upload Current Portfolio">
            <Box>
              <IconButton
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary[50],
                  color: theme.palette.primary.main,
                  marginRight: '1rem',
                  alignItems: 'center',
                  display: 'flex',
                  height: '2.5rem',
                  justifyContent: 'center',
                  width: '2.5rem',
                  borderRadius: '6px',
                  '&:hover': {
                    backgroundColor: theme.palette.primary[100],
                  },
                })}
                onClick={handleUploadPortfolio}
              >
                <UploadFileIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
          <EditButton
            to={`${url}/edit-household`}
            variant="secondary"
            icon={<FaPencilAlt />}
            title="Edit household"
          />
          <RemoveButton
            icon={<TrashIcon />}
            title="Remove household"
            onClick={handleConfirmHouseholdRemoval}
            data-testid="investor-profile-button-remove-household"
          />
        </Header>
        <Main>
          {investorInfo?.user.id ? (
            <Sidebar
              user={userData}
              advisor={investorInfo!.advisor!.fullName}
              investorStatus={investorInfo!.investorStatus as ResultStatus}
              surveyStatus={investorInfo!.surveyResult.statusDisplay}
              surveyModified={investorInfo!.surveyResult.modified}
              investorId={investorId}
            />
          ) : null}
          <ModuleLegacy>
            <ProgressBar />
            <TabsContainer>
              <Tabs>
                <Tab to={`${url}/profile`} data-testid="profile-tab-link">
                  Profile
                </Tab>
                <Tab to={`${url}/portfolio`} data-testid="portfolio-tab-link">
                  Portfolio
                </Tab>
                <Tab to={`${url}/impact`} data-testid="insights-tab-link">
                  Insights
                </Tab>
              </Tabs>
            </TabsContainer>
            <Switch>
              <Route
                exact
                path={`${path}/profile`}
                render={() => <Profile />}
              />
              <Route exact path={`${path}/portfolio`} component={Portfolio} />
              <Route exact path={`${path}/impact`} component={Impact} />
              <Route path={`${path}/`} exact>
                <Redirect to={`${url}/profile`} />
              </Route>
            </Switch>
          </ModuleLegacy>
        </Main>
        <RemoveHouseholdModal investorId={investorId} />
        <ShareModal
          advisors={[]}
          investors={[investorInfo] as any[]}
          allAdvisors={false}
          allInvestors={false}
          recipientRole="INVESTOR"
        />
      </Container>
    </>
  );
};

export default ClientsDetailRoot;
