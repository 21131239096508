import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useHouseholdActions from 'graphql/hooks/useHouseholdActions';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';

import { ConnectProps } from './types';

const useConnect = ({ investorId }: ConnectProps) => {
  const { push } = useHistory();
  const { error, loading, remove } = useHouseholdActions();
  const isMobile = !useMediaQuery(from.tablet);
  const { close: handleClose, isOpen } = useModal('REMOVE_HOUSEHOLD');

  const handleRemove = useCallback(async () => {
    const success = await remove({ investorId });

    if (success) {
      handleClose();
      push('/');
    }
  }, [handleClose, investorId, push, remove]);

  return {
    error,
    handleClose,
    handleRemove,
    isMobile,
    isOpen,
    loading,
  };
};

export default useConnect;
