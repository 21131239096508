import { FC, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Props } from './types';

const Leaf: FC<Props> = ({ size, color, innerColor }) => {
  const theme = useContext(ThemeContext);
  const colors = {
    outer: theme.colors[color || 'primary'],
    inner: innerColor ? theme.colors[innerColor] : 'none',
  };

  if (size === 'large')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="34"
        viewBox="0 0 26 34"
      >
        <g fill="none" fillRule="evenodd" transform="translate(.591)">
          <path
            fill={colors.outer}
            d="M19.2258092,0.0181126841 C19.7038236,-0.0633486791 20.1865944,0.134140191 20.4684876,0.526459918 C24.9174692,6.71824759 25.9665813,13.4227075 23.5897768,20.5110084 C21.1674836,27.734969 14.6914538,31.927013 4.40835488,33.0952834 C3.88921054,33.1542638 3.38703715,32.8897561 3.1445825,32.4296219 C-0.0926117644,26.2860239 -0.831851774,19.8191327 0.933561676,13.0916835 C2.76364893,6.11778211 8.94744274,1.76971181 19.2258092,0.0181126841 Z M21.2874082,19.4205021 L8.52734133,26.0963542 L6.4604502,30.2651616 C14.1723221,28.9348114 18.9569476,25.6089253 20.9838315,20.293099 L21.0906413,20.0040052 L21.1904452,19.7159547 C21.2235053,19.6173602 21.2558263,19.5188761 21.2874082,19.4205021 Z M17.1683904,2.99143986 C9.3124328,4.80386935 4.78126605,8.38949284 3.3807795,13.7263176 C1.99553321,19.0050664 2.29947498,24.062203 4.29689783,28.9379296 L11.2984111,14.8236075 L11.2865933,14.7988053 L11.3194871,14.7817045 L17.1683904,2.99143986 Z M22.263271,12.1539042 L13.2129531,16.6514166 L10.4309238,22.2588769 L22.067791,16.1715979 C22.2816137,14.8118406 22.3468674,13.4730728 22.263271,12.1539042 Z M19.5924458,3.79324689 L15.0634241,12.921211 L21.894258,9.52632799 C21.4666945,7.57438569 20.6997457,5.66495115 19.5924458,3.79324689 Z"
          />
        </g>
      </svg>
    );

  if (size === 'medium-extra')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path
            fill={colors.outer}
            d="M17.835.016c.43-.073.865.105 1.119.458 4.004 5.572 4.948 11.606 2.809 17.986-2.18 6.501-8.009 10.274-17.263 11.326a1.14 1.14 0 01-1.138-.6C.45 23.658-.217 17.838 1.372 11.784 3.02 5.506 8.585 1.593 17.835.016zm1.856 17.462L8.207 23.487l-1.86 3.752c6.94-1.198 11.246-4.191 13.07-8.975l.097-.26.09-.26c.03-.088.058-.177.087-.266zM15.984 2.692c-7.07 1.631-11.149 4.859-12.41 9.662-1.246 4.75-.972 9.302.825 13.69l6.302-12.703-.011-.022.03-.015 5.264-10.612zm4.585 8.247l-8.145 4.047-2.504 5.047 10.473-5.479c.192-1.223.251-2.428.176-3.615zm-2.404-7.525l-4.076 8.215 6.148-3.055c-.385-1.757-1.075-3.476-2.072-5.16z"
          />
        </g>
      </svg>
    );

  if (size === 'small')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
        viewBox="0 0 16 20"
      >
        <path
          fill={colors.outer}
          fillRule="evenodd"
          d="M11.615593,0.01094308 C11.9043934,-0.0382731603 12.1960674,0.0810430323 12.3663779,0.318069534 C15.0543043,4.05894125 15.6881429,8.10955248 14.2521568,12.3920675 C12.788688,16.7565438 8.87608666,19.289237 2.66338108,19.9950671 C2.34973137,20.030701 2.04633494,19.8708943 1.89985193,19.5928966 C-0.055952941,15.8811395 -0.502577113,11.9740594 0.564026846,7.90955875 C1.66970456,3.69616002 5.40574665,1.06920088 11.615593,0.01094308 Z M12.8611425,11.73322 L5.15193539,15.7665473 L3.90318866,18.2852018 C8.56244459,17.4814485 11.4531559,15.472059 12.6777316,12.260414 L12.7422625,12.0857531 L12.8025606,11.9117226 C12.8225344,11.8521551 12.8420617,11.7926543 12.8611425,11.73322 Z M10.3725692,1.80732825 C5.62626149,2.90233773 2.88868157,5.06865193 2.04255428,8.29298357 C1.20563465,11.4822276 1.38926613,14.537581 2.59604244,17.4833325 L6.82612339,8.95592951 L6.81898346,8.94094485 L6.83885679,8.93061311 L10.3725692,1.80732825 Z M13.4507262,7.34298379 L7.98282584,10.0602309 L6.30201647,13.4480715 L13.3326237,9.77034041 C13.4618083,8.94882037 13.5012324,8.13998149 13.4507262,7.34298379 Z M11.8371026,2.29175333 L9.10081874,7.80656497 L13.2277809,5.75548983 C12.9694612,4.57619136 12.5060964,3.42257465 11.8371026,2.29175333 Z"
          transform="translate(.357)"
        />
      </svg>
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
    >
      <g fill="none" fillRule="evenodd" transform="translate(.429)">
        <path
          fill={colors.outer}
          d="M13.9387116,0.013131696 C14.2852721,-0.0459277923 14.6352809,0.0972516388 14.8396535,0.381683441 C18.0651651,4.8707295 18.8257714,9.73146297 17.1025882,14.8704811 C15.3464256,20.1078525 10.651304,23.1470844 3.19605729,23.9940805 C2.81967764,24.0368413 2.45560193,23.8450732 2.27982232,23.5114759 C-0.0671435292,19.0573673 -0.603092536,14.3688712 0.676832215,9.4914705 C2.00364548,4.43539203 6.48689599,1.28304106 13.9387116,0.013131696 Z M15.433371,14.079864 L6.18232247,18.9198568 L4.6838264,21.9422422 C10.2749335,20.9777383 13.743787,18.5664708 15.2132779,14.7124968 L15.290715,14.5029037 L15.3630728,14.2940672 C15.3870413,14.2225861 15.4104741,14.1511852 15.433371,14.079864 Z M12.447083,2.1687939 C6.75151378,3.48280528 3.46641789,6.08238231 2.45106514,9.95158028 C1.44676158,13.7786732 1.66711936,17.4450971 3.11525093,20.979999 L8.19134807,10.7471154 L8.18278015,10.7291338 L8.20662815,10.7167357 L12.447083,2.1687939 Z M16.1408715,8.81158054 L9.57939101,12.072277 L7.56241976,16.1376857 L15.9991485,11.7244085 C16.1541699,10.7385844 16.2014789,9.76797778 16.1408715,8.81158054 Z M14.2045232,2.750104 L10.9209825,9.36787797 L15.8733371,6.90658779 C15.5633535,5.49142963 15.0073156,4.10708959 14.2045232,2.750104 Z"
        />
      </g>
    </svg>
  );
};

export default memo(Leaf);
