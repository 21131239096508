import { FC, memo } from 'react';

import useConnect from './connect';
import Notification from './Notification';
import NotificationBell from './NotificationBell';
import { Option, BaseDropdown } from './styles';
import { Props } from './types';

const Notifications: FC<Props> = ({ className }) => {
  const { arePendingNotifications, markAsRead, notifications, onSelect } =
    useConnect();

  return (
    <BaseDropdown
      className={className}
      title="Notifications"
      data-testid="notifications"
      trigger={
        <NotificationBell
          data-testid="toggle-notifications"
          alert={arePendingNotifications}
          disabled={!notifications.length}
        />
      }
      onOpen={markAsRead}
    >
      {notifications.map((notification) => (
        <Option
          key={notification.id}
          onSelect={onSelect(notification.customUrl)}
        >
          <Notification {...notification} />
        </Option>
      ))}
    </BaseDropdown>
  );
};

export default memo(Notifications);
