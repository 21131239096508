import { useMemo } from 'react';

import { ReactComponent as LogoutIcon } from 'assets/svg/menu/logout.svg';
import { ReactComponent as UserIcon } from 'assets/svg/menu/user.svg';
import { Option as MenuOption } from 'components/Menu/types';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { logout } = useAuthActions();
  const { me } = useMe();
  const { investorInfo } = useInvestorInfo();

  const menuOptions: MenuOption[] = useMemo(
    () => [
      {
        name: 'Edit profile',
        to: '/profile',
        icon: <UserIcon />,
        iconPosition: 'left',
      },
      {
        name: 'Log out',
        onSelect: logout,
        variant: 'alert',
        icon: <LogoutIcon />,
        iconPosition: 'left',
      },
    ],
    [logout],
  );

  return {
    investorInfo,
    me,
    menuOptions,
  };
};

export default useConnect;
