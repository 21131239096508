/* eslint-disable no-console */
import { FormikHelpers } from 'formik';
import { GraphQLError } from 'graphql';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';
import useSearchParams from 'hooks/useSearchParams';
import { logger } from 'services/logger';
import { deleteReleaseNotesDisplayedInSession } from 'services/session';
import { setToken, setRefreshToken } from 'services/storage';
import { from as query, useMediaQuery } from 'styles/media';

import { FormValues as CodeFormValues } from './CodeForm/types';
import { useWarnings, Warnings } from './logic';
import { FormValues } from './types';

export const useConnect = () => {
  const {
    validateParameterToken,
    validateParameterTokenError,
    validateParameterTokenLoading,
    sendVerificationCode,
    validateVerificationCode,
  } = useAuthActions();
  const { login } = useAuthActions();
  const { push } = useHistory();
  const isMobile = !useMediaQuery(query.tablet);
  const { refetch } = useMe();
  const search = useSearchParams();
  const token = search.get('t');
  const refreshToken = search.get('refresh');
  const continueParam = search.get('continue');
  const { closeWarning } = useWarnings();
  const [resendCount, setResendCount] = useState(0);
  const [codeResent, setCodeResent] = useState<boolean>(false);
  const [twoFAToken, setTwoFAToken] = useState<string>('');
  const [uidb64, setUidb64] = useState('');
  const [phone, setPhone] = useState<string>('');
  const [require2FA, setRequire2FA] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      try {
        const response = await login(values);
        const authToken = response.data?.tokenAuth?.token || '';
        const refreshAccessToken = response.data?.tokenAuth?.refreshToken || '';
        const verify2FA = response.data?.tokenAuth?.require2FA;
        const authUidb64 = response.data?.tokenAuth?.uidb64 || '';

        if (verify2FA && authToken && authUidb64) {
          setTwoFAToken(authToken);
          setUidb64(authUidb64);
          setRequire2FA(true);

          try {
            const data = await sendVerificationCode({
              token: authToken,
              uidb64: authUidb64,
            });
            if (data) {
              setPhone(data);
            }
          } catch (err) {
            const error = err as GraphQLError;
            setErrorText(error.message);
            return;
          }
        } else if (!verify2FA && authToken) {
          setToken(authToken);
          if (refreshAccessToken) setRefreshToken(refreshAccessToken);
          deleteReleaseNotesDisplayedInSession();

          await refetch();
          closeWarning(Warnings.INACTIVITY);
          setTimeout(() => push(continueParam || '/'));
        }
      } catch (e) {
        const error = e as GraphQLError;
        setErrors({ password: error.message });
      }
    },
    [login, push, refetch, closeWarning, continueParam, sendVerificationCode],
  );

  const handleResendCode = useCallback(async () => {
    // Limit 3 sends per 15 minutes. 1 initial + 2 resends
    if (resendCount < 2) {
      try {
        await sendVerificationCode({
          token: twoFAToken,
          uidb64,
        });
        setResendCount((currentCount) => currentCount + 1);
        if (resendCount === 1) {
          setCodeResent(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [sendVerificationCode, twoFAToken, uidb64, resendCount]);

  const handleSubmitCode = useCallback(
    async (
      values: CodeFormValues,
      { setErrors }: FormikHelpers<CodeFormValues>,
    ) => {
      try {
        const response = await validateVerificationCode({
          code: values.code,
          token: twoFAToken,
          uidb64,
        });
        const accessToken = response?.token || '';
        const refreshAccessToken = response?.refreshToken || '';
        if (accessToken) setToken(twoFAToken);
        if (refreshAccessToken) setRefreshToken(refreshAccessToken);
        deleteReleaseNotesDisplayedInSession();

        await refetch();
        closeWarning(Warnings.INACTIVITY);
        setTimeout(() => push(continueParam || '/'));
      } catch (e) {
        const error = e as GraphQLError;
        setErrors({ code: error.message });
      }
    },
    [
      validateVerificationCode,
      closeWarning,
      continueParam,
      push,
      refetch,
      twoFAToken,
      uidb64,
    ],
  );

  const handleRequire2FAClear = () => {
    setRequire2FA(false);
  };

  // Okta
  const validate = useCallback(async () => {
    try {
      if (token) {
        const success = await validateParameterToken({
          token,
        });

        if (success) {
          setToken(token);
          setRefreshToken(refreshToken!);

          push('/clients');
        }
      }
    } catch (err) {
      // TODO: should this error be handled better?
      logger.error(err);
    }
  }, [push, refreshToken, token, validateParameterToken]);

  useEffect(() => {
    validate();
  }, [validate]);

  return {
    error: validateParameterTokenError,
    errorText,
    handleSubmit,
    handleResendCode,
    handleSubmitCode,
    handleRequire2FAClear,
    codeResent,
    phone,
    require2FA,
    isMobile,
    loading: validateParameterTokenLoading,
    token,
  };
};
