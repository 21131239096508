import { format, parseISO } from 'date-fns';
import { forwardRef } from 'react';

import { Result } from 'components/SurveyResultsCard';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';

import useConnect from '../Answers/connect';
import { SurveyResultsTitle, SurveyResultsSubTitle } from '../Answers/styles';
import StepComponent from '../Form/StepComponent';
import { Answer } from '../Form/types';
import { Hidden, Container } from './styles';

const PrintAssessment = forwardRef<HTMLDivElement>((_, ref) => {
  const { surveyResults, survey } = useConnect();
  const { investorInfo } = useInvestorInfo();

  const surveySteps = survey?.steps;

  const selectedSteps = surveySteps?.filter(
    (step) =>
      // @ts-ignore
      surveyResults?.answers.find((answer) => answer?.step.id === step.id) !==
      undefined,
  );

  return (
    <Hidden ref={ref}>
      <Container printView>
        <SurveyResultsTitle>Assessment</SurveyResultsTitle>
        <SurveyResultsSubTitle>
          {investorInfo?.surveyResult.modified &&
            `Assessed On ${format(
              parseISO(investorInfo?.surveyResult.modified),
              'MMMM dd, yyyy',
            )}`}
        </SurveyResultsSubTitle>
        {selectedSteps?.map((step: { id: string }, stepIdx: number) => {
          const stepRes = surveyResults?.answers.find(
            // @ts-ignore
            (answer) => answer?.step.id === step.id,
          );
          const currentStep = stepIdx + 1;
          const selected = stepRes?.selected;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Result key={step.id + stepIdx}>
              <StepComponent
                initialValue={selected as Answer}
                // @ts-ignore
                stepList={selectedSteps}
                // @ts-ignore
                currentStep={currentStep}
                onAnswer={() => {}}
                readOnly
              />
            </Result>
          );
        })}
      </Container>
    </Hidden>
  );
});

export default PrintAssessment;
