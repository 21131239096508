import { Component } from 'react';

import gloomyPlantPng from 'assets/images/errorBoundary/gloomy-plant.png';

import {
  Container,
  Logo,
  Content,
  GloomyPlant,
  Title,
  Subtitle,
  Link,
  ContactMessage,
} from './styles';
import { Props, State } from './types';

// There is no way to write error boundaries with hooks as of right now
// eslint-disable-next-line react/prefer-stateless-function
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <Container>
        <Logo />
        <Content>
          <GloomyPlant src={gloomyPlantPng} alt="" />
          <Title>Oops...</Title>
          <Subtitle>Sorry, something went wrong.</Subtitle>
          <Link href={window.location.href}>Try again</Link>
          <ContactMessage>
            If the problem persists{' '}
            <a href="mailto:hello@seedsinvestor.com">Contact Us</a>
          </ContactMessage>
        </Content>
      </Container>
    );
  }
}

export default ErrorBoundary;
