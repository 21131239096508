import { FC } from 'react';

import { isNullOrUndefined } from 'utils/defaultSort';

import { Container } from './styles';
import { Props } from './types';

const UndefinedValue: FC<Props> = ({ children, ...props }) => {
  const isValid = !isNullOrUndefined(children);

  return (
    <Container {...props} $hasValue={isValid}>
      {isValid ? children : 'Not defined'}
    </Container>
  );
};

export default UndefinedValue;
