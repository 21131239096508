import { FC } from 'react';

import { Container } from './styles';
import { Props } from './types';

const ContainerMaxWidth: FC<Props> = ({ tag = 'div', children, ...props }) => {
  return (
    <Container as={tag as any} {...props}>
      {children}
    </Container>
  );
};

export default ContainerMaxWidth;
