import { Field, Formik } from 'formik';
import { FC, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';

import Modal from 'components/Modal';

import { initialValues, validationSchema } from './constants';
import { Container, InputBox, Button, Column, Form } from './styles';
import { Props } from './types';

const SurveyStatusEmail: FC<Props> = ({ email, name, onSubmit, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container {...props}>
      <Button
        variant="primary"
        icon={<FaPaperPlane />}
        iconPlacement="left"
        onClick={() => setIsOpen(true)}
      >
        Send Assessment
      </Button>
      <Modal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title="Send assessment"
      >
        <Formik
          initialValues={initialValues(email, name)}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form noValidate>
              <Column>
                <Field
                  as={InputBox}
                  name="email"
                  type="email"
                  placeholder="House holder email"
                  value={values.email}
                />
                <Button
                  type="submit"
                  variant="primary"
                  icon={<FaPaperPlane />}
                  iconPlacement="left"
                  disabled={isSubmitting || !values.email}
                >
                  Send assessment
                </Button>
              </Column>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default SurveyStatusEmail;
