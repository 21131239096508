export const tabs = [
  {
    label: 'Advisors',
    to: '/advisors',
  },
  {
    label: 'Clients',
    to: '/clients',
  },
  {
    label: 'Shared',
    to: '/shared',
  },
];
