import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { memo } from 'react';

import useConnect from './connect';
import { Asset, Divider, List, Modal, Subtitle, Text, Title } from './styles';

const FailedAssetsModal = () => {
  const { assets, handleClose, isMobile, isOpen } = useConnect();

  return (
    <Modal fullScreen={isMobile} onClose={handleClose} open={isOpen}>
      <Title>Missing Assets</Title>
      <Text>
        The following assets have failed to upload. We have uploaded the assets
        we recognize and adjusted your portfolio allocation accordingly.
      </Text>
      <Subtitle>Missing Assets</Subtitle>
      <Divider />
      <List>
        {assets.map((asset) => (
          <Asset key={asset}>{asset}</Asset>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          marginTop: '1.5rem',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            minWidth: '120px',
          }}
          variant="contained"
          type="button"
          onClick={handleClose}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default memo(FailedAssetsModal);
