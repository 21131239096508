import { ChangeEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import usePortfolioActions from 'context/fastContext/portfolio/actions';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { ValueType } from 'context/fastContext/portfolio/types';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const { error, clearError, importPortfolio } = usePortfolioActions();

  const [creationMethod, setCreationMethod] = useState<ValueType | null>(null);
  const { id } = useParams<{ id: string }>();
  const isMobile = !useMediaQuery(from.tablet);
  const { close: handleClose, isOpen } = useModal('IMPORT_PORTFOLIO');
  const { open: handleOpen } = useModal('FAILED_ASSETS');
  const [spreadsheet, setSpreadsheet] = useState<File | undefined>(undefined);

  const [{ isImportingPortfolio, hasError }] = usePortfolioStore((s) => ({
    isImportingPortfolio: s.isImportingPortfolio,
    hasError: s.hasError,
  }));

  const handleDrop = useCallback(
    (files: File[]) => {
      setSpreadsheet(files[0]);
      clearError();
    },
    [setSpreadsheet, clearError],
  );

  const handleRemove = useCallback(() => {
    setSpreadsheet(undefined);
    clearError();
  }, [setSpreadsheet, clearError]);

  const handleImport = useCallback(async () => {
    clearError();
    if (!!id && !!creationMethod && !!spreadsheet) {
      const { fails, success } = await importPortfolio({
        file: spreadsheet,
        valueType: creationMethod,
      });
      if (success) {
        handleRemove();
        if (fails.length > 0) {
          handleOpen({ assets: fails });
        } else {
          setCreationMethod(null);
          handleClose();
        }
      }
    }
  }, [
    id,
    creationMethod,
    spreadsheet,
    importPortfolio,
    handleRemove,
    handleOpen,
    handleClose,
    clearError,
  ]);

  const handleSelectMetricsValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCreationMethod(e.target.value as ValueType);
      clearError();
    },
    [setCreationMethod, clearError],
  );

  return {
    error,
    hasError,
    handleClose,
    handleDrop,
    handleImport,
    handleRemove,
    handleSelectMetricsValue,
    creationMethod,
    isMobile,
    isOpen,
    isValid: !!id && !!creationMethod && !!spreadsheet && !isImportingPortfolio,
    spreadsheet,
    isImportingPortfolio,
  };
};

export default useConnect;
