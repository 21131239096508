import styled from 'styled-components';

import { ValueCard as DefaultValueCard } from '../ValueCard';
import { CategoryTitle } from './CategoryTitle';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2.5rem;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(CategoryTitle)``;

export const ValueCard = styled(DefaultValueCard)`
  & + & {
    margin-block-start: 0.5rem;
  }
`;
