import { Form as DefaultForm } from 'formik';
import styled from 'styled-components';

import theme from 'styles/v2/theme';

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 35;
  justify-content: start;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Form = styled(DefaultForm)`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
`;

export const InlineInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 0.875rem;
  flex: 1;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 65;
  justify-content: start;
`;

export const ProductTable = styled.div`
  width: 600px;
  border: 1px solid ${theme.palette.grey[100]};
`;

export const TaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const TaxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TicketRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const Title = styled.h4`
  font-family: 'Greycliff CF';
  font-size: 1.125rem;
  font-weight: ${theme.typography.fontWeightBold};
  line-height: 26px;
  margin: 0 0 0.75rem;
`;

export const TradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;
