import { useCallback } from 'react';

import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const isMobile = !useMediaQuery(from.tablet);
  const { close: handleClose, data, isOpen } = useModal('FAILED_ASSETS');
  const { open: handleOpen } = useModal('IMPORT_PORTFOLIO');

  const handleRepeat = useCallback(() => {
    handleClose();
    handleOpen({ assets: [] });
  }, [handleClose, handleOpen]);

  return {
    assets: data?.assets || [],
    handleClose,
    handleRepeat,
    isMobile,
    isOpen,
  };
};

export default useConnect;
