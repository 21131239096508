import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from 'graphql/fragments/notification';

export const NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      ...NotificationParts
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
