import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import type { AccountToAddType } from 'components/Account/Modals/AddAccountModal';
import { InlineInput } from 'components/Account/styles';
import Dropdown from 'components/Dropdown';
import {
  AccountCustodianType,
  AccountTypeType,
} from 'context/fastContext/account/types';

const textFieldStyles = {
  width: '100%',
  flex: 1,
  '& .MuiOutlinedInput-root': {
    height: '46px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px',
  },
  '& .MuiInputLabel-outlined': {
    lineHeight: '14px',
    top: 0,
    '&.MuiInputLabel-shrink': {
      top: '4px',
    },
  },
};

function AddAccounts({
  accountsToAdd,
  updateAccount,
  disableAddAccountInputs,
  handleRemoveAccount,
  accountTypes,
  accountCustodians,
}: {
  accountsToAdd: AccountToAddType[];
  updateAccount: any;
  disableAddAccountInputs: boolean;
  handleRemoveAccount: any;
  accountTypes: AccountTypeType[];
  accountCustodians: AccountCustodianType[];
}) {
  const custodianOptions = accountCustodians.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  const accountTypeOptions = accountTypes.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  return (
    <Box mt="12px">
      {accountsToAdd.map((account, index) => (
        <Box
          key={account.key}
          sx={(theme) => ({
            position: 'relative',
            marginBottom: '16px',
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: '6px',
            padding: '12px',
            rowGap: '14px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
          >
            <TextField
              label="Account Name"
              variant="outlined"
              value={account.name}
              data-type="name"
              data-index={index}
              inputProps={{
                'data-type': 'name',
                'data-index': index,
                'data-testid': 'account-name',
              }}
              onChange={updateAccount as any}
              disabled={disableAddAccountInputs}
              required
              sx={textFieldStyles}
            />
            <TextField
              label="Value (USD)"
              variant="outlined"
              inputProps={{
                'data-type': 'value',
                'data-index': index,
                'data-testid': 'account-value',
              }}
              value={account.value}
              onChange={updateAccount as any}
              disabled={disableAddAccountInputs}
              required
              sx={textFieldStyles}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
          >
            <Dropdown
              onChange={updateAccount}
              options={custodianOptions}
              value={account.custodian}
              dataset={{
                type: 'custodian',
                index,
              }}
              label="Custodian *"
              disabled={disableAddAccountInputs}
              required
              height="22px"
              padding="12px 32px 12px 12px"
              sx={{ flex: '1' }}
              dataTestId="account-custodian"
            />
            <InlineInput>
              <Dropdown
                onChange={updateAccount}
                options={accountTypeOptions}
                value={account.type}
                dataset={{
                  type: 'type',
                  index,
                }}
                label="Account Type *"
                disabled={disableAddAccountInputs}
                required
                height="22px"
                padding="12px 32px 12px 12px"
                sx={{ flex: '1' }}
                dataTestId="account-type"
              />
              {index !== 0 && (
                <IconButton
                  sx={(theme) => ({
                    padding: '2px',
                    color: `${theme.palette.error.main}`,
                    margin: 0,
                    backgroundColor: 'rgba(211, 47, 47, 0.1)',
                    borderRadius: '6px',
                    '&:hover': {
                      backgroundColor: 'rgba(211, 47, 47, 0.2)',
                    },
                  })}
                  data-index={index}
                  onClick={handleRemoveAccount}
                >
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              )}
            </InlineInput>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default AddAccounts;
