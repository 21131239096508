import { SnackbarOrigin, CombinedClassKey } from 'notistack';

import { light } from './light';

export const notistackConfig: {
  maxSnack: number;
  anchorOrigin: SnackbarOrigin;
  classes: Partial<Record<CombinedClassKey, string>>;
} = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  classes: {
    variantSuccess: 'si-snackbar-success',
    variantError: 'si-snackbar-error',
  },
};

export type Theme = typeof light;
export type Color = keyof Theme['colors'];

export default { light };
