import { useMemo } from 'react';

import { calculateRiskScore } from 'context/fastContext/helpers';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import {
  AllocationsType,
  AssetGroupType,
} from 'context/fastContext/proposal/types';
import { Industries, useIndustries } from 'hooks/useIndustries';
import { Sector } from 'models/Portfolio';
// import generateChartData from 'utils/proposal/generateChartData';
import theme from 'styles/v2/theme';
// import getChartBreakdown from 'utils/proposal/getChartBreakdown';
const colorSchema = [
  '#ADB2B0',
  '#CCFAE5',
  '#91E3C2',
  '#24B07A',
  '#1c7552',
  '#D5C9FE',
  '#A199DE',
  '#6449C0',
  '#FCC79E',
  '#D48A57',
];

function isKeyOfAllocationsType(key: string): key is keyof AllocationsType {
  return (
    key in
    {
      BD: true,
      EQ: true,
      INT: true,
      LC: true,
      MC: true,
      SC: true,
      TE: true,
      TX: true,
      'ALT-LQ': true,
      ALT: true,
      'EM-EX-US': true,
    }
  );
}

function assetColors(assetClass: any): string | null {
  const assetColor: Record<string, string> = {
    'Large Cap US': theme.palette.primary[900],
    'Mid Cap US': theme.palette.primary[700],
    'Small Cap US': theme.palette.primary[500],
    International: theme.palette.primary[400],
    'Developed Ex-US': theme.palette.primary[400],
    Emerging: theme.palette.primary[300],
    'Taxable Bonds': theme.palette.primary[200],
    'Municipal Bonds': theme.palette.primary[100],
    'Liquid Alts': theme.palette.primary[50],
  };

  if (assetClass in assetColor) {
    return assetColor[assetClass];
  }
  return theme.palette.grey[900];
}

export const getInnerPieData = ({ riskLevel }: { riskLevel?: number }) => {
  const riskScoreData = [
    {
      title: 'Risk Score',
      color: colorSchema[riskLevel ? riskLevel - 1 : 0],
      value: riskLevel || 1,
    },
  ];
  return [...riskScoreData];
};

export const getSectorsData = (industries: Industries, sectors?: Sector[]) => {
  if (!sectors) return undefined;

  const data = sectors.map(({ sector, value }) => {
    return {
      title: sector,
      value,
      ...industries[sector as keyof Industries],
    };
  });

  return data;
};

export function useChartData() {
  const industries = useIndustries();
  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));

  const [{ assetClassGroups, sectors, allocations, proposalRiskScore }] =
    useProposalStore((s: any) => ({
      assetClassGroups: s.assetClassGroups as AssetGroupType[],
      sectors: s.masterProposal.sectors,
      allocations: s.masterProposal.allocations,
      proposalRiskScore: s.masterProposal.riskScore,
    }));

  const newProposalBreakdown = assetClassGroups
    .sort((a: any, b: any) => a.assetOrder - b.assetOrder)
    .reduce<any[]>((mem, group) => {
      let totalWeight;
      if (allocations && isKeyOfAllocationsType(group.symbol)) {
        totalWeight = allocations[group.symbol];
      }

      const groupObj = {
        name: group.name,
        totalWeight,
        breakdown: group.children
          .sort((a: any, b: any) => a.assetOrder - b.assetOrder)
          .reduce((childMem, child) => {
            let allocation;
            if (allocations && isKeyOfAllocationsType(child.symbol)) {
              allocation = allocations[child.symbol];
            }

            const childObj = {
              allocation,
              label: child.name,
              title: child.name,
              color: assetColors(child.name) || null,
            };

            childMem.push(childObj);

            return childMem;
          }, [] as any[]),
      };

      mem.push(groupObj);

      return mem;
    }, []);

  const pieInnerDataSeeds = useMemo(() => {
    return getInnerPieData({
      riskLevel: proposalRiskScore!,
    });
  }, [proposalRiskScore]);

  const pieInnerDataCurrent = useMemo(() => {
    return getInnerPieData({
      riskLevel: calculateRiskScore(currentPortfolio?.score?.equities || 0),
    });
  }, [currentPortfolio?.score?.equities]);

  const chartData = {
    current: {
      sectors: getSectorsData(industries, currentPortfolio?.sectors),
      pie: pieInnerDataCurrent,
      allocations: {
        equity: currentPortfolio?.score?.equities,
        fixed: currentPortfolio?.score?.fixed,
        cash: currentPortfolio?.score?.cash,
        other: currentPortfolio?.score?.other,
      },
    },
    seeds: {
      breakdown: newProposalBreakdown,
      sectors: getSectorsData(industries, sectors),
      pie: pieInnerDataSeeds,
      allocations: {
        equity: allocations?.EQ,
        fixed: allocations?.BD,
        alternatives: allocations?.ALT,
      },
    },
  };

  return chartData;
}
