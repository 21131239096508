import { IMaskInput } from 'react-imask';
import styled, { css } from 'styled-components';

import { TextWrap } from 'styles/mixins';

import { ContainerProps } from './types';

const Transition = css`
  transition: opacity 80ms ease-out;
`;

export const Input = styled(IMaskInput)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'GreyCliff CF';
  font-size: 1rem;
  line-height: calc(22 / 16);
  width: 100%;

  ${TextWrap}

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey600};
    ${Transition}
  }

  &:focus {
    outline: none;
  }
`;

export const Label = styled.div`
  color: inherit;
  height: 1.125rem;
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  ${Transition}
`;

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-bottom: solid 0.125rem ${({ theme }) => theme.colors.grey200};
  border-radius: 0.5rem 0.5rem 0 0;
  color: ${({ theme }) => theme.colors.grey600};
  display: flex;
  flex-direction: column;
  height: 3.125rem;
  justify-content: center;
  max-width: 16.8125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 100%;

  &:focus-within {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: solid 0.125rem ${({ theme }) => theme.colors.primary};
  }

  ${({ isError, theme }) => {
    return isError
      ? css`
          color: ${theme.colors.flamingo} !important;
          border-bottom: solid 0.125rem ${theme.colors.flamingo} !important;
        `
      : null;
  }}

  &:hover {
    ${Input}::placeholder {
      opacity: 0.7;
      ${Transition};
    }
  }

  .react-datepicker__tab-loop {
    display: none;
  }
`;
