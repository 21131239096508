import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';

import { ReactComponent as EditIcon } from 'assets/svg/pencil.svg';
import Accounts from 'components/Account';
import FinancialGoals from 'components/FinancialGoals';
// import useGlobal from 'context/fastContext/global';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useModal from 'graphql/hooks/useModal';
import EditAllocationsModal from 'v2/components/EditAllocationsModal';

import {
  Description,
  ItemDescription,
  ItemDescriptionWrapper,
  Module,
  ModuleItemContainer,
  ModuleItemsWrapper,
  // SectionTitle,
  ModuleItemsHeader,
  ModuleWrapperTitle,
} from '../../../../../styles';
import { EditButton, Divider } from '../../styles';
import { Props } from './types';

const CurrentAndSeedsLayout = ({ chartData }: Props) => {
  const { investorInfo } = useInvestorInfo();
  const [accounts] = useAccountStore((s: any) => s.accounts);
  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));

  const hasUploadedPortfolio =
    currentPortfolio?.holdings && currentPortfolio?.holdings.length > 0;

  const hasMissingTickers =
    currentPortfolio?.missingTickers &&
    currentPortfolio?.missingTickers.length > 0;
  // TODO: This should be consistent with other checks and check the status on the user
  // not on the survey. Should we only read from the survey status to allow users to retake
  // the survey not anywhere else?
  const surveyCompleted =
    investorInfo?.surveyResult?.statusDisplay === 'Completed';
  const openEditAllocationsModal = useModal('EDIT_ALLOCATIONS');

  const disableEditAllocationsModal = useMemo(
    () => accounts.length > 0,
    [accounts.length],
  );

  return (
    <>
      <Module>
        <ModuleItemsWrapper $surveyCompleted={surveyCompleted}>
          <ModuleItemContainer>
            <ModuleItemsHeader>
              <ModuleWrapperTitle>Current Portfolio</ModuleWrapperTitle>
              <EditButton
                variant="transparent"
                to={`/clients/${investorInfo?.id}/holdings`}
              >
                Edit
                <EditIcon />
              </EditButton>
            </ModuleItemsHeader>
            <Divider />
            {hasUploadedPortfolio ? (
              <FinancialGoals chartData={chartData} dataShown="current" />
            ) : (
              <Description>
                This portfolio has not been completed yet
              </Description>
            )}
            {hasMissingTickers && (
              <Tooltip
                title={`Missing Assets: ${currentPortfolio?.missingTickers.join(
                  ', ',
                )}`}
              >
                <Alert
                  severity="warning"
                  sx={(theme) => ({
                    borderRadius: '6px',
                    color: theme.palette.warning.main,
                    bgcolor: theme.palette.warning.main[100],
                    padding: '0 1rem',
                  })}
                >
                  Current Portfolio Missing Assets
                </Alert>
              </Tooltip>
            )}
          </ModuleItemContainer>
          {surveyCompleted && (
            <ModuleItemContainer>
              <ModuleItemsHeader>
                <ModuleWrapperTitle>Proposed Portfolio</ModuleWrapperTitle>
                <Tooltip
                  title={
                    disableEditAllocationsModal
                      ? 'Please make allocation edits within each account below. Those changes will be reflected here.'
                      : null
                  }
                >
                  <Box>
                    <EditButton
                      disabled={disableEditAllocationsModal}
                      variant="transparent"
                      onClick={() => openEditAllocationsModal.open()}
                      data-testid="edit-allocations-button"
                    >
                      Edit
                      <EditIcon />
                    </EditButton>
                  </Box>
                </Tooltip>
              </ModuleItemsHeader>
              <Divider />
              <FinancialGoals chartData={chartData} dataShown="seeds" />
            </ModuleItemContainer>
          )}
        </ModuleItemsWrapper>
        <ItemDescriptionWrapper>
          <ItemDescription>
            Industry classification is provided via FactSet data. Occasionally,
            positions in the portfolio are not recognized and are excluded from
            the visual representation. Sector weights represent only the equity
            portion of the portfolio.
          </ItemDescription>
        </ItemDescriptionWrapper>

        {surveyCompleted && <Accounts />}
      </Module>
      <EditAllocationsModal />
    </>
  );
};

export default CurrentAndSeedsLayout;
