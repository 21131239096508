import { FC, useMemo } from 'react';

import { Container } from './styles';
import { Props, ValueScoreType } from './types';

const ValueScore: FC<Props> = ({ value, ...props }) => {
  const scoreDefinition = useMemo(() => {
    switch (value) {
      case ValueScoreType.DANGEROUS:
        return 'Dangerous';
      case ValueScoreType.HARMFUL:
        return 'Harmful';
      case ValueScoreType.BENEFICIAL:
        return 'Beneficial';
      case ValueScoreType.IDEAL:
        return 'Ideal';
      case ValueScoreType.NEUTRAL:
        return 'Neutral';
      default:
        return 'Not known';
    }
  }, [value]);
  return (
    <Container $level={value} {...props}>
      {scoreDefinition} impact
    </Container>
  );
};

export default ValueScore;
