import { useSubscription } from '@apollo/client';

import {
  OnNewNotification,
  SubscriptionOnNewNotificationArgs,
} from 'graphql/generated';
import { ON_NEW_NOTIFICATION } from 'graphql/subscriptions/notification';

type Input = {
  refetch: () => void;
  userId?: string;
};

const useNotificationSubscriptions = ({ refetch, userId }: Input) =>
  useSubscription<OnNewNotification, SubscriptionOnNewNotificationArgs>(
    ON_NEW_NOTIFICATION,
    {
      skip: userId === undefined,
      variables: {
        target: userId,
      },

      onSubscriptionData: ({ subscriptionData }) => {
        if (!subscriptionData.error) {
          refetch();
        }
      },
    },
  );

export default useNotificationSubscriptions;
