import { render } from 'react-dom';

import Root from 'containers/Root';

const rootEl = document.getElementById('root');
render(<Root />, rootEl);

if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./containers/Root').default;
    render(<NextApp />, rootEl);
  });
}
