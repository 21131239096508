import { FC, memo } from 'react';

import { CSV, PDF, XLS, Zip } from './styles';
import { Props } from './types';

const FileIcon: FC<Props> = ({ fileType, ...props }) => {
  switch (fileType) {
    case 'text/csv':
      return <CSV {...props} />;
    case 'application/pdf':
      return <PDF {...props} />;
    case 'application/zip':
      return <Zip {...props} />;
    case 'application/vnd.ms-excel':
      return <XLS {...props} />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <XLS {...props} />;

    default:
      return null;
  }
};

export default memo(FileIcon);
