import styled from 'styled-components';

import Button from 'components/Button';

export const ValuesContainer = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
`;

export const EditButton = styled(Button)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  ${({ isHistoric }) =>
    !isHistoric &&
    `
    > svg {
      --size: 0.875rem;
      margin-right: 0;
      margin-left: 0.625rem;
      inline-size: var(--size);
      block-size: var(--size);
    }
  `}
`;

export const Divider = styled.hr`
  background: ${({ theme }) => theme.colors.grey200};
  margin-block-start: 1rem;
  margin-block-end: 1.25rem;
  grid-column: 1 / -1;
  inline-size: 100%;
  border: 0;
  height: 1px;
`;
