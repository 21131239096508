import { useEffect } from 'react';

import { useIdle } from 'hooks/useIdle';

export function useIdleCallback(
  callback: () => void,
  params?: Parameters<typeof useIdle>,
) {
  const isIdle = useIdle.call(null, ...(params || []));

  useEffect(() => {
    if (isIdle) {
      callback();
    }
  }, [isIdle, callback]);
}
