import { parseISO } from 'date-fns';
import { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useModal from 'graphql/hooks/useModal';
import useOpenSeedsAccount from 'graphql/hooks/useOpenSeedsAccount';
// import { useRecentSharedFiles } from 'graphql/hooks/useRecentSharedFiles';
import { removeNulls } from 'utils/removeNulls';
import useSearchParams from 'utils/useSearchParams';

export const useConnect = () => {
  const history = useHistory();
  const query = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { url, path } = useRouteMatch();

  const isCurrent = query.get('portfolio') !== 'seeds';
  const { investorInfo } = useInvestorInfo();
  const { open: confirmHouseholdRemoval } = useModal('REMOVE_HOUSEHOLD');
  const { open: openShareModal } = useModal('SHARE');

  const {
    openSeedsAccount,
    loading: openSeedsAccountLoading,
    error: openSeedsAccountError,
  } = useOpenSeedsAccount();

  // const recentSharedFiles = useRecentSharedFiles({
  //   userId: investorInfo?.user.id,
  // });

  const userData = {
    birthDate: parseISO(investorInfo?.householdPerson?.birthDate || ''),
    ...removeNulls({
      householdName: investorInfo?.household?.name || '',
      email: investorInfo?.householdPerson?.email || '',
      name: investorInfo?.householdPerson?.fullName || '',
      phone: investorInfo?.householdPerson?.phone,
    }),
  };

  const handleConfirmHouseholdRemoval = useCallback(
    () => confirmHouseholdRemoval(),
    [confirmHouseholdRemoval],
  );
  const handleOpenShare = useCallback(() => openShareModal(), [openShareModal]);

  const checkAnswers = () => {
    history.push(`/clients/${id}/survey/answers`);
  };

  // TODO: This is what is fired when an account is ready to open
  // need to pass accountId now instead of investorId
  const callToAction = () => {
    openSeedsAccount({ variables: { investorId: id } });
  };

  return {
    investorId: id,
    isCurrent,
    investorInfo,
    url,
    path,
    history,
    userData,
    handleConfirmHouseholdRemoval,
    handleOpenShare,
    // recentSharedFiles,
    checkAnswers,
    callToAction,
    openSeedsAccountLoading,
    openSeedsAccountError,
  };
};
