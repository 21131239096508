import styled from 'styled-components';

import Card from 'components/Card';
import { GenericTable } from 'components/Table';

import { Asset } from '../types';

export const Container = styled(Card)`
  flex: 1;
  align-self: self-start;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-height: 25rem;
`;

export const Table = styled(GenericTable<Asset>())`
  min-width: unset;
  tbody {
    border-bottom: none;
  }
  td > span {
    overflow: unset;
    white-space: unset;
  }
`;
