import styled, { css } from 'styled-components';

import BaseButton from '../BaseButton';
import { ContainerProps } from './types';

export const Container = styled(BaseButton)<ContainerProps>`
  > svg {
    ${({ iconPlacement }) => {
      switch (iconPlacement) {
        case 'right':
          return css`
            margin-left: 0.4375rem;
          `;
        case 'left':
        default:
          return css`
            margin-right: 0.4375rem;
            margin-top: 0.1rem;
          `;
      }
    }};
  }
`;
