/* eslint-disable jsx-a11y/label-has-associated-control */
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState, useCallback, useEffect, useMemo } from 'react';

import EditAllocationAccountInputs from 'components/Account/EditAllocationAccountInputs';
import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import type { EditAccountParams } from 'context/fastContext/account/types';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { ProposalState } from 'context/fastContext/proposal/types';
import type { ProposalToUpdate } from 'context/fastContext/proposal/types';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';
import formatTextInputCurrency from 'utils/formatTextInputCurrency';
import areEqualProducts from 'utils/proposal/areEqualProducts';
import roundToTarget from 'utils/roundToTarget';

import ActionButtons from './ActionButtons';
import ErrorMessage from './ErrorMessage';
import Group from './Group';
import {
  validateAllocations,
  getDefaultSelectedGroups,
  getMissingDefaultProductsToAdd,
  calculateRatio,
  calculateAllocation,
} from './helpers';
import { Modal, Title } from './styles';
// TODO: Will be pulling these in dynamically as advisors will set them differently
const defaults: { [key: string]: number } = {
  SC: 5,
  MC: 15,
  LC: 55,
  INT: 19,
  'EM-EX-US': 6,
};

// TODO: Figure out best way to handle this
const hasError = false;

export default function EditAllocationsModal() {
  const { close, isOpen } = useModal('EDIT_ALLOCATIONS');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isMobile = !useMediaQuery(from.tablet);

  const [
    {
      assetClassGroups,
      availableProducts,
      masterProposal,
      proposalBeingEdited,
    },
  ] = useProposalStore((s: ProposalState) => ({
    assetClassGroups: s.assetClassGroups,
    availableProducts: s.availableProducts,
    masterProposal: s.masterProposal,
    proposalBeingEdited: s.proposalBeingEdited,
  }));

  const [isValuesDriven] = useInvestorStore(
    (s: any) => s.profile?.isValuesDriven,
  );

  const [
    { isEditingAccount, accountBeingEdited, accountTypes, accountCustodians },
  ] = useAccountStore((s: any) => ({
    isEditingAccount: s.isEditingAccount,
    accountBeingEdited: s.accountBeingEdited,
    accountTypes: s.accountTypes,
    accountCustodians: s.accountCustodians,
  }));

  const { fetchAccounts, updateAccountAndOrProposal } = useAccountActions();

  const proposalDataToUse = useMemo(
    () => (isEditingAccount ? proposalBeingEdited : masterProposal),
    [proposalBeingEdited, isEditingAccount, masterProposal],
  );

  const { products: savedProposalProducts, id: currentProposalId } =
    proposalDataToUse;

  const displayAccountInfo = useMemo(
    () => isEditingAccount,
    [isEditingAccount],
  );

  const [accountName, setAccountName] = useState<string>('');
  const [accountValue, setAccountValue] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('');
  const [accountCustodian, setAccountCustodian] = useState<string>('');

  const handleAccountName = useCallback((e) => {
    setAccountName(e.target.value);
  }, []);

  const handleAccountType = useCallback((e) => {
    setAccountType(e.target.value);
  }, []);

  const handleAccountCustodian = useCallback((e) => {
    setAccountCustodian(e.target.value);
  }, []);

  const handleAccountValue = useCallback(
    (e) => {
      const formattedValue = formatTextInputCurrency(e.target.value);
      if (formattedValue !== null) setAccountValue(formattedValue);
    },
    [setAccountValue],
  );

  useEffect(() => {
    if (isEditingAccount) {
      setAccountName(accountBeingEdited.name!);

      const formattedNumber =
        accountBeingEdited?.amountInvestedDollar?.toLocaleString(); // This will add commas
      const formattedValue = `$ ${
        formattedNumber === '0' ? '' : formattedNumber
      }`;

      setAccountValue(formattedValue);
      setAccountType(accountBeingEdited.accountType!);
      setAccountCustodian(accountBeingEdited.accountCustodian!);
    }
  }, [
    accountBeingEdited.accountCustodian,
    accountBeingEdited.accountType,
    accountBeingEdited.amountInvestedDollar,
    accountBeingEdited.name,
    isEditingAccount,
  ]);

  const { investorInfo } = useInvestorInfo();

  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);
  const [showErrorSavingProposal, setShowErrorSavingProposal] =
    useState<boolean>(false);
  const [selectedGroups, setSelectedGroups] = useState<any>({});
  const [selectedProducts, setSelectedProducts] = useState<any>({});
  const [assetClassSelectionHistory, setAssetClassSelectionHistory] =
    useState<any>({});
  const [groupsAllocationRatio, setGroupsAllocationRatio] = useState<{
    [key: string]: number | null;
  }>({});

  const selectedProductArray: any[] = useMemo(
    () => Object.values(selectedProducts),
    [selectedProducts],
  );

  // TODO: Compare arrays...
  const selectedIsSameAsCurrentProposal = useMemo(
    () => areEqualProducts(savedProposalProducts, selectedProducts),
    [savedProposalProducts, selectedProducts],
  );

  const accountInfoIsSame = useMemo(() => {
    if (!isEditingAccount) return true;
    const {
      name,
      accountCustodian: originalCustodian,
      accountType: originalType,
      amountInvestedDollar,
    } = accountBeingEdited;

    if (
      name === accountName &&
      accountCustodian === originalCustodian &&
      accountType === originalType &&
      Number(accountValue.replace(/,|\$/g, '')) === Number(amountInvestedDollar)
    ) {
      return true;
    }

    return false;
  }, [
    accountBeingEdited,
    accountCustodian,
    accountName,
    accountType,
    accountValue,
    isEditingAccount,
  ]);

  useEffect(() => {
    setShowValidationError(false);
    setShowErrorSavingProposal(false);
    setSelectedProducts({});
    setSelectedGroups({});
  }, [investorInfo?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAllData = useMemo(() => {
    return (
      savedProposalProducts.length !== 0 &&
      assetClassGroups.length !== 0 &&
      Object.keys(availableProducts).length !== 0
    );
  }, [
    assetClassGroups.length,
    availableProducts,
    savedProposalProducts.length,
  ]);

  const assetGroupsKeys = useMemo(
    () => assetClassGroups.map((ac: any) => ac.symbol.toLowerCase()),
    [assetClassGroups],
  );

  const combinedAllProducts = useMemo(
    () =>
      Object.values(availableProducts).reduce(
        (acc: any, assetGroupArray: any) => {
          assetGroupArray.forEach((group: any) => {
            acc.push(...group.products);
          });
          return acc;
        },
        [],
      ),
    [availableProducts],
  );

  useEffect(() => {
    if (hasAllData) {
      const sp = savedProposalProducts.reduce((mem: any, cur: any) => {
        mem[cur.id] = cur;
        return mem;
      }, {});

      const defaultSelectedGroups = getDefaultSelectedGroups(
        assetGroupsKeys,
        availableProducts,
        sp,
      );

      setSelectedGroups(defaultSelectedGroups);

      const missingDefaultProductsToAdd = getMissingDefaultProductsToAdd(
        assetGroupsKeys,
        defaultSelectedGroups,
        assetClassGroups,
        combinedAllProducts,
        Object.values(sp),
        isValuesDriven,
      );

      const selectedProductInitial: any = {
        ...sp,
        ...missingDefaultProductsToAdd,
      };

      const selectedProductInitialArray: any[] = Object.values(
        selectedProductInitial,
      );

      setSelectedProducts(selectedProductInitial);

      // TODO: Set this up to be dynamic somehow (put default values on the group?)
      const equityGroup: any = assetClassGroups.find(
        (acg) => acg.symbol === 'EQ',
      );

      const equityGroupAssetClasses = [
        equityGroup.id,
        ...equityGroup.children.map((acgc: any) => acgc.id),
      ];

      // TODO: Set this up to be dynamic somehow (put default values on the group?)
      const totalEquityAllocationValue = defaultSelectedGroups?.eq
        .isFixedAllocation
        ? selectedProductInitialArray.find(
            (p: any) => p.assetClassId === equityGroup.id,
          ).allocation
        : selectedProductInitialArray.reduce((mem: any, cur: any) => {
            if (equityGroupAssetClasses.includes(cur.assetClassId)) {
              mem += cur.allocation;
            }
            return mem;
          }, 0);

      const defaultEquitySelections = equityGroup?.children.reduce(
        (mem: any, ac: any) => {
          const defaultValue = defaults[ac.symbol];
          if (defaultValue) {
            mem[ac.id] = (defaultValue * totalEquityAllocationValue) / 100;
          }

          return mem;
        },
        {},
      );

      const roundedDefaultSelections = roundToTarget(
        defaultEquitySelections,
        totalEquityAllocationValue,
      );

      setAssetClassSelectionHistory(roundedDefaultSelections);
      setGroupsAllocationRatio({});
    }
  }, [
    investorInfo?.id,
    currentProposalId,
    isOpen,
    hasAllData,
    savedProposalProducts,
    assetGroupsKeys,
    availableProducts,
    assetClassGroups,
    combinedAllProducts,
    isValuesDriven,
  ]);

  const handleSelectGroup = useCallback(
    ({ value, assetType }: any) => {
      const assetClassGroup = assetClassGroups.find(
        (acg: any) => acg.symbol.toLowerCase() === assetType,
      );
      const productGroup: any = availableProducts[assetType];
      const selectedGroup = productGroup?.find(
        (pg: any) => pg.groupName === value,
      );

      const oldGroup = selectedGroups[assetType];

      setSelectedGroups({
        ...selectedGroups,
        [assetType]: { ...selectedGroup },
      });

      const newSelectedProducts = { ...selectedProducts };
      let groupAllocation = 0;

      const oldAssetSelections: any = {};

      oldGroup.products.forEach((product: any) => {
        if (newSelectedProducts[product.id]) {
          // Add up the allocation
          groupAllocation += newSelectedProducts[product.id].allocation;
          oldAssetSelections[product.assetClassId] =
            newSelectedProducts[product.id].allocation;
          delete newSelectedProducts[product.id];
        }
      });

      setAssetClassSelectionHistory({
        ...assetClassSelectionHistory,
        ...oldAssetSelections,
      });

      if (selectedGroup.isFixedAllocation) {
        const productToAdd =
          selectedGroup.products.find((p: any) =>
            isValuesDriven ? p.isValuePlay : !p.isValuePlay,
          ) || selectedGroup.products[0];
        newSelectedProducts[productToAdd.id] = {
          id: productToAdd.id,
          assetClassId: productToAdd.assetClassId,
          allocation: groupAllocation,
        };
      } else {
        // else if it is not then add the value play for each asset class
        assetClassGroup?.children.forEach((assetClass: any) => {
          const productToAdd =
            selectedGroup.products.find(
              (p: any) =>
                (isValuesDriven ? p.isValuePlay : !p.isValuePlay) &&
                p.assetClassId === assetClass.id,
            ) ||
            selectedGroup.products.find(
              (p: any) => p.assetClassId === assetClass.id,
            );

          const assetClassDefault = Math.round(
            (defaults[assetClass.symbol] * groupAllocation) / 100,
          );

          // Instead of making allocation 0 set it to whatever it was before
          newSelectedProducts[productToAdd.id] = {
            id: productToAdd.id,
            assetClassId: productToAdd.assetClassId,
            allocation:
              typeof assetClassSelectionHistory[productToAdd.assetClassId] !==
              'undefined'
                ? assetClassSelectionHistory[productToAdd.assetClassId]
                : assetClassDefault,
          };
        });
      }

      setSelectedProducts({ ...newSelectedProducts });
    },
    [
      assetClassGroups,
      assetClassSelectionHistory,
      availableProducts,
      isValuesDriven,
      selectedGroups,
      selectedProducts,
    ],
  );

  const handleValueSwitch = useCallback(
    (e) => {
      const assetClass = e.target.name;

      // Finds the product currently selected based on asset class we are toggling
      const currentSelectedForSwitch = selectedProductArray.find(
        (sp: any) => sp.assetClassId === assetClass,
      );

      // Get the alternative for that asset class
      const alternateProductForAsset = combinedAllProducts.find(
        (p: any) =>
          p.assetClassId === assetClass && p.id !== currentSelectedForSwitch.id,
      );

      // Remove the old selected product
      const newProductSelection = { ...selectedProducts };
      delete newProductSelection[currentSelectedForSwitch.id];

      // Add the new selected product
      const productToAdd = {
        id: alternateProductForAsset.id,
        assetClassId: alternateProductForAsset.assetClassId,
        allocation: currentSelectedForSwitch.allocation,
      };

      newProductSelection[productToAdd.id] = productToAdd;
      setSelectedProducts(newProductSelection);
    },
    [combinedAllProducts, selectedProductArray, selectedProducts],
  );

  const handleAllocationChange = useCallback(
    (id: string, allocation: number, groupSymbol: string) => {
      const newSelectedProducts = {
        ...selectedProducts,
        [id]: {
          ...selectedProducts[id],
          allocation: Number(allocation),
        },
      };
      setShowValidationError(false);
      setSelectedProducts(newSelectedProducts);

      // If we were editing the at the parent level then reset the ratio as it has now changed
      if (groupsAllocationRatio[groupSymbol]) {
        setGroupsAllocationRatio({
          ...groupsAllocationRatio,
          [groupSymbol]: null,
        });
      }
    },
    [groupsAllocationRatio, selectedProducts],
  );

  const handleBatchAllocationChange = useCallback(
    // TODO: Need to pass original value as well
    ({
      assetClassGroup,
      newValue,
      originalValue,
    }: {
      assetClassGroup: any;
      newValue: number;
      originalValue: number;
    }) => {
      const selectedChildAssetClasses = assetClassGroup.children.map(
        (acgc: any) => acgc.id,
      );

      // Need to keep all batch changes to this ratio
      const selectedGroupProducts = selectedProductArray.filter((sp: any) =>
        selectedChildAssetClasses.includes(sp.assetClassId),
      );

      let ratios;

      // check if ratio exists for back changes based on assetClassGroup.symbol
      // Need to ensure we clear it out when vales are changed using handleAllocationChange.
      if (groupsAllocationRatio[assetClassGroup.symbol]) {
        // If it exists use it,
        ratios = groupsAllocationRatio[assetClassGroup.symbol];
      } else {
        // else create a new one based on selectedGroupProducts
        // get ratio based on original value
        ratios = calculateRatio({
          totalValue: originalValue,
          selectedGroupProducts,
        });

        setGroupsAllocationRatio({
          ...groupsAllocationRatio,
          [assetClassGroup.symbol]: ratios,
        });
      }

      const updatedAllocations = calculateAllocation({
        totalValue: newValue,
        selectedGroupProducts,
        ratios,
      });

      const newProductSelection = Object.keys(selectedProducts).reduce(
        (mem: any, key: string) => {
          const product = { ...selectedProducts[key] };
          const updatedProduct = updatedAllocations[product.id];
          if (updatedProduct) {
            product.allocation = updatedProduct.allocation;
          }

          mem[product.id] = product;

          return mem;
        },
        {},
      );
      setShowValidationError(false);
      setSelectedProducts(newProductSelection);
    },
    [groupsAllocationRatio, selectedProductArray, selectedProducts],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    setShowErrorSavingProposal(false);
    // When saving don't send products with 0 allocation
    const filteredSelections = selectedProductArray.filter(
      (sp: any) => sp.allocation !== 0,
    );

    const proposalIsValid: boolean = validateAllocations(filteredSelections);

    // TODO: Need to prevent save if account doesn't have values
    if (proposalIsValid) {
      const accountToUpdate: EditAccountParams = {
        name: accountName,
        accountTypeId: accountType,
        accountCustodianId: accountCustodian,
        amountInvestedDollar: Number(accountValue.replace(/,|\$/g, '')),
        id: accountBeingEdited.id!,
      };

      const proposalToUpdate: ProposalToUpdate = {
        id: currentProposalId,
        productAllocations: filteredSelections.map((p) => ({
          allocation: p.allocation,
          productId: p.id,
        })),
      };

      const updatedSuccessfully = await updateAccountAndOrProposal({
        accountToUpdate: isEditingAccount ? accountToUpdate : null,
        proposalToUpdate,
      });

      if (updatedSuccessfully) {
        setIsSaving(false);
        handleClose();
        fetchAccounts({ useCache: false, isUpdating: true });
      } else {
        setShowErrorSavingProposal(true);
        setIsSaving(false);
      }
    } else {
      setShowValidationError(true);
      setIsSaving(false);
    }
  }, [
    accountBeingEdited.id,
    accountCustodian,
    accountName,
    accountType,
    accountValue,
    currentProposalId,
    fetchAccounts,
    handleClose,
    isEditingAccount,
    selectedProductArray,
    updateAccountAndOrProposal,
  ]);

  const allAccountFieldsFilled = useMemo(
    () =>
      accountName.trim() !== '' &&
      accountValue.trim() !== '' &&
      accountType.trim() !== '' &&
      accountCustodian.trim() !== '',
    [accountCustodian, accountName, accountType, accountValue],
  );

  const disableSave = useMemo(
    () =>
      isSaving ||
      (isEditingAccount && !allAccountFieldsFilled) ||
      (selectedIsSameAsCurrentProposal && accountInfoIsSame),
    [
      isSaving,
      isEditingAccount,
      allAccountFieldsFilled,
      selectedIsSameAsCurrentProposal,
      accountInfoIsSame,
    ],
  );

  return (
    <Modal
      disableEnforceFocus
      fullScreen={isMobile}
      onClose={handleClose}
      open={isOpen}
    >
      {hasError && <ErrorMessage />}
      {!hasError && (
        <>
          <Box>
            {displayAccountInfo && (
              <Box sx={{ marginBottom: '12px' }}>
                <Typography
                  sx={{
                    fontSize: '1.3125rem',
                    fontWeight: 'bold',
                    lineHeight: 'calc(30 / 21)',
                    height: '1.875rem',
                    marginBottom: '12px',
                  }}
                >
                  Account Information
                </Typography>
                <EditAllocationAccountInputs
                  accountTypes={accountTypes}
                  accountCustodians={accountCustodians}
                  handleAccountName={handleAccountName}
                  handleAccountType={handleAccountType}
                  handleAccountValue={handleAccountValue}
                  handleAccountCustodian={handleAccountCustodian}
                  accountValue={accountValue}
                  accountName={accountName}
                  accountType={accountType}
                  accountCustodian={accountCustodian}
                  disableAccountType
                />
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>Edit Allocations</Title>
            </Box>
          </Box>

          {assetClassGroups.map((acg: any) => {
            if (!hasAllData) return null;

            const assetGroupKey = acg.symbol.toLowerCase();

            const groupAvailableProducts = availableProducts?.[assetGroupKey];

            if (!selectedGroups[assetGroupKey]) return null;

            return (
              <Group
                key={assetGroupKey}
                assetClassGroup={acg}
                availableProducts={groupAvailableProducts}
                combinedAllProducts={combinedAllProducts}
                selectedGroup={selectedGroups[assetGroupKey]}
                selectedProducts={selectedProducts}
                selectedProductArray={selectedProductArray}
                handleSelectGroup={handleSelectGroup}
                handleValueSwitch={handleValueSwitch}
                handleAllocationChange={handleAllocationChange}
                handleBatchAllocationChange={handleBatchAllocationChange}
                isValuesDriven={isValuesDriven}
              />
            );
          })}

          {showValidationError && (
            <div style={{ color: 'red', fontSize: '14px' }}>
              The total of the allocation should sum 100%
            </div>
          )}

          {showErrorSavingProposal && (
            <div style={{ color: 'red', fontSize: '14px' }}>
              There was an error saving your proposal. Please try again, if the
              issue persists please contact support.
            </div>
          )}

          <Box sx={{ display: 'flex' }}>
            <ActionButtons
              disableSave={disableSave}
              handleSave={handleSave}
              isSaving={isSaving}
              selectedIsSameAsCurrentProposal={selectedIsSameAsCurrentProposal}
            />
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
              <Button
                sx={{ fontWeight: 'bold', textTransform: 'initial' }}
                variant="outlined"
                type="button"
                onClick={handleClose}
                data-testid="edit-allocations-cancel"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Modal>
  );
}
