const INCOMPLETES_KEY = 'seeds:incompletes';
const NEW_FEATURE = 'seeds:new_feature_seen';
const RELEASE_NOTES_VIEWD_IN_SESSION = 'seeds:release_notes_viewed_in_session';

export const getIncompletes = () =>
  sessionStorage.getItem(INCOMPLETES_KEY) === 'true';

export const removeIncompletes = () =>
  sessionStorage.removeItem(INCOMPLETES_KEY);

export const setIncompletes = (incompletesValue: boolean) =>
  sessionStorage.setItem(INCOMPLETES_KEY, incompletesValue.toString());

export const setNewFeature = (value: boolean) =>
  localStorage.setItem(NEW_FEATURE, value.toString());

export const getNewFeature = () => localStorage.getItem(NEW_FEATURE) === 'true';

export const releaseNotesDisplayedInSession = () =>
  localStorage.getItem(RELEASE_NOTES_VIEWD_IN_SESSION) === 'true';

export const setReleaseNotesDisplayedInSession = () =>
  localStorage.setItem(RELEASE_NOTES_VIEWD_IN_SESSION, 'true');

export const deleteReleaseNotesDisplayedInSession = () =>
  localStorage.removeItem(RELEASE_NOTES_VIEWD_IN_SESSION);
