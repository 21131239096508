import { FC, memo } from 'react';

import { ReactComponent as BellIcon } from 'assets/svg/bell.svg';

import { Container, Button, Circle } from './styles';
import { Props } from './types';

const NotificationBell: FC<Props> = ({ alert, ...props }) => (
  <Container>
    <Button $alert={alert} {...props}>
      <BellIcon />
    </Button>
    {!!alert && <Circle color="flamingo" data-testid="pending-notifications" />}
  </Container>
);

export default memo(NotificationBell);
