/* eslint-disable no-console */
import { useState, createContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import useMe from 'graphql/hooks/useMe';
import {
  removeToken,
  removeRefreshToken,
  setToken,
  setRefreshToken,
} from 'services/storage';
import AuthFetch from 'utils/authFetch';

export const UserContext = createContext<{
  userInfo: any;
  advisorInfo: any;
  isLoading: boolean;
  isFetchingAdvisorInfo: boolean;
  userInfoFetched: boolean;
  advisorRequestFailed: boolean;
  errorFetching: any;
  fetchUserInfo: Function;
  setAcceptTerms: Function;
  investorCreateHousehold: Function;
  fetchAdvisorInfo: Function;
}>({
  userInfo: {},
  isLoading: false,
  errorFetching: false,
  userInfoFetched: false,
  isFetchingAdvisorInfo: false,
  advisorRequestFailed: false,
  advisorInfo: {},
  fetchUserInfo: () => {},
  setAcceptTerms: () => {},
  investorCreateHousehold: () => {},
  fetchAdvisorInfo: () => {},
});

// For the Actual Logged In User
export const UserProvider = ({ children }: any) => {
  const { push } = useHistory();
  const { refetch } = useMe();
  const [userInfo, setUserInfo] = useState<any>({});
  const [userInfoFetched, setUserInfoFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorFetching, setErrorFetching] = useState<boolean>(false);
  const [isFetchingAdvisorInfo, setIsFetchingAdvisorInfo] =
    useState<boolean>(false);
  const [advisorInfo, setAdvisorInfo] = useState<any>({});
  const [advisorRequestFailed, setAdvisorRequestFailed] =
    useState<boolean>(false);

  const User = useMemo(
    () => ({
      fetchUserInfo: async () => {
        try {
          setErrorFetching(false);
          setIsLoading(true);
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/user/base/info`,
            method: 'GET',
            useCache: false,
          });
          setUserInfoFetched(true);
          setIsLoading(false);
          setUserInfo(data.payload);
        } catch (e: any) {
          setErrorFetching(true);
          setIsLoading(false);
          console.log(`Error fetching user info occured: ${e.message}`, e);
        }
      },
      setAcceptTerms: async () => {
        try {
          const response = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/user/base/accept_terms/`,
            method: 'POST',
          });

          if (response.status) {
            setUserInfo({
              ...userInfo,
              acceptedTerms: true,
            });
          }
        } catch (e: any) {
          console.log(`Error accepting terms occured: ${e.message}`);
        }
      },
      investorCreateHousehold: async (household: any) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}api/user/household/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(household),
            },
          );

          const data = await response.json();

          if (data.status) {
            const { token, refreshToken } = data.payload;
            removeToken();
            removeRefreshToken();
            if (token) setToken(token);
            if (refreshToken) setRefreshToken(refreshToken);
            // push to survey
            await refetch();
            setTimeout(() => {
              push('/survey');
            }, 0);
          }

          return data.payload;
        } catch (e: any) {
          console.log(
            `Error creating household and adding tokens: ${e.message}`,
          );
        }

        return true;
      },
      fetchAdvisorInfo: async (advisorId: string) => {
        try {
          setIsFetchingAdvisorInfo(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}api/user/advisor/get/${advisorId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          const data = await response.json();

          if (data.status) {
            setAdvisorInfo(data.payload);
          } else {
            setAdvisorRequestFailed(true);
          }
        } catch (e: any) {
          console.log(`Error fetching advisor info: ${e.message}`);
          setAdvisorRequestFailed(true);
        }
        setIsFetchingAdvisorInfo(false);
      },
      userInfo,
      advisorInfo,
      userInfoFetched,
      isFetchingAdvisorInfo,
      isLoading,
      errorFetching,
      advisorRequestFailed,
    }),
    [
      userInfo,
      advisorInfo,
      userInfoFetched,
      isFetchingAdvisorInfo,
      isLoading,
      errorFetching,
      advisorRequestFailed,
      refetch,
      push,
    ],
  );

  return <UserContext.Provider value={User}>{children}</UserContext.Provider>;
};
