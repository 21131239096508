import styled, { css } from 'styled-components';

export const Container = styled.span<{ $level?: number }>`
  font-weight: ${({ theme }) => theme.weights.bold};
  ${({ theme, $level }) => css`
    color: ${() => {
      switch ($level) {
        case 1:
          return theme.colors.pottersClay;
        case 2:
          return theme.colors.tacao;
        case 3:
          return theme.colors.atlantis;
        case 4:
          return theme.colors.primary;
        case 5:
          return theme.colors.rainForest;
        default:
          return theme.colors.grey400;
      }
    }};
  `}
`;
