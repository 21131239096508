import { useMemo } from 'react';

import RatingInput, { RatingOptions } from 'components/RatingInput';
import Values from 'components/Values';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';

import { Description, ItemDescription } from '../../../styles';
import { ArrowRight, TitleWrapper, ViewAllFilesLink } from '../../styles';
import { ResultItemContainer, ResultItemTitle } from './styles';
import { Props } from './types';

export function SurveyResultsContainer({ can, investorInfo }: Props) {
  const [{ isValuesDriven, riskLevel, status }] = useInvestorStore(
    (s: any) => ({
      isValuesDriven: s.profile?.isValuesDriven,
      riskLevel: s.profile?.riskLevel,
      status: s.profile?.status,
    }),
  );

  const profileIsComplete = useMemo(() => status === 'COMPLETE', [status]);

  return (
    <>
      <TitleWrapper>
        {can('create-survey') && (
          <ViewAllFilesLink to={`/clients/${investorInfo?.id}/survey`}>
            create assessment <ArrowRight />
          </ViewAllFilesLink>
        )}
      </TitleWrapper>
      <ResultItemContainer style={{ padding: 0, marginBottom: '2rem' }}>
        <ResultItemTitle>Risk Target</ResultItemTitle>
        {riskLevel ? (
          <ResultItemContainer
            style={{ border: '1px solid #E9ECEF', borderRadius: '0.625rem' }}
          >
            <RatingInput
              name="RiskTolerance"
              initialValue={riskLevel || 0}
              // @ts-ignore
              value={riskLevel || 0}
              options={RatingOptions}
              readOnly
              colorized
            />

            <ItemDescription>
              These risk tolerance ranges illustrate portfolio risk exposure and
              theoretical loss potential, but do not guarantee a limit to the
              loss a portfolio may incur. For more information, or if you feel
              that the selection is inaccurate, please discuss with your
              advisor.
            </ItemDescription>
          </ResultItemContainer>
        ) : (
          <Description>No Risk Tolerance selected yet</Description>
        )}
      </ResultItemContainer>
      <ResultItemContainer style={{ padding: 0 }}>
        <ResultItemTitle>VALUES</ResultItemTitle>
        {/* eslint-disable-next-line no-nested-ternary */}
        {profileIsComplete ? (
          isValuesDriven ? (
            <Values />
          ) : (
            <Description data-testid="no-values-preference">
              No preference
            </Description>
          )
        ) : (
          <Description>No values selected yet</Description>
        )}
      </ResultItemContainer>
    </>
  );
}
