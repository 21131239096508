import toCamelCase from 'lodash/camelCase';

import { OrderByType } from 'graphql/generated';

type Summary =
  | Partial<{
      orderBy: (OrderByType | null)[] | undefined | null;
    }>
  | undefined
  | null;

const useNormalizeSorting = (order: Summary) => {
  const firstOrder = order?.orderBy?.[0];

  if (firstOrder?.field && firstOrder.ordering) {
    const { field, ordering } = firstOrder;

    return {
      column: toCamelCase(field),
      direction: ordering.toLowerCase() as 'asc' | 'desc',
    };
  }

  return undefined;
};

export default useNormalizeSorting;
