import { ReactComponent as InfoIcon } from 'assets/svg/info-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/svg/notifications/check.svg';
import { ReactComponent as FileIcon } from 'assets/svg/notifications/file.svg';
import { ReactComponent as RingIcon } from 'assets/svg/notifications/ring.svg';

import Date from '../Date';
import { Bold, Icon, Wrapper, Text } from '../styles';
import { Props } from './types';

const Notification = ({
  type,
  isSharedBySeeds,
  contentName,
  created,
  customText,
}: Props) => {
  if (type === 'FS') {
    return (
      <>
        <Icon icon={<FileIcon />} />
        <Wrapper>
          <Text>
            {isSharedBySeeds ? 'Seeds' : 'Your advisor'} has shared{' '}
            <Bold>{contentName}</Bold>
          </Text>
          <Date date={created} />
        </Wrapper>
      </>
    );
  }

  if (type === 'MR') {
    return (
      <>
        <Icon icon={<RingIcon />} />
        <Wrapper>
          <Text>
            <Bold>{contentName}</Bold> is now available!
          </Text>
          <Date date={created} />
        </Wrapper>
      </>
    );
  }

  if (type === 'SC') {
    return (
      <>
        <Icon icon={<CheckIcon />} />
        <Wrapper>
          <Text>
            <Bold>{contentName}</Bold> has completed the assessment!
          </Text>
          <Date date={created} />
        </Wrapper>
      </>
    );
  }

  if (type === 'CT') {
    return (
      <>
        <Icon icon={<InfoIcon />} />
        <Wrapper>
          <Text>
            {contentName ? (
              <>
                <Bold>{contentName}</Bold> {customText}
              </>
            ) : (
              customText
            )}
          </Text>
          <Date date={created} />
        </Wrapper>
      </>
    );
  }

  return null;
};

export default Notification;
