import styled, { css } from 'styled-components';

import { VisuallyHidden } from 'styles/mixins';

import { LabelProps } from './types';

export const Container = styled.div``;

export const Option = styled.label`
  cursor: pointer;

  &[for=''] {
    cursor: not-allowed;
  }
`;

export const PseudoRadio = styled.div`
  border-radius: 50%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey400};
  height: 1rem;
  width: 1rem;
  display: inline-block;
  flex-shrink: 0;
`;

export const HiddenRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  ${VisuallyHidden}

  &:checked +   ${PseudoRadio} , &:focus +   ${PseudoRadio} {
    border: 0.3125rem solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
  &:read-only,
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Label = styled.span<LabelProps>`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-left: 1rem;

  strong {
    font-weight: bold !important;
  }

  ${({ selected, theme }) =>
    selected
      ? css`
          color: ${theme.colors.primary};
        `
      : null}
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 0.4375rem;
  padding-top: 0.4375rem;
`;
