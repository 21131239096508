import styled from 'styled-components';

import theme from 'styles/v2/theme';

export const Container = styled.div`
  background-color: ${theme.palette.primary[900]};
  min-height: 7.5rem;
  position: relative;
`;

export const ChildContent = styled.div`
  min-height: 7.5rem;
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
`;
