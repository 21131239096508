import jwtDecode from 'jwt-decode';

export default function validateTokenExpiration(token: string) {
  try {
    const { exp }: any = jwtDecode(token);
    return Date.now() < exp * 1000;
  } catch {
    return false;
  }
}
