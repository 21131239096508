import { Formik, Form, Field, FieldArray } from 'formik';

import { ReactComponent as RemoveIcon } from 'assets/svg/minus.svg';
import { ReactComponent as AddIcon } from 'assets/svg/plusCircle.svg';
import DateInput from 'components/DateInput';
import Input, { InputError, InputGroup } from 'components/Input';
import PhoneInput from 'components/PhoneInput';

import useConnect from './connect';
import { defaultOther, initialValues, validationSchema } from './constants';
import {
  AdvisorPicker,
  Back,
  Button,
  Buttons,
  Container,
  Header,
  Inputs,
  SecondaryTitle,
  Title,
} from './styles';
import { MemberInstanceError } from './types';

const AddHousehold = () => {
  const { defaultAdvisor, handleSubmit, me } = useConnect();

  return (
    <Container>
      <Header>
        <Back />
        <Title>New household information</Title>
      </Header>
      <Formik
        initialValues={initialValues(defaultAdvisor)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, values, isValid }) => (
          <Form id="add-household-form">
            <Inputs>
              <InputGroup>
                <Field
                  as={Input}
                  name="householdName"
                  error={!!errors.householdName && !!touched.householdName}
                  placeholder="Household name"
                  data-testid="new-household-input-household-name"
                />
                <InputError name="householdName" />
              </InputGroup>
              <InputGroup>
                <Field
                  as={Input}
                  name="primary.email"
                  error={!!errors.primary?.email && !!touched.primary?.email}
                  placeholder="Email"
                  autoComplete="email"
                  data-testid="new-household-input-primary-email"
                />
                <InputError name="primary.email" />
              </InputGroup>
              <InputGroup>
                <Field
                  as={Input}
                  name="primary.firstName"
                  error={
                    !!errors.primary?.firstName && !!touched.primary?.firstName
                  }
                  placeholder="First name"
                  autoComplete="given-name"
                  data-testid="new-household-input-primary-first-name"
                />
                <InputError name="primary.firstName" />
              </InputGroup>
              <InputGroup>
                <Field
                  as={Input}
                  name="primary.lastName"
                  error={
                    !!errors.primary?.lastName && !!touched.primary?.lastName
                  }
                  placeholder="Last name"
                  autoComplete="family-name"
                  data-testid="new-household-input-primary-last-name"
                />
                <InputError name="primary.lastName" />
              </InputGroup>
              <InputGroup>
                <Field
                  as={DateInput}
                  name="primary.birthDate"
                  error={
                    !!errors.primary?.birthDate && !!touched.primary?.birthDate
                  }
                  placeholder="Birth date (optional)"
                  autoComplete="bday"
                  data-testid="new-household-input-primary-birth-date"
                />
                <InputError name="primary.birthDate" />
              </InputGroup>
              <InputGroup>
                <Field
                  as={PhoneInput}
                  name="primary.phone"
                  error={!!errors.primary?.phone && !!touched.primary?.phone}
                  placeholder="Phone number (optional)"
                  autoComplete="tel"
                  // TODO: Fix the selector bellow. Why it is not working?
                  data-testid="new-household-input-primary-phone"
                />
                <InputError name="primary.phone" />
              </InputGroup>
              <SecondaryTitle
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                Primary Advisor:
              </SecondaryTitle>
              <InputGroup>
                <Field
                  as={AdvisorPicker}
                  disabled={isSubmitting}
                  error={!!errors.advisor && !!touched.advisor}
                  name="advisor"
                />
                <InputError name="advisor" />
              </InputGroup>
            </Inputs>
            <SecondaryTitle>Household Members</SecondaryTitle>
            <FieldArray name="others">
              {({ push, remove }) => (
                <>
                  {values.others!.map((other, index) => {
                    const key = `other-${index}`;
                    return (
                      <Inputs key={key}>
                        <InputGroup>
                          <Field
                            as={Input}
                            name={`others.${index}.firstName`}
                            error={
                              errors.others &&
                              errors.others[index] &&
                              (errors?.others[index] as MemberInstanceError)
                                ?.firstName &&
                              touched?.others &&
                              touched?.others[index] &&
                              touched?.others[index].firstName
                            }
                            placeholder="First name"
                            autoComplete="given-name"
                          />
                          <InputError name={`others.${index}.firstName`} />
                        </InputGroup>
                        <InputGroup>
                          <Field
                            as={Input}
                            name={`others.${index}.lastName`}
                            error={
                              errors.others &&
                              errors.others[index] &&
                              (errors?.others[index] as MemberInstanceError)
                                ?.lastName &&
                              touched?.others &&
                              touched?.others[index] &&
                              touched?.others[index].lastName
                            }
                            placeholder="Last name"
                            autoComplete="family-name"
                          />
                          <InputError name={`others.${index}.lastName`} />
                        </InputGroup>
                        <InputGroup>
                          <Field
                            as={DateInput}
                            name={`others.${index}.birthDate`}
                            error={
                              errors.others &&
                              errors.others[index] &&
                              (errors?.others[index] as MemberInstanceError)
                                ?.birthDate &&
                              touched?.others &&
                              touched?.others[index] &&
                              touched?.others[index].birthDate
                            }
                            placeholder="Birth date (optional)"
                            autoComplete="bday"
                          />
                          <InputError name={`others.${index}.birthDate`} />
                        </InputGroup>
                        <InputGroup>
                          <Field
                            as={Input}
                            name={`others.${index}.email`}
                            error={
                              errors.others &&
                              errors.others[index] &&
                              (errors?.others[index] as MemberInstanceError)
                                ?.email &&
                              touched?.others &&
                              touched?.others[index] &&
                              touched?.others[index].email
                            }
                            placeholder="Email"
                            autoComplete="email"
                          />
                          <InputError name={`others.${index}.email`} />
                        </InputGroup>
                        <InputGroup>
                          <Field
                            as={PhoneInput}
                            name={`others.${index}.phone`}
                            error={
                              errors.others &&
                              errors?.others[index] &&
                              (errors?.others[index] as MemberInstanceError)
                                ?.phone &&
                              touched?.others &&
                              touched?.others[index] &&
                              touched?.others[index].phone
                            }
                            placeholder="Phone number (optional)"
                            autoComplete="tel"
                          />
                          <InputError name={`others.${index}.phone`} />
                        </InputGroup>
                        <Button
                          type="button"
                          icon={<RemoveIcon />}
                          onClick={() => remove(index)}
                        >
                          Remove household person
                        </Button>
                      </Inputs>
                    );
                  })}
                  <Buttons>
                    <Button
                      type="button"
                      variant="secondary"
                      icon={<AddIcon />}
                      onClick={() => {
                        push(defaultOther);
                      }}
                    >
                      Add household person
                    </Button>
                    <Button
                      form="add-household-form"
                      type="submit"
                      variant="primary"
                      icon={<AddIcon />}
                      disabled={
                        isSubmitting || (me && me.role === 'ADMIN' && !isValid)
                      }
                      data-testid="new-household-button-save"
                    >
                      Save
                    </Button>
                  </Buttons>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddHousehold;
