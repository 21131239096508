import { FC } from 'react';

import { Container, List, Title } from './styles';
import { Props } from './types';

const UserList: FC<Props> = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <List>{children}</List>
    </Container>
  );
};

export default UserList;
