import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { MeQuery } from 'graphql/generated';
import { ME } from 'graphql/queries/me';
import { normalizeUser } from 'models/User';

const useMe = (options?: QueryHookOptions<MeQuery>) => {
  const { data, loading, refetch } = useQuery<MeQuery>(ME, {
    ...options,
  });

  const me = useMemo(
    () => (data?.me ? normalizeUser(data.me) : undefined),
    [data],
  );

  return {
    loading,
    me,
    refetch,
  };
};

export default useMe;
