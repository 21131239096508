import { AdvisorQuery, MeQuery, UserType } from 'graphql/generated';

import { normalizeUserAdvisor } from './Advisor';
import { normalizeUserInvestor } from './Investor';

type UserInput = NonNullable<
  NonNullable<AdvisorQuery['advisor']>['user'] | MeQuery['me'] | UserType
>;

type UserRoleInput = string | null | undefined;

export const normalizeUserRole = (input: UserRoleInput) => {
  switch (input) {
    case 'admin':
      return 'ADMIN';
    case 'advisor':
      return 'ADVISOR';
    case 'investor':
      return 'INVESTOR';
    default:
      return 'UNKNOWN';
  }
};

export const normalizeAdvisorUser = (input: UserInput) => {
  const role = normalizeUserRole(input.role);

  return {
    birthDate: input.birthDate,
    email: input.email,
    firstName: input.firstName,
    fullName: `${input.firstName} ${input.lastName}`,
    id: input.id,
    lastName: input.lastName,
    phone: input.phone || '',
    role,
  };
};

export const normalizeInvestorUser = (input: UserInput) => {
  const role = normalizeUserRole(input.role);

  return {
    birthDate: input.birthDate,
    email: input.email,
    firstName: input.firstName,
    fullName: `${input.firstName} ${input.lastName}`,
    id: input.id,
    lastName: input.lastName,
    phone: input.phone || '',
    role,
  };
};

export const normalizeUser = (input: UserInput) => {
  const common = {
    birthDate: input.birthDate,
    email: input.email,
    firstName: input.firstName,
    fullName: `${input.firstName} ${input.lastName}`,
    id: input.id,
    lastName: input.lastName,
    phone: input.phone || '',
    // @ts-ignore
    showReleaseNotes: input?.showReleaseNotes,
    // @ts-ignore
    releaseNotesContent: input?.releaseNotesContent,
  };
  const role = normalizeUserRole(input.role);

  switch (role) {
    case 'ADVISOR':
      return {
        ...common,
        advisor:
          'advisor' in input && input.advisor
            ? normalizeUserAdvisor(input.advisor)
            : undefined,
        role: role as 'ADVISOR',
      };
    case 'INVESTOR':
      return {
        ...common,
        investor:
          'investor' in input && input.investor
            ? // @ts-ignore
              normalizeUserInvestor(input.investor)
            : undefined,
        role: role as 'INVESTOR',
      };
    case 'ADMIN':
    case 'UNKNOWN':
    default:
      return {
        ...common,
        role: role as 'ADMIN' | 'UNKNOWN',
      };
  }
};

export type AdvisorUser = ReturnType<typeof normalizeAdvisorUser>;

export type InvestorUser = ReturnType<typeof normalizeInvestorUser>;

export type User = ReturnType<typeof normalizeUser>;

export type Role = ReturnType<typeof normalizeUserRole>;
