import styled from 'styled-components';

import { ReactComponent as DefaultCancelIcon } from 'assets/svg/cancel.svg';
import Card from 'components/Card';

export const Container = styled(Card)`
  flex: 1;
  align-self: flex-start;
`;

export const CancelIcon = styled(DefaultCancelIcon)`
  width: 0.875rem;
  height: 0.875rem;
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 1rem;
`;

export const Label = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.grey600};
  text-align: center;
`;

export const Form = styled.div`
  display: flex;

  > div:first-child {
    flex: 1;
  }
`;

export const Results = styled.div`
  margin-top: 0.5rem;
`;

export const PaginationResults = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
`;

export const TableFooter = styled.div`
  padding: 1rem 0 0.25rem 0.625rem;
`;
