const initialsOf = (fullName: string) => {
  const fullNameIsEmpty = fullName.trim() === '';

  if (fullNameIsEmpty) return '';

  const [firstName = '', lastName = ''] = fullName.split(' ');

  return [firstName, lastName]
    .map((name) => name.charAt(0))
    .join('')
    .toUpperCase();
};

export default initialsOf;
