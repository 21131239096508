import { useCallback } from 'react';

import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';
import { useIdleCallback } from 'hooks/useIdleCallback';
import { getToken } from 'services/storage';
import validateTokenExpiration from 'utils/validateTokenExpiration';

// TODO: Verifiy this
const useConnect = () => {
  const { logout } = useAuthActions();
  const { loading, me } = useMe();
  // Kicks the user out after 30 minutes of inactivity if JWT expired
  const kickOut = useCallback(() => {
    const token = getToken();

    if (!token) {
      logout('inactivity');
    } else {
      const isTokenValid = validateTokenExpiration(token);
      if (!isTokenValid) {
        logout('inactivity');
      }
    }
  }, [logout]);
  useIdleCallback(kickOut);

  return {
    loading,
    logout,
    me,
  };
};

export default useConnect;
