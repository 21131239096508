import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as DefaultArrowRight } from 'assets/svg/arrowRight.svg';
import DefaultPersonalityProfile from 'components/PersonalityProfile';
import DefaultStatusSelect from 'components/StatusSelect';
import DefaultSurveyStatusEmail from 'components/SurveyStatusEmail';

export const Container = styled.div`
  margin-block-start: 2.5rem;
  position: relative;

  &:before {
    content: '';
    height: 26.25rem;
    position: absolute;
    top: -2.5rem;
    width: 100%;
    z-index: -1;
  }

  > * {
    grid-column: 2;
  }
`;

export const ArrowRight = styled(DefaultArrowRight)`
  transition: transform 200ms ease;
`;

export const ModuleTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
`;

export const StatusSelect = styled(DefaultStatusSelect)``;

export const SurveyStatusEmail = styled(DefaultSurveyStatusEmail)`
  /* margin-top: 0.75rem; */
`;

export const TitleWrapper = styled.div`
  display: flex;

  // survey status props are passed to select therefore
  // this is the only way i came up with to add the spacing
  div {
    margin-left: 0.75rem;
  }
`;

export const ViewAllFilesLink = styled(Link)`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  margin-left: 1rem;

  &:hover {
    svg {
      transform: translateX(0.25rem);
    }
  }
`;

export const PersonalityProfile = styled(DefaultPersonalityProfile)`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  box-shadow: none;
`;
