import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import useSort from 'components/Table/useSort';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';

import { FormValues } from '../types';
import { Props } from './types';

const useConnect = ({ schema }: { schema: Props['schema'] }) => {
  const { setValues, values } = useFormikContext<FormValues>();
  const { valueType } = values;
  const [{ importedPortfolio }] = usePortfolioStore((s) => ({
    importedPortfolio: s.importedPortfolio,
  }));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (importedPortfolio.securities.length) {
      timeout = setTimeout(() => {
        setValues({
          assets: importedPortfolio.securities.map((security) => ({
            id: security.id,
            symbol: security.symbol,
            name: security.name,
            percentage: security.percentage,
            marketValue: security.marketValue,
            position: security.position,
            valueType: security.valueType,
          })),
          valueType: importedPortfolio.securities[0].valueType,
        });
      }, 100);
    }
    return () => clearTimeout(timeout);
  }, [importedPortfolio, setValues]);

  const filteredValues = useMemo(() => {
    const filteredAssets = (values?.assets ?? [])?.filter((value) => {
      return ['id', 'name', 'percentage', 'symbol'].every(
        (field) => field in value && value[field as keyof typeof value] != null,
      );
    });
    return { ...values, assets: filteredAssets };
  }, [values]);

  const { data, ...sorting } = useSort<
    NonNullable<typeof values['assets']>[number]
  >({
    data: filteredValues.assets ?? [],
    schema,
  });

  const handleRemove = useCallback(
    (id: string) => {
      if (values.assets) {
        const indexOfAssetToBeRemoved = values.assets?.findIndex(
          (asset) => asset.id === id,
        );
        const groupOfAssetsAfterAssetRemoved = values.assets
          .slice(indexOfAssetToBeRemoved + 1)
          .map((asset) => ({ ...asset, position: asset.position - 1 }));
        setValues({
          assets: [
            ...values.assets.slice(0, indexOfAssetToBeRemoved),
            ...groupOfAssetsAfterAssetRemoved,
          ],
          valueType: values.valueType,
        });
      }
    },
    [setValues, values.assets, values.valueType],
  );

  useEffect(() => {
    const assets = [...(values?.assets || [])];
    setValues({
      valueType,
      assets: assets.map((a) => ({ ...a, valueType })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueType]);

  return { handleRemove, values: data, sorting };
};

export default useConnect;
