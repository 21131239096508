import styled from 'styled-components';

import { CustomTooltip } from 'styles/mixins';

export const Container = styled.div`
  ${CustomTooltip};
  padding: 0.5rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
`;
