import styled from 'styled-components';

import { ZIndex } from 'styles/metrics';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 5rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 5rem;

  :after {
    background-color: ${({ theme }) => addAlpha(theme.colors.rainForest, 0.75)};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${ZIndex.PROFILE_ALT_OVERLAY};
  }
`;

export const Image = styled.img`
  border-radius: 5rem;
  height: 5rem;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 5rem;
`;

export const Initials = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  text-align: center;
  z-index: ${ZIndex.PROFILE_ALT_INITIALS};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.75rem;
  width: 100%;
  min-width: 0;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

export const Household = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
`;
