import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import ContainerMaxWidth from 'components/ContainerMaxWidth';
import BaseLogo from 'components/Logo';
import { from } from 'styles/media';

export const Container = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 4rem;

  @media print {
    display: none;
  }
`;

export const CompanyLogo = styled.img`
  max-height: 40px;
`;

export const Logo = styled(BaseLogo)`
  position: absolute;
  left: 1.875rem;

  ${from.containerMaxWidth} {
    left: 0;
  }
`;

export const Wrapper = styled(ContainerMaxWidth)`
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Tab = styled(NavLink).attrs(() => ({
  activeClassName: 'selected',
}))`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: calc(20 / 14);
  padding-bottom: 1.25rem;
  padding-top: 1.5rem;
  text-align: center;
  text-decoration: none;
  width: 4.625rem;

  &.selected {
    color: ${({ theme }) => theme.colors.dark};
    border-bottom: 0.125rem solid ${({ theme }) => theme.colors.primary};
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const Menu = styled.div`
  display: flex;
  position: absolute;
  right: 1.875rem;

  ${from.containerMaxWidth} {
    right: 0;
  }

  > * {
    margin-left: 1.25rem;
  }
`;
