import {
  Container,
  Title,
  Tooltip,
  TooltipStyle,
  InfoIconWrapper,
  InfoIcon,
} from './styles';
import { Props } from './types';

export function ValueCard({ title, value, explanation, className }: Props) {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      Better than {value}%
      <TooltipStyle />
      <Tooltip
        title={explanation}
        classes={{ tooltip: 'SeedsCoreEquityValueCard' }}
        placement="bottom"
      >
        <InfoIconWrapper tabIndex={0}>
          <InfoIcon />
        </InfoIconWrapper>
      </Tooltip>
    </Container>
  );
}
