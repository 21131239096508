import * as yup from 'yup';

export const initialValues = {
  confirmPassword: '',
  password: '',
};

export const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters')
    .matches(/[a-z]+/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]+/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]+/, 'Must contain at least one number')
    .matches(
      /[@$!%*#?&^_~]+/,
      'Must contain at least one special symbol (@,#,$,%,&,!)',
    ),

  confirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
