import { useSnackbar as useDefaultSnackbar, OptionsObject } from 'notistack';

import { Snackbar } from 'components/Snackbar';

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (
    message: string,
    options?: OptionsObject &
      Partial<{ variant: 'success' | 'error' | 'warning' }>,
  ) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key) => {
        const { variant, persist } = options || { variant: undefined };
        return (
          <Snackbar
            id={`${key}`}
            message={message}
            variant={variant || 'success'}
            isPersisted={!!persist}
          />
        );
      },
    });
  };

  return pushSnackbar;
};
