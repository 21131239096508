import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  // LoginMutation,
  Login2FAMutation,
  LoginMutationVariables,
  LogoutMutation,
  LogoutMutationVariables,
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables,
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables,
  SetAdvisorOnboardingPasswordInput,
  SetAdvisorOnboardingPasswordMutation,
  SetAdvisorOnboardingPasswordMutationVariables,
  SetInvestorOnboardingPasswordInput,
  SetInvestorOnboardingPasswordMutation,
  SetInvestorOnboardingPasswordMutationVariables,
  SetResetPasswordInput,
  SetResetPasswordMutation,
  SetResetPasswordMutationVariables,
  ValidateAdvisorOnboardingMutation,
  ValidateAdvisorOnboardingMutationVariables,
  ValidateInvestorOnboardingTokenMutation,
  ValidateInvestorOnboardingTokenMutationVariables,
  ValidateParameterTokenMutation,
  ValidateParameterTokenMutationVariables,
  ValidateVerificationCodeMutation,
  ValidateVerificationCodeMutationVariables,
} from 'graphql/generated';
import {
  LOGIN,
  LOGOUT,
  REQUEST_RESET_PASSWORD,
  SEND_VERIFICATION_CODE,
  SET_ADVISOR_ONBOARDING_PASSWORD,
  SET_INVESTOR_ONBOARDING_PASSWORD,
  SET_RESET_PASSWORD,
  VALIDATE_ADVISOR_ONBOARDING,
  VALIDATE_INVESTOR_ONBOARDING_TOKEN,
  VALIDATE_PARAMETER_TOKEN,
  VALIDATE_VERIFICATION_CODE,
} from 'graphql/mutations/auth';
import { logger } from 'services/logger';
import { removeIncompletes } from 'services/session';
import {
  removeToken,
  removeRefreshToken,
  setToken,
  setRefreshToken,
} from 'services/storage';

const useAuthActions = () => {
  const [loginMutation, { loading: loginLoading }] = useMutation<
    // LoginMutation,
    Login2FAMutation,
    LoginMutationVariables
  >(LOGIN);
  const [logoutMutaion, { loading: logoutLoading }] = useMutation<
    LogoutMutation,
    LogoutMutationVariables
  >(LOGOUT);
  const [
    requestResetPasswordMutation,
    { loading: requestResetPasswordLoading },
  ] = useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(REQUEST_RESET_PASSWORD);
  const [
    sendVerificationCodeMutation,
    { error: sendVerificationCodeError, loading: sendVerificationCodeLoading },
  ] = useMutation<
    SendVerificationCodeMutation,
    SendVerificationCodeMutationVariables
  >(SEND_VERIFICATION_CODE);
  const [
    validateAdvisorOnboardingMutation,
    {
      error: validateAdvisorOnboardingError,
      loading: validateAdvisorOnboardingLoading,
    },
  ] = useMutation<
    ValidateAdvisorOnboardingMutation,
    ValidateAdvisorOnboardingMutationVariables
  >(VALIDATE_ADVISOR_ONBOARDING);
  const [
    setAdvisorOnboardingPasswordMutation,
    { loading: setAdvisorOnboardingPasswordLoading },
  ] = useMutation<
    SetAdvisorOnboardingPasswordMutation,
    SetAdvisorOnboardingPasswordMutationVariables
  >(SET_ADVISOR_ONBOARDING_PASSWORD);
  const [
    setInvestorOnboardingPasswordMutation,
    { loading: setInvestorOnboardingPasswordLoading },
  ] = useMutation<
    SetInvestorOnboardingPasswordMutation,
    SetInvestorOnboardingPasswordMutationVariables
  >(SET_INVESTOR_ONBOARDING_PASSWORD);
  const [setResetPasswordMutation, { loading: setResetPasswordLoading }] =
    useMutation<SetResetPasswordMutation, SetResetPasswordMutationVariables>(
      SET_RESET_PASSWORD,
    );
  const [
    validateInvestorOnboardingTokenMutation,
    {
      error: validateInvestorOnboardingTokenError,
      loading: validateInvestorOnboardingTokenLoading,
    },
  ] = useMutation<
    ValidateInvestorOnboardingTokenMutation,
    ValidateInvestorOnboardingTokenMutationVariables
  >(VALIDATE_INVESTOR_ONBOARDING_TOKEN);
  const [
    validateParameterTokenMutation,
    {
      error: validateParameterTokenError,
      loading: validateParameterTokenLoading,
    },
  ] = useMutation<
    ValidateParameterTokenMutation,
    ValidateParameterTokenMutationVariables
  >(VALIDATE_PARAMETER_TOKEN);
  const [
    validateVerificationCodeMutation,
    {
      error: validateVerificationCodeError,
      loading: validateVerificationCodeLoading,
    },
  ] = useMutation<
    ValidateVerificationCodeMutation,
    ValidateVerificationCodeMutationVariables
  >(VALIDATE_VERIFICATION_CODE);

  const login = useCallback(
    async (variables: LoginMutationVariables) => {
      try {
        const response = await loginMutation({
          fetchPolicy: 'no-cache',
          variables,
        });
        return response;
      } catch (error) {
        logger.error(error);
        const message = (error as ApolloError).networkError
          ? 'Failed to connect to server'
          : 'These credentials do not match our records';
        throw new Error(message);
      }
    },
    [loginMutation],
  );

  const logout = useCallback(
    async (reason?: 'inactivity' | 'investor', customUrl?: string) => {
      // clean session on BE
      await logoutMutaion({
        fetchPolicy: 'no-cache',
      });
      // clear local session
      removeIncompletes();
      removeToken();
      removeRefreshToken();

      if (customUrl) {
        window.location.href = customUrl;
      } else if (reason) {
        window.location.href = `/login?loggedOutBy=${reason}&continue=${
          window.location.pathname + window.location.search
        }`;
      } else {
        window.location.href = '/';
      }
    },
    [logoutMutaion],
  );

  const requestResetPassword = useCallback(
    async (email: string) => {
      try {
        await requestResetPasswordMutation({
          fetchPolicy: 'no-cache',
          optimisticResponse: {
            requestResetPassword: true,
          },
          variables: {
            email,
          },
        });
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to request reset password');
      }
    },
    [requestResetPasswordMutation],
  );

  const sendVerificationCode = useCallback(
    async (variables: SendVerificationCodeMutationVariables) => {
      try {
        const response = await sendVerificationCodeMutation({
          fetchPolicy: 'no-cache',
          variables,
        });

        return response.data?.sendVerificationCode || undefined;
      } catch (error) {
        logger.error(error);
        throw new Error(error.message);
      }
    },
    [sendVerificationCodeMutation],
  );

  const validateVerificationCode = useCallback(
    async (variables: ValidateVerificationCodeMutationVariables) => {
      try {
        const response = await validateVerificationCodeMutation({
          fetchPolicy: 'no-cache',
          variables,
        });
        return response.data?.validateVerificationCode || undefined;
      } catch (error) {
        logger.error(error);
        throw new Error(error.message);
      }
    },
    [validateVerificationCodeMutation],
  );

  const setInvestorOnboardingPassword = useCallback(
    async (input: SetInvestorOnboardingPasswordInput) => {
      const response = await setInvestorOnboardingPasswordMutation({
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      });
      const data = response.data?.setInvestorOnboardingPassword;

      if (data?.user) {
        await login({ email: data.user.email, password: input.newPassword1 });

        return data.surveyResult?.status || undefined;
      }

      return undefined;
    },
    [login, setInvestorOnboardingPasswordMutation],
  );

  const setResetPassword = useCallback(
    async (input: SetResetPasswordInput) => {
      try {
        const response = await setResetPasswordMutation({
          fetchPolicy: 'no-cache',
          variables: {
            input,
          },
        });

        return !!response.data?.setResetPassword?.id;
      } catch (error) {
        logger.error(error);
        const errorMessage =
          error.graphQLErrors[0]?.extensions?.newPassword2?.[0] ||
          'Failed to reset password';

        throw new Error(errorMessage);
      }
    },
    [setResetPasswordMutation],
  );

  const validateAdvisorOnboarding = useCallback(
    async (variables: ValidateAdvisorOnboardingMutationVariables) => {
      try {
        // Clear out existing tokens
        removeToken();
        removeRefreshToken();
        const response = await validateAdvisorOnboardingMutation({
          fetchPolicy: 'no-cache',
          variables,
        });

        const requestData = response?.data?.validateAdvisorOnboarding;

        if (requestData) {
          const { isAdvisor } = requestData;
          return { isAdvisor };
        }
        throw new Error('Failed to retrieve advisor');
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to validate token');
      }
    },
    [validateAdvisorOnboardingMutation],
  );

  const setAdvisorOnboardingPassword = useCallback(
    async (input: SetAdvisorOnboardingPasswordInput) => {
      try {
        removeToken();
        removeRefreshToken();
        const response = await setAdvisorOnboardingPasswordMutation({
          fetchPolicy: 'no-cache',
          variables: {
            input,
          },
        });

        const requestData = response.data?.setAdvisorOnboardingPassword;

        if (requestData) {
          const { refreshToken, token } = requestData;

          if (refreshToken && token) {
            if (token) setToken(token);
            if (refreshToken) setRefreshToken(refreshToken);
            return true;
          }
          throw new Error('Failed to retrieve auth tokens');
        }
        return undefined;
      } catch (error) {
        logger.error(error);
        throw new Error('Error setting advisor onboarding password');
      }
    },
    [setAdvisorOnboardingPasswordMutation],
  );

  // Set login stuff here if token is valid
  const validateInvestorOnboardingToken = useCallback(
    async (variables: ValidateInvestorOnboardingTokenMutationVariables) => {
      try {
        // Clear out existing tokens
        removeToken();
        removeRefreshToken();
        const response = await validateInvestorOnboardingTokenMutation({
          fetchPolicy: 'no-cache',
          variables,
        });

        const requestData = response?.data?.validateInvestorOnboardingToken;

        if (requestData) {
          const { refreshToken, token, investorName, activeSurveyStatus } =
            requestData;

          if (refreshToken && token) {
            if (token) setToken(token);
            if (refreshToken) setRefreshToken(refreshToken);
            return { investorName, activeSurveyStatus };
          }
        }
        throw new Error('Failed to retrieve auth tokens');
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to validate token');
      }
    },
    [validateInvestorOnboardingTokenMutation],
  );

  const validateParameterToken = useCallback(
    async (variables: ValidateParameterTokenMutationVariables) => {
      try {
        const response = await validateParameterTokenMutation({
          fetchPolicy: 'no-cache',
          variables,
        });

        return !!response.data?.verifyToken?.payload?.email;
      } catch (error) {
        return false;
      }
    },
    [validateParameterTokenMutation],
  );

  return {
    login,
    loginLoading,
    logout,
    logoutLoading,
    requestResetPassword,
    requestResetPasswordLoading,
    sendVerificationCode,
    sendVerificationCodeError,
    sendVerificationCodeLoading,
    setAdvisorOnboardingPassword,
    setAdvisorOnboardingPasswordLoading,
    setInvestorOnboardingPassword,
    setInvestorOnboardingPasswordLoading,
    setResetPassword,
    setResetPasswordLoading,
    validateAdvisorOnboarding,
    validateAdvisorOnboardingError,
    validateAdvisorOnboardingLoading,
    validateInvestorOnboardingToken,
    validateInvestorOnboardingTokenError,
    validateInvestorOnboardingTokenLoading,
    validateParameterToken,
    validateParameterTokenError,
    validateParameterTokenLoading,
    validateVerificationCode,
    validateVerificationCodeError,
    validateVerificationCodeLoading,
  };
};

export default useAuthActions;
