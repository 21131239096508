import { FC } from 'react';

// import { ReactComponent as EmailIcon } from 'assets/svg/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/phone.svg';
import Icon from 'components/Icon';

import Popover from '..';
import { Name, Company, ContactWrapper, Contact } from './styles';
import { Props } from './types';

const ContactPopover: FC<Props> = ({ children, contactInfo }) => {
  const { name, company, phone } = contactInfo;

  const Content = (
    <>
      <Name>{name}</Name>
      <Company>{company}</Company>
      {/* NOTE: hide email for now https://seedsinvestor.atlassian.net/browse/SD-295 */}
      {/* <ContactWrapper>
        <Icon icon={<EmailIcon />} variant="secondary" />
        <Contact>{email}</Contact>
      </ContactWrapper> */}
      <ContactWrapper>
        <Icon icon={<PhoneIcon />} variant="secondary" />
        <Contact>{phone}</Contact>
      </ContactWrapper>
    </>
  );

  return <Popover content={Content}>{children}</Popover>;
};

export default ContactPopover;
