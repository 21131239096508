import styled from 'styled-components';

import BaseButton from 'components/BaseButton';

export const ListWrapper = styled.div`
  max-width: 28.5rem;
`;

export const UploadButton = styled(BaseButton)`
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  font-weight: bold;
  height: 1.25rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: auto;
  margin-top: 0.75rem;
  padding: 0;
  text-align: right;
`;
