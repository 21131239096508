import styled from 'styled-components';

import SurveyResultsCard from 'components/SurveyResultsCard';

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-top: 0.75rem;
`;

export const Container = styled(SurveyResultsCard)`
  margin: 5rem auto;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`;

export const Column = styled.div`
  margin-top: 1rem;
`;

export const SummaryTitle = styled.h4`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 1rem;
`;

export const SummaryAmount = styled.span`
  display: flex;
  justify-content: space-between;
  color: gray;
`;
