import styled from 'styled-components';

import { Props } from './types';

export const Container = styled.div<Props>`
  border-radius: 0.3125rem;
  height: 0.625rem;
  width: 0.625rem;

  ${({ color, theme }) =>
    `background-color: ${theme.colors[color || 'primary']}`}
`;
