import styled from 'styled-components';

import { CustomTooltip } from 'styles/mixins';
import theme from 'styles/v2/theme';

export const Container = styled.div`
  ${CustomTooltip};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
`;

export const RiskContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RiskHeader = styled.h4`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  vertical-align: baseline;
  color: ${theme.palette.grey[900]};
  margin: 0 0 0.5rem;
`;

export const RiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  > span {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    color: ${theme.palette.grey[900]};
    margin: 0.5rem 0 0;
  }

  @media print {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${theme.palette.primary[50]};
    padding: 1rem;
    border-radius: 1rem;
    width: 50%;
    margin: 1rem auto;
  }
`;

export const RiskCirlce = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 3px;
  font-weight: bold;
`;
