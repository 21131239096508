import { Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridOverlay,
} from '@mui/x-data-grid';
import { useMemo } from 'react';

import CustomPagination from 'components/CustomPagination';
import HoldingsSlider from 'v2/components/HoldingsContainer/Slider';

import type { TableHoldingsType } from './types';

function CustomNoRowsOverlay() {
  return (
    <GridOverlay>
      <Typography variant="subtitle1">
        No holdings match the selection criteria.
      </Typography>
    </GridOverlay>
  );
}

const proposedColumns: GridColDef[] = [
  {
    field: 'symbol',
    headerName: 'TICKER',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.symbol?.length > 10 ? 'N/A' : params.row.symbol,
  },
  { field: 'name', headerName: 'NAME', width: 150 },
  {
    field: 'percentage',
    headerName: '% OF PORTFOLIO',
    width: 135,
    valueGetter: (params: GridValueGetterParams) => `${params.row.percentage}%`,
    sortComparator: (v1, v2, param1, param2) =>
      parseFloat(param1.value) - parseFloat(param2.value),
  },
  {
    field: 'valueScore',
    headerName: 'VALUE SCORE',
    width: 155,
    renderCell: ({ row: { valueScore } }: GridRenderCellParams) => {
      return valueScore === 0 || valueScore === null ? (
        <span>No Score</span>
      ) : (
        <HoldingsSlider type="value" score={valueScore} displayHover />
      );
    },
    sortComparator: (v1, v2, param1, param2) =>
      parseFloat(param1.value) - parseFloat(param2.value),
  },
  {
    field: 'sector',
    headerName: 'SECTOR',
    width: 125,
  },
  {
    field: 'type',
    headerName: 'TYPE',
    width: 110,
  },
];

const currentColumns: GridColDef[] = [
  { field: 'symbol', headerName: 'TICKER', width: 100 },
  { field: 'name', headerName: 'NAME', width: 175 },
  {
    field: 'percentage',
    headerName: '% OF PORTFOLIO',
    width: 150,
    valueGetter: (params: GridValueGetterParams) => `${params.row.percentage}%`,
    sortComparator: (v1, v2, param1, param2) =>
      parseFloat(param1.value) - parseFloat(param2.value),
  },
  {
    field: 'valueScore',
    headerName: 'VALUE SCORE',
    width: 200,
    renderCell: ({ row: { valueScore } }: GridRenderCellParams) => {
      return valueScore === 0 || valueScore === null ? (
        <span>No Score</span>
      ) : (
        <HoldingsSlider type="value" score={valueScore} displayHover />
      );
    },
    sortComparator: (v1, v2, param1, param2) =>
      parseFloat(param1.value) - parseFloat(param2.value),
  },
  {
    field: 'sector',
    headerName: 'SECTOR',
    width: 150,
  },
];

const HoldingsTable = ({
  holdings,
  currentTab,
  isValuesDriven,
}: {
  holdings: TableHoldingsType[];
  currentTab: string;
  isValuesDriven: boolean;
}) => {
  const columnsToDisplay = useMemo(() => {
    let columnsToReturn =
      currentTab === 'Current' ? currentColumns : proposedColumns;
    if (!isValuesDriven) {
      columnsToReturn = columnsToReturn.filter((c) => c.field !== 'valueScore');
      // Need to equally distribute the width that is removed
      const widthToAdd =
        (currentTab === 'Current' ? 200 : 155) / columnsToReturn.length;
      columnsToReturn = columnsToReturn.map((c) => ({
        ...c,
        width: c.width! + widthToAdd,
      }));
    }
    return columnsToReturn;
  }, [currentTab, isValuesDriven]);
  return (
    <DataGrid
      autoHeight
      disableRowSelectionOnClick
      density="comfortable"
      slots={{
        pagination: (paginationProps) => (
          <CustomPagination
            {...paginationProps}
            exportFileName={`${currentTab} Holdings`}
          />
        ),
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      rows={holdings}
      columns={columnsToDisplay}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: 'valueScore', sort: 'desc' }],
        },
      }}
      pageSizeOptions={[10]}
      disableColumnMenu
      hideFooterSelectedRowCount
    />
  );
};

export default HoldingsTable;
