import { format, parse } from 'date-fns/esm';
import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useHouseholdActions from 'graphql/hooks/useHouseholdActions';
import useMe from 'graphql/hooks/useMe';

import { FormValues, MutationErrors } from './types';

const useConnect = () => {
  const { push } = useHistory();
  const { create } = useHouseholdActions();
  const { me } = useMe();

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      const { investorId, errors } = await create({
        advisorId: values.advisor,
        name: values.householdName,
        primary: {
          ...values.primary,
          birthDate: values.primary.birthDate
            ? format(
                parse(values.primary.birthDate, 'MM/dd/yyyy', new Date()),
                'yyyy-MM-dd',
              )
            : undefined,
        },
        // filter empty values
        others: values.others
          .filter((el: any) => {
            const keys = Object.keys(el);
            return keys
              .filter((k) => k !== 'phone' && k !== 'birthDate')
              .every((fieldKey) => !!el[fieldKey]);
          })
          .map((other) => {
            return {
              ...other,
              birthDate: other.birthDate
                ? format(
                    parse(other.birthDate, 'MM/dd/yyyy', new Date()),
                    'yyyy-MM-dd',
                  )
                : undefined,
            };
          }),
        sendEmail: values.isEmailSent,
      });

      const createHouseholdErrors = errors as MutationErrors;
      if (createHouseholdErrors) {
        setErrors({
          primary: createHouseholdErrors?.primary,
          others: createHouseholdErrors?.others,
        });
        return;
      }

      if (investorId) {
        push(`/clients/${investorId}/profile`);
      }
    },
    [create, push],
  );

  return {
    defaultAdvisor: me?.role === 'ADVISOR' ? me?.advisor?.id : undefined,
    handleSubmit,
    me,
  };
};

export default useConnect;
