import styled, { css } from 'styled-components';

import { DotProps } from './types';

export const Container = styled.div<{ $isMobile: boolean }>`
  align-content: center;
  display: grid;
  grid-column-gap: ${({ $isMobile }) => ($isMobile ? '0.5rem' : '1rem')};

  grid-template-columns: 1fr 11.875rem 1fr;

  @media print {
    align-content: baseline;
    grid-template-columns: 3rem 1fr 4rem;
  }
`;

export const Dot = styled.div<DotProps>`
  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: 0.125rem;
  flex: 1;
  height: 0.25rem;

  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          background-color: ${theme.colors.primary300};
          border-radius: 0.25rem;
          height: 0.375rem;
        `
      : null}

  ${({ $isClose, theme }) =>
    $isClose
      ? css`
          background-color: ${theme.colors.primary100};
        `
      : null}
`;

export const Dots = styled.div`
  align-items: center;
  display: flex;

  & > ${Dot}:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export const LeftText = styled.span<{ $isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '1rem')};
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-self: start;
`;

export const RightText = styled(LeftText)`
  justify-self: end;

  @media print {
    justify-self: start;
    min-width: 4.75rem;
    margin-left: auto;
  }
`;
