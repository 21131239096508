import styled from 'styled-components';

import Button from 'components/Button';
import DefaultPlaceholder from 'components/Placeholder';
import { TextWrap } from 'styles/mixins';

export const AddButton = styled(Button).attrs(() => ({
  variant: 'secondary',
}))`
  opacity: 0;
  transition: opacity 150ms ease-out;
  margin-left: 0.25rem;

  &:focus {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0 0.25rem 0.625rem;
  min-height: 2.625rem;

  & + & {
    margin-top: 0.5rem;
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.colors.primary10};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary10};

    ${AddButton} {
      opacity: 1;
    }
  }
`;

export const Name = styled.span`
  flex: 1;
  line-height: 1.2;
  ${TextWrap};
`;

export const PlaceholderName = styled(DefaultPlaceholder)<{
  $width: number;
}>`
  width: ${({ $width }) => $width}%;
  height: 1.25rem;
  padding: 0.6875rem 0;
`;
