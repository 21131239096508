import styled, { css } from 'styled-components';

import BaseButton from 'components/BaseButton';
import BaseIconButton from 'components/IconButton';
import { from } from 'styles/media';
import { addAlpha } from 'utils/addAlpha';

import { NumberProps } from './types';

export const Container = styled.div`
  ${from.tablet} {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Number = styled(BaseButton)<NumberProps>`
  background: none;
  color: ${({ theme }) => theme.colors.grey600};
  line-height: calc(22 / 16);
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1rem;

  &:focus {
    outline: none;
    color: ${({ theme }) => addAlpha(theme.colors.dark, 0.7)};
  }

  :hover {
    opacity: 0.8;
  }

  ${({ theme, $current }) =>
    $current &&
    css`
      color: ${theme.colors.primary};
      opacity: 1;
      :hover {
        opacity: 1;
      }
    `};
`;

export const IconButton = styled(BaseIconButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary10};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  height: 2.25rem;
  justify-content: center;
  padding: 0;
  width: 2.25rem;
  margin-top: 1rem;

  :not(:first-child) {
    margin-left: 1rem;
  }

  ${from.tablet} {
    margin-top: 0;
    margin-left: 1rem;
  }

  :hover:not(:disabled) {
    opacity: 0.8;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary50};
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey0};
    color: ${({ theme }) => theme.colors.grey500};
  }
`;
