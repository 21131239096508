import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrowLeft.svg';
import BaseButton from 'components/Button';
import IconButton from 'components/IconButton';

export const Container = styled.div`
  width: 35.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13.0625rem;
`;

export const Back = styled(IconButton).attrs(() => ({
  variant: 'secondary',
  icon: <ArrowIcon />,
}))`
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-left: 1rem;
`;

export const SecondaryTitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 1.4375rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 7.25rem;
`;

export const Indicator = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  margin-left: auto;
  height: 1.1875rem;
  display: flex;
  align-items: flex-end;
`;

export const Inputs = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.875rem;
  grid-row-gap: 0.75rem;
  margin-bottom: 1.375rem;

  & > button {
    position: absolute;
    right: -60px;
    z-index: 1;

    & > svg {
      margin: 0;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 0.625rem;
  align-items: center;
  justify-content: start;

  > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Button = styled(BaseButton)`
  height: 3rem;
  text-decoration: none;
  text-align: center;
`;

export const ValidationMessage = styled.span`
  color: ${({ theme }) => theme.colors.flamingo};
  height: 1.1875rem;
  display: flex;
  align-items: flex-start;
`;
