import { memo } from 'react';

import useLogic from './logic';
import { Container, Dot, Dots, LeftText, RightText } from './styles';
import { Props } from './types';

const Bar = ({
  className,
  leftText,
  rightText,
  value,
  isMobile = false,
}: Props) => {
  const { values } = useLogic();

  return (
    <Container $isMobile={isMobile} className={`Bar ${className}`}>
      <LeftText $isMobile={isMobile}>{leftText}</LeftText>
      <Dots className="Bar__dots">
        {values.map((current) => (
          <Dot
            key={current}
            $isActive={current === value}
            $isClose={current === value - 1 || current === value + 1}
          />
        ))}
      </Dots>
      <RightText $isMobile={isMobile}>{rightText}</RightText>
    </Container>
  );
};

export default memo(Bar);
