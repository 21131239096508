import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { FormValues } from '../../types';
import { ConnectProps } from './types';

const useConnect = ({ id, name, symbol }: ConnectProps) => {
  const { setValues, values } = useFormikContext<FormValues>();

  const formattedName = useMemo(
    () => (name ? `${symbol} (${name})` : `${symbol}`),
    [name, symbol],
  );

  const handleAdd = useCallback(() => {
    const newAsset = {
      id,
      name,
      position: values.assets ? values.assets.length : 0,
      percentage: 0,
      marketValue: 0,
      symbol,
      valueType: values.valueType,
    };

    setValues((prevValues) => ({
      ...prevValues,
      assets: [...(prevValues.assets || []), newAsset],
    }));
  }, [id, name, setValues, symbol, values.assets, values.valueType]);

  return {
    formattedName,
    handleAdd,
  };
};

export default useConnect;
