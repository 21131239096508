import { Form } from 'formik';
import { memo } from 'react';

import EditPortfolioEmpty from 'components/TableEmptyState/EditPortfolioEmpty';

import { Remove } from '../tableUtils';
import useConnect from './connect';
import { Container, Table } from './styles';
import { Props } from './types';

const Assets = ({ loading, schema }: Props) => {
  const { handleRemove, values, sorting } = useConnect({ schema });

  return (
    <Container>
      <Form id="assets-form" noValidate>
        <Table
          data={values}
          schema={schema}
          empty={<EditPortfolioEmpty />}
          actions={{
            Remove: { action: handleRemove, component: Remove },
          }}
          loading={loading && !values?.length}
          {...sorting}
        />
      </Form>
    </Container>
  );
};

export default memo(Assets);
