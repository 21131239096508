import { gql } from '@apollo/client';

import {
  INVESTOR_FRAGMENT,
  MIN_INVESTOR_FRAGMENT,
} from 'graphql/fragments/investor';

export const INVESTOR = gql`
  query Investor($id: UUID) {
    investor(id: $id) {
      ...InvestorParts
    }
  }
  ${INVESTOR_FRAGMENT}
`;

export const INVESTORS = gql`
  query Investors(
    $orderBy: [OrderByInputType]
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    investors(
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      hasNext
      hasPrev
      page
      pages
      totalResults
      orderBy {
        field
        ordering
      }
      objects {
        ...MinInvestorParts
      }
    }
  }
  ${MIN_INVESTOR_FRAGMENT}
`;
