import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useCallback, useState } from 'react';

import File from 'components/File';
import { FileType } from 'components/File/type';
import Folder from 'components/Folder';
import { FolderType } from 'components/Folder/type';
import SearchBox from 'v2/components/SearchBox';

import { Divider, ModuleDescription } from '../styles';
import {
  LibraryTitle,
  LibraryWrapper,
  Link,
  FolderList,
  FileList,
} from './styles';

export default function InsightsLibrary({
  files,
  folders,
}: {
  files: FileType[];
  folders: FolderType[];
}) {
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<FolderType | null>(null);
  const [isRootContainer, setIsRootContainer] = useState(true);
  const [searchFiles, setSearchFiles] = useState<string>('');
  const [showFiles, setShowFiles] = useState(false);

  const filteredFiles = files.filter(
    (file) =>
      file.name.toLowerCase().includes(searchFiles.toLowerCase()) ||
      file.originalFilename.toLowerCase().includes(searchFiles.toLowerCase()),
  );

  const findFolderByName = (folderName: string): FolderType | undefined => {
    return folders.find((folder) => folder.name === folderName);
  };

  const checkFolderNotEmpty = (folderId: any) => {
    return (
      files.some((file) => file.folder === folderId) ||
      folders.some((folder) => folder.parent === folderId)
    );
  };

  const getParentDescription = (folder: FolderType): string | null => {
    if (folder.parent !== null) {
      const parentFolder = folders.find((f) => f.id === folder.parent);
      if (parentFolder) {
        return parentFolder.description !== null
          ? parentFolder.description
          : getParentDescription(parentFolder);
      }
    }
    return null;
  };

  const getDescription = (): string | null => {
    if (selectedFolder) {
      return selectedFolder.description !== null
        ? selectedFolder.description
        : getParentDescription(selectedFolder);
    }
    return null;
  };

  const handleSearchClear = useCallback(() => {
    setSearchFiles('');
    if (isRootContainer) {
      setBreadcrumbs([]);
      setShowFiles(false);
    }
  }, [isRootContainer]);

  const handleFolderClick = useCallback(
    (folder: FolderType) => {
      setSearchFiles('');
      setBreadcrumbs([...breadcrumbs, folder.name]);
      setSelectedFolder(folder || null);
      setIsRootContainer(false);
    },
    [breadcrumbs],
  );

  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      setBreadcrumbs([]);
      setSearchFiles('');
      setSelectedFolder(null);
      setIsRootContainer(true);
      setShowFiles(false);
    } else {
      const clickedFolderName = breadcrumbs[index - 1];
      const clickedFolder = findFolderByName(clickedFolderName);
      setBreadcrumbs(breadcrumbs.slice(0, index));
      setSelectedFolder(clickedFolder || null);
      setIsRootContainer(false);
      setShowFiles(false);
    }
  };

  const handleSearch = useCallback(
    (e) => {
      const query = e.target.value.trim();
      setSearchFiles(query);
      if (isRootContainer) {
        setBreadcrumbs(query !== '' ? ['Search'] : []);
        setShowFiles(query !== '');
      }
    },
    [isRootContainer],
  );

  return (
    <LibraryWrapper>
      <LibraryTitle>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs.length > 0 ? (
            // eslint-disable-next-line
            <Link className="home" onClick={() => handleBreadcrumbClick(0)}>
              Insights Library
            </Link>
          ) : (
            // eslint-disable-next-line
            <Link className="root">Insights Library</Link>
          )}
          {breadcrumbs.map((label, index) => (
            // eslint-disable-next-line
            <Link key={label} onClick={() => handleBreadcrumbClick(index + 1)}>
              {label}
            </Link>
          ))}
        </Breadcrumbs>
        <SearchBox
          value={searchFiles}
          placeholder="Search for files"
          ariaLabel="search files"
          handleOnChange={handleSearch}
          handleClear={handleSearchClear}
        />
      </LibraryTitle>

      {isRootContainer ? (
        <ModuleDescription>
          The content included here reflects this client’s mindset, risk target,
          and proposed portfolio allocation.
        </ModuleDescription>
      ) : (
        <div>
          {selectedFolder && getDescription() && (
            <ModuleDescription>{getDescription()}</ModuleDescription>
          )}
        </div>
      )}
      <Divider />
      {isRootContainer ? (
        <>
          {!showFiles && folders.length > 0 ? (
            <FolderList>
              {folders
                .filter((item) => item.parent === null)
                .sort((a, b) => a.id - b.id)
                .map((folder) => {
                  const folderNotEmpty = checkFolderNotEmpty(folder.id);

                  return folderNotEmpty ? (
                    <div key={folder.id}>
                      <Folder
                        folder={folder}
                        onFolderClick={handleFolderClick}
                      />
                    </div>
                  ) : null;
                })}
            </FolderList>
          ) : (
            <FileList>
              {filteredFiles.length > 0 ? (
                filteredFiles.map((file) => (
                  <div key={file.id}>
                    <File file={file} />
                  </div>
                ))
              ) : (
                <p>No files</p>
              )}
            </FileList>
          )}
        </>
      ) : (
        <FileList>
          {folders
            .filter((item) => item.parent === selectedFolder?.id)
            .sort((a, b) => a.id - b.id)
            .map((folder) => {
              const folderNotEmpty = checkFolderNotEmpty(folder.id);

              return folderNotEmpty ? (
                <div key={folder.id}>
                  <Folder folder={folder} onFolderClick={handleFolderClick} />
                </div>
              ) : null;
            })}
          {filteredFiles.length > 0 ? (
            filteredFiles
              .filter((item) => item.folder === selectedFolder?.id)
              .map((file) => (
                <div key={file.id}>
                  <File file={file} />
                </div>
              ))
          ) : (
            <p>No files</p>
          )}
        </FileList>
      )}
    </LibraryWrapper>
  );
}
