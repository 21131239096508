import styled from 'styled-components';

import SurveyResultsCard from 'components/SurveyResultsCard';

export const SurveyResultsTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 0.925rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-bottom: 0.5rem;
`;

export const SurveyResultsSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.925rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-bottom: 1.5rem;
`;

export const Container = styled(SurveyResultsCard)`
  margin: 5rem auto;
  width: 100%;
`;
