import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as DefaultIcon } from 'assets/svg/info-circle.svg';

export const InfoIcon = styled(DefaultIcon)`
  color: inherit;
  height: 0.875rem;
  width: 0.875rem;
`;

export const ToolTipStyled = styled(Tooltip)`
  color: #868e96;

  &:hover {
    color: #343a40;
  }

  &.openToolTip {
    color: #54c99c;
  }
`;
