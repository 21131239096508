import { format, isValid, parseISO } from 'date-fns';
import { FC, memo } from 'react';

import { ReactComponent as EmailIcon } from 'assets/svg/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/phone.svg';
import Icon from 'components/Icon';

import {
  Contact,
  ContactWrapper,
  Container,
  DateWrapper,
  Name,
  Primary,
  InfoWrapper,
} from './styles';
import { Props } from './types';

const ContactCard: FC<Props> = ({ contactInfo, className, numberOfPeople }) => {
  const { name, date, email, phone, isPrimary } = contactInfo;
  return (
    <Container className={className}>
      <Name>{name}</Name>
      <DateWrapper>
        {isValid(parseISO(date)) ? format(parseISO(date), 'MM/d/yyyy') : ''}
      </DateWrapper>
      <InfoWrapper $numberOfPeople={numberOfPeople}>
        <ContactWrapper>
          <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
            <ContactWrapper>
              <Icon icon={<EmailIcon />} variant="secondary" />
              <Contact>{email}</Contact>
            </ContactWrapper>
          </a>
        </ContactWrapper>
        {phone && (
          <ContactWrapper>
            <Icon icon={<PhoneIcon />} variant="secondary" />
            <Contact>{phone}</Contact>
            {isPrimary && <Primary>(Primary)</Primary>}
          </ContactWrapper>
        )}
      </InfoWrapper>
    </Container>
  );
};

export default memo(ContactCard);
