import { memo, MouseEvent, useCallback, isValidElement } from 'react';

import TextWrap from 'components/TextWrap';
import UndefinedValue from 'components/UndefinedValue';
import { isNullOrUndefined } from 'utils/defaultSort';
import keysOf from 'utils/keysOf';

import Select from '../Select';
import { useTable } from '../TableContext';
import { Container, Cell, Actions } from './styles';
import { Props } from './types';

type RowData = {
  id: string;
};

function Row<DataType extends RowData>({
  data: { id, ...data },
  columns,
  actions,
  onAction,
  ...props
}: Props<DataType>) {
  const { tableId, selectable, selected, sortable, selectRows } = useTable();
  const checked = !!selected && selected.includes(id);
  const cells = Object.keys(columns).map((key) => {
    const getterKey = key;
    const {
      row: component,
      width,
      align,
    } = columns[getterKey as keyof typeof columns];
    const value = data[key as keyof Omit<DataType, 'id'>];
    const node = !isNullOrUndefined(value)
      ? component(
          value as NonNullable<typeof value>,
          { id, ...data } as NonNullable<DataType>,
        )
      : undefined;

    return (
      <Cell key={key} $width={width} $align={align} $sortable={sortable}>
        <TextWrap>
          <UndefinedValue>{isValidElement(node) ? node : null}</UndefinedValue>
        </TextWrap>
      </Cell>
    );
  });

  if (actions) {
    const dto = { id, ...data } as DataType;
    const components = keysOf(actions).map((name) => {
      const action = actions[name];
      const ActionComponent =
        typeof action === 'function' ? action : action.component;
      const actionFn = typeof action === 'function' ? undefined : action.action;
      return (
        <ActionComponent
          key={`${id}-action-${name}`}
          rowData={dto}
          setData={onAction}
          action={actionFn}
        />
      );
    });
    const actionsCell = <Actions key={`actions-${id}`}>{components}</Actions>;
    cells.push(actionsCell);
  }

  const handleRangeSelection = useCallback(
    (e: MouseEvent<HTMLTableRowElement>) => {
      if (selectable && id && e.shiftKey) {
        e.preventDefault();
        if (selectRows) {
          selectRows({ rowId: id });
        }
      }
    },
    [selectable, id, selectRows],
  );

  return (
    <Container
      selectable={selectable}
      onClick={handleRangeSelection}
      selected={checked}
      {...props}
    >
      {selectable && (
        <Cell>
          <Select id={`${tableId}-${id}`} rowId={id} checked={checked} />
        </Cell>
      )}
      {cells}
    </Container>
  );
}

export default memo(Row) as typeof Row;
