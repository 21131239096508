import { Container, Module } from './styles';
import { Props } from './types';

const ImpactSnapshot = ({ children, className }: Props) => {
  return (
    <Module className={className}>
      <Container>{children}</Container>
    </Module>
  );
};

export default ImpactSnapshot;
