import { useMemo } from 'react';

import { Color } from 'styles/themes';

import { LogicProps } from './types';

const useLogic = ({ color }: LogicProps) => {
  const innerColor: Color | undefined = useMemo(() => {
    switch (color) {
      case 'grey':
        return undefined;
      case 'light':
        return 'grey200';
      case 'primary':
      default:
        return 'primary100';
    }
  }, [color]);

  const outerColor: Color | undefined = useMemo(() => {
    switch (color) {
      case 'grey':
        return 'grey600';
      case 'light':
        return 'grey400';
      case 'primary':
      default:
        return 'primary';
    }
  }, [color]);

  return {
    innerColor,
    outerColor,
  };
};

export default useLogic;
