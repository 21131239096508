import styled from 'styled-components';

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 24);

  @media print {
    font-weight: ${({ theme }) => theme.weights.bold};
    font-size: 1.3125rem;
    line-height: calc(30 / 21);
  }
`;

export const TableSectionHeader = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 2rem;
  }

  > div {
    display: flex;

    > * + * {
      margin-left: 0.75rem;
    }

    > button {
      position: relative;
    }
  }
`;
