import { FC } from 'react';
import { PieChart as RechartPieChart, Pie, Cell, Tooltip } from 'recharts';

import useLogic from './logic';
import CustomTooltip from './Tooltip';
import { Props } from './types';

const PieChart: FC<Props> = ({ outerData, innerData, dataShown }) => {
  const { handle } = useLogic({ outerData, innerData });
  const getCornerRadius = () => {
    return outerData.some((data) => data.value > 3) ? 40 : 4;
  };
  return (
    <RechartPieChart width={180} height={180}>
      <Pie
        blendStroke
        cx="50%"
        cy="50%"
        data={innerData}
        dataKey="value"
        endAngle={-270}
        isAnimationActive={false}
        label={handle.renderCustomizedLabel}
        labelLine={false}
        outerRadius={50}
        startAngle={90}
      >
        {innerData.map((entry) => (
          <Cell key={entry.title} fill={entry.color} />
        ))}
      </Pie>
      <Pie
        blendStroke
        cx="50%"
        cy="50%"
        data={outerData}
        dataKey="value"
        endAngle={-270}
        paddingAngle={3}
        cornerRadius={getCornerRadius()}
        innerRadius={70}
        outerRadius={89}
        isAnimationActive={false}
        // label={handle.renderIconLabel}
        labelLine={false}
        startAngle={90}
      >
        {outerData.map((entry) => (
          <Cell key={entry.title} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip dataShown={dataShown} />} />
    </RechartPieChart>
  );
};

export default PieChart;
