import { Field } from 'formik';
import { useCallback } from 'react';

import Input, { InputGroup, InputError } from 'components/Input';
import Select from 'components/Select';
import ToggleSwitch from 'components/ToggleSwitch';

import { Container, Title, TaxRow } from '../styles';

function TaxManagement({
  values,
  setFieldValue,
  isSubmitting,
  errors,
  touched,
  isQualified,
}: {
  values: any;
  setFieldValue: any;
  isSubmitting: any;
  errors: any;
  touched: any;
  isQualified: boolean;
}) {
  const handleValueSwitch = useCallback(
    (event) => {
      const { name, checked } = event.target;
      setFieldValue(name, checked);
    },
    [setFieldValue],
  );
  return (
    <Container>
      <TaxRow>
        <Title
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            columnGap: '0.5rem',
          }}
        >
          Tax Loss Harvesting
        </Title>
        <InputGroup style={{ marginTop: -8 }}>
          <ToggleSwitch
            onChange={handleValueSwitch}
            name="taxLossHarvesting"
            size="medium"
            checked={values.taxLossHarvesting}
            disabled={isQualified || isSubmitting}
          />
        </InputGroup>
      </TaxRow>
      <TaxRow>
        <Title
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            columnGap: '0.5rem',
          }}
        >
          Tax Transition
        </Title>
        <InputGroup>
          <Field
            as={Select}
            name="taxTransition"
            disabled={isQualified || isSubmitting}
          >
            <option value="">Select...</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Field>
          <InputError name="taxTransition" />
        </InputGroup>
      </TaxRow>
      {values.taxTransition === 'yes' && (
        <>
          <InputGroup>
            <Field
              as={Input}
              name="capitalGainsBudget"
              placeholder="Capital Gains Budget (per year)"
              autoComplete="capital-gains"
              error={!!errors.capitalGains && !!touched.capitalGains}
              disabled={isSubmitting}
            />
            <InputError name="capitalGainsBudget" />
          </InputGroup>
          <InputGroup>
            <Field
              as={Select}
              name="taxTransitionYears"
              disabled={isSubmitting}
            >
              <option value=""># Of Tax Years</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </Field>
            <InputError name="taxTransitionYears" />
          </InputGroup>
          <InputGroup>
            <Field
              as={Input}
              name="taxTransitionInstructions"
              placeholder="Additional Instructions (Optional)"
              autoComplete="additional-instructions"
              disabled={isSubmitting}
            />
            <InputError name="taxTransitionInstructions" />
          </InputGroup>
        </>
      )}
    </Container>
  );
}

export default TaxManagement;
