import { useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelection } from 'components/Table/useSelection';
import useModal from 'graphql/hooks/useModal';
import { Role } from 'models/User';

declare global {
  interface Window {
    previousRoute: string;
  }
}

const useConnect = <T extends { id: string }>(
  tableData: T[],
  recipientRole?: Role,
) => {
  const { open } = useModal('SHARE');
  const [selection] = useSelection({ data: tableData });
  const { pathname, search } = useLocation();

  const canAddHousehold = useMemo(() => {
    if (!selection.selected.length && recipientRole === 'INVESTOR') return true;
    return false;
  }, [recipientRole, selection.selected]);

  const handleOpenModal = useCallback(() => open(), [open]);

  const handleRemove = useCallback(
    (id) => {
      selection.selectRow({ rowId: id });
    },
    [selection],
  );

  useEffect(() => {
    window.previousRoute = pathname + search;
  }, [pathname, search]);

  return {
    canAddHousehold,
    selection,
    tableData,
    handleOpenModal,
    handleRemove,
  };
};

export default useConnect;
