import styled from 'styled-components';

import SurveyResultsCard from 'components/SurveyResultsCard';

export const Hidden = styled.div`
  position: absolute;
  z-index: -1;
  visibility: collapse;
  div {
    visibility: collapse;
  }
  div:first-child {
    height: 0;
  }

  @media print {
    visibility: unset;
    div {
      visibility: unset;
    }
    div:first-child {
      height: unset;
    }
  }
`;

export const Container = styled(SurveyResultsCard)`
  margin: 1rem auto 5rem auto;
  width: 100%;
`;
