import Box from '@mui/material/Box';
import { FC, memo, useEffect, useRef } from 'react';

import { getTooltipMessage } from 'v2/components/Chart/PieChart/Tooltip';

import {
  Container,
  HiddenRadio,
  CircleHiddenRadio,
  LabelContainer,
  Option,
  CircleOption,
  Label,
  Wrapper,
  DescriptionHint,
} from './styles';
import { Props } from './types';

const matchScoreToRiskLabel: { [key: number]: string } = {
  1: 'modest',
  2: 'modest',
  3: 'moderate',
  4: 'moderate',
  5: 'moderate',
  6: 'significant',
  7: 'significant',
  8: 'significant',
  9: 'aggressive',
  10: 'aggressive',
};

// NOTE: design source https://www.figma.com/file/wgXR1RB3TYgyJLc7OH4vaQ/Updated-Profile-View-Components-w%2FRisk-Slider?node-id=0%3A1&t=qb2t1jLBBcDz2gKc-0
const colorSchema = [
  '#ADB2B0',
  '#CCFAE5',
  '#91E3C2',
  '#24B07A',
  '#1c7552',
  '#D5C9FE',
  '#A199DE',
  '#6449C0',
  '#FCC79E',
  '#D48A57',
];

export const RatingOptions = Array.from(Array(10).keys()).map((option) => ({
  label: (option + 1).toString(),
  id: (option + 1).toString(),
  rateValue: option + 1,
}));

const RatingInput: FC<Props> = ({
  name,
  options,
  initialValue,
  colorized = false,
  readOnly,
  isMobile = false,
  ...props
}) => {
  const defaultCheckedRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    defaultCheckedRef.current?.click();
  }, [initialValue]);

  return (
    <Wrapper>
      {/* @ts-ignore */}
      {!readOnly && colorized && props.value && (
        <DescriptionHint
          width={
            // @ts-ignore
            Number(props.value) < 2 ? 250 : 450
          }
        >
          {/* @ts-ignore */}
          {getTooltipMessage(Number(props.value))}
        </DescriptionHint>
      )}
      <Container isMobile={isMobile} bordered={colorized}>
        {options.map(({ id, label, rateValue }, idx) => (
          <div
            key={id}
            style={{
              cursor: readOnly ? 'not-allowed' : 'pointer',
              position: 'unset',
            }}
          >
            {colorized ? (
              <>
                <CircleHiddenRadio
                  {...props}
                  color={colorSchema[idx]}
                  id={id}
                  name={name}
                  ref={
                    rateValue === initialValue ? defaultCheckedRef : undefined
                  }
                  value={rateValue}
                  readOnly={readOnly}
                />

                <CircleOption
                  htmlFor={id}
                  color={
                    // @ts-ignore
                    !props.value ||
                    !initialValue ||
                    // @ts-ignore
                    (rateValue === initialValue && props?.value === rateValue)
                      ? colorSchema[idx]
                      : '#EDEDED'
                  }
                  readOnly={readOnly}
                >
                  {label}
                </CircleOption>
              </>
            ) : (
              <>
                <HiddenRadio
                  {...props}
                  id={id}
                  name={name}
                  ref={
                    rateValue === initialValue ? defaultCheckedRef : undefined
                  }
                  value={rateValue}
                  readOnly={readOnly}
                />
                <Option
                  htmlFor={id}
                  color={colorized ? colorSchema[idx] : ''}
                  readOnly={readOnly}
                >
                  {label}
                </Option>
              </>
            )}
          </div>
        ))}
      </Container>
      {colorized && !isMobile ? (
        <LabelContainer>
          <Label>Modest</Label>
          <Label>Moderate</Label>
          <Label>Significant</Label>
          <Label>Aggressive</Label>
        </LabelContainer>
      ) : null}
      {isMobile ? (
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            mt: '6px',
          }}
        >
          A risk target of {initialValue} indicates{' '}
          {matchScoreToRiskLabel[initialValue || 0]} risk.
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default memo(RatingInput);
