import { FC } from 'react';

import Checkboxes from './Checkboxes';
import Choices from './Choices';
import OrderItems from './OrderItems';
import Range from './Range';
import { SetRisk, SetAllocations } from './SetRisk';
import { Props } from './types';

const StepComponent: FC<Props> = ({
  stepList,
  onAnswer,
  initialValue,
  currentStep,
  readOnly,
}) => {
  const stepInfo = stepList[currentStep - 1];
  const { type, ...stepProps } = stepInfo;

  switch (type) {
    case 'RISK':
      return (
        <SetRisk
          stepList={stepList}
          onAnswer={onAnswer}
          initialValue={initialValue}
          currentStep={currentStep}
          readOnly={readOnly}
        />
      );
    case 'ALLOCATIONS':
      return (
        <>
          <SetAllocations
            stepList={stepList}
            onAnswer={onAnswer}
            initialValue={initialValue}
            currentStep={currentStep}
            readOnly={readOnly}
            {...stepProps}
          />
        </>
      );
    case 'CHECKBOXES':
      return (
        <Checkboxes
          onAnswer={onAnswer}
          initialValue={initialValue as string[]}
          readOnly={readOnly}
          {...stepProps}
        />
      );
    case 'CHOICES':
      return (
        <Choices
          initialValue={initialValue as string}
          onAnswer={onAnswer}
          readOnly={readOnly}
          {...stepProps}
        />
      );
    case 'ORDER_ITEMS':
      return (
        <OrderItems
          initialValue={initialValue as string[]}
          onAnswer={onAnswer}
          readOnly={readOnly}
          {...stepProps}
        />
      );
    case 'RANGE':
      return (
        <Range
          initialValue={initialValue as number}
          readOnly={readOnly}
          onAnswer={(value) =>
            onAnswer(
              JSON.stringify([
                { value: Number(value), id: stepProps.ranges[0].id },
              ]),
            )
          }
          {...stepProps}
        />
      );
    default:
      return null;
  }
};

export default StepComponent;
