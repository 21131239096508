import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

import { modal, Modal } from 'graphql/variables';
import {
  setIncompletes,
  setReleaseNotesDisplayedInSession,
} from 'services/session';
import { setTerms } from 'services/storage';

const useModal = (type: NonNullable<Modal['type']>) => {
  const current = useReactiveVar(modal);
  const isOpen = current.type === type;

  const close = useCallback(() => {
    if (type === 'INCOMPLETE_INVESTORS') setIncompletes(true);
    if (type === 'TERMS') setTerms(true);
    if (type === 'NEW_FEATURE') setReleaseNotesDisplayedInSession();

    modal({});
  }, [type]);

  const open = useCallback(
    (data?: Modal['data']) => {
      modal({ data, type });
    },
    [type],
  );

  return {
    close,
    data: current.data,
    isOpen,
    open,
  };
};

export default useModal;
