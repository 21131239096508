import { nanoid } from 'nanoid';
import { useMemo, useCallback } from 'react';

import keysOf from 'utils/keysOf';

import { Container as Row, Cell } from '../Row/styles';
import { useTable } from '../TableContext';
import { SelectPlaceholder, Placeholder } from './styles';
import { Props } from './types';

const Skeleton = <T extends object>({ columns, rows = 5 }: Props<T>) => {
  const { selectable, sortable } = useTable();
  const makeColumns = useCallback(() => {
    const elements = keysOf(columns).map((column) => {
      const { width, align } = columns[column];

      return (
        <Cell $width={width} $align={align} $sortable={sortable} key={nanoid()}>
          <Placeholder $width={width} />
        </Cell>
      );
    });

    if (selectable) {
      elements.unshift(
        <Cell key={nanoid()}>
          <SelectPlaceholder />
        </Cell>,
      );
    }

    return elements;
  }, [columns, selectable, sortable]);

  const builtRows = useMemo(
    () =>
      Array(rows)
        .fill(0)
        .map(() => (
          <Row selectable={selectable} selected={false} key={nanoid()}>
            {makeColumns()}
          </Row>
        )),
    [rows, selectable, makeColumns],
  );

  return <>{builtRows}</>;
};

export default Skeleton;
