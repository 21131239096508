import { FC } from 'react';

import FileRow from '../FileRow';
import { Container, NewFileRow } from './styles';
import { Props } from './types';

const FileList: FC<Props> = ({
  fileList,
  rootProps,
  isDragActive,
  isDragReject,
  onRemove,
  onRetryUpload,
}) => {
  return (
    <Container
      {...rootProps}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      onClick={() => {}}
    >
      {isDragActive && !isDragReject && <NewFileRow />}

      {fileList.map((fileItem) => (
        <FileRow
          key={fileItem.id}
          onRemove={onRemove}
          onRetryUpload={onRetryUpload}
          fileItem={fileItem}
        />
      ))}
    </Container>
  );
};

export default FileList;
