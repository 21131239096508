import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Formik, Field } from 'formik';
import { useState } from 'react';

import ErrorPage from 'components/ErrorPage';
import Input, { InputGroup, InputError } from 'components/Input';
import PasswordInput from 'components/PasswordInput';
import PrettyLoading from 'components/PrettyLoading';

import CodeForm from './CodeForm';
import { useConnect } from './connect';
import { initialValues, validationSchema } from './constants';
import SidePicture from './SidePicture';
import {
  Button,
  Card,
  Container,
  Content,
  Form,
  Helpers,
  Link,
  Logo,
  LoginHeader,
  Text,
  Wrapper,
} from './styles';

const Login = () => {
  const {
    error,
    errorText,
    handleSubmit,
    isMobile,
    loading,
    handleResendCode,
    handleSubmitCode,
    handleRequire2FAClear,
    codeResent,
    phone,
    require2FA,
    token,
  } = useConnect();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  if (loading) return <PrettyLoading />;

  if (error)
    return (
      <ErrorPage
        title="An error has occurred"
        caption="There has been an error while verifying the token generated by Okta authentication."
      />
    );

  if (token) return null;

  return (
    <>
      <Container>
        <Card>
          {!isMobile ? <SidePicture /> : null}
          <Wrapper>
            {!require2FA ? (
              <Content>
                <Logo size="medium-extra" />
                <LoginHeader>
                  Sign In
                  <p>Enter your account details.</p>
                </LoginHeader>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, isSubmitting, touched }) => (
                    <Form>
                      <InputGroup className="inputGroup">
                        <Field
                          as={Input}
                          disabled={isSubmitting}
                          name="email"
                          error={!!errors.email && !!touched.email}
                          placeholder="Email"
                          autoComplete="username"
                          className="resize"
                          data-testid="login-input-email"
                        />
                        <InputError name="email" />
                      </InputGroup>
                      <InputGroup className="inputGroup">
                        <Field
                          as={PasswordInput}
                          disabled={isSubmitting}
                          name="password"
                          placeholder="Password"
                          type={showPassword ? 'text' : 'password'}
                          autoComplete="current-password"
                          className="resize"
                          rightIcon={
                            <IconButton
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          }
                          data-testid="login-input-password"
                        />
                        <InputError name="password" />
                      </InputGroup>
                      <Helpers>
                        <Link to="/reset-password">Forgot password?</Link>
                      </Helpers>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="primary"
                        data-testid="login-button-sign-in"
                      >
                        Sign In
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Text>
                  Don&apos;t have an account?&nbsp;
                  <Link
                    to={{ pathname: 'https://www.seedsinvestor.com/contact' }}
                    target="_blank"
                    className="demo"
                  >
                    Schedule A Demo
                  </Link>
                </Text>
              </Content>
            ) : (
              <CodeForm
                onResendCode={handleResendCode}
                onSubmit={handleSubmitCode}
                handleRequire2FAClear={handleRequire2FAClear}
                codeResent={codeResent}
                phone={phone}
                errorText={errorText}
              />
            )}
          </Wrapper>
        </Card>
      </Container>
    </>
  );
};

export default Login;
