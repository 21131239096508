import { ReactComponent as ArrowDown } from 'assets/svg/chevronDown.svg';
import { Option } from 'components/Menu/types';

import { Menu, Button } from './styles';

const options: Option[] = [
  {
    to: '/clients',
    name: 'Clients',
  },
  {
    to: '/advisors',
    name: 'Advisors',
  },
];

export function ClientsAdvisorsSwitch() {
  return (
    <Menu options={options}>
      <Button aria-label="Switch between Seeds Clients or Advisors">
        <ArrowDown />
      </Button>
    </Menu>
  );
}
