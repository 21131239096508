import styled from 'styled-components';

import BaseModal, {
  Title as BaseTitle,
  Text as BaseText,
} from 'components/Modal';

export const Modal = styled(BaseModal)``;

export const Text = styled(BaseText)``;

export const Divider = styled.hr`
  border: none;
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  margin-bottom: 1.1875rem;
`;

export const Asset = styled.li`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(20 / 14);
`;

export const List = styled.ul`
  list-style-type: none;
  overflow-y: scroll;
  max-height: 200px;
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 2rem;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
`;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;
