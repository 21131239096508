import styled, { css } from 'styled-components';

import { ZIndex } from 'styles/metrics';
import { prettyScrollbar } from 'styles/mixins';

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled.div<{ $disabled: boolean }>`
  position: relative;
  overflow: ${({ $disabled }) => ($disabled ? 'hidden' : 'auto')};
  ${prettyScrollbar({ vertical: false })}
`;

const Shadow = css<{ $show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  transition: opacity 200ms ease;
  z-index: ${ZIndex.OVERFLOW_CONTAINER};
  pointer-events: none;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
`;

export const ShadowStart = styled.div`
  ${Shadow}
  left: 0;
  background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0));
`;

export const ShadowEnd = styled.div`
  ${Shadow}
  right: 0;
  background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
`;
