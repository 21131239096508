import { parse, format } from 'date-fns/esm';
import * as Yup from 'yup';

import { minDate, maxDate } from 'components/DateInput';
import { phoneValidation } from 'components/PhoneInput/constants';
import { defaultOther } from 'containers/Main/AddHousehold/constants';
import type { InvestorInfo } from 'context/types/investorInfo';
import allOrNone from 'utils/yup/allOrNone';

export const initialValues = (investorInfo: InvestorInfo) => ({
  advisor: investorInfo.advisor?.id || '',
  householdName: investorInfo.household?.name || '',
  primary: {
    email: investorInfo?.user.email || '',
    firstName: investorInfo.householdPerson?.firstName || '',
    lastName: investorInfo.householdPerson?.lastName || '',
    birthDate: investorInfo.householdPerson?.birthDate
      ? format(
          parse(
            investorInfo.householdPerson?.birthDate,
            'yyyy-MM-dd',
            new Date(),
          ),
          'MM/dd/yyyy',
        )
      : '',
    phone: investorInfo.householdPerson?.phone || '',
  },
  others: investorInfo.household?.householdPersonList.length
    ? investorInfo.household?.householdPersonList.map((person: any) => {
        return {
          firstName: person.firstName || '',
          lastName: person.lastName || '',
          birthDate: person.birthDate
            ? format(
                parse(person.birthDate, 'yyyy-MM-dd', new Date()),
                'MM/dd/yyyy',
              )
            : '',
          email: person.email || '',
          phone: person.phone || '',
        };
      })
    : [defaultOther],
});

Yup.addMethod(Yup.object, 'allOrNone', allOrNone);

const requiredFields = ['firstName', 'lastName', 'email'];

const noSpaceRegExp = /^[^\s]+(\s+[^\s]+)*$/;
const noSpaceMessage = 'Leading or trailing whitespaces are not allowed';

export const validationSchema = Yup.object().shape({
  advisor: Yup.string().label('Advisor').required(),
  householdName: Yup.string()
    .matches(noSpaceRegExp, { message: noSpaceMessage })
    .label('Household name')
    .required(),
  primary: Yup.object().shape({
    firstName: Yup.string()
      .matches(noSpaceRegExp, { message: noSpaceMessage })
      .label('First name')
      .required(),
    lastName: Yup.string()
      .matches(noSpaceRegExp, { message: noSpaceMessage })
      .label('Last name')
      .required(),
    birthDate: Yup.date()
      .label('Birth date')
      .min(minDate)
      .max(maxDate)
      .nullable(),
    phone: phoneValidation.nullable(),
  }),
  others: Yup.array()
    .required()
    .of(
      Yup.object()
        .shape({
          firstName: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('First name'),
          lastName: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('Last name'),
          birthDate: Yup.date()
            .label('Birth date')
            .min(minDate)
            .max(maxDate)
            .notRequired()
            .nullable(),
          email: Yup.string()
            .matches(noSpaceRegExp, { message: noSpaceMessage })
            .label('Email')
            .email('Invalid email'),
          phone: phoneValidation.notRequired().nullable(),
        })
        .allOrNone(requiredFields),
    ),
});
