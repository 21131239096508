import { FC } from 'react';

import image1x_png from 'assets/images/surveyResultsCard/header.png';
import image1x_webp from 'assets/images/surveyResultsCard/header.webp';
import image2x_png from 'assets/images/surveyResultsCard/header@2x.png';
import image2x_webp from 'assets/images/surveyResultsCard/header@2x.webp';

import { ChildContent, Container, Picture, Image } from './styles';
import { Props } from './types';

const JourneyFloralBackground: FC<Props> = ({ children, ...props }) => (
  <Container {...props}>
    <Picture>
      <source
        srcSet={`
          ${image2x_webp} 2x,
          ${image1x_webp}
        `}
        type="image/webp"
      />
      <source
        srcSet={`
          ${image2x_png} 2x,
          ${image1x_png}
        `}
        type="image/png"
      />
      <Image src={image1x_png} alt="" />
    </Picture>
    <ChildContent>{children}</ChildContent>
  </Container>
);

export default JourneyFloralBackground;
