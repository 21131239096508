import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;
  button {
    height: 100%;
    margin: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-content: stretch;
  margin-top: 1.25rem;
  button {
    height: 34px;
    margin: 0;
    svg {
      margin: 0;
    }
  }
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Hint = styled.h3`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.grey600};
  margin: 0.5rem 0;
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.flamingo};
  font-size: 0.75rem;
  margin: 0.5rem 0;
`;
