import { FC } from 'react';

import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import FileInput from 'components/FileInput';
import { Text, Error } from 'components/Modal';

import AdvisorRow from './AdvisorRow';
import useConnect from './connect';
import InvestorRow from './InvestorRow';
import {
  ActionButtons,
  CheckboxLabel,
  Modal,
  Select,
  SelectAllRow,
  Title,
  Wrapper,
  Loader,
} from './styles';
import { Props } from './types';
import UserList from './UserList';

const ShareModal: FC<Props> = ({
  advisors = [],
  allAdvisors,
  allInvestors,
  investors = [],
  onRemoveUser,
  recipientRole,
  isLegacy = false,
}) => {
  const {
    all,
    disabled,
    error,
    fileList,
    handleChangeAllPermission,
    handleCheck,
    handleClose,
    handleDrop,
    handleRemove,
    handleRetry,
    handleShare,
    handleUpdatePermission,
    isMobile,
    isOpen,
    loading,
    role,
  } = useConnect({
    idList:
      recipientRole === 'ADVISOR'
        ? advisors.map(({ userId }) => ({ userId }))
        : investors.map((investor) => {
            if ('role' in investor && investor.role === 'INVESTOR') {
              return {
                userId: isLegacy ? investor.userId : investor.user.id,
                investorId: isLegacy ? investor.id : investor.id,
              };
            }
            return { userId: isLegacy ? investor.userId : investor.user.id };
          }),
    all: {
      advisors: allAdvisors,
      investors: allInvestors,
    },
    recipientRole,
  });

  return (
    <Modal fullScreen={isMobile} onClose={handleClose} open={isOpen}>
      <Wrapper>
        <Title>Share files</Title>
        <FileInput
          acceptedFiles="application/zip, application/pdf, text/csv"
          fileList={fileList}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRetryUpload={handleRetry}
        />
        {recipientRole === 'ADVISOR' ? (
          <UserList title="Selected advisors">
            {advisors.length ? (
              advisors.map((advisor) => (
                <AdvisorRow
                  company={advisor.company}
                  disabled={all.advisors}
                  id={advisor.id}
                  key={advisor.id}
                  name={advisor.fullName}
                  onClickRemove={onRemoveUser}
                  updatePermission={handleUpdatePermission}
                />
              ))
            ) : (
              <Text>There are no investors selected</Text>
            )}
          </UserList>
        ) : null}
        {recipientRole === 'INVESTOR' ? (
          <UserList title="Selected investors">
            {investors.length ? (
              investors.map((investor) => (
                <InvestorRow
                  disabled={all.investors}
                  email={isLegacy ? investor.email : investor.user.email}
                  id={investor.id}
                  key={investor.id}
                  name={
                    isLegacy
                      ? investor?.householdPerson?.household?.name
                      : investor?.household?.name
                  }
                  onClickRemove={onRemoveUser}
                />
              ))
            ) : (
              <Text>There are no investors selected</Text>
            )}
          </UserList>
        ) : null}
        <UserList
          title={
            advisors.length || investors.length ? 'Or share with' : 'Share with'
          }
        >
          {role === 'ADMIN' ? (
            <SelectAllRow>
              <Checkbox
                id="allAdvisors"
                defaultChecked={all.advisors}
                onChange={() => handleCheck('allAdvisors')}
              />
              <CheckboxLabel>All advisors</CheckboxLabel>
              <Select
                defaultValue="V"
                onChange={handleChangeAllPermission}
                variant="rowElement"
              >
                <option value="S">Can share</option>
                <option value="V">Only view</option>
              </Select>
            </SelectAllRow>
          ) : null}
          <SelectAllRow>
            <Checkbox
              id="allInvestors"
              defaultChecked={all.investors}
              onChange={() => handleCheck('allInvestors')}
            />
            <CheckboxLabel>All investors</CheckboxLabel>
          </SelectAllRow>
        </UserList>

        <ActionButtons>
          <Button
            disabled={disabled.share}
            icon={loading ? <Loader /> : <ShareIcon />}
            onClick={handleShare}
            variant="primary"
          >
            Share Files
          </Button>
          <Button onClick={handleClose} variant="secondary">
            Cancel
          </Button>
        </ActionButtons>
        {error ? <Error>An error has happened, please try again</Error> : null}
      </Wrapper>
    </Modal>
  );
};

export default ShareModal;
