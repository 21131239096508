import { gql } from '@apollo/client';

import { FILE_FRAGMENT, SHARED_FILE_FRAGMENT } from 'graphql/fragments/file';

export const OWN_FILES = gql`
  query OwnFiles(
    $orderBy: [OrderByInputType]
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    ownFiles(
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      hasNext
      hasPrev
      page
      pages
      totalResults
      orderBy {
        field
        ordering
      }
      objects {
        ...FileParts
      }
    }
  }
  ${FILE_FRAGMENT}
`;

export const SHARED_FILES = gql`
  query SharedFiles(
    $investor: UUID
    $orderBy: [OrderByInputType]
    $page: Int
    $pageSize: Int
    $search: String
  ) {
    sharedFiles(
      investor: $investor
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      hasNext
      hasPrev
      page
      pages
      totalResults
      orderBy {
        field
        ordering
      }
      objects {
        ...SharedFileParts
      }
    }
  }
  ${SHARED_FILE_FRAGMENT}
`;
