import createFastContext from 'context/fastContext/createFastContext';

import { ProposalState, ProposalType, MinProposal } from './types';

const defaultMasterProposal: ProposalType = {
  products: [],
  score: {},
  sectors: [],
  holdingExamples: [],
  holdings: [],
  combinedHoldings: [],
  status: null,
  id: '',
  isMaster: true,
};

const defaultMinProposal: MinProposal = {
  id: '',
  status: null,
  products: [],
  isMaster: false,
};

export const defaultState: ProposalState = {
  masterProposal: { ...defaultMasterProposal },
  proposalBeingEdited: { ...defaultMinProposal },
  proposalState: 'DRAFT',
  allProposals: [],
  availableProducts: {},
  allProducts: [],
  assetClassGroups: [],
  isLoadingProposalData: false,
  isUpdatingProposalData: false,
  hasError: false,
};

const initialState: ProposalState = { ...defaultState };

const { Provider, useStore } = createFastContext<ProposalState>(initialState);

export { Provider as ProposalProvider, useStore };
