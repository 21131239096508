import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ContainerProps, LogoImgProps, TextProps } from './types';

const ContainerStyles = css<ContainerProps>`
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: 1.2688rem;
          svg {
            width: 0.9375rem;
          }
        `;

      case 'large':
        return css`
          height: 2.0688rem;
          svg {
            width: 1.5519rem;
          }
        `;
      case 'medium-extra':
        return css`
          height: 1.8619rem;
          svg {
            width: 1.4375rem;
          }
        `;
      case 'medium':
      default:
        return css`
          height: 1.5rem;
          svg {
            width: 1.125rem;
          }
        `;
    }
  }}
`;

export const Container = styled.div<ContainerProps>`
  ${ContainerStyles};
`;

export const ContainerAsLink = styled(Link)`
  text-decoration: none;
  ${ContainerStyles}
`;

export const Text = styled.div<TextProps>`
  font-family: 'GreyCliff CF';
  font-weight: bold;
  ${({ theme, color }) => {
    switch (color) {
      case 'light':
        return css`
          color: ${theme.colors.grey400};
        `;
      case 'grey':
        return css`
          color ${theme.colors.grey600};
      `;
      case 'primary':
      default:
        return css`
          color: ${theme.colors.primary};
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          font-size: 1.5519rem;
          line-height: calc(33.1 / 24.83);
          margin-left: 0.7025rem;
        `;
      case 'medium-extra':
        return css`
          font-size: 1.3962rem;
          line-height: calc(29.79 / 22.34);
          margin-left: 0.595rem;
        `;
      case 'small':
        return css`
          font-size: 1.125rem;
          line-height: calc(1.33333);
          margin-left: 0.5rem;
        `;
      case 'small-extra':
        return css`
          font-size: 0.9375rem;
          line-height: calc(1.33333);
          margin-left: 0.4063rem;
        `;
      case 'medium':
      default:
        return css`
          font-size: 1.125rem;
          line-height: calc(24 / 18);
          margin-left: 0.5rem;
        `;
    }
  }}
`;

export const LogoImg = styled.img<LogoImgProps>`
  ${({ size }) => {
    switch (size) {
      case 'small-extra':
        return css`
          height: 1.2rem;
          svg {
            width: 0.9375rem;
          }
        `;
      case 'small':
        return css`
          height: 1.4rem;
          svg {
            width: 0.9375rem;
          }
        `;
      case 'large':
        return css`
          height: 2.0688rem;
          svg {
            width: 1.5519rem;
          }
        `;
      case 'medium-extra':
        return css`
          height: 1.8619rem;
          svg {
            width: 1.4375rem;
          }
        `;
      case 'medium':
      default:
        return css`
          height: 1.5rem;
          svg {
            width: 1.125rem;
          }
        `;
    }
  }}

  ${({ theme, color }) => {
    switch (color) {
      case 'light':
        return css`
          color: ${theme.colors.grey400};
        `;
      case 'grey':
        return css`
          color ${theme.colors.grey600};
          filter: grayscale(100%);
      `;
      case 'primary':
      default:
        return css`
          color: ${theme.colors.primary};
        `;
    }
  }}
`;
