import DateInput from 'components/DateInput';
import Input from 'components/Input';
import PhoneInput from 'components/PhoneInput';

export const primaryFields = [
  // {
  //   as: Input,
  //   name: 'householdName',
  //   placeholder: 'Household name',
  // },
  {
    as: Input,
    name: 'primary.firstName',
    placeholder: 'First name',
    autoComplete: 'given-name',
  },
  {
    as: Input,
    name: 'primary.lastName',
    placeholder: 'Last name',
    autoComplete: 'family-name',
  },
  {
    as: Input,
    name: 'primary.email',
    placeholder: 'Email',
    autoComplete: 'email',
  },
  {
    as: DateInput,
    name: 'primary.birthDate',
    placeholder: 'Birth date (optional)',
    autoComplete: 'bday',
  },
  {
    as: PhoneInput,
    name: 'primary.phone',
    placeholder: 'Phone number (optional)',
    autoComplete: 'tel',
  },
];

export const otherFields = [
  {
    as: Input,
    name: 'firstName',
    placeholder: 'First name',
    autoComplete: 'given-name',
  },
  {
    as: Input,
    name: 'lastName',
    placeholder: 'Last name',
    autoComplete: 'family-name',
  },
  {
    as: DateInput,
    name: 'birthdDate',
    placeholder: 'Birth date (optional)',
    autoComplete: 'bday',
  },
  {
    as: Input,
    name: 'email',
    placeholder: 'Email',
    autoComplete: 'email',
  },
  {
    as: Input,
    name: 'phone',
    placeholder: 'Phone number (optional)',
    autoComplete: 'tel',
  },
];
