import { FC, memo } from 'react';

import Leaf from './Leaf';
import useLogic from './logic';
import { Container, ContainerAsLink, Text } from './styles';
import { Props } from './types';

const Logo: FC<Props> = ({
  className,
  color = 'primary',
  size = 'small',
  variant = 'default',
  to,
}) => {
  const { innerColor, outerColor } = useLogic({ color });

  const content = (
    <>
      <Leaf size={size} color={outerColor} innerColor={innerColor} />
      {variant === 'default' && (
        <Text size={size} color={color}>
          Seeds Investor
        </Text>
      )}
    </>
  );

  if (to) {
    return (
      <ContainerAsLink className={className} size={size} to={to}>
        {content}
      </ContainerAsLink>
    );
  }

  return (
    <Container className={className} size={size}>
      {content}
    </Container>
  );
};

export default memo(Logo);
