import { FC } from 'react';

import { ReactComponent as RemoveIcon } from 'assets/svg/trash.svg';
import Select from 'components/Select';

import useLogic from './logic';
import { Container, Company, Name, Remove } from './styles';
import { Props } from './types';

const AdvisorRow: FC<Props> = ({
  company,
  disabled,
  id,
  name,
  onClickRemove,
  updatePermission,
}) => {
  const { onChangePermission } = useLogic(updatePermission, id);

  return (
    <Container $disabled={disabled}>
      <Name title={name}>{name}</Name>
      <Company title={company}>{company}</Company>
      <Select
        defaultValue="V"
        disabled={disabled}
        onChange={onChangePermission}
        variant="rowElement"
      >
        <option value="S">Can share</option>
        <option value="V">Only view</option>
      </Select>
      {onClickRemove ? (
        <Remove
          icon={<RemoveIcon />}
          disabled={disabled}
          onClick={() => onClickRemove(id)}
          variant="transparent"
        />
      ) : null}
    </Container>
  );
};

export default AdvisorRow;
