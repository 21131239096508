import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Divider } from 'components/SurveyCard/styles';

import Choices from '../Choices';
import Counter from '../Counter';
import Range from '../Range';
import { Props } from '../types';
import {
  Content,
  Column,
  SummaryTitle,
  SummaryAmount,
  Subtitle,
} from './styles';
import { PercentageValues } from './types';

const ristLevelOptions = ['low', 'mid', 'high'];
const SetRisk: FC<Props> = ({
  stepList,
  onAnswer,
  initialValue,
  currentStep,
}) => {
  const stepInfo = stepList[currentStep - 1];
  const { type, ...stepProps } = stepInfo;
  const { id: investorId } = useParams<{
    id: string;
  }>();

  const [currentAnswer, setCurrentAnswer] = useState({
    investorId,
    // @ts-ignore
    riskLevel: initialValue?.rangeSelected,
    // @ts-ignore
    incomeLevel: initialValue?.incomeLevel,
  });
  const handleAnswer = (val: any) => {
    const res = {
      ...currentAnswer,
      ...val,
    };
    setCurrentAnswer(res);
    onAnswer(res);
  };

  return (
    <>
      <Range
        colorized
        // @ts-ignore
        initialValue={initialValue?.riskLevel}
        onAnswer={(riskLevel) =>
          handleAnswer({
            riskLevel: {
              id: stepProps.ranges[0].id,
              // @ts-ignore
              value: Number(riskLevel),
            },
          })
        }
        {...stepProps}
      />
      <Choices
        // @ts-ignore
        initialValue={initialValue?.incomeLevel as string}
        onAnswer={(incomeLevel) => {
          const answerIndex = stepInfo.choices.findIndex(
            (choice) => choice.id === incomeLevel,
          );
          handleAnswer({
            incomeLevel: {
              id: incomeLevel,
              value: ristLevelOptions[answerIndex],
            },
          });
        }}
        {...stepProps}
        description=""
        title=""
      />
    </>
  );
};

const SetAllocations: FC<Props> = ({ onAnswer, initialValue = [] }) => {
  const { id: investor } = useParams<{
    id: string;
  }>();

  const baseValues = {
    // @ts-ignore
    largeCap: initialValue[0]?.value || 0,
    // @ts-ignore
    midCap: initialValue[1]?.value || 0,
    // @ts-ignore
    smallCap: initialValue[2]?.value || 0,
    // @ts-ignore
    international: initialValue[3]?.value || 0,
    // @ts-ignore
    coreFixed: initialValue[4]?.value || 0,
    // @ts-ignore
    muni: initialValue[5]?.value || 0,
  };

  const [currentAnswer, setCurrentAnswer] = useState({
    investor,
    ...baseValues,
  });

  const handleAnswer = (val: any) => {
    const res = {
      ...currentAnswer,
      ...val,
    };
    setCurrentAnswer(res);
    onAnswer(res);
  };
  const leftColumn = [
    PercentageValues.largeCap,
    PercentageValues.midCap,
    PercentageValues.smallCap,
    PercentageValues.international,
  ];
  const labels = {
    [PercentageValues.largeCap]: 'Large Cap',
    [PercentageValues.midCap]: 'Mid Cap',
    [PercentageValues.smallCap]: 'Small Cap',
    [PercentageValues.international]: 'International',
    [PercentageValues.coreFixed]: 'Taxable Bonds',
    [PercentageValues.muni]: 'Municipal Bonds',
  };
  const rightColumn = [PercentageValues.coreFixed, PercentageValues.muni];
  return (
    <>
      <>
        <Subtitle>Edit Proposed Allocations</Subtitle>
        <Content>
          <Column>
            <SummaryTitle>
              Equity
              <SummaryAmount>
                {leftColumn.reduce((acc, name) => acc + currentAnswer[name], 0)}{' '}
                %
              </SummaryAmount>
            </SummaryTitle>
            <Divider />
            {leftColumn.map((name) => {
              return (
                <Counter
                  key={name}
                  id={name}
                  title=""
                  description={labels[name]}
                  initialValue={baseValues[name]}
                  ranges={{
                    id: name,
                    min: 0,
                    max: 100,
                    percentage: false,
                    order: 0,
                    label: '',
                    nextStep: undefined,
                  }}
                  onAnswer={(value) => {
                    handleAnswer({ [name]: value });
                  }}
                />
              );
            })}
          </Column>

          <Column>
            <SummaryTitle>
              Fixed
              <SummaryAmount>
                {rightColumn.reduce(
                  (acc, name) => acc + currentAnswer[name],
                  0,
                )}{' '}
                %
              </SummaryAmount>
            </SummaryTitle>
            <Divider />
            {rightColumn.map((name) => {
              return (
                <Counter
                  key={name}
                  id={name}
                  title=""
                  description={labels[name]}
                  initialValue={baseValues[name]}
                  ranges={{
                    id: name,
                    min: 0,
                    max: 100,
                    percentage: false,
                    order: 0,
                    label: '',
                    nextStep: undefined,
                  }}
                  onAnswer={(value) => {
                    handleAnswer({ [name]: value });
                  }}
                />
              );
            })}
          </Column>
        </Content>
      </>
    </>
  );
};

export { SetRisk, SetAllocations };
