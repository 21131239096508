import Box from '@mui/material/Box';
import { useMemo } from 'react';

import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { AssetGroupType } from 'context/fastContext/proposal/types';
import theme from 'styles/v2/theme';

const commonStyles = () => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 10px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightRegular || 'normal',
  lineHeight: '1.25rem',
  height: '38px',
});

type ProductGroupType = {
  assetClassGroup: any;
  associatedProposal: any;
  accountValue: number;
  isCustodian: boolean;
};

const ProductGroup = ({
  assetClassGroup,
  associatedProposal,
  accountValue,
  isCustodian,
}: ProductGroupType) => {
  const [{ allProducts, assetClassGroups }] = useProposalStore((s: any) => ({
    assetClassGroups: s.assetClassGroups as AssetGroupType[],
    allProducts: s.allProducts,
  }));

  const groupAssetClasses = useMemo(
    () => [
      assetClassGroup.id,
      ...assetClassGroup.children.map((acgc: any) => acgc.id),
    ],
    [assetClassGroup.children, assetClassGroup.id],
  );

  const groupAssociatedProducts = useMemo(
    () =>
      associatedProposal?.products.filter((p: any) => {
        return groupAssetClasses.includes(p.assetClassId);
      }),
    [associatedProposal?.products, groupAssetClasses],
  );

  const allProductsWithNames = useMemo(() => {
    return groupAssociatedProducts?.map((sp: any) => {
      const newProducts = allProducts.find((ap: any) => ap.id === sp.id);

      const fund = assetClassGroups.find((acg) => sp.assetClassId === acg.id);
      const { allocation } = sp;

      return {
        ...sp,
        ...newProducts,
        value: (allocation * accountValue) / 100,
        isFund: fund || false,
      };
    });
  }, [groupAssociatedProducts, allProducts, assetClassGroups, accountValue]);

  const fundIsSelected = useMemo(
    () => allProductsWithNames?.some((p: any) => p.isFund),
    [allProductsWithNames],
  );

  const groupChildrenSorted = useMemo(
    () =>
      assetClassGroup.children.sort(
        (a: any, b: any) => a.assetOrder - b.assetOrder,
      ),
    [assetClassGroup.children],
  );

  const totalAllocationValue = allProductsWithNames?.reduce(
    (mem: any, cur: any) => {
      if (groupAssetClasses.includes(cur.assetClassId)) {
        mem += cur.allocation;
      }
      return mem;
    },
    0,
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: theme.palette.grey[100],
            padding: '0 10px',
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: '1.25rem',
            height: '38px',
          }}
        >
          <Box sx={{ flex: 5 }}>{assetClassGroup.name}</Box>
          <Box sx={{ flex: 8 }}>Sleeve Name</Box>
          <Box sx={{ flex: 4 }}>Minimum</Box>
          <Box sx={{ flex: 4 }}>Value</Box>
          <Box sx={{ flex: 1, textAlign: 'end' }}>{totalAllocationValue}%</Box>
        </Box>
      </Box>
      {fundIsSelected ? (
        <Box>
          {allProductsWithNames?.map((product: any) => {
            const minToUse = isCustodian
              ? product.custodianMinimum
              : product.minimum;
            return product.isFund ? (
              <Box sx={commonStyles} key={product.id}>
                <Box sx={{ flex: 5 }}>Fund</Box>
                <Box sx={{ flex: 8 }}>{product?.name}</Box>
                <Box sx={{ flex: 4 }}>
                  {minToUse > 0
                    ? `$${Number(minToUse).toLocaleString()}`
                    : 'N/A'}
                </Box>
                <Box
                  sx={{
                    flex: 4,
                    color:
                      product?.value < minToUse ? 'warning.main' : 'inherit',
                  }}
                >
                  ${Number(product?.value).toLocaleString()}
                </Box>
                <Box sx={{ flex: 1, textAlign: 'end' }}>
                  {product?.allocation}%
                </Box>
              </Box>
            ) : null;
          })}
        </Box>
      ) : (
        <Box>
          {groupChildrenSorted?.map((acgc: any) => {
            const product: any = allProductsWithNames?.find(
              (sp: any) => sp.assetClassId === acgc.id,
            );

            return (
              <Box sx={commonStyles} key={acgc.id}>
                <Box sx={{ flex: 5 }}>{acgc?.name}</Box>
                <Box sx={{ flex: 8 }}>
                  {product?.name ? product?.name : 'N/A'}
                </Box>
                {isCustodian ? (
                  <>
                    <Box sx={{ flex: 4 }}>
                      {product?.custodianMinimum > 0
                        ? `$${Number(
                            product?.custodianMinimum,
                          ).toLocaleString()}`
                        : 'N/A'}
                    </Box>
                    <Box
                      sx={{
                        flex: 4,
                        color:
                          product?.value < product?.custodianMinimum
                            ? 'error.main'
                            : 'inherit',
                      }}
                    >
                      {product?.value
                        ? `$${Number(product?.value).toLocaleString()}`
                        : 'N/A'}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ flex: 4 }}>
                      {product?.minimum > 0
                        ? `$${Number(product?.minimum).toLocaleString()}`
                        : 'N/A'}
                    </Box>
                    <Box
                      sx={{
                        flex: 4,
                        color:
                          product?.value < product?.minimum
                            ? 'error.main'
                            : 'inherit',
                      }}
                    >
                      {product?.value
                        ? `$${Number(product?.value).toLocaleString()}`
                        : 'N/A'}
                    </Box>
                  </>
                )}
                <Box sx={{ flex: 1, textAlign: 'end' }}>
                  {product?.allocation ? `${product?.allocation}%` : 'N/A'}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default ProductGroup;
