/* eslint-disable no-console */
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { calculateAllocations } from 'context/fastContext/helpers';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import type {
  ProposalType,
  ProposalToUpdate,
  AssetGroupType,
} from 'context/fastContext/proposal/types';
import AuthFetch from 'utils/authFetch';

import useProposalActions from '../proposal/actions';
import { useStore, initialState } from './context';
import { AccountType, AddAccountParams, EditAccountParams } from './types';

export default function useAccountActions() {
  const { id: investorId } = useParams<{ id: string }>();
  const [assetClassGroups] = useProposalStore((s) => s.assetClassGroups);
  const { setMasterProposal, setProposals } = useProposalActions();
  const [, setStore] = useStore(() => {});

  const fetchAccountConstants = useCallback(
    async (setLoading: boolean = true) => {
      if (setLoading) setStore({ isLoadingAccounts: true });

      try {
        const data = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/account_constants`,
          method: 'GET',
          useCache: true,
        });

        if (data.payload) {
          const { accountCustodians: custodians, accountTypes: types } =
            data.payload;
          setStore({
            accountCustodians: custodians,
            accountTypes: types,
            ...(setLoading && { isLoadingAccounts: false }),
          });
        }
      } catch (e) {
        console.log('Error retrieving account constants', e);
        if (setLoading) setStore({ isLoadingAccounts: false });
      }
    },
    [setStore],
  );

  const fetchAccounts = useCallback(
    async ({
      useCache = true,
      isUpdating = false,
      assetClasses,
      historicId = null,
      setLoading = true,
    }: {
      useCache?: boolean;
      isUpdating?: boolean;
      assetClasses?: any;
      historicId?: null | string;
      setLoading?: boolean;
    }): Promise<boolean> => {
      const loadKey = isUpdating ? 'isUpdatingAccounts' : 'isLoadingAccounts';
      if (setLoading) setStore({ [loadKey]: true });
      try {
        const data = await AuthFetch({
          url: historicId
            ? `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${historicId}/investor_state`
            : `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${investorId}/current`,
          method: 'GET',
          useCache,
        });

        if (data.payload) {
          const {
            masterProposal,
            accountProposal,
            masterAccount: mAccount,
            accountGroupStatus,
          } = data.payload;

          // If no accountGroup then they only have a proposal so set master proposal
          if (!accountProposal) {
            setMasterProposal({ proposal: masterProposal, assetClasses });

            // Setting this here for progress bar
            setProposals({ proposals: [masterProposal], assetClasses });

            // Reset accounts as they could have been deleted
            setStore({
              masterAccount: {},
              accounts: [],
              isViewingHistory: !!historicId,
              status: accountGroupStatus,
              ...(setLoading && { [loadKey]: false }),
            });
          } else {
            // If they do have account groups then set the accounts + master and master proposal
            const proposals: ProposalType[] = [];
            const mappedAccounts: AccountType[] = [];

            accountProposal.forEach((a: any) => {
              mappedAccounts.push({
                id: a.id,
                name: a.name,
                proposalId: a.proposal.id,
                accountType: a.accountType,
                accountCustodian: a.accountCustodian,
                amountInvestedDollar: a.amountInvestedDollar,
                created: a.created,
              });
              proposals.push(a.proposal);
            });

            setStore({
              status: accountGroupStatus,
              masterAccount: mAccount,
              accounts: mappedAccounts.sort(
                (a, b) =>
                  new Date(a.created!).getTime() -
                  new Date(b.created!).getTime(),
              ),
              isViewingHistory: !!historicId,
              ...(setLoading && { [loadKey]: false }),
            });
            setProposals({ proposals, assetClasses });

            setMasterProposal({
              proposal: masterProposal,
              assetClasses,
            });
          }

          return true;
        }
      } catch (e) {
        console.log('Error retrieving accounts', e);
      }
      if (setLoading) setStore({ [loadKey]: false });
      return false;
    },
    [investorId, setMasterProposal, setProposals, setStore],
  );

  const createAccounts = useCallback(
    async ({
      createdAccounts,
    }: {
      createdAccounts: AddAccountParams[];
    }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/`,
          method: 'POST',
          body: {
            accounts: createdAccounts,
            investorId,
          },
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error creating account(s)', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [investorId, setStore],
  );

  const updateAccountAndOrProposal = useCallback(
    async ({
      accountToUpdate,
      proposalToUpdate,
    }: {
      accountToUpdate: EditAccountParams | null;
      proposalToUpdate: ProposalToUpdate;
    }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });
      try {
        const body: { proposal: any; account?: any } = {
          proposal: proposalToUpdate,
        };
        if (accountToUpdate) {
          body.account = accountToUpdate;
        }

        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/update-account-proposal/`,
          method: 'POST',
          body,
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error editing account', e);
        setStore({ isUpdatingAccounts: false });
      }
      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  // TODO: Move into proposal context?
  const discardProposalDraft = useCallback(
    async ({ proposalId }: { proposalId: string }) => {
      setStore({ isUpdatingAccounts: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${proposalId}/discard`,
          method: 'GET',
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error discarding draft', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  const deleteAccount = useCallback(
    async ({ accountId }: { accountId: string }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });

      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${accountId}/`,
          method: 'DELETE',
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error deleting accounts', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  const activateAccounts = useCallback(async (): Promise<boolean> => {
    setStore({ isUpdatingAccounts: true });

    try {
      const response = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${investorId}/activate`,
        method: 'GET',
        useCache: false,
      });

      if (response.status) {
        setStore({ isUpdatingAccounts: false });
        return true;
      }
    } catch (e) {
      console.log('Error activating accounts', e);
    }

    setStore({ isUpdatingAccounts: false });
    return false;
  }, [investorId, setStore]);

  const implementAlturistAccount = useCallback(
    async ({ accountId }: { accountId: string }): Promise<boolean> => {
      setStore({ isUpdatingAccounts: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${accountId}/implement`,
          method: 'GET',
          useCache: false,
        });

        if (response.status) {
          setStore({ isUpdatingAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error activating accounts', e);
      }

      setStore({ isUpdatingAccounts: false });
      return false;
    },
    [setStore],
  );

  const handleUpdateLoadingState = useCallback(
    (isUpdating: boolean) => {
      setStore({ isUpdatingAccounts: isUpdating });
    },
    [setStore],
  );

  const handleEditAccountInteraction = useCallback(
    async ({ accountToEdit }: { accountToEdit: AccountType }) => {
      setStore({
        isEditingAccount: true,
        accountBeingEdited: { ...accountToEdit },
      });
    },
    [setStore],
  );

  const handleRemoveEditAccount = useCallback(() => {
    setStore({ isEditingAccount: false, accountBeingEdited: {} });
  }, [setStore]);

  const fetchHistory = useCallback(
    async ({
      useCache = true,
      isUpdating = false,
      assetClasses,
      setLoading = true,
    }: {
      useCache?: boolean;
      isUpdating?: boolean;
      assetClasses?: AssetGroupType[];
      setLoading?: boolean;
    }) => {
      const loadKey = isUpdating
        ? 'isLoadingHistoricAccounts'
        : 'isLoadingAccounts';

      if (setLoading) setStore({ [loadKey]: true });
      try {
        const data = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${investorId}/history`,
          method: 'GET',
          useCache,
        });

        if (data.payload) {
          const assetsToUse = assetClassGroups.length
            ? assetClassGroups
            : assetClasses;
          const withAllocations = data.payload.reverse().map((h: any) => {
            const allocations = calculateAllocations(
              h.assetClassBreakdown,
              assetsToUse!,
            );

            return {
              ...h,
              allocations,
            };
          });

          setStore({
            history: withAllocations,
            ...(setLoading && { [loadKey]: false }),
          });
        }
      } catch (e) {
        console.log('Error retrieving account history', e);
      }
      if (setLoading) setStore({ [loadKey]: false });
    },
    [assetClassGroups, investorId, setStore],
  );

  // Just prevents it from showing up in history
  const deactivateHistoricAccount = useCallback(
    async ({ accountId }: { accountId: string }) => {
      setStore({ isLoadingHistoricAccounts: true });

      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/account/${accountId}/history/discard`,
          method: 'GET',
          useCache: false,
        });

        if (response.status) {
          setStore({ isLoadingHistoricAccounts: false });
          return true;
        }
      } catch (e) {
        console.log('Error discarding draft', e);
      }

      setStore({ isLoadingHistoricAccounts: false });
      return false;
    },
    [setStore],
  );

  const handleInitialAccountFetch = useCallback(
    async ({ assetClasses }: { assetClasses: any }) => {
      setStore({ isLoadingAccounts: true });
      await fetchAccountConstants(false);
      await fetchAccounts({ assetClasses, setLoading: false });
      await fetchHistory({ assetClasses, setLoading: false });
      setStore({ isLoadingAccounts: false });
    },
    [fetchAccountConstants, fetchAccounts, fetchHistory, setStore],
  );

  const resetAccounts = useCallback(() => {
    setStore({ ...initialState });
  }, [setStore]);

  return useMemo(
    () => ({
      fetchAccounts,
      createAccounts,
      updateAccountAndOrProposal,
      discardProposalDraft,
      deleteAccount,
      activateAccounts,
      implementAlturistAccount,
      handleUpdateLoadingState,
      handleEditAccountInteraction,
      handleRemoveEditAccount,
      fetchHistory,
      handleInitialAccountFetch,
      resetAccounts,
      deactivateHistoricAccount,
    }),
    [
      activateAccounts,
      createAccounts,
      deactivateHistoricAccount,
      deleteAccount,
      discardProposalDraft,
      fetchAccounts,
      fetchHistory,
      handleEditAccountInteraction,
      handleRemoveEditAccount,
      handleInitialAccountFetch,
      handleUpdateLoadingState,
      resetAccounts,
      updateAccountAndOrProposal,
      implementAlturistAccount,
    ],
  );
}
