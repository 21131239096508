import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.75rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.grey800};
  font-size: 0.85rem;
  font-weight: 400;
  line-heifht: 22px;
  text-align: left;
`;
