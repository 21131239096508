import { Fade, Slide, Zoom } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

const SlideUp = forwardRef<unknown, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" {...props} ref={ref}>
      {children as any}
    </Slide>
  ),
);

const animations = {
  slideUp: SlideUp,
  zoom: Zoom,
  fade: Fade,
};

export default animations;

export type AnimationName = keyof typeof animations;
