import styled from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';
import { TextWrap } from 'styles/mixins';

export const Container = styled(Card)`
  max-width: 23.125rem;
  padding: 0.9063rem 1.2813rem;
`;

export const Name = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(22 / 16);
  ${TextWrap}
`;

export const DateWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-bottom: 0.75rem;
  ${TextWrap}
`;

export const ContactWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  margin-bottom: 0.5rem;
  ${TextWrap}
`;

export const Contact = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Greycliff CF';
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: 0.625rem;
  ${TextWrap}
`;

export const Primary = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: 0.5rem;
  ${TextWrap}
`;

export const GrantAccess = styled(Button).attrs(() => ({
  variant: 'secondary',
}))<{ $numberOfPeople?: number }>`
  ${({ $numberOfPeople }) =>
    $numberOfPeople === 1 ? `margin-bottom: 0.5rem;` : `margin-top: 0.9375rem;`}
`;

export const InfoWrapper = styled.div<{ $numberOfPeople?: number }>`
  ${({ $numberOfPeople }) =>
    $numberOfPeople === 1 &&
    `
      display: flex;
      gap: 1rem
    `}
`;
