import { Field, Formik } from 'formik';

import Input, { InputGroup, InputError } from 'components/Input';

import useConnect from './connect';
import { initialValues, validationSchema } from './constants';
import {
  Button,
  Card,
  Container,
  Content,
  Form,
  Illustration,
  Link,
  Logo,
  RecoveryHeader,
  Text,
  Wrapper,
} from './styles';

const ResetPassword = () => {
  const { handleSubmit, success } = useConnect();

  return (
    <Container>
      <Card>
        <Illustration />
        <Wrapper>
          <Content>
            <Logo size="medium-extra" />
            <RecoveryHeader>
              Forgot Password?
              <p>
                Enter your email below, you will receive an email with
                instructions on how to reset your password in a few minutes. You
                can also set a new password if you&apos;ve never set one before.
              </p>
            </RecoveryHeader>
            {success ? (
              <>
                <Text>Thank You! Please check your email.</Text>
                <Text>
                  <Link to="/login" className="return">
                    Return to Sign In
                  </Link>
                </Text>
              </>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, isSubmitting, touched }) => (
                  <Form>
                    <InputGroup className="inputGroup">
                      <Field
                        as={Input}
                        name="email"
                        error={!!errors.email && !!touched.email}
                        placeholder="Email"
                        autoComplete="username"
                        className="resize"
                      />
                      <InputError name="email" />
                    </InputGroup>
                    <Button disabled={isSubmitting} type="submit">
                      Start Recovery
                    </Button>
                    <Text>
                      <Link to="/login" className="return">
                        Return to Sign In
                      </Link>
                    </Text>
                  </Form>
                )}
              </Formik>
            )}
            <Text>
              Don&apos;t have an account?&nbsp;
              <Link
                to={{ pathname: 'https://www.seedsinvestor.com/contact' }}
                target="_blank"
                className="demo"
              >
                Schedule A Demo
              </Link>
            </Text>
          </Content>
        </Wrapper>
      </Card>
    </Container>
  );
};

export default ResetPassword;
