import { People, Person } from './styles';
import { Props } from './types';

export function HouseholdPeople({ people }: Props) {
  const numberOfPeople = people.length;
  return (
    <People $numberOfPeople={numberOfPeople}>
      {people &&
        people.map(({ date, id, email, isPrimary, fullName, phone }) => (
          <Person
            key={id}
            $numberOfPeople={numberOfPeople}
            contactInfo={{
              date,
              email,
              isPrimary,
              name: fullName,
              phone,
            }}
            numberOfPeople={numberOfPeople}
          />
        ))}
    </People>
  );
}
