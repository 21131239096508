import { FC } from 'react';

import { Container, AnimatedContainer } from './styles';
import { Props } from './types';

const Card: FC<Props> = ({ children, className, animated, ...props }) => {
  if (animated) {
    return (
      <AnimatedContainer className={className} {...props}>
        {children}
      </AnimatedContainer>
    );
  }

  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  );
};

export default Card;
