import { Route, Switch } from 'react-router-dom';

import Detail from './ClientsDetail';
import Root from './ClientsRoot';
import { useConnect } from './connect';

const Clients = () => {
  const { path } = useConnect();
  // console.log('Clients');
  return (
    <Switch>
      <Route component={Detail} path={`${path}/:id`} />
      <Route component={Root} path={`${path}`} />
    </Switch>
  );
};

export default Clients;
