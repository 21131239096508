/* eslint-disable react/no-danger */
import { Formik, Form, Field } from 'formik';
import { FC, SyntheticEvent } from 'react';

import OptionsPad from 'components/OptionsPad';
import RadioGroupSurvey from 'components/RadioGroupSurvey';
import {
  Description,
  Hint,
  RadioButtonContainer,
} from 'components/SurveyCard/styles';

import { Props } from './types';

const Choices: FC<Props> = ({
  choices,
  description = '',
  extraDescription,
  initialValue,
  onAnswer,
  readOnly,
}) => {
  return (
    <>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Description>

      {extraDescription && (
        <>
          {' '}
          <Hint dangerouslySetInnerHTML={{ __html: extraDescription }} />
        </>
      )}

      <Formik
        initialValues={{ options: initialValue }}
        onSubmit={({ options }) => {
          if (options) {
            onAnswer(options);
          }
        }}
      >
        {({ handleChange, submitForm }) => (
          <Form>
            {choices.filter((choice) => choice.isRadioButton).length > 0 ? (
              <RadioButtonContainer>
                <Field
                  as={RadioGroupSurvey}
                  initialValue={initialValue}
                  name="options"
                  options={choices}
                  disabled={readOnly}
                  onChange={(e: SyntheticEvent) => {
                    handleChange(e);
                    submitForm();
                  }}
                />
              </RadioButtonContainer>
            ) : (
              <Field
                as={OptionsPad}
                initialValue={initialValue}
                name="options"
                options={choices}
                disabled={readOnly}
                onChange={(e: SyntheticEvent) => {
                  handleChange(e);
                  submitForm();
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Choices;
