import styled from 'styled-components';

import { TextWrap } from 'styles/mixins';

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  ${TextWrap}
`;

export const Company = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  ${TextWrap}
`;

export const ContactWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  margin-bottom: 0.5rem;
  ${TextWrap}
`;

export const Contact = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: 0.625rem;
  ${TextWrap}
`;
