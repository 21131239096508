import { FC, memo } from 'react';

import image1x_png from 'assets/images/loginCard/login_card@1x.png';
import image1x_webp from 'assets/images/loginCard/login_card@1x.webp';
import image2x_png from 'assets/images/loginCard/login_card@2x.png';
import image2x_webp from 'assets/images/loginCard/login_card@2x.webp';

import { Container, Image } from './styles';
import { Props } from './types';

const SidePicture: FC<Props> = ({ ...props }) => (
  <Container {...props}>
    <picture>
      <source
        srcSet={`
          ${image2x_webp} 2x,
          ${image1x_webp}
        `}
        type="image/webp"
      />
      <source
        srcSet={`
          ${image2x_png} 2x,
          ${image1x_png}
        `}
        type="image/png"
      />
      <Image src={image1x_png} alt="" />
    </picture>
  </Container>
);

export default memo(SidePicture);
