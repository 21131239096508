import styled, { css } from 'styled-components';

import BaseModal, {
  Title as BaseTitle,
  Text as BaseText,
} from 'components/Modal';

export const Shadow = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  bottom: 6rem;
  left: 0;
  height: 1.25rem;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.grey600} 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
`;

export const Terms = styled.div``;

export const Modal = styled(BaseModal)`
  .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 36.25rem;
        width: 36.25rem;
        position: relative;
        ${Shadow} {
          display: block;
        }
        ${Terms} {
          height: 25rem;
          overflow-y: scroll;
        }
      `};
  }
`;

export const Text = styled(BaseText)`
  margin-bottom: 1.25rem;
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const ContactUs = styled.a`
  color: ${({ theme }) => theme.colors.dark};
`;

export const Divider = styled.hr`
  border: none;
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  margin-bottom: 1.1875rem;
`;

export const ModalTitle = styled(BaseTitle)`
  margin-bottom: 2rem;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;
