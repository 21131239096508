import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Formik, Field } from 'formik';
import { useState, useEffect, useMemo, useCallback } from 'react';

import { validationRequestSchema } from 'components/Account/constants';
import Group from 'components/Account/Modals/TradeTicketRequestModal/Group';
import { Form, Title } from 'components/Account/styles';
import Input, { InputGroup, InputError } from 'components/Input';
import Modal from 'components/NewModal';
import Select from 'components/Select';
import {
  AccountType,
  AccountTypeType,
} from 'context/fastContext/account/types';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useTradeTicketActions from 'context/fastContext/tradeticket/actions';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';

function TradeTicketRequestModal({
  accounts,
  accountId,
  accountTypes,
  actionType,
  setAccountInRequest,
  hasErrorImplementingNonCustodian,
}: {
  accountId: string | null;
  accounts: AccountType[];
  accountTypes: AccountTypeType[];
  actionType: any;
  setAccountInRequest: any;
  hasErrorImplementingNonCustodian: boolean;
}) {
  const { createTradeTicketRequest, fetchTradeTicket, fetchTicketHistory } =
    useTradeTicketActions();

  const [{ tradeTicket }] = useTradeTicketStore((s: TradeTicketState) => ({
    tradeTicket: s.tradeTicket,
  }));

  const close = useCallback(() => {
    setAccountInRequest(null);
  }, [setAccountInRequest]);

  const accountInRequest = useMemo(
    () => accounts.find((acc) => acc.id === accountId),
    [accountId, accounts],
  );

  const accountType = accountTypes.find(
    (at) => at.id === accountInRequest?.accountType,
  );

  useEffect(() => {
    if (accountInRequest?.id) {
      fetchTradeTicket({ accountId: accountInRequest.id });
    }
  }, [accountInRequest, fetchTradeTicket]);

  const initialValues = {
    accountName: accountInRequest?.name || '',
    accountType: accountType?.name || '',
    accountNumber: tradeTicket?.accountNumber || '',
    requestFrequency: '',
    requestValue: '',
    additionalInstructions: '',
    requestDetails: '',
    additionalEmail: '',
  };

  const [{ allProposals, assetClassGroups }] = useProposalStore((s: any) => ({
    allProposals: s.allProposals,
    assetClassGroups: s.assetClassGroups,
  }));

  const [showConfirmation, setShowConfirmation] = useState(false);

  const associatedProposal = allProposals.find(
    (p: any) => p.id === accountInRequest?.proposalId,
  );

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    if (!showConfirmation) {
      setShowConfirmation(true);
    } else {
      const allValues = {
        ...values,
        action_type: actionType,
      };
      createTradeTicketRequest({
        accountId: accountInRequest?.id || '',
        createdTradeTicketRequest: allValues,
        tradeTicketId: tradeTicket?.id || '',
      });
      setSubmitting(false);
      setShowConfirmation(false);
      fetchTicketHistory({ useCache: false, isUpdating: true });
      close();
    }
  };

  return (
    <Modal
      sx={{
        maxWidth: 'initial',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 24px)',
      }}
      close={close}
      isOpen={!!accountInRequest}
      transitionSpeed="0.5s"
    >
      <Box style={{ width: '506px' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationRequestSchema(actionType)}
          onSubmit={handleSubmit}
          enableReinitialize // Async data loading
        >
          {({
            resetForm,
            values,
            isSubmitting,
            setSubmitting,
            errors,
            touched,
            dirty,
          }) => (
            <Form>
              <Box>
                <Box>
                  <Title>Account Information</Title>
                  <Box>
                    <InputGroup>
                      <Field
                        as={Input}
                        name="accountName"
                        placeholder="Account Name"
                        autoComplete="account-name"
                        disabled
                      />
                      <InputError name="accountName" />
                    </InputGroup>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.875rem',
                      }}
                    >
                      <InputGroup style={{ flex: '1' }}>
                        <Field
                          as={Input}
                          name="accountType"
                          placeholder="Type"
                          autoComplete="account-type"
                          disabled
                        />
                        <InputError name="accountType" />
                      </InputGroup>
                      <InputGroup style={{ flex: '1' }}>
                        <Field
                          as={Input}
                          name="accountNumber"
                          placeholder="Account # (last 4 digits)"
                          autoComplete="account-number"
                          disabled
                        />
                        <InputError name="accountNumber" />
                      </InputGroup>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Title>Product Allocation</Title>
                  <Box
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.grey[100]}`,
                    })}
                  >
                    {assetClassGroups.map((acg: any) => {
                      const assetGroupKey = acg.symbol.toLowerCase();
                      return (
                        <Group
                          key={assetGroupKey}
                          assetClassGroup={acg}
                          associatedProposal={associatedProposal}
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Box sx={{ marginTop: '0.875rem' }}>
                  <Title>{`${actionType}${
                    actionType ? ' ' : ''
                  }Request`}</Title>
                  {actionType === 'Cash Raise' ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.875rem',
                        }}
                      >
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Select}
                            name="requestFrequency"
                            disabled={isSubmitting}
                          >
                            <option value="">Frequency</option>
                            <option value="monthly">Monthly</option>
                            <option value="one-time">One Time</option>
                          </Field>
                          <InputError name="requestFrequency" />
                        </InputGroup>
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="requestValue"
                            placeholder={
                              values.requestFrequency === 'monthly'
                                ? '$ Value Per Month'
                                : '$ Value'
                            }
                            autoComplete="request-value"
                            error={
                              !!errors.requestValue && !!touched.requestValue
                            }
                            disabled={isSubmitting}
                          />
                          <InputError name="requestValue" />
                        </InputGroup>
                      </Box>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="additionalInstructions"
                          placeholder="Additional Instructions (Optional)"
                          autoComplete="additional-instructions"
                          error={
                            !!errors.additionalInstructions &&
                            !!touched.additionalInstructions
                          }
                          disabled={isSubmitting}
                        />
                        <InputError name="taxTransitionInstructions" />
                      </InputGroup>
                      <Title>Additional Email</Title>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="additionalEmail"
                          placeholder="Additional Email CC"
                          autoComplete="additional-email"
                          error={
                            !!errors.additionalEmail &&
                            !!touched.additionalEmail
                          }
                          disabled={isSubmitting}
                        />
                        <InputError name="additionalEmail" />
                      </InputGroup>
                    </>
                  ) : (
                    <Box>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="requestDetails"
                          placeholder="Request Details"
                          autoComplete="request-details"
                          error={
                            !!errors.requestDetails && !!touched.requestDetails
                          }
                          disabled={isSubmitting}
                        />
                        <InputError name="requestDetails" />
                      </InputGroup>
                      <Title>Additional Email</Title>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="additionalEmail"
                          placeholder="Additional Email CC"
                          autoComplete="additional-email"
                          error={
                            !!errors.additionalEmail &&
                            !!touched.additionalEmail
                          }
                          disabled={isSubmitting}
                        />
                        <InputError name="additionalEmail" />
                      </InputGroup>
                    </Box>
                  )}
                </Box>
              </Box>
              {!showConfirmation && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'initial',
                      minWidth: '120px',
                    }}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !dirty}
                  >
                    Submit
                  </Button>
                  <Button
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'initial',
                      minWidth: '120px',
                    }}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      resetForm();
                      close();
                      setSubmitting(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
              {showConfirmation && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '12px',
                    backgroundColor: 'primary.50',
                    gap: '30px',
                    borderRadius: '10px',
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: '0.75rem' }}>
                      By confirming your submission, you are <br />
                      instructing Seeds to make changes to this <br />
                      account according to your instructions.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '12px' }}>
                    <Button
                      sx={{ fontWeight: 'bold', textTransform: 'initial' }}
                      variant="contained"
                      type="button"
                      onClick={() => handleSubmit(values, { setSubmitting })}
                    >
                      Confirm
                    </Button>
                    <Button
                      sx={{ fontWeight: 'bold', textTransform: 'initial' }}
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        setShowConfirmation(false);
                        setSubmitting(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
              {hasErrorImplementingNonCustodian && (
                <Alert sx={{ marginTop: '8px' }} severity="error">
                  Error creating a request. Please try again and if the error
                  persists contact support.
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default TradeTicketRequestModal;
