import { Select, SelectProps } from '@mui/material';
import styled, { css } from 'styled-components';

type StyledSelectProps = SelectProps & {
  small?: boolean;
  large?: boolean;
};

export const StyledSelect = styled(Select)<StyledSelectProps>`
  font-family: 'Greycliff CF', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;

  border-radius: 10px !important;

  ${(props: any) =>
    props.large &&
    css`
      font-size: 20px !important;
    `}

  ${(props: any) =>
    props.small &&
    css`
      font-size: 12px !important;
      line-height: 12px !important;
      width: 100px;
    `}
`;
