import { useRef, useState, useCallback } from 'react';

import PrintRequest from 'components/Account/Print/PrintRequest';
import PrintTradeTicket from 'components/Account/Print/PrintTradeTicket';
import Print from 'components/Print';
import SidebarActionButtons from 'components/SidebarActionButtons';
import Proposal from 'containers/Main/Proposal';

export function SidebarActionContainer() {
  const [component, setComponentToPrint] = useState('proposal');
  const [requestId, setRequestId] = useState(null);
  const proposalRef = useRef(null);
  const printRequestRef = useRef(null);
  const printTradeTicketRef = useRef(null);

  const handleSetPrintRequest = useCallback((id) => {
    setRequestId(id);
  }, []);

  return (
    <>
      <Proposal ref={proposalRef} />
      <PrintRequest ref={printRequestRef} requestId={requestId} />
      <PrintTradeTicket ref={printTradeTicketRef} />
      <Print
        content={() => {
          switch (component) {
            case 'proposal':
              return proposalRef.current;
            case 'ticketRequest':
              return printRequestRef.current;
            case 'tradeTicket':
              return printTradeTicketRef.current;
            default:
              return null;
          }
        }}
      >
        {({ triggerPrint, loading }) => (
          <SidebarActionButtons
            triggerPrint={triggerPrint}
            loading={loading}
            setComponentToPrint={setComponentToPrint}
            setPrintRequest={handleSetPrintRequest}
          />
        )}
      </Print>
    </>
  );
}
