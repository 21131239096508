import styled from 'styled-components';

import { VisuallyHidden } from 'styles/mixins';

import { StyledProps } from './types';

export const Container = styled.div`
  display: inline-block;
`;

export const Hidden = styled.input.attrs((props) => ({
  type: 'checkbox',
  ...props,
}))`
  ${VisuallyHidden}
`;

export const Styled = styled.label<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem ${({ theme }) => theme.colors.grey400};
  background-color: ${({ theme }) => theme.colors.white};

  :hover {
    border: solid 0.0625rem ${({ theme }) => theme.colors.primary300};
  }

  svg {
    opacity: 0;
  }

  ${Hidden}:focus + & {
    border: solid 0.125rem ${({ theme }) => theme.colors.primary};
  }

  ${Hidden}:checked + & {
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
    svg {
      opacity: 1;
    }
  }

  cursor: pointer;
`;
