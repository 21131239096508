import {
  Card,
  Container,
  Content,
  Illustration,
  Link,
  Logo,
  Text,
  Wrapper,
} from '../styles';

const Success = () => (
  <Container>
    <Card>
      <Illustration />
      <Wrapper>
        <Content>
          <Logo size="medium-extra" />
          <Text>You have changed your password successfully!</Text>
          <Text>
            <Link to="/login" className="return">
              Return to Sign In
            </Link>
          </Text>
        </Content>
      </Wrapper>
    </Card>
  </Container>
);

export default Success;
