import styled, { css } from 'styled-components';

import { ReactComponent as DragHandlerIcon } from 'assets/svg/drag-handler.svg';

export const DragHandler = styled(DragHandlerIcon)`
  fill: ${({ theme }) => theme.colors.grey400};
  display: none;
  margin-right: 6px;
`;

export const Numbering = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.875rem;
  font-variant-numeric: tabular-nums;
  margin-right: 6px;
`;

const hoverAndDragginState = css`
  background-color: ${({ theme }) => theme.colors.grey0};
  align-items: center;

  ${DragHandler} {
    display: initial;
  }

  ${Numbering} {
    display: none;
  }
`;

export const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.1rem;
  border-radius: 0.5rem;
  user-select: none;

  &:hover {
    ${hoverAndDragginState};
  }

  ${({ isDragging }) => isDragging && hoverAndDragginState};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.grey700};
  font-size: 0.875rem;
  margin-left: 0.625rem;
`;
