/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Formik, Field } from 'formik';
import { forwardRef, useMemo, useEffect } from 'react';

import Group from 'components/Account/Implement/Group';
import { Form, Title } from 'components/Account/styles';
import Input, { InputGroup, InputError } from 'components/Input';
import Select from 'components/Select';
import ToggleSwitch from 'components/ToggleSwitch';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import type { AccountState } from 'context/fastContext/account/types';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';
import normalizeTime from 'utils/normalizeTime';

import { tradeInstructionOptions } from '../Implement/constants';
import { Hidden, Page } from './styles';

const PrintTradeTicket = forwardRef<HTMLDivElement>((_, ref) => {
  const [{ accounts, accountCustodians }] = useAccountStore(
    (s: AccountState) => ({
      accounts: s.accounts,
      accountCustodians: s.accountCustodians,
    }),
  );

  const [{ tradeTicket, ticketHistory }] = useTradeTicketStore(
    (s: TradeTicketState) => ({
      tradeTicket: s.tradeTicket,
      ticketHistory: s.ticketHistory,
    }),
  );

  const [{ allProposals, assetClassGroups }] = useProposalStore((s: any) => ({
    allProposals: s.allProposals,
    assetClassGroups: s.assetClassGroups,
  }));

  const selectedTradeTicket = ticketHistory.find(
    (th) => th.id === tradeTicket.id,
  );

  const associatedProposal = allProposals.find(
    (p: any) => p.id === selectedTradeTicket?.proposalId,
  );

  const accountToImplement = useMemo(
    () => accounts.find((acc) => acc.id === selectedTradeTicket?.accountId),
    [selectedTradeTicket?.accountId, accounts],
  );

  const custodianType = accountCustodians.find(
    (cus) => cus.id === accountToImplement?.accountCustodian,
  );

  const formattedAccountValue = useMemo(() => {
    const formattedNumber =
      Number(accountToImplement?.amountInvestedDollar).toLocaleString() || '0';
    return `$${formattedNumber === '0' ? '' : formattedNumber}`;
  }, [accountToImplement?.amountInvestedDollar]);

  const hasInsructions = useMemo(
    () => (tradeTicket?.instructionsList?.length || 0) > 0,
    [tradeTicket?.instructionsList],
  );

  const initialValues = {
    accountName: selectedTradeTicket?.accountName || '',
    accountValue: accountToImplement?.amountInvestedDollar || 0,
    accountType: selectedTradeTicket?.accountType || '',
    accountCustodian: custodianType?.name || '',
    accountNumber: tradeTicket?.accountNumber || '',
    taxLossHarvesting: tradeTicket?.taxLossHarvesting || false,
    taxTransition: tradeTicket?.taxTransition?.toLowerCase(),
    capitalGainsBudget: tradeTicket?.capitalGainsBudget || '',
    taxTransitionYears: tradeTicket?.taxTransitionYears || '',
    taxTransitionInstructions: tradeTicket?.taxTransitionInstructions || '',
    initialSetAside: tradeTicket?.initialSetAside || '',
    monthlyCashRaise: tradeTicket?.monthlyCashRaise || '',
    instructionsList: tradeTicket?.instructionsList
      ? (tradeTicket?.instructionsList).map((inst) => ({
          instructionType: inst.instructionType || '',
          instructions: inst.instructions || '',
        }))
      : [{ instructionType: '', instructions: '' }],
  };

  return (
    <Hidden>
      <Box style={{ width: '100%' }} ref={ref}>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          enableReinitialize // Async data loading
        >
          {() => (
            <Form>
              <Page>
                <Box>
                  <Box>
                    <Title>
                      {normalizeTime(selectedTradeTicket?.timestamp || '')}
                    </Title>
                    <Title>Account Information</Title>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.875rem',
                        }}
                      >
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountName"
                            placeholder="Account Name"
                          />
                          <InputError name="accountName" />
                        </InputGroup>
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountValue"
                            placeholder="Account Value"
                            value={formattedAccountValue}
                          />
                          <InputError name="accountValue" />
                        </InputGroup>
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountNumber"
                            placeholder="Account # (last 4 digits)"
                          />
                          <InputError name="accountNumber" />
                        </InputGroup>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.875rem',
                        }}
                      >
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountType"
                            placeholder="Type"
                          />
                          <InputError name="accountTyp" />
                        </InputGroup>
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountCustodian"
                            placeholder="Custodian"
                          />
                          <InputError name="accountCustodian" />
                        </InputGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Title>Product Allocation</Title>
                    <Box
                      sx={(theme) => ({
                        border: `1px solid ${theme.palette.grey[100]}`,
                      })}
                    >
                      {assetClassGroups.map((acg: any) => {
                        const assetGroupKey = acg.symbol.toLowerCase();
                        return (
                          <Group
                            key={assetGroupKey}
                            assetClassGroup={acg}
                            associatedProposal={associatedProposal}
                            accountValue={
                              accountToImplement?.amountInvestedDollar || 0
                            }
                            isCustodian={false}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '16px',
                      }}
                    >
                      <Title style={{ flex: '1' }}>Tax Loss Harvesting</Title>
                      <InputGroup style={{ marginTop: '-8px' }}>
                        <ToggleSwitch
                          onChange={(e) => e.preventDefault()}
                          name="taxLossHarvesting"
                          size="medium"
                          checked={!!tradeTicket?.taxLossHarvesting}
                        />
                      </InputGroup>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'top',
                      gap: '0.875rem',
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Box>
                        <Title
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            columnGap: '0.5rem',
                          }}
                        >
                          Tax Transition
                        </Title>
                        <InputGroup>
                          <Field as={Select} name="taxTransition">
                            <option value="">Select...</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </Field>
                          <InputError name="taxTransition" />
                        </InputGroup>
                      </Box>
                      {tradeTicket?.taxTransition === 'Yes' && (
                        <>
                          <InputGroup>
                            <Field
                              as={Input}
                              name="capitalGainsBudget"
                              placeholder="Capital Gains Budget (per year)"
                            />
                            <InputError name="capitalGainsBudget" />
                          </InputGroup>
                          <InputGroup>
                            <Field as={Select} name="taxTransitionYears">
                              <option value=""># Of Tax Years</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </Field>
                            <InputError name="taxTransitionYears" />
                          </InputGroup>
                          <InputGroup>
                            <Field
                              as={Input}
                              name="taxTransitionInstructions"
                              placeholder="Additional Instructions (Optional)"
                            />
                            <InputError name="taxTransitionInstructions" />
                          </InputGroup>
                        </>
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Title>Cash Management</Title>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="initialSetAside"
                          placeholder="Initial Set Aside (Optional)"
                        />
                        <InputError name="initialSetAside" />
                      </InputGroup>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="monthlyCashRaise"
                          placeholder="Monthly Cash Raise"
                          autoComplete="monthly-cash-raise"
                        />
                        <InputError name="monthlyCashRaise" />
                      </InputGroup>
                    </Box>
                  </Box>
                </Box>
              </Page>
              {hasInsructions && (
                <Page>
                  <Box>
                    <Box>
                      <Title>Trade Instructions</Title>
                      <>
                        {(tradeTicket?.instructionsList || []).map(
                          (item: any, index: number) => {
                            return (
                              <div key={item.id || index}>
                                <InputGroup>
                                  <Field
                                    as={Select}
                                    name={`instructionsList[${index}].instructionType`}
                                  >
                                    {tradeInstructionOptions.map(
                                      ({ name, value }) => (
                                        <option key={name} value={value}>
                                          {name}
                                        </option>
                                      ),
                                    )}
                                  </Field>
                                  <InputError
                                    name={`instructionsList[${index}].instructionType`}
                                  />
                                </InputGroup>
                                <InputGroup>
                                  <TextField
                                    variant="outlined"
                                    value={item.instructions || 'N/A'}
                                  />
                                  <InputError
                                    name={`instructionsList[${index}].instructions`}
                                  />
                                </InputGroup>
                              </div>
                            );
                          },
                        )}
                      </>
                    </Box>
                  </Box>
                </Page>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Hidden>
  );
});

export default PrintTradeTicket;
