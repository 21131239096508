import styled from 'styled-components';

import { VisuallyHidden } from 'styles/mixins';

export const Container = styled.div``;

export const Option = styled.label`
  cursor: pointer;
`;

export const PseudoRadio = styled.div`
  border-radius: 50%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey400};
  height: 1rem;
  width: 1rem;
  display: inline-block;
`;

export const HiddenRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  ${VisuallyHidden}

  &:checked +   ${PseudoRadio} , &:focus +   ${PseudoRadio} {
    border: 0.3125rem solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-left: 1rem;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 0.4375rem;
  padding-top: 0.4375rem;
`;
