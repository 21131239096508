import { FC } from 'react';

import HoldingsSlider from 'v2/components/HoldingsContainer/Slider';

import { Container, Column } from './styles';
import { Props } from './types';

const HoldingRow: FC<Props> = ({ name, percentage, score, sector }) => {
  const hasScore = score != null;
  return (
    <Container hasScore={hasScore}>
      <Column>{name}</Column>
      <Column>{percentage}%</Column>
      {score && score > 0 ? (
        <Column $clip={false}>
          <HoldingsSlider
            score={score || 0}
            type="value"
            displayHover={false}
          />
        </Column>
      ) : (
        <Column>No Score</Column>
      )}
      <Column>{sector}</Column>
    </Container>
  );
};

export default HoldingRow;
