import { FC, useMemo } from 'react';
import {
  BarChart as RechartBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import theme from 'styles/v2/theme';

import CustomTooltip from './Tooltip';
import { Props } from './types';

type CustomTickProps = {
  x: number;
  y: number;
  payload: {
    value: string;
  };
  value: any;
  width: number;
};

const BarChart: FC<Props> = ({ data, maxValue, printable = false }) => {
  const filteredData = useMemo(() => {
    if (!data) return null;
    return [...data].sort((a: any, b: any) => a.title.localeCompare(b.title));
  }, [data]);

  if (!filteredData?.length) return null;

  const abbreviateTitle = (title: string) => {
    const words = title.split(' ');
    if (words.length === 2) {
      let firstWord = words[0];
      firstWord =
        firstWord.length > 6 ? `${firstWord.substring(0, 3)}.` : firstWord;
      return `${firstWord} ${words[1]}`;
    }
    return title;
  };

  const renderCustomAxisTick = ({ x, y, payload }: CustomTickProps) => {
    return (
      <text
        x={x - (printable ? 100 : 120)}
        y={y}
        textAnchor="start"
        dy={4}
        fontSize={printable ? '.625rem' : '0.75rem'}
        fill={theme.palette.common.black}
      >
        {abbreviateTitle(payload.value).toUpperCase()}
      </text>
    );
  };

  const renderCustomBarLabel = ({ x, y, width, value }: CustomTickProps) => {
    const formattedValue = value.toFixed(2);
    return (
      <text
        x={printable ? x + width + 15 : x + width / 2}
        y={y + 9}
        fill={printable ? theme.palette.grey[900] : theme.palette.common.white}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="0.725rem"
        fontWeight={600}
      >
        {formattedValue}
      </text>
    );
  };

  return (
    <RechartBarChart
      width={400}
      height={300}
      data={filteredData}
      layout="vertical"
      margin={{ top: 5, right: 0, left: printable ? 50 : 70, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="1 3" />
      <XAxis
        type="number"
        ticks={[]}
        tick={false}
        axisLine={false}
        domain={[0, (printable && maxValue ? maxValue + 6 : maxValue) ?? 0]}
      />
      <YAxis dataKey="title" type="category" tick={renderCustomAxisTick} />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
      <Bar
        dataKey="value"
        fill={theme.palette.primary.main}
        radius={20}
        isAnimationActive={false}
        label={printable ? renderCustomBarLabel : ''}
      />
    </RechartBarChart>
  );
};

export default BarChart;
