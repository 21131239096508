import { useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { LogicProps } from './types';

const radian = Math.PI / 180;

// const numerically = (direction: 'asc' | 'desc') => {
//   if (direction === 'asc') {
//     return (a: number, b: number) => a - b;
//   }
//   return (a: number, b: number) => b - a;
// };

const useLogic = ({ innerData }: LogicProps) => {
  const theme = useContext(ThemeContext);
  const riskScore = innerData[0]?.value || undefined;

  const renderCustomizedLabel = useCallback(
    (props: any) => {
      const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
      const x = cx + radius * Math.cos(-midAngle * radian);
      const y = cy + radius * Math.sin(-midAngle * radian);

      return (
        <text
          x={x}
          y={percent === 1 ? cy : y}
          fill={theme.colors.white}
          textAnchor="middle"
          dominantBaseline="central"
          fontFamily="GreyCliff CF"
          fontSize="2rem"
          fontWeight={theme.weights.bold}
          style={{ pointerEvents: 'none' }}
        >
          {riskScore}
        </text>
      );
    },
    [riskScore, theme.colors.white, theme.weights.bold],
  );

  // const renderIconLabel = useCallback(
  //   (props: any) => {
  //     const { cx, cy, midAngle, innerRadius, outerRadius, index } = props;
  //     const { breakdown } = outerData[index];

  //     if (breakdown.value < 7) return null;

  //     if (label?.icon) {
  //       const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //       const x =
  //         cx - label.width * 0.5 + radius * Math.cos(-midAngle * radian);
  //       const y =
  //         cy - label.height * 0.5 + radius * Math.sin(-midAngle * radian);
  //       const Icon = label?.icon as FC<SVGAttributes<SVGElement>>;
  //       return (
  //         <Icon
  //           x={x}
  //           y={y}
  //           style={{
  //             pointerEvents: 'none',
  //             color: label?.color,
  //           }}
  //         />
  //       );
  //     }
  //     return null;
  //   },
  //   [outerData],
  // );

  return {
    handle: {
      renderCustomizedLabel,
      // renderIconLabel,
    },
  };
};

export default useLogic;
