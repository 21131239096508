// import { DataViewContainerTitleSwitch } from 'components/DataViewContainerTitleSwitch';
// import ClientsEmpty from 'components/TableEmptyState/ClientsEmpty';
// import DataViewContainer from 'containers/DataViewContainer';

// import { useConnect } from './connect';
import InvestorsTable from './InvestorsTable';

const ClientsRoot = () => {
  // const {
  //   advisorSearchParam,
  //   handleSearch,
  //   handleSort,
  //   investors,
  //   itemsShown,
  //   pagination,
  //   schema,
  //   sortedBy,
  //   loading,
  // } = useConnect();
  return (
    <>
      <InvestorsTable />
      {/* <DataViewContainer
        data={investors}
        empty={<ClientsEmpty />}
        initialSearch={advisorSearchParam || undefined}
        itemsShown={itemsShown}
        onSearch={handleSearch}
        onSort={handleSort}
        pagination={pagination}
        recipientRole="INVESTOR"
        schema={schema}
        title={<DataViewContainerTitleSwitch title="Clients" />}
        sortedBy={sortedBy}
        loading={loading.investors}
      /> */}
    </>
  );
};

export default ClientsRoot;
