import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  column-gap: 1.5rem;

  @media print {
    column-gap: 0.75rem;
    grid-template-columns: 2rem 1fr;
    > svg {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  > a {
    text-decoration: none;
  }
`;

export const Industry = styled.span`
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.0625rem;
  color: ${({ theme }) => theme.colors.grey600};

  @media print {
    font-size: 0.5rem;
  }
`;

export const Name = styled.h4`
  font-size: 1.125rem;
  line-height: 1.44;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.dark};
  margin-block: 0.125rem 0.375rem;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  @media print {
    font-size: 0.9rem;
    line-height: 1.24;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.375;
  color: ${({ theme }) => theme.colors.dark};

  @media print {
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;
