import Button from 'components/Button';
import { CheckIcon } from 'components/SteppedProgressBar/styles';

import useConnect from './connect';
import { Modal, ActionButtons } from './styles';

const NewFeature = () => {
  const { isMobile, isOpen, releaseNotesContent, handleClose, role } =
    useConnect();

  if (['INVESTOR', 'UNKNOWN'].includes(role || '')) {
    return <></>;
  }

  return (
    <Modal fullScreen={isMobile} onClose={handleClose} open={isOpen}>
      <div
        className="content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: releaseNotesContent }}
      />
      <ActionButtons>
        <Button
          icon={<CheckIcon />}
          onClick={handleClose}
          variant="primary"
          data-testid="accept-terms"
        >
          Close
        </Button>
      </ActionButtons>
    </Modal>
  );
};

export default NewFeature;
