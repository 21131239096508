import { useEffect, useState } from 'react';

import keysOf from 'utils/keysOf';

const sizes = {
  mobile: 550,
  tablet: 768,
  desktop: 992,
  containerMaxWidth: 1229,
  largeScreen: 1441,
} as const;

const minWidthQuery = (width: number) => `@media (min-width: ${width}px)`;

export const from = keysOf(sizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: minWidthQuery(sizes[key]),
  }),
  {} as { [key in keyof typeof sizes]: string },
);

export const useMediaQuery = (query: string): boolean => {
  const mediaQuery = query.replace('@media', '').trim();

  const [match, setMatch] = useState(() =>
    window.matchMedia ? window.matchMedia(mediaQuery).matches : false,
  );

  useEffect(() => {
    if (!window.matchMedia) return () => {};

    const mediaQueryList = window.matchMedia(mediaQuery);

    const handleChange = (event: MediaQueryListEvent) =>
      setMatch(event.matches);

    mediaQueryList.addEventListener('change', handleChange);

    return () => mediaQueryList.removeEventListener('change', handleChange);
  }, [mediaQuery]);

  return match;
};
