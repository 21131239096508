import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import theme from 'styles/v2/theme';

const ToggleSwitch = styled(Switch)(() => ({
  // Unchecked
  '& .MuiSwitch-thumb': {
    color: theme.palette.grey[500],
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[300],
  },
  '& .Mui-disabled .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[300],
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 3px 0px rgba(0,0,0,0.02)',
  },
  // Checked
  '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  '& .Mui-checked.Mui-disabled .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary[200],
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary[100],
  },
}));

export default ToggleSwitch;
