import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 18.3125rem;
  justify-content: center;
  width: 100%;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-top: 0.75rem;
  text-align: center;
`;
