import toSnakeCase from 'lodash/snakeCase';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { SortCallback } from 'components/Table/types';
import useSearchParams from 'utils/useSearchParams';

import { URL_PARAM_KEYS } from './constants';

const useAsyncSort = (urlMemorize?: boolean) => {
  const params = useSearchParams();
  const history = useHistory();
  const orderByValue = params.get(URL_PARAM_KEYS.orderBy) || '';
  const orderDirectionValue = params.get(URL_PARAM_KEYS.ordering) || '';
  // set initial values in case order value is present
  const initialSortState = orderByValue
    ? {
        field: orderByValue,
        ordering: orderDirectionValue || 'ASC',
      }
    : undefined;
  // NOTE: initially should be undefined
  const [sortBy, setSortBy] = useState<
    { field: string; ordering: 'ASC' | 'DESC' } | any
  >(initialSortState);

  const onSort: SortCallback = useCallback(
    ({ column, direction }) => {
      const causedField = toSnakeCase(
        column
          // TODO: FO - Make sure this doesn't causes issues on some other table sorting...
          // .replace(/surveyResult/g, '')
          .replace(/total/g, '')
          .replace(/input/g, 'status'),
      ).replace(/first_/g, '');
      const orderingDirection = direction.toUpperCase() as 'ASC' | 'DESC';
      setSortBy({
        field: causedField,
        ordering: orderingDirection,
      });
      if (urlMemorize) {
        params.set(URL_PARAM_KEYS.orderBy, causedField);
        params.set(URL_PARAM_KEYS.ordering, orderingDirection);
        history.push({
          search: params.toString(),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlMemorize],
  );

  return [sortBy, onSort, setSortBy] as const;
};

export default useAsyncSort;
