import { nanoid } from 'nanoid';
import { FC, useRef, useEffect, useMemo, ReactNode } from 'react';

import { AssetResultPlaceholder } from '../AssetResult';
import { Props } from './types';

const Placeholder: FC<Props> = ({ children, realItemsLength, show }) => {
  const previousNumberOfRows = useRef<number>(10);

  useEffect(() => {
    previousNumberOfRows.current = realItemsLength;
  }, [realItemsLength]);

  const assetResultsPlaceholder: ReactNode[] = useMemo(
    () =>
      Array.from(Array(previousNumberOfRows.current || 10).keys()).map(() => (
        <AssetResultPlaceholder key={nanoid()} />
      )),
    [],
  );

  if (show) {
    return <>{assetResultsPlaceholder}</>;
  }

  return <>{children}</>;
};

export default Placeholder;
