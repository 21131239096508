import { isEqual } from 'lodash';
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useCallback,
} from 'react';

// TODO: Move this to an NPM module
export default function createFastContext<Store>(initialState: Store) {
  function useStoreData(): {
    get: () => Store;
    set: (value: Partial<Store>) => void;
    subscribe: (callback: () => void) => () => void;
  } {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: Partial<Store>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

  const StoreContext = createContext<UseStoreDataReturnType | null>(null);

  function Provider({ children }: { children: React.ReactNode }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  function useStore<SelectorOutput>(
    selector: (store: Store) => SelectorOutput,
  ): [SelectorOutput, (value: Partial<any>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Store not found');
    }

    // Initial state is set here
    const [selectedState, setSelectedState] = useState(() =>
      selector(store.get()),
    );

    useEffect(() => {
      const checkForUpdates = () => {
        const newSelectedState = selector(store.get());
        // Only update if the selected part of the state has changed
        if (!isEqual(newSelectedState, selectedState)) {
          setSelectedState(newSelectedState);
        }
      };

      // Subscribe for changes in the store
      return store.subscribe(checkForUpdates);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, selectedState]); // Make sure to include `selectedState` and `store` as dependencies

    return [selectedState, store.set];
  }

  return {
    Provider,
    useStore,
  };
}
