import { ReactComponent as FolderIcon } from 'assets/svg/files/folder.svg';

import { FolderWrapper } from './styles';
import { Props } from './type';

export default function Folder({ folder, onFolderClick }: Props) {
  const handleFolderClick = () => {
    onFolderClick(folder);
  };
  return (
    <FolderWrapper>
      <FolderIcon style={{ cursor: 'pointer' }} onClick={handleFolderClick} />
      {
        // eslint-disable-next-line
        <span onClick={handleFolderClick}>{folder.name}</span>
      }
    </FolderWrapper>
  );
}
