import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  NotificationsQuery,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
} from 'graphql/generated';
import { READ_NOTIFICATIONS } from 'graphql/mutations/notification';
import { NOTIFICATIONS } from 'graphql/queries/notification';
import { logger } from 'services/logger';

const useNotificationActions = () => {
  const { cache } = useApolloClient();
  const [readMutation] = useMutation<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >(READ_NOTIFICATIONS);

  const markAsRead = useCallback(async () => {
    try {
      await readMutation({
        fetchPolicy: 'no-cache',
        optimisticResponse: {
          readNotification: {
            __typename: 'ReadNotification',
            ok: true,
          },
        },
      });

      const results = cache.readQuery<NotificationsQuery>({
        query: NOTIFICATIONS,
      });

      if (results?.notifications) {
        cache.writeQuery<NotificationsQuery>({
          data: {
            notifications: results.notifications.map((notification) => ({
              ...notification,
              read: true,
            })),
          },
          query: NOTIFICATIONS,
        });
      }

      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }, [cache, readMutation]);

  return { markAsRead };
};

export default useNotificationActions;
