import DocIcon from 'components/DocIcon';

import { FileWrapper } from './styles';
import { Props } from './type';

export default function File({ file }: Props) {
  return (
    <FileWrapper>
      <a target="_blank" rel="noopener noreferrer" href={file.file}>
        <DocIcon fileType={file.mimeType} />
        {file.name.length > 0 ? (
          <span>{file.name}</span>
        ) : (
          <span>{file.originalFilename}</span>
        )}
      </a>
    </FileWrapper>
  );
}
