import { FC } from 'react';

import { ReactComponent as FileUpload } from 'assets/svg/fileUpload.svg';
import { ReactComponent as FileUploadInvalid } from 'assets/svg/fileUploadInvalid.svg';

import { Container, IconBox, Browse, Text } from './styles';
import { Props } from './types';

const Dropbox: FC<Props> = ({ rootProps, isDragActive, isDragReject }) => {
  const activeText = isDragReject ? 'Invalid file' : 'Drag files here...';

  return (
    <Container
      {...rootProps}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <IconBox isDragReject={isDragReject}>
        {isDragReject ? <FileUploadInvalid /> : <FileUpload />}
      </IconBox>
      {!isDragActive ? (
        <Text>
          Drag <span style={{ fontWeight: 700 }}>.csv</span> and{' '}
          <span style={{ fontWeight: 700 }}>.xlsx</span> files here or{' '}
          <Browse>browse</Browse>
        </Text>
      ) : (
        <Text>{activeText}</Text>
      )}
    </Container>
  );
};

export default Dropbox;
