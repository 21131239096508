import IconButton from '@mui/material/IconButton';
import styled, { css, keyframes } from 'styled-components';

import Card from 'components/Card';
import { from } from 'styles/media';
import { TextWrap } from 'styles/mixins';

export const Container = styled(Card).attrs(() => ({
  variant: 'borderless',
}))`
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 58.75rem;
  overflow: hidden;

  @media print {
    border-radius: 0;
  }
`;

const popOn = keyframes`
  from {
    transform: scale(.75);
  }
  to {
    transform: scale(1);
  }
`;

export const EmailButton = styled(IconButton)`
  svg[data-icon='check'] {
    animation: ${popOn} 0.2s ease-in-out;
  }
`;

export const HeaderTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  left: 1.5625rem;
  left: 1.5625rem;
  letter-spacing: 0;
  line-height: calc(44 / 32);
  position: absolute;
  top: 3.75rem;

  ${from.tablet} {
    left: 5rem;
  }
`;

export const HeaderAction = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Content = styled.div<{ $isMobile: boolean }>`
  padding: 3.75rem 1.5625rem 5.125rem 1.5625rem;

  ${from.tablet} {
    padding: 3.75rem 5rem 5.125rem 5rem;
  }

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 3.75rem 0.5rem 5.125rem 0.5rem;
        `
      : null}

  @media print {
    padding-top: 3rem;
  }
`;

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-bottom: 1.5rem;
`;

export const Block = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 0.625rem;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
`;

export const BlockParagraph = styled.p`
  line-height: calc(20 / 14);
  letter-spacing: 0;
  font-size: 0.875rem;
  font-family: 'Greycliff CF';
  color: ${({ theme }) => theme.colors.dark};

  & + & {
    margin-top: 0.75rem;
  }
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ResultTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: 1.125rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;

  ${TextWrap}
`;

export const GoalsResultsWrapper = styled.div`
  > * {
    margin-bottom: 1.125rem;
  }
`;

export const SectionContent = styled.div`
  ${from.tablet} {
    display: grid;
    grid-column-gap: 1.25rem;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Result = styled.div`
  margin-bottom: 1.5rem;
  @media print {
    break-inside: avoid;
  }
`;
