import styled from 'styled-components';

import ImpactSnapshot from 'components/ImpactSnapshot';
import { from } from 'styles/media';

export const DataNotAvailable = styled(ImpactSnapshot)``;

export const Description = styled.p`
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(26 / 16);
  margin: 0.5rem 0 1.875rem 0;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const ItemDescription = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  margin-top: 0.75rem;
`;

export const ModuleItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModuleItemsWrapper = styled.div`
  margin-top: 2.5rem;
  ${from.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const SectionModuleTitle = styled.h3`
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  line-height: calc(22 / 16);
  color: grey600;
`;

export const ValuesContainer = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
`;
