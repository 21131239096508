import type {
  AvailableProductGroupType,
  AssetClassProductType,
} from 'context/types/proposal';
import roundToTarget from 'utils/roundToTarget';

export const hasAssetClassSelections = (
  availableProducts: AvailableProductGroupType,
) => {
  let hasMultiple = false;
  Object.keys(availableProducts).forEach((pac) => {
    availableProducts[pac].forEach((k) => {
      const products: AssetClassProductType[] = k?.products;
      if (products) {
        products?.reduce((mem: any, cur: any) => {
          if (mem.includes(cur.assetClassId)) {
            hasMultiple = true;
          }

          mem.push(cur.assetClassId);
          return mem;
        }, []);
      }
    });
  });

  return hasMultiple;
};

export const validateAllocations = (selectedProductArray: any[]): boolean => {
  const totalSum = selectedProductArray.reduce((total, { allocation }) => {
    total += allocation;
    return total;
  }, 0);

  return totalSum === 100;
};

export const getDefaultSelectedGroups = (
  assetGroupsKeys: string[],
  availableProducts: AvailableProductGroupType,
  selectedProducts: { [key: string]: number },
) =>
  assetGroupsKeys.reduce((mem: { [key: string]: any }, cur: string) => {
    const assetProductGroup = availableProducts[cur];
    if (!assetProductGroup) return mem;
    let sg = assetProductGroup.find((apg) =>
      apg.products.some((p) => selectedProducts[p.id]),
    );

    // It's possible there is nothing selected in a specific group
    // (ie. Someone is 100% equities). So default to first.
    if (typeof sg === 'undefined') {
      [sg] = assetProductGroup;
    }

    mem[cur] = sg;
    return mem;
  }, {});

export const getMissingDefaultProductsToAdd = (
  assetGroupsKeys: string[],
  defaultSelectedGroups: any,
  assetClassGroups: any,
  combinedAllProducts: any,
  selectedProductArray: any,
  isValuesDriven: boolean,
) => {
  const assetsNeeded = assetGroupsKeys.reduce(
    (acc: any[], groupName: string) => {
      const selectedGroup = defaultSelectedGroups[groupName];
      if (!selectedGroup) return acc;
      const groupIsFixed = selectedGroup.isFixedAllocation;
      const groupAssetClass = assetClassGroups.find(
        (acg: any) => acg.symbol.toLowerCase() === groupName,
      );

      if (groupAssetClass) {
        if (groupIsFixed) {
          acc.push(groupAssetClass.id);
        } else {
          acc.push(...groupAssetClass.children.map((c: any) => c.id));
        }
      }
      return acc;
    },
    [],
  );

  const missingAssets = assetsNeeded.reduce((mem: any, cur: any) => {
    const assetIsSelected = selectedProductArray.find(
      (sp: any) => sp.assetClassId === cur,
    );

    if (!assetIsSelected) {
      mem.push(cur);
    }

    return mem;
  }, []);

  const missingDefaultProductsToAdd = missingAssets.reduce(
    (mem: any, assetClassId: any) => {
      const product =
        combinedAllProducts.find(
          (p: any) =>
            p.assetClassId === assetClassId && p.isValuePlay === isValuesDriven,
        ) ||
        combinedAllProducts.find((p: any) => p.assetClassId === assetClassId);
      mem[product.id] = {
        allocation: 0,
        assetClassId,
        id: product.id,
      };

      return mem;
    },
    {},
  );

  return missingDefaultProductsToAdd;
};

export const calculateRatio = ({
  totalValue,
  selectedGroupProducts,
}: {
  totalValue: number;
  selectedGroupProducts: any;
}) => {
  const numberOfProducts = selectedGroupProducts.length;

  const ratio = selectedGroupProducts.reduce((mem: any, cur: any) => {
    // if total value = 0 then set the ratio as equally split among the values
    if (totalValue === 0) {
      mem[cur.id] = 100 / numberOfProducts;
      // If the total value is greater than 0 and current allocation is 0 keep ratio to 0
    } else if (cur.allocation === 0) {
      mem[cur.id] = 0;
      // track ratio
    } else {
      mem[cur.id] = (100 * cur.allocation) / totalValue;
    }

    return mem;
  }, {});

  return ratio;
};

export const calculateAllocation = ({
  totalValue,
  selectedGroupProducts,
  ratios,
}: {
  totalValue: number;
  selectedGroupProducts: any;
  ratios: any;
}) => {
  const newSelections = selectedGroupProducts.reduce((mem: any, cur: any) => {
    const newSelection = { ...cur };
    const ratio = ratios[cur.id];
    if (ratio === 0) {
      newSelection.allocation = 0;
    } else {
      newSelection.allocation = Math.round((ratio * totalValue) / 100);
    }

    mem[cur.id] = newSelection;

    return mem;
  }, {});

  return roundToTarget(newSelections, totalValue, 'allocation');
};
