import { FC } from 'react';

import SidePicture from 'components/SidePicture';
import { from, useMediaQuery } from 'styles/media';

import { Button, Card, Container, Content, Logo, Text, Title } from './styles';
import { Props } from './types';

const ErrorPage: FC<Props> = ({ title, caption, button }) => {
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <Container>
      <Card>
        {!isMobile && <SidePicture />}
        <Content>
          <Logo size="medium" />
          <Title>{title}</Title>
          <Text>{caption}</Text>
          {button && <Button onClick={button.onClick}>{button.label}</Button>}
        </Content>
      </Card>
    </Container>
  );
};

export default ErrorPage;
