import { useState, createContext, useMemo, useCallback } from 'react';

import { FileType } from 'components/File/type';
import { FolderType } from 'components/Folder/type';

export const InsightsLibraryContext = createContext<{
  files: FileType[];
  folders: FolderType[];
  loading: boolean;
  error: any;
  fetchInvestorFiles: Function;
  fetchInvestorFolders: Function;
}>({
  files: [],
  folders: [],
  loading: true,
  error: null,
  fetchInvestorFiles: () => {},
  fetchInvestorFolders: () => {},
});

// Have this use fastContext when rewriting Household List
export const InsightsLibraryProvider = ({ children }: any) => {
  const [folders, setFolders] = useState<FolderType[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);
  // TODO: FO - Figure out better way to handle errors
  const [error, setError] = useState<any>(null);

  const fetchInvestorFiles = useCallback(
    async (investorId: string) => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}api/filer/files/${investorId}/`,
        );
        setLoading(false);
        const data = await response.json();
        if (data.status) {
          setFiles(data.payload);
        } else {
          setError(data.message);
        }
      } catch (e: any) {
        setLoading(false);
        setError(e.message);
      }
    },
    [setLoading, setFiles, setError],
  );

  const fetchInvestorFolders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}api/filer/folders/`,
      );
      setLoading(false);
      const data = await response.json();
      setFolders(data?.payload);
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }, [setLoading, setFolders, setError]);

  const Library = useMemo(
    () => ({
      fetchInvestorFiles,
      fetchInvestorFolders,
      files,
      folders,
      loading,
      error,
    }),
    [fetchInvestorFiles, fetchInvestorFolders, files, folders, loading, error],
  );

  return (
    <InsightsLibraryContext.Provider value={Library}>
      {children}
    </InsightsLibraryContext.Provider>
  );
};
