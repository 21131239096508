import styled, { css } from 'styled-components';

import { ReactComponent as ChevronDown } from 'assets/svg/chevronDown.svg';
import theme from 'styles/v2/theme';

import { ContainerProps, SelectorProps } from './types';

export const Container = styled.div<ContainerProps>`
  display: inline-flex;
  position: relative;

  ${({ $variant }) => {
    switch ($variant) {
      case 'rowElement':
        return css`
          height: 1.25rem;
        `;
      case 'default':
      default:
        return css`
          height: 2.875rem;
        `;
    }
  }};
`;

export const Icon = styled(ChevronDown)`
  position: absolute;
  pointer-events: none;
  color: ${theme.palette.grey[500]};
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const Selector = styled.select<SelectorProps>`
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  padding-left: 0.75rem;
  padding-right: 1.875rem;
  height: 100%;
  width: 100%;

  ${({ $error, variant }) => {
    switch (variant) {
      case 'rowElement':
        return css`
          border-radius: 0;
          background-color: transparent;
          border: none;
          border-right: solid 0.0625rem ${theme.palette.grey[100]};
          color: ${theme.palette.grey[500]};
          font-size: 0.875rem;
          line-height: calc(20 / 14);
          min-width: 5.1875rem;
        `;
      case 'default':
      default:
        return css`
          border-radius: 0.5rem;
          background-color: ${theme.palette.grey[50]};
          border: none;
          color: ${theme.palette.grey[900]};
          font-size: 0.875rem;
          min-width: 7.5625rem;
          outline: 0;

          &:disabled {
            color: ${theme.palette.grey[500]};
            cursor: not-allowed;
          }

          ${!$error
            ? css`
                &:hover,
                &:focus {
                  border-color: ${theme.palette.primary.main};
                }
              `
            : null}
        `;
    }
  }}

  ${({ $error }) =>
    $error
      ? css`
          border-color: ${theme.palette.warning.main};
        `
      : null}
`;
