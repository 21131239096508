import { nanoid } from 'nanoid';
import {
  createContext,
  useContext,
  FC,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';

import { SortCallback } from './types';
import { useSelection } from './useSelection';

type TableContextProps = {
  tableId: string;
  selectable: boolean;
  sortable: boolean;
  sortBy: ({
    column,
    direction,
  }: {
    column: string;
    direction: 'asc' | 'desc';
  }) => void;
  sortedBy: Sort;
} & Partial<ReturnType<typeof useSelection>[0]>;

type TableProviderProps = {
  data: { id: string }[];
  onSort?: SortCallback;
  children: ReactNode;
  sortedBy: Sort;
} & Partial<ReturnType<typeof useSelection>[0]>;

type Sort =
  | {
      column: string;
      direction: 'asc' | 'desc';
    }
  | undefined;

const TableContext = createContext<TableContextProps>({
  tableId: '',
  selectable: true,
  selectRow: () => null,
  selectRows: () => null,
  selectAll: () => null,
  selected: [],
  areAllSelected: false,
  sortable: true,
  sortBy: () => null,
  sortedBy: undefined,
});

export const useTable = () => useContext(TableContext);

const TableProvider: FC<TableProviderProps> = ({
  data,
  onSort,
  sortedBy,
  selected,
  selectAll,
  selectRow,
  selectRows,
  areAllSelected,
  children,
  ...props
}) => {
  const tableId = useMemo(
    () => (process.env.NODE_ENV === 'testing' ? 'tableId' : nanoid(4)),
    [],
  );

  const sortBy = useCallback(
    ({ column, direction }: { column: string; direction: 'asc' | 'desc' }) => {
      if (!onSort) return;
      onSort({ column, direction });
    },
    [onSort],
  );

  return (
    <TableContext.Provider
      value={{
        tableId,
        selectable: !!selected,
        sortable: !!onSort,
        selected,
        selectRow,
        selectRows,
        selectAll,
        areAllSelected,
        sortBy,
        sortedBy,
      }}
      {...props}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
