import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Field, FieldArray } from 'formik';

import Input, { InputGroup, InputError } from 'components/Input';
import Select from 'components/Select';

import { Container, InlineInput, Title, TradeContainer } from '../styles';
import { tradeInstructionOptions } from './constants';

interface TradeTicketInstructionType {
  instructionType: string;
  instructions: string;
}

function TradeInstructions({
  instructionsList,
  isSubmitting,
  setFieldValue,
  errors,
  touched,
}: {
  instructionsList: TradeTicketInstructionType[];
  isSubmitting: any;
  setFieldValue: any;
  errors: any;
  touched: any;
}) {
  return (
    <Container style={{ height: '100%' }}>
      <TradeContainer>
        <Box>
          <Title>Trade Instructions</Title>
          <FieldArray name="instructionsList">
            {({ push, remove }) => (
              <>
                {instructionsList.map((item: any, index: number) => {
                  return (
                    <div key={item.id || index}>
                      <InputGroup>
                        <Field
                          as={Select}
                          name={`instructionsList[${index}].instructionType`}
                          disabled={isSubmitting}
                          onChange={(e: any) => {
                            const { value } = e.target;
                            setFieldValue(
                              `instructionsList[${index}].instructionType`,
                              value,
                            );
                            if (value === '') {
                              setFieldValue(
                                `instructionsList[${index}].instructions`,
                                '',
                              );
                            }
                          }}
                        >
                          {tradeInstructionOptions.map(({ name, value }) => (
                            <option key={name} value={value}>
                              {name}
                            </option>
                          ))}
                        </Field>
                        <InputError
                          name={`instructionsList[${index}].instructionType`}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InlineInput>
                          <Field
                            as={Input}
                            name={`instructionsList[${index}].instructions`}
                            placeholder="Specify Instructions"
                            autoComplete="off"
                            disabled={isSubmitting || !item.instructionType}
                            error={
                              !!errors.instructionsList?.[index]
                                ?.specifyInstructions &&
                              !!touched.instructionsList?.[index]
                                ?.specifyInstructions
                            }
                          />
                          <IconButton
                            onClick={() => remove(index)}
                            sx={(theme) => ({
                              padding: '2px',
                              color: `${theme.palette.error.main}`,
                              margin: 0,
                              backgroundColor: 'rgba(211, 47, 47, 0.1)',
                              borderRadius: '6px',
                              '&:hover': {
                                backgroundColor: 'rgba(211, 47, 47, 0.2)',
                              },
                            })}
                          >
                            <CloseOutlinedIcon fontSize="small" />
                          </IconButton>
                        </InlineInput>
                        <InputError
                          name={`instructionsList[${index}].instructions`}
                        />
                      </InputGroup>
                    </div>
                  );
                })}
                {!isSubmitting && (
                  <Box
                    role="button"
                    onClick={() =>
                      push({ instructionType: '', instructions: '' })
                    }
                    sx={(theme) => ({
                      marginBottom: '14px',
                      fontSize: '14px',
                      padding: '12px',
                      borderRadius: '0.5rem',
                      border: `1px dashed ${theme.palette.grey[100]}`,
                      color: `${theme.palette.grey[400]}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        border: `1px dashed ${theme.palette.primary.main}`,
                        color: theme.palette.primary.main,
                      },
                    })}
                  >
                    <AddCircleOutlineRoundedIcon sx={{ fontSize: '20px' }} />
                    &nbsp;Add Instructions
                  </Box>
                )}
              </>
            )}
          </FieldArray>
        </Box>
        <Box>
          <Title>Additional Email</Title>
          <InputGroup>
            <Field
              as={Input}
              name="additionalEmail"
              placeholder="Additional Email CC"
              autoComplete="additional-email"
              error={!!errors.additionalEmail && !!touched.additionalEmail}
              disabled={isSubmitting}
            />
            <InputError name="additionalEmail" />
          </InputGroup>
        </Box>
      </TradeContainer>
    </Container>
  );
}

export default TradeInstructions;
