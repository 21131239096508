import type { FilterOptionType } from 'components/Filter/types';

export const ASSET_FILTER_OPTIONS: FilterOptionType = {
  Type: [
    { name: 'LARGE_CAP', label: 'Large Cap US' },
    { name: 'MID_CAP', label: 'Mid Cap US' },
    { name: 'SMALL_CAP', label: 'Small Cap US' },
    { name: 'INTERNATIONAL', label: 'Developed Ex-US' },
    { name: 'EMERGING_EX_US', label: 'Emerging' },
    { name: 'CORE_FIXED', label: 'Taxable Bonds' }, // TODO: Change name to match label
    { name: 'MUNI', label: 'Municipal Bonds' },
    { name: 'LIQUID_ALT', label: 'Alternatives' },
  ],
  Sector: [
    { name: 'MATERIALS', label: 'Materials' },
    {
      name: 'COMMUNICATION_SERVICES',
      label: 'Communication Services',
    },
    {
      name: 'CONSUMER_DISCRETIONARY',
      label: 'Consumer Discretionary',
    },
    { name: 'CONSUMER_STAPLES', label: 'Consumer Staples' },
    { name: 'ENERGY', label: 'Energy' },
    {
      name: 'FINANCIAL_SERVICES',
      label: 'Financials Services',
    },
    { name: 'HEALTHCARE', label: 'Healthcare' },
    { name: 'INDUSTRIALS', label: 'Industrials' },
    { name: 'TECHNOLOGY', label: 'Technology' },
    { name: 'UTILITIES', label: 'Utilities' },
  ],
};
