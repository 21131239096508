import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  display: inline-flex;
  position: relative;
  border-radius: 0.25rem;
  align-items: center;
  background: none;
  color: inherit;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: bold;
  height: 1.5rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;

  ${({ theme, status }) => {
    switch (status) {
      case 'COMPLETED':
        return css`
          background-color: ${theme.colors.primary50};
          color: ${theme.colors.primary};
        `;
      case 'OPEN':
        return css`
          background-color: ${theme.colors.primary};
          color: ${theme.colors.white};
        `;
      case 'WAITING':
        return css`
          background-color: ${theme.colors.tacao};
          color: ${theme.colors.white};
        `;
      case 'SENT':
        return css`
          background-color: ${theme.colors.grey200};
          color: ${theme.colors.grey600};
        `;
      default:
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.dark};
        `;
    }
  }}
`;
