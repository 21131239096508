/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import { Formik, Field } from 'formik';
import { forwardRef } from 'react';

import Group from 'components/Account/Modals/TradeTicketRequestModal/Group';
import { Form, Title } from 'components/Account/styles';
import Input, { InputGroup, InputError } from 'components/Input';
import Select from 'components/Select';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';
import normalizeTime from 'utils/normalizeTime';

import { Hidden } from './styles';

interface PrintRequestProps {
  requestId: string | null;
}

const PrintRequest = forwardRef<HTMLDivElement, PrintRequestProps>(
  ({ requestId }, ref) => {
    const [{ tradeTicket, ticketHistory }] = useTradeTicketStore(
      (s: TradeTicketState) => ({
        tradeTicket: s.tradeTicket,
        ticketHistory: s.ticketHistory,
      }),
    );

    const [{ allProposals, assetClassGroups }] = useProposalStore((s: any) => ({
      allProposals: s.allProposals,
      assetClassGroups: s.assetClassGroups,
    }));

    const selectedTradeTicket = ticketHistory.find(
      (th) => th.id === tradeTicket.id,
    );

    const selectedRequest = selectedTradeTicket?.requests.find(
      (sr) => sr.id === requestId,
    );

    const associatedProposal = allProposals.find(
      (p: any) => p.id === selectedTradeTicket?.proposalId,
    );

    const initialValues = {
      accountName: selectedTradeTicket?.accountName || '',
      accountType: selectedTradeTicket?.accountType || '',
      accountNumber: tradeTicket?.accountNumber || '',
      requestFrequency: selectedRequest?.frequency || '',
      requestValue: selectedRequest?.value || '',
      additionalInstructions: selectedRequest?.instructions || '',
      requestDetails: selectedRequest?.details || '',
    };

    return (
      <Hidden>
        <Box style={{ width: '100%' }} ref={ref}>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize // Async data loading
          >
            {() => (
              <Form>
                <Box>
                  <Box>
                    <Title>
                      {normalizeTime(selectedRequest?.timestamp || '')}
                    </Title>
                    <Title>Account Information</Title>
                    <Box>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="accountName"
                          placeholder="Account Name"
                        />
                        <InputError name="accountName" />
                      </InputGroup>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.875rem',
                        }}
                      >
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountType"
                            placeholder="Type"
                          />
                          <InputError name="accountType" />
                        </InputGroup>
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="accountNumber"
                            placeholder="Account # (last 4 digits)"
                          />
                          <InputError name="accountNumber" />
                        </InputGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Title>Product Allocation</Title>
                    <Box
                      sx={(theme) => ({
                        border: `1px solid ${theme.palette.grey[100]}`,
                      })}
                    >
                      {assetClassGroups.map((acg: any) => {
                        const assetGroupKey = acg.symbol.toLowerCase();
                        return (
                          <Group
                            key={assetGroupKey}
                            assetClassGroup={acg}
                            associatedProposal={associatedProposal}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: '0.875rem' }}>
                    <Title>{`${selectedRequest?.requestType}${
                      selectedRequest?.requestType ? ' ' : ''
                    }Request`}</Title>
                    {selectedRequest?.requestType === 'Cash Raise' ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '0.875rem',
                          }}
                        >
                          <InputGroup style={{ flex: '1' }}>
                            <Field as={Select} name="requestFrequency">
                              <option value="">Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="one-time">One Time</option>
                            </Field>
                            <InputError name="requestFrequency" />
                          </InputGroup>
                          <InputGroup style={{ flex: '1' }}>
                            <Field
                              as={Input}
                              name="requestValue"
                              placeholder="$ Value"
                            />
                            <InputError name="requestValue" />
                          </InputGroup>
                        </Box>
                        <InputGroup>
                          <Field
                            as={Input}
                            name="additionalInstructions"
                            placeholder="Additional Instructions (Optional)"
                          />
                          <InputError name="additionalInstructions" />
                        </InputGroup>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.875rem',
                        }}
                      >
                        <InputGroup style={{ flex: '1' }}>
                          <Field
                            as={Input}
                            name="requestDetails"
                            placeholder="Request Details"
                          />
                          <InputError name="requestDetails" />
                        </InputGroup>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Hidden>
    );
  },
);

export default PrintRequest;
