import { MenuItem } from '@reach/menu-button';
import styled from 'styled-components';

import DefaultBaseDropdown from 'components/BaseDropdown';
import { Animation, Title } from 'components/BaseDropdown/styles';
import BaseIcon from 'components/Icon';
import { prettyScrollbar } from 'styles/mixins';

export const BaseDropdown = styled(DefaultBaseDropdown)`
  overflow-y: auto;
  max-height: 50vh;
  ${prettyScrollbar({})}

  ${Title} {
    position: sticky;
    top: -0.625rem;
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Icon = styled(BaseIcon).attrs(() => ({
  variant: 'secondary',
}))`
  flex-shrink: 0;
  margin-top: 0.25rem;
  margin-right: 1rem;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.625rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`;

export const Option = styled(MenuItem)`
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
  cursor: unset;
  display: flex;
  padding: 1rem 1rem 1rem 0.75rem;
  width: 18.625rem;

  &[data-selected] {
    background-color: ${({ theme }) => theme.colors.primary10};
    color: inherit;
  }

  ${Animation}
`;

export const Text = styled.div`
  line-height: 1.4285;
  letter-spacing: 0;
  font-size: 0.875rem;
  width: 100%;
  flex-grow: 0;
`;
