import styled from 'styled-components';

interface ContainerProps {
  isValuesDriven: boolean;
}
export const Column = styled.div`
  line-height: calc(18 / 12);
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 1rem 0.5rem;

  ${Column} {
    &:nth-child(1) {
      width: ${({ isValuesDriven }) => (isValuesDriven ? '30%' : '35%')};
    }
    &:nth-child(2) {
      width: ${({ isValuesDriven }) => (isValuesDriven ? '20%' : '25%')};
      text-align: right;
      padding-right: 1.875rem;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: ${({ isValuesDriven }) => (isValuesDriven ? '25%' : '40%')};
    }
  }
`;
