import { MouseEvent, useCallback, useRef } from 'react';

const useLogic = () => {
  const previousRows = useRef<number | undefined>();

  const handleSelection = useCallback((event: MouseEvent<HTMLTableElement>) => {
    if (event.shiftKey) {
      event.preventDefault();
    }
  }, []);

  return {
    handleSelection,
    previousRows,
  };
};

export default useLogic;
