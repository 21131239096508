import { ReactComponent as EmailIcon } from 'assets/svg/footer/email.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/footer/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/svg/footer/twitter.svg';

export const links = [
  { label: 'About', url: 'https://www.seedsinvestor.com/about' },
  { label: 'Resources', url: 'https://www.seedsinvestor.com/resources' },
  { label: 'Disclosures', url: '/disclosures' },
  { label: 'Terms and Conditions', url: '/terms-and-conditions' },
];

export const socialMedia = [
  {
    id: 'twitter',
    icon: <TwitterIcon />,
    url: 'https://twitter.com/SeedsInvestor',
  },
  {
    id: 'linkedin',
    icon: <LinkedinIcon />,
    url: 'https://www.linkedin.com/company/seeds-investor/',
  },
  {
    id: 'email',
    icon: <EmailIcon />,
    url: 'mailto:support@seedsinvestor.com',
  },
];
