import { useSnackbar } from 'notistack';
import { forwardRef, useCallback, useMemo } from 'react';

import {
  Container,
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  Message,
} from './styles';
import { Props } from './types';

export const Snackbar = forwardRef<HTMLDivElement, Props>(
  ({ id, variant, message, isPersisted, ...rest }, ref) => {
    const Icon = useMemo(() => {
      switch (variant) {
        case 'warning':
          return <WarningIcon />;
        case 'error':
          return <ErrorIcon />;
        default:
        case 'success':
          return <SuccessIcon />;
      }
    }, [variant]);

    const { closeSnackbar } = useSnackbar();

    const handleClick = useCallback(() => {
      if (isPersisted) {
        closeSnackbar(id);
      }
    }, [isPersisted, closeSnackbar, id]);

    return (
      <Container {...rest} ref={ref} onClick={handleClick}>
        {Icon}
        <Message>{message}</Message>
      </Container>
    );
  },
);
