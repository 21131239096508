import { gql } from '@apollo/client';

export const GET_UPLOAD_URL = gql`
  mutation GetUploadUrl($input: UploadInput!) {
    uploadUrl(input: $input) {
      retrieveUrl
      contentType
      uploadUrl
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation RemoveFile($fileId: UUID!) {
    removeFile(fileId: $fileId) {
      ok
    }
  }
`;

export const SHARE_NEW_FILES = gql`
  mutation ShareNewFiles($input: FileSharingInput!) {
    shareNewFiles(input: $input) {
      ok
    }
  }
`;
