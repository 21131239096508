import { Field, FormikHelpers, ErrorMessage } from 'formik';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Select from 'components/Select';
import { Bold } from 'components/Table/mock';
import { Schema } from 'components/Table/types';
import usePortfolioActions from 'context/fastContext/portfolio/actions';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { ValueType } from 'context/fastContext/portfolio/types';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useModal from 'graphql/hooks/useModal';
import { compareStrings } from 'utils/defaultSort';

import Input from './Input';
import { Asset, AssetValue, FormValues } from './types';

export const useConnect = () => {
  const { push } = useHistory();
  const { id: investorId } = useParams<{ id: string }>();
  const { open } = useModal('IMPORT_PORTFOLIO');
  const [
    {
      currentPortfolio,
      importedPortfolio,
      isLoadingPortfolio,
      isImportingPortfolio,
      isUpdatingPortfolio,
    },
  ] = usePortfolioStore((s) => ({
    importedPortfolio: s.importedPortfolio,
    currentPortfolio: s.currentPortfolio,
    isLoadingPortfolio: s.isLoadingPortfolio,
    isImportingPortfolio: s.isImportingPortfolio,
    isUpdatingPortfolio: s.isUpdatingPortfolio,
  }));
  const [hasCurrentPortfolio, setHasCurrentPortfolio] =
    useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<ValueType>('MARKET_VALUE');
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [confirmErrors, setConfirmErrors] = useState<string | null>(null);

  const handleDeletePortfolio = useCallback(() => {
    setShowConfirmDelete(true);
  }, []);

  const {
    fetchCurrentPortfolio,
    createPortfolio,
    resetPortfolioStore,
    deletePortfolio,
  } = usePortfolioActions();

  const { investorInfo } = useInvestorInfo();
  const portfolioHasBeenClearedRef = useRef(false);

  useEffect(() => {
    resetPortfolioStore();
  }, [resetPortfolioStore]);

  useEffect(() => {
    if (importedPortfolio.securities.length) {
      setSelectedType(importedPortfolio.securities[0].valueType);
    } else if (currentPortfolio && currentPortfolio?.valueType) {
      setSelectedType(currentPortfolio?.valueType);
    }
  }, [currentPortfolio, importedPortfolio]);

  useEffect(() => {
    if (currentPortfolio?.holdings && currentPortfolio.holdings.length > 0) {
      setHasCurrentPortfolio(true);
    } else {
      setHasCurrentPortfolio(false);
    }
  }, [currentPortfolio]);

  const defaultAssets = useMemo(
    () =>
      currentPortfolio
        ? currentPortfolio?.holdings
            .map((item) => ({
              id: item.id,
              name: item.asset.name,
              marketValue: item.monetaryValue ?? 0,
              percentage: item.actualWeight ?? 0,
              symbol: item.asset.symbol,
              valueType: selectedType,
            }))
            .sort((a, b) => compareStrings(a.id, b.id))
            .map((asset, index) => ({ ...asset, position: index }))
        : [],
    [currentPortfolio, selectedType],
  );

  const optionIsVisible = useMemo(
    () => (option: ValueType) => {
      if (importedPortfolio.securities.length > 0) {
        return option === selectedType;
      }
      if (hasCurrentPortfolio) {
        if (currentPortfolio.valueType === 'PERCENTAGE') {
          return option === 'PERCENTAGE';
        }
      }
      return true;
    },
    [selectedType, importedPortfolio, currentPortfolio, hasCurrentPortfolio],
  );

  const schema: Schema<Asset> = useMemo(
    () => ({
      columns: {
        symbol: {
          header: <>symbol</>,
          row: (value) => <>{value}</>,
          width: '25%',
        },
        name: {
          header: <>name</>,
          row: (value) => <Bold>{value}</Bold>,
          width: '35%',
        },
        valueType: {
          header: (
            <>
              <Field
                as={Select}
                name="valueType"
                value={selectedType}
                onChange={(e: React.ChangeEvent<{ value: ValueType }>) => {
                  setSelectedType(e.target.value);
                }}
              >
                {optionIsVisible('PERCENTAGE') && (
                  <option value="PERCENTAGE">Percentage</option>
                )}
                {optionIsVisible('MARKET_VALUE') && (
                  <option value="MARKET_VALUE">Market Value</option>
                )}
              </Field>
            </>
          ),
          row: (value: ValueType, object: any) => (
            <>
              <Field
                as={Input}
                type="number"
                name={`assets[${object.position}].${AssetValue[value]}`}
                min="0"
                placeholder="0"
                step={0.01}
                value={object[AssetValue[value]] || ''}
              />
              <ErrorMessage
                name={`assets[${object.position}].${AssetValue[value]}`}
                render={(msg: string) => (
                  <span style={{ color: 'red', fontSize: '12px' }}>{msg}</span>
                )}
              />
            </>
          ),
          width: '30%',
          sortBy: false,
        },
      },
    }),
    [selectedType, optionIsVisible],
  );

  const handleOpenModal = useCallback(() => open(), [open]);

  const handleRefetch = useCallback(async () => {
    await fetchCurrentPortfolio();
  }, [fetchCurrentPortfolio]);

  const handleSubmit = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        const response = await createPortfolio({
          securities: values.assets?.map((asset) => ({
            id: asset.id,
            name: asset.name ?? '',
            position: asset.position,
            marketValue: asset.marketValue ?? 0,
            percentage: asset.percentage,
            symbol: asset.symbol,
            valueType: selectedType,
          })),
          valueType: selectedType,
          missingTickers: importedPortfolio.missing,
        });
        if (response.success) {
          resetPortfolioStore();
          resetForm({ values: { assets: [], valueType: 'MARKET_VALUE' } });
          await handleRefetch();
          push({
            pathname: `/clients/${investorId}/profile`,
          });
        } else {
          setConfirmErrors(
            'Error saving portfolio, please try again or contact support',
          );
        }
      } catch (e) {
        setConfirmErrors('Error saving portfolio');
      }
    },
    [
      selectedType,
      investorId,
      handleRefetch,
      push,
      createPortfolio,
      resetPortfolioStore,
      importedPortfolio.missing,
    ],
  );

  const handleConfirmDeletePortfolio = useCallback(async () => {
    try {
      const { success } = await deletePortfolio();
      if (success) {
        setHasCurrentPortfolio(false);
        setConfirmErrors(null);
        setShowConfirmDelete(false);
        resetPortfolioStore();
        await handleRefetch();
        push({
          pathname: `/clients/${investorId}/profile`,
        });
      } else {
        setConfirmErrors(
          'Error deleting portfolio, please try again or contact support',
        );
      }
    } catch (e) {
      setConfirmErrors('Error deleting portfolio');
    }
  }, [investorId, push, deletePortfolio, resetPortfolioStore, handleRefetch]);

  return {
    handleRefetch,
    handleOpenModal,
    handleSubmit,
    handleConfirmDeletePortfolio,
    hasCurrentPortfolio,
    handleDeletePortfolio,
    showConfirmDelete,
    setShowConfirmDelete,
    confirmErrors,
    setConfirmErrors,
    isLoadingPortfolio,
    isImportingPortfolio,
    isUpdatingPortfolio,
    setSelectedType,
    defaultAssets,
    investorId,
    portfolioType: selectedType,
    portfolioHasBeenClearedRef,
    schema,
    user: investorInfo?.householdPerson?.fullName,
  };
};
