import { Popover } from '@mui/material';
import styled from 'styled-components';

import { addAlpha } from 'utils/addAlpha';

export const Container = styled(Popover)`
  &.MuiPopover-root {
    pointer-events: none;
    top: 0.25rem !important;
  }
  .MuiPaper-root {
    border-radius: '0.5rem';
    box-shadow: 0 0.3125rem 0.9375rem -0.3125rem ${({ theme }) => addAlpha(theme.colors.dark, 0.18)};
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 21.875rem;
  min-width: 13.0625rem;
  overflow-x: hidden;
  padding: 0.625rem 1rem;
`;
