import { ValueType } from 'context/fastContext/portfolio/types';

import { initialValues } from './constants';

export type Asset = {
  id: string;
  marketValue?: number;
  name?: string;
  percentage: number;
  position: number;
  symbol: string;
  valueType: ValueType | undefined;
};

export type FormValues = ReturnType<typeof initialValues>;

export enum AssetValue {
  SHARES = 'shares',
  PERCENTAGE = 'percentage',
  MARKET_VALUE = 'marketValue',
}
