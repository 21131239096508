import styled, { css } from 'styled-components';

import { ContainerProps, LabelProps } from './types';

export const Container = styled.div<ContainerProps>`
  border-radius: 0.25rem;
  display: inline-block;
  padding: 0.125rem 0.375rem 0.25rem 0.5rem;

  ${({ theme, $status }) => {
    switch ($status) {
      case 'NOT_SENT':
        return css`
          background-color: ${theme.colors.grey500};
          color: ${theme.colors.white};
        `;
      case 'SENT':
        return css`
          background-color: ${theme.colors.grey100};
          color: ${theme.colors.grey600};
        `;
      case 'WAITING':
        return css`
          background-color: ${theme.colors.tacao};
          color: ${theme.colors.white};
        `;
      default:
      case 'COMPLETED':
        return css`
          background-color: ${theme.colors.primary50};
          color: ${theme.colors.primary};
        `;
    }
  }}
`;

export const Label = styled.span<LabelProps>`
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
`;
