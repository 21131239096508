import { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

import { VisuallyHidden } from 'styles/mixins';

export type LabelProps = {
  readOnly?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

export const Container = styled.div<{
  bordered?: boolean;
  isMobile?: boolean;
}>`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? `repeat(5, 1fr)` : `repeat(auto-fit, minmax(40px, 1fr))`};
  gap: ${({ isMobile }) => (isMobile ? `0` : `0.5rem`)};
  grid-row-gap: ${({ isMobile }) => (isMobile ? `6px` : `0.5`)};
  border-radius: 0.625rem;
  padding: 0.35rem;
  flex-wrap: wrap;
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.colors.grey200}` : 'none'};

  & > * {
    margin-left: ${({ isMobile }) => (isMobile ? `0` : `0.25rem`)};

    &:first-child {
      margin-left: 0;
    }

    &:last-child > label {
      margin-right: 0;
    }
  }
`;

export const HiddenRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  ${VisuallyHidden}

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.primary50};
    color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + label,
  &:focus + label {
    border: solid 0.125rem ${({ theme }) => theme.colors.primary};
  }
`;

export const CircleHiddenRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  ${VisuallyHidden}

  &:checked + label {
    background-color: ${({ color }) => color};
  }

  &:checked + label,
  &:focus + label {
    border: solid 0.25rem ${({ color }) => color};
  }
`;

export const Option = styled.label<LabelProps>`
  align-items: center;
  border-radius: 0.625rem;
  border-radius: 0.5rem;
  color: ${({ theme, color }) => (color ? 'black' : theme.colors.grey600)};
  background-color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: 2.5rem;
  justify-content: center;
  letter-spacing: 0.0625rem;
  width: 2.5rem;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'auto')};
  position: relative;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey0};
  }
`;

export const CircleOption = styled.label<LabelProps>`
  align-items: center;
  border-radius: 50%;
  color: ${({ theme, color }) => (color ? 'black' : theme.colors.grey600)};
  background-color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: 3rem;
  width: 3rem;
  justify-content: center;
  letter-spacing: 0.0625rem;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'auto')};
  margin-right: 0.825rem;
  position: unset;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey0};
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  & div {
    &:nth-of-type(1),
    &:nth-of-type(4) {
      flex: 1.5;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      flex: 2;
    }
  }
`;

export const Label = styled.div`
  color: inherit;
  height: 1.925rem;
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  text-align: center;
  font-weight: 500;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.grey200};

  svg {
    position: static;
  }
  &:last-child {
    border-right: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const DescriptionHint = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  color: #868e96;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  margin: 0.5rem auto;
  transition: width 0.3sec;
  font-size: 14px;
  transition: all 0.2s ease-out;

  // max-width: 450px;
  height: 92px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 10px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;
