import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'assets/svg/plusCircle.svg';
import BaseButton from 'components/Button';
import useMe from 'graphql/hooks/useMe';

function AddClient() {
  const { me } = useMe();
  const { push } = useHistory();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showLinkShare, setShowLinkShare] = useState<boolean>(false);
  const [showLinkCopiedMessage, setShowLinkCopiedMessage] =
    useState<boolean>(false);

  const toggleShowAddModal = useCallback(() => {
    if (me?.role === 'ADMIN') {
      push('/clients/add');
    } else {
      setShowAddModal(!showAddModal);
    }
  }, [me?.role, push, showAddModal]);

  const closeShowAddModal = useCallback(() => {
    setShowAddModal(false);
    setShowLinkShare(false);
  }, []);

  const handleToggleLinkShare = useCallback(() => {
    setShowLinkShare(!showLinkShare);
  }, [showLinkShare]);

  const actionItems = useMemo(
    () => [
      {
        label: 'Manually Add',
        description:
          'Input a single household, including names and contact information for each household member.',
        onClick: () => {
          push('/clients/add');
        },
      },
      {
        label: 'Link Share',
        description:
          'Send a link to multiple clients. Client can enter their own information and take the assessment.',
        onClick: () => {
          handleToggleLinkShare();
        },
      },
      // {
      //   label: 'Import Request',
      //   description:
      //     'Load an Excel file with multiple household and client account records for mass import.',
      //   onClick: '',
      // },
    ],
    [push, handleToggleLinkShare],
  );

  const linkShare = useMemo(() => {
    const hash = (me as any)?.advisor?.shortHash;
    const company = (me as any)?.advisor?.company
      .replace(/[^\w\s]/gi, '') // Remove all non-word characters
      .trim() // Remove whitespace from both ends of a string
      .replace(/\s+/g, '_'); // Replace spaces with _
    return (me as any)?.advisor?.id
      ? `${window.origin}/o/${company}/${hash}`.replace(/^https?:\/\//, '')
      : '';
  }, [me]);

  const copyTextToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(linkShare);
      setShowLinkCopiedMessage(true);
      setTimeout(() => {
        setShowLinkCopiedMessage(false);
      }, 800);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy text: ', err);
    }
  }, [linkShare]);

  return (
    <ClickAwayListener onClickAway={closeShowAddModal}>
      <Box>
        <BaseButton
          onClick={toggleShowAddModal}
          variant="primary"
          data-testid="clients-button-new-clients"
        >
          <AddIcon /> New Client(s)
        </BaseButton>
        {showAddModal && (
          <Box
            sx={{
              position: 'absolute',
              border: '1px solid #E9ECEF',
              mt: 1,
              zIndex: 9999,
              backgroundColor: '#F8F9FA',
              marginRight: '12px',
              borderRadius: '12px',
            }}
          >
            {!showLinkShare && (
              <List component="nav" aria-label="main mailbox folders">
                {actionItems.map((item) => (
                  <ListItem
                    key={item.label}
                    sx={{
                      display: 'flex',
                      padding: '4px 16px',
                      alignItems: 'initial',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Button
                      onClick={item.onClick}
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        flex: '1',
                        textAlign: 'center',
                        marginRight: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: '#000',
                        },
                        '@media (max-width:1425px)': {
                          flex: '2',
                        },
                      }}
                      data-testid={`clients-button-${item.label
                        .toLowerCase()
                        .replace(' ', '-')}`}
                    >
                      {item.label}
                    </Button>
                    <ListItemText
                      sx={{ fontSize: '0.8rem', flex: '4' }}
                      primaryTypographyProps={{ variant: 'body2' }}
                      primary={item.description}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            {showLinkShare && (
              <Box maxWidth="425px" sx={{ padding: '8px 16px 16px 16px' }}>
                <Button
                  sx={{
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={handleToggleLinkShare}
                >
                  <ArrowBackIosIcon fontSize="small" /> Back
                </Button>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Box
                    sx={{
                      flex: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      wordBreak: 'break-all',
                      marginRight: '12px',
                    }}
                  >
                    {linkShare}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={copyTextToClipboard}
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        flex: '1',
                        textAlign: 'center',
                        marginRight: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        width: '90px',
                        height: '40px',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: '#000',
                        },
                      }}
                    >
                      {showLinkCopiedMessage ? (
                        <CheckCircleIcon />
                      ) : (
                        'Copy Link'
                      )}
                    </Button>
                  </Box>
                </Box>
                <Typography fontSize={12} mt={2}>
                  This link can be shared to multiple users at once. Each user
                  will be prompted to input household information prior to
                  completing an assessment. You will be notified when complete.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default AddClient;
