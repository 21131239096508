import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';

interface OptionType {
  value: string | number;
  label: string;
}

interface DropdownProps {
  label?: string;
  options: OptionType[];
  value: string | undefined;
  onChange: (value: any, dataset?: any) => void;
  disabled?: boolean;
  sx?: any;
  dataset?: any;
  height?: string;
  padding?: string;
  required?: boolean;
  dataTestId?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  sx = {},
  dataset = {},
  height = '28.5px',
  padding = '16.5px 32px 16.5px 12px',
  required = false,
  dataTestId,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (Object.keys(dataset).length) {
      onChange(event, dataset);
    } else {
      onChange(event);
    }
  };

  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        {label && (
          <InputLabel
            sx={{
              lineHeight: 'inherit',
              '&.MuiInputLabel-shrink': {
                lineHeight: `${height} !important`,
              },
            }}
            id={`${label}-select-label`}
          >
            {label}
          </InputLabel>
        )}
        <Select
          required={required}
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          value={value}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          inputProps={{ 'data-testid': dataTestId }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px',
                overflowY: 'scroll',
              },
            },
          }}
          sx={{
            '.MuiSelect-select': {
              paddingLeft: '12px !important',
              backgroundColor: 'primary.common.white',
              height: `${height} !important`,
              lineHeight: `${height} !important`,
              padding: `${padding} !important`,
            },
            '&.Mui-disabled': {
              '.MuiSelect-select': {
                backgroundColor: 'grey.100', // Disabled background color
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              data-testid={option.label}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;
