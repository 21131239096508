import styled from 'styled-components';

import Card from 'components/Card';
import { TextWrap } from 'styles/mixins';

export const Container = styled(Card)`
  padding: 1.875rem 2rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
  max-width: 23.75rem;
`;

export const Goal = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  ${TextWrap};
`;

export const Value = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  text-align: right;
  margin-left: 0.375rem;
  ${TextWrap};
`;
