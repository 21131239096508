import { Tooltip } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { FaLongArrowAltLeft, FaRocket } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as InfoIcon } from 'assets/svg/info-circle.svg';
import Button from 'components/Button';
import RadioGroupSurvey from 'components/RadioGroupSurvey';
import RatingInput, { RatingOptions } from 'components/RatingInput';
import SurveyStatusEmail from 'components/SurveyStatusEmail';

import { useConnect } from './connect';
import { Block, Error, Hint, Row, Title } from './styles';
import { SetInvestorRiskProps } from './types';

export const SetInvestorRisk = ({
  sendSurveyEmail,
  investorInfo,
  survey,
  fetchInvestorInfo,
  loadingInvestorInfo,
}: SetInvestorRiskProps) => {
  const [formState, setFormState] = useState({
    riskLevel: investorInfo?.riskLevel,
    incomeLevel: investorInfo?.incomeLevel,
  });
  const { save, error, loading } = useConnect();
  const { id: investorId } = useParams<{
    id: string;
  }>();
  const { push } = useHistory();

  const [surveyProgress, setSurveyProgress] = useState({
    isStarted: false,
    currentStep: 0,
  });

  const handleStart = () => {
    const nextStage = !surveyProgress.isStarted;
    setSurveyProgress({
      ...surveyProgress,
      isStarted: nextStage,
      currentStep: nextStage ? 1 : 0,
    });
  };

  const getStepHeader = () => {
    switch (surveyProgress.currentStep) {
      case 0:
        return "To start, click 'set' to begin populating your client's three-dimensional investor profile.";
      case 1:
      case 2:
        return 'Next, select whether to send the profile assessment via email or launch it live with your client.';
      case 3:
        return 'Next, select the appropriate risk target for your client based on tolerance, need, and capacity.';
      case 4:
        return "Finally, select your client's annual income range.";
      default:
        return '';
    }
  };

  const goNext = () => {
    setSurveyProgress({
      ...surveyProgress,
      currentStep: surveyProgress.currentStep + 1,
    });
  };

  const goBack = () => {
    setSurveyProgress({
      ...surveyProgress,
      currentStep: surveyProgress.currentStep - 1,
    });
  };

  const goFinancialSurvey = () => {
    // TODO: Decide what to do with this
    // I feel like this is irrelevant...should just go to the first question in survey
    // as the financial section is first anyway so it will always default to 0
    const surveyStepIndex =
      survey?.steps.findIndex((el) => {
        const containsNewSectionLink = !!el.sectionLink;
        return (
          containsNewSectionLink
            ? el.sectionLink.name.toLowerCase()
            : el.section
        ).includes('financial');
      }) || 0;

    push(`/clients/${investorId}/survey/${surveyStepIndex + 1}`);
  };

  const goMindsetSurvey = () => {
    // in any case redirect to first question
    push(`/clients/${investorId}/survey/1`);
  };

  const onSubmit = async (values: any) => {
    setFormState({
      ...formState,
      ...values,
    });
    await save({
      id: investorId,
      ...values,
    });
    await fetchInvestorInfo(investorId, false);
    if (values.riskLevel) {
      goNext();
    } else {
      goMindsetSurvey();
    }
  };

  const StepContent = () => {
    if (surveyProgress.currentStep === 1) {
      return (
        <Block>
          <SurveyStatusEmail
            onSubmit={sendSurveyEmail}
            email={investorInfo?.householdPerson.email || ''}
            name={investorInfo?.householdPerson.fullName || ''}
          />
          <Button
            variant="primary"
            onClick={goNext}
            icon={<FaRocket />}
            data-testid="investor-profile-button-launch-assessment"
          >
            Launch Assessment
          </Button>
        </Block>
      );
    }

    if (surveyProgress.currentStep === 2) {
      return (
        <Block>
          <Button
            variant="secondary"
            icon={<FaLongArrowAltLeft />}
            onClick={goBack}
          />
          <Button variant="primary" onClick={goNext}>
            Pre-Set Risk Target
          </Button>

          <Button
            variant="primary"
            onClick={goFinancialSurvey}
            data-testid="investor-profile-button-assess-risk-target"
          >
            Assess Risk Target
          </Button>
        </Block>
      );
    }
    if (surveyProgress.currentStep === 3) {
      return (
        <Block>
          <Formik
            initialValues={{ riskLevel: formState.riskLevel }}
            onSubmit={async ({ riskLevel }) => {
              await onSubmit({ riskLevel: Number(riskLevel) });
            }}
          >
            {({ values, isSubmitting }) => {
              const isButtonDisabled =
                !values.riskLevel ||
                loading ||
                isSubmitting ||
                loadingInvestorInfo;
              return (
                <Form style={{ width: '100%' }}>
                  <Field
                    initialValue={values.riskLevel}
                    as={RatingInput}
                    colorized
                    name="riskLevel"
                    options={RatingOptions}
                    hint="Each Risk Target corresponds to a specific asset allocation."
                  />
                  <Error>{error}</Error>
                  <Row>
                    <Button
                      variant="secondary"
                      icon={<FaLongArrowAltLeft />}
                      onClick={goBack}
                    />
                    <Button
                      disabled={
                        // NOTE: user should be stopped to move further when selecting 1 [https://seedsinvestor.atlassian.net/browse/SD-553]
                        isButtonDisabled || Number(values.riskLevel) === 1
                      }
                      variant="primary"
                      type="submit"
                    >
                      Next
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Block>
      );
    }

    if (surveyProgress.currentStep === 4) {
      return (
        <Block>
          <Formik
            initialValues={{ incomeLevel: formState.incomeLevel }}
            onSubmit={onSubmit}
          >
            {({ values, isSubmitting }) => {
              const isButtonDisabled =
                !values.incomeLevel ||
                loading ||
                isSubmitting ||
                loadingInvestorInfo;
              return (
                <Form>
                  <Field
                    initialValue={values.incomeLevel}
                    as={RadioGroupSurvey}
                    name="incomeLevel"
                    options={[
                      {
                        label:
                          'Below $89,000 filing individually or below $178,000 filing jointly',
                        id: 'low',
                        description: '',
                      },
                      {
                        label:
                          'Between $89,000 and $215,000 filing individually or between $178,000 and $430,000 filing jointly',
                        id: 'mid',
                        description: '',
                      },
                      {
                        label:
                          'Above $215,000 filing individually or above $430,000 filing jointly',
                        id: 'high',
                        description: '',
                      },
                    ]}
                  />
                  <Error>{error}</Error>
                  <Row>
                    <Button
                      variant="secondary"
                      icon={<FaLongArrowAltLeft />}
                      onClick={goBack}
                    />
                    <Button
                      disabled={isButtonDisabled}
                      variant="primary"
                      type="submit"
                    >
                      Finish
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Block>
      );
    }

    return <></>;
  };

  return (
    <>
      <Title>
        Investor Profile{' '}
        <Button
          onClick={handleStart}
          variant={surveyProgress.isStarted ? 'default' : 'primary'}
          data-testid="investor-profile-button-set"
        >
          SET
        </Button>
      </Title>

      <Hint>
        {getStepHeader()}{' '}
        {surveyProgress.currentStep === 99 ? (
          <Tooltip title="Each Risk Target corresponds to a specific asset allocation.">
            <InfoIcon />
          </Tooltip>
        ) : null}
      </Hint>
      {surveyProgress.isStarted ? (
        <>
          <StepContent />
        </>
      ) : null}
    </>
  );
};
