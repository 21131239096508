import { FC, memo } from 'react';

import { Container } from './styles';
import { Props } from './types';

const Icon: FC<Props> = ({ icon, variant, className }) => {
  return (
    <Container className={className} variant={variant}>
      {icon}
    </Container>
  );
};

export default memo(Icon);
