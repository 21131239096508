import { AccountProvider } from './account/context';
import { AdvisorProvider } from './advisor/context';
import { GlobalProvider } from './global/context';
import { InvestorProvider } from './investor/context';
import { PortfolioProvider } from './portfolio/context';
import { ProposalProvider } from './proposal/context';
import { TradeTicketProvider } from './tradeticket/context';

function CustomProviders({ children }: any) {
  return (
    <GlobalProvider>
      <PortfolioProvider>
        <AdvisorProvider>
          <InvestorProvider>
            <ProposalProvider>
              <AccountProvider>
                <TradeTicketProvider>{children}</TradeTicketProvider>
              </AccountProvider>
            </ProposalProvider>
          </InvestorProvider>
        </AdvisorProvider>
      </PortfolioProvider>
    </GlobalProvider>
  );
}

export default CustomProviders;
