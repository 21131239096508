import { useCallback } from 'react';
import { BsTrash } from 'react-icons/bs';

import { ActionComponent } from 'components/Table/types';

import { RemoveButton } from '../styles';
import { Asset } from '../types';

export const Remove: ActionComponent<Asset> = ({ rowData: { id }, action }) => {
  const handleClick = useCallback(() => {
    if (action) {
      action(id);
    }
  }, [id, action]);

  return (
    <RemoveButton onClick={handleClick} title="Remove asset">
      <BsTrash />
    </RemoveButton>
  );
};
