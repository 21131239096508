import Box from '@mui/material/Box';
import { useState, useCallback, useEffect, useMemo } from 'react';

import Filter from 'components/Filter';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import { AccountState } from 'context/fastContext/account/types';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { InvestorState } from 'context/fastContext/investor/types';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { ProposalState } from 'context/fastContext/proposal/types';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import removeItem from 'utils/removeItem';

import SearchBox from '../SearchBox';
import { ASSET_FILTER_OPTIONS } from './constants';
import { SectionTitle, TableSectionHeader } from './styles';
import HoldingsTable from './Table';
import type { TableHoldingsType } from './Table/types';
import TableTabs from './TableTabs';

const ASSET_FILTER_OPTIONS_ARRAY = {
  Type: ASSET_FILTER_OPTIONS.Type.map((t) => t.name),
  Sector: ASSET_FILTER_OPTIONS.Sector.map((t) => t.name),
};

const HoldingsContainer = () => {
  const { investorInfo } = useInvestorInfo();
  const seedsInvested = investorInfo?.seedsInvested;
  const [isValuesDriven] = useInvestorStore(
    (s: InvestorState) => s.profile?.isValuesDriven,
  );
  const [{ holdings, combinedHoldings, proposalState }] = useProposalStore(
    (s: ProposalState) => ({
      holdings: s.masterProposal.holdings,
      combinedHoldings: s.masterProposal.combinedHoldings,
      proposalState: s.proposalState,
    }),
  );
  const [accounts] = useAccountStore((s: AccountState) => s.accounts);

  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));

  const allProposalHoldings: TableHoldingsType[] = useMemo(
    () =>
      holdings.map((h: any) => ({
        symbol: h.asset.symbol,
        name: h.asset.name,
        percentage: h.actualWeight,
        valueScore: h.asset.scoreValue,
        sector: h.asset.sector,
        type: h.asset.assetType,
        assetTypeCode: h.asset.assetTypeCode,
        accountId: h.accountId,
        id: h.id,
      })),
    [holdings],
  );

  const combinedProposalHoldings: TableHoldingsType[] = useMemo(
    () =>
      combinedHoldings.map((h: any) => ({
        symbol: h.asset.symbol,
        name: h.asset.name,
        percentage: h.actualWeight,
        valueScore: h.asset.scoreValue,
        sector: h.asset.sector,
        type: h.asset.assetType,
        assetTypeCode: h.asset.assetTypeCode,
        accountId: h.accountId,
        id: h.id,
      })),
    [combinedHoldings],
  );

  const allCurrentHoldings: TableHoldingsType[] = useMemo(
    () =>
      (currentPortfolio?.holdings || []).map((h) => ({
        symbol: h.asset.symbol,
        name: h.asset.name,
        percentage: h.actualWeight,
        valueScore: h.asset.scoreValue,
        sector: h.asset.sector,
        type: h.asset.assetType,
        assetTypeCode: h.asset.assetTypeCode,
        id: h.id,
      })),
    [currentPortfolio?.holdings],
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [filterValues, setFilterValues] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabsToRender, setTabsToRender] = useState<string[]>([]);

  useEffect(() => {
    const hasUploadedPortfolio =
      currentPortfolio?.holdings && currentPortfolio?.holdings?.length > 0;
    const hasProposalHoldings = holdings.length > 0;

    if (
      hasProposalHoldings &&
      hasUploadedPortfolio &&
      proposalState !== 'IMPLEMENTED'
    ) {
      setTabsToRender(['Current', 'Proposed']);
      setSelectedTab(1);
    } else if (
      (hasProposalHoldings && !hasUploadedPortfolio) ||
      proposalState === 'IMPLEMENTED'
    ) {
      setTabsToRender(['Proposed']);
    } else {
      setTabsToRender(['Current']);
    }
  }, [
    holdings.length,
    currentPortfolio?.holdings,
    seedsInvested,
    proposalState,
  ]);

  const currentTab = useMemo(
    () => tabsToRender[selectedTab],
    [selectedTab, tabsToRender],
  );

  useEffect(() => {
    setFilterValues([]);
  }, [selectedTab]);

  const accountIds = useMemo(() => accounts.map((a) => a.id), [accounts]);

  const filteredByAccount = accountIds.some((id) => filterValues.includes(id));

  const accountOptions = useMemo(
    () =>
      accounts.map((a) => ({
        name: a.id!,
        label: a.name!,
        isDisabled: filteredByAccount && !filterValues.includes(a.id),
      })),
    [accounts, filterValues, filteredByAccount],
  );

  const holdingsToDisplay = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      currentTab === 'Current'
        ? allCurrentHoldings
        : filteredByAccount
        ? allProposalHoldings
        : combinedProposalHoldings,
    [
      allCurrentHoldings,
      allProposalHoldings,
      combinedProposalHoldings,
      currentTab,
      filteredByAccount,
    ],
  );

  const filterOptionsToUse = useMemo(() => {
    // If the current tab is 'Current', return only Sector options.
    if (currentTab === 'Current') {
      return { Sector: ASSET_FILTER_OPTIONS.Sector };
    }
    // For other tabs, start with all ASSET_FILTER_OPTIONS
    const options = { ...ASSET_FILTER_OPTIONS };

    // If there are multiple accounts, add them to the options.
    if (accountOptions.length > 0) {
      // Assuming you want to include accounts if any exist.
      options.Account = accountOptions;
    }

    return options;
  }, [accountOptions, currentTab]);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleSetFilterValues = useCallback(
    (e: any) => {
      const isChecked = e.target.checked;
      const { value } = e.target;

      if (isChecked && !filterValues.includes(value)) {
        const newArr = [...filterValues, value];
        setFilterValues(newArr);
      } else if (!isChecked && filterValues.includes(value)) {
        const newArr = [...filterValues];
        const updatedArr = removeItem(newArr, value);
        setFilterValues(updatedArr);
      }
    },
    [filterValues],
  );

  const handleClearFilters = useCallback(() => {
    setFilterValues([]);
  }, []);

  useEffect(() => {
    // Clear out any old timer before setting a new timer
    if (timer) {
      clearTimeout(timer);
    }

    // Timer acting as a debounce
    const newTimer = setTimeout(() => {
      setSearchFilterValue(searchValue.toLowerCase());
    }, 400);

    setTimer(newTimer);

    // Cleanup timer on unmount or before re-running the effect
    return () => {
      clearTimeout(newTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const filteredHoldings = useMemo(() => {
    const filteredBySector = ASSET_FILTER_OPTIONS_ARRAY.Sector.some((item) =>
      filterValues.includes(item),
    );

    const filteredByType = ASSET_FILTER_OPTIONS_ARRAY.Type.some((item) =>
      filterValues.includes(item),
    );

    return holdingsToDisplay.filter((h) => {
      let containsType: boolean | undefined = true;
      let containsIndustry: boolean | undefined = true;
      let nameIncludesSearchText: boolean | undefined = true;
      let symbolIncludesSearchText: boolean | undefined = true;
      let containsAccount: boolean | undefined = true;

      if (filterValues.length) {
        if (filteredByType) {
          containsType = filterValues.includes(h.assetTypeCode?.toUpperCase());
        }

        if (filteredBySector) {
          containsIndustry = filterValues.includes(
            h.sector?.toUpperCase().split(' ').join('_'),
          );
        }

        if (filteredByAccount) {
          containsAccount = filterValues.includes(h?.accountId);
        }
      }

      if (searchFilterValue.length) {
        nameIncludesSearchText = h.name
          ?.toLowerCase()
          .includes(searchFilterValue);

        symbolIncludesSearchText = h.symbol
          ?.toLowerCase()
          .includes(searchFilterValue);
      }

      return (
        containsType &&
        containsIndustry &&
        containsAccount &&
        (nameIncludesSearchText || symbolIncludesSearchText)
      );
    });
  }, [holdingsToDisplay, filterValues, searchFilterValue, filteredByAccount]);

  return (
    <Box
      sx={{
        '& *': {
          fontFamily:
            "'Greycliff CF',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif !important",
        },
      }}
    >
      <TableSectionHeader>
        <SectionTitle>Holdings</SectionTitle>

        <Box mb={2}>
          <SearchBox
            value={searchValue}
            placeholder="Search for assets by their name or ticker"
            ariaLabel="search holdings"
            handleOnChange={handleSearchValueChange}
            handleClear={handleSearchClear}
          />
          <Filter
            filterValues={filterValues}
            handleSetFilterValues={handleSetFilterValues}
            filterOptions={filterOptionsToUse}
            clearFilters={handleClearFilters}
          />
        </Box>
        <TableTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabsToRender={tabsToRender}
        />
      </TableSectionHeader>
      {allCurrentHoldings.length === 0 && allProposalHoldings.length === 0 ? (
        <Box sx={{ margin: '12px 0' }}>No holdings uploaded yet.</Box>
      ) : (
        <HoldingsTable
          currentTab={currentTab}
          holdings={filteredHoldings}
          isValuesDriven={isValuesDriven!}
        />
      )}
    </Box>
  );
};

export default HoldingsContainer;
