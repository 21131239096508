import styled from 'styled-components';

import BaseButton from 'components/Button';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13.0625rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-left: 1rem;
`;

export const SecondaryTitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 1.4375rem;

  @media (max-width: 700px) {
    text-align: center;
  }
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 7.25rem;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.875rem;
  grid-row-gap: 0.75rem;
  margin-bottom: 0.5rem;

  > div {
    width: 300px;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 0.625rem;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;

    > Button {
      &:last-child {
        margin-top: 12px;
      }
    }
  }

  > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Button = styled(BaseButton)`
  height: 3rem;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
`;

export const SendEmailText = styled.label`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

export const Block = styled.div`
  padding: 0.875rem 0.875rem;
  border-radius: 0.3125rem;
  margin-bottom: 2.25rem;
`;
