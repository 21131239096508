import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

import { Pagination } from 'containers/DataViewContainer/types';
import useAdvisors from 'graphql/hooks/useAdvisors';

import { schema } from './tableUtils';

const pageSize = 10;

export const useConnect = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');

  const {
    advisors,
    onSort: handleSort,
    sortedBy,
    loading,
  } = useAdvisors({
    page,
    pageSize,
    search,
  });

  const itemsShown = useMemo(
    () =>
      advisors?.totalResults
        ? {
            initial: (page - 1) * pageSize + 1,
            final:
              page * pageSize > advisors.totalResults
                ? advisors.totalResults
                : page * pageSize,
            total: advisors.totalResults,
          }
        : undefined,
    [page, advisors],
  );

  const onChangePage = useCallback(
    (updatedPage: number) => {
      setPage(updatedPage);
    },
    [setPage],
  );

  const handleSearchNotDebounced = useCallback(
    (updatedSearch: string) => {
      setSearch(updatedSearch);
      setPage(1);
    },
    [setSearch, setPage],
  );

  const handleSearch = useMemo(
    () => debounce(handleSearchNotDebounced, 500),
    [handleSearchNotDebounced],
  );

  return {
    advisors: advisors.objects,
    handleSearch,
    handleSort,
    itemsShown,
    pagination: {
      hasNext: advisors.hasNext,
      hasPrev: advisors.hasPrev,
      page: advisors.page,
      pages: advisors.pages,
      onChangePage,
    } as Pagination,
    schema,
    sortedBy,
    loading,
  };
};
