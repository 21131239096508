import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function TableTabs({
  selectedTab,
  handleTabChange,
  tabsToRender,
}: {
  selectedTab: number;
  handleTabChange: any;
  tabsToRender: string[];
}) {
  return (
    <Box borderBottom="0.0625rem solid #E9ECEF">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        TabIndicatorProps={{
          style: {
            backgroundColor: '#36BF8A',
            color: '#36BF8A',
          },
        }}
      >
        {tabsToRender.map((tab) => (
          <Tab key={tab} sx={{ textTransform: 'none' }} label={tab} />
        ))}
      </Tabs>
    </Box>
  );
}

export default TableTabs;
