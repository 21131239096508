import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 1.5rem 0;

  :not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.0625rem;
    width: 5rem;
    background-color: ${({ theme }) => theme.colors.grey100};

    @media print {
      display: none;
    }
  }
`;

export const Title = styled.h4`
  width: 100%;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: ${({ theme }) => theme.colors.dark};
`;

export const Description = styled.p`
  margin-left: 1.5rem;
  font-size: 0.875rem;
  line-height: calc(20 / 14);
  color: ${({ theme }) => theme.colors.grey600};
`;
