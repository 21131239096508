import Box from '@mui/material/Box';
import { FC } from 'react';

import { ReactComponent as Indicator } from 'assets/svg/holdings-toggle/indicator.svg';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { InvestorState } from 'context/fastContext/investor/types';
import theme from 'styles/v2/theme';

import { Circle, ItemText } from '../../styles';
import {
  Container,
  RiskContainer,
  RiskCirlce,
  RiskHeader,
  RiskWrapper,
} from './styles';
import { Props } from './types';

export const colorSchema = [
  '#ADB2B0',
  '#CCFAE5',
  '#91E3C2',
  '#24B07A',
  '#1c7552',
  '#D5C9FE',
  '#A199DE',
  '#6449C0',
  '#FCC79E',
  '#D48A57',
];

export const getTooltipMessage = (rate: number) => {
  switch (rate) {
    case 2:
      return `Risk Target translates to a 20% Equities | 80% Fixed Income  asset allocation.`;
    case 3:
      return `Risk Target translates to a 30% Equities | 70% Fixed Income  asset allocation.`;
    case 4:
      return `Risk Target translates to a 40% Equities | 60% Fixed Income  asset allocation.`;
    case 5:
      return `Risk Target translates to a 50% Equities | 50% Fixed Income  asset allocation.`;
    case 6:
      return `Risk Target translates to a 60% Equities | 40% Fixed Income  asset allocation.`;
    case 7:
      return `Risk Target translates to a 70% Equities | 30% Fixed Income  asset allocation.`;
    case 8:
      return `Risk Target translates to a 80% Equities | 20% Fixed Income  asset allocation.`;
    case 9:
      return `Risk Target translates to a 90% Equities | 10% Fixed Income  asset allocation.`;
    case 10:
      return `Risk Target translates to a 100% Equities asset allocation.`;
    default:
      return `Selected Risk Target is not valid.`;
  }
};

const Tooltip: FC<Props> = ({ active, payload, dataShown }) => {
  const [assessedRiskLevel] = useInvestorStore(
    (s: InvestorState) => s?.profile?.riskLevel,
  );
  if (!active) return null;
  const { title, color, value } = payload?.[0].payload;

  const assessedAndCurrentAreAligned = assessedRiskLevel === value;

  return (
    <Container
      style={{
        backgroundColor:
          title === 'Risk Score'
            ? theme.palette.primary[50]
            : theme.palette.common.white,
        border: 'none',
      }}
    >
      {title === 'Risk Score' ? (
        <RiskWrapper>
          <RiskHeader>{title}</RiskHeader>
          <RiskContainer>
            {colorSchema.map((circleColor: any, idx: any) => {
              const isSelected = value === idx + 1;
              const isAssessed = assessedRiskLevel === idx + 1;
              const size = isSelected ? 2 : 1.5;
              return (
                <Box
                  key={circleColor}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <RiskCirlce
                    className="circle"
                    style={{
                      backgroundColor: circleColor,
                      width: `${size}rem`,
                      height: `${size}rem`,
                      lineHeight: `${size}rem`,
                      fontSize: isSelected ? '14px' : '10px',
                      color: 'white',
                    }}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {isSelected ? value : isAssessed ? assessedRiskLevel : ''}
                  </RiskCirlce>
                  {isAssessed && (
                    <Box
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: assessedAndCurrentAreAligned ? '40px' : '30px',
                      }}
                    >
                      <Indicator /> Assessed
                    </Box>
                  )}
                </Box>
              );
            })}
          </RiskContainer>
          <Box mt={assessedAndCurrentAreAligned ? '40px' : '30px'}>
            {getTooltipMessage(value)}
          </Box>
        </RiskWrapper>
      ) : (
        <>
          <Circle background={color} />
          {dataShown === 'current' && (
            <ItemText>
              {title}: {title === 'Risk Score' ? value : `${value}`}
            </ItemText>
          )}
          {dataShown === 'seeds' && (
            <ItemText>
              {title}: {title === 'Risk Score' ? value : `${value}%`}
            </ItemText>
          )}
        </>
      )}
    </Container>
  );
};

export default Tooltip;
