/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';

import Modal from 'components/NewModal';

function ConfirmDeleteModal({
  isOpen,
  setShowConfirmDelete,
  confirmDelete,
  accountToDelete,
  confirmErrors,
  setConfirmErrors,
}: any) {
  const close = useCallback(() => {
    setShowConfirmDelete(false);
    setConfirmErrors(null);
  }, [setConfirmErrors, setShowConfirmDelete]);

  useEffect(() => {
    if (confirmErrors && !isOpen) {
      setConfirmErrors(null);
    }
  }, [confirmErrors, isOpen, setConfirmErrors]);

  return (
    <Modal
      transitionSpeed="0.3s"
      sx={{ maxWidth: '350px' }}
      isOpen={isOpen}
      close={close}
    >
      <Box mb="16px">
        <Typography>{`Are you sure you want to delete the account ${accountToDelete?.name}?`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Button
          onClick={confirmDelete}
          color="error"
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            flex: 2,
            color: 'common.white',
          }}
          variant="contained"
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          sx={{ fontWeight: 'bold', textTransform: 'initial', flex: 1 }}
          onClick={close}
        >
          Cancel
        </Button>
      </Box>
      {confirmErrors && (
        <Box sx={{ marginTop: '12px', fontSize: '14px', color: 'error.main' }}>
          {confirmErrors}
        </Box>
      )}
    </Modal>
  );
}

export default ConfirmDeleteModal;
