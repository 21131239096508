/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PaginationItem } from '@mui/material';
import Box from '@mui/material/Box';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import {
  gridPageCountSelector,
  GridPagination,
  GridFooterContainer,
  useGridApiContext,
  useGridSelector,
  GridToolbarExport,
} from '@mui/x-data-grid';

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  if (pageCount <= 1) {
    return null;
  }

  const handleEllipsisClick = (
    event: any,
    type: 'start-ellipsis' | 'end-ellipsis',
  ) => {
    if (type === 'start-ellipsis') {
      const newPage = Math.max(0, page - 5); // adjust as needed
      onPageChange(event, newPage);
    } else if (type === 'end-ellipsis') {
      const newPage = Math.min(pageCount - 1, page + 5); // adjust as needed
      onPageChange(event, newPage);
    }
  };

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
      renderItem={(item) => {
        if (item.type === 'start-ellipsis' || item.type === 'end-ellipsis') {
          return (
            <div
              role="button"
              tabIndex={-1}
              onClick={(e) => {
                if (
                  item.type === 'start-ellipsis' ||
                  item.type === 'end-ellipsis'
                ) {
                  handleEllipsisClick(e, item.type);
                  e.stopPropagation();
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <PaginationItem {...item} />
            </div>
          );
        }
        return <PaginationItem {...item} />;
      }}
    />
  );
}

function CustomPagination({ exportFileName, ...props }: any) {
  const apiRef = useGridApiContext();
  const gridState = apiRef.current.state;

  const startRange =
    gridState.pagination.paginationModel.pageSize *
      gridState.pagination.paginationModel.page +
    1;
  const endRange =
    startRange + gridState.pagination.paginationModel.pageSize - 1;

  return (
    <GridFooterContainer
      className="test"
      sx={{
        width: '100%',
        marginTop: '12px',
      }}
    >
      <Box
        sx={{
          marginRight: '12px',
          width: '100%',
          '.MuiToolbar-root': {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '10px',
          },
          '.MuiTablePagination-spacer': {
            display: 'none',
          },
        }}
      >
        <GridPagination
          labelDisplayedRows={() => (
            <span style={{ fontSize: '12px' }}>
              {`${startRange} - ${endRange} OF `}
              <span data-testid="holdings-count">
                {gridState.rows.totalRowCount}&nbsp;
              </span>
              <span style={{ color: '#868E96' }}>RESULTS</span>
            </span>
          )}
          ActionsComponent={Pagination}
          {...props}
        />
      </Box>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: exportFileName, // exportFileName
          allColumns: true,
        }}
      />
    </GridFooterContainer>
  );
}

export default CustomPagination;
