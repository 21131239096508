import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  format,
} from 'date-fns';
import { useMemo } from 'react';

const useLogic = (dateString: string) => {
  const formattedDate = useMemo(() => {
    const date = new Date(dateString);
    const days = differenceInCalendarDays(new Date(), date);
    const weeks = differenceInCalendarWeeks(new Date(), date);
    const months = differenceInCalendarMonths(new Date(), date);

    if (days < 0) return null;
    if (days === 0) return `Today - ${format(date, 'hh:mm')}`;
    if (days === 1) return 'Yesterday';
    if (weeks === 0) return `${days} days ago`;
    if (weeks === 1) return 'Last week';
    if (months === 0) return `${weeks} weeks ago`;
    if (months === 1) return 'Last month';
    if (months < 12) return `${months} months ago`;

    return format(date, 'd/MM/yyyy');
  }, [dateString]);

  return { formattedDate };
};

export default useLogic;
