/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';

const ProgressBar = () => {
  const [proposalState] = useProposalStore((s: any) => s.proposalState);

  const [profile] = useInvestorStore((s: any) => s.profile);

  const activeStep = useMemo(() => {
    if (profile?.status !== 'COMPLETE') return 0;
    if (proposalState === 'IMPLEMENTED') return 4;
    if (proposalState === 'PENDING') return 3;
    if (proposalState === 'ACTIVE') return 2;
    if (proposalState === 'DRAFT') return 1;

    return 0;
  }, [profile?.status, proposalState]);

  const steps = [
    '',
    'Assessment Complete',
    'Proposal Complete',
    'Accounts In Progress',
    'Accounts Implemented',
  ];

  const ColorlibConnector = ({ ...props }) => (
    <StepConnector
      {...props}
      sx={{
        '& .MuiStepConnector-line': {
          borderTopWidth: 6,
          marginTop: '-4px',
          borderColor: 'grey.100',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
        },
        [`&.${stepConnectorClasses.active}`]: {
          '& .MuiStepConnector-line': {
            borderColor: 'primary.main', // Color when the step is active
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          '& .MuiStepConnector-line': {
            borderColor: 'primary.main', // Color when the step is completed
          },
        },
        [`&.${stepConnectorClasses.disabled}`]: {
          '& .MuiStepConnector-line': {
            borderColor: 'grey.100', // Color when the step is not yet reached
          },
        },
      }}
    />
  );

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;
    const icons: { [index: number]: React.ReactElement | null } = {
      1: null,
      2: active || completed ? <CheckCircle /> : <CircleIcon />,
      3: active || completed ? <CheckCircle /> : <CircleIcon />,
      4: active || completed ? <CheckCircle /> : <CircleIcon />,
      5: active || completed ? <CheckCircle /> : <CircleIcon />,
    };
    const icon = icons[Number(props.icon)];
    const hasIcon = !!icon;

    return (
      <Box
        sx={{
          // eslint-disable-next-line no-nested-ternary
          color: active
            ? 'primary.main'
            : completed
            ? 'primary.main'
            : 'grey.100',
          width: '1.375rem',
          height: '1.375rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
          borderRadius: '100%',
          zIndex: '1',
          border:
            hasIcon && (active || completed) ? '4px solid #36BF8A' : 'none',
          outline: hasIcon && active ? '4px solid #BEE8D5' : 'none',
        }}
      >
        {icon}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', marginBottom: '16px' }}>
      <Typography marginBottom="12px" fontWeight="bold">
        Progress
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{
          '& .MuiStepConnector-root': {
            left: 'calc(-50% + 8px)',
            right: 'calc(50% + 8px)',
          },
          padding: 0,
          '.MuiStepConnector-line': {
            marginLeft: 0,
          },
          '.MuiStep-root': {
            padding: 0,
          },
          '.MuiStepLabel-label': {
            marginTop: '8px',
            whiteSpace: 'normal',
            textAlign: 'center',
          },
        }}
      >
        {steps.map((label, index) => {
          return (
            <Step
              sx={{
                '&.MuiStep-root ': {
                  flex: index === 0 ? '0.5' : '1',
                },
              }}
              key={label}
            >
              <StepLabel
                sx={{
                  '& .MuiStepLabel-labelContainer': {
                    width: '100px',
                  },
                }}
                StepIconComponent={ColorlibStepIcon}
              >
                <span data-testid="step-label">{label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default ProgressBar;
