import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { User } from 'containers/Modals/ShareModal/types';
import {
  FileSharingInput,
  GetUploadUrlMutation,
  GetUploadUrlMutationVariables,
  OwnFilesQueryVariables,
  RemoveFileMutation,
  RemoveFileMutationVariables,
  ShareNewFilesMutation,
  ShareNewFilesMutationVariables,
  UploadInput,
} from 'graphql/generated';
import {
  GET_UPLOAD_URL,
  REMOVE_FILE,
  SHARE_NEW_FILES,
} from 'graphql/mutations/file';
import { OWN_FILES, SHARED_FILES } from 'graphql/queries/file';
import { INVESTOR } from 'graphql/queries/investor';
import { Role } from 'models/User';
import { logger } from 'services/logger';
import useGetQueriesVariablesByOperation from 'utils/useGetQueriesByOperation';

const useFileActions = () => {
  const { cache } = useApolloClient();
  const getOwnFilesActiveQueryVariables = useGetQueriesVariablesByOperation();
  const [
    getUploadUrlMutation,
    { error: getUploadUrlError, loading: getUploadUrlLoading },
  ] = useMutation<GetUploadUrlMutation, GetUploadUrlMutationVariables>(
    GET_UPLOAD_URL,
  );
  const [removeMutation, { error: removeError, loading: removeLoading }] =
    useMutation<RemoveFileMutation, RemoveFileMutationVariables>(REMOVE_FILE);
  const [shareMutation, { error: shareError, loading: shareLoading }] =
    useMutation<ShareNewFilesMutation, ShareNewFilesMutationVariables>(
      SHARE_NEW_FILES,
    );

  const getUploadUrl = useCallback(
    async (input: UploadInput) => {
      try {
        const response = await getUploadUrlMutation({
          fetchPolicy: 'no-cache',
          variables: { input },
        });
        const data = response.data?.uploadUrl;

        return data;
      } catch (error) {
        logger.error(error);
        return undefined;
      }
    },
    [getUploadUrlMutation],
  );

  const remove = useCallback(
    async (variables: RemoveFileMutationVariables) => {
      try {
        const response = await removeMutation({
          fetchPolicy: 'no-cache',
          optimisticResponse: {
            removeFile: {
              __typename: 'RemoveFile',
              ok: true,
            },
          },
          variables,
        });
        const data = response.data?.removeFile?.ok;

        if (data) {
          cache.evict({ id: `FileType:${variables.fileId}` });
          cache.gc();
        }

        return true;
      } catch (error) {
        logger.error(error);
        return false;
      }
    },
    [cache, removeMutation],
  );

  const share = useCallback(
    async ({
      recipientRole,
      files,
      allAdvisors,
      allInvestors,
      allPermission,
      users,
    }: Omit<FileSharingInput, 'users'> & {
      recipientRole: Role;
      users: User[];
    }) => {
      try {
        const activeOwnFilesVariables =
          getOwnFilesActiveQueryVariables<OwnFilesQueryVariables>({
            query: OWN_FILES,
          });

        const refetchQueries = [
          ...((recipientRole === 'INVESTOR' &&
            users?.flatMap((user) => {
              const queries: {
                query: DocumentNode;
                variables: { [key: string]: any };
              }[] = [
                {
                  query: SHARED_FILES,
                  variables: { investor: user.userId, page: 1, pageSize: 3 },
                },
              ];

              if ('investorId' in user && user.investorId) {
                queries.push({
                  query: INVESTOR,
                  variables: { id: user.investorId },
                });
              }
              return queries;
            })) ||
            []),
          {
            query: OWN_FILES,
            variables: activeOwnFilesVariables,
          },
        ];

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'sharedFiles',
        });

        const usersVariable = users.map(({ userId, permission }) => ({
          userId,
          permission,
        }));

        await shareMutation({
          fetchPolicy: 'no-cache',
          refetchQueries,
          awaitRefetchQueries: true,
          variables: {
            input: {
              files,
              allAdvisors,
              allInvestors,
              allPermission,
              users: usersVariable,
            },
          },
        });

        return true;
      } catch (error) {
        logger.error(error);
        return false;
      }
    },
    [shareMutation, getOwnFilesActiveQueryVariables, cache],
  );

  return {
    error: !!getUploadUrlError || !!removeError || !!shareError,
    getUploadUrl,
    loading: getUploadUrlLoading || removeLoading || shareLoading,
    remove,
    share,
  };
};

export default useFileActions;
