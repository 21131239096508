import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(/^[0-9]+$/, 'Only numeric values are allowed')
    .min(4, 'Account number must be at least 4 digits')
    .max(4, 'Account number must be exactly 4 digits'),
  taxTransition: Yup.string()
    .required('Tax Transition is required')
    .notOneOf([], 'You must select an option'),
  capitalGainsBudget: Yup.string().when('taxTransition', {
    is: (value: string) => value === 'yes',
    then: Yup.string().required('Capital Gains Budget is required'),
    otherwise: Yup.string().notRequired(),
  }),
  taxTransitionYears: Yup.string().when('taxTransition', {
    is: (value: string) => value === 'yes',
    then: Yup.string().required('Tax Transition Years is required'),
    otherwise: Yup.string().notRequired(),
  }),
  monthlyCashRaise: Yup.string().notRequired(),
  additionalEmail: Yup.string().email('Invalid Email address').notRequired(),
});

export const validationRequestSchema = (actionType: any) => {
  return Yup.object().shape({
    accountNumber: Yup.string()
      .required('Account number is required')
      .matches(/^[0-9]+$/, 'Only numeric values are allowed')
      .min(4, 'Account number must be at least 4 digits')
      .max(4, 'Account number must be exactly 4 digits'),
    requestFrequency: Yup.string().when([], () => {
      return actionType === 'Cash Raise'
        ? Yup.string().required('Frequency is required')
        : Yup.string().notRequired();
    }),
    requestValue: Yup.number().when([], () => {
      return actionType === 'Cash Raise'
        ? Yup.number()
            .required('Value is required')
            .typeError('Value must be a number')
        : Yup.number().typeError('Value must be a number').notRequired();
    }),
    requestDetails: Yup.string().when([], () => {
      return actionType === 'Cash Raise'
        ? Yup.string().notRequired()
        : Yup.string().required('Value is required');
    }),
  });
};

// TODO: Move this to API Request
export const accountTypeOptions = [
  {
    label: 'Corporation',
    value: 'Corporation',
  },
  {
    label: 'Estate',
    value: 'Estate',
  },
  {
    label: 'Individual (TOD)',
    value: 'Individual (TOD)',
  },
  {
    label: 'Joint (Joint, Joint Tenant, JTWROS)',
    value: 'Joint (Joint, Joint Tenant, JTWROS)',
  },
  {
    label: 'Limited Liability Company',
    value: 'Limited Liability Company',
  },
  {
    label: 'Partnership',
    value: 'Partnership',
  },
  {
    label: 'Sole Proprietorship',
    value: 'Sole Proprietorship',
  },
  {
    label:
      'Trust (Revocable/Irrevocable trusts, with living or deceased grantors)',
    value:
      'Trust (Revocable/Irrevocable trusts, with living or deceased grantors)',
  },
  {
    label: 'UTMA/UGMA',
    value: 'UTMA/UGMA',
  },
  {
    label: 'Unincorporated Association',
    value: 'Unincorporated Association',
  },
  {
    label: 'Traditional IRA',
    value: 'Traditional IRA',
  },
  {
    label: 'Contributory IRA',
    value: 'Contributory IRA',
  },
  {
    label: 'Beneficiary IRA',
    value: 'Beneficiary IRA',
  },
  {
    label: 'Rollover IRA',
    value: 'Rollover IRA',
  },
  {
    label: 'Roth IRA',
    value: 'Roth IRA',
  },
  {
    label: 'SEP-IRA',
    value: 'SEP-IRA',
  },
  {
    label: 'Simple (Simplified Employee Pension Plan)',
    value: 'Simple (Simplified Employee Pension Plan)',
  },
  {
    label: 'Pension',
    value: 'Pension',
  },
];

// TODO: Move this to API Request? Use same as accountType
export const custodians = [
  {
    label: 'Schwab',
    value: 'Schwab',
  },
  {
    label: 'Fidelity',
    value: 'Fidelity',
  },
  {
    label: 'Alturist',
    value: 'Alturist',
  },
];
