import { ReactNode } from 'react';
import styled from 'styled-components';

import OverflowContainer from 'components/OverflowContainer';

import Header from './Header';
import useLogic from './logic';
import Row from './Row';
import Skeleton from './Skeleton';
import { Container, Body, EmptyState } from './styles';
import TableProvider from './TableContext';
import { Props } from './types';

function Table<DataType extends { id: string }>({
  id,
  data = [],
  empty,
  overlay,
  schema,
  actions,
  sortedBy,
  onSort,
  onAction,
  selected,
  selectAll,
  selectRow,
  selectRows,
  areAllSelected,
  loading,
  ...props
}: Props<DataType>) {
  const { handleSelection, previousRows } = useLogic();

  const { columns } = schema;
  let rows: ReactNode;

  if (data.length === 0 && empty && !loading) {
    rows = (
      <tr>
        <EmptyState>{empty}</EmptyState>
      </tr>
    );
  } else if (loading) {
    rows = (
      <Skeleton
        columns={columns}
        {...(previousRows.current && {
          rows: previousRows.current,
        })}
      />
    );
  } else {
    previousRows.current = data.length;
    rows = data.map((row) => {
      return (
        <Row<DataType>
          key={row.id}
          columns={columns}
          data={row}
          actions={actions}
          onAction={onAction}
        />
      );
    });
  }

  return (
    <OverflowContainer disabled={!!overlay}>
      <Container onMouseDown={handleSelection} id={id} {...props}>
        <TableProvider
          sortedBy={sortedBy}
          onSort={onSort}
          data={data}
          selected={selected}
          selectAll={selectAll}
          selectRow={selectRow}
          selectRows={selectRows}
          areAllSelected={areAllSelected}
        >
          <Header<DataType> columns={columns} hasActions={!!actions} />
          <Body>{rows}</Body>
        </TableProvider>
      </Container>
      {!!overlay && overlay}
    </OverflowContainer>
  );
}

export const GenericTable = <T extends { id: string }>() =>
  styled((props: Props<T>) => <Table<T> {...props} />)``;

export default Table;
