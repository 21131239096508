import { gql } from '@apollo/client';

import { USER_FRAGMENT } from 'graphql/fragments/user';

export const SEND_ONBOARDING_EMAIL = gql`
  mutation SendOnboardingEmail($investorId: UUID!) {
    sendOnboardingEmail(investorId: $investorId) {
      ok
    }
  }
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($input: UserProfileInput!) {
    editProfile(input: $input) {
      ok
      errors {
        birthDate
        email
        firstName
        lastName
        phone
      }
      user {
        ...UserParts
      }
    }
  }
  ${USER_FRAGMENT}
`;
