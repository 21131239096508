import { useEffect } from 'react';

export function ScrollToTopOnMount() {
  useEffect(() => {
    if (typeof window === 'undefined' || !('scrollTo' in window)) return;
    window.scrollTo(0, 0);
  }, []);

  return null;
}
