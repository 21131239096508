import { FC, memo } from 'react';

import { ReactComponent as Check } from 'assets/svg/checkboxCheck.svg';

import { Container, Hidden, Styled } from './styles';
import { Props } from './types';

const Checkbox: FC<Props> = ({
  className,
  checked,
  id,
  name,
  type = 'checkbox',
  ...props
}) => (
  <Container className={className}>
    <Hidden
      checked={checked}
      id={id || name}
      name={name}
      type={type}
      {...props}
    />
    <Styled htmlFor={id || name}>
      <Check disabled />
    </Styled>
  </Container>
);

export default memo(Checkbox);
