import { useState, createContext, useMemo } from 'react';

import AuthFetch from 'utils/authFetch';

import { InvestorInfo, InvestorStatus } from '../types/investorInfo';

export const InvestorInfoContext = createContext<{
  loadingInvestorInfo: boolean;
  investorInfo: InvestorInfo | null;
  fetchInvestorInfo: (investorId: string, useCache?: boolean) => void;
  handleResetInvestorInfo: Function;
}>({
  loadingInvestorInfo: false,
  investorInfo: null,
  fetchInvestorInfo: () => {},
  handleResetInvestorInfo: () => {},
});

// TODO: Change this
export const normalizeInvestorStatus = (input: InvestorStatus) => {
  switch (input) {
    case 'AC':
      return 'COMPLETED';
    case 'AI':
      return 'WAITING';
    case 'AW':
      return 'PENDING';
    case 'AO':
      return 'OPEN';
    case 'AS':
      return 'SENT';
    case 'AP':
    default:
      return 'NOT_SENT';
  }
};

export const normalizeAssessmentStatus = (input: InvestorStatus) => {
  switch (input) {
    case 'AC':
    case 'AO':
    case 'AW':
      return 'COMPLETED';
    case 'AI':
      return 'WAITING';
    case 'AS':
      return 'SENT';
    case 'AP':
    default:
      return 'NOT_SENT';
  }
};

const stupidNormalize = (data: any) => ({
  ...data,
  // TODO: This is dumb, need to fix statuses, this isn't assessment status really
  investorStatus: normalizeInvestorStatus(data.status),
  assessmentStatus: normalizeAssessmentStatus(data.status),
});

// Used to retrieve general Investor Info (not proposal or portfolio info)
export const InvestorInfoProvider = ({ children }: any) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [investorInfo, setInvestorInfo] = useState<InvestorInfo | null>(null);
  const [loadingInvestorInfo, setLoadingInvestorInfo] = useState<boolean>(true);

  const InvestorInfoValues = useMemo(
    () => ({
      fetchInvestorInfo: async (id: string, useCache: boolean = true) => {
        try {
          setLoadingInvestorInfo(true);
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/user/investor/${id}/info/`,
            method: 'GET',
            useCache,
          });
          const normalized = stupidNormalize(data.payload);
          setInvestorInfo(normalized);
        } catch (e: any) {
          setHasError(e.message);
        }

        setLoadingInvestorInfo(false);
      },
      handleResetInvestorInfo: () => {
        setLoadingInvestorInfo(true);
        setInvestorInfo(null);
      },
      investorInfo,
      loadingInvestorInfo,
      hasError,
    }),
    [investorInfo, loadingInvestorInfo, hasError],
  );

  return (
    <InvestorInfoContext.Provider value={InvestorInfoValues}>
      {children}
    </InvestorInfoContext.Provider>
  );
};
