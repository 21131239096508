import { m } from 'framer-motion';
import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

const Card = css<ContainerProps>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  padding: 0.75rem;
  ${({ variant, theme }) => {
    switch (variant) {
      case 'borderless':
        return css`
          border: none;
        `;
      case 'default':
      default:
        return css`
          border: 0.0625rem solid ${theme.colors.grey200};
        `;
    }
  }}
`;

export const Container = styled.div<ContainerProps>`
  ${Card};
`;

export const AnimatedContainer = styled(m.div).attrs(() => ({
  layoutTransition: { duration: 0.15 },
}))<ContainerProps>`
  ${Card}
`;
