import styled, { css } from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
import Status from 'components/Status';

export const Container = styled(Status)`
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem 0.375rem 0.5rem;

  ${({ theme, status }) => {
    switch (status) {
      case 'NOT SENT':
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.grey600};
        `;
      case 'SENT':
        return css`
          font-size: 0.75rem;
          font-weight: bold;
          letter-spacing: 0.0625rem;
          line-height: calc(18 / 12);
          text-decoration: none;
          background-color: ${theme.colors.white};
          color: ${theme.colors.grey600};
        `;
      case 'WAITING':
        return css`
          font-size: 0.75rem;
          font-weight: bold;
          letter-spacing: 0.0625rem;
          line-height: calc(18 / 12);
          text-decoration: none;
          background-color: ${theme.colors.white};
          color: ${theme.colors.tacao};
        `;
      default:
      case 'OPEN':
      case 'COMPLETED':
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.primary};
        `;
    }
  }}
`;

export const Check = styled(CheckIcon)`
  margin-right: 0.25rem;
`;
