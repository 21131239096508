import styled, { css } from 'styled-components';

import { LabelProps } from './types';

export const Label = styled.span<LabelProps>`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: 1rem;
  margin-bottom: 1rem;
  user-select: none;

  &:last-child {
    margin-bottom: unset;
  }

  ${({ checked, theme }) =>
    checked
      ? css`
          color: ${theme.colors.primary};
        `
      : null}
`;

export const Container = styled.label`
  display: flex;
  cursor: pointer;
  padding-bottom: 0.4375rem;
  padding-top: 0.4375rem;

  :hover,
  :focus-within {
    label {
      border: solid 0.0625rem ${({ theme }) => theme.colors.primary300};
    }
    ${Label} {
      opacity: 0.75;
    }
  }

  &[for=''] {
    cursor: not-allowed !important;
  }
`;

export const Wrapper = styled.div`
  margin-top: 0.125rem;
`;
