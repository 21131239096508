export const epiToLongSentence = (value: 'earth' | 'people' | 'integrity') => {
  const episMap = {
    earth: 'issues that affect the planet',
    people: 'issues that affect people',
    integrity: 'ethical management of companies',
  };
  return episMap[value];
};

export const epiToShortSentence = (value: 'earth' | 'people' | 'integrity') => {
  const episMap = {
    earth: 'earth',
    people: 'people',
    integrity: 'corporate integrity',
  };
  return episMap[value];
};

export const humanizeTimeHorizon = (value: Readonly<number[]>) => {
  const timesMap = {
    '1-4': 'just a bit of time',
    '4-10': 'some time',
    '10-20': 'plenty of time',
    '20-50': 'a very long time',
  };
  const valueAsKey = value.join('-') as keyof typeof timesMap;
  return timesMap[valueAsKey] || '';
};
