import { FC, memo } from 'react';

import { ReactComponent as Icon } from 'assets/svg/assetsEmpty.svg';

import { Container, Text } from './styles';
import { Props } from './types';

const AdvisorsEmpty: FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Icon />
      <Text>No advisors found!</Text>
    </Container>
  );
};

export default memo(AdvisorsEmpty);
