import styled from 'styled-components';

export const Container = styled.table`
  display: grid;
  min-width: 60rem;
`;

export const Body = styled.tbody`
  display: grid;
  grid-row-gap: 0.75rem;
  padding: 0.75rem 0;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
`;

export const EmptyState = styled.td`
  display: block;
`;
