import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function AccountHeader({
  hasSavedAccounts,
  handleAddAccount,
}: {
  hasSavedAccounts: boolean;
  handleAddAccount: any;
}) {
  return hasSavedAccounts ? (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.grey[900],
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        padding: '1.25rem',
        borderRadius: '6px',
      })}
    >
      <Box sx={{ flex: 5, display: 'flex' }}>
        <Typography flex={9} fontWeight="bold">
          Account Name
        </Typography>
        <Typography flex={7} fontWeight="bold">
          Account Type
        </Typography>
        <Typography flex={5} fontWeight="bold">
          Value
        </Typography>
        <Typography flex={3} fontWeight="bold">
          Equity
        </Typography>
        <Typography flex={3} fontWeight="bold">
          Fixed
        </Typography>
        <Typography flex={4} textAlign="center" fontWeight="bold">
          Risk Target
        </Typography>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Add Account">
          <Box>
            <IconButton
              onClick={handleAddAccount}
              sx={(theme) => ({
                borderRadius: '6px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                padding: '6px',
                '&:hover': {
                  backgroundColor: theme.palette.primary[500],
                },
              })}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.grey[900],
        padding: '1.25rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '6px',
      })}
    >
      <Typography fontWeight="bold">
        Propose your asset allocation by account
      </Typography>
      <Button
        sx={{
          textTransform: 'capitalize',
          display: 'flex',
        }}
        onClick={handleAddAccount}
        variant="contained"
        data-testid="add-account"
      >
        <AddCircleOutlineIcon fontSize="small" />
        <Typography sx={{ marginLeft: '6px', fontWeight: 'bold' }}>
          Add Account
        </Typography>
      </Button>
    </Box>
  );
}

export default AccountHeader;
