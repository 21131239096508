import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC, memo } from 'react';

import { Error } from 'components/Modal';
import RadioInputGroup from 'components/RadioInputGroup';
import SpreadsheetInput from 'components/SpreadsheetInput';

import useConnect from './connect';
import { metrics } from './constants';
import {
  Divider,
  Modal,
  Title,
  Wrapper,
  SwitchLabel,
  ContactMessage,
  ExampleTable,
} from './styles';
import { Props } from './types';

const EXAMPLE_DATA = {
  AAPL: {
    MARKET_VALUE: '$84,050.70',
    PERCENTAGE: '20%',
  },
  MSFT: {
    MARKET_VALUE: '$46,095.43',
    PERCENTAGE: '10%',
  },
  CASH: {
    MARKET_VALUE: '$6,095.43',
    PERCENTAGE: '15%',
  },
  OTHER: {
    MARKET_VALUE: '$15,095.43',
    PERCENTAGE: '30%',
  },
};

const ImportPortfolioModal: FC<Props> = () => {
  const {
    error,
    hasError,
    handleClose,
    handleDrop,
    handleImport,
    handleRemove,
    handleSelectMetricsValue,
    creationMethod,
    isMobile,
    isOpen,
    isValid,
    spreadsheet,
    isImportingPortfolio,
  } = useConnect();

  return (
    <Modal fullScreen={isMobile} onClose={handleClose} open={isOpen}>
      <Wrapper>
        <Title>Import portfolio</Title>
        <SpreadsheetInput
          acceptedFiles="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          file={spreadsheet}
          onDrop={handleDrop}
          onRemove={handleRemove}
        />
        <div style={{ display: 'flex', flex: 14 }}>
          <div
            style={{
              flex: 6,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <SwitchLabel>Select Value Type</SwitchLabel>
              <RadioInputGroup
                name="metrics"
                options={metrics}
                selectedValue={creationMethod}
                onChange={handleSelectMetricsValue}
              />
            </div>
            <div style={{ fontSize: '12px' }}>
              * The first column values must contain ticker symbols. The second
              column values must contain the allocation. See example on the
              right.
            </div>
          </div>
          <div
            style={{
              flex: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingLeft: '30px',
            }}
          >
            <SwitchLabel>Example Import File</SwitchLabel>
            <ExampleTable>
              <tbody>
                <tr>
                  <th>Ticker</th>
                  <th>Allocation</th>
                </tr>
                <tr>
                  <td>AAPL</td>
                  <td>{creationMethod && EXAMPLE_DATA.AAPL[creationMethod]}</td>
                </tr>
                <tr>
                  <td>MSFT</td>
                  <td>{creationMethod && EXAMPLE_DATA.MSFT[creationMethod]}</td>
                </tr>
                <tr style={{ fontSize: '18px' }}>
                  <td>...</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>CASH$</span>
                  </td>
                  <td>{creationMethod && EXAMPLE_DATA.CASH[creationMethod]}</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 600 }}>OTHER</span>
                  </td>
                  <td>
                    {creationMethod && EXAMPLE_DATA.OTHER[creationMethod]}
                  </td>
                </tr>
              </tbody>
            </ExampleTable>
            <div style={{ fontSize: '12px', marginTop: '14px' }}>
              {creationMethod === 'MARKET_VALUE' && '($ symbol is optional)'}
              {creationMethod === 'PERCENTAGE' && (
                <span>Must add up to 100% (% symbol is optional)</span>
              )}
            </div>
          </div>
        </div>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            margin: '1.5rem 0',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{
              fontWeight: 'bold',
              textTransform: 'initial',
              minWidth: '120px',
            }}
            disabled={!isValid || isImportingPortfolio}
            onClick={handleImport}
            variant="contained"
          >
            Import Portfolio
          </Button>
          <Button
            sx={{
              fontWeight: 'bold',
              textTransform: 'initial',
              minWidth: '120px',
            }}
            variant="outlined"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Wrapper>
      {error ? (
        <Error>
          {hasError.length > 0 ? (
            <>
              An error has occurred during upload.
              {hasError.map((err) => (
                <ContactMessage key={err}>{err}</ContactMessage>
              ))}
            </>
          ) : (
            <>
              An error has occurred during upload.
              <ContactMessage>
                Please check your upload settings as well as your import file
                and try again.
              </ContactMessage>
              {creationMethod === 'PERCENTAGE' && (
                <ContactMessage>
                  Ensure your percentages add up to 100%.
                </ContactMessage>
              )}
            </>
          )}
          <ContactMessage>
            If the problem persists{' '}
            <a href="mailto:hello@seedsinvestor.com">Contact Us</a>
          </ContactMessage>
        </Error>
      ) : null}
    </Modal>
  );
};

export default memo(ImportPortfolioModal);
