import styled from 'styled-components';

import { ReactComponent as WRDIcon } from 'assets/svg/files/fileDoc.svg';
import { ReactComponent as PDFIcon } from 'assets/svg/files/filePDF.svg';
import { ReactComponent as TXTIcon } from 'assets/svg/files/fileText.svg';
import { ReactComponent as XLSIcon } from 'assets/svg/files/fileXLS.svg';
import { ReactComponent as ZipIcon } from 'assets/svg/files/zip.svg';
import Icon from 'components/Icon';

const Container = styled(Icon)`
  height: 90px;
  width: 94px;
  margin: 0px 0px 10px;
`;

export const TXT = styled(Container).attrs(() => ({
  icon: <TXTIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

export const WRD = styled(Container).attrs(() => ({
  icon: <WRDIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

export const PDF = styled(Container).attrs(() => ({
  icon: <PDFIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.flamingo};
`;

export const XLS = styled(Container).attrs(() => ({
  icon: <XLSIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Zip = styled(Container).attrs(() => ({
  icon: <ZipIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.tacao};
`;
