import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Answers from './Answers';
import { useConnect } from './connect';
import Results from './Results';

const LazyForm = lazy(() => import('./Form'));

const Survey = () => {
  const { path } = useConnect();

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route component={Results} exact path={`${path}/results`} />
        <Route component={Answers} exact path={`${path}/answers`} />
        <Route component={LazyForm} path={`${path}/:step`} />
        <Route path={path}>
          <Redirect to="survey/1" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Survey;
