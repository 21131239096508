import { Redirect, Route, Switch } from 'react-router-dom';

import AppLayout from 'containers/Main/AppLayout';

import Survey from '../Survey';
import InvestorHome from './InvestorHome';

const Investor = () => {
  return (
    <AppLayout disableNavLinks isInvestor>
      <Switch>
        <Route component={InvestorHome} path="/home" />
        <Route component={Survey} path="/survey" />
        <Redirect to="/home" />
      </Switch>
    </AppLayout>
  );
};

export default Investor;
