import { FC } from 'react';

import { Container, Text } from './styles';
import { Props } from './types';

const PrettyLoading: FC<Props> = ({ text = 'Loading...' }) => {
  return (
    <Container>
      <Text>{text}</Text>
    </Container>
  );
};

export default PrettyLoading;
