import styled from 'styled-components';

import { ReactComponent as AddIcon } from 'assets/svg/plusCircle.svg';
import { ReactComponent as ShareIcon } from 'assets/svg/share.svg';
import BaseButton from 'components/Button';
import BaseCard from 'components/Card';
import ContainerMaxWidth from 'components/ContainerMaxWidth';
import DefaultPagination from 'components/Pagination';
import { from } from 'styles/media';

import { CardProps } from './types';

export const Container = styled(ContainerMaxWidth)`
  margin-bottom: 5rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AddButton = styled(BaseButton).attrs(() => ({
  icon: <AddIcon />,
  variant: 'primary',
  to: '/clients/add',
}))`
  text-decoration: none;
`;
export const ShareButton = styled(BaseButton).attrs(() => ({
  icon: <ShareIcon />,
  variant: 'primary',
}))``;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 7.25rem;
  justify-content: space-between;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  line-height: calc(36 / 24);
`;

export const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

export const Card = styled(BaseCard).attrs(() => ({
  variant: 'borderless',
}))<CardProps>`
  width: ${({ width }) => `${width}px`};
  padding: 0.75rem 0.75rem 0.9375rem 0.75rem;
  * > table {
    min-width: unset;
  }
`;

export const TableFooter = styled.div`
  padding: 1.0625rem 1rem;

  ${from.tablet} {
    align-items: center;
    display: flex;
  }
`;

export const Pagination = styled(DefaultPagination)`
  margin-top: 1rem;

  ${from.tablet} {
    margin-top: 0;
    margin-left: auto;
  }
`;

export const ResultsWrapper = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
`;

export const Results = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
`;
