import { useMemo, useCallback } from 'react';

import NonCustodian from 'components/Account/Implement/NonCustodian';
import Modal from 'components/NewModal';
import {
  AccountType,
  AccountTypeType,
  AccountCustodianType,
} from 'context/fastContext/account/types';

function ImplementAccount({
  accountId,
  setAccountToImplement,
  confirmNonCustodianImplement,
  hasErrorImplementingNonCustodian,
  accounts,
  accountTypes,
  accountCustodians,
}: {
  accountId: string | null;
  setAccountToImplement: any;
  confirmNonCustodianImplement: any;
  hasErrorImplementingNonCustodian: boolean;
  accounts: AccountType[];
  accountTypes: AccountTypeType[];
  accountCustodians: AccountCustodianType[];
}) {
  const close = useCallback(() => {
    setAccountToImplement(null);
  }, [setAccountToImplement]);

  const accountToImplement = useMemo(
    () => accounts.find((acc) => acc.id === accountId),
    [accountId, accounts],
  );

  const accountType = accountTypes.find(
    (at) => at.id === accountToImplement?.accountType,
  );

  const custodianType = accountCustodians.find(
    (cus) => cus.id === accountToImplement?.accountCustodian,
  );

  const isOpen = useMemo(
    () => !!accountToImplement && !custodianType?.skipApproval,
    [accountToImplement, custodianType?.skipApproval],
  );

  return (
    <Modal
      sx={{
        maxWidth: 'initial',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 24px)',
      }}
      close={close}
      isOpen={isOpen}
      transitionSpeed="0.5s"
    >
      <NonCustodian
        confirmNonCustodianImplement={confirmNonCustodianImplement}
        accountToImplement={accountToImplement}
        accountType={accountType}
        custodianType={custodianType}
        hasErrorImplementingNonCustodian={hasErrorImplementingNonCustodian}
        onClose={close}
        isOpen={isOpen}
      />
    </Modal>
  );
}

export default ImplementAccount;
