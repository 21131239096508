export const riskColors: { [key: number]: string } = {
  1: '#ADB2B0',
  2: '#CCFAE5',
  3: '#91E3C2',
  4: '#24B07A',
  5: '#1c7552',
  6: '#D5C9FE',
  7: '#A199DE',
  8: '#6449C0',
  9: '#FCC79E',
  10: '#D48A57',
};
