import { FC } from 'react';

import { Selector, Container, Icon } from './styles';
import { Props } from './types';

const Select: FC<Props> = ({
  className,
  error,
  variant,
  children,
  disabled,
  ...props
}) => {
  return (
    <Container className={className} $variant={variant}>
      <Selector
        variant={variant}
        {...props}
        disabled={!!disabled}
        $error={error}
      >
        {children}
      </Selector>
      <Icon />
    </Container>
  );
};

export default Select;
