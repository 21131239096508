import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { Container, Check } from './styles';
import { Props } from './types';

const Badge: FC<Props> = ({ status, to, ...props }) => {
  return (
    <Container
      status={status}
      {...(to ? ({ as: Link, to } as any) : {})}
      {...props}
    >
      {status === 'COMPLETED' ? <Check /> : null}
      {status}
    </Container>
  );
};

export default memo(Badge);
