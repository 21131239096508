import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationParts on NotificationType {
    id
    created
    notificationType
    read
    contentName
    sharedBySeeds
    customText
    customUrl
  }
`;
