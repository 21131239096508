import {
  ApolloClient,
  ApolloLink,
  // HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';

import { link as statusNotifierLink } from 'graphql/statusNotifier';
import { logger } from 'services/logger';
import { removeIncompletes } from 'services/session';
import {
  removeRefreshToken,
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  removeToken,
} from 'services/storage';
import validateTokenExpiration from 'utils/validateTokenExpiration';

import { modal } from './variables';

function createNotifierLink(link: any, baseLink: any): ApolloLink {
  return baseLink.concat(link);
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        modal: {
          read() {
            return modal();
          },
        },
      },
    },
    InvestorType: {
      fields: {
        sharedFiles: {
          // prefer incoming from existing
          merge: false,
        },
      },
    },
    PortfolioType: {
      fields: {
        assetList: {
          merge(existing = [], incoming = []) {
            if (incoming.length === 0) {
              return existing;
            }
            return [...incoming];
          },
        },
      },
    },
  },
});

const wsLink = new WebSocketLink({
  options: {
    connectionParams: () => {
      const token = getToken();

      return token ? { authToken: token } : {};
    },
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 1,
  },
  uri: process.env.REACT_APP_WS_ENDPOINT!,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  const isTokenValid = validateTokenExpiration(token!);
  const { operationName } = operation;
  const isLogin = operationName === 'Login';
  operation.setContext(({ headers = {} }) => ({
    headers:
      token && isTokenValid && !isLogin
        ? {
            ...headers,
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
          }
        : {
            ...headers,
            Accept: 'application/json',
          },
  }));

  return forward(operation);
});

const httpLink = createNotifierLink(
  authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    }) as any,
  ),
  statusNotifierLink,
);

const logoutLink = onError((res) => {
  const { networkError } = res;
  const error =
    !!networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401;

  if (error) removeToken();
});

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'token',
  isTokenValidOrUndefined: () => {
    const token = getToken();
    if (!token) {
      return true;
    }

    return validateTokenExpiration(token);
  },
  fetchAccessToken: () => {
    const refreshToken = getRefreshToken();
    return fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          mutation {
            refreshToken(refreshToken: "${refreshToken}") {
              token
              refreshToken
            }
          }
        `,
      }),
    });
  },
  handleFetch: async (accessToken, operation) => {
    const { refreshToken } = operation.getContext();
    setToken(accessToken);
    setRefreshToken(refreshToken);
  },
  handleResponse: (operation, accessTokenField) => async (response: any) => {
    if (response.status !== 200) {
      return;
    }

    const { data } = await response.json();
    if (!data || !data.refreshToken) {
      throw new Error('Unable to retrieve new access token');
    }

    operation.setContext({
      refreshToken: data.refreshToken.refreshToken,
    });

    // eslint-disable-next-line consistent-return
    return {
      [accessTokenField]: data.refreshToken.token,
      refreshToken: data.refreshToken.refreshToken,
    };
  },
  handleError: async () => {
    await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          mutation LOGOUT {
            logoutUser {
              ok
            }
          }
        `,
      }),
    });
    // clear local session
    removeIncompletes();
    removeToken();
    removeRefreshToken();
    window.location.href = `/login?loggedOutBy=inactivity&continue=${
      window.location.pathname + window.location.search
    }`;
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

// log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    errorLink,
    logoutLink,
    refreshTokenLink,
    authLink,
    splitLink,
  ]),
});

export default client;
