import { InvestorQuery, InvestorStatus, MeQuery } from 'graphql/generated';

import { normalizeSurveyResult, normalizeSurvey } from './Survey';

type Input = NonNullable<
  InvestorQuery['investor'] | NonNullable<MeQuery['me']>['investor']
>;

export const normalizeAssessmentStatus = (input: InvestorStatus) => {
  switch (input) {
    case 'AC':
      return 'COMPLETED';
    case 'AI':
      return 'WAITING';
    case 'AW':
      return 'PENDING';
    case 'AO':
      return 'OPEN';
    case 'AS':
      return 'SENT';
    case 'AP':
    default:
      return 'NOT_SENT';
  }
};

export const normalizeInvestor = (input: Input) => {
  const currentSurvey = input.currentSurvey
    ? normalizeSurvey(input.currentSurvey)
    : undefined;
  const surveyResult = input.surveyResult
    ? normalizeSurveyResult(input.surveyResult)
    : undefined;

  return {
    currentSurvey,
    surveyResult,
  };
};

export const normalizeUserInvestor = (input: Input) => {
  const advisor = {
    shortHash: input?.advisor?.shortHash,
  };
  const currentSurvey = input.currentSurvey
    ? normalizeSurvey(input.currentSurvey)
    : undefined;
  const surveyResult = input.surveyResult
    ? normalizeSurveyResult(input.surveyResult)
    : undefined;

  return {
    id: input.id,
    advisor,
    currentSurvey,
    surveyResult,
  };
};

export type Investor = ReturnType<typeof normalizeInvestor>;

export type UserInvestor = ReturnType<typeof normalizeUserInvestor>;
