export function getTitleAndMessage({
  portfolioHasBeenCleared,
  isPortfolioDirty,
  isPortfolioValid,
}: {
  portfolioHasBeenCleared: boolean;
  isPortfolioDirty: boolean;
  isPortfolioValid: boolean;
}) {
  if (portfolioHasBeenCleared && isPortfolioValid) {
    return {
      title: 'Unsaved changes',
      message: `The portfolio was cleared but not saved, going back restores the portfolio state to how it was before clearing.`,
    };
  }

  if (isPortfolioDirty && isPortfolioValid) {
    return {
      title: 'Unsaved changes',
      message: `The portfolio has been changed but not saved, going back restores the portfolio state to how it was before editing it.`,
    };
  }

  return {
    title: `Changes needed`,
    message: `Your client's portfolio is incomplete. Please add at least one portfolio holding with a value greater than 0 to proceed.`,
  };
}
