import {
  AssetGroupType,
  ProductSelectionType,
  ProposalScoring,
  ProposalStateType,
  ProposalType,
  SectorKeys,
} from './proposal/types';

const sectorsMap: Record<SectorKeys, string> = {
  consumerDiscretionary: 'Consumer Discretionary',
  consumerStaples: 'Consumer Staples',
  energy: 'Energy',
  financials: 'Financial Services',
  healthcare: 'Healthcare',
  industrials: 'Industrials',
  informationTechnology: 'Technology',
  materials: 'Materials',
  realEstate: 'Real Estate',
  telecommunicationServices: 'Communication Services',
  utilities: 'Utilities',
};

type SectorMapping = {
  sector: string;
  value: number;
}[];

export const normalizeEpiPriority = (input: string | null | undefined) => {
  return input
    ? Array.from(input).map((char) => {
        switch (char) {
          case 'E':
            return 'earth';
          case 'P':
            return 'people';
          case 'I':
          default:
            return 'integrity';
        }
      })
    : [];
};

export const sectorsMapper = (
  proposalScoring: ProposalScoring,
): SectorMapping => {
  const mapped = (
    Object.keys(sectorsMap) as Array<keyof typeof sectorsMap>
  ).reduce<SectorMapping>((mem, cur) => {
    const score = proposalScoring[cur] as number;
    mem.push({
      sector: sectorsMap[cur],
      value: Math.round(score * 100) / 100,
    });

    return mem;
  }, []);

  return mapped;
};

/*
 * If any account has an implemented or pending status then it is Accounts in Progress
 * If all accounts have implemented status then implemented
 * Proposal complete means - saved to vault
 */
export const determineProposalState = (
  proposals: { status: ProposalType['status'] | string }[],
): ProposalStateType => {
  if (proposals.length === 0) return 'DRAFT';
  if (proposals.every((p) => p.status === 'deployed')) return 'IMPLEMENTED';
  if (proposals.some((p) => ['pending', 'deployed'].includes(p.status!)))
    return 'PENDING';
  if (proposals.every((p) => p.status === 'active')) return 'ACTIVE';

  return 'DRAFT';
};

// TODO: Move all risk calculations to backend
export const calculateRiskScore = (equitiesAllocation: number): number => {
  if (equitiesAllocation === 100) return 10;
  if (equitiesAllocation >= 90) return 9;
  if (equitiesAllocation >= 80) return 8;
  if (equitiesAllocation >= 70) return 7;
  if (equitiesAllocation >= 60) return 6;
  if (equitiesAllocation >= 50) return 5;
  if (equitiesAllocation >= 40) return 4;
  if (equitiesAllocation >= 30) return 3;
  if (equitiesAllocation >= 20) return 2;
  return 1;
};

function adjustAllocations(
  allocations: Record<string, number>,
  assetClasses: AssetGroupType[],
): Record<string, number> {
  const adjustedAllocations: Record<string, number> = { ...allocations };
  let parentAllocationSum = 0;

  // Step 1: Normalize parent allocations to sum up to 100 and round them
  assetClasses.forEach((parent) => {
    if (parent.children) {
      // It's a top-level asset
      adjustedAllocations[parent.symbol] = Math.round(
        adjustedAllocations[parent.symbol],
      );
      parentAllocationSum += adjustedAllocations[parent.symbol];
    }
  });

  const roundingError = 100 - parentAllocationSum;
  if (roundingError !== 0) {
    adjustedAllocations[assetClasses[0].symbol] += roundingError; // Adjust the first parent for simplicity
  }

  // Step 2: Adjust children allocations
  assetClasses.forEach((parent) => {
    if (parent.children && parent.children.length) {
      let childrenSum = 0;
      parent.children.forEach((child) => {
        childrenSum += allocations[child.symbol];
      });

      if (childrenSum === 0) {
        // If sum of children's allocation is 0, directly assign 0 to avoid division
        parent.children.forEach((child) => {
          adjustedAllocations[child.symbol] = 0;
        });
      } else {
        const parentAllocation = adjustedAllocations[parent.symbol];
        let allocatedSum = 0;

        parent.children.forEach((child, index) => {
          if (index === parent.children.length - 1) {
            // Last child gets the remainder to avoid rounding issues
            adjustedAllocations[child.symbol] = parentAllocation - allocatedSum;
          } else {
            const childAllocation = Math.round(
              (allocations[child.symbol] / childrenSum) * parentAllocation,
            );
            adjustedAllocations[child.symbol] = childAllocation;
            allocatedSum += childAllocation;
          }
        });
      }
    }
  });

  return adjustedAllocations;
}

export function calculateAllocations(
  allocations: ProductSelectionType[],
  assetClasses: AssetGroupType[],
): Record<string, number> {
  const allocationsBySymbol: Record<string, number> = {};

  // Include both parent and children asset classes in idToSymbolMap
  const idToSymbolMap: Record<
    string,
    { symbol: string; parentSymbol?: string }
  > = {};
  assetClasses.forEach((parent) => {
    // Include parent itself in the mapping
    idToSymbolMap[parent.id] = { symbol: parent.symbol };

    // Initialize parent symbols with zero allocation
    allocationsBySymbol[parent.symbol] = 0;

    parent.children?.forEach((child) => {
      // Initialize child symbols with zero allocation
      allocationsBySymbol[child.symbol] = 0;

      // Map child ID to its symbol and parent's symbol
      idToSymbolMap[child.id] = {
        symbol: child.symbol,
        parentSymbol: parent.symbol,
      };
    });
  });

  // Calculate allocations by asset class ID
  allocations.forEach((allocation) => {
    const mapping = idToSymbolMap[allocation?.assetClassId || allocation?.id];
    if (mapping) {
      const { symbol, parentSymbol } = mapping;
      // Update allocation for the symbol (can be a child or directly a parent)
      allocationsBySymbol[symbol] +=
        allocation.allocation || allocation.weight!;
      // Additionally, update allocation for the parent symbol if it exists
      if (parentSymbol) {
        allocationsBySymbol[parentSymbol] +=
          allocation.allocation || allocation.weight!;
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `No matching asset class found for ID: ${
          allocation.assetClassId || allocation?.id
        }`,
      );
    }
  });

  return adjustAllocations(allocationsBySymbol, assetClasses);
}
