import styled from 'styled-components';

export const FolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  text-align: center;

  > span {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0px;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
    }
  }
`;
