import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import RiskNumber from 'components/RiskNumber';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import {
  AccountType,
  AccountTypeType,
} from 'context/fastContext/account/types';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import { ProposalType } from 'context/fastContext/proposal/types';

function AccountProposalDisplay() {
  const [allProposals] = useProposalStore((s: any) => s.allProposals);
  const [{ accounts, accountTypes }] = useAccountStore((s: any) => ({
    accounts: s.accounts as AccountType[],
    accountTypes: s.accountTypes as AccountTypeType[],
  }));
  return (
    <Box>
      <Box sx={{ display: 'flex', marginBottom: '6px' }}>
        <Typography flex={9} fontWeight="bold">
          Account Name
        </Typography>
        <Typography flex={7} fontWeight="bold">
          Account Type
        </Typography>
        <Typography flex={5} fontWeight="bold">
          Value
        </Typography>
        <Typography flex={3} fontWeight="bold">
          Equity
        </Typography>
        <Typography flex={3} fontWeight="bold">
          Fixed
        </Typography>
        <Typography flex={4} textAlign="center" fontWeight="bold">
          Risk Target
        </Typography>
      </Box>
      {accounts?.map(
        ({ id, name, accountType, amountInvestedDollar, proposalId }) => {
          const associatedProposal = allProposals.find(
            (p: ProposalType) => p.id === proposalId,
          );
          const accountTypeName = accountTypes.find(
            (at) => at.id === accountType,
          )?.name;

          return (
            <Box key={id} sx={{ display: 'flex', marginBottom: '3px' }}>
              <Typography flex={9}>{name}</Typography>
              <Typography flex={7}>{accountTypeName}</Typography>
              <Typography flex={5}>
                $ {Number(amountInvestedDollar).toLocaleString()}
              </Typography>
              <Typography flex={3}>
                {associatedProposal?.allocations?.EQ}%
              </Typography>
              <Typography flex={3}>
                {associatedProposal?.allocations?.BD}%
              </Typography>
              <Box sx={{ display: 'flex', flex: 4, justifyContent: 'center' }}>
                <RiskNumber number={associatedProposal?.riskScore!} />
              </Box>
            </Box>
          );
        },
      )}
    </Box>
  );
}

export default AccountProposalDisplay;
