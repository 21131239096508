import { memo } from 'react';

import { Container, Label } from './styles';
import { Props } from './types';

const Status = ({ className, status }: Props) => {
  return (
    <Container $status={status} className={className}>
      <Label $status={status}>
        {status?.replaceAll('_', ' ')?.toUpperCase()}
      </Label>
    </Container>
  );
};

export default memo(Status);
