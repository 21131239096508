import styled, { css } from 'styled-components';

import IconButton from 'components/IconButton';

export const Container = styled.div<{ $disabled?: boolean }>`
  align-items: center;
  display: flex;
  height: 2.25rem;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.25;
    `}
`;

export const Name = styled.div`
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: calc(20 / 14);
  overflow-x: hidden;
  padding-right: 0.375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.0625rem;
`;

export const Email = styled.div`
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: calc(20 / 14);
  overflow-x: hidden;
  padding-right: 0.375rem;
  text-overflow: ellipsis;
`;

export const Remove = styled(IconButton)`
  background: none;
  border: none;
  flex: 0 0 2.25rem;
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey500};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.flamingo};
      }
    `}
`;
