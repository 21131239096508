import { parse, format } from 'date-fns';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useHouseholdActions from 'graphql/hooks/useHouseholdActions';
import useModal from 'graphql/hooks/useModal';
import useTemporaryIndicator from 'utils/useNotifySaved';

import { FormValues } from './types';

const useConnect = () => {
  const { open } = useModal('REMOVE_HOUSEHOLD');
  const { id: investorId } = useParams<{ id: string }>();
  const { update, error } = useHouseholdActions();
  const [wasSaved, notifySave] = useTemporaryIndicator();
  const { push } = useHistory();
  const { investorInfo } = useInvestorInfo();

  const handleOpenModal = useCallback(() => open(), [open]);

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }) => {
      try {
        const input = {
          advisorId: values.advisor,
          name: values.householdName,
          others: values.others
            .filter((el: any) => {
              const keys = Object.keys(el);
              return keys
                .filter((k) => k !== 'phone' && k !== 'birthDate')
                .every((fieldKey) => !!el[fieldKey]);
            })
            .map((other: any) => {
              const id = investorInfo?.household?.householdPersonList.find(
                (el) => el.email === other.email,
              )?.id;
              return {
                ...other,
                id,
                birthDate: other.birthDate
                  ? format(
                      parse(other.birthDate, 'MM/dd/yyyy', new Date()),
                      'yyyy-MM-dd',
                    )
                  : undefined,
              };
            }),
          primary: {
            ...values.primary,
            email: values.primary.email,
            birthDate: values.primary.birthDate
              ? format(
                  parse(values.primary.birthDate, 'MM/dd/yyyy', new Date()),
                  'yyyy-MM-dd',
                )
              : undefined,
          },
        };

        const success = await update(input, investorId);
        if (success) {
          notifySave();
          push(`/clients/${investorId}/profile`);
        }
        if (error) {
          setErrors(error);
        }
      } catch (e) {
        const err = e as GraphQLError;
        setErrors({ others: err.message });
      }
    },
    [
      update,
      investorId,
      error,
      investorInfo?.household?.householdPersonList,
      notifySave,
      push,
    ],
  );

  return {
    investorInfo,
    handleOpenModal,
    handleSubmit,
    wasSaved,
  };
};

export default useConnect;
