import styled from 'styled-components';

import BaseCard from 'components/Card';

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Card = styled(BaseCard).attrs(() => ({ variant: 'borderless' }))`
  overflow: hidden;
  border-radius: 1.25rem;
  margin-bottom: 7.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  padding: 0;
  width: 58.75rem;
`;

export const ContactUs = styled.a`
  color: ${({ theme }) => theme.colors.dark};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 2rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  padding: 5rem;
`;

export const HeaderTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0;
  line-height: calc(44 / 32);
  position: absolute;
  top: 3.75rem;
`;
