import { gql } from '@apollo/client';

import { MIN_ADVISOR_FRAGMENT } from './advisor';
import { MIN_HOUSEHOLD_FRAGMENT } from './household';
import { MIN_CURRENT_PORTFOLIO_FRAGMENT } from './portfolio';
import {
  MIN_SURVEY_RESULT_FRAGMENT,
  SURVEY_RESULT_FRAGMENT,
  SURVEY_FRAGMENT,
} from './survey';
import { USER_FRAGMENT } from './user';

export const PERSONALITY_FRAGMENT = gql`
  fragment PersonalityParts on PersonalityBadgeType {
    id
    name
    approachText
    approachValue
    perspectiveText
    perspectiveValue
    mindsetText
    mindsetValue
    imageUrl
  }
`;

export const MIN_INVESTOR_FRAGMENT = gql`
  fragment MinInvestorParts on InvestorType {
    id
    status
    riskLevel
    incomeLevel
    user {
      ...UserParts
    }
    advisor {
      ...MinAdvisorParts
    }
    currentSurvey {
      ...SurveyParts
    }
    surveyResult {
      ...MinSurveyResultParts
    }
    household {
      ...MinHouseholdParts
    }
    currentPortfolio {
      ...MinCurrentPortfolioParts
    }
    personalityBadge {
      ...PersonalityParts
    }
  }
  ${PERSONALITY_FRAGMENT}
  ${MIN_ADVISOR_FRAGMENT}
  ${MIN_CURRENT_PORTFOLIO_FRAGMENT}
  ${MIN_HOUSEHOLD_FRAGMENT}
  ${SURVEY_FRAGMENT}
  ${MIN_SURVEY_RESULT_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const INVESTOR_FRAGMENT = gql`
  fragment InvestorParts on InvestorType {
    id
    currentSurvey {
      ...SurveyParts
    }
    surveyResult {
      ...SurveyResultParts
    }
    advisor {
      shortHash
    }
  }
  ${SURVEY_FRAGMENT}
  ${SURVEY_RESULT_FRAGMENT}
`;
