export const calculateDescription = (completePercentage: number) => {
  let description = '';

  if (completePercentage > 0 && completePercentage <= 1) {
    description = 'Dangerous';
  } else if (completePercentage > 1 && completePercentage <= 2) {
    description = 'Harmful';
  } else if (completePercentage > 2 && completePercentage <= 3) {
    description = 'Neutral';
  } else if (completePercentage > 3 && completePercentage <= 4) {
    description = 'Beneficial';
  } else if (completePercentage > 4 && completePercentage <= 5) {
    description = 'Ideal';
  }
  return description;
};
