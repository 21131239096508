import { FC, memo } from 'react';
import { useDropzone } from 'react-dropzone';

import Dropbox from './Dropbox';
import FileList from './FileList';
import { ListWrapper, UploadButton } from './styles';
import { Props } from './types';

const FileInput: FC<Props> = ({
  onDrop,
  onRemove,
  onRetryUpload,
  fileList,
  acceptedFiles,
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFiles,
    });

  return (
    <>
      <input type="file" {...getInputProps()} accept={acceptedFiles} />
      {fileList.length ? (
        <ListWrapper>
          <FileList
            fileList={fileList}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            onRemove={onRemove}
            onRetryUpload={onRetryUpload}
            rootProps={getRootProps()}
          />
          <UploadButton onClick={getRootProps().onClick}>
            Upload more files
          </UploadButton>
        </ListWrapper>
      ) : (
        <Dropbox
          rootProps={getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        />
      )}
    </>
  );
};

export default memo(FileInput);
