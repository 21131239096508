import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import PrettyLoading from 'components/PrettyLoading';
import AddHousehold from 'containers/Main/AddHousehold';
import AppLayout from 'containers/Main/AppLayout';
import Clients from 'containers/Main/Clients';
import NewFeature from 'containers/Modals/Feature';
import TermsModal from 'containers/Modals/TermsModal';
import { from, useMediaQuery } from 'styles/media';

import Disclosures from '../Disclosures';
import Profile from '../Profile';
import Terms from '../Terms';
// import Shared from './Shared';

const Advisor = () => {
  const isMobile = !useMediaQuery(from.tablet);
  const isProposal = useLocation().pathname.includes('/proposal');

  if (isMobile && !isProposal) {
    return (
      <PrettyLoading text="Seeds platform does not support the current screen size" />
    );
  }

  return (
    <>
      <AppLayout>
        <Switch>
          <Route component={AddHousehold} exact path="/clients/add" />
          <Route component={Clients} path="/clients" />
          <Route component={Disclosures} exact path="/disclosures" />
          <Route component={Profile} exact path="/profile" />
          {/* <Route component={Shared} path="/shared" /> */}
          <Route component={Terms} exact path="/terms-and-conditions" />
          <Redirect to="/clients" />
        </Switch>
      </AppLayout>
      <TermsModal />
      <NewFeature />
    </>
  );
};

export default Advisor;
