import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { InvestorQuery, InvestorQueryVariables } from 'graphql/generated';
import { INVESTOR } from 'graphql/queries/investor';
import { normalizeInvestor } from 'models/Investor';

const useInvestor = (
  id?: string,
  options?: QueryHookOptions<InvestorQuery, InvestorQueryVariables>,
) => {
  const { data, loading, refetch } = useQuery<
    InvestorQuery,
    InvestorQueryVariables
  >(INVESTOR, {
    ...options,
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const investor = useMemo(
    () => (data?.investor ? normalizeInvestor(data.investor) : undefined),
    [data],
  );

  return { investor, loading, refetch };
};

export default useInvestor;
