/* eslint-disable no-console */
import { useState, createContext, useMemo } from 'react';

import { normalizeEpiPriority } from 'context/fastContext/helpers';
import AuthFetch from 'utils/authFetch';
import { cacheService } from 'utils/cacheService';

import type { InvestorProfileType } from '../types/investor';
import type {
  CurrentProposal,
  HistoricProductsRequest,
} from '../types/proposal';

const defaultProposal = {
  products: [],
  score: {},
  sectors: [],
  holdingExamples: [],
  holdings: [],
  status: null,
  id: '',
  isHistoric: false,
};

const sectorsMapper = (proposalScoring: any) => {
  const sectorsMap: any = {
    consumerDiscretionary: 'Consumer Discretionary',
    consumerStaples: 'Consumer Staples',
    energy: 'Energy',
    financials: 'Financial Services',
    healthcare: 'Healthcare',
    industrials: 'Industrials',
    informationTechnology: 'Technology',
    materials: 'Materials',
    realEstate: 'Real Estate',
    telecommunicationServices: 'Communication Services',
    utilities: 'Utilities',
  };

  const mapped = Object.keys(sectorsMap).reduce((mem: any, cur: string) => {
    mem.push({
      sector: sectorsMap[cur],
      value: Math.round(proposalScoring[cur] * 100) / 100,
    });

    return mem;
  }, []);

  return mapped;
};

export const InvestorContext = createContext<{
  currentProposal: CurrentProposal;
  investorProfile: InvestorProfileType;
  hasError: boolean;
  investorProposalHistory: HistoricProductsRequest[];
  investorProposalHistoryLoading: boolean;
  fetchInvestorProfile: Function;
  fetchInvestorProposalHistory: Function;
  fetchInvestorCurrentProposal: Function;
  fetchInvestorProposalByProposalId: Function;
  saveInvestorSelections: Function;
  handleResetError: Function;
  handleResetInvestor: Function;
  loading: boolean;
  isSavingProposal: boolean;
  loadingProfile: boolean;
  loadingCurrentProposal: boolean;
  discardDraft: Function;
  activateDraft: Function;
  isDiscardingOrActivatingDraft: boolean;
  deactivateProposal: Function;
}>({
  currentProposal: { ...defaultProposal },
  investorProfile: {},
  investorProposalHistory: [],
  investorProposalHistoryLoading: false,
  loading: false,
  isSavingProposal: false,
  hasError: false,
  loadingProfile: false,
  loadingCurrentProposal: false,
  isDiscardingOrActivatingDraft: false,
  fetchInvestorProfile: () => {},
  fetchInvestorProposalHistory: () => {},
  fetchInvestorCurrentProposal: () => {},
  fetchInvestorProposalByProposalId: () => {},
  saveInvestorSelections: () => {},
  handleResetError: () => {},
  handleResetInvestor: () => {},
  // fetchCurrentPortfolio: () => {},
  discardDraft: () => {},
  activateDraft: () => {},
  deactivateProposal: () => {},
});

// TODO: this can probably be split into three providers
//  InvestorProvider, ProposalProvider, PortfolioProvider
// TODO: Try switching to "fast context" when refactoring providers
export const InvestorProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [loadingCurrentProposal, setLoadingCurrentProposal] =
    useState<boolean>(false);
  const [isSavingProposal, setIsSavingProposal] = useState<boolean>(false);
  const [isDiscardingOrActivatingDraft, setIsDiscardingOrActivatingDraft] =
    useState<boolean>(false);
  const [currentProposal, setCurrentProposal] = useState<any>({
    ...defaultProposal,
  });

  const [hasError, setHasError] = useState(false);

  const [investorProposalHistory, setInvestorProposalHistory] = useState<
    HistoricProductsRequest[]
  >([]);
  const [investorProposalHistoryLoading, setInvestorProposalHistoryLoading] =
    useState<boolean>(false);
  const [investorProfile, setInvestorProfile] = useState<InvestorProfileType>(
    {},
  );

  const Investor = useMemo(
    () => ({
      fetchInvestorProfile: async (
        investorId: string,
        useCache: boolean = true,
      ): Promise<InvestorProfileType | null> => {
        setLoadingProfile(true);
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}v2/investor/profile/base`,
            queryParams: { id: investorId },
            method: 'GET',
            useCache,
          });

          let profile = data.payload;

          if (profile.valueRankings || profile.epiPriority) {
            profile = {
              ...profile,
              valueRankings: profile.valueRankings
                ? JSON.parse(profile.valueRankings)
                : null,
              normalizedEpiPriority: normalizeEpiPriority(profile.epiPriority),
            };
          }

          setInvestorProfile(profile);
          setLoadingProfile(false);

          return profile;
        } catch (e: any) {
          setHasError(true);
          setLoadingProfile(false);
          return null;
        }
      },
      fetchInvestorProposalHistory: async (
        investorId: string,
        useCache: boolean = true,
      ) => {
        try {
          setInvestorProposalHistoryLoading(true);
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/history/`,
            method: 'GET',
            useCache,
          });

          if (data.status) {
            setInvestorProposalHistory(data.payload);
          }
        } catch (e) {
          console.log('Error fetching proposal list');
        }

        setInvestorProposalHistoryLoading(false);
      },
      // This gets the latest created proposal which can be active or draft
      fetchInvestorCurrentProposal: async (
        investorId: string,
        setLoadingWhenFetching = true,
        useCache = true,
      ) => {
        if (setLoadingWhenFetching) setLoading(true);
        if (setLoadingWhenFetching) setLoadingCurrentProposal(true);
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/current/`,
            method: 'GET',
            useCache,
          });

          setCurrentProposal({
            ...data.payload,
            sectors: sectorsMapper(data.payload.score),
          });

          setLoading(false);
          setLoadingCurrentProposal(false);
        } catch (e: any) {
          setHasError(true);
          setLoading(false);
          setLoadingCurrentProposal(false);
        }
      },
      fetchInvestorProposalByProposalId: async (
        proposalId: string,
        proposalIndex: number,
      ) => {
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${proposalId}`,
            method: 'GET',
          });

          setCurrentProposal({
            ...data.payload,
            sectors: sectorsMapper(data.payload.score),
            isHistoric: proposalIndex !== 0,
          });

          setLoading(false);
          setLoadingCurrentProposal(false);
        } catch (e: any) {
          setLoading(false);
          setLoadingCurrentProposal(false);
        }
      },
      saveInvestorSelections: async (
        investorId: string,
        selectedProducts: any,
        isDraft: boolean,
      ) => {
        setIsSavingProposal(true);
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/`,
            method: 'POST',
            body: {
              investorId,
              productAllocations: selectedProducts.map((sp: any) => ({
                ...sp,
                productId: sp.id,
              })),
              isDraft,
            },
            useCache: false,
          });
          if (!data?.status) {
            setHasError(true);
            return false;
          }

          await Investor.refetchAfterProposalChanges(investorId);

          setIsSavingProposal(false);
          return true;
        } catch (e) {
          console.log('Error saving and/or retrieving latest proposal', e);
          setIsSavingProposal(false);
          return false;
        }
      },
      activateDraft: async (investorId: string) => {
        try {
          setIsDiscardingOrActivatingDraft(true);
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/activate/`,
            method: 'GET',
            useCache: false,
          });

          if (data.status) {
            await Investor.refetchAfterProposalChanges(investorId);
          }
        } catch (e: any) {
          console.log('Error activating draft', e);
        }
        setIsDiscardingOrActivatingDraft(false);
      },
      deactivateProposal: async (investorId: string, proposalId: string) => {
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${proposalId}/deactivate/`,
            method: 'GET',
            useCache: false,
          });

          if (data.status) {
            // Invalidate proposal history
            cacheService.invalidateByKey({
              url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/history/`,
              method: 'GET',
            });

            // Fetch latest proposal history
            await Investor.fetchInvestorProposalHistory(investorId);
          }
        } catch (e: any) {
          console.log('Error activating draft', e);
        }
        setIsDiscardingOrActivatingDraft(false);
      },
      discardDraft: async (investorId: string) => {
        setIsDiscardingOrActivatingDraft(true);
        try {
          const data = await AuthFetch({
            url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/discard/`,
            method: 'GET',
            useCache: false,
          });

          if (data.status) {
            await Investor.refetchAfterProposalChanges(investorId);
          }
        } catch (e: any) {
          console.log('Error activating draft', e);
        }
        setIsDiscardingOrActivatingDraft(false);
      },
      // TODO: Ensure this is working alright
      refetchAfterProposalChanges: async (investorId: string) => {
        // Invalidate request for current selections
        cacheService.invalidateByKey({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/current/`,
          method: 'GET',
        });

        // Fetch latest current
        await Investor.fetchInvestorCurrentProposal(investorId, false);

        // Invalidate proposal history
        cacheService.invalidateByKey({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/proposal/${investorId}/history/`,
          method: 'GET',
        });

        // Fetch latest proposal history
        await Investor.fetchInvestorProposalHistory(investorId);
      },

      handleResetInvestor: () => {
        setCurrentProposal({ ...defaultProposal });
        setHasError(false);
        setInvestorProfile({});
        setInvestorProposalHistory([]);
      },
      handleResetError: () => {
        setHasError(false);
      },
      currentProposal,
      investorProfile,
      investorProposalHistory,
      investorProposalHistoryLoading,
      hasError,
      loading,
      isSavingProposal,
      loadingProfile,
      loadingCurrentProposal,
      isDiscardingOrActivatingDraft,
    }),
    [
      currentProposal,
      investorProfile,
      investorProposalHistory,
      investorProposalHistoryLoading,
      hasError,
      loading,
      isSavingProposal,
      loadingProfile,
      loadingCurrentProposal,
      isDiscardingOrActivatingDraft,
    ],
  );

  return (
    <InvestorContext.Provider value={Investor}>
      {children}
    </InvestorContext.Provider>
  );
};
