import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      uidb64
      require2FA
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT {
    logoutUser {
      ok
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation SendVerificationCode($token: String!, $uidb64: String!) {
    sendVerificationCode(token: $token, uidb64: $uidb64)
  }
`;

export const SET_INVESTOR_ONBOARDING_PASSWORD = gql`
  mutation SetInvestorOnboardingPassword(
    $input: SetInvestorOnboardingPasswordInput!
  ) {
    setInvestorOnboardingPassword(input: $input) {
      surveyResult {
        status
      }
      user {
        email
      }
    }
  }
`;

export const SET_RESET_PASSWORD = gql`
  mutation SetResetPassword($input: SetResetPasswordInput!) {
    setResetPassword(input: $input) {
      id
    }
  }
`;

export const VALIDATE_ADVISOR_ONBOARDING = gql`
  mutation ValidateAdvisorOnboarding($token: String!, $uidb64: String!) {
    validateAdvisorOnboarding(token: $token, uidb64: $uidb64) {
      isAdvisor {
        user {
          firstName
          lastName
          phone
        }
        company
        companyLogo
      }
    }
  }
`;

export const SET_ADVISOR_ONBOARDING_PASSWORD = gql`
  mutation SetAdvisorOnboardingPassword($input: AdvisorOnboardingInput!) {
    setAdvisorOnboardingPassword(input: $input) {
      token
      refreshToken
    }
  }
`;

export const VALIDATE_INVESTOR_ONBOARDING_TOKEN = gql`
  mutation ValidateInvestorOnboardingToken($token: String!, $uidb64: String!) {
    validateInvestorOnboardingToken(token: $token, uidb64: $uidb64) {
      token
      refreshToken
      investorName
      activeSurveyStatus
    }
  }
`;

export const VALIDATE_PARAMETER_TOKEN = gql`
  mutation ValidateParameterToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const VALIDATE_VERIFICATION_CODE = gql`
  mutation ValidateVerificationCode(
    $code: String!
    $token: String!
    $uidb64: String!
  ) {
    validateVerificationCode(code: $code, token: $token, uidb64: $uidb64) {
      token
      refreshToken
    }
  }
`;
