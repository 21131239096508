import styled, { css } from 'styled-components';

import BaseModal, { Title as BaseTitle } from 'components/Modal';
import { prettyScrollbar } from 'styles/mixins';

export const Modal = styled(BaseModal)`
  .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        inline-size: 30rem;
        min-block-size: 34.125rem;
        overflow-y: auto;
        ${prettyScrollbar}

        > div {
          display: flex;
          flex-direction: column;
          row-gap: 0.35rem;
          block-size: 100%;
        }
      `};
  }
`;

export const ClickHereButton = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 6px;
  a {
    color: #36bf8a;
    text-decoration: none;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  margin-block-start: auto;
  > * {
    margin-inline-end: 1.25rem;
    &:last-child {
      margin-inline-end: auto;
    }
  }
`;

export const Title = styled(BaseTitle)``;

export const Allocations = styled.div`
  display: grid;
  row-gap: 1rem;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  inline-size: 100%;

  fieldset {
    display: contents;
  }

  legend {
    color: ${({ theme }) => theme.colors.grey900};
    font-weight: ${({ theme }) => theme.weights.bold};
    margin: 0;
  }

  output {
    display: flex;
    justify-self: flex-end;
    color: ${({ theme }) => theme.colors.grey600};
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  label {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  hr {
    background: ${({ theme }) => theme.colors.grey200};
    height: 1px;
    inline-size: 100%;
    border: none;
    grid-column: 1 / -1;
    margin: 0;
  }
`;
