import styled from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';
import { from } from 'styles/media';

export const Buttons = styled.div`
  display: flex;
`;

export const BackButton = styled(Button)`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 0.5rem;
`;

export const Container = styled(Card).attrs(() => ({
  variant: 'borderless',
  animated: true,
}))`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 55rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey300}`};

  ${from.tablet} {
    height: 32.3125rem;
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${from.desktop} {
    padding: 2rem 2.5rem 2.5rem 2.5rem;
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.flamingo};
  font-size: 0.75rem;
  position: absolute;
  bottom: 1.25rem;
  left: 16.25rem;
`;

export const ProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  ${from.mobile} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${from.tablet} {
    margin-top: auto;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 18px;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 2rem;
  margin-top: 1.5rem;

  strong {
    font-weight: bold !important;
  }

  p {
    margin: 30px 0 !important;

    &:first-of-type {
      margin-top: 0 !important;
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
`;

export const Hint = styled.span`
  margin-bottom: 30px;
`;

export const Image = styled.img`
  flex-shrink: 0;
  border-radius: 0.625rem 0 0 0.625rem;
  width: 13.75rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary50};

  ${from.tablet} {
    margin-top: auto;
  }
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-top: 0.75rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  text-align: center;
  text-align: left;
`;

export const SecondaryTitle = styled.h2`
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  svg {
    margin-right: 5px;
    animation: spin 2s infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SecondaryTitleLight = styled.h2`
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;

  h2 {
    margin: 0;
  }

  .Bar {
    display: flex;
    &__dots {
      width: 100px;
    }
  }
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.grey300};
  margin: 2rem 0;
  border: 0;
  height: 1px;

  @media print {
    margin: 1rem 0;
  }
`;

export const RadioButtonContainer = styled.div`
  display: block;
  color: red;
  > div {
    > label {
      > div {
        align-items: start;

        > div {
          margin-top: 4px;
        }
      }
    }
  }
`;

export const ActionPanel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;

  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const WideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55rem;
  overflow: auto;
`;
