const TERMS_KEY = 'seeds:terms';
const TOKEN_KEY = 'seeds:token';
const REFRESH_TOKEN_KEY = 'seeds:refresh:token';

export const getTerms = () => localStorage.getItem(TERMS_KEY) === 'true';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const removeTerms = () => localStorage.removeItem(TERMS_KEY);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const setTerms = (termsValue: boolean) =>
  localStorage.setItem(TERMS_KEY, termsValue.toString());

export const setToken = (tokenValue: string) =>
  localStorage.setItem(TOKEN_KEY, tokenValue);

export const setRefreshToken = (tokenValue: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, tokenValue);

export const removeRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_KEY);
