import { gql } from '@apollo/client';

import {
  SURVEY_FRAGMENT,
  SURVEY_STEP_FRAGMENT,
} from 'graphql/fragments/survey';

export const ACTIVE_SURVEY = gql`
  query ActiveSurvey {
    activeSurvey {
      ...SurveyParts
    }
  }
  ${SURVEY_FRAGMENT}
`;

export const SURVEY_STEP_RESULT = gql`
  query SurveyStepResult($investorId: UUID, $stepId: UUID!) {
    resultStep(investorId: $investorId, stepId: $stepId) {
      id
      step {
        ...SurveyStepParts
      }
      checkboxesSelected
      choiceSelected
      id
      orderItemsSelected
      rangeSelected
    }
  }
  ${SURVEY_STEP_FRAGMENT}
`;
