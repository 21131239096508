import { useFormikContext } from 'formik';
import { Location } from 'history';
import { memo, useMemo, useCallback } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import { FormValues } from 'containers/Main/Portfolio/types';

import Confirm from '../Confirm';
import { getTitleAndMessage } from './logic';

const InvalidFormPrompt = ({
  portfolioHasBeenCleared,
}: {
  portfolioHasBeenCleared: boolean;
}) => {
  const { isValid, values, dirty: isDirty } = useFormikContext<FormValues>();
  const { title, message } = useMemo(
    () =>
      getTitleAndMessage({
        isPortfolioDirty: isDirty,
        isPortfolioValid: isValid,
        portfolioHasBeenCleared,
      }),
    [isValid, portfolioHasBeenCleared, isDirty],
  );
  const handleShowExitConfirm = useCallback(
    (
      currentLocation: Location<unknown>,
      nextLocation?: Location<unknown> | undefined,
    ) => {
      const nextLocationSearchParams = new URLSearchParams(
        nextLocation?.search,
      );
      const isLoggedOutByInactivity =
        nextLocationSearchParams.get('loggedOutBy') === 'inactivity';
      return (
        !isLoggedOutByInactivity &&
        (!isValid ||
          isDirty ||
          (portfolioHasBeenCleared && values.assets?.length === 0))
      );
    },
    [portfolioHasBeenCleared, values, isValid, isDirty],
  );

  return (
    <NavigationPrompt disableNative when={handleShowExitConfirm}>
      {({ isActive, onConfirm, onCancel }) => (
        <Confirm
          show={isActive}
          onCancel={onCancel}
          onConfirm={onConfirm}
          title={title}
        >
          {message}
        </Confirm>
      )}
    </NavigationPrompt>
  );
};

export default memo(InvalidFormPrompt);
