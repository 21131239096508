import styled from 'styled-components';

import Card from 'components/Card';
import { TextWrap } from 'styles/mixins';

export const Container = styled(Card)`
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  height: 4.375rem;
  padding: 1.0313rem 0.7813rem 0.9688rem 0.7813rem;
  width: 16.875rem;
`;

export const Link = styled.a`
  all: unset;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-left: 0.75rem;
  flex-direction: column;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  height: 1.0625rem;
  letter-spacing: 0;
  line-height: calc(17 / 14);
  ${TextWrap}
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  height: 1.25rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  max-width: 12.5rem;
  ${TextWrap};
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.colors.dark};
`;
