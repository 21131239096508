import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  SubmitSurveyMutation,
  SubmitSurveyMutationVariables,
} from 'graphql/generated';
import { SUBMIT_SURVEY } from 'graphql/mutations/survey';
import { INVESTOR } from 'graphql/queries/investor';

const useSurveyActions = () => {
  const [sendLinkMutation, { loading }] = useMutation<
    SubmitSurveyMutation,
    SubmitSurveyMutationVariables
  >(SUBMIT_SURVEY);

  const sendLink = useCallback(
    async (variables: { email?: string; investorId: string }) => {
      await sendLinkMutation({
        fetchPolicy: 'no-cache',
        optimisticResponse: {
          sendSurvey: {
            __typename: 'SendSurveyInvestorMutation',
            ok: true,
          },
        },
        variables,
        refetchQueries: [
          {
            query: INVESTOR,
            variables: { id: variables.investorId },
          },
        ],
      });
    },
    [sendLinkMutation],
  );

  return {
    loading,
    sendLink,
  };
};

export default useSurveyActions;
