import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FC, useState } from 'react';
import { BsXCircle } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';

import useInvestorActions from '../../context/fastContext/investor/actions';
import Header from './Header';
import {
  EmailButton,
  Block,
  BlockParagraph,
  Bold,
  Container,
  Content,
  GoalsResultsWrapper,
  HeaderTitle,
  HeaderAction,
  Result,
  ResultTitle,
  SectionTitle,
  SectionContent,
} from './styles';
import { Props } from './types';

const SurveyResultsCard: FC<Props> = ({
  isMobile = false,
  children,
  title,
  triggerPrint,
  printView = false,
  ...props
}) => {
  const { push } = useHistory();
  const [{ id: investorId }] = useInvestorStore((s: any) => ({ id: s.id }));
  const { emailResults } = useInvestorActions();
  const [emailSent, setEmailSent] = useState(false);

  const handleClose = () => {
    if (investorId) {
      push(`/clients/${investorId}`);
    } else {
      // If no investor ID we are assuming they are logged in as an investor
      push('/home/profile?isCompleted=true');
    }
  };

  const handleEmailClick = () => {
    emailResults(investorId);
    setTimeout(() => {
      setEmailSent(true);
    }, 500);
    setTimeout(() => {
      setEmailSent(false);
    }, 6000);
  };

  return (
    <Container {...props}>
      <Header>
        <HeaderTitle>{title ?? 'Assessment results'}</HeaderTitle>
        <HeaderAction>
          {triggerPrint && (
            <IconButton
              onClick={() => triggerPrint()}
              sx={(theme) => ({
                borderRadius: '6px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover': {
                  backgroundColor: theme.palette.primary[500],
                },
              })}
            >
              <PrintIcon fontSize="small" />
            </IconButton>
          )}
          <Tooltip title="Email results" placement="top">
            <EmailButton
              onClick={handleEmailClick}
              sx={(theme) => ({
                borderRadius: '6px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover': {
                  backgroundColor: theme.palette.primary[500],
                },
              })}
            >
              {emailSent ? (
                <CheckIcon fontSize="small" data-icon="check" />
              ) : (
                <EmailIcon fontSize="small" />
              )}
            </EmailButton>
          </Tooltip>
          {!printView && (
            <Button
              icon={<BsXCircle />}
              iconPlacement="right"
              variant="secondary"
              onClick={handleClose}
              data-testid="survey-results-button-close"
            >
              Close&nbsp;
            </Button>
          )}
        </HeaderAction>
      </Header>
      <Content $isMobile={isMobile}>{children}</Content>
    </Container>
  );
};

export default SurveyResultsCard;

export {
  Block,
  BlockParagraph,
  Bold,
  GoalsResultsWrapper,
  Result,
  ResultTitle,
  SectionTitle,
  SectionContent,
};
