import { FormikHelpers, FormikValues } from 'formik';
import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { DEFAULT_LEVELS } from 'components/LevelBar/constants';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
// import useInvestor from 'context/hooks/Investor.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
// import { useRecentSharedFiles } from 'graphql/hooks/useRecentSharedFiles';
import useActiveSurvey from 'graphql/hooks/useSurvey';
import useSurveyActions from 'graphql/hooks/useSurveyActions';
import { useEnqueueSnackbar } from 'hooks/useEnqueueSnackbar';
import { logger } from 'services/logger';

import { Action, Actions } from '../types';

export function useConnect() {
  const { survey } = useActiveSurvey();
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  const { investorInfo, loadingInvestorInfo, fetchInvestorInfo } =
    useInvestorInfo();
  // const {
  //   currentProposal: { status },
  // } = useInvestor();

  const [status] = useProposalStore((s: any) => s.masterProposal.status);

  const hasSeedsProposal = !!status;

  // const recentSharedFiles = useRecentSharedFiles({
  //   userId: investorInfo?.user?.id,
  // });

  const { sendLink } = useSurveyActions();

  const enqueueSnackbar = useEnqueueSnackbar();

  const people = useMemo(
    () =>
      [
        ...(investorInfo?.householdPerson
          ? [investorInfo.householdPerson]
          : []),
        ...(investorInfo?.household?.householdPersonList
          ? investorInfo.household.householdPersonList
          : []),
      ].map((person, index) => ({
        fullName: person.fullName,
        isPrimary: index === 0,
        id: person.id,
        email: person.email,
        date: person.birthDate,
        phone: person.phone,
      })),
    [investorInfo],
  );

  const riskTolerance = DEFAULT_LEVELS.find(
    ({ name }) =>
      name.toUpperCase() === investorInfo?.surveyResult?.riskTolerance,
  );

  const sendSurveyEmail = useCallback(
    async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
      const { email: emailInput } = values;
      try {
        await sendLink({
          investorId: id,
          email: emailInput,
        });
        enqueueSnackbar('The survey was sent to your client.', {
          variant: 'success',
        });
      } catch (error) {
        logger.error(error);
        enqueueSnackbar('An error has ocurred. Try again.', {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [id, sendLink, enqueueSnackbar],
  );

  const can = useCallback(
    (action: Action) => {
      const actions: Partial<Actions> = {
        'show-survey-results': hasSeedsProposal,
        'create-survey': !hasSeedsProposal,
      };

      const value = actions[action];
      if (typeof value === 'function') {
        return value();
      }
      return value;
    },
    [hasSeedsProposal],
  );

  return {
    // investor,
    investorInfo,
    investorId: id,
    people,
    // recentSharedFiles,
    riskTolerance,
    url,
    can,
    sendSurveyEmail,
    survey,
    fetchInvestorInfo,
    loadingInvestorInfo,
  };
}
