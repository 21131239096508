import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  overflow: hidden;
  flex: 5;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  min-height: 100vh;
`;
