import { FC } from 'react';

import animations from './animations';
import { CloseButton, Content, Error, Root, Title, Text } from './styles';
import { Props } from './types';

const Modal: FC<Props> = ({
  animation = 'slideUp',
  children,
  fullScreen,
  onClose,
  open,
  showCloseButton = true,
  disableEnforceFocus = false,
  ...props
}) => (
  <Root
    TransitionComponent={animations[animation]}
    closeAfterTransition
    fullScreen={fullScreen}
    onClose={onClose}
    open={open}
    disableEnforceFocus={disableEnforceFocus}
    {...props}
  >
    {showCloseButton && <CloseButton onClick={onClose} />}
    <Content>{children}</Content>
  </Root>
);

export default Modal;

export { Error, Title, Text };
