import styled, { css } from 'styled-components';

export const Container = styled.tr<{ selectable: boolean; selected: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  height: 2.75rem;
  padding: 0.625rem 0 0.625rem 1rem;

  ${({ theme, selectable, selected }) =>
    selectable &&
    css`
      > td:first-child {
        flex: 0;
      }

      > td:nth-child(2) {
        margin-left: 0;
      }

      > td:not(:first-child) {
        overflow: hidden;
      }

      ${selected && `background-color: ${theme.colors.primary10};`}
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary10};
  }
`;

export const Cell = styled.td<{
  $width?: string;
  $align?: 'start' | 'end';
  $sortable?: boolean;
}>`
  flex: 1;
  display: flex;
  justify-content: ${({ $align }) => `flex-${$align || 'start'}`};

  & + & {
    margin-left: 1rem;
  }

  ${({ $sortable, $align }) =>
    $sortable &&
    $align === 'end' &&
    css`
      padding-right: 1rem;
    `}

  ${({ $width }) =>
    $width &&
    css`
      flex-grow: 0;
      flex-basis: ${$width};
    `}
`;

export const Actions = styled.td`
  flex-grow: 0;
  flex-basis: 4rem;
  display: flex;
  justify-content: flex-end;
`;
