// TODO: FO - Ensure GraphQL logic is removed when switching over to DRF login
import { removeIncompletes } from 'services/session';
import {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
} from 'services/storage';
import { cacheService } from 'utils/cacheService';
import validateTokenExpiration from 'utils/validateTokenExpiration';

type KeyValuePair = { [key: string]: any };

export class CustomError extends Error {
  statusCode: number;

  data: any;

  constructor(message: string, statusCode: number, data?: any) {
    super(message);
    this.name = 'CustomError';
    this.statusCode = statusCode;
    this.data = data;
  }
}

async function logOut(): Promise<void> {
  await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT as string, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation LOGOUT {
          logoutUser {
            ok
          }
        }
      `,
    }),
  });
  // clear local session
  removeIncompletes();
  removeToken();
  removeRefreshToken();
  window.location.href = `/login?loggedOutBy=inactivity&continue=${
    window.location.pathname + window.location.search
  }`;
}

function generateUrlWithQueryString({
  url,
  queryParams,
}: {
  url: string;
  queryParams: KeyValuePair;
}): string {
  const urlObj = new URL(url);

  Object.entries(queryParams).forEach(([key, value]: [string, any]) => {
    urlObj.searchParams.append(key, value);
  });

  return urlObj.toString();
}

async function fetchNewAccessToken(refreshToken: string): Promise<boolean> {
  const response = await fetch(
    process.env.REACT_APP_GRAPHQL_ENDPOINT as string,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        mutation {
          refreshToken(refreshToken: "${refreshToken}") {
            token
            refreshToken
          }
        }
      `,
      }),
    },
  );

  if (response.status !== 200) {
    return false;
  }

  const { data } = await response.json();
  if (!data || !data.refreshToken) {
    return false;
  }

  const { token, refreshToken: newRefreshToken } = data.refreshToken;

  setToken(token);
  setRefreshToken(newRefreshToken);

  return true;
}

type AuthFetchProps = {
  url: string;
  method: string;
  queryParams?: KeyValuePair;
  body?: KeyValuePair;
  useCache?: Boolean;
  contentType?: string;
};

function generateAuthHeaders(): Headers {
  const token = getToken();

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  return headers;
}

// Used to make requests for protected resources.
// If the access token is expired it will try to get a new one if a refresh token is present.
async function AuthFetch({
  url,
  method,
  queryParams,
  body,
  useCache = true,
  contentType = 'application/json',
}: AuthFetchProps) {
  if (useCache) {
    // If useCache is true then check if request is in cache first
    const cachedResponse = cacheService.get({ url, method, queryParams, body });

    if (cachedResponse !== null) {
      return cachedResponse;
    }
  }

  // If it does not exist in the cache or is expired, make the network request
  const token = getToken();
  if (!token) {
    throw new CustomError(`No access token present...`, 401);
  }

  const isTokenValid = validateTokenExpiration(token);

  if (!isTokenValid) {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      const retrievedNewToken = await fetchNewAccessToken(refreshToken);
      if (!retrievedNewToken) {
        throw new CustomError('Error retrieving new access token...', 401);
      }
    } else {
      throw new CustomError('No refresh token present...', 401);
    }
  }

  const formattedUrl = queryParams
    ? generateUrlWithQueryString({ url, queryParams })
    : url;

  const headers = generateAuthHeaders();

  const fetchParams: any = { method, headers };

  if (body && contentType === 'application/json') {
    fetchParams.body = JSON.stringify(body);
  } else if (body && contentType === 'multipart/form-data') {
    headers.delete('Content-Type');
    const payload = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      payload.append(key, value);
    });
    fetchParams.body = payload;
  }

  const response = await fetch(formattedUrl, fetchParams);

  if (!response.ok) {
    throw new CustomError(`${response.statusText}`, response.status);
  }

  const data = await response.json();

  // Store response in cache
  cacheService.set({ url, method, queryParams, body }, data);

  return data;
}

export default async function AuthFetchWithLogoutWrapper(
  props: AuthFetchProps,
) {
  try {
    return await AuthFetch(props);
  } catch (e: any) {
    // Only logout if it's a 401 error
    if (e.statusCode === 401) {
      await logOut();
    }
    throw e;
  }
}
