import { DocumentNode, getApolloContext } from '@apollo/client';
import { QueryInfo } from '@apollo/client/core/QueryInfo';
import { OperationDefinitionNode } from 'graphql';
import { useCallback, useContext } from 'react';

interface ExtendedApolloClient {
  cache: { data: { data: { ROOT_QUERY: Record<string, object> } } };
  queryManager: { queries: Map<number, QueryInfo> };
}

export const useApolloContext = () => useContext(getApolloContext());

export function getOperationDefinitionName(document: DocumentNode) {
  return document.definitions.find(
    (definitions): definitions is OperationDefinitionNode =>
      definitions.kind === 'OperationDefinition',
  )!.name?.value;
}

export function useCachedQueries() {
  const { client } = useApolloContext();
  const { queries } = (client as unknown as ExtendedApolloClient).queryManager;

  const normalizeCachedQuery = (query: QueryInfo) => {
    if (!query.document || !query.variables) return null;
    const name = getOperationDefinitionName(query.document);
    return { name, variables: query.variables };
  };

  const normalizedQueries = Array.from(queries.values())
    .map(normalizeCachedQuery)
    .filter(
      (
        normalizedQuery,
      ): normalizedQuery is NonNullable<typeof normalizedQuery> =>
        normalizedQuery !== null,
    );

  return normalizedQueries;
}

export default function useGetQueriesVariablesByOperation() {
  const activeQueries = useCachedQueries();
  return useCallback(
    <Variables extends any>({ query }: { query: DocumentNode }) => {
      const lookedOperation = getOperationDefinitionName(query);
      return activeQueries
        .filter(
          (activeQuery) =>
            activeQuery.name === lookedOperation && activeQuery.variables,
        )
        .map((activeQuery) => activeQuery.variables as Variables);
    },
    [activeQueries],
  );
}
