import { Module } from '../styles';
import { HouseholdPeople } from './components/HouseholdPeople';
import { SetInvestorRisk } from './components/SetInvestorRisk';
import { SurveyResultsContainer } from './components/SurveyResultsContainer';
import { useConnect } from './connect';
import { Container, PersonalityProfile } from './styles';

export function Profile() {
  const {
    investorInfo,
    people,
    sendSurveyEmail,
    riskTolerance,
    can,
    survey,
    fetchInvestorInfo,
    loadingInvestorInfo,
  } = useConnect();

  return (
    <Container>
      <HouseholdPeople people={people} />
      {['NOT_SENT', 'NOT_SET', 'SENT'].includes(
        investorInfo?.assessmentStatus || 'NOT_SET',
      ) ? (
        <SetInvestorRisk
          survey={survey}
          investorInfo={investorInfo}
          sendSurveyEmail={sendSurveyEmail}
          fetchInvestorInfo={fetchInvestorInfo}
          loadingInvestorInfo={loadingInvestorInfo}
        />
      ) : (
        <>
          <PersonalityProfile />
          <Module>
            <SurveyResultsContainer
              can={can}
              investorInfo={investorInfo}
              riskTolerance={riskTolerance}
              survey={survey}
            />
          </Module>
        </>
      )}
    </Container>
  );
}
