import styled from 'styled-components';

import DefaultPlaceholder from 'components/Placeholder';

export const SelectPlaceholder = styled(DefaultPlaceholder)`
  margin-right: 0.75rem;
  width: 1rem;
  height: 1rem;
`;

export const Placeholder = styled(DefaultPlaceholder)<{
  $width?: string;
}>`
  width: 80%;
  height: 1.25rem;
`;
