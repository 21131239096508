import { ApolloProvider } from '@apollo/client';
import { ThemeProvider as ThemeProviderV2 } from '@mui/material/styles';
import { MotionConfig, ExitFeature, AnimationFeature } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import App from 'containers/App';
import ErrorBoundary from 'containers/ErrorBoundary';
import FastProviders from 'context/fastContext';
import CustomProviders from 'context/providers';
import client from 'graphql/client';
import GlobalStyle from 'styles/GlobalStyle';
import themes, { notistackConfig } from 'styles/themes';
import themeV2 from 'styles/v2/theme';

import 'styles/fonts.css';

const Root: FC = () => (
  <StyleSheetManager>
    <ApolloProvider client={client}>
      <ThemeProviderV2 theme={themeV2}>
        <ThemeProvider theme={themes.light}>
          <GlobalStyle />
          <ErrorBoundary>
            <ClearBrowserCacheBoundary
              auto
              fallback="Loading"
              duration={600000}
            >
              <MotionConfig features={[AnimationFeature, ExitFeature]}>
                <SnackbarProvider {...notistackConfig}>
                  <BrowserRouter>
                    <FastProviders>
                      <CustomProviders>
                        <App />
                      </CustomProviders>
                    </FastProviders>
                  </BrowserRouter>
                </SnackbarProvider>
              </MotionConfig>
            </ClearBrowserCacheBoundary>
          </ErrorBoundary>
        </ThemeProvider>
      </ThemeProviderV2>
    </ApolloProvider>
  </StyleSheetManager>
);

export default Root;
