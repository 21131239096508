import { FC } from 'react';

import {
  Container,
  HiddenRadio,
  PseudoRadio,
  Label,
  Option,
  Wrapper,
} from './styles';
import { Props } from './types';

const RadioInputGroup: FC<Props> = ({
  name,
  options,
  className,
  selectedValue,
  ...props
}) => {
  return (
    <Container className={className}>
      {options.map(({ id, label, value }) => (
        <Option htmlFor={id} key={id}>
          <Wrapper>
            <HiddenRadio
              checked={selectedValue === value}
              {...props}
              id={id}
              name={name}
              value={value}
            />
            <PseudoRadio />
            <Label>{label}</Label>
          </Wrapper>
        </Option>
      ))}
    </Container>
  );
};

export default RadioInputGroup;
