/* eslint-disable @typescript-eslint/no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';

function Modal({
  children,
  isOpen,
  close,
  transitionSpeed = '0.5s',
  sx = {},
  dataTestId,
}: {
  children: any;
  isOpen: boolean;
  close: any;
  transitionSpeed?: string;
  sx?: any;
  dataTestId?: string;
}) {
  useEffect(() => {
    if (isOpen) {
      // Dont to prevent the body from "moving" when scrollbar is removed
      const getScrollbarWidth = () =>
        window.innerWidth - document.documentElement.clientWidth;
      const scrollbarWidth = getScrollbarWidth();
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = 'initial';
    }
  }, [isOpen]);

  // TODO: Make it so we don't render this regardless if it's being shown or not
  // only reason to always show is for transition but we can use a setTimeout or something
  return (
    <Box>
      {isOpen && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            opacity: 0.1,
            width: '100%',
            height: '100%',
            zIndex: 900,
            backgroundColor: theme.palette.grey['900'],
          })}
          onClick={close}
        />
      )}

      <Box
        sx={(theme) => ({
          position: 'fixed',
          left: '50%',
          top: isOpen ? '50%' : '200%',
          transform: 'translate(-50%, -50%)',
          transition: `top ${transitionSpeed} ease`,
          zIndex: 901,
          borderRadius: '10px',
          backgroundColor: theme.palette.common.white,
          boxShadow: 'rgba(31, 27, 36, 0.1) 0px 1.25rem 2.5rem -0.125rem',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 48px)',
          ...sx,
        })}
      >
        <IconButton
          onClick={close}
          sx={{
            p: 0,
            position: 'absolute',
            right: '16px',
            top: '16px',
          }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <Box
          sx={{
            padding: '2rem',
          }}
          data-testid={dataTestId}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Modal;
