import { useCallback, ChangeEvent } from 'react';

import { User } from '../types';
import { Props } from './types';

const useLogic = (
  updatePermission: Props['updatePermission'],
  userId: string,
) => {
  const onChangePermission = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      updatePermission({
        userId,
        permission: e.target.value,
      } as User);
    },
    [updatePermission, userId],
  );

  return { onChangePermission };
};

export default useLogic;
