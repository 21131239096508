import { TextField } from '@mui/material';
import styled from 'styled-components';

export const AllocationContainer = styled.div`
  display: block;

  > div {
    display: flex;

    > div {
      flex: 1;

      &:nth-of-type(2) {
        display: flex;
        align-items: center;
      }

      &:nth-of-type(3) {
        display: flex;
        justify-content: right;
      }
    }
  }
`;

export const SectionHead = styled.div`
  display: flex;
  height: 38px;
  line-height: 38px;

  > div {
    &:nth-of-type(1) {
      flex: 1;
      color: ${({ theme }) => theme.colors.grey900};
      font-weight: ${({ theme }) => theme.weights.bold};
      margin: 0;
      display: flex;
      min-width: 154px;

      > div {
        &:nth-of-type(2) {
          margin-left: 6px;
        }
      }
    }

    &:nth-of-type(2) {
      flex: 2;
      justify-content: left;
      display: flex;
    }

    &:nth-of-type(3) {
      flex: 1;
      color: ${({ theme }) => theme.colors.grey600};
      font-weight: ${({ theme }) => theme.weights.bold};
      justify-content: center;
      display: flex;
      min-width: 144px;

      input {
        text-align: right;
        border-bottom: 0;
      }
    }
  }
`;

export const HeadContainer = styled.div`
  hr {
    background: ${({ theme }) => theme.colors.grey200};
    height: 1px;
    inline-size: 100%;
    border: none;
    grid-column: 1 / -1;
    margin: 0;
    margin-top: 16px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  &::after {
    content: '%';
    color: ${({ theme }) => theme.colors.grey600};
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }
`;

export const Input = styled.div`
  display: flex;
  column-gap: 0.5rem;

  button {
    --size: 2.125rem;
    block-size: var(--size);
    inline-size: var(--size);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    background: ${({ theme }) => theme.colors.grey0};
    border-radius: 0.5rem;
    border: 0;
    font-size: 0.875rem;
    inline-size: 3.75rem;
    margin: 0;
    padding: 0.375rem calc(1.25rem + 1ch) 0.375rem calc(0.875rem - 1ch);
    text-align: end;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  > svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  > div {
    > div {
      vertical-align: middle;
    }
  }
`;

export const GroupAllocationInput = styled(TextField)`
  border: 1px solid #36bf8a !important;
  border-radius: 5px;
  padding: 0px 6px !important;

  > div {
    height: 100%;
  }

  & .MuiInput-underline:before {
    border-bottom: none !important;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }

  & .MuiInput-underline:after {
    border-bottom: none !important;
  }
`;
