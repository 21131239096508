import styled from 'styled-components';

import { ReactComponent as CSVIcon } from 'assets/svg/files/csv.svg';
import { ReactComponent as PDFIcon } from 'assets/svg/files/pdf.svg';
import { ReactComponent as XLSIcon } from 'assets/svg/files/xls.svg';
import { ReactComponent as ZipIcon } from 'assets/svg/files/zip.svg';
import Icon from 'components/Icon';

const Container = styled(Icon)`
  height: 2rem;
  width: 2rem;
`;

export const CSV = styled(Container).attrs(() => ({
  icon: <CSVIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.primary50};
  color: ${({ theme }) => theme.colors.primary};
`;

export const PDF = styled(Container).attrs(() => ({
  icon: <PDFIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.bridesmaid};
  color: ${({ theme }) => theme.colors.flamingo};
`;

export const XLS = styled(Container).attrs(() => ({
  icon: <XLSIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.primary50};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Zip = styled(Container).attrs(() => ({
  icon: <ZipIcon />,
}))`
  background-color: ${({ theme }) => theme.colors.sazerac};
  color: ${({ theme }) => theme.colors.tacao};
`;
