import styled, { css, keyframes } from 'styled-components';

import { addAlpha } from 'utils/addAlpha';

const loadAnimation = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(700%);
    }
`;

export const Container = styled.div`
  border-radius: 1rem;
  display: inline-block;
  color: transparent;
  position: relative;
  background-color: ${({ theme }) => addAlpha(theme.colors.grey500, 0.6)};
  overflow: hidden;
  width: 80%;
  height: 1.25rem;

  &::after {
    content: '';
    background-image: ${({ theme }) => css`
        linear-gradient(to right, ${addAlpha(
          theme.colors.grey500,
          0.4,
        )}, ${addAlpha(theme.colors.grey500, 0.2)}, ${addAlpha(
      theme.colors.grey300,
      0.4,
    )})
      `};
    width: 25%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    animation: ${loadAnimation} 800ms ease-in-out infinite;
  }
`;
