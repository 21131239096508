import { ModuleTitle, Divider, ModuleItemContainer } from '../styles';
import { Categories } from './Categories';
import { useLogic } from './logic';
import { Props } from './types';

export function SeedsCoreEquity({
  ranks,
  epiPriority,
  proposed,
  className,
}: Props) {
  const { categories } = useLogic(ranks, epiPriority);
  return (
    <ModuleItemContainer className={className}>
      <ModuleTitle>Core Equity</ModuleTitle>
      <p>
        Understand the insights of your {proposed ? 'proposed' : ''} stock
        ownership on the world compared to other companies.
      </p>
      <Divider />
      <Categories categories={categories} />
    </ModuleItemContainer>
  );
}
