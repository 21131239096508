import GradingIcon from '@mui/icons-material/Grading';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TermsModal from 'containers/Modals/TermsModal';
import useInvestorActions from 'context/fastContext/investor/actions';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useUser from 'context/hooks/User.hooks';
import useAuthActions from 'graphql/hooks/useAuthActions';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';
import useSearchParams from 'utils/useSearchParams';

const InvestorHome = () => {
  const {
    userInfo: { acceptedTerms, createdByLink },
    advisorInfo,
    fetchAdvisorInfo,
  } = useUser();

  const { fetchInvestorInfo, investorInfo } = useInvestorInfo();
  const { fetchProfile } = useInvestorActions();

  const { me, loading: meLoading } = useMe();
  const query = useSearchParams();
  const isCompletedQuery = query.get('isCompleted') === 'true';
  const { push } = useHistory();
  const { logout } = useAuthActions();
  const isMobile = !useMediaQuery(from.tablet);
  const { close: closeModal, isOpen, open: openModal } = useModal('TERMS');
  useEffect(() => {
    if (!advisorInfo?.id)
      fetchAdvisorInfo((me as any)?.investor?.advisor?.shortHash);
    fetchInvestorInfo((me as any)?.investor?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(me as any)?.investor]);

  const handleToggleModal = useCallback(() => {
    if (isOpen) {
      closeModal();
    } else {
      openModal();
    }
  }, [closeModal, isOpen, openModal]);

  const surveyStatus = investorInfo?.surveyResult?.statusDisplay || '';

  // TODO: Change this when off GraphQL
  // The use of checking the query isCompleted is done because GraphQL is dumb and
  // meLoading does not change to loading when refetching data
  // So checking the query params ensures if we want it to say complete
  // it does instead of showing not complete and then complete once fetch is done
  const surveyIsCompleted = useMemo(
    () =>
      ['completed', 'open'].includes(surveyStatus.toLowerCase()) ||
      isCompletedQuery,
    [surveyStatus, isCompletedQuery],
  );

  const showTerms = useMemo(
    () => !surveyIsCompleted && !acceptedTerms,
    [acceptedTerms, surveyIsCompleted],
  );

  const handleCompleteSurveyClick = useCallback(() => {
    push('/survey');
  }, [push]);

  const logoutRedirectUrl = useMemo(
    () =>
      advisorInfo?.company?.domain
        ? advisorInfo?.company?.domain
        : 'https://www.seedsinvestor.com/',
    [advisorInfo?.company?.domain],
  );

  const handleLogout = useCallback(() => {
    logout('investor', logoutRedirectUrl);
  }, [logout, logoutRedirectUrl]);

  const handleViewResults = useCallback(async () => {
    await fetchProfile({ investorId: (me as any)?.investor?.id });
    push('/survey/results');
  }, [fetchProfile, me, push]);

  return (
    <Container maxWidth="sm" id="top">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        {meLoading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid item>
            <Typography color="primary.main" mb={2} variant="h5">
              Hi {me?.firstName}
            </Typography>
            <Typography mb={2}>
              {surveyIsCompleted
                ? `Thank you for completing the assessment. ${
                    createdByLink ? 'We' : 'Your advisor'
                  } will be in touch to go over the results.`
                : `You have not yet completed your assessment. Once completed, ${
                    createdByLink ? 'we' : 'your advisor'
                  } will reach out to you and review the results.`}
            </Typography>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '2fr 1fr',
                gap: '20px',
              }}
            >
              {!surveyIsCompleted && (
                <Button variant="contained" onClick={handleCompleteSurveyClick}>
                  Complete Assessment {showTerms && '*'}
                </Button>
              )}
              {surveyIsCompleted && (
                <Button variant="contained" onClick={handleViewResults}>
                  <GradingIcon />
                  &nbsp;View Results
                </Button>
              )}
              <Button variant="outlined" onClick={handleLogout}>
                <LogoutIcon />
                &nbsp;End Session
              </Button>
            </Box>
            {showTerms && (
              <Box sx={{ textAlign: 'left', marginTop: '12px' }}>
                <Typography fontSize="12px">
                  *By taking the Assessment you agree to our{' '}
                  <span
                    tabIndex={0}
                    onClick={handleToggleModal}
                    onKeyUp={handleToggleModal}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    role="button"
                  >
                    Terms of Service.
                  </span>
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <TermsModal isInvestor />
    </Container>
  );
};

export default InvestorHome;
