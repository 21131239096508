import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC } from 'react';

import { from, useMediaQuery } from 'styles/media';

import { Modal, Text, Title } from './styles';
import { Props } from './types';

const Confirm: FC<Props> = ({ show, onConfirm, onCancel, title, children }) => {
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <Modal fullScreen={isMobile} open={show} onClose={onCancel}>
      <Title>{title}</Title>
      <Text>{children}</Text>
      <Box
        sx={{
          display: 'flex',
          marginTop: '1.5rem',
          justifyContent: 'space-between',
        }}
      >
        <Button
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            minWidth: '120px',
          }}
          onClick={onConfirm}
          variant="contained"
        >
          Exit Anyway
        </Button>
        <Button
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            minWidth: '120px',
          }}
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default Confirm;
