import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  format,
  parseISO,
} from 'date-fns/esm';
import { useMemo } from 'react';

export const useLogic = (rawDate?: string) => {
  const formattedDate = useMemo(() => {
    if (!rawDate) return null;

    const date = parseISO(rawDate);
    const days = differenceInCalendarDays(new Date(), date);
    const weeks = differenceInCalendarWeeks(new Date(), date);
    const months = differenceInCalendarMonths(new Date(), date);

    if (days < 0) return null;
    if (days === 0) return 'Today';
    if (days === 1) return 'Yesterday';
    if (weeks === 0) return `${days} Days ago`;
    if (weeks === 1) return 'Last week';
    if (months === 0) return `${weeks} Weeks ago`;
    if (months === 1) return 'Last month';
    if (months < 12) return `${months} Months ago`;

    return format(date, 'MM/d/yyyy');
  }, [rawDate]);

  return { formattedDate };
};
