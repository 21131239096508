import CloseIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import type { ChangeEventHandler, MouseEventHandler } from 'react';

type SearchBoxProps = {
  value: string;
  placeholder: string;
  ariaLabel: string;
  handleOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleClear: MouseEventHandler<HTMLButtonElement>;
  // eslint-disable-next-line react/require-default-props
  sx?: any;
  dataTestId?: string;
};

export default function SearchBox({
  value,
  placeholder,
  ariaLabel,
  handleOnChange,
  handleClear,
  sx = {
    display: 'flex',
    alignItems: 'center',
    width: 352,
    height: 36,
    p: '6px',
    border: '1px solid #CED4DA',
    borderColor: value.length > 0 ? 'primary.main' : '#CED4DA',
    borderRadius: '0.3125rem',
    '&:hover': {
      borderColor: 'primary.main', // border color on hover
    },
  },
  dataTestId,
}: SearchBoxProps) {
  return (
    <Box sx={sx}>
      <SearchIcon />
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          fontSize: '14px',
        }}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        inputProps={{ 'aria-label': ariaLabel, 'data-testid': dataTestId }}
      />
      {value.length > 0 && (
        <IconButton
          onClick={handleClear}
          type="button"
          sx={{ p: '10px' }}
          aria-label="search"
        >
          <CloseIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      )}
    </Box>
  );
}
