import styled from 'styled-components';

import { ReactComponent as DefaultDownloadIcon } from 'assets/svg/download-circle.svg';
import Button from 'components/Button';
import Card from 'components/Card';
import { from } from 'styles/media';

export const Container = styled(Card)`
  align-items: space-between;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 0.25rem;
  width: 100%;

  > button {
    margin-top: 0.825rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  > a {
    margin-bottom: 0.825rem;
  }

  ${from.tablet} {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    margin-left: 0.75rem;
  }
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(22 / 16);
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
`;

export const DownloadIcon = styled(DefaultDownloadIcon)``;

export const PrintButton = styled(Button).attrs(() => ({
  variant: 'secondary',
}))`
  margin-right: auto;
  margin-top: 0.625rem;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${from.tablet} {
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    width: 100%;
  }
`;

export const ResultsButton = styled(Button).attrs(() => ({
  variant: 'secondary',
}))`
  margin-right: auto;
  margin-top: 0.625rem;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${from.tablet} {
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    width: 100%;
  }
`;

export const Error = styled.p`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.flamingo};
  font-size: 0.75rem;
`;
