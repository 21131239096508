import { useCallback, useMemo } from 'react';

import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import IconButton from 'components/IconButton';
import roundToTarget from 'utils/roundToTarget';
import CustomTooltip from 'v2/components/CustomTooltip';
import Dropdown from 'v2/components/Dropdown';
import ValueSwitch from 'v2/components/EditAllocationsModal/ValueSwitch';

import {
  AllocationContainer,
  DropdownContainer,
  HeadContainer,
  Input,
  InputContainer,
  SectionHead,
} from './styles';

type ProductGroupType = {
  assetClassGroup: any;
  availableProducts: any;
  combinedAllProducts: any;
  selectedGroup: any;
  handleSelectGroup: any;
  selectedProducts: any;
  selectedProductArray: any;
  handleValueSwitch: any;
  handleAllocationChange: any;
  handleBatchAllocationChange: any;
  isValuesDriven: any;
};

const ProductGroup = ({
  assetClassGroup,
  availableProducts,
  combinedAllProducts,
  selectedGroup,
  handleSelectGroup,
  selectedProducts,
  selectedProductArray,
  handleValueSwitch,
  handleAllocationChange,
  handleBatchAllocationChange,
  isValuesDriven,
}: ProductGroupType) => {
  const shouldDisplayGroupDropdown = useMemo(
    () => availableProducts.length > 1,
    [availableProducts.length],
  );

  // For Group Dropdown (Funds / Single Stocks)
  const groupOptions = useMemo(
    () =>
      availableProducts.map((g: any) => ({
        label: g.groupName,
        value: g.groupName,
      })),
    [availableProducts],
  );

  // For Group Dropdown (Funds / Single Stocks)
  const selectedGroupValue = useMemo(
    () => ({
      label: selectedGroup?.groupName,
      value: selectedGroup?.groupName,
    }),
    [selectedGroup],
  );

  const groupIsFixedAllocation = useMemo(
    () => selectedGroup?.isFixedAllocation,
    [selectedGroup?.isFixedAllocation],
  );

  const assetType = useMemo(
    () => assetClassGroup.symbol.toLowerCase(),
    [assetClassGroup.symbol],
  );

  const groupAssetClasses = [
    assetClassGroup.id,
    ...assetClassGroup.children.map((acgc: any) => acgc.id),
  ];

  // For displaying the total allocation value for the group
  const totalAllocationValue = groupIsFixedAllocation
    ? selectedProductArray.find(
        (sp: any) => sp.assetClassId === assetClassGroup.id,
      ).allocation
    : selectedProductArray.reduce((mem: any, cur: any) => {
        if (groupAssetClasses.includes(cur.assetClassId)) {
          mem += cur.allocation;
        }
        return mem;
      }, 0);

  const valueIsCheckedForFixedAllocation = useMemo(() => {
    if (!groupIsFixedAllocation) return false;

    const selectedProduct = selectedProductArray.find(
      (sp: any) => sp.assetClassId === assetClassGroup.id,
    );

    const isValueItem = combinedAllProducts.find(
      (p: any) => p.id === selectedProduct.id,
    ).isValuePlay;

    return isValueItem;
  }, [
    assetClassGroup.id,
    combinedAllProducts,
    groupIsFixedAllocation,
    selectedProductArray,
  ]);

  const selectedGroupProduct = useMemo(() => {
    if (!groupIsFixedAllocation) return false;
    return selectedGroup?.products.find((p: any) => selectedProducts[p.id]);
  }, [groupIsFixedAllocation, selectedGroup?.products, selectedProducts]);

  const handleAllocationIncriments = useCallback(
    (type, assetClassId) => {
      let { allocation } = selectedProducts[assetClassId];
      if (type === 'increment' && allocation !== 100) {
        allocation += 1;
      } else if (type === 'decrement' && allocation !== 0) {
        allocation -= 1;
      }

      handleAllocationChange(assetClassId, allocation, assetClassGroup.symbol);
    },
    [handleAllocationChange, selectedProducts, assetClassGroup],
  );

  const handleGroupAllocationChange = useCallback(
    (value) => {
      handleBatchAllocationChange({
        assetClassGroup,
        newValue: Number(value),
        originalValue: totalAllocationValue,
      });
    },
    [handleBatchAllocationChange, assetClassGroup, totalAllocationValue],
  );

  const handleGroupAllocationIncrimentChange = useCallback(
    (type: string) => {
      let value = totalAllocationValue;
      if (type === 'increment' && value !== 100) {
        value += 1;
      } else if (type === 'decrement' && value !== 0) {
        value -= 1;
      }

      handleBatchAllocationChange({
        assetClassGroup,
        newValue: value,
        originalValue: totalAllocationValue,
      });
    },
    [assetClassGroup, handleBatchAllocationChange, totalAllocationValue],
  );

  const displayValueHeader = useMemo(() => {
    let hasMultiple = false;
    selectedGroup?.products.reduce((mem: any, cur: any) => {
      if (mem.includes(cur.assetClassId)) {
        hasMultiple = true;
      }

      mem.push(cur.assetClassId);
      return mem;
    }, []);

    return hasMultiple;
  }, [selectedGroup]);

  const groupChildrenSorted = useMemo(
    () =>
      assetClassGroup.children.sort(
        (a: any, b: any) => a.assetOrder - b.assetOrder,
      ),
    [assetClassGroup.children],
  );

  const calculateGroups = groupChildrenSorted.reduce((mem: any, ac: any) => {
    mem[ac.id] =
      ((selectedGroupProduct?.[ac.id] || 0) * totalAllocationValue) / 100;

    return mem;
  }, {});

  const roundedValues: any = groupIsFixedAllocation
    ? roundToTarget(calculateGroups, totalAllocationValue)
    : [];

  const disableValueToggles = useMemo(
    () => !isValuesDriven && assetClassGroup.symbol === 'EQ', // TODO: Make this dynamic somehow
    [assetClassGroup.symbol, isValuesDriven],
  );

  return (
    <>
      <HeadContainer>
        <SectionHead>
          <div>
            <div>{assetClassGroup.name}</div>
            {shouldDisplayGroupDropdown && (
              <DropdownContainer>
                <Dropdown
                  placeholder=""
                  options={groupOptions}
                  onChange={handleSelectGroup}
                  value={selectedGroupValue}
                  assetType={assetType}
                />
                <CustomTooltip title="For equity exposure, select either single stocks or funds. If funds are selected, sub-asset class percentages cannot be adjusted. See the list of holdings for more detail." />
              </DropdownContainer>
            )}
          </div>
          <div>
            {displayValueHeader && (
              <div>
                Values{' '}
                <CustomTooltip title="When toggled on (green), it means the system will propose values-integrated (ESG) options." />
              </div>
            )}
            {groupIsFixedAllocation && selectedGroup?.products.length > 1 && (
              <div>
                <ValueSwitch
                  onChange={handleValueSwitch}
                  checked={valueIsCheckedForFixedAllocation}
                  name={assetClassGroup.id}
                  size="small"
                  disabled={disableValueToggles}
                  data-testid="value-switch"
                />
              </div>
            )}
          </div>
          <div>
            <>
              <Input>
                <IconButton
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    if (groupIsFixedAllocation) {
                      handleAllocationIncriments(
                        'decrement',
                        selectedGroupProduct.id,
                      );
                    } else {
                      handleGroupAllocationIncrimentChange('decrement');
                    }
                  }}
                  aria-label="Decrement"
                  icon={<MinusIcon aria-hidden />}
                />
                <InputContainer>
                  <input
                    type="number"
                    min={0}
                    max={100}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      if (groupIsFixedAllocation) {
                        handleAllocationChange(
                          selectedGroupProduct.id,
                          value,
                          assetClassGroup.symbol,
                        );
                      } else {
                        handleGroupAllocationChange(value);
                      }
                    }}
                    value={totalAllocationValue}
                  />
                </InputContainer>
                <IconButton
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    if (groupIsFixedAllocation) {
                      handleAllocationIncriments(
                        'increment',
                        selectedGroupProduct.id,
                      );
                    } else {
                      handleGroupAllocationIncrimentChange('increment');
                    }
                  }}
                  aria-label="Increment"
                  icon={<PlusIcon aria-hidden />}
                />
              </Input>
            </>
          </div>
        </SectionHead>
        <hr style={{ marginTop: '8px' }} />
      </HeadContainer>

      <AllocationContainer>
        {groupChildrenSorted.map((acgc: any) => {
          const assetProducts =
            selectedGroup?.products.filter(
              (p: any) => p.assetClassId === acgc.id,
            ) || [];

          const anyValuesBasedProducts = assetProducts.some(
            (p: any) => p.isValuePlay,
          );

          const selectedAssetProduct: any = selectedProductArray.find(
            (sp: any) => sp.assetClassId === acgc.id,
          );

          const isValueItem = combinedAllProducts.find(
            (p: any) => p?.id === selectedAssetProduct?.id,
          )?.isValuePlay;

          return (
            <div
              style={{ minHeight: '38px', marginBottom: '8px' }}
              key={acgc.id}
              data-testid="asset-class-selection"
            >
              <div style={{ minWidth: '180px', lineHeight: '38px' }}>
                {acgc.name}
              </div>
              <div>
                {anyValuesBasedProducts && (
                  <>
                    {assetProducts.length > 1 ? (
                      <div>
                        <ValueSwitch
                          onChange={handleValueSwitch}
                          name={acgc.id}
                          checked={isValueItem}
                          size="small"
                          data-testid="value-switch"
                        />
                      </div>
                    ) : (
                      <div>
                        {!groupIsFixedAllocation && (
                          // Stocks with Values Intelli: Mid, Small, Inter
                          <div>
                            <ValueSwitch
                              onChange={handleValueSwitch}
                              name={acgc.id}
                              checked={isValuesDriven}
                              disabled
                              size="small"
                            />
                            <CustomTooltip
                              title={`Values-agnostic ${acgc.name} option is unavailable at this time.`}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                <>
                  <Input>
                    <IconButton
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        handleAllocationIncriments(
                          'decrement',
                          selectedAssetProduct.id,
                        );
                      }}
                      aria-label="Decrement"
                      icon={<MinusIcon aria-hidden />}
                      disabled={groupIsFixedAllocation}
                    />
                    <InputContainer>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          handleAllocationChange(
                            selectedAssetProduct.id,
                            value,
                            assetClassGroup.symbol,
                          );
                        }}
                        value={
                          groupIsFixedAllocation
                            ? roundedValues[acgc.id] || 0
                            : selectedAssetProduct.allocation
                        }
                        disabled={groupIsFixedAllocation}
                      />
                    </InputContainer>
                    <IconButton
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        handleAllocationIncriments(
                          'increment',
                          selectedAssetProduct.id,
                        );
                      }}
                      aria-label="Increment"
                      icon={<PlusIcon aria-hidden />}
                      disabled={groupIsFixedAllocation}
                    />
                  </Input>
                </>
              </div>
            </div>
          );
        })}
      </AllocationContainer>
    </>
  );
};

export default ProductGroup;
