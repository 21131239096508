import { useFormikContext } from 'formik';
import {
  FocusEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import { pattern } from './constants';
import { LogicProps, State } from './types';

const useLogic = ({
  name,
  onBlur,
  onFocus,
  placeholder: placeholderProp,
}: LogicProps) => {
  const initialState: State = {
    placeholder: placeholderProp || pattern,
    focused: false,
    showLabel: false,
    filled: false,
  };

  const [{ placeholder, showLabel, focused, filled }, setState] =
    useState(initialState);

  const { setFieldValue } = useFormikContext();

  const inputRef = useRef<any>(null);
  const maskRef = useRef<any>(null);

  const lazy = useMemo(() => (focused ? false : !filled), [focused, filled]);

  useEffect(() => {
    if (inputRef?.current?.value) {
      setState((prevState) => ({
        ...prevState,
        showLabel: true,
        filled: inputRef.current.value.length > 0,
      }));
    }
  }, []);

  useEffect(() => {
    inputRef.current.placeholder = placeholder;
    inputRef.current.spellcheck = false;
  }, [placeholder]);

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);

      const value =
        inputRef?.current?.value === pattern ? null : inputRef?.current?.value;

      if (!value && name) {
        setFieldValue(name, '');
      }

      setState((prevState) => ({
        ...prevState,
        showLabel: !value ? false : prevState.showLabel,
        focused: false,
        filled: !!value,
        placeholder: !value
          ? placeholderProp || pattern
          : prevState.placeholder,
      }));
    },
    [setState, onBlur, inputRef, placeholderProp, setFieldValue, name],
  );

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(e);
      setState((prevState) => ({
        ...prevState,
        showLabel: true,
        focused: true,
        placeholder: pattern,
      }));
    },
    [onFocus, setState],
  );

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleOnChange = useCallback(
    (value: string) => {
      if (name) {
        setFieldValue(name, value);
      }
      setState((prevState) => ({
        ...prevState,
        filled: value.length > 0,
      }));
    },
    [name, setFieldValue],
    // defaultvalue is value of the input which is probably changing on every keystroke
    // may be a source of memory problem
  );

  return {
    handle: {
      focus: handleFocus,
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      onChange: handleOnChange,
    },
    inputRef,
    maskRef,
    showLabel,
    lazy,
  };
};

export default useLogic;
