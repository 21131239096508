import { FC, memo, useEffect, useRef } from 'react';

import { Container, HiddenRadio, Option, Label, Description } from './styles';
import { Props } from './types';

const OptionsPad: FC<Props> = ({
  options,
  name,
  initialValue,
  disabled,
  ...props
}) => {
  const defaultCheckedRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    defaultCheckedRef.current?.click();
  }, [initialValue]);

  return (
    <Container>
      {options.map(({ id, label, description }) => (
        <div key={id}>
          <HiddenRadio
            {...props} // Keep {...props} before other props
            id={id}
            name={name}
            ref={initialValue === id ? defaultCheckedRef : undefined}
            value={id}
            readOnly={disabled}
          />
          <Option htmlFor={disabled ? '' : id} withDescription={!!description}>
            <Label>{label}</Label>
            {description && <Description>{description}</Description>}
          </Option>
        </div>
      ))}
    </Container>
  );
};

export default memo(OptionsPad);
