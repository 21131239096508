import styled, { css } from 'styled-components';

import { Loader as Spinner } from 'components/FileInput/FileRow/styles';
import BaseModal, { Title as BaseTitle } from 'components/Modal';
import BaseSelect from 'components/Select';

export const Modal = styled(BaseModal)`
  .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 32.5rem;
        width: 32.5rem;
      `};
  }
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 1.875rem;
`;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 28.5rem;
  margin-left: auto;
  margin-right: auto;
`;

export const SelectAllRow = styled.div`
  display: flex;
  padding: 0.75rem 0;
`;

export const CheckboxLabel = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0;
  line-height: calc(20 / 14);
  margin-left: 1rem;
`;

export const Select = styled(BaseSelect)`
  margin-left: auto;

  select {
    border-right: none;
  }
`;

export const Loader = styled(Spinner)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.4375rem;
`;
