import { FC, memo } from 'react';

import BarChart from './BarChart';
import { Container } from './styles';
import { Props } from './types';

const Sectors: FC<Props> = ({ chartData, maxValue, printable, ...props }) => {
  return (
    <Container {...props}>
      <BarChart data={chartData} maxValue={maxValue} printable={printable} />
    </Container>
  );
};

export default memo(Sectors);
