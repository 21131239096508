import Popover from 'components/Popover/ContactPopover';
import RiskValue from 'components/RiskValue';
import Status from 'components/Status';
import defaultSort from 'utils/defaultSort';

import {
  Client,
  SimpleClient,
  Bold,
  BoldLink,
  valueFormat,
  horizonFormatter,
  SmallClient,
} from '.';
import { Schema } from '../types';

export const schema: Schema<Client> = {
  columns: {
    household: {
      header: <>House Hold Name</>,
      row: (value) => (
        <BoldLink to={`/clients/${value.name}`}>
          <Popover contactInfo={value}>{value.name}</Popover>
        </BoldLink>
      ),
      sortBy: 'name',
    },
    company: {
      header: <>Company</>,
      row: (company) => <>{company.name}</>,
      sortBy: 'name',
    },
    advisor: {
      header: <>Advisor</>,
      row: (advisor) => <>{advisor.name}</>,
      sortBy: 'name',
    },
    values: {
      header: <>Values</>,
      row: (value) => <>{valueFormat(value)}</>,
      width: '6rem',
      sortBy: defaultSort,
    },
    horizon: {
      header: <>Horizon</>,
      row: (value) => <>{horizonFormatter(value)}</>,
      align: 'end',
      sortBy: 'start',
      width: '6rem',
    },
    risk: {
      header: <>Risks</>,
      row: (value) => <RiskValue value={value} />,
      width: '7.125rem',
      sortBy: defaultSort,
    },
    status: {
      header: <>Status</>,
      row: (value) => <Status status={value} />,
      width: '6.25rem',
      sortBy: defaultSort,
    },
  },
};

export const schemaSmall: Schema<SmallClient> = {
  columns: {
    advisorName: {
      header: <>Advisor Name</>,
      row: (value) => <>{value}</>,
    },
    company: {
      header: <>Company</>,
      row: (value) => <>{value}</>,
    },
    numberOfClients: {
      header: <># Clients</>,
      row: (value) => <>{value}</>,
      align: 'end',
      width: '6.25rem',
    },
  },
};

export const simpleSchema: Schema<SimpleClient> = {
  columns: {
    name: {
      header: <>Selected clients</>,
      row: (value) => <Bold>{value}</Bold>,
    },
    email: {
      header: null,
      row: (value) => <>{value}</>,
    },
  },
};
