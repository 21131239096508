import styled, { createGlobalStyle, css } from 'styled-components';

import { from } from 'styles/media';
import { CustomTooltip } from 'styles/mixins';

export const TooltipGlobalStyle = createGlobalStyle`
  .MuiTooltip-popper .MuiTooltip-tooltip {
    ${CustomTooltip};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${from.mobile} {
    flex-direction: row;
  }
`;

export const Column = styled.div<{
  $isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 15.625rem;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100%;
    `};

  ${from.tablet} {
    width: 100%;
    & + & {
      margin-left: 1rem;
    }
  }
`;
