import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useEffect, FC, useCallback } from 'react';

import { Props } from './types';

const AutoSave: FC<Props> = ({ everyMs = 300 }) => {
  const { dirty, submitForm, values } = useFormikContext();

  const handleSubmit = useCallback(
    () => debounce(submitForm, everyMs),
    [submitForm, everyMs],
  );

  useEffect(() => {
    if (!dirty) return;
    handleSubmit();
  }, [values, dirty, handleSubmit, submitForm]);

  return null;
};

export default AutoSave;
