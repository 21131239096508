import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    font-size: 100%;
    font-family: 'Greycliff CF', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.white};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga","kern";
    font-variant-numeric: proportional-nums;
  }
  * {
    box-sizing: border-box;
    @media print {
      color-adjust: exact;
    }
  }
  button {
    font-family: inherit;
    line-height: normal;
  }

  .MuiTooltip-tooltip {
    font-family: 'Greycliff CF', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    font-size: 12px !important;
  }

  .MuiSelect-select {
    border-radius: 0.5rem !important;
    padding-left: 3px !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #36bf8a !important;
    border-radius: 0.5rem !important;
  }
`;

export default GlobalStyle;
