import { NotificationsQuery } from 'graphql/generated';

type Input = NonNullable<NotificationsQuery['notifications']>[number];

export const normalizeNotification = (input: Input) => ({
  created: input.created,
  contentName: input.contentName || '',
  id: input.id,
  isSharedBySeeds: input.sharedBySeeds || false,
  read: input.read,
  type: input.notificationType,
  customText: input.customText,
  customUrl: input.customUrl,
});

export type Notification = ReturnType<typeof normalizeNotification>;
