import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { logger } from 'services/logger';
import AuthFetch, { CustomError } from 'utils/authFetch';

import { sectorsMapper } from '../helpers';
import {
  useStore,
  initialState,
  initialCurrentPortfolioState,
} from './context';
import { AddPortfolioArgs, UploadFileArgs } from './types';

function usePortfolioActions() {
  const { id: investorId } = useParams<{ id: string }>();
  const [error, setError] = useState(false);
  const [, setStore] = useStore((s) => s);

  const fetchCurrentPortfolio = useCallback(async () => {
    setStore({
      isLoadingPortfolio: true,
      currentPortfolio: initialCurrentPortfolioState,
    });
    try {
      const response = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/portfolio/${investorId}`,
        method: 'GET',
        useCache: false,
      });
      setStore({
        currentPortfolio: {
          ...response,
          sectors: sectorsMapper(response.score),
        },
      });
    } catch (e) {
      if (e instanceof CustomError) {
        if (e.statusCode === 404) {
          logger.warn('Current Portfolio not found for this user.');
        } else {
          logger.error('Error fetching current portfolio:', {
            message: e.message,
            data: e.data,
          });
        }
      } else {
        logger.error('Unexpected error:', { error: e });
      }
    } finally {
      setStore({ isLoadingPortfolio: false });
    }
  }, [investorId, setStore]);

  const importPortfolio = useCallback(
    async ({ file, valueType }: UploadFileArgs) => {
      setStore({ isImportingPortfolio: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/portfolio/file/`,
          method: 'POST',
          useCache: false,
          contentType: 'multipart/form-data',
          body: { investorId, file, valueType },
        });

        if (response.status) {
          const fails = response.payload.missing
            ? [...response.payload.missing]
            : [];
          setStore({
            importedPortfolio: {
              securities: response.payload.securities,
              missing: fails,
            },
          });
          return { success: true, fails };
        }

        setStore({ hasError: response.payload.nonFieldErrors });
        setError(true);
      } catch (e: any) {
        setStore({ hasError: e });
        setError(true);
      } finally {
        setStore({ isImportingPortfolio: false });
      }

      return { success: false, fails: [] };
    },
    [investorId, setStore],
  );

  const createPortfolio = useCallback(
    async ({ securities, valueType, missingTickers }: AddPortfolioArgs) => {
      setStore({ isUpdatingPortfolio: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/portfolio/`,
          method: 'POST',
          useCache: false,
          body: { investorId, securities, valueType, missingTickers },
        });

        if (response.status) {
          setStore({
            importedPortfolio: {
              securities: response.payload?.securities,
              missing: response.payload?.missing || [],
            },
          });
          return { success: true, fails: [] };
        }
      } catch (e: any) {
        setStore({ hasError: e });
        setError(true);
      } finally {
        setStore({ isUpdatingPortfolio: false });
      }
      return { success: false, fails: [] };
    },
    [investorId, setStore],
  );

  const deletePortfolio = useCallback(async () => {
    setStore({ isUpdatingPortfolio: true });
    try {
      const response = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/investor/portfolio/${investorId}`,
        method: 'DELETE',
        useCache: false,
      });
      if (response.status) {
        return { success: true };
      }
    } catch (e) {
      logger.error('Error deleting portfolio', e);
    } finally {
      setStore({ isUpdatingPortfolio: false });
    }
    return { success: false };
  }, [investorId, setStore]);

  const fetchSearchSecurities = useCallback(
    async (search, page, page_size) => {
      setStore({ isLoadingPortfolio: true });
      try {
        const response = await AuthFetch({
          url: `${process.env.REACT_APP_API_ENDPOINT}api/core/securities`,
          queryParams: { search, page, page_size },
          method: 'GET',
          useCache: false,
        });
        if (response.status) {
          return response.payload;
        }
        return [];
      } catch (e) {
        logger.error('Error fetching securities', e);
      } finally {
        setStore({ isLoadingPortfolio: false });
      }
      return [];
    },
    [setStore],
  );

  const resetPortfolioStore = useCallback(() => {
    setStore(initialState);
  }, [setStore]);

  const clearError = useCallback(() => {
    setError(false);
  }, [setError]);

  return {
    error,
    clearError,
    importPortfolio,
    createPortfolio,
    deletePortfolio,
    resetPortfolioStore,
    fetchSearchSecurities,
    fetchCurrentPortfolio,
  };
}

export default usePortfolioActions;
