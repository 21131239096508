import PrintIcon from '@mui/icons-material/Print';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState, useCallback, useEffect } from 'react';

import { Title } from 'components/Account/styles';
import Modal from 'components/NewModal';
import useTradeTicketActions from 'context/fastContext/tradeticket/actions';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';
import normalizeTime from 'utils/normalizeTime';

function RequestVaultModal({
  isOpen,
  triggerPrint,
  setComponentToPrint,
  setPrintRequest,
  handleShowRequestVault,
}: {
  triggerPrint: Function;
  isOpen: boolean;
  setComponentToPrint: any;
  setPrintRequest: any;
  handleShowRequestVault: Function;
}) {
  const [errorPrintingTicket, setErrorPrintingTicket] =
    useState<boolean>(false);
  const [expanded, setExpanded] = useState(null);

  const { fetchTradeTicket } = useTradeTicketActions();
  const [{ ticketHistory }] = useTradeTicketStore((s: TradeTicketState) => ({
    ticketHistory: s.ticketHistory,
  }));

  const toggleAccordion = (id: any) => {
    setExpanded((prev) => (prev === id ? null : id));
  };

  const handleTradeTicketPrint = useCallback(
    async (event) => {
      if (errorPrintingTicket) {
        setErrorPrintingTicket(false);
      }
      const { accountId } = event.currentTarget.dataset;
      const isSuccess = await fetchTradeTicket({
        useCache: true,
        isUpdating: true,
        accountId,
      });

      if (isSuccess) {
        setComponentToPrint('tradeTicket');
        triggerPrint();
      } else {
        setErrorPrintingTicket(true);
      }
    },
    [errorPrintingTicket, setComponentToPrint, fetchTradeTicket, triggerPrint],
  );

  const handleRequestPrint = useCallback(
    async (event) => {
      if (errorPrintingTicket) {
        setErrorPrintingTicket(false);
      }
      const { accountId, requestId } = event.currentTarget.dataset;
      const isSuccess = await fetchTradeTicket({
        useCache: true,
        isUpdating: true,
        accountId,
      });

      if (isSuccess) {
        setPrintRequest(requestId || '');
        setComponentToPrint('ticketRequest');
        triggerPrint();
      } else {
        setErrorPrintingTicket(true);
      }
    },
    [
      errorPrintingTicket,
      setPrintRequest,
      setComponentToPrint,
      fetchTradeTicket,
      triggerPrint,
    ],
  );

  useEffect(() => {
    if (!isOpen && errorPrintingTicket) {
      setErrorPrintingTicket(false);
    }
  }, [isOpen, errorPrintingTicket]);

  return (
    <Modal
      sx={{
        maxWidth: 'initial',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 24px)',
      }}
      close={handleShowRequestVault}
      isOpen={isOpen}
      transitionSpeed="0.5s"
    >
      <Box style={{ width: '506px' }}>
        <Title>Trade Ticket Request Vault</Title>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {ticketHistory.map(
            ({
              id,
              timestamp: ttTimeStamp,
              accountId,
              accountName,
              requests,
            }) => {
              return (
                <Box key={`tt-${id}`}>
                  {requests?.length > 0 ? (
                    <Accordion
                      sx={(theme) => ({
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                          outline: `1px solid ${theme.palette.primary.main}`,
                        },
                        borderRadius: '6px !important',
                      })}
                      expanded={expanded === id}
                    >
                      <AccordionSummary
                        sx={(theme) => ({
                          backgroundColor: theme.palette.primary[50],
                          color: theme.palette.grey[900],
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '0.75rem 1.25rem',
                          borderRadius: '6px',
                          '&.Mui-expanded': {
                            borderRadius: '6px 6px 0px 0px',
                            height: '60px !important',
                          },
                          '.MuiAccordionSummary-content': {
                            alignItems: 'center',
                            margin: 0,
                          },
                          cursor: 'default !important',
                        })}
                      >
                        <Typography flex={7}>{accountName}</Typography>
                        <Typography flex={6}>
                          {normalizeTime(ttTimeStamp)}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flex: 3,
                            justifyContent: 'flex-end',
                            gap: '12px',
                          }}
                        >
                          <Tooltip title="Request History">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleAccordion(id);
                              }}
                              sx={(theme) => ({
                                borderRadius: '6px',
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary[500],
                                },
                              })}
                            >
                              <RequestPageIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Print Trade Ticket">
                            <IconButton
                              onClick={handleTradeTicketPrint}
                              data-ticket-id={id}
                              data-account-id={accountId}
                              sx={(theme) => ({
                                borderRadius: '6px',
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary[500],
                                },
                              })}
                            >
                              <PrintIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </AccordionSummary>
                      {requests.map(
                        ({
                          id: requestId,
                          requestType,
                          timestamp: requestTimeStamp,
                        }) => {
                          return (
                            <AccordionDetails
                              key={requestTimeStamp}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '0.75rem 1.25rem',
                                minHeight: '64px',
                              }}
                            >
                              <Typography flex={7}>
                                {requestType} Request
                              </Typography>
                              <Typography flex={6}>
                                {normalizeTime(requestTimeStamp || '')}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flex: 3,
                                  justifyContent: 'flex-end',
                                  gap: '12px',
                                }}
                              >
                                <Tooltip title="Print Request">
                                  <IconButton
                                    data-request-id={requestId}
                                    data-account-id={accountId}
                                    onClick={handleRequestPrint}
                                    sx={(theme) => ({
                                      borderRadius: '6px',
                                      backgroundColor:
                                        theme.palette.primary[50],
                                      color: theme.palette.primary.main,
                                      '&:hover': {
                                        backgroundColor:
                                          theme.palette.primary[100],
                                      },
                                    })}
                                  >
                                    <PrintIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </AccordionDetails>
                          );
                        },
                      )}
                    </Accordion>
                  ) : (
                    <Box
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary[50],
                        color: theme.palette.grey[900],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0.75rem 1.25rem',
                        borderRadius: '6px',
                      })}
                    >
                      <Typography flex={7}>{accountName}</Typography>
                      <Typography flex={6}>
                        {normalizeTime(ttTimeStamp)}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flex: 3,
                          justifyContent: 'flex-end',
                          gap: '12px',
                        }}
                      >
                        <Tooltip title="Print Trade Ticket">
                          <IconButton
                            onClick={handleTradeTicketPrint}
                            data-ticket-id={id}
                            data-account-id={accountId}
                            sx={(theme) => ({
                              borderRadius: '6px',
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                              '&:hover': {
                                backgroundColor: theme.palette.primary[500],
                              },
                            })}
                          >
                            <PrintIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            },
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default RequestVaultModal;
