import styled from 'styled-components';

import DefaultLogo from 'components/Logo';

export const Container = styled.div`
  height: 100%;
  position: relative;
  page-break-after: always;
  page-break-before: always;
  width: 100%;

  @page {
    margin: 1cm;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const CompanyLogo = styled.img`
  max-height: 40px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const PageIndicator = styled.div`
  bottom: 1cm;
  font-size: 12px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grey600};
  position: absolute;
  right: 0;
`;

export const Logo = styled(DefaultLogo)``;
