import { useState, useCallback, useRef } from 'react';

const OPENING_TOOLTIP_DELAY = 300; // miliseconds

export const useTooltip = (isMobile: boolean) => {
  const tooltipOpeningRef = useRef<number | null>(null);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const onMouseEnterHandler = useCallback(() => {
    const timeoutId = window.setTimeout(
      () => setTooltipOpened(true),
      isMobile ? 0 : OPENING_TOOLTIP_DELAY,
    );
    tooltipOpeningRef.current = timeoutId;
  }, [isMobile]);

  const onMouseLeaveHandler = useCallback(() => {
    if (tooltipOpeningRef.current) {
      clearTimeout(tooltipOpeningRef.current);
      tooltipOpeningRef.current = null;
    }
    setTooltipOpened(false);
  }, []);

  return {
    tooltipOpened,
    onMouseEnterHandler,
    onMouseLeaveHandler,
  };
};
