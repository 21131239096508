import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import { useMemo } from 'react';

import CustomPagination from 'components/CustomPagination';

import tableColumns, { companyColumn } from './columns';

const createSkeletonRows = (count: number, columns: GridColDef[]): any[] => {
  return Array.from({ length: count }, (_, index) => {
    const row: any = { id: `skeleton-${index}` };
    columns.forEach((column) => {
      row[column.field] = (
        <Skeleton variant="text" width={column.width || 100} />
      );
    });
    return row;
  });
};

function CustomNoRowsOverlay() {
  return (
    <GridOverlay>
      <Typography variant="subtitle1">
        No clients match the selection criteria.
      </Typography>
    </GridOverlay>
  );
}

const ClientsTable = ({
  clients,
  isLoadingClientsList,
  role,
}: {
  clients: any[];
  isLoadingClientsList: boolean;
  role: string;
}) => {
  const columnsToUse = useMemo(
    () => (role === 'admin' ? [...tableColumns, companyColumn] : tableColumns),
    [role],
  );
  return (
    <DataGrid
      loading={isLoadingClientsList}
      autoHeight
      disableRowSelectionOnClick
      density="comfortable"
      slots={{
        pagination: (paginationProps) => (
          <CustomPagination
            {...paginationProps}
            exportFileName="Advisors List"
          />
        ),
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      rows={
        isLoadingClientsList ? createSkeletonRows(10, columnsToUse) : clients
      }
      getRowId={(row) => row.id}
      columns={columnsToUse}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: 'householdName', sort: 'asc' }],
        },
      }}
      pageSizeOptions={[10]}
      disableColumnMenu
      hideFooterSelectedRowCount
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '0.75rem',
          textTransform: 'uppercase',
        },
      }}
    />
  );
};

export default ClientsTable;
