function isObject(object: any): boolean {
  return object != null && typeof object === 'object';
}

function isDeepEqual(obj1: any, obj2: any): boolean {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  // Create a set of all keys excluding those with zero allocation in both objects
  const allKeys = new Set([...obj1Keys, ...obj2Keys]);
  ['allocation'].forEach((key) => {
    if (obj1[key] === 0 && obj2[key] === undefined) allKeys.delete(key);
    if (obj2[key] === 0 && obj1[key] === undefined) allKeys.delete(key);
  });

  if (allKeys.size !== obj1Keys.length || allKeys.size !== obj2Keys.length) {
    return false;
  }

  return Array.from(allKeys).every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // If the key is 'allocation', treat zero as equivalent to undefined
    if (key === 'allocation') {
      return (val1 === 0 || val1) === (val2 === 0 || val2);
    }

    const areObjects = isObject(val1) && isObject(val2);
    return (
      (areObjects && isDeepEqual(val1, val2)) || (!areObjects && val1 === val2)
    );
  });
}

export default function areEqualProducts<T extends { [key: string]: any }>(
  array: T[],
  object: { [key: string]: T },
): boolean {
  // Check if the array and object have the same number of non-zero items
  const nonZeroArray = array.filter((item) => item.allocation !== 0);
  const nonZeroObjectKeys = Object.keys(object).filter(
    (key) => object[key].allocation !== 0,
  );

  if (nonZeroArray.length !== nonZeroObjectKeys.length) {
    return false;
  }

  // Check each non-zero item in the array
  return nonZeroArray.every((item) => {
    const objectItem = object[item.id as keyof typeof object];

    // Check if the item exists in the object and has the same values
    return objectItem && isDeepEqual(item, objectItem);
  });
}
