import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.span<ContainerProps>`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.4285;

  ${({ theme, $hasValue }) =>
    $hasValue
      ? css`
          color: ${theme.colors.dark};
        `
      : css`
          color: ${theme.colors.grey600};
        `};
`;
