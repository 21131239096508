import styled, { css } from 'styled-components';

import { TextWrap } from 'styles/mixins';
import theme from 'styles/v2/theme';

const Transition = css`
  transition: opacity 80ms ease-out;
`;
export const Hidden = styled.div`
  display: none;
  @media print {
    display: block;
  }
`;

export const InlineInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 0.875rem;
  flex: 1;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  color: ${theme.palette.grey[900]};
  font-family: 'GreyCliff CF';
  font-size: 0.875rem;
  line-height: calc(20 / 14);
  width: 100%;
  ${TextWrap}

  ::placeholder {
    color: ${theme.palette.grey[500]};
    ${Transition}
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${theme.palette.grey[400]};
    cursor: not-allowed;
  }
`;

export const Page = styled.div`
  height: 100%;
  position: relative;
  page-break-after: always;
  width: 100%;

  &:last-child {
    page-break-after: auto;
  }
`;
