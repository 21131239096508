export const valueColors: { [K in 'E' | 'P' | 'I']: string } = {
  E: '#007562',
  P: '#72D2AD',
  I: '#D3F5E9',
};

export const opacityValues = {
  E: [100, 81, 69],
  P: [100, 83, 66],
  I: [100, 65, 40],
};

export const epiMap: { [key: string]: string } = {
  E: 'Earth',
  P: 'People',
  I: 'Integrity',
};
