import styled, { css } from 'styled-components';

import { ContainerProps, IconBoxProps } from './types';

export const Browse = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: bold;
  height: 1.1875rem;
  line-height: calc(19 / 16);
`;

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.primary10};
  border-radius: 0.75rem;
  border: 0.0625rem dashed ${({ theme }) => theme.colors.primary};
  height: 10.8125rem;
  padding: 3rem 2.5rem 2.6875rem 2.5rem;
  max-width: 28.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  ${({ isDragActive, isDragReject, theme }) => {
    if (isDragActive) {
      return isDragReject
        ? css`
            background-color: ${theme.colors.bridesmaid};
            border: 0.0625rem dashed ${theme.colors.flamingo};
          `
        : css`
            background-color: ${theme.colors.primary50};
          `;
    }
    return null;
  }}
`;

export const IconBox = styled.div<IconBoxProps>`
  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: 0.9375rem;
  color: ${({ theme }) => theme.colors.primary};
  height: 3.4375rem;
  margin-left: auto;
  margin-right: auto;
  width: 3.4375rem;
  margin-bottom: 0.3125rem;
  padding: 0.8125rem 0.6875rem 0.4375rem 1rem;

  ${({ isDragReject, theme }) =>
    !!isDragReject &&
    css`
      background-color: inherit;
      color ${theme.colors.flamingo};
  `}
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 1rem;
  height: 1.1875rem;
  line-height: calc(19 / 16);
  text-align: center;
`;
