import styled, { css } from 'styled-components';

import { from } from 'styles/media';
import { VisuallyHidden } from 'styles/mixins';

import { PadProps } from './types';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  max-width: 100%;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    max-width: 30rem;
  }
`;

export const HiddenRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  ${VisuallyHidden}

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.primary50};
    border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  &:focus + label {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Option = styled.label<PadProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  color: ${({ theme }) => theme.colors.grey600};
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  padding: 0.625rem 1rem;
  width: 100%;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ withDescription }) =>
    withDescription &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

  &[for=''] {
    cursor: not-allowed;
  }
`;

export const Label = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
`;

export const Description = styled.span`
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
`;
