export const tradeInstructionOptions = [
  {
    name: 'Select (Optional)',
    value: '',
    description: '',
  },
  {
    name: 'Do Not Buy',
    value: 'do not buy',
    // description:
    //   'List any ticker(s), whether currently present in the Seeds models included in the account allocation or not, that you do NOT wish to be held in the account.',
  },
  {
    name: 'Do Not Sell',
    value: 'do not sell',
    // description:
    //   'List ticker(s) that are legacy assets currently held in the account that you do NOT wish to be sold out of the account upon investment in Seeds models.',
  },
  {
    name: 'Carve Out',
    value: 'carve out',
    // description:
    //   'List CUSIP(s) that cannot be sold from the legacy assets currently held in the account that you wish to carve out of the overall account allocation. These holdings will not be included in the regular rebalancing/maintenance of the account.',
  },
  {
    name: 'Other',
    value: 'other',
    description: '',
  },
];

export const tradeTicketHelp = [
  {
    title: 'Tax Loss Harvesting',
    children: [
      {
        title: 'Qualified Accounts',
        description:
          'Tox Loss Harvesting does not apply to qualified accounts.',
      },
      {
        title: 'Non-Qualified Accounts',
        description:
          "All Non-Qualified accounts have active tax monitoring where Tax Loss Harvesting (TLH) Opportunities are reviewed on a weekly basis. The settings for these weekly TLH scans are a minimum $100 or 10% loss. This is in an effort to reduce the client's overall tax bill at the end of the year, if possible. The default is to have this feature turned on, but you can turn TLH off for this Non-Qualified account. The Cost Basis depletion method for all taxable accounts must be set to Tax Lot Optimizer (Schwab) or Short Term Tax Sensitive (Fidelity).",
      },
    ],
  },
  {
    title: 'Tax Transition',
    description:
      "For taxable accounts, you are able to take a client's current invested account and mindfully transition the legacy holdings into the intended Seeds models while keeping in mind Capital Gains budgets and desired timeframe (number of tax years). The Cost Basis depletion method for all taxable accounts must be set to Tax Lot Optimizer (Schwab) or Short Term Tax Sensitive (Fidelity).",
    children: [
      {
        title: 'Capital Gains Budget',
        description:
          'Provide the Capital Gains budget per year for the entire account while it is in transition.',
      },
      {
        title: 'Number of Tax Years',
        description:
          'Choose the number of tax years in which to transition the account from legacy holdings to Seeds model holdings/allocation. Choose from 2-4 years.',
      },
    ],
  },
  {
    title: 'Cash Management',
    children: [
      {
        title: 'Initial Set Aside',
        description:
          'List the amount of funds in the initial account value that you want set aside, kept in cash, and not invested in the account allocation. (For example; money for a home down payment, medical expense, etc. that will be withdrawn at a later date.)',
      },
      {
        title: 'Monthly Cash Raise',
        description:
          'List the monthly recurring amount of cash that the client would like to raise from investments in their account. (Note: We raise 3 months of cash at a time to ensure cash is available on the specific date of monthly distribution, while helping to avoid wash sales.)',
      },
    ],
  },
  {
    title: 'Trade Instructions',
    children: [
      {
        title: 'Do Not Buy',
        description:
          'List any ticker(s), whether currently present in the Seeds models included in the account allocation or not, that you do NOT wish to be <u>held</u> in the account.',
      },
      {
        title: 'Do Not Sell',
        description:
          'List ticker(s) that are legacy assets currently held in the account that you do NOT wish to be <u>sold</u> out of the account upon investment in Seeds models.',
      },
      {
        title: 'Carve Outs',
        description:
          'List CUSIP(s) that cannot be sold from the legacy assets currently held in the account that you wish to carve out of the overall account allocation. These holdings will not be included in the regular rebalancing/maintenance of the account.',
      },
    ],
  },
];
