import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  border-radius: 0.75rem;
  border: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  overflow: hidden;

  ${({ isDragActive, isDragReject, theme }) => {
    if (isDragActive) {
      return isDragReject
        ? css`
            border: solid 0.0625rem ${theme.colors.flamingo};
          `
        : css`
            border: solid 0.0625rem ${theme.colors.primary};
          `;
    }
    return null;
  }}
`;

export const NewFileRow = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.primary10};
`;
