import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.button<ContainerProps>`
  padding: 0.4375rem 0.875rem;
  border-radius: 0.3125rem;
  border: none;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: calc(20 / 14);
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme, variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.primary};
        `;
      case 'secondary':
        return css`
          color: ${theme.colors.primary};
          background-color: ${theme.colors.primary50};
        `;
      case 'transparent':
        return css`
          color: ${theme.colors.grey500};
          background-color: transparent;
        `;
      case 'alert':
        return css`
          background-color: ${theme.colors.bridesmaid};
          color: ${theme.colors.flamingo};
        `;
      case 'danger':
        return css`
          background-color: ${theme.colors.flamingo};
          color: ${theme.colors.bridal};
        `;
      default:
        return css`
          color: ${theme.colors.grey500};
          background-color: ${theme.colors.grey0};
        `;
    }
  }}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey200};
    color: ${({ theme }) => theme.colors.grey500};
    cursor: not-allowed;
  }
`;
