import useConnect from './connect';
import Loading from './Loading';
import ResetForm from './ResetForm';
import Success from './Success';

const NewPassword = () => {
  const { handleSubmitReset, status } = useConnect();

  switch (status) {
    case 'LOADING':
      return <Loading />;
    case 'RESET':
      return <ResetForm onSubmit={handleSubmitReset} />;
    case 'SUCCESS':
      return <Success />;
    default:
      return <></>;
  }
};

export default NewPassword;
