import keysOf from 'utils/keysOf';

import SortColumn from '../SortColumn';
import { useTable } from '../TableContext';
import { Container, Row, Cell, SelectAll, Actions } from './styles';
import { Props } from './types';

function Header<T>({ columns, hasActions, ...props }: Props<T>) {
  const { tableId, selectable, sortable, areAllSelected } = useTable();
  const columnElements = keysOf(columns).map((columnName) => {
    const { header: columnElement, width, align, sortBy } = columns[columnName];
    return (
      <Cell key={columnName as string} $width={width} $align={align}>
        {sortable && sortBy !== false ? (
          <SortColumn columnName={columnName as string}>
            {columnElement}
          </SortColumn>
        ) : (
          columnElement
        )}
      </Cell>
    );
  });

  return (
    <Container {...props}>
      <Row selectable={selectable}>
        {selectable && (
          <Cell>
            <SelectAll id={`${tableId}-select-all`} checked={areAllSelected} />
          </Cell>
        )}
        {columnElements}
        {hasActions && <Actions />}
      </Row>
    </Container>
  );
}

export default Header;
