import { FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';

import useAuthActions from 'graphql/hooks/useAuthActions';

import { FormValues } from './types';

const useConnect = () => {
  const { requestResetPassword } = useAuthActions();
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
      try {
        await requestResetPassword(values.email);
        setSuccess(true);
      } catch (error) {
        setErrors({ email: error.message });
        setSuccess(false);
      }
    },
    [requestResetPassword, setSuccess],
  );

  return {
    handleSubmit,
    success,
  };
};

export default useConnect;
