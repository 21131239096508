import { FC, useCallback, useMemo } from 'react';

import { useTable } from 'components/Table/TableContext';

import { Container, Indicators, Asc, Desc } from './styles';
import { Props } from './types';

const SortColumn: FC<Props> = ({ columnName, children, ...props }) => {
  const { sortBy, sortedBy } = useTable();

  const direction = useMemo(() => {
    if (!sortedBy || columnName !== sortedBy.column) return undefined;
    return sortedBy.direction;
  }, [sortedBy, columnName]);

  const handleClick = useCallback(() => {
    const newDirection = direction && direction === 'asc' ? 'desc' : 'asc';
    sortBy({
      column: columnName,
      direction: newDirection,
    });
  }, [direction, columnName, sortBy]);

  return (
    <Container
      {...props}
      onClick={handleClick}
      type="button"
      $direction={direction}
    >
      {children}
      <Indicators>
        {(direction === 'asc' || direction === undefined) && <Asc />}
        {(direction === 'desc' || direction === undefined) && <Desc />}
      </Indicators>
    </Container>
  );
};

export default SortColumn;
