import chunk from 'lodash/chunk';
import { useMemo, useEffect } from 'react';

import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import type { ProposalState } from 'context/fastContext/proposal/types';
import useInsightsLibrary from 'context/hooks/InsightsLibrary.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useMe from 'graphql/hooks/useMe';
import { useChartData } from 'hooks/useChartData';

const useConnect = () => {
  const { investorInfo } = useInvestorInfo();
  const { me } = useMe();

  const [combinedHoldings] = useProposalStore(
    (s: ProposalState) => s.masterProposal.combinedHoldings,
  );

  const orderedByValueScoreAndWeight = useMemo(() => {
    return combinedHoldings.sort((a, b) => {
      // First, compare by scoreValue
      const scoreDifference =
        (b.asset.scoreValue || 0) - (a.asset.scoreValue || 0);

      if (scoreDifference !== 0) {
        // If scoreValue is different, return the difference
        return scoreDifference;
      }
      // If scoreValue is the same, compare by actualWeight
      return (b.actualWeight || 0) - (a.actualWeight || 0);
    });
  }, [combinedHoldings]);

  const holdingsData = [
    [...orderedByValueScoreAndWeight.slice(0, 22)],
    ...chunk(orderedByValueScoreAndWeight.slice(22), 22),
  ];

  const dontRecommend = useMemo(
    () =>
      investorInfo?.surveyResult?.timeHorizon?.split('-').join('') === '14' &&
      investorInfo?.surveyResult.riskTolerance === 'MODEST',
    [investorInfo],
  );

  const chartData = useChartData();

  const { fetchInvestorFiles, fetchInvestorFolders, files, folders } =
    useInsightsLibrary();

  useEffect(() => {
    const fetchFolderData = async () => {
      await fetchInvestorFolders();
    };

    const fetchFileData = async () => {
      await fetchInvestorFiles(investorInfo?.id);
    };

    fetchFolderData();
    if (investorInfo?.id) {
      fetchFileData();
    }
  }, [fetchInvestorFiles, fetchInvestorFolders, investorInfo?.id]);

  const goalsResults = [
    {
      goal: 'Risk Tolerance',
      value: investorInfo?.surveyResult?.riskTolerance,
    },
    {
      goal: 'Time Horizon',
      value: investorInfo?.surveyResult?.timeHorizon
        ? `${investorInfo?.surveyResult.timeHorizon
            .split('-')
            .join(' - ')} Years`
        : undefined,
    },
  ];

  return {
    chartData,
    dontRecommend,
    files,
    folders,
    goalsResults,
    holdingsData,
    investorInfo,
    me,
    totalPages: holdingsData.length + 5,
  };
};

export default useConnect;
