import { FaRocket } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import useActiveSurvey from 'graphql/hooks/useSurvey';

import { Container, Content, Title } from './styles';

export const PleaseCompleteAssessment = ({
  investorId,
}: {
  investorId: string;
}) => {
  const { push } = useHistory();
  const { survey } = useActiveSurvey();

  const onGoAssessment = () => {
    // TODO: Decide what to do with this
    // I feel like this is irrelevant...should just go to the first question in survey
    // as the financial section is first anyway so it will always default to 0
    const surveyStepIndex =
      survey?.steps.findIndex((el) => {
        const containsNewSectionLink = !!el.sectionLink;
        return (
          containsNewSectionLink
            ? el.sectionLink.name.toLowerCase()
            : el.section
        ).includes('financial');
      }) || 0;

    push(`/clients/${investorId}/survey/${surveyStepIndex + 1}`);
  };

  return (
    <Container className="red">
      <Content>
        <Title>
          Please complete the Assessment to see your values aligned portfolio.{' '}
        </Title>
        {investorId && (
          <Button
            variant="primary"
            onClick={() => {
              onGoAssessment();
            }}
            icon={<FaRocket />}
          >
            Launch Assessment
          </Button>
        )}
      </Content>
    </Container>
  );
};
