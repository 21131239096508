import { DataViewContainerTitleSwitch } from 'components/DataViewContainerTitleSwitch';
import AdvisorsEmpty from 'components/TableEmptyState/AdvisorsEmpty';
import DataViewContainer from 'containers/DataViewContainer';

import { useConnect } from './connect';

const Advisors = () => {
  const {
    advisors,
    itemsShown,
    schema,
    handleSearch,
    handleSort,
    pagination,
    sortedBy,
    loading,
  } = useConnect();

  return (
    <DataViewContainer
      cardWidth={570}
      data={advisors}
      empty={<AdvisorsEmpty />}
      itemsShown={itemsShown}
      onSearch={handleSearch}
      onSort={handleSort}
      pagination={pagination}
      recipientRole="ADVISOR"
      schema={schema}
      title={<DataViewContainerTitleSwitch title="Seeds Advisors" />}
      sortedBy={sortedBy}
      loading={loading && !advisors.length}
    />
  );
};

export default Advisors;
