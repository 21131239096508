import Box from '@mui/material/Box';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ContainerMaxWidth from 'components/ContainerMaxWidth';
import Footer from 'components/Footer';
import useUser from 'context/hooks/User.hooks';
import AddHousehold from 'v2/components/AddHousehold';

const InvestorOnboardingHousehold = () => {
  const { advisorShortHash } = useParams<{ advisorShortHash: string }>();
  const { fetchAdvisorInfo, advisorInfo } = useUser();
  useEffect(() => {
    fetchAdvisorInfo(advisorShortHash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyLogo = useMemo(
    () => advisorInfo?.company?.logo,
    [advisorInfo?.company?.logo],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#F8F9FA',
      }}
    >
      <ContainerMaxWidth>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 3,
          }}
        >
          <AddHousehold />
        </Box>
      </ContainerMaxWidth>
      <Footer companyLogo={companyLogo} />
    </Box>
  );
};

export default InvestorOnboardingHousehold;
