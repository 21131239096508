import styled from 'styled-components';

export const ResultItemContainer = styled.div`
  padding: 1.25rem;
  font-size: 0.75rem;
  line-height: calc(18 / 12);
  color: ${({ theme }) => theme.colors.dark};
`;

export const ResultItemTitle = styled.h3`
  margin-bottom: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const StandardTitle = styled.h3`
  margin-bottom: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey700};
`;
