import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Field, FieldProps, Formik } from 'formik';
import { useState, useEffect, useMemo, useCallback } from 'react';

import Checkbox from 'components/Checkbox';
import Input, { InputGroup, InputError } from 'components/Input';
import PasswordInput from 'components/PasswordInput';
import PhoneInput from 'components/PhoneInput';
import PrettyLoading from 'components/PrettyLoading';
import TermsModal from 'containers/Modals/TermsModal';
import useModal from 'graphql/hooks/useModal';

import useConnect from './connect';
import { getInitialValues, validationSchema } from './constants';
import {
  Button,
  Card,
  CompanyLogo,
  Container,
  Content,
  Form,
  Header,
  Logo,
  Illustration,
  Link,
  StrengthMeter,
  StrengthColors,
  StrengthIcon,
  Text,
  ValidationGroup,
  ValidationItem,
  ValidationWrapper,
  Wrapper,
  Inputs,
} from './styles';

function AdvisorOnboarding() {
  const { errorMessage, isMobile, loading, handleSubmit, advisor } =
    useConnect();
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [hasLength, setHasLength] = useState<boolean>(false);
  const [hasLower, setHasLower] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasUpper, setHasUpper] = useState<boolean>(false);
  const [hasSpecial, setHasSpecial] = useState<boolean>(false);
  const { close: closeModal, isOpen, open: openModal } = useModal('TERMS');

  const companyLogo = useMemo(
    () => advisor?.companyLogo,
    [advisor?.companyLogo],
  );

  const hasUrl = companyLogo?.includes('https://');

  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = useCallback(() => {
    if (isOpen) {
      closeModal();
    } else {
      openModal();
    }
  }, [closeModal, isOpen, openModal]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordStrength = () => {
    const conditions = [hasLength, hasUpper, hasLower, hasNumber, hasSpecial];
    return conditions.filter(Boolean).length;
  };

  useEffect(() => {
    setHasLength(password.length >= 12);
    setHasUpper(/[A-Z]/.test(password));
    setHasLower(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecial(/[!,%,&,@,#,$,^,*,?,_,~]/.test(password));
  }, [password]);

  if (loading) return <PrettyLoading />;

  return (
    <>
      <Container>
        <Card>
          {!isMobile ? <Illustration /> : null}
          <Wrapper>
            <Content>
              {companyLogo ? (
                <CompanyLogo
                  src={
                    hasUrl
                      ? companyLogo
                      : `${process.env.REACT_APP_S3_MEDIA}${companyLogo}`
                  }
                  alt=""
                />
              ) : (
                <Logo size="medium-extra" />
              )}
              <Header>
                Welcome!
                <p>Enter your details to finish setting up your account.</p>
              </Header>
              {errorMessage ? (
                <Box>
                  <div>{errorMessage}</div>
                </Box>
              ) : (
                <Formik
                  initialValues={getInitialValues(advisor)}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    isSubmitting,
                    touched,
                    handleChange,
                  }) => (
                    <Form>
                      <InputGroup>
                        <Field
                          as={Input}
                          name="company"
                          error={!!errors.company && !!touched.company}
                          placeholder="Company"
                          className="resize"
                          autoComplete="company"
                          disabled={!!advisor?.company}
                        />
                        <InputError name="company" />
                      </InputGroup>
                      <Inputs>
                        <InputGroup>
                          <Field
                            as={Input}
                            name="firstName"
                            error={!!errors.firstName && !!touched.firstName}
                            placeholder="First name"
                            autoComplete="given-name"
                            disabled={!!advisor?.user?.firstName}
                          />
                          <InputError name="firstName" />
                        </InputGroup>
                        <InputGroup>
                          <Field
                            as={Input}
                            name="lastName"
                            error={!!errors.lastName && !!touched.lastName}
                            placeholder="Last name"
                            autoComplete="family-name"
                            disabled={!!advisor?.user?.lastName}
                          />
                          <InputError name="lastName" />
                        </InputGroup>
                      </Inputs>
                      <InputGroup>
                        <Field
                          as={PhoneInput}
                          name="phone"
                          error={!!errors.phone && !!touched.phone}
                          placeholder="Phone number"
                          className="resize"
                          autoComplete="telPhone"
                        />
                        <InputError name="phone" />
                      </InputGroup>
                      <InputGroup style={{ marginTop: '0.5rem' }}>
                        <Field
                          as={PasswordInput}
                          autoComplete="new-password"
                          name="password"
                          error={!!errors.password && !!touched.password}
                          placeholder="New Password"
                          type={showPassword ? 'text' : 'password'}
                          className="resize"
                          onChange={(e: any) => {
                            handleChange(e);
                            setPassword(e.target.value);
                          }}
                          value={password}
                          rightIcon={
                            <IconButton
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          }
                        />
                        <InputError name="password" />
                      </InputGroup>
                      <InputGroup>
                        <Field
                          as={Input}
                          autoComplete="off"
                          name="confirmPassword"
                          error={
                            !!errors.confirmPassword &&
                            !!touched.confirmPassword
                          }
                          placeholder="Confirm your new password"
                          type={showPassword ? 'text' : 'password'}
                          className="resize"
                        />
                        <InputError name="confirmPassword" />
                      </InputGroup>
                      <StrengthMeter>
                        {[1, 2, 3, 4, 5].map((item) => (
                          <StrengthIcon
                            key={item}
                            style={{
                              background:
                                item <= passwordStrength()
                                  ? StrengthColors[item - 1]
                                  : StrengthColors[5],
                            }}
                          />
                        ))}
                      </StrengthMeter>
                      <ValidationGroup>
                        <ValidationWrapper>
                          <ValidationItem className={hasLength ? 'valid' : ''}>
                            Minimum 12 characters
                          </ValidationItem>
                          <ValidationItem className={hasUpper ? 'valid' : ''}>
                            One uppercase letter
                          </ValidationItem>
                          <ValidationItem className={hasLower ? 'valid' : ''}>
                            One lowercase letter
                          </ValidationItem>
                        </ValidationWrapper>
                        <ValidationWrapper>
                          <ValidationItem className={hasNumber ? 'valid' : ''}>
                            One number
                          </ValidationItem>
                          <ValidationItem className={hasSpecial ? 'valid' : ''}>
                            Special symbol (@,#,$,%,&,!)
                          </ValidationItem>
                        </ValidationWrapper>
                      </ValidationGroup>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <Field name="acceptTerms" type="checkbox">
                          {({ field }: FieldProps) => (
                            <Checkbox
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          )}
                        </Field>
                        <Text>
                          I agree to the
                          <span
                            tabIndex={0}
                            onClick={handleToggleModal}
                            onKeyUp={handleToggleModal}
                            role="button"
                          >
                            Terms of Service.
                          </span>
                        </Text>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'top',
                          marginBottom: '1.5rem',
                        }}
                      >
                        <Field name="accept2FA" type="checkbox">
                          {({ field }: FieldProps) => (
                            <Checkbox
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          )}
                        </Field>
                        <Text>
                          By providing your phone number, you agree to receive
                          text messages from Seeds Investor. Message and data
                          rates may apply.
                        </Text>
                      </Box>
                      <Button
                        disabled={
                          !(values.acceptTerms && values.accept2FA) ||
                          isSubmitting
                        }
                        type="submit"
                      >
                        Complete Setup
                      </Button>
                      <Text>
                        <Link to="/login" className="return">
                          Sign In
                        </Link>
                      </Text>
                    </Form>
                  )}
                </Formik>
              )}
            </Content>
          </Wrapper>
        </Card>
      </Container>
      <TermsModal isInvestor />
    </>
  );
}

export default AdvisorOnboarding;
