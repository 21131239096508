import styled from 'styled-components';

import ContactCard from 'components/ContactCard';

export const People = styled.div<{ $numberOfPeople: number }>`
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 1fr ${({ $numberOfPeople }) =>
      $numberOfPeople > 1 && `1fr`};
  margin-bottom: 2.5rem;
`;

export const Person = styled(ContactCard)<{ $numberOfPeople: number }>`
  ${({ $numberOfPeople }) =>
    $numberOfPeople === 1 &&
    `
      max-width: 100%;
      display: grid;
    `}
`;
