import { Formik, Form, Field, FieldArray } from 'formik';
import { Redirect } from 'react-router-dom';

import { ReactComponent as RemoveIcon } from 'assets/svg/minus.svg';
import { ReactComponent as AddIcon } from 'assets/svg/plusCircle.svg';
import AutoSave from 'components/AutoSave';
import DateInput from 'components/DateInput';
import Input, { InputError, InputGroup } from 'components/Input';
import PhoneInput from 'components/PhoneInput';
import PrettyLoading from 'components/PrettyLoading';
import { defaultOther } from 'containers/Main/AddHousehold/constants';
import { AdvisorPicker } from 'containers/Main/AddHousehold/styles';
import { MemberInstanceError } from 'containers/Main/AddHousehold/types';
import RemoveHouseholdModal from 'containers/Modals/RemoveHouseholdModal';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';

import useConnect from './connect';
import { initialValues, validationSchema } from './constants';
import {
  Back,
  Button,
  Buttons,
  Container,
  Header,
  Indicator,
  Inputs,
  SecondaryTitle,
  Title,
} from './styles';

const EditHousehold = () => {
  const { investorInfo, loadingInvestorInfo } = useInvestorInfo();
  const { handleOpenModal, handleSubmit, wasSaved } = useConnect();

  if (loadingInvestorInfo && !investorInfo) return <PrettyLoading />;

  if (!investorInfo || !investorInfo.householdPerson) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Container>
        <Formik
          initialValues={initialValues(investorInfo)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, values, isValid }) => (
            <>
              <Header>
                <Back to={`/clients/${investorInfo.id}`} title="Go back" />
                <Title>Edit household information</Title>
                {isSubmitting && <Indicator>Saving...</Indicator>}
                {!isSubmitting && wasSaved && <Indicator>Saved!</Indicator>}
              </Header>
              <Form>
                <Inputs>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="householdName"
                      error={!!errors.householdName && !!touched.householdName}
                      placeholder="Household name"
                      autoFocus
                    />
                    <InputError name="householdName" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="primary.email"
                      error={
                        !!errors.primary?.email && !!touched.primary?.email
                      }
                      placeholder="Email"
                      autoComplete="email"
                    />
                    <InputError name="primary.email" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="primary.firstName"
                      error={
                        !!errors.primary?.firstName &&
                        !!touched.primary?.firstName
                      }
                      placeholder="First name"
                    />
                    <InputError name="primary.firstName" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="primary.lastName"
                      error={
                        !!errors.primary?.lastName &&
                        !!touched.primary?.lastName
                      }
                      placeholder="Last name"
                    />
                    <InputError name="primary.lastName" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={DateInput}
                      name="primary.birthDate"
                      error={
                        !!errors.primary?.birthDate &&
                        !!touched.primary?.birthDate
                      }
                      placeholder="Birth date (optional)"
                    />
                    <InputError name="primary.birthDate" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={PhoneInput}
                      name="primary.phone"
                      error={
                        !!errors.primary?.phone && !!touched.primary?.phone
                      }
                      placeholder="Phone number (optional)"
                    />
                    <InputError name="primary.phone" />
                  </InputGroup>
                  <SecondaryTitle
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                  >
                    Primary Advisor:
                  </SecondaryTitle>
                  <InputGroup>
                    <Field
                      as={AdvisorPicker}
                      name="advisor"
                      error={!!errors.advisor && !!touched.advisor}
                    />
                    <InputError name="advisor" />
                  </InputGroup>
                </Inputs>
                <SecondaryTitle>Household Members</SecondaryTitle>
                <FieldArray name="others">
                  {({ push, remove }) => (
                    <>
                      {values.others!.map((_: any, index: number) => {
                        const key = `other-${index}`;
                        return (
                          <Inputs key={key}>
                            <Button
                              type="button"
                              icon={<RemoveIcon />}
                              onClick={() => remove(index)}
                              disabled={isSubmitting}
                              variant="alert"
                            />
                            <InputGroup>
                              <Field
                                as={Input}
                                name={`others.${index}.firstName`}
                                error={
                                  errors.others &&
                                  errors?.others[index] &&
                                  (errors?.others[index] as MemberInstanceError)
                                    ?.firstName &&
                                  touched?.others &&
                                  touched?.others[index] &&
                                  touched?.others[index].firstName
                                }
                                placeholder="First name"
                              />
                              <InputError name={`others.${index}.firstName`} />
                            </InputGroup>
                            <InputGroup>
                              <Field
                                as={Input}
                                name={`others.${index}.lastName`}
                                error={
                                  errors.others &&
                                  errors?.others[index] &&
                                  (errors?.others[index] as MemberInstanceError)
                                    ?.lastName &&
                                  touched?.others &&
                                  touched?.others[index] &&
                                  touched?.others[index].lastName
                                }
                                placeholder="Last name"
                              />
                              <InputError name={`others.${index}.lastName`} />
                            </InputGroup>
                            <InputGroup>
                              <Field
                                as={DateInput}
                                name={`others.${index}.birthDate`}
                                error={
                                  errors.others &&
                                  errors?.others[index] &&
                                  (errors?.others[index] as MemberInstanceError)
                                    ?.birthDate &&
                                  touched?.others &&
                                  touched?.others[index] &&
                                  touched?.others[index].birthDate
                                }
                                placeholder="Birth date (optional)"
                              />
                              <InputError name={`others.${index}.birthDate`} />
                            </InputGroup>
                            <InputGroup>
                              <Field
                                as={Input}
                                name={`others.${index}.email`}
                                error={
                                  errors.others &&
                                  errors?.others[index] &&
                                  (errors?.others[index] as MemberInstanceError)
                                    ?.email &&
                                  touched?.others &&
                                  touched?.others[index] &&
                                  touched?.others[index].email
                                }
                                placeholder="Email"
                              />
                              <InputError name={`others.${index}.email`} />
                            </InputGroup>
                            <InputGroup>
                              <Field
                                as={PhoneInput}
                                name={`others.${index}.phone`}
                                error={
                                  errors.others &&
                                  errors?.others[index] &&
                                  (errors?.others[index] as MemberInstanceError)
                                    ?.phone &&
                                  touched?.others &&
                                  touched?.others[index] &&
                                  touched?.others[index].phone
                                }
                                placeholder="Phone number (optional)"
                              />
                              <InputError name={`others.${index}.phone`} />
                            </InputGroup>
                          </Inputs>
                        );
                      })}

                      <Buttons>
                        <Button
                          type="button"
                          variant="secondary"
                          icon={<AddIcon />}
                          onClick={() => {
                            push(defaultOther);
                          }}
                        >
                          Add household person
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting || !isValid}
                        >
                          Save changes
                        </Button>
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={handleOpenModal}
                          disabled={isSubmitting}
                        >
                          Remove household
                        </Button>
                        <AutoSave everyMs={500} />
                      </Buttons>
                    </>
                  )}
                </FieldArray>
              </Form>
            </>
          )}
        </Formik>
      </Container>
      <RemoveHouseholdModal investorId={investorInfo.id} />
    </>
  );
};

export default EditHousehold;
