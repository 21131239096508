import { Form as DefaultForm } from 'formik';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import DefaultButton from 'components/Button';
import DefaultCard from 'components/Card';
import DefaultLogo from 'components/Logo';
import { from } from 'styles/media';

import DefaultIllustration from '../Login/SidePicture';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey0};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Form = styled(DefaultForm)`
  width: 100%;
`;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  width: 100%;
  justify-content: center;
  min-height: 2.5rem;
`;

export const Card = styled(DefaultCard).attrs(() => ({
  variant: 'borderless',
}))`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border-radius: 0;
`;

export const Illustration = styled(DefaultIllustration)`
  display: none;
  flex-shrink: 0;

  ${from.tablet} {
    display: flex;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 528px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;

  .resize {
    max-width: unset;
  }

  .inputGroup {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 769px) {
    padding: 4rem;
  }

  ${from.tablet} {
    margin-bottom: 6rem;
  }
`;

export const Logo = styled(DefaultLogo)`
  margin-bottom: 3rem;
`;

export const Link = styled(DefaultLink)`
  align-self: flex-start;
  color: inherit;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.rainForest};
  text-decoration: none;
  font-size: 0.875rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const RecoveryHeader = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'GreyCliff CF';
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 32);
  margin-bottom: 1.5rem;

  p {
    color: ${({ theme }) => theme.colors.grey600};
    margin-top: 10px;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.weights.regular};
  }

  ${from.largeScreen} {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.dark};

  .demo {
    color: ${({ theme }) => theme.colors.primary};
    margin: 3rem 0px;
    font-size: 16px;
  }

  .return {
    color: ${({ theme }) => theme.colors.primary};
    margin: 1rem auto 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  flex: 5;
`;
