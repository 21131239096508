import { useCallback, useMemo } from 'react';

import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useSearchParams from 'utils/useSearchParams';

import { Action, Actions } from '../types';

export function useConnect() {
  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));
  const [{ proposalState, status }] = useProposalStore((s: any) => ({
    status: s.masterProposal.status,
    proposalState: s.proposalState,
  }));
  const { investorInfo } = useInvestorInfo();

  const query = useSearchParams();

  const isCurrent = useMemo(() => {
    if (proposalState === 'IMPLEMENTED') {
      return false;
    }
    return query.get('portfolio') !== 'seeds';
  }, [proposalState, query]);

  const hasUploadedPortfolio =
    currentPortfolio?.holdings && currentPortfolio?.holdings?.length > 0;

  const currentPortfolioHasAssets = !!currentPortfolio?.holdings?.length;

  const isSurveyCompleted = investorInfo?.assessmentStatus === 'COMPLETED';
  // const isAccountOpen = investorInfo?.investorStatus === 'OPEN';
  // const isAccountPending = investorInfo?.investorStatus === 'PENDING';
  const isAccountOpen = proposalState === 'IMPLEMENTED';
  const isAccountPending = proposalState === 'PENDING';
  const assesmentSent = investorInfo?.assessmentStatus === 'SENT';
  // const hasSeedsDiagnostic = !!currentProposal?.status;
  const hasSeedsDiagnostic = !!status;

  // TODO: Rewrite this
  const can = useCallback(
    (action: Action) => {
      const actions: Partial<Actions> = {
        'download-proposal':
          (isSurveyCompleted && hasSeedsDiagnostic) ||
          isAccountPending ||
          isAccountOpen,
        'download-monthly-snapshot': assesmentSent,
        'show-holdings-tabs':
          currentPortfolioHasAssets &&
          hasSeedsDiagnostic &&
          !investorInfo?.seedsInvested &&
          !isAccountOpen,
        'show-seeds-holdings':
          (!isCurrent && hasSeedsDiagnostic) ||
          !!investorInfo?.seedsInvested ||
          (hasSeedsDiagnostic && !currentPortfolioHasAssets) ||
          isAccountOpen,
        'show-single-portfolio-current':
          !investorInfo?.seedsInvested && !hasSeedsDiagnostic,
        'show-single-portfolio-seeds':
          !!investorInfo?.seedsInvested ||
          (hasSeedsDiagnostic && !currentPortfolioHasAssets) ||
          (hasUploadedPortfolio && isAccountOpen),
        'show-current-and-seeds-portfolio':
          !investorInfo?.seedsInvested &&
          currentPortfolioHasAssets &&
          hasSeedsDiagnostic,
      };

      const value = actions[action];
      if (typeof value === 'function') {
        return value();
      }
      return value;
    },
    [
      isSurveyCompleted,
      hasSeedsDiagnostic,
      isAccountPending,
      isAccountOpen,
      assesmentSent,
      currentPortfolioHasAssets,
      investorInfo?.seedsInvested,
      isCurrent,
      hasUploadedPortfolio,
    ],
  );

  return {
    can,
  };
}
