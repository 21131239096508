function roundToNDecimalsIfNeeded(
  value: number,
  maxDecimals: number = 2,
): number {
  // Convert to a string to check if it has more than two decimal places
  const valueAsString = value.toString();
  // Find the decimal part
  const decimalPart = valueAsString.split('.')[1];
  // If there is a decimal part and its length is more than maxDecimals, round it
  if (decimalPart && decimalPart.length > maxDecimals) {
    return parseFloat(value.toFixed(maxDecimals));
  }
  // Otherwise, return the original number
  return value;
}
export default roundToNDecimalsIfNeeded;
