import { FC, memo } from 'react';

import { ReactComponent as Icon } from 'assets/svg/assetsEmpty.svg';

import { Container, Text } from './styles';
import { Props } from './types';

const EditPortfolioEmpty: FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Icon />
      <Text>
        Import your assets using a spreadsheet or
        <br /> add them manually using the search function
      </Text>
    </Container>
  );
};

export default memo(EditPortfolioEmpty);
