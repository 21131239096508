import styled from 'styled-components';

import BaseSurveyStatus from 'components/Sidebar/SurveyStatus';

import BaseProfileAlt from './ProfileAlt';

export const ProfileAlt = styled(BaseProfileAlt)`
  margin-bottom: 1.3125rem;
`;

export const Container = styled.aside`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 22.5rem;

  .FilesWrapper {
    display: flex;
    flex-direction: column;
    > a {
      display: inline-block;
      margin-bottom: 0.825rem;
    }
  }
`;

export const Divider = styled.hr`
  align-self: flex-start;
  border: none;
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  justify-self: start;
  margin: 1rem 0;
  width: 5rem;
`;

export const SurveyStatus = styled(BaseSurveyStatus)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const Title = styled.h2`
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  line-height: calc(18 / 16);
  letter-spacing: 0.0625rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const Subtitle = styled.p`
  padding-left: 0.625rem;
  color: ${({ theme }) => theme.colors.grey600};
  font-family: 'Greycliff CF';
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
`;

export const Info = styled.p`
  padding-left: 0.625rem;
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Greycliff CF';
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
`;

export const InfoSpan = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: calc(17 / 14);
  padding-inline-start: 0.625rem;
`;

export const CollapsableContainer = styled.div`
  display: flex;
  justify-content: right;
  height: 40px;
  position: absolute;
  right: -17px;
  top: 0;
  z-index: 10;
  svg {
    margin: 0;
  }
  button {
    background-color: ${({ theme }) => theme.colors.grey100};
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey200};
    }
  }
`;
