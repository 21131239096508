import { FaBullseye } from 'react-icons/fa';

import { ReactComponent as CommunicationServices } from 'assets/svg/chart/CommunicationServices.svg';
import { ReactComponent as Community } from 'assets/svg/chart/Community.svg';
import { ReactComponent as ConsumerDiscretionary } from 'assets/svg/chart/ConsumerDiscretionary.svg';
import { ReactComponent as ConsumerStaples } from 'assets/svg/chart/ConsumerStaples.svg';
import { ReactComponent as Education } from 'assets/svg/chart/Education.svg';
import { ReactComponent as Energy } from 'assets/svg/chart/Energy.svg';
import { ReactComponent as Financials } from 'assets/svg/chart/Financials.svg';
import { ReactComponent as HealthCare } from 'assets/svg/chart/HealthCare.svg';
import { ReactComponent as Industrials } from 'assets/svg/chart/Industrials.svg';
import { ReactComponent as InformationTechnologies } from 'assets/svg/chart/InformationTechnologies.svg';
import { ReactComponent as Materials } from 'assets/svg/chart/Materials.svg';
import { ReactComponent as RealState } from 'assets/svg/chart/RealState.svg';
import { ReactComponent as Transportation } from 'assets/svg/chart/Transportation.svg';
import { ReactComponent as Utilities } from 'assets/svg/chart/Utilities.svg';
import { ReactComponent as Water } from 'assets/svg/chart/Water.svg';
import theme from 'styles/v2/theme';

const DEFAULT_DIMENSIONS = { width: 20, height: 20 };

export const useIndustries = () => {
  return {
    Blend: {
      color: theme.palette.primary[700],
      label: {
        icon: FaBullseye,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Communication Services': {
      color: theme.palette.primary[600],
      label: {
        icon: CommunicationServices,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Consumer Discretionary': {
      color: theme.palette.primary[500],
      label: {
        icon: ConsumerDiscretionary,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Consumer Staples': {
      color: theme.palette.primary[300],
      label: {
        icon: ConsumerStaples,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Energy: {
      color: theme.palette.primary[200],
      label: {
        icon: Energy,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Financials: {
      color: theme.palette.primary[100],
      label: {
        icon: Financials,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Financial Industries': {
      color: theme.palette.primary[50],
      label: {
        icon: Financials,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Financial Services': {
      color: theme.palette.secondary[900],
      label: {
        icon: Financials,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Healthcare: {
      color: theme.palette.primary.main,
      label: {
        icon: HealthCare,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Industrials: {
      color: theme.palette.secondary[800],
      label: {
        icon: Industrials,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Information Technology': {
      color: theme.palette.secondary[700],
      label: {
        icon: InformationTechnologies,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Materials: {
      color: theme.palette.secondary[600],
      label: {
        icon: Materials,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Real Estate': {
      color: theme.palette.primary[800],
      label: {
        icon: RealState,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Water: {
      color: theme.palette.secondary[500],
      label: {
        icon: Water,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Education: {
      color: theme.palette.secondary[400],
      label: {
        icon: Education,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Transportation: {
      color: theme.palette.secondary[300],
      label: {
        icon: Transportation,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Technology: {
      color: theme.palette.primary[900],
      label: {
        icon: InformationTechnologies,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    'Telecommunication Services': {
      color: theme.palette.secondary[200],
      label: {
        icon: CommunicationServices,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Utilities: {
      color: theme.palette.secondary[100],
      label: {
        icon: Utilities,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
    Community: {
      color: theme.palette.secondary[50],
      label: {
        icon: Community,
        color: theme.palette.common.white,
        ...DEFAULT_DIMENSIONS,
      },
    },
  };
};

export type Industries = ReturnType<typeof useIndustries>;
