import { FC, memo } from 'react';

import { FileType } from 'components/File/type';

import { ModuleTitle, ModuleInfo, Divider } from '../styles';
import Story from './Story';
import { StoriesWrapper, StoriesContainer, StoriesItem } from './styles';

type StockStoriesProps = {
  files: FileType[];
};

const StockStories: FC<StockStoriesProps> = ({ files }) => {
  return (
    <StoriesWrapper>
      <StoriesItem>
        <ModuleTitle>Stock Stories</ModuleTitle>
        <Divider />
        <StoriesContainer>
          {files?.map((file) => {
            return (
              <Story
                key={file.id}
                sector="COMMUNITY"
                title={file.name || ''}
                description={file.description || ''}
                file={file.file}
              />
            );
          })}
        </StoriesContainer>
      </StoriesItem>
      <ModuleInfo>
        Highlighted Investments are for illustrative purposes only and are
        representative holdings based on what strategies and portfolios you may
        hold. In preparing this proposal, we have obtained information from
        sources we believe to be reliable but do not offer any guarantees as to
        its accuracy or completeness.
      </ModuleInfo>
    </StoriesWrapper>
  );
};

export default memo(StockStories);
