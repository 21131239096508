import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import useFileActions from 'graphql/hooks/useFileActions';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import { from, useMediaQuery } from 'styles/media';

import { User, Props } from './types';
import useFileInput from './useFileInput';

const useConnect = ({
  idList,
  all,
  recipientRole,
}: {
  idList: { userId: string; investorId?: string }[];
  all: { advisors: boolean; investors: boolean };
  recipientRole: Props['recipientRole'];
}) => {
  const { me } = useMe();
  const role = me?.role;
  const { handleDrop, handleEmpty, handleRemove, handleRetry, fileList } =
    useFileInput();
  const {
    error: fileActionsError,
    loading: fileActionsLoading,
    share,
  } = useFileActions();
  const isMobile = !useMediaQuery(from.tablet);
  const { close: handleClose, isOpen } = useModal('SHARE');
  const [userList, setUserList] = useState<User[]>([]);
  const [allAdvisors, setAllAdvisors] = useState(all.advisors);
  const [allInvestors, setAllInvestors] = useState(all.investors);
  const [allPermission, setAllPermission] = useState('V');

  useEffect(() => {
    const updatedSelection = idList.map((ids) => ({
      userId: ids.userId,
      investorId: ids.investorId,
      permission:
        userList.find(({ userId }) => userId === ids.userId)?.permission || 'V',
    })) as User[];

    if (JSON.stringify(updatedSelection) !== JSON.stringify(userList)) {
      setUserList(updatedSelection);
    }
  }, [idList, userList]);

  useEffect(() => {
    setAllAdvisors(all.advisors);
  }, [all.advisors]);

  useEffect(() => {
    setAllInvestors(all.investors);
  }, [all.investors]);

  const handleUpdatePermission = useCallback(
    (updated: User) => {
      const index = userList.findIndex(
        ({ userId }) => userId === updated.userId,
      );

      const copyUserList = [...userList];
      copyUserList.splice(index, 1, updated);
      setUserList(copyUserList);
    },
    [userList],
  );

  const handleShare = useCallback(async () => {
    const files = fileList
      .filter(
        ({ error, loading, retrieveUrl }) =>
          !error && !loading && !!retrieveUrl,
      )
      .map(({ file: { name, type: fileType }, retrieveUrl: link }) => ({
        name,
        link: link as string,
        fileType,
      }));

    const success = await share({
      files,
      users: userList,
      allAdvisors,
      allInvestors,
      allPermission,
      recipientRole,
    });

    if (success) {
      handleEmpty();
      handleClose();
    }
  }, [
    allAdvisors,
    allInvestors,
    allPermission,
    fileList,
    handleClose,
    handleEmpty,
    share,
    userList,
    recipientRole,
  ]);

  const handleCheck = useCallback((value: string) => {
    if (value === 'allAdvisors') {
      setAllAdvisors((prev) => !prev);
    }
    if (value === 'allInvestors') {
      setAllInvestors((prev) => !prev);
    }
  }, []);

  const handleChangeAllPermission = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setAllPermission(value);
    },
    [],
  );

  return {
    all: {
      advisors: allAdvisors,
      investors: allInvestors,
    },
    disabled: {
      share:
        !fileList.filter(
          ({ error, loading, retrieveUrl }) =>
            !error && !loading && !!retrieveUrl,
        ).length ||
        (!userList.length && (!allAdvisors || !!allInvestors)) ||
        !!fileActionsLoading,
      fileList,
    },
    fileList,
    handleChangeAllPermission,
    handleCheck,
    handleClose,
    handleDrop,
    handleRemove,
    handleRetry,
    handleShare,
    handleUpdatePermission,
    isMobile,
    isOpen,
    error: fileActionsError,
    loading: fileActionsLoading,
    role,
  };
};

export default useConnect;
