import styled from 'styled-components';

import theme from 'styles/v2/theme';

export const Container = styled.div`
  background-color: ${theme.palette.primary[900]};
  min-height: 7.5rem;
  position: relative;
`;

export const ChildContent = styled.div`
  min-height: 7.5rem;
  position: relative;
`;

export const Picture = styled.picture`
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;

  @media print {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
