import HoldingsContainer from 'v2/components/HoldingsContainer';

import { Module } from '../styles';
import { PortfolioContainer } from './components/PortfolioContainer';
import { useConnect } from './connect';

export function Portfolio() {
  const { can } = useConnect();

  return (
    <>
      <PortfolioContainer can={can} />
      <Module id="holdings">
        <HoldingsContainer />
      </Module>
    </>
  );
}
