import { useLocation } from 'react-router-dom';

const useConnect = () => {
  const { pathname } = useLocation();

  return {
    pathname,
  };
};

export default useConnect;
