import Box from '@mui/material/Box';
import { memo } from 'react';

import { useStore as useInvestorStore } from 'context/fastContext/investor/context';

import useLogic from './logic';
import {
  Bar,
  Button,
  Icon,
  Image,
  Left,
  Overline,
  Right,
  Text,
  Title,
  TitleContainer,
  MainTitle,
} from './styles';
import { Props } from './types';

const PersonalityProfile = ({
  className,
  isProposal,
  isMobile = false,
}: Props) => {
  const { animation, handleAnimation, hide } = useLogic();

  const [
    {
      approachValue,
      perspectiveValue,
      mindsetValue,
      mindsetText,
      personalityBadge,
    },
  ] = useInvestorStore((s: any) => s.profile);

  if (!personalityBadge) return null;

  return (
    <>
      <MainTitle>MINDSET</MainTitle>
      <Box
        className={className}
        sx={{
          background: '#FFFFFF',
          borderRadius: '0.625rem',
          boxShadow: '0 0.3125rem 1.25rem -0.125rem rgba(31, 27, 36, 0.1)',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Left $isMobile={isMobile}>
          <TitleContainer>
            {isProposal ? (
              <Title data-testid="personality-badge-name1">
                {personalityBadge?.name}
              </Title>
            ) : (
              <>
                <Title data-testid="personality-badge-name2">
                  {personalityBadge?.name}
                </Title>
                <Button
                  $active={animation === 'TEXT'}
                  onClick={handleAnimation}
                  type="button"
                  data-testid="show-proposal"
                >
                  <Icon />
                </Button>
              </>
            )}
          </TitleContainer>
          <Text $animation={animation} $color="dark" $hide={hide}>
            {personalityBadge?.approachText} {personalityBadge?.perspectiveText}{' '}
            {mindsetText}{' '}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`${
                process.env.REACT_APP_S3_ENDPOINT || ''
              }static/survey/Learn More - Your Investor Profile.pdf`}
            >
              Learn more
            </a>
          </Text>
          <Image
            alt="Investor profile illustration"
            $animation={animation}
            $hide={hide}
            src={personalityBadge?.image}
          />
        </Left>
        <Right $isMobile={isMobile}>
          <Overline>Focus</Overline>
          <Text
            $animation={animation}
            $color="grey600"
            $hide={hide}
            $withMargin
          >
            How deeply you want to understand the details and participate in
            portfolio construction.
          </Text>
          <Bar
            isMobile={isMobile}
            $animation={animation}
            $hide={hide}
            leftText="Details"
            rightText="Big Picture"
            value={approachValue || 0}
          />
          <Overline>Perspective</Overline>
          <Text
            $animation={animation}
            $color="grey600"
            $hide={hide}
            $withMargin
          >
            How you want your dollars to have effects beyond you.
          </Text>
          <Bar
            isMobile={isMobile}
            $animation={animation}
            $hide={hide}
            leftText="Inward"
            rightText="Outward"
            value={perspectiveValue || 0}
          />
          <Overline>Approach</Overline>
          <Text $animation={animation} $color="grey600" $hide={hide}>
            How you balance wisdom of the past and innovation of the future.
          </Text>
          <Bar
            isMobile={isMobile}
            $animation={animation}
            $hide={hide}
            leftText="Legacy"
            rightText="Innovation"
            value={mindsetValue || 0}
          />
        </Right>
      </Box>
    </>
  );
};

export default memo(PersonalityProfile);
