export enum PercentageValues {
  largeCap = 'largeCap',
  midCap = 'midCap',
  smallCap = 'smallCap',
  international = 'international',
  coreFixed = 'coreFixed',
  muni = 'muni',
}

export type RiskValues = {
  riskLevel: number;
  incomeLevel: string;
};

export const PercentageFields = [
  PercentageValues.largeCap,
  PercentageValues.midCap,
  PercentageValues.smallCap,
  PercentageValues.international,
  PercentageValues.coreFixed,
  PercentageValues.muni,
];
