import { Form as DefaultForm } from 'formik';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import DefaultButton from 'components/Button';
import DefaultCard from 'components/Card';
import DefaultLogo from 'components/Logo';
import { from } from 'styles/media';

import DefaultIllustration from '../Login/SidePicture';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey0};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Form = styled(DefaultForm)`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;

  & button {
    margin-top: 0 !important;
  }
`;

export const ResendButton = styled(DefaultButton).attrs(() => ({
  variant: 'secondary',
}))`
  margin-left: 0.5rem;
  margin-top: 0.5rem;
`;

export const SubmitButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))``;

export const Button = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  width: 100%;
  justify-content: center;
  min-height: 2.5rem;
`;

export const Card = styled(DefaultCard).attrs(() => ({
  variant: 'borderless',
}))`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border-radius: 0;
`;

export const Illustration = styled(DefaultIllustration)`
  display: none;
  flex-shrink: 0;

  ${from.tablet} {
    display: flex;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 528px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;

  .resize {
    max-width: unset;
  }

  .inputGroup {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 769px) {
    padding: 4rem;
  }

  ${from.tablet} {
    margin-bottom: 6rem;
  }
`;

export const Logo = styled(DefaultLogo)`
  margin-bottom: 3rem;
`;

export const Link = styled(DefaultLink)`
  align-self: flex-start;
  color: inherit;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.rainForest};
  text-decoration: none;
  font-size: 0.875rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const RecoveryHeader = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'GreyCliff CF';
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 32);
  margin-bottom: 1.5rem;

  p {
    color: ${({ theme }) => theme.colors.grey600};
    margin-top: 10px;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.weights.regular};
  }

  b {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  ${from.largeScreen} {
    font-size: 2rem;
  }
`;

export const StrengthMeter = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const StrengthIcon = styled.span`
  min-width: 40px;
  width: 100%;
  height: 0.75rem;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const StrengthColors = [
  '#CCFAE5',
  '#91E3C2',
  '#36BF8A',
  '#1C7552',
  '#12402E',
  '#E5E8EC',
];

export const Text = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.dark};

  .return {
    color: ${({ theme }) => theme.colors.primary};
    margin: 1rem auto 0;
  }
`;

export const ValidationGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

export const ValidationWrapper = styled.ul`
  list-style: circle;
  list-style-position: inside;
`;

export const ValidationItem = styled.li`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.2rem;

  &.valid {
    color: ${({ theme }) => theme.colors.primary};
    list-style: disc;
    list-style-position: inside;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  flex: 5;
`;
