import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserParts on UserType {
    id
    firstName
    lastName
    email
    birthDate
    phone
    role
  }
`;
