import { Container, Category, Title, ValueCard } from './styles';
import { Props } from './types';

export function Categories({ categories }: Props) {
  return (
    <Container>
      {categories.map((category) => (
        <Category key={category.title}>
          <Title title={category.title} />
          {category.items.map((item) => (
            <ValueCard
              key={item.title}
              title={item.title}
              value={item.value}
              explanation={item.statement}
            />
          ))}
        </Category>
      ))}
    </Container>
  );
}
