// @ts-nocheck
import without from 'lodash/without';
import * as Yup from 'yup';

export default function allOrNone(this: Yup.ObjectSchema, list: string[]) {
  const { fields } = this;

  if (!fields) return this.shape({});

  const newSchema = list.reduce((acc, field) => {
    const fieldSchema = fields[field as keyof typeof fields] as
      | Yup.StringSchema
      | Yup.DateSchema;

    return {
      ...acc,
      [field]: fieldSchema.when(without(list, field), {
        is: (...values: any[]) => values.some((item) => item),
        then: fieldSchema.required(),
      }),
    };
  }, {});

  // dependencies needed to avoid cyclic dependencies
  const dependencies = list.reduce(
    (acc, item, idx, all) => [
      ...acc,
      ...all.slice(idx + 1).map((i) => [item, i] as [string, string]),
    ],
    [] as [string, string][],
  );

  return this.shape(newSchema, dependencies);
}
