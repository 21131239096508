import styled from 'styled-components';

import ContainerMaxWidth from 'components/ContainerMaxWidth';
import { from } from 'styles/media';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  min-height: 12.8125rem;
  padding-bottom: 2.0313rem;
  padding-top: 1.9031rem;
  width: 100%;

  @media print {
    display: none;
  }
`;

export const CompanyLogo = styled.img`
  max-height: 30px;
  filter: grayscale(100%);
`;

export const Wrapper = styled(ContainerMaxWidth)`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 1.25rem;

  ${from.tablet} {
    grid-template-columns: 0.5fr 1fr 0.5fr;
    justify-items: unset;
  }
`;

export const SocialMedia = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  justify-content: flex-end;
  display: flex;
  align-items: center;

  > * {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    height: 2.5rem;
  }

  ${from.tablet} {
    align-items: unset;
    > * {
      margin-right: 0;
      height: unset;
    }
  }
`;

export const Links = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  display: grid;
  font-size: 0.75rem;
  grid-template-columns: 1fr 1fr;
  letter-spacing: 0;
  margin-top: 0.7781rem;
  max-width: 37.5rem;
  text-align: center;

  ${from.tablet} {
    text-align: unset;
    margin-top: unset;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const Link = styled.a`
  color: inherit;
  height: 1.125rem;
  line-height: calc(18 / 12);
  text-decoration: none;

  &:hover {
    opacity: 0.7;
    transition: opacity 180ms ease-on-out;
  }
`;
