import { useMemo } from 'react';

import { ClientsAdvisorsSwitch } from 'components/ClientsAdvisorsSwitch';
import useMe from 'graphql/hooks/useMe';

import { Container } from './styles';
import { Props } from './types';

export function DataViewContainerTitleSwitch({ title }: Props) {
  const { me } = useMe();
  const isAdmin = useMemo(() => me?.role === 'ADMIN', [me]);
  return (
    <Container>
      {title}
      {isAdmin && <ClientsAdvisorsSwitch />}
    </Container>
  );
}
