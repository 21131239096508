export const light = {
  colors: {
    atlantis: '#9AC78A',
    bianca: '#FDFBF8',
    bridal: '#FFF8F0',
    bridesmaid: '#FEF0F0',
    cognac: '#9E441E',
    colonialWhite: '#FFEBBF',
    dark: '#1F1B24',
    eveningSea: '#025043',
    flamingo: '#F26B32',
    fringyFlower: '#AADCC2',
    frostee: '#DFF4E7',
    grey0: '#F8F9FA',
    grey100: '#F1F3F5',
    grey200: '#E9ECEF',
    grey300: '#DEE2E6',
    grey400: '#CED4DA',
    grey500: '#ADB5BD',
    grey600: '#868E96',
    grey700: '#495057',
    grey800: '#343A40',
    grey900: '#212529',
    negroni: '#FFE3C0',
    pottersClay: '#8C6231',
    primary: '#36BF8A',
    primary10: '#F7FCFA',
    primary50: '#E7F7F1',
    primary100: '#C3ECDC',
    primary200: '#9BDFC5',
    primary300: '#72D2AD',
    primary400: '#54C99C',
    primary500: '#36BF8A',
    primary600: '#30B982',
    primary700: '#29B177',
    primary800: '#22A96D',
    primary900: '#169B5A',
    primrose: '#CDE6C5',
    rainForest: '#007562',
    redwood: '#66280E',
    roseBud: '#F9B7B7',
    sandy: '#F28E63',
    sazerac: '#FFF4DF',
    secondary: '#12402E',
    tacao: '#F4B483',
    water: '#d4f1f9',
    white: '#FFFFFF',
    blue: '#1354cb',
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    heavy: 900,
  },
};
