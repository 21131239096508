import styled from 'styled-components';

import { from } from 'styles/media';

export const Container = styled.div`
  margin: 0 auto;
  max-width: calc(100% + 1.875rem);
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;

  ${from.tablet} {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  ${from.containerMaxWidth} {
    max-width: 73.125rem;
    padding-left: 0;
    padding-right: 0;
  }
`;
