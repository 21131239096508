import styled, { css } from 'styled-components';

import BaseModal, {
  Title as BaseTitle,
  Error as BaseError,
} from 'components/Modal';
import { prettyScrollbar } from 'styles/mixins';

export const Modal = styled(BaseModal)`
  .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 32.5rem;
        width: 32.5rem;
        height: auto;
        max-height: 48rem;
        min-height: 28.125rem;
        overflow-y: unset;

        > div {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      `};
  }

  // custom styles for native elements
  h1,
  h2 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 1.3125rem;
    font-weight: bold;
    line-height: calc(30 / 21);
    height: 1.875rem;
    margin-bottom: 0.5rem;
  }

  .wrapper {
    flex: 1;
    letter-spacing: 0.0225rem;
    line-height: calc(14 / 12);
  }

  strong {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: bold;
  }

  ul {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
    position: relative;
    list-style: disc;
    margin-left: 30px;
    li {
      color: ${({ theme }) => theme.colors.grey700};
      margin-bottom: 0.675rem;
    }
  }
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  text-transform: uppercase;
`;

export const Divider = styled.hr`
  border: none;
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  margin-bottom: 0.975rem;
`;

export const Error = styled(BaseError)``;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  letter-spacing: 0.0225rem;
  line-height: calc(14 / 12);
  &::after,
  &::before {
    content: '';
    position: absolute;
    height: 0.2rem;
    width: 100%;
  }

  &::after {
    top: 0;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.white},
      rgba(255, 255, 255, 0)
    );
  }

  &::before {
    bottom: 0;
    background-image: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.white},
      rgba(255, 255, 255, 0)
    );
  }
  ${prettyScrollbar}
`;

export const List = styled.div`
  max-height: 16.5625rem;
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
  position: relative;
  > * {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.grey700};
  margin-bottom: 0.675rem;
`;

export const TextBlock = styled.span`
  color: ${({ theme }) => theme.colors.grey700};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;

export const TextAccent = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: bold;
`;
