import { FC, useCallback } from 'react';

import { useTable } from '../TableContext';
import { Container } from './styles';
import { Props } from './types';

const Select: FC<Props> = ({ rowId, checked, ...props }) => {
  const { selectAll, selectRow } = useTable();
  const handleChange = useCallback(() => {
    if (!selectAll || !selectRow) return;
    if (!rowId) {
      selectAll();
    } else {
      selectRow({ rowId });
    }
  }, [selectRow, selectAll, rowId]);
  return <Container onChange={handleChange} checked={checked} {...props} />;
};

export default Select;
