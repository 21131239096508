import * as Yup from 'yup';

export const initialValues = (defaultEmail?: string, defaultName?: string) => ({
  email: defaultEmail || '',
  name: defaultName || '',
  useDefaultEmail: !!defaultEmail,
});

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  name: Yup.string().required('Name is required'),
  useDefaultEmail: Yup.boolean(),
});
