import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { USER_FRAGMENT } from 'graphql/fragments/user';
import {
  SendOnboardingEmailMutation,
  SendOnboardingEmailMutationVariables,
  UpdateMeMutation,
  UpdateMeMutationVariables,
  UserPartsFragment,
  UserProfileInput,
} from 'graphql/generated';
import { SEND_ONBOARDING_EMAIL, UPDATE_ME } from 'graphql/mutations/me';
import { logger } from 'services/logger';
import { cacheService } from 'utils/cacheService';

const useMeActions = () => {
  const { cache } = useApolloClient();
  const [sendOnboardingEmailMutation, { loading: sendOnboardingEmailLoading }] =
    useMutation<
      SendOnboardingEmailMutation,
      SendOnboardingEmailMutationVariables
    >(SEND_ONBOARDING_EMAIL);
  const [updateMutation, { loading: updateLoading }] = useMutation<
    UpdateMeMutation,
    UpdateMeMutationVariables
  >(UPDATE_ME);

  const sendOnboardingEmail = useCallback(
    async (variables: SendOnboardingEmailMutationVariables) => {
      await sendOnboardingEmailMutation({
        optimisticResponse: {
          sendOnboardingEmail: {
            __typename: 'SendOnboardingEmail',
            ok: true,
          },
        },
        variables,
      });
    },
    [sendOnboardingEmailMutation],
  );

  const update = useCallback(
    async (input: UserProfileInput) => {
      try {
        const response = await updateMutation({ variables: { input } });
        const data = response.data?.editProfile?.user;

        if (data) {
          const user = cache.readFragment<UserPartsFragment>({
            fragment: USER_FRAGMENT,
            fragmentName: 'UserParts',
            id: `UserType:${data.id}`,
          });

          if (user) {
            cache.writeFragment<UserPartsFragment>({
              data,
              fragment: USER_FRAGMENT,
              fragmentName: 'UserParts',
              id: `UserType:${data.id}`,
            });
          }

          // Clearing local cache to ensure updates are retrieved
          cacheService.clear();
        }

        return {
          errors: response.data?.editProfile?.errors,
          success: true,
        };
      } catch (error) {
        logger.error(error);
        return {
          errors: undefined,
          success: false,
        };
      }
    },
    [cache, updateMutation],
  );

  return {
    loading: sendOnboardingEmailLoading || updateLoading,
    sendOnboardingEmail,
    update,
  };
};

export default useMeActions;
