import SearchBox from 'components/SearchBox';
import Table from 'components/Table';
import ShareModal from 'containers/Modals/ShareModal';
import { Advisor } from 'models/Advisor';

import AddClient from './AddClient';
import useConnect from './connect';
import {
  Actions,
  Card,
  CardWrapper,
  Container,
  Header,
  Pagination,
  Results,
  ResultsWrapper,
  ShareButton,
  TableFooter,
  Title,
} from './styles';
import { Props } from './types';

function DataViewContainer<T extends { id: string }>({
  cardWidth,
  data = [],
  empty,
  initialSearch,
  itemsShown,
  onSearch,
  pagination,
  recipientRole,
  schema,
  title,
  ...props
}: Props<T>) {
  const {
    canAddHousehold,
    handleOpenModal,
    handleRemove,
    selection,
    tableData,
  } = useConnect(data, recipientRole);

  return (
    <>
      <Container>
        <Header>
          <Title>{title}</Title>
          <Actions>
            {canAddHousehold ? <AddClient /> : null}
            {selection.selected.length ? (
              <ShareButton onClick={handleOpenModal}>Share</ShareButton>
            ) : null}
            {onSearch ? (
              <SearchBox onWrite={onSearch} initialSearch={initialSearch} />
            ) : null}
          </Actions>
        </Header>
        {cardWidth ? (
          <CardWrapper>
            <Card width={cardWidth}>
              <Table
                data={tableData}
                empty={empty}
                schema={schema}
                {...selection}
                {...props}
              />
              <TableFooter>
                {itemsShown ? (
                  <ResultsWrapper>
                    {itemsShown.initial} - {itemsShown.final} OF{' '}
                    {itemsShown.total} <Results>RESULTS</Results>
                  </ResultsWrapper>
                ) : null}
                {pagination && pagination.pages > 1 ? (
                  <Pagination
                    hasPrev={pagination.hasPrev || false}
                    hasNext={pagination.hasNext || false}
                    page={pagination.page || 1}
                    pages={pagination.pages || 1}
                    onChangePage={pagination.onChangePage}
                  />
                ) : null}
              </TableFooter>
            </Card>
          </CardWrapper>
        ) : (
          <>
            <Table
              data={tableData}
              empty={empty}
              schema={schema}
              {...selection}
              {...props}
            />
            <TableFooter>
              {itemsShown ? (
                <ResultsWrapper>
                  {itemsShown.initial} - {itemsShown.final} OF{' '}
                  {itemsShown.total} <Results>RESULTS</Results>
                </ResultsWrapper>
              ) : null}
              {pagination && pagination.pages > 1 ? (
                <Pagination
                  hasPrev={pagination.hasPrev || false}
                  hasNext={pagination.hasNext || false}
                  page={pagination.page || 1}
                  pages={pagination.pages || 1}
                  onChangePage={pagination.onChangePage}
                />
              ) : null}
            </TableFooter>
          </>
        )}
      </Container>
      {/* FIXME: the typings are very clunky here, we should consider another way of implementing this */}
      {recipientRole ? (
        <ShareModal
          advisors={
            /* @ts-ignore */
            recipientRole === 'ADVISOR' ? (selection.entities as Advisor[]) : []
          }
          investors={
            recipientRole === 'INVESTOR'
              ? /* @ts-ignore */
                (selection.entities as MinInvestor[])
              : []
          }
          allAdvisors={recipientRole === 'ADVISOR' && selection.areAllSelected}
          allInvestors={
            recipientRole === 'INVESTOR' && selection.areAllSelected
          }
          recipientRole={recipientRole}
          onRemoveUser={handleRemove}
          isLegacy
        />
      ) : null}
    </>
  );
}

export default DataViewContainer;
