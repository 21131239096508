import styled from 'styled-components';

import { Logo as BaseLogo } from 'components/Navbar/styles';
import { from } from 'styles/media';

export const Container = styled.div`
  align-items: center;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 20vh;

  @media print {
    display: none;
  }
`;

export const Logo = styled(BaseLogo)`
  position: unset;
  margin-block-end: 2rem;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-top: 1.25rem;
  padding-right: 1.875rem;
  padding-left: 1.875rem;

  ${from.tablet} {
    font-size: 2.25rem;
  }
`;
