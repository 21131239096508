import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './user';

export const FILE_FRAGMENT = gql`
  fragment FileParts on FileType {
    id
    name
    fileType
    file
    owner {
      ...UserParts
    }
    uploaded
    shares {
      id
      toUser {
        ...UserParts
      }
    }
    totalUsers
    totalAdvisors
    totalInvestors
  }
  ${USER_FRAGMENT}
`;

export const SHARED_FILE_FRAGMENT = gql`
  fragment SharedFileParts on FileSharingType {
    id
    file {
      ...FileParts
    }
    fromUser {
      ...UserParts
    }
    toUser {
      ...UserParts
    }
    permission
    shared
  }
  ${FILE_FRAGMENT}
  ${USER_FRAGMENT}
`;
