import { AdvisorQuery, AdvisorsQuery } from 'graphql/generated';

import { normalizeAdvisorUser } from './User';

type GroupInput = NonNullable<
  | NonNullable<AdvisorQuery['advisor']>['group']
  | NonNullable<NonNullable<AdvisorsQuery['advisors']>['objects']>[0]['group']
>;

type GroupLeadInput = NonNullable<GroupInput['lead']>;

export const normalizeGroupLead = (input: GroupLeadInput) => {
  const { id: userId, ...user } = normalizeAdvisorUser(input.user);

  return {
    company: input.company || '',
    id: input.id,
    userId,
    ...user,
  };
};

export const normalizeGroup = (input: GroupInput) => ({
  id: input.id,
  lead: input.lead ? normalizeGroupLead(input.lead) : undefined,
  name: input.name,
});

export type Group = ReturnType<typeof normalizeGroup>;
