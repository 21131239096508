import { FC, memo } from 'react';

import TextWrap from 'components/TextWrap';
import initialsOf from 'utils/initialsOf';

import {
  Container,
  Image,
  ImageWrapper,
  Initials,
  TextWrapper,
  Name,
  Household,
} from './styles';
import { Props } from './types';

const ProfileAlt: FC<Props> = ({ householdName, imageURL, name, ...props }) => {
  return (
    <Container {...props}>
      <ImageWrapper>
        {imageURL && <Image src={imageURL} />}
        <Initials>{initialsOf(name)}</Initials>
      </ImageWrapper>
      <TextWrapper>
        <TextWrap>
          <Name>{name}</Name>
        </TextWrap>
        <TextWrap>
          <Household>{householdName}</Household>
        </TextWrap>
      </TextWrapper>
    </Container>
  );
};

export default memo(ProfileAlt);
