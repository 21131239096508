import { FC, memo } from 'react';

import { isNullOrUndefined } from 'utils/defaultSort';

import { Container, Label, Wrapper } from './styles';
import { Props } from './types';

const RiskValue: FC<Props> = ({ value }) => {
  return (
    <Container>
      {!isNullOrUndefined(value) ? <Wrapper>{value}</Wrapper> : 'Not defined'}
    </Container>
  );
};

const RiskLevel: FC<Props> = ({ riskLevel }) => {
  const getValue = (val: number | undefined) => {
    switch (val) {
      case 1:
      case 2:
        return 'Modest';
      case 3:
      case 4:
      case 5:
        return 'Moderate';
      case 6:
      case 7:
      case 8:
        return 'Significant';
      case 9:
      case 10:
        return 'Aggressive';
      default:
        return 'N/A';
    }
  };
  return <Label>{getValue(riskLevel)}</Label>;
};

export { RiskLevel };
export default memo(RiskValue);
