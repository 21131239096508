import { gql } from '@apollo/client';

import { ADVISOR_FRAGMENT } from 'graphql/fragments/advisor';
import { INVESTOR_FRAGMENT } from 'graphql/fragments/investor';
import { USER_FRAGMENT } from 'graphql/fragments/user';

export const ME = gql`
  query Me {
    me {
      showReleaseNotes
      releaseNotesContent
      ...UserParts
      advisor {
        ...AdvisorParts
      }
      investor {
        ...InvestorParts
      }
    }
  }
  ${ADVISOR_FRAGMENT}
  ${INVESTOR_FRAGMENT}
  ${USER_FRAGMENT}
`;
