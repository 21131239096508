import { FC, memo } from 'react';

import HoldingsSlider from 'v2/components/HoldingsContainer/Slider';

import { calculateDescription } from './logic';
import { Container, Description, Title } from './styles';
import { Props } from './types';

const Level: FC<Props> = ({ completePercentage = 0, title, ...props }) => {
  const description = calculateDescription(completePercentage);
  return (
    <Container {...props}>
      <Title>{title}</Title>
      <HoldingsSlider type="value" score={completePercentage} displayHover />
      <Description>{description}</Description>
    </Container>
  );
};

export default memo(Level);
