import { useState, useCallback } from 'react';

/**
 * This hook is ideal to show for a few seconds
 * an indicator that something happened.
 */
const useTemporaryIndicator = (msToStay = 1500) => {
  const [happened, setItHappened] = useState(false);
  const activateIndicator = useCallback(() => {
    setItHappened(true);
    setTimeout(() => setItHappened(false), msToStay);
  }, [msToStay]);
  return [happened, activateIndicator] as const;
};

export default useTemporaryIndicator;
