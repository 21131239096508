import { FC, memo } from 'react';

import { Container } from './styles';
import { Props } from './types';

const Circle: FC<Props> = ({ color, className, ...props }) => {
  return <Container color={color} className={className} {...props} />;
};

export default memo(Circle);
