/* eslint-disable no-nested-ternary */
function hasThreeDecimals(number: string) {
  // Convert the number to a string
  const numberAsString = number.toString();

  // Split the string at the decimal point
  const parts = numberAsString.split('.');

  // Check if there is a fraction part and if it has exactly three digits
  return parts[1] && parts[1].length === 3;
}

function checkEndsWithDotZero(str: string) {
  const pattern = /\.[1-9]0$/;

  return pattern.test(str);
}

function formatTextInputCurrency(value: string) {
  // removes the commas and $ to get an actual number
  const number = value.replace(/,|\$/g, '').trim();

  const hasThree = hasThreeDecimals(number);
  if (!Number.isNaN(number) && Number(number) >= 0 && !hasThree) {
    // Keeps track of these to append it back to the end of the string after formatting
    const endsWithDot = number.endsWith('.');
    const endsWithPoint0 = number.endsWith('.0');
    const endsWithDotNumber0 = checkEndsWithDotZero(number);

    let formattedNumber = Number(number).toLocaleString();

    if (formattedNumber === '0') return '';

    if (endsWithDot) formattedNumber += '.';
    if (endsWithPoint0) formattedNumber += '.0';
    if (endsWithDotNumber0) formattedNumber += '0';

    return `$ ${formattedNumber}`;
  }

  return null;
}

export default formatTextInputCurrency;
