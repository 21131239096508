import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';

import { ReactComponent as EditIcon } from 'assets/svg/pencil.svg';
import Accounts from 'components/Account';
import FinancialGoals from 'components/FinancialGoals';
import { PleaseCompleteAssessment } from 'components/PleaseCompleteAssessment';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { useStore as usePortfolioStore } from 'context/fastContext/portfolio/context';
import { useStore as useProposalStore } from 'context/fastContext/proposal/context';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useModal from 'graphql/hooks/useModal';
import EditAllocationsModal from 'v2/components/EditAllocationsModal';

import {
  Description,
  ItemDescription,
  ItemDescriptionWrapper,
  Module,
  ModuleItemContainer,
  ModuleItemsWrapper,
  ModuleItemsHeader,
  ModuleWrapperTitle,
  TitleContainer,
} from '../../../../../styles';
import { EditButton, Divider } from '../../styles';
import { Props } from './types';

const SinglePortfolioLayout = ({ chartData, dataShown }: Props) => {
  const [accounts] = useAccountStore((s: any) => s.accounts);
  const [{ currentPortfolio }] = usePortfolioStore((s) => ({
    currentPortfolio: s.currentPortfolio,
  }));
  const [{ proposalState, allProposals }] = useProposalStore((s: any) => ({
    allProposals: s.allProposals,
    proposalState: s.proposalState,
  }));
  const [status] = useInvestorStore((s: any) => s.profile?.status);

  const { investorInfo } = useInvestorInfo();

  const hasUploadedPortfolio =
    currentPortfolio?.holdings && currentPortfolio?.holdings.length > 0;

  const isAssessmentNotYetComplete = [
    'NOT_SENT',
    'NOT_SET',
    'SENT',
    'WAITING',
  ].includes(investorInfo?.assessmentStatus || '');

  const isAssessmentCompletedOrProposalIsPending =
    investorInfo?.assessmentStatus === 'COMPLETED' ||
    proposalState === 'PENDING';

  const accountsImplemented = proposalState === 'IMPLEMENTED';

  const dynamicPortfolioTitle = useMemo(() => {
    if (accountsImplemented) {
      return 'Your Portfolio';
    }
    if (hasUploadedPortfolio && isAssessmentCompletedOrProposalIsPending) {
      return 'Current Portfolio';
    }
    if (hasUploadedPortfolio && isAssessmentNotYetComplete) {
      return 'Current Portfolio';
    }

    return investorInfo?.seedsInvested ? 'Your Proposal' : 'Proposed Portfolio';
  }, [
    accountsImplemented,
    hasUploadedPortfolio,
    isAssessmentCompletedOrProposalIsPending,
    isAssessmentNotYetComplete,
    investorInfo?.seedsInvested,
  ]);

  const isCurrentAndIncomplete = useMemo(
    () =>
      dataShown === 'current' &&
      (!currentPortfolio || currentPortfolio?.holdings.length === 0),
    [currentPortfolio, dataShown],
  );

  const openEditAllocationsModal = useModal('EDIT_ALLOCATIONS');

  const disableEditAllocationsModal = useMemo(
    () => accounts.length > 0,
    [accounts.length],
  );

  if (!investorInfo) return null;

  const isAssessmentAvailable = ['NOT_SENT', 'NOT_SET', 'SENT'].includes(
    investorInfo?.investorStatus || 'NOT_SET',
  );

  const canDisplay =
    dataShown === 'current' ? hasUploadedPortfolio : allProposals.length > 0;

  return (
    <Module style={{ marginTop: 0 }}>
      <TitleContainer>
        {dataShown === 'current' && isCurrentAndIncomplete && (
          <EditButton
            variant="transparent"
            to={`/clients/${investorInfo?.id}/holdings`}
          >
            Edit
            <EditIcon />
          </EditButton>
        )}
      </TitleContainer>
      {canDisplay ? (
        <>
          <ModuleItemsWrapper>
            <ModuleItemsHeader>
              <ModuleWrapperTitle>{dynamicPortfolioTitle}</ModuleWrapperTitle>
              {dataShown === 'current' ? (
                <EditButton
                  variant="transparent"
                  to={`/clients/${investorInfo?.id}/holdings`}
                >
                  Edit
                  <EditIcon />
                </EditButton>
              ) : (
                <Tooltip
                  title={
                    disableEditAllocationsModal
                      ? 'Please make allocation edits within each account below. Those changes will be reflected here.'
                      : null
                  }
                >
                  <Box>
                    <EditButton
                      disabled={disableEditAllocationsModal}
                      variant="transparent"
                      onClick={() => openEditAllocationsModal.open()}
                      data-testid="edit-allocations-button"
                    >
                      Edit
                      <EditIcon />
                    </EditButton>
                  </Box>
                </Tooltip>
              )}
            </ModuleItemsHeader>
            <Divider />
            <ModuleItemContainer>
              <FinancialGoals chartData={chartData} dataShown={dataShown} />
            </ModuleItemContainer>
            {isAssessmentAvailable ? (
              <PleaseCompleteAssessment investorId={investorInfo.id} />
            ) : null}
          </ModuleItemsWrapper>
          <ItemDescriptionWrapper>
            <ItemDescription>
              Industry classification is provided via FactSet data.
              Occasionally, positions in the portfolio are not recognized and
              are excluded from the visual representation. Sector weights
              represent only the equity portion of the portfolio.
            </ItemDescription>
          </ItemDescriptionWrapper>
        </>
      ) : (
        <Description>
          {dataShown === 'seeds'
            ? 'The proposed portfolio is not defined'
            : 'This portfolio has not been completed yet'}
        </Description>
      )}
      <ItemDescription>
        Portfolios assessments based on a proprietary methodology using
        third-party &quot;EPI&quot; factor data. See disclosures for more
        detail.
        <br />
        <br />
        The Earth, People, and Integrity (&apos;EPI&apos;) ratings shown above
        may default to the headline values score, as individual EPI factor data
        is not available for certain funds and individual holdings.
      </ItemDescription>
      {status === 'COMPLETE' && <Accounts />}
      <EditAllocationsModal />
    </Module>
  );
};

export default SinglePortfolioLayout;
