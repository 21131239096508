import { nanoid } from 'nanoid';
import { FC, memo, useState, useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { from, useMediaQuery } from 'styles/media';
import takeFrom from 'utils/takeFrom';

import { reorder, locationsAreEqual } from './logic';
import Option from './Option';
import { Container, Column, TooltipGlobalStyle } from './styles';
import { Props } from './types';

const DraggableOptions: FC<Props> = ({
  options,
  itemsPerColumn = 5,
  onChange,
  disabled,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const numberOfColumns = Math.ceil(options.length / itemsPerColumn);
  const [columns, setColumns] = useState(() =>
    Array(numberOfColumns)
      .fill(0)
      .map((_, index) => ({
        id: nanoid(),
        items: takeFrom(options, index, itemsPerColumn),
      })),
  );

  const [itemOrder, setItemOrder] = useState(
    options.map((item, index) => index + 1),
  );

  useEffect(
    () => onChange(columns.flatMap((column) => column.items)),
    [columns, onChange],
  );

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination || locationsAreEqual(source, destination)) {
      return;
    }

    const reorderedColumns = reorder(
      columns,
      source,
      destination,
      itemsPerColumn,
    );

    setColumns(reorderedColumns);
    setItemOrder(itemOrder);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        {columns.map(({ id: columnId, items: columnItems }, columnIndex) => (
          <Droppable key={columnId} droppableId={columnId}>
            {(provided) => (
              <Column
                ref={provided.innerRef}
                $isMobile={isMobile}
                {...props}
                {...provided.droppableProps}
              >
                {columnItems.map(({ id, label, description, icon }, index) => (
                  <Option
                    key={id}
                    id={id}
                    label={label}
                    description={description}
                    index={index}
                    itemsPerColumn={itemsPerColumn}
                    column={columnIndex}
                    disabled={disabled}
                    icon={icon}
                    itemNumber={itemOrder[index]}
                  />
                ))}
                {provided.placeholder}
              </Column>
            )}
          </Droppable>
        ))}
      </Container>
      <TooltipGlobalStyle />
    </DragDropContext>
  );
};

export default memo(DraggableOptions);
