import { FormikHelpers } from 'formik';
import { GraphQLError } from 'graphql';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import useAuthActions from 'graphql/hooks/useAuthActions';

import { FormValues as ResetFormValues } from './ResetForm/types';
import { FormStatus } from './types';

const useConnect = () => {
  const { setResetPassword } = useAuthActions();
  const { token, uidb64 } = useParams<{ token: string; uidb64: string }>();
  const [status, setStatus] = useState<FormStatus>('RESET');

  const handleSubmitReset = useCallback(
    async (
      values: ResetFormValues,
      { setErrors }: FormikHelpers<ResetFormValues>,
    ) => {
      try {
        const success = await setResetPassword({
          newPassword1: values.password,
          newPassword2: values.confirmPassword,
          token,
          uidb64,
        });

        if (success) {
          setStatus('SUCCESS');
        } else {
          setStatus('ERROR');
        }
      } catch (e) {
        const error = e as GraphQLError;
        setErrors({ confirmPassword: error.message });
      }
    },
    [setResetPassword, setStatus, token, uidb64],
  );

  return { handleSubmitReset, status };
};

export default useConnect;
