import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PrettyLoading from 'components/PrettyLoading';
import TermsModal from 'containers/Modals/TermsModal';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';

import SidePicture from '../SidePicture';
import { Card } from '../styles';
import useConnect from './connect';
import { Container, Content } from './styles';

const InvestorSurvey = () => {
  const [isLoadingSurvey, setIsLoadingSurvey] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { errorMessage, isMobile, loading, userName } = useConnect();
  const { push } = useHistory();
  const { refetch } = useMe();
  const { close: closeModal, isOpen, open: openModal } = useModal('TERMS');

  // Prevent modal from opening on page load
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = useCallback(() => {
    if (isOpen) {
      closeModal();
    } else {
      openModal();
    }
  }, [closeModal, isOpen, openModal]);

  const handleBeginSurvey = useCallback(async () => {
    setIsLoadingSurvey(true);
    try {
      await refetch();
      setTimeout(() => push('/survey'), 0);
    } catch (e) {
      setHasError(true);
    }
    setIsLoadingSurvey(false);
  }, [push, refetch]);

  if (loading) return <PrettyLoading />;

  return (
    <>
      <Container>
        <Card>
          {!isMobile && <SidePicture />}
          <Content>
            {errorMessage ? (
              <div>{errorMessage}</div>
            ) : (
              <Box>
                <Typography mb={1} variant="h6">
                  Hi {userName}.
                </Typography>
                <Box mb={2} fontSize="14px">
                  <Typography fontSize={14} mb={2}>
                    Thanks for taking the time to complete this assessment.
                  </Typography>
                  <Typography fontSize={14}>
                    It is designed to uncover what&apos;s most important to you
                    when it comes to investing.
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleBeginSurvey}
                    sx={{ mb: 2, width: '100%' }}
                    disabled={isLoadingSurvey || hasError}
                  >
                    {isLoadingSurvey ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography sx={{ marginRight: '0.5rem' }}>
                          Loading Survey
                        </Typography>
                        <CircularProgress size="1rem" />
                      </Box>
                    ) : (
                      'Begin Assessment*'
                    )}
                  </Button>
                  {hasError && (
                    <Alert
                      icon={false}
                      sx={{ fontSize: '12px', marginBottom: 1 }}
                      severity="error"
                    >
                      An error occurred loading the survey, please refresh the
                      page and try again. If the error persists, please contact
                      your advisor.
                    </Alert>
                  )}
                  <Typography fontSize="12px">
                    *By clicking Begin Assessment you agree to our{' '}
                    <span
                      tabIndex={0}
                      onClick={handleToggleModal}
                      onKeyUp={handleToggleModal}
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      role="button"
                    >
                      Terms of Service.
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
          </Content>
        </Card>
      </Container>
      <TermsModal isInvestor />
    </>
  );
};

export default InvestorSurvey;
