import { AdvisorProvider } from './AdvisorProvider';
import { InsightsLibraryProvider } from './InsightsLibraryProvider';
import { InvestorInfoProvider } from './InvestorInfoProvider';
import { InvestorProvider } from './InvestorProvider';
import { UserProvider } from './UserProvider';

function CustomProviders({ children }: any) {
  return (
    <UserProvider>
      <AdvisorProvider>
        <InvestorInfoProvider>
          <InvestorProvider>
            <InsightsLibraryProvider>{children}</InsightsLibraryProvider>
          </InvestorProvider>
        </InvestorInfoProvider>
      </AdvisorProvider>
    </UserProvider>
  );
}

export default CustomProviders;
