import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import Button from 'components/Button';

import useConnect from './connect';
import { ActionButtons, Error, Modal, Text, Title } from './styles';
import { Props } from './types';

const RemoveHouseholdModal = ({ investorId }: Props) => {
  const { error, handleClose, handleRemove, isMobile, isOpen, loading } =
    useConnect({ investorId });

  return (
    <Modal fullScreen={isMobile} onClose={handleClose} open={isOpen}>
      <Title>Remove Household</Title>
      <Text>
        Are you sure you want to delete this household? This action cannot be
        reversed and all data will be lost forever.
      </Text>
      <ActionButtons>
        <Button
          icon={<TrashIcon />}
          onClick={handleRemove}
          disabled={loading}
          variant="danger"
          data-testid="investor-profile-button-remove-household-confirm"
        >
          Confirm
        </Button>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>
      </ActionButtons>
      {error ? <Error>An error has happened, please try again</Error> : null}
    </Modal>
  );
};

export default RemoveHouseholdModal;
