import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  CreateHouseholdInput,
  CreateHouseholdMutation,
  CreateHouseholdMutationVariables,
  HouseholdInput,
  RemoveHouseholdMutation,
  RemoveHouseholdMutationVariables,
  UpdateHouseholdMutation,
  UpdateHouseholdMutationVariables,
} from 'graphql/generated';
import {
  CREATE_HOUSEHOLD,
  REMOVE_HOUSEHOLD,
  UPDATE_HOUSEHOLD,
} from 'graphql/mutations/household';
import { logger } from 'services/logger';
import { cacheService } from 'utils/cacheService';

const useHouseholdActions = () => {
  const [createMutation, { error: createError, loading: createLoading }] =
    useMutation<CreateHouseholdMutation, CreateHouseholdMutationVariables>(
      CREATE_HOUSEHOLD,
    );
  const [removeMutation, { error: removeError, loading: removeLoading }] =
    useMutation<RemoveHouseholdMutation, RemoveHouseholdMutationVariables>(
      REMOVE_HOUSEHOLD,
    );
  const [updateMutation, { error: updateError, loading: updateLoading }] =
    useMutation<UpdateHouseholdMutation, UpdateHouseholdMutationVariables>(
      UPDATE_HOUSEHOLD,
    );

  const create = useCallback(
    async (input: CreateHouseholdInput) => {
      const response = await createMutation({
        fetchPolicy: 'no-cache',
        variables: { input },
      });
      const data = response.data?.createHousehold?.investor;
      // clear investors
      cacheService.invalidateByUrl(
        `${process.env.REACT_APP_API_ENDPOINT}api/user/clients/`,
      );
      return {
        investorId: data?.id,
        errors: response.data?.createHousehold?.errors,
      };
    },
    [createMutation],
  );

  const remove = useCallback(
    async (variables: { investorId: string }) => {
      try {
        await removeMutation({
          fetchPolicy: 'no-cache',
          variables,
        });

        cacheService.invalidateByUrl(
          `${process.env.REACT_APP_API_ENDPOINT}api/user/clients/`,
        );

        return true;
      } catch (error) {
        logger.error(error);
        return false;
      }
    },
    [removeMutation],
  );

  const update = useCallback(
    async (input: HouseholdInput, investorId: string) => {
      try {
        const response = await updateMutation({
          fetchPolicy: 'no-cache',
          variables: { input, investorId },
        });
        const data = response.data?.editHousehold?.investor;

        cacheService.invalidateByUrl(
          `${process.env.REACT_APP_API_ENDPOINT}api/user/clients/`,
        );

        return data?.id;
      } catch (error) {
        logger.error(error);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw updateError;
      }
    },
    [updateMutation, updateError],
  );

  return {
    create,
    error: !!createError || !!removeError || !!updateError,
    loading: createLoading || removeLoading || updateLoading,
    remove,
    update,
  };
};

export default useHouseholdActions;
