import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import Footer from 'components/Footer';
import Menu, { Button } from 'components/Menu';
import Navbar from 'components/Navbar';
import Profile from 'components/Profile';
import { from, useMediaQuery } from 'styles/media';

import useConnect from './connect';
import Notifications from './Notifications';
import { Container, Content } from './styles';
import { Props } from './types';

const AppLayout: FC<Props> = ({
  children,
  tabs,
  disableNavLinks = false,
  isInvestor = false,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const { me, menuOptions, investorInfo } = useConnect();
  const { pathname } = useLocation();
  const inSurveyView = !!pathname.match(/survey/);
  const inStaticPage =
    !!pathname.match(/disclosures/) || !!pathname.match(/terms-and-conditions/);
  /**
   * This is pretty much a hack. We should not do this. This is done to remove the maxwidht on a specific
   * container, because it needs a section that expands to the edges of the page.
   */
  const isClientDetailPage = !!pathname.match(
    /^\/clients\/[a-z0-9-]+\/?(profile|impact|portfolio)$/,
  );
  const withNavbar = !pathname.match(/survey\/\d/);
  /**
   * Need a better way to grab advisorInfo, company logo should just come from advisor, not investor
   */
  const companyLogo = useMemo(
    () =>
      me && 'advisor' in me && me?.advisor?.company
        ? me.advisor.companyLogo
        : investorInfo?.advisor?.company?.logo,
    [me, investorInfo?.advisor?.company?.logo],
  );

  const isAdvisorOrInvestor = useMemo(
    () => me && (me.role === 'ADVISOR' || me.role === 'INVESTOR'),
    [me],
  );

  return (
    <Container $darkerBackground={inSurveyView || inStaticPage} {...props}>
      {withNavbar && (
        <Navbar
          disableNavLinks={disableNavLinks}
          tabs={[]}
          companyLogo={isAdvisorOrInvestor ? companyLogo : null}
        >
          {me ? <Notifications /> : null}
          {me && !isMobile ? (
            <Profile
              groupName={
                'advisor' in me && me?.advisor?.group
                  ? me.advisor.group.name
                  : ''
              }
              imageURL=""
              name={me.fullName}
              profileRole={me.role}
            />
          ) : null}
          <Menu options={menuOptions}>
            <Button data-testid="toggle-menu">
              <MenuIcon />
            </Button>
          </Menu>
        </Navbar>
      )}
      {isClientDetailPage ? (
        children
      ) : (
        <Content $centerElements={isInvestor && withNavbar}>{children}</Content>
      )}
      <Footer companyLogo={isAdvisorOrInvestor ? companyLogo : null} />
    </Container>
  );
};

export default AppLayout;
