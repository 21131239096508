import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollAnchoring() {
  const { hash } = useLocation();
  useLayoutEffect(() => {
    if (!hash) return () => {};
    const getLinkedElement = () => document.getElementById(hash.substr(1));
    let element = getLinkedElement();
    if (element) {
      element.scrollIntoView();
      return () => {};
    }
    const onMutation: MutationCallback = (entries, observer) => {
      element = getLinkedElement();
      if (element) {
        element.scrollIntoView();
        observer.disconnect();
      }
    };
    const mutationObserver = new MutationObserver(onMutation);
    mutationObserver.observe(document.body, {
      subtree: true,
      childList: true,
    });
    return () => {
      mutationObserver.disconnect();
    };
  }, [hash]);
}
