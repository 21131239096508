import { FC, memo, useCallback } from 'react';

import { pattern, blocksSchema } from './constants';
import useLogic from './logic';
import { Container, Input, Label } from './styles';
import { Props } from './types';

const PhoneInput: FC<Props> = ({
  className,
  disabled,
  error,
  onBlur,
  onChange,
  onFocus,
  name,
  placeholder: placeholderProp,
  value,
  ...props
}) => {
  const { handle, inputRef, maskRef, showLabel, lazy } = useLogic({
    onBlur,
    onFocus,
    onChange,
    name,
    value,
    placeholder: placeholderProp,
  });

  const accessRef = useCallback(
    (el: HTMLInputElement) => {
      inputRef.current = el;
    },
    [inputRef],
  );

  return (
    <>
      <Container className={className} isError={error} onClick={handle.focus}>
        {showLabel && <Label>{placeholderProp}</Label>}
        <Input
          autofix
          overwrite
          mask={pattern}
          blocks={blocksSchema}
          lazy={lazy}
          inputRef={accessRef}
          ref={maskRef}
          name={name}
          value={value}
          onBlur={handle.onBlur}
          onAccept={handle.onChange}
          onFocus={handle.onFocus}
          disabled={disabled}
          {...props}
        />
      </Container>
    </>
  );
};

export default memo(PhoneInput);
