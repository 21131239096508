import styled from 'styled-components';

export const Container = styled.span`
  margin-block-end: 0.75rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  > svg {
    width: 1.1563rem;
    height: 1.1563rem;
    margin-inline-end: 0.6875rem;
  }
`;
