import { useState, useCallback, useEffect } from 'react';

import defaultSort from 'utils/defaultSort';

import { SortCallback, Schema, SortColumn, Sort } from './types';

/**
 * The `sortFactory` function resolves which values and
 * sorter should be used.
 *
 * If we have defined a property name in the schema defi-
 * nition we will sort by the property value of the object
 * that the currently sorted column holds.
 *
 * For example:
 * having this data:
 * `{ name: 'Peter', company: {id: 1, name: 'Gucci' }}`
 * and this schema
 * `{ company: { sortBy: 'name' }}`
 * will sort by the name of the company.
 *
 * But if we want to hook into this sorting process we can
 * by setting a custom sort function as sortBy.
 *
 * For example:
 * having this data:
 * `{ name: 'Peter', risk: 'SIGNIFICANT' }`
 * and this schema
 * `{ company: { sortBy: sortRisks }}`
 * the sortRisks function can normalize the value
 * to something sortable.
 *
 * Finally if we don't define anything the value of the
 * column wil be sorted by the defaultSort, which if it
 * does not understand the value will return 0. Meaning
 * values wont be sorted.
 */
export default function useSort<T>({
  data: givenData,
  schema,
}: {
  data: T[];
  schema: Schema<T>;
}) {
  const [data, setData] = useState(givenData);
  const [sortedBy, setSortedBy] = useState<Sort>();
  const getSortFunction = useCallback(
    ({ column, direction }: Parameters<SortCallback>[0]) => {
      const columnName = column as keyof Schema<T>['columns'];
      const columnDefinition = schema.columns[columnName];

      function sortFactory(): (a: any, b: any) => number {
        if ('sortBy' in columnDefinition) {
          const { sortBy } = columnDefinition as SortColumn<
            T,
            keyof Schema<T>['columns']
          >;

          if (typeof sortBy === 'string') {
            return function sortByColumnProp(a, b) {
              const columnA = a[columnName];
              const columnB = b[columnName];
              const prop = sortBy as keyof typeof columnA;
              const valueA = columnA && columnA[prop];
              const valueB = columnB && columnB[prop];
              return defaultSort(valueA, valueB, direction);
            };
          }

          return function sortByGivenSorter(a, b) {
            return sortBy(a[columnName], b[columnName], direction);
          };
        }

        return function sortByColumn(a, b) {
          const valueA = a[columnName];
          const valueB = b[columnName];
          return defaultSort(valueA, valueB, direction);
        };
      }

      return sortFactory;
    },
    [schema.columns],
  );

  const onSort: SortCallback = useCallback(
    ({ column, direction }) => {
      const sortFactory = getSortFunction({ column, direction });

      setSortedBy({ column, direction });

      setData((current) => {
        const copy = [...current];
        copy.sort(sortFactory());
        return copy;
      });
    },
    [getSortFunction],
  );

  useEffect(() => {
    if (sortedBy) {
      const sortedGivenData = [...givenData];
      const sortFactory = getSortFunction(sortedBy);
      sortedGivenData.sort(sortFactory());
      setData(sortedGivenData);
    } else {
      setData([...givenData]);
    }
  }, [givenData, sortedBy, getSortFunction]);

  return {
    data,
    onSort,
    sortedBy,
  };
}
