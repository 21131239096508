import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import useMe from 'graphql/hooks/useMe';
import useNotificationActions from 'graphql/hooks/useNotificationActions';
import useNotifications from 'graphql/hooks/useNotifications';
import useNotificationSubscriptions from 'graphql/hooks/useNotificationsSubscription';

const useConnect = () => {
  const { push } = useHistory();
  const { me } = useMe();
  const { notifications, refetch } = useNotifications();
  const { markAsRead } = useNotificationActions();

  const arePendingNotifications = useMemo(
    () => notifications.some((notification) => !notification.read),
    [notifications],
  );

  useNotificationSubscriptions({ refetch, userId: me?.id });

  const onSelect = useCallback(
    (customUrl: string | undefined) => {
      let callback = () => {};

      if (customUrl) {
        callback = () => {
          const customUrlInstance = new URL(customUrl);
          const isSameOrigin =
            customUrlInstance.origin === document.location.origin;
          if (isSameOrigin) {
            push(customUrlInstance.pathname);
          } else {
            document.location.href = customUrlInstance.href;
          }
        };
      }

      return callback;
    },
    [push],
  );

  return {
    arePendingNotifications,
    markAsRead,
    notifications,
    onSelect,
  };
};

export default useConnect;
