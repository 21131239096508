import styled from 'styled-components';

import DefaultLogo from 'components/Logo';

export const Container = styled.div`
  text-align: center;
  width: 100%;
`;

export const Logo = styled(DefaultLogo).attrs(() => ({
  color: 'grey',
  size: 'large',
}))`
  margin-top: 6.375rem;
  margin-bottom: 5.5rem;
`;

export const Content = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 2.5rem 0;
  width: 38.75rem;
  border-radius: 0.625rem;
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const GloomyPlant = styled.img`
  width: 8.3125rem;
  height: 12.25rem;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h1`
  font-size: 2rem;
  line-height: 2.75rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 1.5rem;
`;

export const Link = styled.a`
  display: block;
  width: 85px;
  margin-bottom: 1.5rem;
  padding: 0.4375rem 0.875rem 0.5625rem 0.875rem;
  line-height: 1.42;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const ContactMessage = styled.p`
  font-size: 14px;
  line-height: 1.42;
  color: ${({ theme }) => theme.colors.grey500};

  a {
    color: inherit;
  }
`;
