import { MouseEvent, useCallback, useState } from 'react';

const useLogic = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const isOpen = Boolean(anchorEl);

  return {
    anchorEl,
    isOpen,
    handle: {
      open: handlePopoverOpen,
      close: handlePopoverClose,
    },
  };
};

export default useLogic;
