import * as Yup from 'yup';

import { ValueType } from 'context/fastContext/portfolio/types';

import { Asset, AssetValue } from './types';

export const initialValues = (
  defaultAssets?: Asset[],
  defaultType?: ValueType,
) => ({
  assets: (defaultAssets ?? []) as Asset[] | undefined,
  valueType: defaultType,
});

export const validationSchema = Yup.object().shape({
  assets: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('Required'),
        shares: Yup.number().when('valueType', {
          is: 'SHARES',
          then: Yup.number()
            .moreThan(0, 'Must be greater than 0')
            .required('Required'),
        }),
        percentage: Yup.number().when('valueType', {
          is: 'PERCENTAGE',
          then: Yup.number()
            .moreThan(0, 'Must be greater than 0')
            .required('Required'),
        }),
        marketValue: Yup.number().when('valueType', {
          is: 'MARKET_VALUE',
          then: Yup.number()
            .moreThan(0, 'Must be greater than 0')
            .required('Required'),
        }),
      }),
    )
    .required('Minimum one asset required')
    .when('valueType', {
      is: 'PERCENTAGE',
      then: Yup.array<Asset>().test(
        'sum',
        'All values should sum up to 100%',
        (value) => {
          const sum = (value || [])
            .reduce((prev, current) => {
              const getterKey = AssetValue[current.valueType as ValueType];
              return prev + Number(current[getterKey] || 0);
            }, 0)
            .toFixed(2);
          return +sum === 100;
        },
      ),
      otherwise: Yup.array(),
    }),
  valueType: Yup.string(),
});
