import styled from 'styled-components';

import BaseModal, {
  Title as BaseTitle,
  Text as BaseText,
  Error as BaseError,
} from 'components/Modal';

export const Modal = styled(BaseModal)``;

export const Text = styled(BaseText)`
  font-size: 1rem;
  line-height: 1.5;
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 0.75rem;
`;

export const Error = styled(BaseError)``;

export const ActionButtons = styled.div`
  margin-top: 1.875rem;
  display: flex;
  > * {
    margin-right: 1.25rem;
    &:last-child {
      margin-right: auto;
    }
  }
`;
