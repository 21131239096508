import { FC } from 'react';

import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import FileIcon from 'components/FileIcon';

import { Container, Name, Remove } from './styles';
import { Props } from './types';

const FileRow: FC<Props> = ({ fileItem, onRemove }) => {
  return (
    <Container>
      {fileItem.type ? <FileIcon fileType={fileItem.type} /> : null}
      <Name>{fileItem.name}</Name>
      <Remove
        variant="transparent"
        onClick={onRemove}
        icon={<TrashIcon />}
        title="Remove file"
      />
    </Container>
  );
};

export default FileRow;
