import { Link } from 'react-router-dom';
import styled from 'styled-components';

export * from './formatters';
export * from './schemas';
export * from './types';
export * from './data';
export * from './components';

export const Bold = styled.div`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const BoldLink = styled(Link)`
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
`;

export const SmallTableContainer = styled.div`
  table {
    min-width: 24.75rem;
  }
`;
