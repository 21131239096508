import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef } from 'react';

import { useEnqueueSnackbar } from 'hooks/useEnqueueSnackbar';
import useSearchParams from 'utils/useSearchParams';

export enum Warnings {
  INACTIVITY = 'inactivity',
}

export function useWarnings() {
  const enqueue = useEnqueueSnackbar();
  const { closeSnackbar } = useSnackbar();
  const search = useSearchParams();
  const loggedOutBy = search.get('loggedOutBy');
  const warningsRef = useRef({
    [Warnings.INACTIVITY]: false,
  });

  useEffect(() => {
    if (
      loggedOutBy === Warnings.INACTIVITY &&
      !warningsRef.current[Warnings.INACTIVITY]
    ) {
      enqueue('Session ended due to inactivity.', {
        key: Warnings.INACTIVITY,
        preventDuplicate: true,
        persist: true,
        variant: 'warning',
      });
      warningsRef.current[Warnings.INACTIVITY] = true;
    }
  }, [loggedOutBy, enqueue]);

  const closeWarning = useCallback(
    (warning: Warnings) => {
      closeSnackbar(warning);
      warningsRef.current[warning] = false;
    },
    [closeSnackbar],
  );

  return {
    closeWarning,
  };
}
