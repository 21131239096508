import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';

import { InfoIcon, ToolTipStyled } from './styles';

type CustomTooltipType = {
  title: any;
};

const CustomTooltip = ({ title }: CustomTooltipType) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <ToolTipStyled
        className={open ? 'openToolTip' : ''}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        onClick={handleTooltipToggle}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <InfoIcon />
      </ToolTipStyled>
    </ClickAwayListener>
  );
};

export default CustomTooltip;
