import styled from 'styled-components';

import { from } from 'styles/media';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  display: flex;
`;

export const Image = styled.img`
  display: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.625rem;
  opacity: 0.5;
  ${from.tablet} {
    display: block;
  }
`;

export const Initials = styled.div`
  align-items: center;
  background-color: ${({ theme }) => addAlpha(theme.colors.rainForest, 0.75)};
  border-radius: 2.5rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  height: 2.5rem;
  justify-content: center;
  text-transform: uppercase;
  width: 2.5rem;
  overflow: hidden;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.0625rem;
  letter-spacing: 0;
  line-height: calc(17 / 14);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Information = styled.span`
  color: ${({ theme }) => theme.colors.grey600};
  display: block;
  display: block;
  font-size: 0.875rem;
  height: 1.25rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  max-width: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  margin-left: 0.75rem;
  max-width: 12.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
