import { FC } from 'react';

import { ReactComponent as RemoveIcon } from 'assets/svg/trash.svg';

import { Container, Email, Name, Remove } from './styles';
import { Props } from './types';

const InvestorRow: FC<Props> = ({
  disabled,
  email,
  id,
  name,
  onClickRemove,
}) => {
  return (
    <Container $disabled={disabled}>
      <Name title={name}>{name}</Name>
      <Email title={email}>{email}</Email>
      {onClickRemove ? (
        <Remove
          disabled={disabled}
          icon={<RemoveIcon />}
          onClick={() => onClickRemove(id)}
          variant="transparent"
        />
      ) : null}
    </Container>
  );
};

export default InvestorRow;
