import styled, { css } from 'styled-components';

import ContainerMaxWidth from 'components/ContainerMaxWidth';

export const Container = styled.div<{ $darkerBackground: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${({ $darkerBackground, theme }) =>
    $darkerBackground &&
    css`
      background-color: ${theme.colors.grey0};
    `};
`;

export const Content = styled(ContainerMaxWidth)<{
  $centerElements: boolean;
}>`
  flex: 1;
  ${({ $centerElements }) =>
    $centerElements &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
    `};
`;
