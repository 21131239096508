import { createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    '50': string;
    '100': string;
    '200': string;
    '300': string;
    '400': string;
    '500': string;
    '600': string;
    '700': string;
    '800': string;
    '900': string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
    fontWeightExtraBold: React.CSSProperties['fontWeight'];
    fontWeightHeavy: React.CSSProperties['fontWeight'];
  }
  interface TypographyVariantsOptions {
    fontWeightSemiBold?: React.CSSProperties['fontWeight'];
    fontWeightExtraBold?: React.CSSProperties['fontWeight'];
    fontWeightHeavy?: React.CSSProperties['fontWeight'];
  }
}

const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#36BF8A',
      contrastText: '#ffffff',
      50: '#e4f6ee',
      100: '#bee8d5',
      200: '#94d9ba',
      300: '#63cb9f',
      400: '#36bf8a', // Main
      500: '#00b376',
      600: '#00a46b',
      700: '#00925d',
      800: '#008051',
      900: '#00613a',
    },
    secondary: {
      main: '#6549c0',
      contrastText: '#1F1B24',
      50: '#ede9f7',
      100: '#d1c8ec',
      200: '#b3a4e0',
      300: '#957fd4',
      400: '#7d63ca',
      500: '#6549c0', // Main
      600: '#5b44ba',
      700: '#4e3cb0',
      800: '#4236a8',
      900: '#2d2a99',
    },
    grey: {
      50: '#F8F9FA',
      100: '#E9ECEF',
      200: '#DEE2E6',
      300: '#CED4DA',
      400: '#ADB5BD',
      500: '#868E96',
      600: '#495057',
      700: '#343A40',
      800: '#212529',
      900: '#1F1B24', // Seeds Black
    },
    info: {
      main: '#96582b',
      contrastText: '#1F1B24',
      50: '#fff8f0',
      100: '#ffeddb',
      200: '#e0a784',
      300: '#c38861',
      400: '#ac6f46',
      500: '#96582b', // Main
      600: '#8a4e25',
      700: '#7a411c',
      800: '#6c3316',
      900: '#5c240e',
    },
    error: {
      main: '#d32f2f',
      50: '#f2c1c1',
    },
    success: {
      main: '#36BF8A', // Seeds Green
    },
    warning: {
      main: '#ed6c02',
    },
  },
  typography: {
    fontFamily:
      "'Greycliff CF',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif !important",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightHeavy: 900,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          '.MuiDataGrid-row .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word',
            overflow: 'visible',
            fontSize: '13px',
          },
          '& .MuiDataGrid-columnHeader *': {
            fontWeight: 'bold',
            color: '#868E96',
            fontSize: '12px',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 0,
          },
          '& .MuiDataGrid-columnHeader': {
            borderBottom: 0,
          },
          '& .MuiDataGrid-columnHeader--sorted *': {
            color: '#000000',
          },
          '& .MuiDataGrid-footerContainer': { border: 0 },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#F7FCFA',
          },
        },
      },
    },
  },
});

export default theme;
