import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import { useChartData } from 'hooks/useChartData';

import CurrentAndSeedsLayout from './components/CurrentAndSeedsLayout';
import SinglePortfolioLayout from './components/SinglePortfolioLayout';
import { Props } from './types';

export function PortfolioContainer({ can }: Props) {
  const chartData = useChartData();
  const { investorInfo } = useInvestorInfo();
  if (!investorInfo) return null;

  // TODO: Rewrite this single and current/seeds should be same component...
  if (can('show-single-portfolio-current')) {
    return <SinglePortfolioLayout chartData={chartData} dataShown="current" />;
  }

  if (can('show-single-portfolio-seeds')) {
    return <SinglePortfolioLayout chartData={chartData} dataShown="seeds" />;
  }

  if (can('show-current-and-seeds-portfolio')) {
    return <CurrentAndSeedsLayout chartData={chartData} />;
  }

  return null;
}
