import styled, { css } from 'styled-components';

import { ReactComponent as InfoCircle } from 'assets/svg/infoCircle.svg';
import IconButton from 'components/IconButton';
import {
  Tabs as DefaultTabs,
  Tab as DefaultTab,
} from 'components/Navbar/styles';
import DefaultSharedFileCard from 'components/SharedFileCard';
import DefaultSidebar from 'components/Sidebar';
import DefaultSteppedProgressBar from 'components/SteppedProgressBar';
import { from } from 'styles/media';

import { ModuleProps } from './types';

export const Container = styled.div`
  margin: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 70.125rem 1fr;

  > * {
    grid-column: 2;
  }
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  position: relative;
  z-index: 0;
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: 2.5rem;
`;

export const GoBack = styled(IconButton)`
  margin-right: 1rem;
`;

export const ShareButton = styled(IconButton)`
  margin-right: 1rem;
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
`;

export const EditButton = styled(IconButton)`
  margin-right: 1rem;
`;

export const RemoveButton = styled(IconButton).attrs(() => ({
  variant: 'alert',
}))`
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
`;

export const Title = styled.header`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Greycliff CF';
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 24);
  text-transform: capitalize;
  flex: 1;
`;

export const ModuleLegacy = styled.div<ModuleProps>`
  background-color: ${({ $backgroundColor = 'white', theme }) =>
    theme.colors[$backgroundColor]};
  padding: 2.5rem 0;
  width: 100%;
  &:first-child {
    padding-block-end: 0;
  }
  ${from.tablet} {
    padding: 2.5rem 0 2.5rem 1.875rem;
  }
`;

export const SteppedProgressBarLegacy = styled(DefaultSteppedProgressBar)`
  margin-bottom: 4.5rem;
`;

export const Module = styled.div`
  margin: 2.5rem 0;

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto !important;
    margin: 20mm 20mm 0 !important;
  }
`;

export const Separator = styled.hr`
  width: 100%;
  height: 0.0625rem;
  border: 0;
  background-color: ${({ theme }) => theme.colors.grey200};
  margin-bottom: 1.375rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 24);

  @media print {
    font-weight: ${({ theme }) => theme.weights.bold};
    font-size: 1.3125rem;
    line-height: calc(30 / 21);
  }
`;

export const ModuleItemsHeader = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModuleItemsTitle = styled.h2`
  font-size: 1rem;
  line-height: calc(22 / 16);
  color: ${({ theme }) => theme.colors.dark};
  text-transform: capitalize;
`;

export const SectionModuleTitle = styled.h3<{ $isGrey?: boolean }>`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  color: ${({ theme, $isGrey = false }) =>
    theme.colors[$isGrey ? 'grey600' : 'dark']};
`;

export const ModuleWrapperTitle = styled.h3<{ $isGrey?: boolean }>`
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  line-height: calc(22 / 16);
  color: ${({ theme, $isGrey = false }) =>
    theme.colors[$isGrey ? 'grey600' : 'dark']};
`;

export const ModuleItemsWrapper = styled.div<{ $surveyCompleted?: boolean }>`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    flex-direction: row;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;

    ${(props) =>
      props.$surveyCompleted === false &&
      css`
        grid-template-columns: 1fr;
      `};
  }
`;

export const ModuleItemContainer = styled.div`
  ${from.tablet} {
    & + & {
      margin-left: 1.875rem;
    }
  }
`;

export const SteppedProgressBar = styled(DefaultSteppedProgressBar)`
  margin-bottom: 1.5rem;
`;

export const FilesWrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
`;

export const SharedFileCard = styled(DefaultSharedFileCard)`
  & + & {
    margin-left: 1rem;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;

  & > * {
    margin-top: 0;
  }
`;

export const InfoIcon = styled(InfoCircle)`
  margin-right: 0.5rem;
  margin-top: 0.375rem;
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  line-height: 1.5;
  margin-top: 0.75rem;
  margin: 0;
`;

export const ItemDescriptionWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const TabsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
  grid-column: 1 / -1;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Tabs = styled(DefaultTabs)`
  height: auto;
  justify-content: flex-start;
  width: 48.25rem;
  margin-left: initial;
`;

export const Tab = styled(DefaultTab)``;

export const Description = styled.p`
  font-size: 1rem;
  letter-spacing: 0;
  line-height: calc(26 / 16);
  margin: 0.5rem 0 1.875rem 0;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const ItemDescription = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.85rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const Sidebar = styled(DefaultSidebar)`
  padding-top: 2.5rem;
  box-sizing: content-box;
  padding-right: 1rem !important;
  ${from.tablet} {
    border-right: 0.0625rem solid ${({ theme }) => theme.colors.grey200};
    padding-right: 1rem;
  }
`;
