import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState, useMemo, useCallback } from 'react';

import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import { ValueRankingsType } from 'context/fastContext/investor/types';
import { from, useMediaQuery } from 'styles/media';

import { valueColors, epiMap, opacityValues } from './constants';

const Values = ({
  showBreakdownByDefault = false,
}: {
  // eslint-disable-next-line react/require-default-props
  showBreakdownByDefault?: boolean;
}) => {
  const isMobile: boolean = !useMediaQuery(from.tablet);
  const [showBreakdown, setShowBreakdown] = useState<boolean>(
    showBreakdownByDefault,
  );
  const [epiToHighlight, setEpiToHighlight] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const [{ epiPriority, valueRankings }] = useInvestorStore((s: any) => ({
    epiPriority: s.profile.epiPriority as string,
    valueRankings: s.profile.valueRankings as ValueRankingsType[],
  }));

  const closeShowBreakdown = useCallback(() => {
    setShowBreakdown(false);
    setEpiToHighlight(null);
    setSelectedValue(null);
  }, []);

  const handleShowBreakdown = useCallback(
    (e) => {
      const epiValue = e.target.dataset.priority;
      const selectedId = e.target.dataset.id;

      if (selectedId === selectedValue) {
        setEpiToHighlight(null);
        setShowBreakdown(false);
        setSelectedValue(null);
      } else {
        setEpiToHighlight(epiValue);
        setShowBreakdown(true);
        setSelectedValue(selectedId);
      }
    },
    [selectedValue],
  );

  const scoring = useMemo(
    () =>
      valueRankings?.reduce<
        {
          [key in 'E' | 'P' | 'I']: {
            score: number;
            rankings: string[];
          };
        }
      >(
        (mem, { id, epiType }, index) => {
          const valueScore = 10 - (index + 1);
          const key = epiType as 'E' | 'P' | 'I';
          // Just a failsafe if the corresponding management command hasn't been ran yet
          if (!mem[key]) return mem;
          mem[key].score += valueScore;
          mem[key].rankings.push(id);
          return mem;
        },
        {
          E: {
            score: 0,
            rankings: [],
          },
          P: {
            score: 0,
            rankings: [],
          },
          I: {
            score: 0,
            rankings: [],
          },
        },
      ),
    [valueRankings],
  );

  const splitEpi = useMemo(() => epiPriority?.split(''), [epiPriority]);

  if (!epiPriority || !valueRankings) return null;

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[200]}`,
        display: isMobile ? 'block' : 'flex',
        borderRadius: '0.625rem',
        padding: '24px',
        minHeight: '270px',
      })}
      data-testid="value-container"
    >
      <Box sx={{ display: isMobile ? 'block' : 'flex', flex: 1 }}>
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            height: isMobile ? '200px' : 'initial',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              marginRight: '6px',
              color: `${theme.palette.grey[400]}`,
              fontSize: '12px',
            })}
          >
            <Box>100%</Box>
            <Box>75%</Box>
            <Box>50%</Box>
            <Box>25%</Box>
            <Box>0%</Box>
          </Box>
          <Box sx={{ width: isMobile ? '100%' : '110px' }}>
            {splitEpi?.map((k, index) => {
              const { score, rankings } = scoring![k as 'E' | 'P' | 'I'];

              return (
                <Box
                  key={k}
                  data-priority={k}
                  onClick={handleShowBreakdown}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    height: `${(score * 100) / 45}%`,
                    '> div:nth-of-type(1)': { width: '50%' },
                    '> div:nth-of-type(2)': { width: '30%' },
                    '> div:nth-of-type(3)': { width: '20%' },
                  }}
                >
                  {rankings.map((id, i) => {
                    const isSelected = selectedValue === id;
                    let borderRadius = 'none';
                    if (index === 0 && i === 0) borderRadius = '5px 0 0 0';
                    if (index === 0 && i === 2) borderRadius = '0 5px 0 0';
                    if (index === 2 && i === 0) borderRadius = '0 0 0 5px';
                    if (index === 2 && i === 2) borderRadius = '0 0 5px 0px';
                    return (
                      <Box
                        key={id}
                        sx={{
                          opacity: `${opacityValues[k as 'E' | 'P' | 'I'][i]}%`,
                          backgroundColor: valueColors[k as 'E' | 'P' | 'I'],
                          borderRadius,
                          border: isSelected ? '2px solid #000' : 'none',
                        }}
                        data-priority={k}
                        data-id={id}
                        height="100%"
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            marginTop: isMobile ? '16px' : 'initial',
            marginBottom: isMobile ? '16px' : 'initial',
          }}
        >
          <Box
            sx={{
              '> div': { marginBottom: isMobile ? 0 : '12px' },
              '> div:last-of-type': { marginBottom: 0 },
              display: isMobile ? 'flex' : 'block',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {splitEpi?.map((p) => (
              <Box sx={{ alignItems: 'center', display: 'flex' }} key={p}>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: valueColors[p as 'E' | 'P' | 'I'],
                    marginRight: '8px',
                  }}
                />
                <Typography fontSize="12px">{epiMap[p]}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 2 }}>
        {showBreakdown ? (
          <Box>
            {!showBreakdownByDefault && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  sx={{ p: 0 }}
                  type="button"
                  onClick={closeShowBreakdown}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            <ol
              style={{
                padding: 0,
                listStyleType: 'none',
                display: isMobile ? 'flex' : 'grid',
                flexDirection: isMobile ? 'column' : 'initial',
                gridAutoFlow: 'column',
                gridTemplateRows: 'repeat(5, 1fr)',
                columnGap: '6px',
              }}
            >
              {valueRankings?.map(({ epiType, id, label }, i) => {
                const epiName = epiMap[epiType]?.toLowerCase();
                const icon = epiName === 'integrity' ? 'scales' : epiName;
                const isSelectedGroup = epiType === epiToHighlight;
                const isSelectedId = id === selectedValue;
                return (
                  <li
                    style={{
                      display: 'flex',
                      marginBottom: '12px',
                      fontSize: '14px',
                      alignItems: 'center',
                    }}
                    key={id}
                  >
                    <Box
                      sx={(theme) => ({
                        flex: 1,
                        color: `${theme.palette.primary.main}`,
                        fontWeight: 'bold',
                      })}
                    >
                      {i + 1}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 12,
                        alignItems: 'center',
                      }}
                    >
                      {showBreakdownByDefault && (
                        <Box
                          sx={{
                            height: '25px',
                          }}
                          marginRight="6px"
                        >
                          <img
                            height="25px"
                            width="25px"
                            src={`${process.env.PUBLIC_URL}/${icon}.png`}
                            alt={`${icon} icon`}
                          />
                        </Box>
                      )}
                      <Box
                        sx={(theme) => ({
                          fontWeight: isSelectedGroup ? 'bold' : 'initial',
                          color: isSelectedGroup
                            ? theme.palette.primary.main
                            : 'initial',
                        })}
                      >
                        {label.replace(/<\/?p>/g, '')}{' '}
                      </Box>
                      {isSelectedId && (
                        <Box
                          sx={{
                            height: '25px',
                          }}
                          marginLeft="6px"
                        >
                          <img
                            height="25px"
                            width="25px"
                            src={`${process.env.PUBLIC_URL}/${icon}.png`}
                            alt={`${icon} icon`}
                          />
                        </Box>
                      )}
                    </Box>
                  </li>
                );
              })}
            </ol>
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                marginBottom: '12px',
                lineHeight: '18px',
                letterSpacing: '1px',
              }}
              color="secondary.contrastText"
            >
              OVERVIEW
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '30px',
              }}
            >
              This values ranking chart displays the priority order of the core
              values categories of Earth, People, and Integrity (top to bottom;
              top is the highest priority), as well as the nine total
              subcategories within the three core categories (left to right;
              left is the highest priority). Click on any section for more
              detail.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Values;
