import createFastContext from 'context/fastContext/createFastContext';

import { AccountState } from './types';

export const initialState: AccountState = {
  accounts: [],
  masterAccount: {},
  accountBeingEdited: {},
  isLoadingAccounts: false, // Used for showing full page Loading...
  isUpdatingAccounts: false, // Used for showing circular loader
  isEditingAccount: false, // Used to display edit allocations modal
  accountCustodians: [],
  accountTypes: [],
  history: [],
  isViewingHistory: false,
  isLoadingHistoricAccounts: false,
  status: null,
};

const { Provider, useStore } = createFastContext<AccountState>(initialState);

// Dont forget to include Provider within src/context/fastContext/index.tsx
export { Provider as AccountProvider, useStore };
