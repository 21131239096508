import styled, { css } from 'styled-components';

import { ReactComponent as DefaultIcon } from 'assets/svg/info-circle.svg';
import { prettyScrollbar } from 'styles/mixins';

import { fadeIn, fadeOut } from './animations';
import DefaultBar from './Bar';
import {
  BarProps,
  ButtonProps,
  ImageProps,
  OverlineProps,
  TextProps,
} from './types';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.3125rem 1.25rem -0.125rem rgba(31, 27, 36, 0.1);
  display: flex;
  overflow: hidden;
`;

export const Left = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${({ $isMobile }) =>
    $isMobile ? '2.5rem 0 2.25rem 1rem' : '2.5rem 0 2.25rem 3rem'};
  position: relative;
`;

export const Overline = styled.p<OverlineProps>`
  color: ${({ $color = 'grey600', theme }) => theme.colors[$color]};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
`;

export const MainTitle = styled.p<OverlineProps>`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.grey600};
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.625rem;
`;

export const Button = styled.button<ButtonProps>`
  all: unset;
  color: ${({ $active, theme }) =>
    theme.colors[$active ? 'primary400' : 'grey600']};
  display: inline-flex;

  &:hover {
    color: ${({ $active, theme }) =>
      theme.colors[$active ? 'primary400' : 'grey800']};
    cursor: pointer;
  }
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 0.875rem;
  width: 0.875rem;
`;

export const Image = styled.img<ImageProps>`
  bottom: 0;
  height: auto;
  position: absolute;
  left: 0;
  width: 100%;

  @media print {
    height: 56%;
    width: auto;
  }

  ${({ $animation }) => {
    switch ($animation) {
      case 'BAR':
        return css`
          animation: ${fadeIn} 0.5s linear forwards;
        `;
      case 'TEXT':
        return css`
          animation: ${fadeOut} 0.5s linear forwards;
          opacity: 0;
        `;
      case 'SKIP':
      default:
        return null;
    }
  }}

  ${({ $hide }) =>
    $hide === 'BAR'
      ? css`
          display: none;
        `
      : null};
`;

export const Right = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  padding: ${({ $isMobile }) => ($isMobile ? '12px' : '2.5rem 2rem')};
  @media print {
    padding: 1.5rem 1.5rem 1.5rem 0;
  }
`;

export const Text = styled.p<TextProps>`
  color: ${({ $color, theme }) => theme.colors[$color]};
  display: ${({ $hide }) =>
    $hide === 'SKIP' || $hide === 'TEXT' ? 'none' : 'block'};
  font-size: 0.875rem;
  line-height: 1.25rem;
  opacity: 0;
  max-height: 160px;
  overflow-y: auto;
  padding-right: 0.375rem;
  ${prettyScrollbar({ vertical: true })}

  a {
    color: inherit;
  }

  ${({ $animation }) => {
    switch ($animation) {
      case 'BAR':
        return css`
          animation: ${fadeOut} 0.5s linear forwards;
        `;
      case 'TEXT':
        return css`
          animation: ${fadeIn} 0.5s linear forwards;
        `;
      case 'SKIP':
      default:
        return null;
    }
  }}

  ${({ $withMargin }) =>
    $withMargin
      ? css`
          margin-bottom: 1.625rem;
        `
      : null}
`;

export const Bar = styled(DefaultBar)<BarProps>`
  display: ${({ $hide }) => ($hide === 'BAR' ? 'none' : 'grid')};
  margin-bottom: 1.5rem;
  max-width: 23.75rem;
  opacity: 1;

  @media print {
    max-width: 20rem;
  }

  ${({ $animation }) => {
    switch ($animation) {
      case 'BAR':
        return css`
          animation: ${fadeIn} 0.5s linear forwards;
        `;
      case 'TEXT':
        return css`
          animation: ${fadeOut} 0.5s linear forwards;
          opacity: 0;
        `;
      case 'SKIP':
      default:
        return null;
    }
  }}
`;
