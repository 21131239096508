import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Field, Formik } from 'formik';
import { useState, useEffect } from 'react';

import Input, { InputGroup, InputError } from 'components/Input';
import PasswordInput from 'components/PasswordInput';

import {
  Button,
  Card,
  Container,
  Content,
  Form,
  Illustration,
  Link,
  Logo,
  RecoveryHeader,
  StrengthMeter,
  StrengthColors,
  StrengthIcon,
  Text,
  ValidationGroup,
  ValidationItem,
  ValidationWrapper,
  Wrapper,
} from '../styles';
import { initialValues, validationSchema } from './constants';
import { Props } from './types';

const ResetForm = ({ onSubmit }: Props) => {
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [hasLength, setHasLength] = useState<boolean>(false);
  const [hasLower, setHasLower] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasUpper, setHasUpper] = useState<boolean>(false);
  const [hasSpecial, setHasSpecial] = useState<boolean>(false);

  useEffect(() => {
    setHasLength(password.length >= 12);
    setHasUpper(/[A-Z]/.test(password));
    setHasLower(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecial(/[!,%,&,@,#,$,^,*,?,_,~]/.test(password));
  }, [password]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordStrength = () => {
    const conditions = [hasLength, hasUpper, hasLower, hasNumber, hasSpecial];
    return conditions.filter(Boolean).length;
  };

  return (
    <Container>
      <Card>
        <Illustration />
        <Wrapper>
          <Content>
            <Logo size="medium-extra" />
            <RecoveryHeader>
              Reset Password
              <p>
                At least 12 characters long. Has a combination of upper and
                lowercase letters, numbers, and special symbols.
              </p>
            </RecoveryHeader>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, isSubmitting, touched, handleChange }) => (
                <Form>
                  <InputGroup className="inputGroup">
                    <Field
                      as={PasswordInput}
                      autoComplete="off"
                      name="password"
                      error={!!errors.password && !!touched.password}
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      className="resize"
                      onChange={(e: any) => {
                        handleChange(e);
                        setPassword(e.target.value);
                      }}
                      value={password}
                      rightIcon={
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                    />
                    <InputError name="password" />
                  </InputGroup>
                  <InputGroup className="inputGroup">
                    <Field
                      as={Input}
                      autoComplete="off"
                      name="confirmPassword"
                      error={
                        !!errors.confirmPassword && !!touched.confirmPassword
                      }
                      placeholder="Confirm your new password"
                      type={showPassword ? 'text' : 'password'}
                      className="resize"
                    />
                    <InputError name="confirmPassword" />
                  </InputGroup>
                  <StrengthMeter>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <StrengthIcon
                        key={item}
                        style={{
                          background:
                            item <= passwordStrength()
                              ? StrengthColors[item - 1]
                              : StrengthColors[5],
                        }}
                      />
                    ))}
                  </StrengthMeter>
                  <ValidationGroup>
                    <ValidationWrapper>
                      <ValidationItem className={hasLength ? 'valid' : ''}>
                        Minimum 12 characters
                      </ValidationItem>
                      <ValidationItem className={hasUpper ? 'valid' : ''}>
                        One uppercase letter
                      </ValidationItem>
                      <ValidationItem className={hasLower ? 'valid' : ''}>
                        One lowercase letter
                      </ValidationItem>
                      <ValidationItem className={hasNumber ? 'valid' : ''}>
                        One number
                      </ValidationItem>
                      <ValidationItem className={hasSpecial ? 'valid' : ''}>
                        Special symbol (@,#,$,%,&,!)
                      </ValidationItem>
                    </ValidationWrapper>
                  </ValidationGroup>
                  <Button disabled={isSubmitting} type="submit">
                    Change Password
                  </Button>
                  <Text>
                    <Link to="/login" className="return">
                      Return to Sign In
                    </Link>
                  </Text>
                </Form>
              )}
            </Formik>
          </Content>
        </Wrapper>
      </Card>
    </Container>
  );
};

export default ResetForm;
