type CacheKey = {
  url: string;
  method: string;
  queryParams?: Record<string, any>;
  body?: Record<string, any>;
};

type CacheItem = {
  value: any;
  expiration: number;
};

const DEFAULT_EXPIRATION_NUMBER = 30 * 60 * 1000; // 30 minutes

const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  Object.keys(ob).forEach((key) => {
    const value = ob[key];
    if (typeof value === 'object') {
      const flatObject = flattenObject(value);
      Object.keys(flatObject).forEach((nestedKey) => {
        toReturn[`${key}.${nestedKey}`] = flatObject[nestedKey];
      });
    } else {
      toReturn[key] = value;
    }
  });

  return toReturn;
};

// TODO: Revisit this, might we worth just adding in custom keys...
function generateKey(cacheKey: CacheKey): string {
  const myFlattenedObj = flattenObject(cacheKey);
  return JSON.stringify(myFlattenedObj, Object.keys(myFlattenedObj).sort());
}

class CacheService {
  private cache: Map<string, CacheItem>;

  constructor() {
    this.cache = new Map();
  }

  get(cacheKey: CacheKey): any | null {
    const key = generateKey(cacheKey);
    const cachedItem = this.cache.get(key);

    if (!cachedItem) {
      return null;
    }

    // If the item has expired, remove it from the cache and return null
    if (Date.now() > cachedItem.expiration) {
      this.cache.delete(key);
      return null;
    }

    return cachedItem.value;
  }

  set(
    cacheKey: CacheKey,
    value: any,
    ttl: number = DEFAULT_EXPIRATION_NUMBER,
  ): void {
    const key = generateKey(cacheKey);
    const expiration = Date.now() + ttl;

    this.cache.set(key, { value, expiration });
  }

  invalidateByUrl(url: string): void {
    const keysToDelete: string[] = [];

    this.cache.forEach((value, key) => {
      const keyObject: CacheKey = JSON.parse(key);
      if (keyObject.url === url) {
        keysToDelete.push(key);
      }
    });

    keysToDelete.forEach((key) => {
      this.cache.delete(key);
    });
  }

  invalidateByKey(cacheKey: CacheKey): void {
    const key = generateKey(cacheKey);
    this.cache.delete(key);
  }

  clear(): void {
    this.cache.clear();
  }
}

export const cacheService = new CacheService();
