import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';

import Modal from 'components/NewModal';

function ConfirmActivateModal({
  isOpen,
  setShowConfirmActivate,
  confirmActivate,
  hasErrorActivating,
  setHasErrorActivating,
}: any) {
  const close = useCallback(() => {
    setShowConfirmActivate(false);
    setHasErrorActivating(false);
  }, [setHasErrorActivating, setShowConfirmActivate]);

  return (
    <Modal
      transitionSpeed="0.3s"
      sx={{ maxWidth: '350px' }}
      isOpen={isOpen}
      close={close}
    >
      <Box mb="16px">
        <Typography>
          Select Save below to save your proposal to the proposal vault.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Button
          onClick={confirmActivate}
          color="success"
          sx={{
            fontWeight: 'bold',
            textTransform: 'initial',
            flex: 2,
            color: 'common.white',
          }}
          variant="contained"
          data-testid="confirm-vault-save"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{ fontWeight: 'bold', textTransform: 'initial', flex: 1 }}
          onClick={close}
        >
          Cancel
        </Button>
      </Box>
      {hasErrorActivating && (
        <Box sx={{ marginTop: '12px', fontSize: '14px', color: 'error.main' }}>
          Error saving proposal, please try again or contact support.
        </Box>
      )}
    </Modal>
  );
}

export default ConfirmActivateModal;
