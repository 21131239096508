import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Field, Formik } from 'formik';

import Input, { InputGroup, InputError } from 'components/Input';
import {
  ButtonContainer,
  Content,
  Form,
  Link,
  Logo,
  RecoveryHeader,
  ResendButton,
  Row,
  SubmitButton,
  Text,
} from 'containers/Guest/NewPassword/styles';

import { initialValues, validationSchema } from './constants';
import { Props } from './types';

const CodeForm = ({
  onResendCode,
  onSubmit,
  codeResent,
  phone,
  handleRequire2FAClear,
  errorText,
}: Props) => {
  return (
    <Content>
      <Logo size="medium-extra" />
      <RecoveryHeader>
        Enter 2FA Code
        <p>We have sent an SMS to the following phone number</p>
        <p>
          <b>* (***) ***-{phone}</b>
        </p>
      </RecoveryHeader>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting, touched }) => (
          <Form>
            <Row>
              <InputGroup>
                <Field
                  as={Input}
                  autoComplete="off"
                  name="code"
                  error={!!errors.code && !!touched.code}
                  placeholder="Code"
                  type="text"
                />
                <InputError name="code" />
              </InputGroup>
              {codeResent ? (
                <Tooltip title="Resend limit reached. Please wait 15 minutes.">
                  <Box>
                    <ResendButton
                      onClick={onResendCode}
                      disabled={codeResent}
                      type="button"
                    >
                      Resend
                    </ResendButton>
                  </Box>
                </Tooltip>
              ) : (
                <ResendButton
                  onClick={onResendCode}
                  disabled={codeResent}
                  type="button"
                >
                  Resend
                </ResendButton>
              )}
            </Row>
            <ButtonContainer>
              <SubmitButton disabled={isSubmitting} type="submit">
                Submit Code
              </SubmitButton>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <Text>
        <Link to="/login" className="return" onClick={handleRequire2FAClear}>
          Return to Sign In
        </Link>
      </Text>
      {errorText && (
        <Box
          sx={(theme) => ({
            color: `${theme.palette.warning.main}`,
            fontSize: '14px',
            marginTop: '1rem',
          })}
        >
          {errorText}
        </Box>
      )}
    </Content>
  );
};

export default CodeForm;
