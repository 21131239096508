import styled, { css } from 'styled-components';

import Select from '../Select';

export const Container = styled.thead`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-bottom: 0.75rem;
  height: 2.75rem;
`;

export const Row = styled.tr<{ selectable: boolean }>`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  flex: 1;
  ${({ selectable }) =>
    selectable &&
    css`
      > th:first-child {
        flex: 0;
      }

      > th:nth-child(2) {
        margin-left: 0;
      }
    `}
`;

export const Cell = styled.th<{
  $width?: string;
  $align?: 'start' | 'end';
}>`
  display: flex;
  justify-content: ${({ $align }) => `flex-${$align || 'start'}`};
  flex: 1;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey600};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weights.bold};

  & + & {
    margin-left: 1rem;
  }

  ${({ $width }) =>
    $width &&
    css`
      flex-grow: 0;
      flex-basis: ${$width};
    `}
`;

export const SelectAll = styled(Select)``;

export const Actions = styled.th`
  flex-grow: 0;
  flex-basis: 4rem;
`;
