import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

import { DataViewContainerTitleSwitch } from 'components/DataViewContainerTitleSwitch';
import Filter from 'components/Filter';
import type { FilterOptionType } from 'components/Filter/types';
import AddClient from 'containers/DataViewContainer/AddClient';
import useAdvisorActions from 'context/fastContext/advisor/actions';
import { useStore as useAdvisorStore } from 'context/fastContext/advisor/context';
import { AdvisorState } from 'context/fastContext/advisor/types';
import { determineProposalState } from 'context/fastContext/helpers';
import useUser from 'context/hooks/User.hooks';
import removeItem from 'utils/removeItem';
import useSearchParams from 'utils/useSearchParams';
import SearchBox from 'v2/components/SearchBox';

import { FILTER_OPTIONS, FILTER_OPTIONS_ARRAY } from './constants';
import ClientsTable from './table';

function InvestorsTable() {
  const query = useSearchParams();
  const presetAdvisorFilter = query.get('advisor');
  const defaultFilterValues = useMemo(
    () => (presetAdvisorFilter ? [presetAdvisorFilter] : []),
    [presetAdvisorFilter],
  );
  const { userInfo } = useUser();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [filterValues, setFilterValues] = useState<any[]>(defaultFilterValues);
  const { fetchClientsList } = useAdvisorActions();

  const [{ clientsList, isLoadingClientsList }] = useAdvisorStore(
    (s: AdvisorState) => ({
      clientsList: s.clientsList,
      isLoadingClientsList: s.isLoadingClientsList,
    }),
  );

  useEffect(() => {
    fetchClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const advisorList: string[] = useMemo(() => {
    const uniqueAdvisors = new Set<string>(defaultFilterValues);
    clientsList.forEach((client) => uniqueAdvisors.add(client.advisor));
    return Array.from(uniqueAdvisors).sort();
  }, [clientsList, defaultFilterValues]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterOptionsToUse: FilterOptionType = useMemo(
    () => ({
      ...FILTER_OPTIONS,
      Advisor: advisorList.map<{ name: string; label: string }>((advisor) => ({
        name: advisor,
        label: advisor,
      })),
    }),
    [advisorList],
  );

  const handleRefresh = useCallback(() => {
    fetchClientsList(false);
  }, [fetchClientsList]);

  useEffect(() => {
    // Clear out any old timer before setting a new timer
    if (timer.current) {
      clearTimeout(timer.current);
    }

    // Timer acting as a debounce
    const newTimer = setTimeout(() => {
      setSearchFilterValue(searchValue.toLowerCase());
    }, 400);

    timer.current = newTimer;

    // Cleanup timer on unmount or before re-running the effect
    return () => {
      clearTimeout(newTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleSetFilterValues = useCallback(
    (e: any) => {
      const isChecked = e.target.checked;
      const { value } = e.target;

      if (isChecked && !filterValues.includes(value)) {
        const newArr = [...filterValues, value];
        setFilterValues(newArr);
      } else if (!isChecked && filterValues.includes(value)) {
        const newArr = [...filterValues];
        const updatedArr = removeItem(newArr, value);
        setFilterValues(updatedArr);
      }
    },
    [filterValues],
  );

  const handleClearFilters = useCallback(() => {
    setFilterValues([]);
  }, []);

  const filteredList = useMemo(() => {
    const filteredByEPI = FILTER_OPTIONS_ARRAY['EPI Priority'].some((item) =>
      filterValues.includes(item),
    );

    const filteredByRisk = FILTER_OPTIONS_ARRAY.Risk.some((item) =>
      filterValues.includes(item),
    );

    const riskValues = filterValues.filter((v) =>
      FILTER_OPTIONS_ARRAY.Risk.includes(v),
    );

    const filteredByAdvisor = advisorList.some((item) =>
      filterValues.includes(item),
    );

    const filterByProgress = FILTER_OPTIONS_ARRAY.Progress.some((item) =>
      filterValues.includes(item),
    );

    const filteredByMindset = FILTER_OPTIONS_ARRAY.Mindset.some((item) =>
      filterValues.includes(item),
    );

    const filteredByCreatedBy = FILTER_OPTIONS_ARRAY['Created By'].some(
      (item) => filterValues.includes(item),
    );
    const byAdvisor = filterValues.includes('Advisor');
    const byInvestor = filterValues.includes('Investor');

    return clientsList.filter((c) => {
      let containsSearchValue: boolean = true;
      let containsEPI: boolean = true;
      let containsRisk: boolean = true;
      let containsProgressStatus: boolean = true;
      let containsCreatedBy: boolean = true;
      let containsMindset: boolean = true;
      let containsAdvisor: boolean = true;

      if (filterValues.length) {
        if (filteredByEPI) {
          containsEPI = filterValues.includes(c.investorProfile.epiPriority);
        }

        if (filteredByRisk) {
          containsRisk = riskValues.includes(
            c?.investorProfile.riskLevel?.toString(),
          );
        }

        if (filteredByMindset) {
          containsMindset = filterValues.includes(
            c.investorProfile?.personalityBadge?.name,
          );
        }

        if (filteredByAdvisor) {
          containsAdvisor = filterValues.includes(c.advisor);
        }

        if (filterByProgress) {
          const proposals = c.proposals
            ? c.proposals.filter((p) => p.status !== 'deactivated')
            : c.proposals;
          const proposalState = proposals?.length
            ? determineProposalState(proposals)
            : 'DRAFT';
          containsProgressStatus =
            c.investorProfile.status !== 'COMPLETE'
              ? filterValues.includes('AWAITING')
              : filterValues.includes(proposalState);
        }

        if (filteredByCreatedBy && !(byAdvisor && byInvestor)) {
          containsCreatedBy =
            (byInvestor && c.createdBy === 'Investor') ||
            (byAdvisor && c.createdBy === 'Advisor');
        }
      }

      if (searchFilterValue) {
        containsSearchValue =
          c.householdName?.toLocaleLowerCase()?.includes(searchFilterValue) ||
          c.email?.toLocaleLowerCase()?.includes(searchFilterValue) ||
          c.name?.toLocaleLowerCase()?.includes(searchFilterValue) ||
          c.advisor?.toLocaleLowerCase()?.includes(searchFilterValue) ||
          c.company?.toLocaleLowerCase()?.includes(searchFilterValue);
      }

      return (
        containsSearchValue &&
        containsEPI &&
        containsRisk &&
        containsMindset &&
        containsProgressStatus &&
        containsCreatedBy &&
        containsAdvisor
      );
    });
  }, [advisorList, clientsList, filterValues, searchFilterValue]);

  return (
    <Container sx={{ marginBottom: '24px' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '7.25rem',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={(theme) => ({
            color: theme.palette.common.black, // Adjust according to your theme structure
            fontSize: '1.5rem',
            fontWeight: 800, // Adjust according to your theme structure
            lineHeight: 'calc(36 / 24)',
          })}
        >
          <DataViewContainerTitleSwitch title="Clients" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *': {
              marginRight: '1.25rem',
              '&:last-child': {
                marginRight: 0,
              },
            },
          }}
        >
          <AddClient />
          <SearchBox
            value={searchValue}
            placeholder="Search..."
            ariaLabel="search clients"
            handleClear={handleSearchClear}
            handleOnChange={handleSearchValueChange}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 270,
              height: 36,
              p: '6px',
              border: '1px solid',
              borderColor: searchValue.length > 0 ? 'primary.main' : 'grey.300',
              borderRadius: '0.3125rem',
              '&:hover': {
                borderColor: 'primary.main',
              },
            }}
            dataTestId="clients-search"
          />
          <Box sx={{ mr: 0 }}>
            <Filter
              filterValues={filterValues}
              handleSetFilterValues={handleSetFilterValues}
              filterOptions={filterOptionsToUse}
              clearFilters={handleClearFilters}
            />
          </Box>
          <IconButton disabled={isLoadingClientsList} onClick={handleRefresh}>
            <Tooltip title="Refresh">
              <RefreshIcon color="primary" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <ClientsTable
        isLoadingClientsList={isLoadingClientsList}
        clients={filteredList}
        role={userInfo.role}
      />
    </Container>
  );
}

export default InvestorsTable;
