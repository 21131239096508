import type { FilterOptionType } from 'components/Filter/types';

export const statusSortModel: { [key: string]: number } = {
  AS: 1,
  AP: 2,
  AI: 3,
  AC: 4,
  AW: 5,
  AO: 6,
};

export const surveyStatusSortModel: { [key: string]: number } = {
  'Not Sent': 1,
  Sent: 2,
  Started: 3,
  Complete: 4,
};

export const riskColors: { [key: number]: string } = {
  1: '#ADB2B0',
  2: '#CCFAE5',
  3: '#91E3C2',
  4: '#24B07A',
  5: '#1c7552',
  6: '#D5C9FE',
  7: '#A199DE',
  8: '#6449C0',
  9: '#FCC79E',
  10: '#D48A57',
};

export const FILTER_OPTIONS: FilterOptionType = {
  'EPI Priority': [
    { name: 'EPI', label: 'EPI' },
    { name: 'EIP', label: 'EIP' },
    { name: 'PIE', label: 'PIE' },
    { name: 'PEI', label: 'PEI' },
    { name: 'IPE', label: 'IPE' },
    { name: 'IEP', label: 'IEP' },
    { name: 'No Preference', label: 'No Preference' },
  ],
  Risk: [
    // { name: '1', label: '1' },
    { name: '2', label: '2' },
    { name: '3', label: '3' },
    { name: '4', label: '4' },
    { name: '5', label: '5' },
    { name: '6', label: '6' },
    { name: '7', label: '7' },
    { name: '8', label: '8' },
    { name: '9', label: '9' },
    { name: '10', label: '10' },
  ],
  Mindset: [
    { name: 'Private Investigator', label: 'Private Investigator' },
    { name: 'Change Maker', label: 'Change Maker' },
    { name: 'Score Keeper', label: 'Score Keeper' },
    { name: 'Balanced Believer', label: 'Balanced Believer' },
    { name: 'Reflective Reflector', label: 'Reflective Reflector' },
    { name: 'Passive Protector', label: 'Passive Protector' },
    { name: 'Team Player', label: 'Team Player' },
    { name: 'Effects Causer', label: 'Effects Causer' },
    { name: 'Complex Contrarian', label: 'Complex Contrarian' },
  ],
  Progress: [
    { name: 'AWAITING', label: 'Awaiting Assessment' },
    { name: 'DRAFT', label: 'Assessment Complete' },
    { name: 'ACTIVE', label: 'Proposal Complete' },
    { name: 'PENDING', label: 'Accounts In Progress' },
    { name: 'IMPLEMENTED', label: 'Accounts Implemented' },
  ],
  'Created By': [
    { name: 'Advisor', label: 'Advisor' },
    { name: 'Investor', label: 'Investor' },
  ],
};

export const FILTER_OPTIONS_ARRAY = {
  'EPI Priority': FILTER_OPTIONS['EPI Priority'].map((t) => t.name),
  Risk: FILTER_OPTIONS.Risk.map((t) => t.name),
  Mindset: FILTER_OPTIONS.Mindset.map((t) => t.name),
  Progress: FILTER_OPTIONS.Progress.map((t) => t.name),
  'Created By': FILTER_OPTIONS['Created By'].map((t) => t.name),
};
