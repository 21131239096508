import { Formik, Form, Field } from 'formik';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import Input, { InputError, InputGroup } from 'components/Input';
import PhoneInput from 'components/PhoneInput';

import useConnect from './connect';
import { Back, Container, Header, Inputs, Title, Success } from './styles';

const checkValuesAreSame = (obj1: any, obj2: any) => {
  // Should be the same object...
  const keys = Object.keys(obj1);
  const notEqualValues = keys.filter((k: string) => obj1[k] !== obj2[k]);
  return notEqualValues.length === 0;
};

const Profile = () => {
  const { validationSchema, handleSubmit, initialValues, me, showSuccess } =
    useConnect();

  if (!me) return null;

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => {
          const valuesAreSame = checkValuesAreSame(values, initialValues);
          return (
            <>
              <Header>
                <Back />
                <Title>Edit profile</Title>
              </Header>
              <Form>
                <Inputs>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="firstName"
                      error={!!errors.firstName && !!touched.firstName}
                      placeholder="First name"
                    />
                    <InputError name="firstName" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      name="lastName"
                      error={!!errors.lastName && !!touched.lastName}
                      placeholder="Last name"
                    />
                    <InputError name="lastName" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={DateInput}
                      name="birthDate"
                      error={!!errors.birthDate && !!touched.birthDate}
                      placeholder="Birth date"
                    />
                    <InputError name="birthDate" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      readonly
                      disabled
                      as={Input}
                      name="email"
                      error={!!errors.email && !!touched.email}
                      placeholder="Email"
                    />
                    <InputError name="email" />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      as={PhoneInput}
                      name="phone"
                      error={!!errors.phone && !!touched.phone}
                      placeholder="Phone number"
                    />
                    <InputError name="phone" />
                  </InputGroup>
                </Inputs>

                <Button
                  variant="primary"
                  disabled={valuesAreSame || isSubmitting}
                >
                  Save Changes
                </Button>
              </Form>
              {showSuccess && <Success>Changes saved successfully.</Success>}
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default Profile;
