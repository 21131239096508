import { useQuery } from '@apollo/client';

import { ActiveSurveyQuery } from 'graphql/generated';
import { ACTIVE_SURVEY } from 'graphql/queries/survey';
import { normalizeSurvey } from 'models/Survey';

const useActiveSurvey = () => {
  const { data, loading } = useQuery<ActiveSurveyQuery>(ACTIVE_SURVEY);

  const survey = data?.activeSurvey
    ? // @ts-ignore
      normalizeSurvey(data.activeSurvey)
    : undefined;

  return {
    loading,
    survey,
  };
};

export default useActiveSurvey;
