import { MenuButton } from '@reach/menu-button';
import styled, { css } from 'styled-components';

import { Animation } from 'components/BaseDropdown/styles';
import DefaultButton from 'components/Button';
import { Loader as Spinner } from 'components/FileInput/FileRow/styles';
import IconButton from 'components/IconButton';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-bottom: 21.6875rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;

  form {
    tbody {
      tr {
        min-height: 2.75rem;
        height: initial;
        padding: 0.325rem 0 0.325rem 0.7rem;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.75rem;
`;

export const GoBack = styled(IconButton)`
  margin-right: 1rem;
`;
export const Title = styled.h2`
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  color: ${({ theme }) => theme.colors.dark};
`;

export const Paragraph = styled.h4`
  font-weight: bold;
  letter-spacing: 0;
  line-height: calc(30 / 21);
  color: ${({ theme }) => theme.colors.dark};
`;

const ActionButtonBase = css`
  all: unset;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600};

  &:disabled {
    color: ${({ theme }) => theme.colors.grey500};
    cursor: not-allowed;
  }
`;

export const DropdownButton = styled(MenuButton)`
  ${ActionButtonBase};
`;

export const ActionLink = styled.a`
  align-items: center;
  border-radius: 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  width: 7.5rem;

  ${Animation}

  &:hover, &:focus {
    background-color: ${({ theme }) => theme.colors.primary10};
    color: inherit;
  }
`;

export const ImportButton = styled(DefaultButton).attrs({
  type: 'button',
  variant: 'secondary',
})`
  margin-left: auto;
`;

export const RemoveButton = styled.button.attrs({ type: 'button' })`
  ${ActionButtonBase};
  margin-left: 0.3125rem;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${({ theme }) => theme.colors.flamingo};
  }
`;

export const ConfirmButton = styled.button.attrs({ type: 'button' })`
  ${ActionButtonBase};
  margin-left: 0.3125rem;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${({ theme }) => theme.colors.flamingo};
  }

  & > svg {
    transform: rotate(180deg);
  }
`;

export const SaveButton = styled(DefaultButton).attrs(() => ({
  variant: 'primary',
}))`
  margin-right: 0.5rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;
  > * {
    margin-top: 0;
  }
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey600};
  line-height: 1.5;
  margin-top: 0.75rem;
  margin: 0;
`;

export const Link = styled.a`
  color: inherit;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
    transition: opacity 180ms ease-on-out;
  }
`;

export const Loader = styled(Spinner)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.4375rem;
`;
