import { FC } from 'react';

import image1x_png from 'assets/images/disclosuresCard/disclosure_header.png';
import image2x_png from 'assets/images/disclosuresCard/disclosure_header@2x.png';

import { ChildContent, Container, Image } from './styles';
import { Props } from './types';

const DisclosuresHeader: FC<Props> = ({ children, ...props }) => (
  <Container {...props}>
    <picture>
      <source
        srcSet={`
          ${image2x_png} 2x,
          ${image1x_png}
        `}
        type="image/png"
      />
      <Image src={image1x_png} alt="" />
    </picture>
    <ChildContent>{children}</ChildContent>
  </Container>
);

export default DisclosuresHeader;
