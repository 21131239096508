import { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import Portfolio from 'containers/Main/Portfolio';
import Survey from 'containers/Main/Survey';
import useAccountActions from 'context/fastContext/account/actions';
import useInvestorActions from 'context/fastContext/investor/actions';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import usePortfolioActions from 'context/fastContext/portfolio/actions';
import useProposalActions from 'context/fastContext/proposal/actions';
import useInvestor from 'context/hooks/Investor.hooks';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';

import Edit from './ClientsDetailEdit';
import Root from './ClientsDetailRoot';
import { useConnect } from './connect';

const ClientsDetail = () => {
  const { path } = useConnect();
  const { id: investorId } = useParams<{ id: string }>();
  const {
    // fetchInvestorCurrentProposal,
    loadingCurrentProposal,
    handleResetInvestor,
    // fetchInvestorProposalHistory,
  } = useInvestor();

  const { initialProposalFetch, resetProposal } = useProposalActions();
  const { fetchCurrentPortfolio } = usePortfolioActions();
  const { handleInitialAccountFetch, resetAccounts } = useAccountActions();
  const [isLoadingProfile] = useInvestorStore((s: any) => s.isLoadingProfile);
  const { fetchProfile, resetProfile } = useInvestorActions();
  const { fetchInvestorInfo, handleResetInvestorInfo } = useInvestorInfo();

  useEffect(() => {
    const initialFetch = async () => {
      if (investorId && !isLoadingProfile) {
        // TODO: Change this to an initial fetch of data?
        const profileData = await fetchProfile({ investorId, useCache: false });
        await fetchInvestorInfo(investorId, false);
        fetchCurrentPortfolio();
        if (profileData?.status === 'COMPLETE' && !loadingCurrentProposal) {
          const assetClasses = await initialProposalFetch();
          await handleInitialAccountFetch({ assetClasses });
        }
      }
    };
    // If the ID changes then lets just reset investor to be safe
    handleResetInvestor();
    handleResetInvestorInfo();
    resetProposal();
    resetAccounts();
    resetProfile();
    initialFetch();
  }, [investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route component={Edit} path={`${path}/edit-household`} />
      <Route component={Portfolio} path={`${path}/holdings`} />
      {/* <Route component={Shared} path={`${path}/shared`} /> */}
      <Route component={Survey} path={`${path}/survey`} />
      <Route component={Root} path={path} />
    </Switch>
  );
};

export default ClientsDetail;
