export default function roundToTarget(
  obj: {
    [key: string]: any;
  },
  target: number,
  valueKey: string = 'weight',
): { [key: string]: any } {
  const keys = Object.keys(obj);
  const isNested = typeof obj[keys[0]] === 'object' && obj[keys[0]] !== null;

  if (typeof target !== 'number') {
    throw new Error('Target must be a number');
  }

  // extract values
  const values = isNested
    ? keys.map((key) => (obj[key] as { [key: string]: number })[valueKey])
    : keys.map((key) => obj[key] as number);

  if (values.some((val) => typeof val !== 'number')) {
    throw new Error('All values must be numbers');
  }

  // calculate integer and fractional parts
  const intParts = values.map(Math.floor);
  const fracParts = values.map((num) => num - Math.floor(num));
  const intSum = intParts.reduce((a, b) => a + b, 0);
  let remaining = target - intSum;

  // Determine the indices for adjustments based on the direction of remaining
  const sortedIndices =
    remaining > 0
      ? fracParts
          .map((part, index) => ({ index, value: part }))
          .sort((a, b) => b.value - a.value)
          .map((item) => item.index)
      : fracParts
          .map((part, index) => ({ index, value: part }))
          .sort((a, b) => a.value - b.value)
          .map((item) => item.index);

  const adjustedValues = intParts.map((part, index) => {
    if (remaining > 0 && sortedIndices.includes(index)) {
      // eslint-disable-next-line no-plusplus
      remaining--;
      return part + 1;
    }
    if (remaining < 0 && sortedIndices.includes(index)) {
      // eslint-disable-next-line no-plusplus
      remaining++;
      return part - 1;
    }
    return part;
  });

  // construct the final rounded object
  const roundedObj: any = {};
  keys.forEach((key: any, index: number) => {
    if (isNested) {
      roundedObj[key] = { ...obj[key], [valueKey]: adjustedValues[index] };
    } else {
      roundedObj[key] = adjustedValues[index];
    }
  });

  return roundedObj;
}
