import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.875rem;
`;

export const List = styled.div`
  border-top: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  max-height: 16.5625rem;
  overflow-y: auto;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  position: relative;
  > * {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.75rem;
  font-weight: bold;
  height: 1.125rem;
  letter-spacing: 0.0625rem;
  line-height: calc(18 / 12);
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`;
