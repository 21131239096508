import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { tradeTicketHelp } from './constants';

function TradeTicketHelp({ close }: { close: any }) {
  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.common.white,
        padding: '2rem 1rem 2rem 2rem',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Button variant="outlined" size="small" onClick={close}>
          Back
        </Button>
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          Trade Ticket Help
        </Typography>
        <Box sx={{ width: '65.67px' }} />
      </Box>
      <Box
        sx={{
          maxHeight: 'calc(100% - 52px)',
          overflowY: 'auto',
          paddingRight: '8px',
        }}
      >
        {tradeTicketHelp.map((item) => (
          <Box key={item.title} marginBottom="20px">
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {item.title}
            </Typography>
            {item.description && (
              <Typography
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}

            {item.children &&
              item.children.map((c) => (
                <Box sx={{ marginTop: '12px' }} key={c.title}>
                  <Typography sx={{ marbinBottom: '12px', fontWeight: 'bold' }}>
                    {c.title}
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: c.description }}
                  />
                </Box>
              ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TradeTicketHelp;
