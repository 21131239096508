import { useCallback } from 'react';

import AuthFetch from 'utils/authFetch';

import { useStore } from './context';

export default function useAdvisor() {
  const [, setStore] = useStore(() => {});

  const fetchClientsList = useCallback(
    async (useCache = true) => {
      setStore({ isLoadingClientsList: true });
      const data = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/user/clients/`,
        method: 'GET',
        useCache,
      });

      setStore({ clientsList: data.payload, isLoadingClientsList: false });
    },
    [setStore],
  );

  const fetchZendeskToken = useCallback(
    async (useCache = false) => {
      const data = await AuthFetch({
        url: `${process.env.REACT_APP_API_ENDPOINT}api/user/base/zendesk_jwt/`,
        method: 'POST',
        useCache,
      });
      setStore({ zendeskToken: data?.payload?.zendeskToken });
    },
    [setStore],
  );

  return { fetchClientsList, fetchZendeskToken };
}
