import { FC, memo } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';

import useConnect from './connect';
import { Container, Name, AddButton, PlaceholderName } from './styles';
import { Props } from './types';

export const AssetResultPlaceholder = ({ ...props }) => {
  const randomLength = Math.floor(Math.random() * (60 - 15) + 15);
  return (
    <Container {...props}>
      <PlaceholderName $width={randomLength} />
    </Container>
  );
};

const MAX_CHARS_SPACE = 59;

const AssetResult: FC<Props> = ({ id, name, symbol, ...props }) => {
  const { formattedName, handleAdd } = useConnect({ id, name, symbol });

  return (
    <Container {...props}>
      <Name
        title={
          formattedName.length > MAX_CHARS_SPACE ? formattedName : undefined
        }
      >
        {formattedName}
      </Name>
      <AddButton onClick={handleAdd} icon={<MdAddCircleOutline />}>
        Add asset
      </AddButton>
    </Container>
  );
};

export default memo(AssetResult);
