import { FC, memo, useMemo } from 'react';

import PieChart from './PieChart';
import {
  Circle,
  Container,
  Legend,
  Item,
  ItemText,
  Divider,
  Header,
} from './styles';
import { Props } from './types';

const Chart: FC<Props> = ({
  data,
  pieData,
  extended = true,
  dataShown,
  newProposalPie,
  ...props
}) => {
  const outerData = useMemo(() => {
    // If seeds dataShown. Remove conditional after Portfolio Asset Classes are pulled in
    if (dataShown === 'seeds') {
      return data.reduce<any>((mem, product: any) => {
        const items = product.breakdown.map((item: any) => ({
          title: item.title,
          color: item.color,
          value: item.allocation,
        }));

        return mem.concat(items);
      }, []);
    }
    // If current dataShown. Redo after Portfolio Asset Classes are pulled in
    return data;
  }, [data, dataShown]);

  const legendItems = useMemo(
    () => data.sort((a: any, b: any) => b.value - a.value),
    [data],
  );

  return (
    <Container {...props}>
      <PieChart
        outerData={outerData}
        innerData={newProposalPie?.length ? newProposalPie : pieData}
        dataShown={dataShown}
      />
      {dataShown === 'current' && ( // Remove this after Portfolio Asset Classes are pulled in
        <Legend $extended={extended}>
          <div>
            <Header>
              <span>Sectors</span>
            </Header>
            <Divider />
            {legendItems
              .filter((sec: any) => sec.value !== 0)
              .map((sec: any) => {
                return (
                  <Item key={sec.title} $lighter={data.length > 6}>
                    <div>
                      <Circle background={sec.color} />
                      <ItemText>{sec.title}</ItemText>
                    </div>
                  </Item>
                );
              })}
          </div>
        </Legend>
      )}
      {dataShown === 'seeds' && (
        <Legend $extended={extended}>
          {data.map((acg: any) => {
            const assetGroupKey = acg.name.toLowerCase();
            return (
              <div key={assetGroupKey}>
                <Header>
                  <span>{acg.isFund ? 'Fund' : acg.name}</span>
                  <span>{acg.totalWeight}%</span>
                </Header>
                <Divider />
                {acg.breakdown.map((item: any) => {
                  return (
                    <Item key={item.title} $lighter={data.length > 6}>
                      <div>
                        <Circle background={item.color} />
                        <ItemText>{item.title}</ItemText>
                      </div>
                      <span>{item.allocation}%</span>
                    </Item>
                  );
                })}
              </div>
            );
          })}
        </Legend>
      )}
    </Container>
  );
};

export default memo(Chart);
