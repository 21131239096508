import { Tooltip as DefaultTooltip } from '@mui/material';
import styled, { createGlobalStyle } from 'styled-components';

import { ReactComponent as InfoCircle } from 'assets/svg/info-circle.svg';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 0.625rem;
  cursor: default;
  padding: 1.125rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.42;
  color: ${({ theme }) => theme.colors.dark};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  transition: background-color 200ms ease-in-out;
  &:hover,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.grey0};
  }

  @media print {
    padding: 0.625rem 0.875rem;
    font-size: 0.75rem;
    grid-template-columns: 1fr;
    line-height: 1;
    grid-template-rows: 0.75rem 0.75rem;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-block-end: 0.125rem;

  @media print {
    margin-block-start: -0.125rem;
  }
`;

export const Tooltip = styled(DefaultTooltip)`
  @media print {
    display: none;
  }
`;

export const InfoIconWrapper = styled.span`
  grid-column: 2;
  grid-row: 1 / -1;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.775rem;
  height: 1.775rem;
  color: ${({ theme }) => theme.colors.grey500};
  opacity: 0;
  transition: opacity 200ms ease, color 200ms ease;

  @media print {
    display: none;
  }

  ${Container}:hover &, &:focus {
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const InfoIcon = styled(InfoCircle)``;

export const TooltipStyle = createGlobalStyle`
  .SeedsCoreEquityValueCard.MuiTooltip-tooltip {
    border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
    border-radius: 0.625rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1.25rem 2.5rem -0.125rem rgba(31, 27, 36, 0.1);
    padding: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.42;
    color: ${({ theme }) => theme.colors.dark};
    font-family: inherit;
  }
`;
