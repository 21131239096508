import Box from '@mui/material/Box';
import { Formik } from 'formik';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { ReactComponent as ImportIcon } from 'assets/svg/import.svg';
import { ScrollToTopOnMount } from 'components/ScrollToTopOnMount';
import FailedAssetsModal from 'containers/Modals/FailedAssetsModal';
import ImportPortfolioModal from 'containers/Modals/ImportPortfolioModal';
import InvalidFormPrompt from 'containers/Modals/InvalidFormPrompt';

import Assets from './Assets';
import { useConnect } from './connect';
import { initialValues, validationSchema } from './constants';
import Search from './Search';
import {
  Container,
  GoBack,
  Header,
  ImportButton,
  Info,
  InfoWrapper,
  SaveButton,
  Title,
  Loader,
  Row,
  Paragraph,
} from './styles';
import Toolbar from './Toolbar';
import TotalPercentage from './Toolbar/TotalPercentage';

const Portfolio = () => {
  const {
    handleRefetch,
    handleOpenModal,
    handleSubmit,
    handleConfirmDeletePortfolio,
    hasCurrentPortfolio,
    handleDeletePortfolio,
    showConfirmDelete,
    setShowConfirmDelete,
    confirmErrors,
    setConfirmErrors,
    isLoadingPortfolio,
    isImportingPortfolio,
    isUpdatingPortfolio,
    defaultAssets,
    investorId,
    portfolioType,
    portfolioHasBeenClearedRef,
    schema,
    user,
  } = useConnect();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues(defaultAssets, portfolioType)}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={
        portfolioHasBeenClearedRef.current ? undefined : validationSchema
      }
    >
      {({ isSubmitting, values, dirty }) => (
        <>
          <InvalidFormPrompt
            portfolioHasBeenCleared={portfolioHasBeenClearedRef.current}
          />
          <ScrollToTopOnMount />
          <Container>
            <Header>
              <GoBack
                icon={<ArrowLeft />}
                variant="secondary"
                to={`/clients/${investorId}`}
                title="Go to investor profile"
              />
              <Title>
                {defaultAssets ? `Edit ${user} holdings` : 'Create portfolio'}{' '}
              </Title>
              <ImportButton icon={<ImportIcon />} onClick={handleOpenModal}>
                Import from spreadsheet
              </ImportButton>
            </Header>
            <Paragraph>
              Upload current holdings to analyze a portfolio
            </Paragraph>
            <Row>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  flex: 1,
                  minHeight: '2.875rem',
                }}
              >
                <SaveButton
                  type="submit"
                  form="assets-form"
                  disabled={
                    portfolioHasBeenClearedRef.current
                      ? isSubmitting
                      : (values.assets && values.assets.length === 0) ||
                        isSubmitting ||
                        !dirty
                  }
                >
                  {isSubmitting ? <Loader /> : null}
                  Save Current Portfolio
                </SaveButton>
                <Toolbar
                  portfolioType={portfolioType}
                  portfolioHasBeenClearedRef={portfolioHasBeenClearedRef}
                  actions={{ handleRefetch }}
                  hasCurrentPortfolio={hasCurrentPortfolio}
                  handleConfirmDeletePortfolio={handleConfirmDeletePortfolio}
                  handleDeletePortfolio={handleDeletePortfolio}
                  showConfirmDelete={showConfirmDelete}
                  setShowConfirmDelete={setShowConfirmDelete}
                  confirmErrors={confirmErrors}
                  setConfirmErrors={setConfirmErrors}
                  isLoadingPortfolio={isLoadingPortfolio}
                  isImportingPortfolio={isImportingPortfolio}
                  isUpdatingPortfolio={isUpdatingPortfolio}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                }}
              >
                {portfolioType === 'PERCENTAGE' ? <TotalPercentage /> : null}
              </Box>
            </Row>
            <Row>
              <Assets
                loading={isLoadingPortfolio && !defaultAssets?.length}
                schema={schema}
              />
              <Search />
            </Row>
            <InfoWrapper>
              <Info>
                Live market information for each holding may not be immediately
                available and therefore could require manual inputs that result
                in inaccurate prices relative to current market fluctuations.
                Seeds updates manually inputted holding prices monthly on a
                best-efforts basis.
                <br />
                <br />
                Certain mutual funds and ETFs may not be available to include if
                they do not have third-party &apos;EPI&apos; factor data. Please
                contact&nbsp;
                <a href="mailto:support@seedsinvestor.com">
                  support@seedsinvestor.com
                </a>
                &nbsp; if you have questions.
              </Info>
            </InfoWrapper>
          </Container>
          <ImportPortfolioModal />
          <FailedAssetsModal />
        </>
      )}
    </Formik>
  );
};

export default Portfolio;
