import createFastContext from 'context/fastContext/createFastContext';

import { AdvisorState } from './types';

const initialState: AdvisorState = {
  clientsList: [],
  isLoadingClientsList: false,
  zendeskToken: '',
};

const { Provider, useStore } = createFastContext<AdvisorState>(initialState);

export { Provider as AdvisorProvider, useStore };
