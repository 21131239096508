import { FC, memo } from 'react';

import { Container, Goal, Value } from './styles';
import { Props } from './types';

const FinancialGoalsResult: FC<Props> = ({ goal, value }) => {
  return (
    <Container>
      <Goal>{goal.toUpperCase()}</Goal>
      <Value>{value.toUpperCase()}</Value>
    </Container>
  );
};

export default memo(FinancialGoalsResult);
