import styled from 'styled-components';

import { from } from 'styles/media';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem;

  ${from.containerMaxWidth} {
    padding: 4rem;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  margin-bottom: 4rem;

  ${from.largeScreen} {
    width: 80%;
  }

  ${from.containerMaxWidth} {
    margin-bottom: 6rem;
  }
`;

export const Promo = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'GreyCliff CF';
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.extraBold};
  letter-spacing: 0;
  line-height: calc(36 / 32);
  max-width: 600px;

  ${from.largeScreen} {
    font-size: 2rem;
  }
`;
