import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrintIcon from '@mui/icons-material/Print';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SaveIcon from '@mui/icons-material/Save';
import ViewListIcon from '@mui/icons-material/ViewList';
import { memo, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RequestVaultModal from 'components/Account/Modals/RequestVaultModal';
import useAccountActions from 'context/fastContext/account/actions';
import { useStore as useAccountStore } from 'context/fastContext/account/context';
import type { AccountState } from 'context/fastContext/account/types';
import useTradeTicketActions from 'context/fastContext/tradeticket/actions';
import { useStore as useTradeTicketStore } from 'context/fastContext/tradeticket/context';
import type { TradeTicketState } from 'context/fastContext/tradeticket/types';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import theme from 'styles/v2/theme';
import ProposalVaultModal from 'v2/components/modals/ProposalVault';

import { Container, PrintButton, ResultsButton } from './styles';

const SidebarActionButtons = ({
  loading,
  triggerPrint,
  setComponentToPrint,
  setPrintRequest,
}: {
  loading: boolean;
  triggerPrint: Function;
  setComponentToPrint: any;
  setPrintRequest: any;
}) => {
  const { activateAccounts, fetchAccounts, fetchHistory } = useAccountActions();

  const [{ accounts, status }] = useAccountStore((s: AccountState) => ({
    accounts: s.accounts,
    status: s.status,
  }));

  const { fetchTicketHistory } = useTradeTicketActions();
  const [{ ticketHistory }] = useTradeTicketStore((s: TradeTicketState) => ({
    ticketHistory: s.ticketHistory,
  }));

  const [showRequestVault, setShowRequestVault] = useState<boolean>(false);
  const handleShowRequestVault = useCallback(() => {
    setShowRequestVault(!showRequestVault);
    setComponentToPrint('proposal');
  }, [setComponentToPrint, showRequestVault]);

  const openRequestVault = useCallback(() => {
    handleShowRequestVault();
  }, [handleShowRequestVault]);

  const [showProposalVault, setShowProposalVault] = useState<boolean>(false);
  const handleShowProposalVault = useCallback(() => {
    setShowProposalVault(!showProposalVault);
  }, [showProposalVault]);

  const { investorInfo } = useInvestorInfo();
  const history = useHistory();

  const hasMeetingUrl = investorInfo?.advisor?.meetingUrl;

  const checkAnswers = useCallback(() => {
    history.push(`/clients/${investorInfo?.id}/survey/answers`);
  }, [history, investorInfo?.id]);

  useEffect(() => {
    fetchTicketHistory({ useCache: false });
  }, [fetchTicketHistory]);

  const openProposalVault = useCallback(() => {
    handleShowProposalVault();
  }, [handleShowProposalVault]);

  const handleSaveProposal = useCallback(async () => {
    await activateAccounts();
    await fetchAccounts({ useCache: false, isUpdating: true });
    await fetchHistory({ useCache: false, isUpdating: true });
  }, [activateAccounts, fetchAccounts, fetchHistory]);

  return (
    <Container>
      {hasMeetingUrl && (
        <a
          href={hasMeetingUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <ResultsButton
            icon={<CalendarMonthIcon />}
            style={{
              backgroundColor: theme.palette.secondary[50],
              color: theme.palette.secondary.main,
            }}
          >
            Book A Meeting
          </ResultsButton>
        </a>
      )}
      <ResultsButton
        variant="secondary"
        onClick={checkAnswers}
        icon={<AssignmentIcon />}
        data-testid="investor-profile-button-assessment-results"
      >
        Assessment Results
      </ResultsButton>
      <PrintButton
        onClick={() => {
          setComponentToPrint('proposal');
          triggerPrint();
        }}
        icon={<PrintIcon />}
      >
        Print Proposal
      </PrintButton>
      <ResultsButton
        variant="secondary"
        onClick={openProposalVault}
        icon={<ViewListIcon />}
      >
        Proposal Vault
      </ResultsButton>
      {!!ticketHistory.length && (
        <ResultsButton
          variant="secondary"
          onClick={openRequestVault}
          icon={<RequestPageIcon />}
        >
          Request Vault
        </ResultsButton>
      )}
      {!accounts.length && status && (
        <ResultsButton
          variant="secondary"
          onClick={handleSaveProposal}
          icon={<SaveIcon />}
          disabled={status !== 'draft'}
        >
          Save To Vault
        </ResultsButton>
      )}
      <ProposalVaultModal
        isOpen={showProposalVault}
        handleShowProposalVault={handleShowProposalVault}
        triggerPrint={triggerPrint}
        printIsLoading={loading}
        setComponentToPrint={setComponentToPrint}
      />
      <RequestVaultModal
        isOpen={showRequestVault}
        handleShowRequestVault={handleShowRequestVault}
        triggerPrint={triggerPrint}
        setComponentToPrint={setComponentToPrint}
        setPrintRequest={setPrintRequest}
      />
    </Container>
  );
};

export default memo(SidebarActionButtons);
