import { makeVar } from '@apollo/client';

import { IncompleteInvestor } from 'models/IncompleteInvestor';

export type Modal = {
  data?: {
    assets?: string[];
    incompleteInvestors?: IncompleteInvestor[];
  };
  type?:
    | 'FAILED_ASSETS'
    | 'IMPORT_PORTFOLIO'
    | 'INCOMPLETE_INVESTORS'
    | 'SHARE'
    | 'REMOVE_HOUSEHOLD'
    | 'TERMS'
    | 'EDIT_ALLOCATIONS'
    | 'NEW_FEATURE'
    | 'PROPOSAL_VAULT';
};

export const modal = makeVar<Modal>({});
