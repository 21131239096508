import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useUser from 'context/hooks/User.hooks';
import { from, useMediaQuery } from 'styles/media';

import SidePicture from '../../SidePicture';
import { Card } from '../../styles';
import { CompanyLogo, Container, Content } from './styles';

function InvestorOnboarding() {
  const { fetchAdvisorInfo, advisorRequestFailed, advisorInfo } = useUser();
  const { push } = useHistory();
  const { advisorShortHash } = useParams<{ advisorShortHash: string }>();
  const isMobile = !useMediaQuery(from.tablet);

  useEffect(() => {
    fetchAdvisorInfo(advisorShortHash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = useCallback(() => {
    push(`/investor/onboarding/household/${advisorShortHash}`);
  }, [advisorShortHash, push]);

  const companyLogo = useMemo(
    () => advisorInfo?.company?.logo,
    [advisorInfo?.company?.logo],
  );

  return (
    <Container>
      {companyLogo && <CompanyLogo src={companyLogo} alt="" />}
      <Card>
        {!isMobile && <SidePicture />}
        <Content>
          {!advisorRequestFailed && (
            <Box>
              <Typography mb={1} variant="h6">
                Hi there.
              </Typography>
              <Box mb={4} fontSize="14px">
                <Typography fontSize={14} mb={2}>
                  This assessment is designed to uncover what&rsquo;s most
                  important to you when it comes to investing. To get started,
                  you&rsquo;ll first be asked to fill out your household
                  information.
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleContinue}
                  sx={{
                    mb: 2,
                    width: '100%',
                    textTransform: 'initial',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          )}
          {advisorRequestFailed && (
            <Box>
              There seems to be an issue with the link provided. Please reach
              out to your advisor for a new link.
              <br />
              <br />
              Sorry the inconvenience!
            </Box>
          )}
        </Content>
      </Card>
    </Container>
  );
}

export default InvestorOnboarding;
