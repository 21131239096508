import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { AdvisorsQuery, AdvisorsQueryVariables } from 'graphql/generated';
import { ADVISORS } from 'graphql/queries/advisor';
import useNormalizeSorting from 'hooks/useNormalizeSorting';
import { MinAdvisor, normalizeAdvisor } from 'models/Advisor';
import useAsyncSort from 'utils/useAsyncSort';

const useAdvisors = (variables: AdvisorsQueryVariables) => {
  const [sortBy, onSort] = useAsyncSort(true);
  const { data, loading } = useQuery<AdvisorsQuery, AdvisorsQueryVariables>(
    ADVISORS,
    {
      variables: {
        ...variables,
        orderBy: sortBy ? [sortBy] : undefined,
      },
    },
  );

  const advisors = useMemo(
    () =>
      data?.advisors?.objects
        ? {
            ...data.advisors,
            objects: data.advisors.objects.map(normalizeAdvisor),
          }
        : {
            hasNext: false,
            hasPrev: false,
            objects: [] as MinAdvisor[],
            orderBy: [],
            page: 0,
            pages: 0,
            totalResults: 0,
          },
    [data],
  );

  const sortedBy = useNormalizeSorting(data?.advisors);

  return {
    advisors,
    loading,
    onSort,
    sortedBy,
  };
};

export default useAdvisors;
