import styled from 'styled-components';

import theme from 'styles/v2/theme';

export const Container = styled.div`
  background-color: ${theme.palette.primary[900]};
  position: relative;
  width: 13.1875rem;
  height: 100%;
`;

export const Image = styled.img`
  flex-shrink: 0;
  width: 13.1875rem;
  height: 100%;
  background: ${theme.palette.primary[50]};
`;
