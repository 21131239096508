import styled, { css } from 'styled-components';

export const Column = styled.div<{ $clip?: boolean }>`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: calc(20 / 14);
  ${({ $clip = true }) =>
    $clip &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

interface ContainerProps {
  hasScore: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 0.5rem;

  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.colors.primary10};
  }

  ${Column} {
    &:nth-child(1) {
      width: ${({ hasScore }) => (hasScore ? '30%' : '35%')};
    }
    &:nth-child(2) {
      width: ${({ hasScore }) => (hasScore ? '20%' : '25%')};
      text-align: right;
      padding-right: 1.875rem;
    }
    &:nth-child(3) {
      width: ${({ hasScore }) => (hasScore ? '25%' : '0%')};
    }
    &:nth-child(4),
    &:last-child {
      width: ${({ hasScore }) => (hasScore ? '25%' : '40%')};
    }
  }
`;
