import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrowLeft.svg';
import BaseButton from 'components/Button';
import IconButton from 'components/IconButton';

import DefaultAdvisorPicker from './AdvisorPicker';

export const Container = styled.div`
  width: 35.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13.0625rem;
`;

export const Back = styled(IconButton).attrs(() => ({
  to: '/clients',
  variant: 'secondary',
  icon: <ArrowIcon />,
}))`
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(30 / 21);
  margin-left: 1rem;
`;

export const SecondaryTitle = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: calc(22 / 16);
  margin-bottom: 1.4375rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 7.25rem;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.875rem;
  grid-row-gap: 0.75rem;
  margin-bottom: 1.375rem;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 0.625rem;
  align-items: center;

  > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Button = styled(BaseButton)`
  height: 3rem;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
`;

export const SendEmailText = styled.label`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

export const Block = styled.div`
  padding: 0.875rem 0.875rem;
  border-radius: 0.3125rem;
  margin-bottom: 2.25rem;
`;

export const AdvisorPicker = styled(DefaultAdvisorPicker)`
  height: 3.125rem;

  & select {
    font-size: 1rem;
  }
`;
