import { FC } from 'react';

import { from, useMediaQuery } from 'styles/media';

import {
  CompanyLogo,
  Container,
  Logo,
  Tab,
  Tabs,
  Menu,
  Wrapper,
} from './styles';
import { Props } from './types';

const Navbar: FC<Props> = ({
  tabs,
  companyLogo,
  disableNavLinks = false,
  children,
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const hasUrl = companyLogo?.includes('https://');

  return (
    <Container>
      <Wrapper>
        {companyLogo ? (
          <a href="/" style={{ textDecoration: 'none' }}>
            <CompanyLogo
              src={
                hasUrl
                  ? companyLogo
                  : `${process.env.REACT_APP_S3_MEDIA}${companyLogo}`
              }
              alt=""
            />
          </a>
        ) : (
          <Logo to="/" />
        )}
        {!isMobile && !disableNavLinks ? (
          <Tabs>
            {tabs.map(({ label, to }) => (
              <Tab key={label} to={to}>
                {label}
              </Tab>
            ))}
          </Tabs>
        ) : null}
        {!disableNavLinks && <Menu>{children}</Menu>}
      </Wrapper>
    </Container>
  );
};

export default Navbar;
