import { subDays } from 'date-fns';
import IMask from 'imask';

export const pattern = 'm/`d/`Y';

export const maxDate = subDays(new Date(), 1);
export const minDate = new Date('01/01/1900');

export const blocksSchema = {
  d: {
    mask: IMask.MaskedRange,
    placeholderChar: 'd',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  m: {
    mask: IMask.MaskedRange,
    placeholderChar: 'm',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  Y: {
    mask: IMask.MaskedRange,
    placeholderChar: 'y',
    from: 1900,
    to: new Date().getFullYear() - 1,
    maxLength: 4,
  },
};
