import { Formik, Form } from 'formik';
import React, { FC, useMemo } from 'react';

import CheckboxControl from 'components/CheckboxControl';
import { Description, Hint } from 'components/SurveyCard/styles';

import useConnect from './connect';
import { initialValues, validationSchema } from './constants';
import { Props } from './types';

const Checkboxes: FC<Props> = ({
  id: stepId,
  checkboxes,
  description,
  extraDescription,
  onAnswer,
  initialValue,
  readOnly,
}) => {
  const { handleSubmit } = useConnect({ onAnswer });

  const noneOfTheAboveId = useMemo(
    () =>
      checkboxes.find((cb) =>
        cb.label.toLowerCase().includes('none of the above'),
      ),
    [checkboxes],
  )?.id;

  return (
    <>
      <Description dangerouslySetInnerHTML={{ __html: description || '' }} />
      {extraDescription && (
        <Hint dangerouslySetInnerHTML={{ __html: extraDescription }} />
      )}
      <Formik
        initialValues={initialValues(initialValue)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form id={stepId} noValidate>
            {checkboxes.map(({ id, label }, index) => (
              <CheckboxControl
                checked={values.items.includes(id)}
                id={`items.${index}`}
                key={id}
                // eslint-disable-next-line react/no-danger
                label={<span dangerouslySetInnerHTML={{ __html: label }} />}
                name="items"
                onChange={(event) => {
                  const isNoneOfTheAbove = id === noneOfTheAboveId;
                  if (isNoneOfTheAbove && event.target.checked) {
                    // If "None of the Above" is checked, reset others
                    setFieldValue('items', [noneOfTheAboveId]);
                  } else if (!isNoneOfTheAbove && event.target.checked) {
                    // If any other checkbox is checked, remove "None of the Above" from the selection
                    setFieldValue(
                      'items',
                      values.items
                        .filter((item) => item !== noneOfTheAboveId)
                        .concat(id),
                    );
                  } else {
                    // Handle unchecking normally
                    setFieldValue(
                      'items',
                      values.items.filter((item) => item !== id),
                    );
                  }

                  submitForm();
                }}
                value={id}
                disabled={readOnly}
              />
            ))}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Checkboxes;
