import { gql } from '@apollo/client';

export const ASSET_FRAGMENT = gql`
  fragment AssetParts on AssetObjectType {
    id
    symbol
    assetType
    name
    valueScore
    sector
  }
`;
