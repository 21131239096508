import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import { releaseNotesDisplayedInSession } from 'services/session';
import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const { me, refetch } = useMe();
  const isMobile = !useMediaQuery(from.tablet);
  const { close: handleClose } = useModal('NEW_FEATURE');

  const releaseNotesAlreadyDisplayed = releaseNotesDisplayedInSession();
  const isOpen = me?.showReleaseNotes && !releaseNotesAlreadyDisplayed;

  return {
    handleClose,
    refreshUserData: refetch,
    isMobile,
    isOpen,
    showReleaseNotes: me?.showReleaseNotes,
    releaseNotesContent: me?.releaseNotesContent,
    role: me?.role,
  };
};

export default useConnect;
