import createFastContext from 'context/fastContext/createFastContext';

import type { InvestorState } from './types';

const initialState: InvestorState = {
  profile: {},
  id: null,
  isLoadingProfile: false,
};

const { Provider, useStore } = createFastContext<InvestorState>(initialState);

export { Provider as InvestorProvider, useStore };
