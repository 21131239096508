import styled from 'styled-components';

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > a {
    text-align: -webkit-center;
    text-decoration: none;
  }

  > a > span {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.dark};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
    }
  }
`;
