import { FC } from 'react';

import { Chart, Description } from './styles';
import { Props } from './types';

const FinancialGoals: FC<Props> = ({ chartData, dataShown }) => {
  if (!chartData) return null;

  const formatValue = (value: number) => {
    const formattedValue = parseFloat(value.toFixed(2));
    return formattedValue > 0 ? formattedValue : '0.00';
  };

  const valuesToDisplay =
    dataShown === 'seeds'
      ? {
          equity: formatValue(chartData.seeds?.allocations?.equity || 0),
          fixed: formatValue(chartData.seeds?.allocations?.fixed || 0),
          alternatives: formatValue(
            chartData.seeds?.allocations?.alternatives || 0,
          ),
          cash: formatValue(0), // set these when available
          other: formatValue(0), // set these when available
        }
      : {
          equity: formatValue(chartData.current?.allocations?.equity || 0),
          fixed: formatValue(chartData.current?.allocations?.fixed || 0),
          cash: formatValue(chartData.current?.allocations?.cash || 0),
          other: formatValue(chartData.current?.allocations?.alternatives || 0),
          alternatives: formatValue(0),
        };

  return (
    <>
      <Description>
        <span style={{ fontWeight: 700 }}>Asset Class Breakdown:</span>
        <br />
        <span style={{ fontWeight: 700 }}>{valuesToDisplay.equity}%</span>{' '}
        Equity{', '}
        <span style={{ fontWeight: 700 }}>{valuesToDisplay.fixed}%</span> Fixed
        {' and '}
        <span style={{ fontWeight: 700 }}>
          {valuesToDisplay.alternatives}%
        </span>{' '}
        Alternatives.
      </Description>
      {dataShown === 'current' &&
        chartData.current.sectors &&
        chartData.current.pie && (
          <Chart
            data={chartData.current.sectors}
            pieData={chartData.current.pie}
            extended={false}
            dataShown={dataShown}
          />
        )}
      {dataShown === 'seeds' &&
        chartData.seeds.breakdown &&
        chartData.seeds.pie && (
          <Chart
            data={chartData.seeds.breakdown}
            pieData={chartData.seeds.pie}
            extended={false}
            dataShown={dataShown}
          />
        )}
    </>
  );
};

export default FinancialGoals;
