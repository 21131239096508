import { FC } from 'react';

import ContactPopover from './ContactPopover';
import useLogic from './logic';
import { Content, Container } from './styles';
import { Props } from './types';

const Popover: FC<Props> = ({ children, content }) => {
  const { anchorEl, handle, isOpen } = useLogic();
  return (
    <>
      <span onMouseEnter={handle.open} onMouseLeave={handle.close}>
        {children}
      </span>
      <Container
        id="mouse-over-popover"
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handle.close}
        disableRestoreFocus
      >
        <Content>{content}</Content>
      </Container>
    </>
  );
};

export default Popover;

export { ContactPopover };
