import { useFormikContext } from 'formik';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import usePortfolioActions from 'context/fastContext/portfolio/actions';
import useDebounce from 'hooks/useDebounce';

import { FormValues } from '../types';

const INITIAL_PAGE = 1;

const PAGE_SIZE = 100;

const useConnect = () => {
  const { fetchSearchSecurities } = usePortfolioActions();
  const { values } = useFormikContext<FormValues>();
  const [, setPage] = useState<number>(INITIAL_PAGE);
  const [search, setSearch] = useDebounce<string>('', 500);

  const [assets, setAssets] = useState<
    { id: string; name: string; symbol: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);

  const notAddedAssets = useMemo(() => {
    const addedAssetsSymbols = values.assets
      ? values.assets.map(({ symbol }) => symbol)
      : [];
    return assets.filter(({ symbol }) => !addedAssetsSymbols.includes(symbol));
  }, [assets, values.assets]);

  const handleReset = useCallback(() => {
    setSearch('');
    setAssets([]);
    setTotalResults(0);
  }, [setSearch, setAssets, setTotalResults]);

  const clearSearchInput = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      const closest = document.querySelector<HTMLInputElement>(
        'input[name="search"]',
      );
      if (closest) {
        closest.value = '';
        handleReset();
      }
    },
    [handleReset],
  );

  const handleSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.currentTarget.value.trim();
      if (searchValue) {
        setPage(INITIAL_PAGE);
        setSearch(searchValue);
        setLoading(true);
        const data = await fetchSearchSecurities(
          searchValue,
          INITIAL_PAGE,
          PAGE_SIZE,
        );
        if (data) {
          setAssets(data.securities);
          setTotalResults(data.totalResults);
        }
        setLoading(false);
      }
    },
    [setPage, setSearch, setLoading, fetchSearchSecurities],
  );

  useEffect(() => {
    if (!search) {
      setPage(INITIAL_PAGE);
    }
  }, [search]);

  return {
    clearSearchInput,
    handleSearch,
    loading,
    notAddedAssets,
    totalResults,
    search,
  };
};

export default useConnect;
