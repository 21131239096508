import { Formik, Form, Field } from 'formik';
import { FC } from 'react';

import Button from 'components/Button';
import { Hint } from 'components/SurveyCard/styles';

import { Container, FieldContainer, Description } from './styles';
import { Props } from './types';

const Counter: FC<Props> = ({
  extraDescription,
  description,
  ranges,
  onAnswer,
  initialValue = 0,
}) => {
  const { max, min } = ranges;

  return (
    <Container>
      <Description>
        {description}
        {extraDescription && (
          <>
            {' '}
            <Hint>{extraDescription}</Hint>
          </>
        )}
      </Description>
      <Formik
        initialValues={{ counter: initialValue as number }}
        onSubmit={({ counter }) => onAnswer(counter)}
      >
        {({ values, submitForm, setFieldValue }) => (
          <Form style={{ display: 'flex' }} onChange={submitForm}>
            <Button
              variant="secondary"
              onClick={() => {
                const nextValue = values.counter - 10;
                if (nextValue >= min) {
                  setFieldValue('counter', nextValue);
                }
              }}
            >
              -10
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                const nextValue = values.counter - 1;
                if (nextValue >= min) {
                  setFieldValue('counter', nextValue);
                }
              }}
            >
              -
            </Button>
            <FieldContainer>
              <Field
                initialValue={initialValue}
                name="counter"
                disabled
                value={`${values.counter} %`}
              />
            </FieldContainer>

            <Button
              variant="secondary"
              onClick={() => {
                const nextValue = values.counter + 1;
                if (nextValue <= max) {
                  setFieldValue('counter', nextValue);
                }
              }}
            >
              +
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                const nextValue = values.counter + 10;
                if (nextValue <= max) {
                  setFieldValue('counter', nextValue);
                }
              }}
            >
              +10
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Counter;
