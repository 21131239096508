import { memo } from 'react';

import FileIcon from 'components/FileIcon';

import { useLogic } from './logic';
import { Author, Container, Link, Info, Name, Wrapper } from './styles';
import { Props } from './types';

function SharedFileCard({ author, date, name, type, url, className }: Props) {
  const { formattedDate } = useLogic(date);

  const content = (
    <Container className={className}>
      <FileIcon fileType={type} />
      <Wrapper>
        <Name>{name}</Name>
        <Info>
          {formattedDate}
          {author ? (
            <>
              {' '}
              by <Author>{author}</Author>
            </>
          ) : null}
        </Info>
      </Wrapper>
    </Container>
  );

  if (url) {
    return (
      <Link href={url} download target="__blank">
        {content}
      </Link>
    );
  }

  return content;
}

export default memo(SharedFileCard);
