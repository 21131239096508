import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import styled, { css } from 'styled-components';

export const Indicators = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;
  opacity: 0;
  transition: opacity 100ms ease;
`;

export const Asc = styled(TiArrowSortedUp)`
  margin-bottom: calc(1em / -4);
`;

export const Desc = styled(TiArrowSortedDown)`
  margin-top: calc(1em / -4);
`;

export const Container = styled.button<{ $direction?: string }>`
  all: unset;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  min-height: 1.5rem;

  &:hover,
  &:focus {
    ${Indicators} {
      opacity: 1;
    }
  }

  ${({ theme, $direction }) =>
    $direction &&
    css`
      font-weight: ${theme.weights.bold};
      color: ${theme.colors.dark};
      ${Indicators} {
        opacity: 1;
        align-items: center;
        > svg {
          margin: 0;
        }
      }
    `};
`;
