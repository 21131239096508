import { FC, useEffect } from 'react';

import { Container, MenuPopover, Options, ReachMenu, Title } from './styles';
import { Props } from './types';
import '@reach/menu-button/styles.css';

const OnOpen = ({
  execute,
  onOpen,
}: Pick<Props, 'onOpen'> & { execute: boolean }) => {
  useEffect(() => {
    if (!onOpen || !execute) return;
    onOpen();
  }, [onOpen, execute]);
  return null;
};

const BaseDropdown: FC<Props> = ({
  children,
  className,
  title,
  trigger,
  onOpen,
  ...props
}) => (
  <ReachMenu>
    {({ isExpanded }: { isExpanded: boolean }) => (
      <>
        {trigger}
        <MenuPopover>
          <OnOpen execute={isExpanded} onOpen={onOpen} />
          <Container className={className} {...props}>
            <Options>
              {title && <Title>{title}</Title>}
              {children}
            </Options>
          </Container>
        </MenuPopover>
      </>
    )}
  </ReachMenu>
);

export default BaseDropdown;
