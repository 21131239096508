import { useField } from 'formik';
import { FC, memo } from 'react';

import InputBox from 'components/InputBox';
import { Props } from 'components/InputBox/types';

const Input: FC<Props & { name: string }> = ({ name, ...props }) => {
  const [, meta] = useField<number>(name);
  const hasError = !!meta.error;
  return (
    <InputBox
      name={name}
      error={hasError}
      title={hasError ? meta.error : undefined}
      {...props}
    />
  );
};

export default memo(Input);
