import { useEffect, useMemo, useRef } from 'react';

import PrettyLoading from 'components/PrettyLoading';
import Print from 'components/Print';
import RatingInput, { RatingOptions } from 'components/RatingInput';
import {
  Block,
  BlockParagraph,
  Bold,
  Result,
  ResultTitle,
  SectionTitle,
} from 'components/SurveyResultsCard';
import Values from 'components/Values';
import useInvestorActions from 'context/fastContext/investor/actions';
import { useStore as useInvestorStore } from 'context/fastContext/investor/context';
import useInvestorInfo from 'context/hooks/InvestorInfo.hooks';
import useMe from 'graphql/hooks/useMe';
import { from, useMediaQuery } from 'styles/media';

import PrintAssessment from '../Print/PrintAssessment';
import { epiToLongSentence, humanizeTimeHorizon } from './logic';
import { Container, PersonalityProfile } from './styles';

const Results = () => {
  const isMobile = !useMediaQuery(from.tablet);
  const { me } = useMe();
  const printRef = useRef<HTMLDivElement>(null);

  const [{ isValuesDriven, normalizedEpiPriority, status }] = useInvestorStore(
    (s: any) => ({
      isValuesDriven: s.profile?.isValuesDriven,
      normalizedEpiPriority: s.profile?.normalizedEpiPriority,
      status: s.profile?.status,
    }),
  );

  const { fetchProfile } = useInvestorActions();

  const profileIsComplete = useMemo(() => status === 'COMPLETE', [status]);

  const { investorInfo, loadingInvestorInfo, fetchInvestorInfo } =
    useInvestorInfo();

  const dontRecommend = useMemo(
    () =>
      investorInfo?.surveyResult?.timeHorizon?.split('-')[1] === '14' &&
      investorInfo?.surveyResult.riskTolerance === 'MODEST',
    [investorInfo],
  );

  const investorId = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      investorInfo?.id
        ? investorInfo?.id
        : (me as any)?.role === 'INVESTOR'
        ? (me as any)?.investor?.id
        : null,
    [investorInfo?.id, me],
  );

  useEffect(() => {
    if (investorId) {
      fetchProfile({ investorId });
      fetchInvestorInfo(investorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorId]);

  if (loadingInvestorInfo) return <PrettyLoading text="Generating results" />;
  return (
    <>
      <PrintAssessment ref={printRef} />
      <Print content={() => printRef.current}>
        {({ triggerPrint }) => (
          <Container isMobile={isMobile} triggerPrint={triggerPrint}>
            <SectionTitle>Here is what matters to you</SectionTitle>
            <PersonalityProfile isMobile={isMobile} />
            <Result>
              <ResultTitle>RISK TARGET</ResultTitle>
              <RatingInput
                isMobile={isMobile}
                name="RiskTolerance"
                // @ts-ignore
                initialValue={investorInfo?.surveyResult?.riskLevel || 0}
                // @ts-ignore
                value={investorInfo?.surveyResult?.riskLevel || 0}
                options={RatingOptions}
                readOnly
                colorized
              />
            </Result>
            <Result>
              <ResultTitle>VALUES</ResultTitle>
              {/* eslint-disable-next-line no-nested-ternary */}
              {profileIsComplete ? (
                isValuesDriven ? (
                  <Values />
                ) : (
                  <BlockParagraph>No preference</BlockParagraph>
                )
              ) : null}
            </Result>
            {investorInfo &&
            investorInfo.surveyResult?.timeHorizon &&
            investorInfo.surveyResult?.riskTolerance ? (
              <Block>
                <BlockParagraph>
                  You have{' '}
                  <Bold>
                    {humanizeTimeHorizon(
                      investorInfo.surveyResult.timeHorizon
                        .split('-')
                        .map(Number),
                    )}
                  </Bold>{' '}
                  before you need to use your portfolio assets, and you’re
                  willing to take{' '}
                  <Bold>
                    {investorInfo.surveyResult.riskTolerance.toLowerCase()}
                  </Bold>{' '}
                  risk.
                </BlockParagraph>
                <BlockParagraph>
                  You also care most about the{' '}
                  <Bold>{epiToLongSentence(normalizedEpiPriority?.[0]!)}</Bold>{' '}
                  followed by{' '}
                  <Bold>{epiToLongSentence(normalizedEpiPriority?.[1]!)}</Bold>
                  and the
                  <Bold>{epiToLongSentence(normalizedEpiPriority?.[2]!)}</Bold>.
                </BlockParagraph>
                {dontRecommend && (
                  <BlockParagraph>
                    For now, we don&apos;t recommend investing in the market
                    since you don&apos;t have much time before you&apos;ll need
                    to use your money and you&apos;re not comfortable taking on
                    risk.
                  </BlockParagraph>
                )}
              </Block>
            ) : null}
          </Container>
        )}
      </Print>
    </>
  );
};

export default Results;
