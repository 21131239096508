import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  NotificationsQuery,
  NotificationsQueryVariables,
} from 'graphql/generated';
import { NOTIFICATIONS } from 'graphql/queries/notification';
import { normalizeNotification } from 'models/Notification';

const useNotifications = () => {
  const { data, loading, refetch } = useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NOTIFICATIONS);

  const notifications = useMemo(
    () =>
      data?.notifications ? data.notifications.map(normalizeNotification) : [],
    [data],
  );

  return {
    loading,
    notifications,
    refetch,
  };
};

export default useNotifications;
